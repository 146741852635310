<div class="dialog">
    <div class="wrapper_grid">
        <app-widget>
            <p class="section-title">{{ 'panels.closedPerMonthOfComplaint' | translate }}
                <span class="material-icons ml-2 fullscreen-icon" [mat-dialog-close]="true">fullscreen_exit</span>
            </p>

            <app-color-table [headerIndicators]="headerIndicators" [tableInfo]="tableInfo"></app-color-table>

        </app-widget>
    </div>
</div>
export var multi = [
    {
        "name": "Tasa de rechazo Técnica",
        "series": [
            {
                "name": "Jul19",
                "value": 40
            },
            {
                "name": "Sep19",
                "value": 60
            },
            {
                "name": "Oct19",
                "value": 55
            },
            {
                "name": "Nov19",
                "value": 70
            },
            {
                "name": "Dic19",
                "value": 80
            },
            {
                "name": "Ene19",
                "value": 57
            },
            {
                "name": "Feb19",
                "value": 60
            }
        ]
    }
];

<app-widget>
    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <span class="title section-card-title">{{ 'panels.sinistersClaimsPercentage' | translate }}</span>
    </div>

    <div class="mt-7 d-flex flex-column justify-content-end align-items-center">

        <div class="d-flex mt-4 mb-7">
            <component-loader 
                [width]="210" 
                [height]="100"
                *ngIf="!sinistersClaimsPercentageLoaded">
            </component-loader>
            <app-kpi-color 
                class="text-center" 
                [title]="'panels.sinistersClaimsPercent'" 
                [mainValue]="sinistersClaimsPercentage?.main_value" 
                [mainValueUnit]="' ' + sinistersClaimsPercentage.main_value_unit" 
                [percentagePrevious]="sinistersClaimsPercentage?.percentage_previous" 
                [percentage]="sinistersClaimsPercentage?.percentage" 
                [trend]="sinistersClaimsPercentage?.trend" 
                *ngIf="sinistersClaimsPercentageLoaded">
            </app-kpi-color>
        </div>
        
        <div class="d-flex my-5">
            <component-loader 
                [width]="210" 
                [height]="100"
                *ngIf="!regulatorClaimsPercentageLoaded">
            </component-loader>
            <app-kpi-color 
                class="text-center" 
                [title]="'panels.regulatorClaimsPercent'" 
                [mainValue]="regulatorClaimsPercentage?.main_value" 
                [mainValueUnit]="' ' + regulatorClaimsPercentage.main_value_unit" 
                [percentagePrevious]="regulatorClaimsPercentage?.percentage_previous" 
                [percentage]="regulatorClaimsPercentage?.percentage" 
                [trend]="regulatorClaimsPercentage?.trend" 
                *ngIf="regulatorClaimsPercentageLoaded">
            </app-kpi-color>
        </div>

        <!-- <div class="d-flex my-5">
            <component-loader 
                [width]="210" 
                [height]="100"
                *ngIf="!judicialClaimsPercentageLoaded">
            </component-loader>
            <app-kpi-color 
                class="text-center" 
                [title]="'panels.judicialClaimsPercent'" 
                [mainValue]="judicialClaimsPercentage?.main_value" 
                [mainValueUnit]="' ' + judicialClaimsPercentage.main_value_unit" 
                [percentagePrevious]="judicialClaimsPercentage?.percentage_previous" 
                [percentage]="judicialClaimsPercentage?.percentage" 
                [trend]="judicialClaimsPercentage?.trend" 
                *ngIf="judicialClaimsPercentageLoaded">
            </app-kpi-color>
        </div> -->

    </div>

</app-widget>
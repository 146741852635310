<ngx-charts-bubble-chart
    [view]="view"
    [scheme]="colorScheme"
    [results]="bubbleData"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [minRadius]="minRadius"
    [maxRadius]="maxRadius">
</ngx-charts-bubble-chart>

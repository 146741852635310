import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-clients-internal-process-quality-table',
  templateUrl: './clients-internal-process-quality-table.component.html',
  styleUrls: ['./clients-internal-process-quality-table.component.scss']
})
export class ClientsInternalProcessQualityTableComponent implements OnInit {
  
  loaded: boolean = false;

  filtersValues: any;

  unsubscribe$: Subject<any> = new Subject<any>();

  dropoutRateAttentionTitle: string = "labels.dropoutRateAttention";
  dropoutRateAttentionMainValue: string = "2";
  dropoutRateAttentionMainValueUnit: string = "%";
  dropoutRateAttentionTrend: number = 1;
  dropoutRateAttentionPercentage: string = "";

  dropoutRateSinistersTitle: string = "labels.dropoutRateSinisters";
  dropoutRateSinistersMainValue: string = "2";
  dropoutRateSinistersMainValueUnit: string = "%";
  dropoutRateSinistersTrend: number = 1;
  dropoutRateSinistersPercentage: string = "";

  serviceLevelAttentionTitle: string = "labels.serviceLevelAttention";
  serviceLevelAttentionMainValue: string = "2";
  serviceLevelAttentionMainValueUnit: string = "%";
  serviceLevelAttentionTrend: number = 1;
  serviceLevelAttentionPercentage: string = "";

  serviceLevelSinistersTitle: string = "labels.serviceLevelSinisters";
  serviceLevelSinistersMainValue: string = "2";
  serviceLevelSinistersMainValueUnit: string = "%";
  serviceLevelSinistersTrend: number = 1;
  serviceLevelSinistersPercentage: string = "";

  rejectionsTitle: string = "labels.rejections";
  rejectionsMainValue: string = "-6";
  rejectionsMainValueUnit: string = "%";
  rejectionsTrend: number = 1;
  rejectionsPercentage: string = "";

  processingTimeTitle: string = "labels.processingTime";
  processingTimeMainValue: string = "1";
  processingTimeMainValueUnit: string = "%";
  processingTimeTrend: number = 1;
  processingTimePercentage: string = "";

  earlyFallTitle: string = "labels.earlyFall";
  earlyFallMainValue: string = "2";
  earlyFallMainValueUnit: string = "%";
  earlyFallTrend: number = 1;
  earlyFallPercentage: string = "";

  endorsementsRateTitle: string = "labels.endorsementsRate";
  endorsementsRateMainValue: string = "-1";
  endorsementsRateMainValueUnit: string = "%";
  endorsementsRateTrend: number = 1;
  endorsementsRatePercentage: string = "";

  doubledRateTitle: string = "labels.doubledRate";
  doubledRateMainValue: string = "2";
  doubledRateMainValueUnit: string = "%";
  doubledRateTrend: number = 1;
  doubledRatePercentage: string = "";

  renewalRateTitle: string = "labels.renewalRate";
  renewalRateMainValue: string = "6";
  renewalRateMainValueUnit: string = "%";
  renewalRateTrend: number = 0;
  renewalRatePercentage: string = "-1";

  averageTimeAttendanceTitle: string = "labels.averageTimeAttendance";
  averageTimeAttendanceMainValue: string = "-2.1";
  averageTimeAttendanceMainValueUnit: string = "%";
  averageTimeAttendanceTrend: number = 1;
  averageTimeAttendancePercentage: string = "";

  assistsConsumedPercentageTitle: string = "labels.assistsConsumedPercentage";
  assistsConsumedPercentageMainValue: string = "2";
  assistsConsumedPercentageMainValueUnit: string = "%";
  assistsConsumedPercentageTrend: number = 1;
  assistsConsumedPercentagePercentage: string = "";

  attendanceWaiversPercentageTitle: string = "labels.attendanceWaiversPercentage";
  attendanceWaiversPercentageMainValue: string = "6";
  attendanceWaiversPercentageMainValueUnit: string = "%";
  attendanceWaiversPercentageTrend: number = 0;
  attendanceWaiversPercentagePercentage: string = "-1";

  constructor(
    private filtersService: FiltersService
  ) { }

  ngOnInit(): void {
    this.subscribeToFiltersValues();
  }

  subscribeToFiltersValues(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        filtersValues => {
          if (filtersValues.length !== 0) {
            this.loaded = false;
            this.filtersValues = filtersValues;
            
            // this.resetVariables();
            this.retrieveClientsInternalQualityProcess();
          }
        }
      );
  }

  retrieveClientsInternalQualityProcess(): void {

    // TODO: edit when final API call ready (simulated with timeout)
    setTimeout(() => {
      this.loaded = true;
    }, 3000);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

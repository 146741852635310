import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators'
import { QualityClaimReason } from 'src/app/shared/models/quality-claim-reason';
import { TranslateService } from '@ngx-translate/core';
import { ExcelFileInfo } from 'src/app/shared/models/excel-file-info';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { environment } from '../../../../environments/environment';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import _ from 'lodash';

@Component({
  selector: 'app-quality-claims',
  templateUrl: './quality-claims.component.html',
  styleUrls: ['./quality-claims.component.scss']
})
export class QualityClaimsComponent implements OnInit, OnDestroy {
  
  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "sale-quality-complaints--sales-claims";

  excelDataLoaded: ExcelFileInfo;

  subscription: Subscription;

  title: string = "";
  dropdownId: string = 'salesQualityClaims';

  unsubscribe$: Subject<any> = new Subject<any>();
  qualityClaimReasons: any = [];
  loaded: boolean = false;
  allF: string;
  
  constructor(
    private apimstService: ApimstService,
    private translateService: TranslateService,
    private events: EventsService,
    private filtersService: FiltersService,
    private excelService: ExcelService,
    private atlasHelper: AtlasHelperService,
    private i18n: I18nService
  ) { }

  ngOnInit(): void {

    this.subscribeToActiveLanguage();
    this.translateService.get('labels.allF').pipe(takeUntil(this.unsubscribe$)).subscribe(data => this.allF = data);
    this.retrieveQualityClaimsReason();

    this.subscribeToExcelInfo();
    this.subscribeToFiltersValues();
  }

  private retrieveQualityClaimsReason() {
    this.loaded = false;
    this.apimstService.getClaimReasonMaster().pipe(takeUntil(this.unsubscribe$)).subscribe(
      (data: Array<QualityClaimReason>) => {
        this.qualityClaimReasons = [];
        const salesQualityClaimReasons = data.filter(v => v.type_claim_reason_id === '6');
        this.qualityClaimReasons.push(
          {
            id: '',
            type: 'sale-claim',
            name: this.allF,
            selected: true
          }
        );
        for(let reason of salesQualityClaimReasons) {
          this.qualityClaimReasons.push(
            {
              id: reason.subtype_claim_reason_id,
              parentId: reason.type_claim_reason_id,
              type: 'sale-claim',
              name: reason.subtype_claim_reason_desc,
              selected: false
            }
          );
        }

        this.loaded = true;
      },

      error => console.log("An error ocurred while retrieving quality claims reasons data " + error)
    );
  }

  subscribeToExcelInfo(): void {
    this.subscription = this.events.salesClaimsExcelInfoLoaded$
      .subscribe({
        next: excelDataLoaded => {
          this.loaded = false;

          if (!excelDataLoaded.json) return;

          this.excelDataLoaded = excelDataLoaded;
          this.loaded = true;

        }
      })
  }

  subscribeToFiltersValues(): void {
    this.subscription = this.filtersService.filtersValues$
      .subscribe({ next: filters => this.loaded = false })
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.excelDataLoaded.json, this.excelDataLoaded.headers, this.excelDataLoaded.excelFileName, this.excelDataLoaded.filtersValues);
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscription.unsubscribe();
  }

}

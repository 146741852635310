<div class="wrapper">


    <span *ngIf="activeItemIndex != 1" class="left-button" [routerLink]="prevItem"></span>
    <span *ngIf="activeItemIndex != itemsNumber" class="right-button" [routerLink]="nextItem"></span>

    <div class="page-points" *ngIf="itemsNumber > 1">
        <span 
            [routerLink]="dot.link" 
            routerLinkActive="active" 
            *ngFor="let dot of dots">
        </span>
    </div>

    <ng-content></ng-content>

</div>
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { FiltersService } from '../../filters/filters.service';
import { UtilsService } from '../../utils/utils.service';
import { FILTERS_MAP } from 'src/app/shared/globals/globals';

/*

  Example of data for document sheets
  -----------------------------------

    data_1: any[] = [
      [ "Data 1 Column 1", "Data 1 Column 2", "Data 1 Column 3", "Data 1 Column 4", "Data 1 Column 5", "Data 1 Column 6", "Data 1 Column 7" ],
      [  1, 2, 3, 4, 5, 6, 7 ],
      [  8, 9, 10, 11, 12, 13, 14 ]
    ];

    data_2: any[] = [
      ....
    ];

    data_total: any[] = [this.data_1, this.data_2];
    data_sheet_names: any[] = ['Portfolio', 'New business'];

*/

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  filtersValues: any;
  translations: any;
  filtersTranslations: any = {};
  selectedFilterValues: any = [];

  constructor(
    private utilsService: UtilsService,
    private filtersService: FiltersService,
    private translateService: TranslateService
  ) { 
    // this.filtersService.filtersValues$
    //   .subscribe(
    //     filters => {
    //       this.filtersValues = filters;
    //     }
    //   )
  }

  public exportAsExcelFile(json: any[], headers: any[], excelFileName: string, filtersValues: any, filters: boolean = true): void {

    this.filtersService.filtersValues$
    .subscribe(
      filters => {
        this.filtersValues = filters;
      }
    )

    this.getRequiredFilters(filtersValues);
    this.getTranslations();

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: {}, SheetNames: [] };

    json.forEach((value, index) => {
      let ws_name = this.getFormattedName(headers[index]);
      let dataDetails = [...value];
      if (filters) {
        dataDetails = this.addFiltersToSheet(dataDetails);
      }
      let ws = XLSX.utils.aoa_to_sheet(dataDetails);
      XLSX.utils.book_append_sheet(workbook, ws, ws_name);
    });

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);

  }

  // Method to retrieve the requiered filters applied to the search
  private getRequiredFilters(filtersValues: any) {
    this.selectedFilterValues = [];
    if (filtersValues) {
      for (let item of filtersValues) {
        if (item.values) {
          this.selectedFilterValues.push({name: item.name, value: item.values});
        } 
      }
    }
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + this.formatDate() + EXCEL_EXTENSION);
  }

  private addFiltersToSheet(value: any[]): any[] {
    let title: any[] = [this.translations.appliedFilters];
    let data_names: any[] = [this.translations.name, this.translations.value_s];
    let filterName: string = "";
    let filterInfo: any[];
    let filterValues: any[];

    for (let _i = 0; _i < 4; _i++) {
      value.push([]);
    }
    value.push(title);
    value.push(data_names);

    for (let item of this.selectedFilterValues) {
      const filter = this.filtersValues.filter(v => v.name === item.name);
      if (filter.length !== 0) {
        filterInfo = [];
        filterName = this.searchTranslation( filter[0]['name'], this.filtersTranslations );
        filterValues = filter[0]['valueItems'];

        for(let item of filterValues) {
          filterInfo.push(item['item_text']);
        }

        value.push([filterName, filterInfo.join(' // ')]);
      }

      if (item.name === 'date') {
        const dateValues = item.value;
        const monthTranslation = this.searchTranslation('month', this.filtersTranslations);
        const yearTranslation = this.searchTranslation('year', this.filtersTranslations);

        if (dateValues.day) {
          const dayTranslation = this.searchTranslation('day', this.filtersTranslations);
          value.push([dayTranslation, dateValues.day]);
        }
      
        value.push([monthTranslation, dateValues?.month]);
        value.push([yearTranslation, dateValues?.year])

      }

    } 
    
    return value;

  }

  public searchTranslation(name: string, translationsSource: any) {
    let nameToSearch: any;
    nameToSearch = translationsSource[name];

    return nameToSearch;
  }

  private getTranslations(): void {
    this.translateService.get('filtersPanel')
    .subscribe(
      translations => {
        this.translations = translations;
        for (var key_map in FILTERS_MAP) {
          for (var key_translations in this.translations) {
              if(FILTERS_MAP[key_map] == key_translations) {
                this.filtersTranslations[key_map] = this.translations[key_translations];
              }
          }
        }
      }
    )
  }

  private getFormattedName(name: string ="") {
    if( name.length > 31 ) return this.limitNameLength(name);
    return name;
  }

  private limitNameLength(name: string ="", length: number = 31) {
    return `${name.slice(0, length-3)}...`;
  }

  private formatDate() {
    let fullDate = "";
    let dateObject = new Date();
    let year = dateObject.getFullYear();
    let month = this.utilsService.padWithZeros((dateObject.getMonth() + 1), 2);
    let day = this.utilsService.padWithZeros(dateObject.getDate(), 2);
    fullDate = `${year}${month}${day}`;

    return fullDate;
  }

}

import { Component, OnInit } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { CurrencyService } from 'src/app/shared/services/currency/currency.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';

@Component({
  selector: 'app-retention-according-to-record-funnel',
  templateUrl: './retention-according-to-record-funnel.component.html',
  styleUrls: ['./retention-according-to-record-funnel.component.scss']
})
export class RetentionAccordingToRecordFunnelComponent implements OnInit {

// Loader
loaded: boolean;

// Items to retrieve data
items: any = [];
itemsBonus: any[] = [];
itemsPolicy: any[] = [];

cancellationRequest: any = {};
cancellationRetentionAction: any = {};
cancellationVolunteersIndicator: any = {};

cancellationRequestBonus: any = [];
cancellationRequestPolicy: any = []
cancellationRetentionActionBonus: any = [];
cancellationRetentionActionPolicy: any = [];
cancellationVolunteersIndicatorBonus: any = [];
cancellationVolunteersIndicatorPolicy: any = [];

percentagePrevious: string = "labels.pm";

// Option tabs
actualOption: string = 'panels.bonus';
optionTabBonus: string = 'panels.bonus';
optionTabPolicy: string = 'panels.policy';
optionToFetch: string = 'p';
graphName: string = 'retentionRecordRateCharge';

// Filters value
filtersValues: any;

// Subscription
unsubscribe$: Subject<any> = new Subject<any>();

// Format numbers
formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

// Check number symbol
isNegative: boolean = false;

constructor(
  private apimstService: ApimstService,
  private eventsService: EventsService,
  private filtersService: FiltersService,
  private utilsService: UtilsService,
  private currencyService: CurrencyService
) { }

ngOnInit(): void {

  this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        if (data.length !== 0) {
          this.filtersValues = data;
          this.resetVariables();
          this.assignData();
        }
      }
    );

    this.eventsService.policiesBonusOption$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const option = data[0];
          const name = data[1];
          if ((option === this.optionTabBonus || option === this.optionTabPolicy) && name === this.graphName ) {
            this.actualOption = option;
            this.assignData();
          }
        }
      );
}

// Method for retrieving the data information for the funnel
retrieveFunnelData(type_data, data) {

  const cancellationManagedRetentionRequests = this.apimstService.getRetentionFunnelIndicators('cmrr', data, type_data);
  const cancellationManagedRetentionActionNumber = this.apimstService.getRetentionFunnelIndicators('cmran', data, type_data);
  const cancellationManagedRetentionVolunteers = this.apimstService.getRetentionFunnelIndicators('cmrv', data);
  const requests = [cancellationManagedRetentionRequests, cancellationManagedRetentionActionNumber, cancellationManagedRetentionVolunteers]

  // # cancellation_managed_retention_requests="cmrr"
  // # cancellation_managed_retention_requests_premium="cmrrp"
  // # cancellation_managed_retention_action_number="cmran"
  // # cancellation_managed_retention_action_number_premium="cmranp"
  // # cancellation_managed_retention_volunteers="cmrv"


  // Show loader
  this.loaded = false;

  forkJoin(requests)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(results => {
      const cancellationManagedRetentionRequestsResult = results[0][0];
      const cancellationManagedRetentionActionNumberResult = results[1][0];
      const cancellationManagedRetentionVolunteersResult = results[2][0];

      // Currency symbol for the 'Bonus' values
      const currencySymbol = this.getCurrencySymbol();

      // Create items array
      this.cancellationRequest.title = "funnels.requests";
      this.cancellationRequest.main_value = cancellationManagedRetentionRequestsResult['value'] != null && cancellationManagedRetentionRequestsResult['value'] != "" ? this.formatPipe.transform( parseFloat( cancellationManagedRetentionRequestsResult['value'].replace(/,/g, '') ) ) + currencySymbol : '-1';
      this.cancellationRequest.second_value_prefix = this.percentagePrevious;
      this.cancellationRequest.second_value = cancellationManagedRetentionRequestsResult['diff'] != null ? this.formatPipe.transform( parseFloat( cancellationManagedRetentionRequestsResult['diff'].replace(/-|,/g, '')) ) + "%" : '0';
      this.cancellationRequest.trend = cancellationManagedRetentionRequestsResult['diff_trend'] != null ? parseInt(cancellationManagedRetentionRequestsResult['diff_trend']) : 0;
      this.cancellationRequest.trend_direction = this.utilsService.isNegative( cancellationManagedRetentionRequestsResult['diff'] ) == false ? 1 : 0;

      this.cancellationRetentionAction.title = "funnels.retentionsPerAction";
      this.cancellationRetentionAction.main_value = cancellationManagedRetentionActionNumberResult['value'] != null && cancellationManagedRetentionActionNumberResult['value'] != "" ? this.formatPipe.transform( parseFloat( cancellationManagedRetentionActionNumberResult['value'].replace(/,/g, '') ) ) + currencySymbol : '-1';
      this.cancellationRetentionAction.second_value_prefix = this.percentagePrevious;
      this.cancellationRetentionAction.second_value = cancellationManagedRetentionActionNumberResult['diff'] != null ? this.formatPipe.transform( parseFloat( cancellationManagedRetentionActionNumberResult['diff'].replace(/-|,/g, '')) ) + "%" : '0';
      this.cancellationRetentionAction.trend = cancellationManagedRetentionActionNumberResult['diff_trend'] != null ? parseInt(cancellationManagedRetentionActionNumberResult['diff_trend']) : 0;
      this.cancellationRetentionAction.trend_direction = this.utilsService.isNegative( cancellationManagedRetentionActionNumberResult['diff'] ) == false ? 1 : 0;

      this.cancellationVolunteersIndicator.title = "funnels.voluntaryCancellation";
      this.cancellationVolunteersIndicator.main_value = cancellationManagedRetentionVolunteersResult['value'] != null && cancellationManagedRetentionVolunteersResult['value'] != "" ? this.formatPipe.transform( parseFloat( cancellationManagedRetentionVolunteersResult['value'].replace(/,/g, '') ) ) + currencySymbol : '-1';
      this.cancellationVolunteersIndicator.second_value_prefix = this.percentagePrevious;
      this.cancellationVolunteersIndicator.second_value = cancellationManagedRetentionVolunteersResult['diff'] != null ? this.formatPipe.transform( parseFloat( cancellationManagedRetentionVolunteersResult['diff'].replace(/-|,/g, '')) ) + "%" : '0';
      this.cancellationVolunteersIndicator.trend = cancellationManagedRetentionVolunteersResult['diff_trend'] != null ? parseInt(cancellationManagedRetentionVolunteersResult['diff_trend']) : 0;
      this.cancellationVolunteersIndicator.trend_direction = this.utilsService.isNegative( cancellationManagedRetentionVolunteersResult['diff'] ) == false ? 1 : 0;

      const itemData: any = [];
      itemData.push(this.cancellationRequest);
      itemData.push(this.cancellationRetentionAction);
      itemData.push(this.cancellationVolunteersIndicator);

      if (this.actualOption === this.optionTabBonus) {
        this.itemsBonus = JSON.parse(JSON.stringify(itemData));
      } else if (this.actualOption === this.optionTabPolicy) {
        this.itemsPolicy = JSON.parse(JSON.stringify(itemData));
      }

      this.assignDataToPrint();

    });

  }

// Method to add currency symbol if 'Bonus' selected
private getCurrencySymbol() {
  let currencySymbol: string = '';

  if (this.actualOption === this.optionTabBonus) {
    this.currencyService.currencyInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(currencyInfo =>
        currencySymbol = currencyInfo.symbol
      );
  }
  return currencySymbol;
}

// Method to reset the data storage variables
private resetVariables() {
  this.itemsBonus = [];
  this.itemsPolicy = [];
}

// Method to assign the data to print
private assignDataToPrint() {
  if (this.actualOption === this.optionTabBonus) {
    this.items = this.itemsBonus;
    this.loaded = true;
  } else if (this.actualOption === this.optionTabPolicy) {
    this.items = this.itemsPolicy;
    this.loaded = true;
  }
}

// Method to assing data
private assignData() {
  if (this.actualOption === this.optionTabBonus && this.itemsBonus.length === 0) {
    this.retrieveFunnelData('p', this.filtersValues);
  } else if (this.actualOption === this.optionTabPolicy && this.itemsPolicy.length === 0) {
    this.retrieveFunnelData('c', this.filtersValues);
  } else {
    this.assignDataToPrint();
  }
}

// OnDestroy
ngOnDestroy() {
  this.unsubscribe$.next();
  this.unsubscribe$.complete();
}

}

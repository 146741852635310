<div class="d-flex justify-content-between w-100">
    <div>
        <component-loader 
            [width]="110" 
            [height]="55" 
            *ngIf="!currentFixedStockLoaded">
        </component-loader>
        <app-small-kpi 
            [title]="'panels.fixedCurrentStock'" 
            [mainValue]="mainValueCurrentFixedStock" 
            [mainValueUnit]="mainValueUnitCurrentFixedStock" 
            [trend]="trendCurrentFixedStock"
            [percentagePrevious]="percentagePreviousCurrentFixedStock" 
            [percentage]="percentageCurrentFixedStock"
            *ngIf="currentFixedStockLoaded">
        </app-small-kpi>
    </div> 
    <div class="ml-5">
        <component-loader 
            [width]="110" 
            [height]="55" 
            *ngIf="!premiumCertificateLoaded">
        </component-loader>
        <app-small-kpi 
            [title]="'panels.fixedCertificateCancellations'" 
            [mainValue]="mainValueCertificateStock" 
            [mainValueUnit]="mainValueUnitCertificateStock" 
            [trend]="trendCertificateStock"
            [percentagePrevious]="percentagePreviousCertificateStock" 
            [percentage]="percentageCertificateStock"
            *ngIf="premiumCertificateLoaded">
        </app-small-kpi>
    </div>     
    <div class="ml-5">
        <component-loader 
            [width]="110" 
            [height]="55" 
            *ngIf="!premiumCertificateLoaded">
        </component-loader>
        <app-small-kpi 
            [title]="'panels.fixedPremiumCancellations'" 
            [mainValue]="mainValuePremiumStock" 
            [mainValueUnit]="mainValueUnitPremiumStock" 
            [trend]="trendPremiumStock"
            [percentagePrevious]="percentagePreviousPremiumStock" 
            [percentage]="percentagePremiumStock"
            *ngIf="premiumCertificateLoaded">
        </app-small-kpi>
    </div> 
</div>
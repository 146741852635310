<div class="portfolio-wrapper">
    
    <div class="mt-3" *ngIf="!loaded">
        <component-loader [width]="" [height]="230"></component-loader>
    </div>

    <div class="d-flex justify-content-start" *ngIf="loaded">
        <app-small-kpi [mainValue] = "mainValueKpi" [mainValueUnit] = "mainValueUnit" [percentagePrevious] = "percentagePrevious" [percentage] = "percentageKpi" [trend] = "trendKpi"></app-small-kpi>
        <mat-icon *ngIf="loaded" class="excel-icon ml-3 icon-margin" svgIcon="excel" (click)="exportAsXLSX()"></mat-icon>
    </div>
    <app-wok-graph *ngIf="loaded"
        [view]="view"
        [scheme]="colorScheme"
        [schemeType]="schemeType"
        [results]="evolutionGraph"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [xAxisTicks]="xAxisTicks"
        [animations]="animations"
        [showDataLabel] = "showDataLabel"
        [dataLabelFormatting] = "dataLabelFormatting"
        [noBarWhenZero] = "noBarWhenZero"
        [labelDiff] = "percentageKpi"
        [viewBox] = "viewBox"
        [dataLabelUnit] = "dataLabelUnit">
    </app-wok-graph>
</div>
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-semicircle-pie',
  templateUrl: './semicircle-pie.component.html',
  styleUrls: ['./semicircle-pie.component.scss']
})
export class SemicirclePieComponent implements OnInit {

  @Input() items: any[];
  @Input() kpiInfo: any;
  @Input() width: number = 200;

  height: number = this.width / 2;

  constructor() { }

  ngOnInit(): void {
    this.height = this.width / 2;
  }

}

<div class="d-flex justify-content-center">
    <component-loader class="mt-2" [width]="330" [height]="145" *ngIf="!pieGraphLoaded"></component-loader>
    <div class="clients-portfolio-distribution-pie-chart pie-graph-gen mt-1" *ngIf="pieGraphLoaded">
        <ngx-charts-pie-chart 
            [view]="viewPie" 
            [scheme]="colorSchemePie" 
            [customColors]="customColorsPieGraph"
            [results]="DistributionToPrint" 
            [gradient]="gradientPie"
            [legend]="showLegendPie"
            [legendTitle]="legendTitlePie" 
            [legendPosition]="legendPositionPie" 
            [labels]="showLabelsPie" 
            [doughnut]="isDoughnutPie"
            [arcWidth]="arcWidth"
            [tooltipDisabled]="tooltipDisabledPie"
            [animations]="animationsPie"
            [labelFormatting]="labelFormatting.bind(this, DistributionToPrint)"
            [customColors]="customColorsPieGraph"
            (activate)="activate($event)">
            <ng-template #tooltipTemplate let-model="model">
                <div class="pie-tooltip">
                    <span class="pie-tooltip-title d-block">{{ tooltipTitle }}</span>
                    <span class="pie-tooltip-data d-block">{{ tooltipNumber }}</span>
                </div>
            </ng-template>
        </ngx-charts-pie-chart>
        <span class="material-icons fullscreen-icon text-light" (click)="openPieChartDistributionDialog()" *ngIf="pieGraphLoaded">fullscreen</span>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-color-link',
  templateUrl: './color-link.component.html',
  styleUrls: ['./color-link.component.scss']
})
export class ColorLinkComponent implements OnInit {

  @Input() text: string = "";
  @Input() icon: string = "";
  @Input() color: string = "";

  constructor() { }

  ngOnInit(): void {
  }

}

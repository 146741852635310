<app-widget>

    <component-loader [width]="" [height]="40" *ngIf="!loaded"></component-loader>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2" *ngIf="loaded">
        <div class="d-flex">
            <span class="title section-card-title">{{ 'panels.claims' | translate }}</span>
            <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams"
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </div>
        <div class="dropdown-container dropdown-light">
            <app-dropdown
                [title]="title"
                [items]="qualityClaimReasons"
                [dropdownId]="dropdownId">
            </app-dropdown>
        </div>
    </div>

    <div class="mt-1">
        <app-sinisters-claims-velocimeter></app-sinisters-claims-velocimeter>
    </div>

    <div class="mt-1">
        <app-sinisters-claims-graphic></app-sinisters-claims-graphic>
    </div>

</app-widget>
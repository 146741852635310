import { Component, OnInit, OnDestroy } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { AccumulatedCancellationReasonRate } from 'src/app/shared/models/accumulated-cancellation-reason-rate';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { trim } from 'lodash';
import { CancellationReasonRate } from 'src/app/shared/models/cancellation-reason-rate';

@Component({
  selector: 'app-cancellation-reasons-graphic',
  templateUrl: './cancellation-reasons-graphic.component.html',
  styleUrls: ['./cancellation-reasons-graphic.component.scss']
})
export class CancellationReasonsGraphicComponent implements OnInit, OnDestroy {

  customColors: any = [];

  unsubscribe$: Subject<any> = new Subject<any>();
  monthNames: any;
  planName: string;
  month: number;
  year: number;
  xAxisDates: any = [];
  filtersValues: any;
  optionTabBonus: string = 'panels.bonus';
  optionTabPolicy: string = 'panels.policy';
  optionTabDiscreet: string = 'panels.discreet';
  optionTabAccumulated: string = 'panels.accumulated';
  bonusPolicySelectedOption: string = 'panels.bonus';
  bonusDiscreetAccumulatedSelectedOption: string = 'panels.discreet';
  graphName: string = 'cancellationReasons';

  // Options for chart creation
  view = [530, 130];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  showRightYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  legendTitle: string = '';
  legendPosition: string = '';
  showXAxisLabel: boolean = false;
  showYAxisLabel: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  animations: boolean = false;
  schemeType: string = 'ordinal';
  colorScheme = {
    domain: [COLORS.darkBlue, COLORS.lightBlue]
  };
  yLeftAxisScaleFactor: string = '';
  yRightAxisScaleFactor: string = '';
  showGridLines: boolean = true;
  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  barPadding: number = 30;
  tooltipDisabled: boolean = false;
  roundDomains: boolean = false;
  noBarWhenZero: boolean = false;
  lineChartScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkBlue, COLORS.lightBlue, '#ffcc00', '#ff66ff']
  };
  comboBarScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkBlue, COLORS.lightBlue]
  };
  rotateXAxisTicks: boolean = false;
  showTotalInTooltip: boolean = true;
  showPercentageSymbolInTooltip: boolean = true;

  lineChartSeries: any[] = [];
  barChart: any[] = [];
  dropdownOptions: any = [];
  loaded: boolean = false;

  voluntaryName: string;
  involuntaryName: string;
  companyDecisionName: string;
  churnRateName: string;
  accumulatedChurnRateName: string;

  cancellationReasonRate: any;
  premiumBarsData: any = [];
  certificateBarsData: any = [];
  premiumLinesData: any = [];
  certificateLinesData: any = [];

  dropdownSelectedValues: any = [];

  // Default constructor
  constructor(
    private filtersService: FiltersService,
    private eventsService: EventsService,
    private apimstService: ApimstService,
    private translateService: TranslateService
  ) { }

  // OnInit
  ngOnInit(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const months = this.translateService.get('abbreviatedMonthNames')
          const plan = this.translateService.get('labels.plan');
          const voluntary = this.translateService.get('labels.voluntary');
          const involuntary = this.translateService.get('labels.involuntary');
          const churnRate = this.translateService.get('labels.churnRate');
          const accChurnRate = this.translateService.get('labels.accumulatedChurnRate');
          const companyDecision = this.translateService.get('labels.companyDecision');

          forkJoin([months, plan, voluntary, involuntary, churnRate, accChurnRate, companyDecision])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              {
                next: results => {
                  this.monthNames = results[0];
                  this.planName = results[1];
                  this.voluntaryName = results[2];
                  this.involuntaryName = results[3];
                  this.churnRateName = results[4];
                  this.accumulatedChurnRateName = results[5];
                  this.companyDecisionName = results[6];

                  this.assignColorBar();
                },
                complete: () => {
                  if (data.length !== 0) {
                    this.filtersValues = data;
                    this.month = parseInt(this.filtersValues.filter(v => v.name === 'month')[0].value);
                    this.year = parseInt(this.filtersValues.filter(v => v.name === 'year')[0].value);
                    this.calculateXAxisDates();
                    this.retrieveCancellationReasonRate();
                  }
                }
              }
            );
        }
      );

    this.eventsService.filterDropdownEvent$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data['form_name'] === 'cancellationReasons') {
            if (data['form_action'] == "select") {
              this.addSelectedItem(data['item_id']);
              this.retrieveData();
            } else if (data['form_action'] == "deselect") {
              this.removeSelectedItem(data['item_id']);
              this.retrieveData();
            } else if (data['form_action'] == "selectAll") {
              this.dropdownSelectedValues = data['item_id'];
              this.retrieveData();
            } else if (data['form_action'] == "deselectAll"){
              this.dropdownSelectedValues = [];
              this.barChart = [];
              this.lineChartSeries = [];
            }
          }
        }
      );

    this.eventsService.policiesBonusOption$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const option = data[0];
          const name = data[1];
          if ((option === this.optionTabBonus || option === this.optionTabPolicy) && name === this.graphName) {
            this.bonusPolicySelectedOption = option;
            this.assignDataToPrint();
          }
        }
      );
  }

  // Method to retrieve the cancellation reason rate
  private retrieveCancellationReasonRate() {
    this.loaded = false;

    this.apimstService.getCancellationReasonMaster()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.calculateDropdownOptions(data);
        this.retrieveData();
      }
    );

  }

  // Method to retrieve data from mst
  private retrieveData() {
    this.loaded = false;
    const churnRate = this.apimstService.getCancellationReasonRate(this.dropdownSelectedValues, 'crg', this.filtersValues);
    const voluntaryInvoluntary = this.apimstService.getCancellationReasonRate(this.dropdownSelectedValues, 'crs', this.filtersValues);

    forkJoin([churnRate, voluntaryInvoluntary])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(results => {
      this.calculateAccumulatedDataGraph(results[0]);
      this.calculateVoluntaryInvoluntaryData(results[1]);
      this.assignDataToPrint();
      this.loaded = true;
    });
  }

  // Method for calculating the churn rate and the accumulated churn rate
  private calculateAccumulatedDataGraph(data: Array<AccumulatedCancellationReasonRate>) {
    this.premiumLinesData = [];
    this.certificateLinesData = [];

    let premiumChurnRateLineValues: any = [];
    let accumulatedPremiumChurnRateLineValues: any = [];
    let certificateChurnRateLineValues: any = [];
    let accumulatedCertificateChurnRateLineValues: any = [];
    let premiumTargetLineValues: any = [];
    let certificateTargetLineValues: any = [];

    const cancellationReasonRate = data;

    for (let date of this.xAxisDates) {
      const splitDate = date.split('/');
      const monthValue = splitDate[0];
      const yearValue = splitDate[1];

      let premiumCR = 0;
      let premiumACR = 0;
      let premiumTarget = 0;
      let certificateCR = 0;
      let certificateACR = 0;
      let certificateTarget = 0;

      const name = this.monthNames[monthValue] + ' ' + yearValue.slice(2, 4);

      for (let month of cancellationReasonRate) {
        if (month.month === monthValue && month.year === yearValue) {
          premiumCR = (month.churn_premium || trim(month.churn_premium).length > 0) ? parseFloat(month.churn_premium.replace(/,/g, '')) : 0;
          premiumACR = (month.avg_accumulated_churn_premium || trim(month.avg_accumulated_churn_premium).length > 0) ? parseFloat(month.avg_accumulated_churn_premium.replace(/,/g, '')) : 0;
          certificateCR = (month.churn_certificate || trim(month.churn_certificate).length > 0) ? parseFloat(month.churn_certificate.replace(/,/g, '')) : 0;
          certificateACR = (month.avg_accumulated_churn_certificate || trim(month.avg_accumulated_churn_certificate).length > 0) ? parseFloat(month.avg_accumulated_churn_certificate.replace(/,/g, '')) : 0;
          premiumTarget = (month.target_premium || trim(month.target_premium).length > 0) ? parseFloat(month.target_premium.replace(/,/g, '')) : 0;
          certificateTarget = (month.target_certificate || trim(month.target_certificate).length > 0) ? parseFloat(month.target_certificate.replace(/,/g, '')) : 0;
          break;
        }
      }

      premiumChurnRateLineValues.push(
        {
          name: name,
          value: premiumCR
        }
      );

      certificateChurnRateLineValues.push(
        {
          name: name,
          value: certificateCR
        }
      );

      accumulatedPremiumChurnRateLineValues.push(
        {
          name: name,
          value: premiumACR
        }
      );

      accumulatedCertificateChurnRateLineValues.push(
        {
          name: name,
          value: certificateACR
        }
      );

      premiumTargetLineValues.push(
        {
          name: name,
          value: premiumTarget
        }
      );

      certificateTargetLineValues.push(
        {
          name: name,
          value: certificateTarget
        }
      );

    }

    this.premiumLinesData.push(
      {
        name: this.churnRateName,
        series: premiumChurnRateLineValues
      },
      {
        name: this.accumulatedChurnRateName,
        series: accumulatedPremiumChurnRateLineValues
      },
      {
        name: this.planName,
        series: premiumTargetLineValues
      }
    );

    this.certificateLinesData.push(
      {
        name: this.churnRateName,
        series: certificateChurnRateLineValues
      },
      {
        name: this.accumulatedChurnRateName,
        series: accumulatedCertificateChurnRateLineValues
      },
      {
        name: this.planName,
        series: certificateTargetLineValues
      }
    );

  }

  // Method for calculating the voluntary and involuntary data
  private calculateVoluntaryInvoluntaryData(data: Array<CancellationReasonRate>) {
    this.premiumBarsData = [];
    this.certificateBarsData = [];

    let helper = {};
    let cancellationReasonRateByDate = data.reduce(function (r, o) {
      var key = o.month + '-' + o.year;
      if (!helper[key]) {
        helper[key] = data.filter(v => (v.month === o.month && v.year === o.year));
        r.push(helper[key]);
      }
      return r;
    }, []);

    for (let date of this.xAxisDates) {
      const splitDate = date.split('/');
      const monthValue = splitDate[0];
      const yearValue = splitDate[1];

      let premiumVoluntaryValue = 0;
      let premiumInvoluntaryValue = 0;
      let premiumCompanyDecisionValue = 0;
      let certificateVoluntaryValue = 0;
      let certificateInvoluntaryValue = 0;
      let certificateCompanyDecisionValue = 0;

      const name = this.monthNames[monthValue] + ' ' + yearValue.slice(2, 4);

      for (let month of cancellationReasonRateByDate) {
        if (month[0]?.month === monthValue && month[0]?.year === yearValue) {
          const voluntary = month.filter(v => v.name === 'Baja voluntaria');
          const involuntary = month.filter(v => v.name === 'Baja involuntaria');
          const companyDecision = month.filter(v => v.name === 'Decision de Compañía');

          if (voluntary.length !== 0) {
            premiumVoluntaryValue = (voluntary[0].premium || trim(voluntary[0].premium).length > 0) ? parseFloat(voluntary[0].premium.replace(/,/g, '')) : 0;
            certificateVoluntaryValue = (voluntary[0].certificate || trim(voluntary[0].certificate).length > 0) ? parseFloat(voluntary[0].certificate.replace(/,/g, '')) : 0;
          }

          if (involuntary.length !== 0) {
            premiumInvoluntaryValue = (involuntary[0].premium || trim(involuntary[0].premium).length > 0) ? parseFloat(involuntary[0].premium.replace(/,/g, '')) : 0;
            certificateInvoluntaryValue = (involuntary[0].certificate || trim(involuntary[0].certificate).length > 0) ? parseFloat(involuntary[0].certificate.replace(/,/g, '')) : 0;
          }

          if (companyDecision.length !== 0) {
            premiumCompanyDecisionValue = (companyDecision[0].premium || trim(companyDecision[0].premium).length > 0) ? parseFloat(companyDecision[0].premium.replace(/,/g, '')) : 0;
            certificateCompanyDecisionValue = (companyDecision[0].certificate || trim(companyDecision[0].certificate).length > 0) ? parseFloat(companyDecision[0].certificate.replace(/,/g, '')) : 0;
          }

          break;
        }
      }

      this.premiumBarsData.push(
        {
          name: name,
          series: [
            {
              name: this.voluntaryName,
              value: premiumVoluntaryValue
            },
            {
              name: this.involuntaryName,
              value: premiumInvoluntaryValue
            },
            {
              name: this.companyDecisionName,
              value: premiumCompanyDecisionValue
            },
          ]
        }
      );

      this.certificateBarsData.push(
        {
          name: name,
          series: [
            {
              name: this.voluntaryName,
              value: certificateVoluntaryValue
            },
            {
              name: this.involuntaryName,
              value: certificateInvoluntaryValue
            },
            {
              name: this.companyDecisionName,
              value: certificateCompanyDecisionValue
            },
          ]
        }
      );

    }
  }

  // Method for calculating the dropdown options
  private calculateDropdownOptions(data: any) {
    this.dropdownSelectedValues = [];
    this.dropdownOptions = [];
    for (let register of data) {
      this.dropdownOptions.push({
        item_text: register.description,
        item_id: register.id
      });

      this.dropdownSelectedValues.push(register.id);
    }

    this.eventsService.cancellationReasons$.emit(this.dropdownOptions);
  }

  // Method to assign data according to the selected option
  private assignDataToPrint() {
    if (this.bonusPolicySelectedOption === this.optionTabBonus) {
      this.barChart = this.premiumBarsData;
      this.lineChartSeries = this.premiumLinesData;
    } else if (this.bonusPolicySelectedOption === this.optionTabPolicy) {
      this.barChart = this.certificateBarsData;
      this.lineChartSeries = this.certificateLinesData;
    }
  }

  // Method to add an item to the selection list
  private addSelectedItem(item: string) {
    this.dropdownSelectedValues.push(item);
  }

  // Method to remove an item from the selection list
  private removeSelectedItem(item: string) {
    const index = this.dropdownSelectedValues.indexOf(item);
    if (index !== -1) {
      this.dropdownSelectedValues.splice(index, 1);
    }
  }

  // Method to assign the colors to the graph bars
  private assignColorBar() {
    this.customColors.push(
      {
        name: this.involuntaryName,
        value: COLORS.darkBlue
      },
      {
        name: this.voluntaryName,
        value: COLORS.lightBlue
      },
      {
        name: this.companyDecisionName,
        value: COLORS.darkYellow
      },
      {
        name: this.planName,
        value: COLORS.lightestBlue
      },
      {
        name: this.accumulatedChurnRateName,
        value: COLORS.lightOrange
      },
      {
        name: this.churnRateName,
        value: COLORS.darkGreen
      },
      {
        name: 'Total',
        value: COLORS.black
      })
  }

  // Method to format the right y axis labels with '%'
  yRightAxisTickFormatting(val) {
    return Intl.NumberFormat("de-DE").format(val).toLocaleString() + '%';
  }

  // Method to format the right y axis labels
  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value);
  }

  // Method to calculate all the days from the previous and current filtered month
  private calculateXAxisDates() {
    this.xAxisDates = [];
    let originalDate = new Date(this.year, this.month-13);

    for (let _i = 0; _i < 13; _i++) {
      const month = (originalDate.getMonth()+1).toString().length < 2 ? '0'+(originalDate.getMonth()+1) : (originalDate.getMonth()+1);
      this.xAxisDates.push(month + '/' + originalDate.getFullYear().toString());
      originalDate.setMonth(originalDate.getMonth() + 1);
    }

  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

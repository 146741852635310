import { Component, Input, OnInit } from '@angular/core';
import { EventsService } from 'src/app/shared/services/events/events.service';

@Component({
  selector: 'app-info-panel-list-item',
  templateUrl: './info-panel-list-item.component.html',
  styleUrls: ['./info-panel-list-item.component.scss']
})
export class InfoPanelListItemComponent implements OnInit {
  @Input() item: any;

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
  }

  goToTechicalInfo(id: string) {
    const data = {
      breadcrumbAction: 'add',
      id: id
    }
    this.eventsService.networkGraphSelectedNode$.emit(data);
  }

}

export var bubbleData = [
    {
        name: '',
        series: [
            {
                name: '',
                x: 'Platinum',
                y: 'Preferente',
                r: 8000
            },
            {
                name: '',
                x: 'Gold',
                y: 'Premier',
                r: 2000
            },
            {
                name: '',
                x: 'Gold',
                y: 'Preferente',
                r: 9000
            },
            {
                name: '',
                x: 'Silver',
                y: 'Preferente',
                r: 12000
            },
            {
                name: '',
                x: 'Bronze',
                y: 'Pyme',
                r: 9000
            },
            {
                name: '',
                x: 'Bronze',
                y: 'Otros',
                r: 9000
            },
            {
                name: '',
                x: 'Otro',
                y: 'Otros',
                r: 15000
            }
        ]
    }
];

<component-loader [width]="" [height]="250" *ngIf="!loaded"></component-loader>
<div class="d-flex justify-content-end" *ngIf="loaded">
    <app-legend 
        name={{legend.name}} 
        color={{legend.color}} 
        type={{legend.type}} 
        gradient={{legend.gradient}}
        *ngFor="let legend of legends">
    </app-legend>
</div>
<div class="hide-horizontal-line hide-horizontal-line-clients-tnps d-flex justify-content-center mt-4" *ngIf="loaded">
    <grouped-vertical-horizontal-graph-d-axis
        [view]="view"
        [scheme]="colorScheme"
        [results]="barSeries"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [barPadding]="barPadding"
        [groupPadding]="groupPadding"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [legendTitle]="legendTitle"
        [lineChart]="lineSeries"
        [showGridLines]="showGridLines"
        [showRightGridLines]="showRightGridLines"
        [customColors]="customColors"
        [roundEdges]="roundEdges"
        [activeEntries]="activeEntries"
        [trimXAxisTicks]="trimXAxisTicks"
        [trimYAxisTicks]="trimXAxisTicks"
        [rotateXAxisTicks]="rotateXAxisTicks"
        [maxXAxisTickLength]="maxXAxisTickLength"
        [maxYAxisTickLength]="maxYAxisTickLength"
        [tooltipDisabled]="tooltipDisabled"
        [roundDomains]="roundDomains"
        [yRightAxisTickFormatting]="yRightAxisTickFormatting"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [onlyPoints]="onlyPoints"
        [synchronizedYAxis]="synchronizedYAxis"
        [showPercentageSymbolInTooltip]="showPercentageSymbolInTooltip">
    </grouped-vertical-horizontal-graph-d-axis>
</div>
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { EventsService } from '../../services/events/events.service';
import { LogoutDialogComponent } from '../logout-dialog/logout-dialog.component';

@Component({
  selector: 'session-expired-dialog',
  templateUrl: './session-expired-dialog.component.html',
  styleUrls: ['./session-expired-dialog.component.scss']
})
export class SessionExpiredDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LogoutDialogComponent>,
    private cookieService: CookieService,
    private events: EventsService
  ) { }

  ngOnInit(): void {
  }

  refreshPage(): void {
    console.log('Session Expired...');
    this.cookieService.delete('iSession', '/');
    this.cookieService.delete('jSessionID', '/');
    this.events.forceCheckLogin$.emit('');
  }

}

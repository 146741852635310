import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COLORS } from 'src/app/shared/globals/globals';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-clients-voc-grouped-graphic',
  templateUrl: './clients-voc-grouped-graphic.component.html',
  styleUrls: ['./clients-voc-grouped-graphic.component.scss']
})
export class ClientsVocGroupedGraphicComponent implements OnInit {

  loaded: boolean = false;

  multi: any[] = [];
  barSeries: any = [];
  lineSeries: any = [];
  view: any[] = [680, 200];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  legendTitle: string = '';
  legendPosition: string = '';
  showXAxisLabel: boolean = false;
  showYAxisLabel: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  yLeftAxisScaleFactor: string = '';
  yRightAxisScaleFactor: string = '';
  showGridLines: boolean = true;
  showRightGridLines: boolean = true;
  innerPadding: string = '10%';
  animations: boolean = false;
  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  tooltipDisabled: boolean = false;
  schemeType: string = "ordinal";
  roundDomains: boolean = true;
  noBarWhenZero: boolean = true;
  customColors: any = [];
  roundEdges: boolean = false;
  activeEntries: any = [];
  trimXAxisTicks: boolean = true;
  trimYAxisTicks: boolean = true;
  rotateXAxisTicks: boolean = false;
  maxXAxisTickLength: number = 10;
  maxYAxisTickLength: number = 16;
  barPadding: number = 8;
  groupPadding: number = 15;
  onlyPoints: boolean = true;
  synchronizedYAxis: boolean = true;
  showPercentageSymbolInTooltip: boolean = false;

  yScaleMin:number = -80
  yScaleMax:number = 80

  colorScheme = {
    domain: [COLORS.darkOrange, COLORS.lightBlue, COLORS.darkYellow]
  };
  colorListCSS: any = ['dark-orange', 'light-blue', 'dark-yellow'];

  monthsNames: any;
  targetName: string = "";

  legends = [
    {
      name: 'Jul19',
      color: this.colorListCSS[0],
      type: 'line',
      gradient: 'v-gradient'
    },
    {
      name: 'Jul20',
      color: this.colorListCSS[1],
      type: 'line',
      gradient: 'v-gradient'
    },
    {
      name: 'Objetivo',
      color: this.colorListCSS[2],
      type: 'round',
      gradient: 'v-gradient'
    }
  ];

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any[];

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.subscribeToFiltersValues();
  }

  subscribeToFiltersValues(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: filtersValues => {

          this.resetValues();
          this.getTranslations();

          this.filtersValues = filtersValues;
          this.loaded = false;
          this.retrieveClientsClaimsGraph("ctny", this.filtersValues);
        }
      })
  }

  resetValues() {
    this.multi = [];
    this.legends = [];
    this.barSeries = [];
    this.lineSeries = [];
  }

  getTranslations() {
    const monthsTranslations = this.translateService.get('abbreviatedMonthNames');
    const targetTranslation = this.translateService.get('labels.target');

    forkJoin([monthsTranslations, targetTranslation])
      .subscribe({
        next: translations => {
          this.monthsNames = translations[0];
          this.targetName = translations[1];
        }
      })
  }

  private retrieveClientsClaimsGraph(type_report: string, filtersValues: any) {
    this.apimstService.getClientsQualityClaims(type_report, filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: data => {
          this.loaded = true;

          if (data == null) return;

          this.multi = this.handleData(data);

          this.barSeries = this.handleResponseData(data)[0];
          this.lineSeries =  this.handleResponseData(data)[1];
      
          this.legends = this.createLegends(this.barSeries);

        }
      });
  }

  createLegends(barSeries) {
    let legends: any[] = barSeries[0].series.map((item, index) =>
    ({
      name: item.name,
      color: this.colorListCSS[index],
      type: 'line',
      gradient: 'v-gradient'
    }))
    legends.push(
      {
        name: this.targetName,
        color: 'dark-yellow',
        type: 'line',
        gradient: 'v-gradient'
      }
    )
    return legends;
  }

  handleResponseData(data: any[] = []) {
    const filteredValues = this.filterValues(data);
    const numericValues = this.setNumericValues(filteredValues);
    const barSeries = this.createBarSeries(numericValues);
    const lineSeries = this.createLineSeries(numericValues);
    return [barSeries, lineSeries];
  }

  handleData(data: any[] = []) {
    const filteredValues = this.filterValues(data);
    const numericValues = this.setNumericValues(filteredValues);
    const series = this.createSeries(numericValues);
    return [];
  }

  filterValues(values: any[] = []) {
    return values.filter(v => v.name != "No asignado" && v.name != "No aplica");
  }

  setNumericValues(values: any[] = []) {
    return values.map(v => ({
      name: v.name,
      value: v.rate && v.rate != "" ? parseFloat(v.rate) : 0,
      value_previous_year: v.rate_previous_year && v.rate_previous_year != "" ? parseFloat(v.rate_previous_year) : 0,
      target: v.target && v.target != "" ? parseFloat(v.target) : 0,
    }));
  }

  createBarSeries(values: any[] = []) {
    const series = values.map( value => (
      {
        name: value.name,
        series: [
          {name: `${this.getMonthName()}${this.getPreviousYearFilterValue().slice(2, 4)}`, value: value.value_previous_year},
          {name: `${this.getMonthName()}${this.getYearFilterValue().slice(2, 4)}`, value: value.value},
        ]
      }
    ))
    return series;
  }

  createLineSeries(values: any[] = []) {
    let initSeries = [{ name: this.targetName, series: [] }];
    const series = values.map( value => (
      {
        name: value.name,
        value: value.target,
      }
    ));

    initSeries[0].series = series;

    return initSeries;
  }

  createSeries(values: any[] = []) {
    let previousYearSeries: any[] = [];
    let actualYearSeries: any[] = [];
    let targetSeries: any[] = [];

    let series = [
      { name: `${this.getMonthName()}${this.getPreviousYearFilterValue().slice(2, 4)}`, series: [] },
      { name: `${this.getMonthName()}${this.getYearFilterValue().slice(2, 4)}`, series: [] },
      { name: this.targetName, series: [] },
    ]

    for (let value of values) {
      previousYearSeries.push({ name: value.name, value: value.value_previous_year })
      actualYearSeries.push({ name: value.name, value: value.value })
      targetSeries.push({ name: value.name, value: value.target })
    }

    series[0].series = previousYearSeries;
    series[1].series = actualYearSeries;
    series[2].series = targetSeries;

    return series;
  }

  getMonthFilterValue(filtersValues: any[] = []) {
    return filtersValues.filter(f => f.name == "month")[0].value;
  }

  getMonthName() {
    const index = this.filtersValues.filter(f => f.name == "month")[0].value;
    return this.monthsNames[index];
  }

  getYearFilterValue() {
    return this.filtersValues.filter(f => f.name == "year")[0].value;
  }

  getPreviousYearFilterValue() {
    return String(parseInt(this.filtersValues.filter(f => f.name == "year")[0].value) - 1);
  }

  onSelect(event) {
    // console.log(event);
  }

  yRightAxisTickFormatting(val) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return  formatPipe.transform(val); 
  }

  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<svg:g
    *ngFor="let group of series; let index = index;"
    [@animationState]="'active'"
    [attr.transform]="groupTransform(group)"
    >
    <svg:g
    ngx-charts-series-vertical
    type="stacked"
    [xScale]="xScale"
    [yScale]="yScale"
    [activeEntries]="activeEntries"
    [colors]="colors"
    [series]="group.series"
    [dims]="dims"
    [gradient]="gradient"
    [tooltipDisabled]="tooltipDisabled"
    [showDataLabel]="showDataLabel"
    [dataLabelFormatting]="dataLabelFormatting"
    [seriesName]="group.name"
    [animations]="animations"
    [noBarWhenZero]="noBarWhenZero"
    [roundEdges]="roundEdges"
    />
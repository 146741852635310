import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COLORS } from 'src/app/shared/globals/globals';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import * as _ from 'lodash';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { tableInfo } from './data';
import { TranslateService } from '@ngx-translate/core';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { MatDialog } from '@angular/material/dialog';
import { ColorTableDialogComponent } from 'src/app/shared/dialogs/color-table-dialog/color-table-dialog.component';
import { EventsService } from 'src/app/shared/services/events/events.service';

@Component({
  selector: 'app-sinisters-closed-per-month-table',
  templateUrl: './sinisters-closed-per-month-table.component.html',
  styleUrls: ['./sinisters-closed-per-month-table.component.scss']
})
export class SinistersClosedPerMonthTableComponent implements OnInit {

  loaded: boolean = false;

  headerIndicators: any[] = [
    { name: "1" },
    { name: "2" },
    { name: "3" },
    { name: "4" },
    { name: "5" },
    { name: "6" },
    { name: "7" },
    { name: "8" },
    { name: "9" },
    { name: "10" },
    { name: "11" },
    { name: "12" },
    { name: "13" },
    { name: "14" },
    { name: "15" },
    { name: "16" },
    { name: "17" },
    { name: "18" },
    { name: "19" },
    { name: "20" },
    { name: "21" },
    { name: "22" },
    { name: "23" },
    { name: "24" },
  ]

  tableInfo: any[] = tableInfo;
  rawInfo: any[] = [];

  itemsNumberLimit: number = 24;

  monthsNames: any[] = []

  colorScheme: any[] = [
    COLORS.tableBeige,
    COLORS.tableLightestOrange,
    COLORS.tableLightOrange,
    COLORS.tableMediumOrange,
    COLORS.tableDarkOrange,
    COLORS.tableGray,
    COLORS.tableDarkBlue,
    COLORS.tableMediumBlue,
    COLORS.tableLightBlue,
    COLORS.tableLightestBlue,
    COLORS.tableLightestGreen,
    COLORS.tableDarkGreen
  ]

  filtersValues: any[] = [];

  unsubscribe$: Subject<any> = new Subject<any>();

  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  @Output() tableLoaded = new EventEmitter<boolean>();

  constructor(
    private filtersService: FiltersService,
    private apiService: ApimstService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    this.multiplyColorScheme();
    this.subscribeToFilters();
    this.assignColors();
    this.detectTableZoomEvent();
  }

  subscribeToFilters() {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: filtersValues => {
          this.filtersValues = filtersValues;
          if (this.filtersValues.length > 0) {
            this.resetData();
            this.getTranslations();
            this.retrieveSinistersClosedPerMonth(this.filtersValues);
          }
        }
      })
  }

  getTranslations() {
    const months = this.translateService.get('abbreviatedMonthNames');
    forkJoin([months])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: translations => {
          this.monthsNames = translations[0];
        }
      })
  }

  resetData() {
    this.tableInfo = [];
  }

  retrieveSinistersClosedPerMonth(filtersValues: any[] = []) {
    this.loaded = false;

    this.apiService.getSinistersClosedPerMonth(filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: data => {
          this.rawInfo = data;

          this.loaded = true;
          this.tableLoaded.emit(true);
          if (this.isNotValidData(this.rawInfo)) return;

          this.createTableInfo(this.rawInfo);
          this.assignColors();

        }
      })

  }

  createTableInfo(info: any[] = []) {
    const groupedArrayByRef = _.chain(info).groupBy("ref_date").map((value, key) => ({ date: key, values: value })).value();
    let tempArray: any[] = [];

    for (let month of groupedArrayByRef) {
      let values: any[] = [];
      values.push({ name: this.monthsNames[month.date.slice(5, 7)] + month.date.slice(2, 4) });
      values.push({
        name: month.values[0].value != "" ? this.formatPipe.transform(parseFloat(month.values[0].value.replace(/,/g, ''))) : "0"
      });
      month.values.forEach((value, index) => {
        if(index < this.itemsNumberLimit) {
          values.push({
            name: value.percentage != "" ? this.formatPipe.transform(parseFloat(value.percentage.replace(/,/g, ''))) : "0"
          })
        } else { return }
      });
      tempArray.push({ values: values })
    }
    this.tableInfo = tempArray;
  }

  multiplyColorScheme() {
    this.colorScheme = [...this.colorScheme, ...this.colorScheme, ...this.colorScheme];
  }

  assignColors() {
    this.tableInfo.forEach((value, indexTable) => {
      let initialColor = this.colorScheme[indexTable];
      value.values.forEach((value, indexValue) => {
        if (indexValue != 0 && indexValue != 1) {
          value.bgColor = initialColor;
          initialColor = this.getColorNext(initialColor);
        }
        if (indexValue == 0) value.bgColor = initialColor;
        if (indexValue == 1) value.bgColor = COLORS.white;
      });
    });
  }

  getColorNext(color: string) {
    let nextColor: string = "";
    for (let i = 0; i < this.colorScheme.length; i++) {
      if (this.colorScheme[i] == color) {
        nextColor = this.colorScheme[i + 1];
        return nextColor;
      }
    }
    return nextColor;
  }

  isNotValidData(data: any) {
    return data == null || data == undefined || typeof data == "undefined";
  }

  private detectTableZoomEvent() {
    this.eventsService.tableColorZoom$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      data => {
        if (data) {
          this.openTableZoom();
        }
      }
    );
  }

  private openTableZoom() {
    this.dialog.open(ColorTableDialogComponent, {
      data : {
        tableInfo: this.tableInfo,
        headerIndicators: this.headerIndicators
      },
      panelClass: 'table-color-dialog'
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Component, OnInit } from '@angular/core';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { environment } from 'src/environments/environment';
import { ClientsVocClaimsService } from '../clients-voc-claims/clients-voc-claims.service';
import { ClientsVocNpsService } from './clients-voc-nps.service';

@Component({
  selector: 'app-clients-voc-nps',
  templateUrl: './clients-voc-nps.component.html',
  styleUrls: ['./clients-voc-nps.component.scss']
})
export class ClientsVocNpsComponent implements OnInit {
  
  surveyTypeLoaded: boolean = false;

  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "clients-voc--tnps";

  unsubscribe$: Subject<any> = new Subject<any>();

  graphName: string = '';
  panelOptions: any[] = [];

  selectorTitle: string = "";
  clientsClaimReasons: any[] = [];
  clientsDropdownId: string = "clientsQualityNPS";

  clientsTnpsItems: any[] = [];
  selectedClientsTnpsItems: any[] = [];
  selectedClientsTnpsItemsLength: number = 0;

  clientsSurveysDropdownTitle: string = "";
  itemsClientsSurveys: any[] = [
    { id: "Asistencia", type: "clientSurvey", name: "Asistencia", selected: true },
    { id: "Cancelación", type: "clientSurvey", name: "Cancelación", selected: false },
    { id: "Siniestros", type: "clientSurvey", name: "Siniestros", selected: false },
    { id: "Venta", type: "clientSurvey", name: "Venta", selected: false },
    { id: "Reclamación", type: "clientSurvey", name: "Reclamación", selected: false },
  ]

  constructor(
    private i18n: I18nService,
    private atlasHelper: AtlasHelperService,
    private clientsVocNpsService: ClientsVocNpsService,
    private eventsService: EventsService,
    private filtersService: FiltersService,
    private apimstService: ApimstService
  ) { }

  ngOnInit(): void {
    this.subscribeToActiveLanguage();
    this.subscribeToFiltersValues();
    this.setSelectedReason(this.clientsVocNpsService.surveyTypeDefaultValue);
  }

  subscribeToFiltersValues() {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: filtersValues => {
          this.surveyTypeLoaded = false;

            this.apimstService.getClientsQualityClaims("ctne", filtersValues)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: data => {
                setTimeout(() => {
                  this.surveyTypeLoaded = true;
                  this.resetValues();
                  data = this.getClaimReasonsMaster(data);
                  this.itemsClientsSurveys = this.getClaimReasonsFormatted(data);
                  this.setSelectedReason(this.itemsClientsSurveys[0].id);
                }, 25);
              }
            })
        }
      })
  }

  resetValues() {
    this.itemsClientsSurveys = [];
  }

  getClaimReasonsMaster(data: any[] = []) {
    const array = data
      .filter(item => item.name != "No asignado" && item.name != "No aplica")
      .map(item => item.name)
    return [...new Set(array)];
  }

  getClaimReasonsFormatted(data: any[] = []) {
    return data.map(
      item => ({
        id: item,
        type: "clientSurvey",
        name: item,
        selected: false
      })
    )
  }

  setSelectedReason(option: string = "") {
    this.clientsVocNpsService.setSelectedReason(option);
  }

  updateSelectedItems(option) {
    let selectedClientsTnpsItems = this.selectedClientsTnpsItems;
    switch (option.item.form_action) {
      case 'selectAll':
        selectedClientsTnpsItems = option.items;
        break;
      case 'select':
        if (selectedClientsTnpsItems.filter(n => n.item_id == option.item.item_id).length == 0) {
          selectedClientsTnpsItems.push(option.item)
        }
        break;
      case 'deselect':
        selectedClientsTnpsItems = selectedClientsTnpsItems.filter(n => n.item_id != option.item.item_id);
        break;
      case 'deselectAll':
        selectedClientsTnpsItems = [];
        break;
    }
    return selectedClientsTnpsItems;
  }

  getSelectionIds(selectedItems: any[] = []) {
    return selectedItems.map(item => item.item_id )
  }

  // subscribeToClientsClaimsReasonsMaster(): void {
  //   this.clientsVocNpsService.getSurveyTypeMaster()
  //   .pipe(takeUntil(this.unsubscribe$))
  //   .subscribe({
  //     next: data => {
  //       this.surveyTypeLoaded = true;

  //       this.clientsTnpsItems = this.setClaimsReasonsSelectorValues(data);
  //       this.selectedClientsTnpsItems = this.setClaimsReasonsSelectorValues(data);
  //       this.selectedClientsTnpsItemsLength = this.selectedClientsTnpsItems.length;
  //     }
  //   })
  // }

  // setClaimsReasonsSelectorValues(surveyMaster: any[] = []) {
  //   let reasonsList: any = [];
  //   for (let item of surveyMaster) {
  //     if (item.survey_subtype_id != "-1" && item.survey_subtype_id != "-2") {
  //       reasonsList.push({
  //         item_id: item.survey_subtype_id,
  //         item_text: `${item.survey_type_desc} - ${item.survey_subtype_desc}`
  //       })
  //     }
  //   }
  //   return reasonsList;
  // }

  subscribeToActiveLanguage() {
    this.i18n.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: language => {
          this.activeLang = language;
          this.updateAtlasInfoParams(this.pageId, this.activeLang);
        }
      })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Component, OnInit } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { ExcelFileInfo } from 'src/app/shared/models/excel-file-info';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import _ from 'lodash';

@Component({
  selector: 'app-gwp',
  templateUrl: './gwp.component.html',
  styleUrls: ['./gwp.component.scss']
})
export class GwpComponent implements OnInit {
  
  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "sale-mix-portfolio-gwp--gwp";

  loaded: boolean = false;

  excelDataLoaded: ExcelFileInfo;

  subscription: Subscription;

  leftValue: string = '6,4K';
  leftPercentage: number = 8;

  // Options for chart creation
  view = [1240, 205];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  legendTitle: string = '';
  legendPosition: string = '';
  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  yAxisTickFormatting: string = '';
  yLeftAxisScaleFactor: string = '';
  yRightAxisScaleFactor: string = '';
  yRightAxisTickFormatting: string = '';
  showGridLines: boolean = true;
  innerPadding: string = '10%';
  animations: boolean = false;
  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  tooltipDisabled: boolean = false;
  schemeType: string = "ordinal";
  roundDomains: boolean = false;
  noBarWhenZero: boolean = true;
  barChart: any[] = [];
  lineChartSeries: any[] = [];
  lineChartScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.lightPurple, COLORS.darkYellow, COLORS.lightBlue, '#08ded1']
  };
  comboBarScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.lightBlue, COLORS.lightBlue, '#085da6', '#0869aa']
  };

  // Default constructor
  constructor(
    private excelService: ExcelService,
    private events: EventsService,
    private filtersService: FiltersService,
    private atlasHelper: AtlasHelperService,
    private i18n: I18nService
  ) { }

  // OnInit
  ngOnInit(): void {
    this.subscribeToActiveLanguage();
    this.subscribeToExcelInfo();
    this.subscribeToFiltersValues();
  }

  subscribeToExcelInfo(): void {
    this.subscription = this.events.gwpExcelInfoLoaded$
      .subscribe({
        next: excelDataLoaded => {
          this.loaded = false;

          if (!excelDataLoaded.json) return;

          this.excelDataLoaded = excelDataLoaded;
          this.loaded = true;

        }
      })
  }

  subscribeToFiltersValues(): void {
    this.subscription = this.filtersService.filtersValues$
      .subscribe({ next: filters => this.loaded = false })
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.excelDataLoaded.json, this.excelDataLoaded.headers, this.excelDataLoaded.excelFileName, this.excelDataLoaded.filtersValues);
  }

  subscribeToActiveLanguage() {
    this.subscription = this.i18n.language$.subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

<div class="technical-container light-background">
    <app-dg-header [headerTitle]="headerTitle" [headerSubtitle]="headerSubtitle" [area]="area" [routerLink]="routerLink" [hasSearchButton]="true"></app-dg-header>

    <app-technical-upper-menu [menuOptions]="upperMenuOptions"></app-technical-upper-menu>

    <app-technical-search-bar *ngIf="technicalSearchBarLoaded" [title]="technicalSearchBarTitle" [lineColor]="technicalSearchBarColor" [results]="results"></app-technical-search-bar>

    <component-loader *ngIf="!technicalSearchBarLoaded" class="technical-search-bar-loaded" [height]="639" [width]="240" [lightStyle]="true"></component-loader>

    <app-breadcrumbs *ngIf="graphLoaded" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

    <app-info-panel *ngIf="graphLoaded && entityInfo" [title]="entityTitle" [subtitle]="entityType" [headerColor]="entityColor" [headerBgColor]="entityColor"
        bgColor="#ffffff" [tabElements]="tabElements">
    </app-info-panel>

    <app-network-graph *ngIf="graphLoaded" [data]="data" [onlyNodes]="onlyNodes" [width]="entityInfo ? reducedWidth : width" [height]="height"></app-network-graph>

    <component-loader *ngIf="!graphLoaded" [height]="575" [width]="1040" [lightStyle]="true"></component-loader>

</div>

<div class="app-loader" *ngIf="!loaded">
    <base-loader></base-loader>
</div>

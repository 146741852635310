<div class="color-bar">
    <span class="name text-white small">{{ title }}</span>
    <div class="bar">
        <span class="bar-block" [ngStyle]="{'background-color': item.color,'flex-basis.%': item.percentage}" *ngFor="let item of items"></span>
        <div class="bg-bar-container">
            <span class="bg-bar-line"></span>
            <span class="bg-bar-line"></span>
            <span class="bg-bar-line"></span>
            <span class="bg-bar-line"></span>
        </div>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COLORS } from 'src/app/shared/globals/globals';
import { rejectionEvolutionIndicator } from 'src/app/shared/models/rejection-evolution-indicator';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-rejections-walk',
  templateUrl: './rejections-walk.component.html',
  styleUrls: ['./rejections-walk.component.scss']
})
export class RejectionsWalkComponent implements OnInit {

  @Input() percentageKpi: string;
  @Input() evolutionGraph: rejectionEvolutionIndicator[];

  translations: any[] = ['panels.paymentZero', 'panels.admin', 'panels.rTechnical'];
  paymentZeroName: string = "";
  adminName: string = "";
  rTechnicalName: string = "";

  type_data: string;
  type_report: string;

  evolutionGraphLoaded: boolean = false;

  // evolutionGraph: rejectionEvolutionIndicator[] = [];
  
  unsubscribe$: Subject<any> = new Subject<any>();

  filtersValues: any;
  
  view: any[] = [470, 100];
  schemeType: string = 'ordinal';
  gradient: boolean = true;
  showXAxis: boolean = true;
  showYAxis: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  showYAxisLabel: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  xAxisTicks: any;
  animations: boolean = false;
  showDataLabel: boolean = true;
  dataLabelFormatting: any;
  noBarWhenZero: boolean = false;
  rotateXAxisTicks: boolean = false;
  colorScheme = {
    domain: [COLORS.darkGreen, COLORS.walkRed, COLORS.walkGreen, COLORS.lightGreen]
  };
  customColors: any = [];

  // percentageKpi: string;
  viewBox: any = [0, 0, 300, 6];
  textLabel: string = '';
  
  constructor(
    private translateService: TranslateService,
    private filtersService: FiltersService
  ) { }

  ngOnInit(): void {

    this.evolutionGraphLoaded = false;

    // this.filtersService.filtersValues$
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe({
    //     next: data => {
    //         // this.clearInfoCopies();

    //         if ( data.length == 0 ) return;
    //         this.filtersValues = data;

    //         // this.getSelectedOption();
    //         this.retrieveRejectionEvolution(this.type_data, this.filtersValues);
    //       }
    //     }
    //   );

    // this.translateService.get(this.translations)
    //   .subscribe({
    //     next: names => {
    //       this.paymentZeroName = names['panels.paymentZero'];
    //       this.adminName = names['panels.admin'];
    //       this.rTechnicalName = names['panels.rTechnical'];
  
    //       this.retrieveRejectionEvolution(this.type_data, this.filtersValues);
    //     }
    //   })

  }

  retrieveRejectionEvolution(type_data: string, filtersValues: any): void {
    this.evolutionGraphLoaded = false;
    
    setTimeout(() => {
      this.createEvolutionGraph();
  
      this.evolutionGraphLoaded = true;
    }, 3000);

  }

  createEvolutionGraph(): void {
    this.evolutionGraph = [
      {name: "Feb 2020", value: 1.68},
      {name: this.paymentZeroName, value: -0.5},
      {name: this.adminName, value: -0.73},
      {name: this.rTechnicalName, value: -0.06},
      {name: "Feb 2021", value: 0.38},
    ]

    // this.percentageKpi = "1.5";
  }

  formatDataLabel(value) {
    return value + '%';
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

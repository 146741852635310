import { Injectable } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ICONS } from 'src/app/shared/globals/globals';

@Injectable({
  providedIn: 'root'
})
export class IconsService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { }

    public registerIcons(): void {
      this.loadIcons(Object.values(ICONS), '../../../../assets/icons');
    }
  
    private loadIcons(iconKeys: string[], iconUrl: string): void {
      for(let icon of iconKeys) {
        // console.log(icon);
        this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${icon}.svg`));
      }
    }
}

<div class="editor-container">
    <angular-editor [(ngModel)]="htmlContent" [config]="config"></angular-editor>
    <div class="">
        <div class="justify-content-end row mt-4 px-2">
            <div class="px-2">
                <button type="button" class="btn btn-block btn-bg-dark mt-0" (click)="saveEdition()">{{ 'buttons.save' | translate}}</button>
            </div>
            <div class="px-2">
                <button type="button" class="btn btn-block btn-bg-light mt-0" (click)="cancelEdition()">{{ 'buttons.cancel' | translate}}</button>
            </div>
        </div>
    </div>
</div>

<div class="dialog">
    <div class="wrapper_grid">
        <app-widget>
            <p class="section-title">
                <!-- {{ 'panels.monthlySale' | translate }} -->
                {{ sectionTitle | translate }}
                <span class="material-icons ml-2 fullscreen-icon" [mat-dialog-close]="true" >fullscreen_exit</span>
            </p>

            <!-- <div class="legend-row d-flex justify-content-end align-items-center">
                <app-legend class="mr-2" name="OM" color="light-blue" type="round" gradient="v-gradient"></app-legend>
                <app-legend class="mr-2" name="CR" color="dark-blue" type="round" gradient="v-gradient"></app-legend>
                <app-legend class="mr-2" name="Plan" color="dark-yellow" type="line" gradient="v-gradient"></app-legend>
                <app-legend class="mr-2" name="Media" color="light-purple" type="line" gradient="v-gradient"></app-legend>
            </div> -->

            <div class="legends-container d-flex justify-content-end align-items-center mb-1 w-100" *ngIf="dialogLegends">
                <app-legend 
                    class="mr-2 monthly-months-legend-dialog" 
                    [name]="dialogLegend.name" 
                    [color]="dialogLegend.color" 
                    [type]="dialogLegend.type" 
                    [gradient]="dialogLegend.gradient"
                    *ngFor="let dialogLegend of dialogLegends.slice(0, 7)">
                </app-legend>

                <div appTooltip class="d-flex" *ngIf="dialogLegends.length != 0">
                    <span class="material-icons text-white btn-legends-tooltip ml-1" *ngIf="dialogLegends.length > 7">more</span>
                      <ng-template #tooltipTemplate>   
                          <div class="tooltip tooltip-monthly-months tooltip-bottom-left">
                              <div class="tooltip-monthly-months-legends d-flex flex-wrap align-items-center">
                                <app-legend *ngFor="let item of dialogLegends" name={{item.name}} color={{item.color}} type={{item.type}} gradient={{item.gradient}}></app-legend>
                              </div>
                          </div>    
                      </ng-template>  
                  </div>

            </div>

            <div>
                <stacked-vertical-horizontal-graph
                [view]="view"
                [scheme]="comboBarScheme"
                [colorSchemeLine]="lineChartScheme"
                [results]="barChart"
                [animations]="animations"
                [lineChart]="lineChartSeries"
                [yAxisTickFormatting]="yAxisTickFormatting"
                [yLeftAxisScaleFactor]="yLeftAxisScaleFactor"
                [yRightAxisScaleFactor]="yRightAxisScaleFactor"
                [yRightAxisTickFormatting]="yRightAxisTickFormatting"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [legendTitle]="legendTitle"
                [legendPosition]="legendPosition"
                [showGridLines]="showGridLines"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [showRightYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [tooltipDisabled]="tooltipDisabled"
                [barPadding]="barPadding"
                [showGridLines]="showGridLines"
                [schemeType]="schemeType"
                [roundDomains]="roundDomains"
                [noBarWhenZero]="noBarWhenZero"
                [customColors]="customColors"
                [showTotalInTooltip]="showTotalInTooltip">
                </stacked-vertical-horizontal-graph>
            </div>
        </app-widget>
    </div> 
</div>   
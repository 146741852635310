import { Component, OnInit, OnDestroy } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { takeUntil } from 'rxjs/operators'
import { forkJoin, Subject } from 'rxjs'
import { PieChartDialogComponent } from 'src/app/shared/components/pie-chart-dialog/pie-chart-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ClientsOccupationBouquet } from 'src/app/shared/models/clients-occupation-bouquet';

@Component({
  selector: 'app-clients-occupation-main-pie',
  templateUrl: './clients-occupation-main-pie.component.html',
  styleUrls: ['./clients-occupation-main-pie.component.scss']
})
export class ClientsOccupationMainPieComponent implements OnInit {

  pieGraphData: any[];
  pieGraph: any[];
  showPieDialogButton: boolean = false;

  rawInfo: any;
  rawInfoProductClass: any;
  rawInfoOccupation: any;

  type_data: string;
  type_report: string;
  rejection_reason: any[];

  viewPie: any[] = [450, 180];
  gradientPie: boolean = false;
  showLegendPie: boolean = true;
  showLabelsPie: boolean = false;
  isDoughnutPie: boolean = false;
  isDoughnut: boolean = false;
  legendPositionPie: string = 'below';
  legendTitlePie: string = '';
  tooltipDisabledPie: boolean = false;
  animationsPie: boolean = false;
  colorSchemePie = {
    domain: [COLORS.darkBlue, COLORS.lightBlue, COLORS.pink, COLORS.lightOrange, COLORS.darkGreen, COLORS.lightPurple, COLORS.lightGreen, COLORS.lightestBlue, COLORS.darkYellow]
  };
  customColorsPieGraph: any = [];
  arcWidth: number = 1.5;

  tooltipTitle: string;
  tooltipNumber: string;

  // Pie chart dialog options
  pieDialogView: any[] = [600, 380];
  pieDialogShowLabels: boolean = true;
  pieDialogLegendPosition: string = 'below';

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;

  pieGraphLoaded: boolean = false;
  pieGraphLabels: any = [];

  barGraphToPrint: any = [];
  productClassToPrint: any = [];
  OccupationToPrint: any = [];
  rateToPrint: any = [];

  constructor(
    private dialog: MatDialog,
    private apimstService: ApimstService,
    private filtersService: FiltersService
  ) { }

  ngOnInit(): void {
    this.subscribeToFiltersValues();
  }

  subscribeToFiltersValues(): void {
    this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        this.pieGraphLoaded = false;
        this.resetValues();

        this.filtersValues = data;
        if (this.filtersValues.length == 0) return;
        this.retrieveOccupationPieInfo(this.filtersValues);
      }
    )
  }

  resetValues() {
    this.OccupationToPrint = [];
  }

  retrieveOccupationPieInfo(filtersValues: any): void {
    this.apimstService.getClientsOccupation("csb", filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data: ClientsOccupationBouquet[]) => {
          this.pieGraphLoaded = true;
          if( data == null) return;
          this.OccupationToPrint = this.createGraph(data);
        }
      })
  }

  createGraph(rawInfo: any[] = []) {
    let graphFinal: any[] = [];
    for (let element of rawInfo) {
      graphFinal.push({
        name: element.name,
        value: element.percentage ? parseFloat(element.percentage) : 0
      })
    }
    return graphFinal;
  }

  openPieChartOccupationDialog(): void {
    this.dialog.open(PieChartDialogComponent, {
      data: {
        sectionTitle: 'panels.occupation',
        results: this.OccupationToPrint,
        view: this.pieDialogView,
        gradient: this.gradientPie,
        showLegend: this.showLegendPie,
        showLabels: this.pieDialogShowLabels,
        isDoughnut: this.isDoughnut,
        legendPosition: this.pieDialogLegendPosition,
        legendTitle: this.legendTitlePie,
        tooltipDisabled: this.tooltipDisabledPie,
        animations: this.animationsPie,
        colorScheme: this.colorSchemePie,
        arcWidth: this.arcWidth,
        tooltipTitle: this.tooltipTitle,
        tooltipNumber: this.tooltipNumber
      },
      panelClass: ['mat-dialog-gen', 'mat-dialog-pie-gen', 'mat-dialog-pie-chart-clients-occupation'],
    })
  }

  labelFormatting(series: any[], name: string): string {
    const item = series.filter(data => data.name === name);
    if (item.length > 0) {
      return Intl.NumberFormat("de-DE").format(item[0].value) + "%";
    }
    return name;
  }

  activate(e) {
    this.tooltipTitle = e['value'].label;
    this.tooltipNumber = Intl.NumberFormat("de-DE").format(e['value'].value) + "%";
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<ngx-charts-chart [view]="[width, height]" [showLegend]="false" [animations]="animations">
    <svg:g class="chart">
        <linearGradient id="gradient">
        <stop stop-color="#FFFFFF" offset="0%"/>
        <stop stop-color="#FFFFFF" [attr.offset]="offsetValue"/>
        <stop stop-color="#23d778" [attr.offset]="offsetValue"/>
        <stop stop-color="#23d778" offset="100%"/>
        </linearGradient>
        <svg:g
        ngx-charts-bar
        class="background-bar"
        [width]="valueScale(max)"
        [height]="22"
        [x]="0"
        [y]="dims.height / 2"
        [data]="{}"
        [orientation]="'horizontal'"
        [roundEdges]="false"
        [animations]="animations"
        fill="url(#gradient)"
        >
        </svg:g>
        <line
        *ngFor="let loop of counter(16); let i = index"
        class="vertical-division-line"
        [attr.transform]="transformDivisionLine(i+1)"
        x1="0"
        y1="10"
        x2="0"
        y2="-1.3"
        [attr.stroke-width]="1"
        />
        <text class="gauge-min" [attr.transform]="transformMin">{{minDisplay}}</text>
        <text class="gauge-center" [attr.transform]="transformCenter">{{centerDisplay}}</text>
        <text class="gauge-max" [attr.transform]="transformMax">{{maxDisplay}}</text>
        <text class="gauge-value" [attr.transform]="transformValue">{{valueDisplay}}</text>
        <svg:line
        class="vertical-line"
        [attr.transform]="transformLine"
        x1="0"
        y1="14"
        x2="0"
        y2="-12"
        [attr.stroke-width]="5"
        >
        </svg:line>
    </svg:g>
</ngx-charts-chart>

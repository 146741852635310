import {
  Component,
  Input,
  AfterViewInit,
  ChangeDetectionStrategy,
  ElementRef,
} from "@angular/core";
import {
  BaseChartComponent,
  calculateViewDimensions,
  ViewDimensions,
  ColorHelper,
} from "@swimlane/ngx-charts";
import { MagnitudeOrderPipe } from "src/app/shared/pipes/magnitude-order.pipe";

import { scaleLinear } from "d3-scale";

@Component({
  selector: "linear-gauge-graph",
  templateUrl: "./linear-gauge-graph.component.html",
  styleUrls: ["./linear-gauge-graph.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinearGaugeGraphComponent
  extends BaseChartComponent
  implements AfterViewInit
{
  // Input variables to configure the chart options
  // Min graph value
  @Input() min;

  // Max graph value
  @Input() max;

  // Value taken by vertical bar
  @Input() value;

  // Value formatting function
  @Input() valueFormatting;

  // Color scheme for the graph
  @Input() colorSchme;

  @Input() center: number;

  @Input() units: string;

  // Number Format pipe
  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  // Values to display
  centerDisplay: string;
  maxDisplay: string;
  minDisplay: string;
  valueDisplay: string;

  // Variables
  dims: ViewDimensions;
  valueDomain: any;
  valueScale: any;
  colors: ColorHelper;
  transform: string;
  margin: any[] = [10, 6, 10, 6];
  transformLine: string;
  displayValue: string;
  hasPreviousValue: boolean;
  transformValue: string;
  transformMin: string;
  transformCenter: string;
  transformMax: string;
  counter = Array;
  lineDistance: number;
  offsetValue: string;

  // AfterViewInit
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  // Method to update the dimensions and theoptions ot the graph
  update(): void {
    super.update();

    // Calculation of Min - Max values
    this.max = Math.max(this.max, this.value);
    this.min = Math.min(this.min, this.value);

    // Calculation of dimensions
    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
    });

    // Calculation of domain, scale and value to display
    this.valueDomain = this.getValueDomain();
    this.valueScale = this.getValueScale();
    this.displayValue = this.getDisplayValue();

    // Calculation of the center value (center change to input value)
    //this.center = this.max / 2;

    // Distance between the different guide lines
    this.lineDistance = this.valueScale(this.max) / 17;

    // Calculation of the offset to transform the lines
    const xOffset = this.margin[3] + this.dims.width / 2;
    const yOffset = this.margin[0] + this.dims.height / 2;
    const indicatorYOffset = this.dims.height / 4;
    const extremeValueYOffset = this.dims.height * 1.8;

    // Offset center value
    const center_offset = (this.center - this.min) / (this.max - this.min);

    // Transform operations for the differents lines
    this.transform = `translate(${xOffset}, ${yOffset})`;
    this.transformLine = `translate(${this.valueScale(
      this.value
    )}, ${yOffset})`;
    this.transformValue = `translate(${this.valueScale(this.value) * 0.985}, ${
      indicatorYOffset * 0.7
    })`;
    this.transformMin = `translate(${this.valueScale(
      this.min
    )}, ${extremeValueYOffset})`;
    this.transformCenter = `translate(${
      this.valueScale(this.max) * center_offset * 0.96
    }, ${extremeValueYOffset})`;
    this.transformMax = `translate(${
      this.valueScale(this.max) * 0.97
    }, ${extremeValueYOffset})`;
    this.offsetValue = center_offset * 100 + "%";

    // Format values
    this.formatValues();
  }

  // Method to retrieve the graph domain
  private getValueDomain(): any[] {
    return [this.min, this.max];
  }

  // Method for calculating the scale of the graph
  private getValueScale(): any {
    return scaleLinear().range([0, this.dims.width]).domain(this.valueDomain);
  }

  // Method to format the value if format available
  private getDisplayValue(): string {
    if (!this.value) return "0";

    if (this.valueFormatting) {
      return this.valueFormatting(this.value);
    }
    return this.value.toLocaleString();
  }

  // Method to format values
  private formatValues(): void {
    this.centerDisplay = this.formatPipe.transform(this.center) + this.units;
    this.valueDisplay = this.formatPipe.transform(this.value) + this.units;
    this.maxDisplay = this.formatPipe.transform(this.max) + this.units;
    this.minDisplay = this.formatPipe.transform(this.min) + this.units;
  }

  // Method to apply the transform pattern to every guide line
  transformDivisionLine(index: number): string {
    return `translate(${this.lineDistance * index}, ${
      this.dims.height / 1.85
    })`;
  }
}

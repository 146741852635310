import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-technical-search-bar',
  templateUrl: './technical-search-bar.component.html',
  styleUrls: ['./technical-search-bar.component.scss']
})
export class TechnicalSearchBarComponent implements OnInit {
  @Input() width: string = "240";
  @Input() height: string = "639";
  @Input() bgColor: string = "#f2f7fa";
  @Input() title: string = "";
  @Input() lineColor: string = "";
  @Input() results: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  getStyles() {
    var styles = {
      'width.px': this.width,
      'height.px': this.height,
      'background-color': this.bgColor
    }
    return styles;
  }

}

import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highmaps';
import argentinaMap from "@highcharts/map-collection/countries/ar/ar-all.geo.json";
import brazilMap from "@highcharts/map-collection/countries/br/br-all.geo.json";
import chileMap from "@highcharts/map-collection/countries/cl/cl-all.geo.json";
import mexicoMap from "@highcharts/map-collection/countries/mx/mx-all.geo.json";
import uruguayMap from "@highcharts/map-collection/countries/uy/uy-all.geo.json";
import { COLORS } from 'src/app/shared/globals/globals';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = "mapChart";
  // chartData = [{ code3: "ABW", z: 105 }, { code3: "AFG", z: 35530 }];

  chartOptions: Highcharts.Options = {
    colorAxis: {
      min: 0,
      max: 30,
      // type: 'linear',
      // minColor: '#efecf3',
      // maxColor: '#990041',
    },
    chart: {
      map: brazilMap
    },
    title: {
      text: ""
    },
    subtitle: {
      text:
        ''
    },
    mapNavigation: {
      enabled: false,
      buttonOptions: {
        alignTo: "spacingBox"
      }
    },
    legend: {
      enabled: true
    },
    tooltip: {
      valueSuffix: '%'
    },
    // colorAxis: {
    //   min: 0
    // },
    series: [
      {
        type: "map",
        name: "",
        states: {
          hover: {
            color: COLORS.darkBlue
          }
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}"
        },
        allAreas: false,
        data: [
          ['br-sp', 0],
          ['br-ma', 1],
          ['br-pa', 2],
          ['br-sc', 3],
          ['br-ba', 4],
          ['br-ap', 5],
          ['br-ms', 6],
          ['br-mg', 7],
          ['br-go', 8],
          ['br-rs', 9],
          ['br-to', 10],
          ['br-pi', 11],
          ['br-al', 12],
          ['br-pb', 13],
          ['br-ce', 14],
          ['br-se', 15],
          ['br-rr', 16],
          ['br-pe', 17],
          ['br-pr', 18],
          ['br-es', 19],
          ['br-rj', 20],
          ['br-rn', 21],
          ['br-am', 22],
          ['br-mt', 23],
          ['br-df', 24],
          ['br-ac', 25],
          ['br-ro', 26]
        ]
      }
    ]
  };


}

<mat-tab-group mat-align-tabs="start" class="tab-group-gen tab-group-info-panel">
  <mat-tab [label]="tab.tabLabel" *ngFor="let tab of tabElements">
    <!-- <div *ngIf="tab.tabType == 'raw'">
      <div [innerHTML]="tab.tabContent" class="html-content"></div>
    </div> -->
    <div *ngIf="tab.tabType == 'list'">
      <app-info-panel-list [items]="tab.tabContent"></app-info-panel-list>
    </div>
    </mat-tab>
</mat-tab-group>

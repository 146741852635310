import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiAtlasService } from 'src/app/shared/services/api-atlas/api-atlas.service';
import { ApiDataService } from 'src/app/shared/services/api-data/api-data.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { COLORS } from 'src/app/shared/globals/globals';

@Component({
  selector: 'app-portal-widget-section',
  templateUrl: './portal-widget-section.component.html',
  styleUrls: ['./portal-widget-section.component.scss']
})
export class PortalWidgetSectionComponent implements OnInit {

  @Input() categoryInformation;

  image: string = '';
  color: string = '';
  order: string;
  title: string;
  dashboards: any = [];
  unsubscribe$: Subject<any> = new Subject<any>();
  activeLanguage: string;
  
  constructor(
    private route: Router,
    private apiDataService: ApiDataService,
    private ApiAtlasService: ApiAtlasService,
    private i18n: I18nService
  ) { }

  ngAfterViewInit() {
    const titleId = "title-underline-" + this.order;
    const titleUnderline = document.getElementById(titleId);
    titleUnderline.style.backgroundColor = this.color;
  }

  ngOnInit(): void {
    this.subscribeToActiveLanguage();
    this.order = this.categoryInformation?.order;
    this.color = this.categoryInformation?.color;
    this.title = this.categoryInformation?.name;
    this.image = this.categoryInformation?.background_image;
    this.title = this.categoryInformation?.name;
    this.dashboards = this.categoryInformation?.dashboards.sort((a,b) => {
      if (a.order < b.order) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  private subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLanguage = language;
      }
    })
  }

  goToSectionDetail(guid: string) {
    this.apiDataService.updateDataGovernanceBusinessCategory([]);
    localStorage.setItem('dataGovernanceInfo', JSON.stringify({}));
    this.ApiAtlasService.getDashboardDetail(this.activeLanguage, guid)
    // .pipe(takeUntil(this.unsubscribe$))
    .subscribe(data => {
      let sectionInformation = data;
      sectionInformation.color = this.categoryInformation.color;
      sectionInformation.title = this.categoryInformation.name;
      sectionInformation.dashboards = this.categoryInformation.dashboards;
      sectionInformation.controlPanelGuid = sectionInformation.guid;
      sectionInformation.order = 0;
      this.apiDataService.updateDataGovernanceBusinessCategory(sectionInformation);
      localStorage.setItem('dataGovernanceInfo', JSON.stringify(sectionInformation));
    });

    this.route.navigate(['/data-governance/business']);
    
  }

  getFontColor(order: string) {
    let styles;

    if (order === '2' || order === '4') {
      styles = {
        'color': COLORS.dgGray
      }
    } else {
      styles = {
        'color': COLORS.white
      }
    }

    return styles;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Component, OnInit } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-clients-portfolio-distribution-by-holding-indicators',
  templateUrl: './clients-portfolio-distribution-by-holding-indicators.component.html',
  styleUrls: ['./clients-portfolio-distribution-by-holding-indicators.component.scss']
})
export class ClientsPortfolioDistributionByHoldingIndicatorsComponent implements OnInit {

  loaded: boolean = false;
  loadedClientsPolicyCancellationPlusOne: boolean = false;
  loadedRetainedCustomersNumber: boolean = false;
  loadedRetentionsByClientNumber: boolean = false;

  filtersValues: any;

  unsubscribe$: Subject<any> = new Subject<any>();

  clientsPolicyCancellationPlusOneTitle: string = "labels.clientsPolicyCancellationPlusOne";
  clientsPolicyCancellationPlusOneMainValue: string = "83000";
  clientsPolicyCancellationPlusOnePercentage: string = "4.3";
  clientsPolicyCancellationPlusOneTrend: number = 1;

  retainedCustomersNumberTitle: string = "labels.retainedCustomersNumber";
  retainedCustomersNumberMainValue: string = "102000";
  retainedCustomersNumberPercentage: string = "0.3";
  retainedCustomersNumberTrend: number = 1;

  retentionsByClientNumberTitle: string = "labels.retentionsByClientNumber";
  retentionsByClientNumberMainValue: string = "0.1";
  retentionsByClientNumberPercentage: string = "0.1";
  retentionsByClientNumberTrend: number = 1;

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService
  ) { }

  ngOnInit(): void {
    this.subscribeToFiltersValues();
  }

  subscribeToFiltersValues(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data.length !== 0) {
            this.filtersValues = data;
            this.loaded = false;
            this.loadedClientsPolicyCancellationPlusOne = false;
            this.loadedRetainedCustomersNumber = false;
            this.loadedRetentionsByClientNumber = false;

            this.resetValues();
            this.retrieveClientsPortfolioDistribution(this.filtersValues);
          }
        }
      );
  }

  resetValues(): void {
    this.clientsPolicyCancellationPlusOneMainValue = "";
    this.clientsPolicyCancellationPlusOnePercentage = "";
    this.clientsPolicyCancellationPlusOneTrend = 0;

    this.retainedCustomersNumberMainValue = "";
    this.retainedCustomersNumberPercentage = "";
    this.retainedCustomersNumberTrend = 0;

    this.retentionsByClientNumberMainValue = "";
    this.retentionsByClientNumberPercentage = "";
    this.retentionsByClientNumberTrend = 0;
  }

  retrieveClientsPortfolioDistribution(filtersValues: any): void {

    const clientWithMoreThanOneValueRequest = this.apimstService.getClientsPortfolioHolding("chc", filtersValues);
    const clientWithMoreThanOnePreviousRequest = this.apimstService.getClientsPortfolioHolding("chcp", filtersValues);

    forkJoin([clientWithMoreThanOneValueRequest, clientWithMoreThanOnePreviousRequest])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: data => {
          this.loadedClientsPolicyCancellationPlusOne = true;
          if( data[0] == null && data[1] == null ) return;
          this.createClientsPolicyCancellationPlusOne(data[0][0], data[1][0]);
        }
      })

      this.apimstService.getClientsPortfolioHolding("chr", filtersValues)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: data => {
            this.loadedRetainedCustomersNumber = true;
            if(data == null) return;
            this.createRetainedCustomersNumber(data[0]);
          }
        })

      this.apimstService.getClientsPortfolioHolding("chrc", filtersValues)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: data => {
            this.loadedRetentionsByClientNumber = true;
            if(data == null) return;
            this.createRetentionsByClientNumber(data[0]);
          }
        })
  }

  createClientsPolicyCancellationPlusOne(actualInfo: any = {}, previousInfo: any = {}) {
    const actualValue = parseInt(actualInfo.value.replace(/,/g, ''));
    const previousValue = parseInt(previousInfo.value.replace(/,/g, ''));
    const variation = ( actualValue - previousValue ) / previousValue;
    this.clientsPolicyCancellationPlusOneMainValue = actualInfo.value && actualInfo.value != "" ? actualInfo.value.replace(/,/g, '') : "0";
    this.clientsPolicyCancellationPlusOnePercentage = variation.toString();
    this.clientsPolicyCancellationPlusOneTrend = variation > 0 ? 1 : 0;
  }

  createRetainedCustomersNumber(indicatorInfo: any = {}): void {
    this.retainedCustomersNumberMainValue = indicatorInfo.value && indicatorInfo.value != "" ? indicatorInfo.value.replace(/,/g, '') : "0";
    this.retainedCustomersNumberPercentage = indicatorInfo.diff && indicatorInfo.diff != "" ? indicatorInfo.diff.replace(/,/g, '') : "0";
    this.retainedCustomersNumberTrend = indicatorInfo.diff_trend ? parseInt(indicatorInfo.diff_trend) : 0;
  }

  createRetentionsByClientNumber(indicatorInfo: any = {}): void {
    this.retentionsByClientNumberMainValue = indicatorInfo.value && indicatorInfo.value != "" ? indicatorInfo.value.replace(/,/g, '') : "0";
    this.retentionsByClientNumberPercentage = indicatorInfo.diff && indicatorInfo.diff != "" ? indicatorInfo.diff.replace(/,/g, '') : "0";
    this.retentionsByClientNumberTrend = indicatorInfo.diff_trend ? parseInt(indicatorInfo.diff_trend) : 0;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

export var leftRouting = [
    {
        name: 'portfolio',
        value: 'sales/details'
    },
    {
        name: 'mix-portfolio-gwp',
        value: 'sales/portfolio'
    },
    {
        name: 'quality-complaints',
        value: 'sales/mix-portfolio-gwp'
    }

]
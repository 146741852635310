<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex">
            <div class="d-flex align-items-center">
                <span class="title section-card-title">{{ 'panels.fixedStockChurnRatePerCrop' | translate }}</span>
                <app-btn-gobernance-info 
                    class="ml-2" 
                    [endpoint]="endpoint" 
                    [endpointParams]="endpointParams"
                    *ngIf="hasGobernanceInfo">
                </app-btn-gobernance-info>
            </div>    
            <mat-icon *ngIf="loadedGraph" class="excel-icon ml-2" svgIcon="excel" (click)="exportAsXLSX()"></mat-icon>
        </div>    
        <app-option-tab [leftOption]="bonus" [rightOption]="policy" [graphName]="graphName" class="option-tab align-items-center"></app-option-tab>
    </div>

    <div class="d-flex mt-2 mb-1">
        <app-fixed-stock-churn-rate-per-crop-graphic [excelExportEvent]="churnRatePerCropExcelButton" (loadedGraph)="detectLoadedGraph($event)"></app-fixed-stock-churn-rate-per-crop-graphic>
    </div>

</app-widget>

<div class="h-100 d-flex align-items-center justify-content-between">
    <div class="logo-container">
        <a [routerLink]="['/portal']">
            <img [ngClass]="{'z-logo-height': isDataGovernancePortal}" class="z-logo" src={{zLogoSrc}}>
            <img class="santander-logo" src={{santanderLogoSrc}}>
        </a>
    </div>
    <app-home-header-menu class="mr-3" [menuLinks]="menuLinks" *ngIf="!isDataGovernancePortal"></app-home-header-menu>
    <app-search-button class="search-button" [isDataGovernancePortal]="isDataGovernancePortal" *ngIf="isDataGovernancePortal"></app-search-button>
</div>

<app-searcher *ngIf="openSearchPanel" [@animateFilters] [area]="area"></app-searcher>
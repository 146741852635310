import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiAtlasService } from 'src/app/shared/services/api-atlas/api-atlas.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { COLORS } from 'src/app/shared/globals/globals';
import { ApiDataService } from 'src/app/shared/services/api-data/api-data.service';
import _ from 'lodash';

@Component({
  selector: 'app-data-governance-technical',
  templateUrl: './data-governance-technical.component.html',
  styleUrls: ['./data-governance-technical.component.scss']
})
export class DataGovernanceTechnicalComponent implements OnInit {

  area: string = 'technical';
  headerTitle: string = 'dataGovernanceSection.dataGovernancePortal';
  headerSubtitle: string = 'dataGovernanceSection.technicalArea';
  loaded: boolean = false;
  graphLoaded: boolean = false;
  technicalSearchBarLoaded: boolean = false;
  entityInfo: boolean = false;
  unsubscribe$: Subject<any> = new Subject<any>();
  entityTitle: string;
  entityType: string;
  entityGuid: string;
  entityColor: string;
  data: any = [];
  onlyNodes: boolean = false;
  results: any[] = [];
  breadcrumbs: any[] = [];
  technicalSearchBarTitle: string;
  technicalSearchBarColor: string;
  width: number = 1100;
  height: number = 550;
  reducedWidth: number = 700;
  breadcrumbStackSize: number = 15;
  completeResultList: any = [];

  locationLabel: string;
  descriptionLabel: string;
  commentsLabel: string;
  creationLabel: string;
  modificationLabel: string;
  ownerLabel: string;
  hiddenLabel: string;
  idLabel: string;
  versionIdLabel: string;
  reportLabel: string;
  reportsLabel: string;
  attributeLabel: string;
  attributesLabel: string;
  metricLabel: string;
  metricsLabel: string;
  factLabel: string;
  factsLabel: string;
  tableLabel: string;
  tablesLabel: string;
  informationLabel: string;
  relationshipsLabel: string;
  entityDetailLabel: string;
  labelsArray;
  routerLink: string = "/data-governance/portal";

  upperMenuOptions = [
    {
      title: "dataGovernanceTechnicalArea.reports",
      lineColor: COLORS.report,
      isActive: false,
      id: "report",
      cursor: true,
      isLink: true
    },
    {
      title: "dataGovernanceTechnicalArea.attributes",
      lineColor: COLORS.attribute,
      isActive: false,
      id: "attribute",
      cursor: true,
      isLink: true
    },
    {
      title: "dataGovernanceTechnicalArea.metrics",
      lineColor: COLORS.metric,
      isActive: false,
      id: "metric",
      cursor: true,
      isLink: true
    },
    {
      title: "dataGovernanceTechnicalArea.facts",
      lineColor: COLORS.fact,
      isActive: false,
      id: "fact",
      cursor: true,
      isLink: true
    },
    {
      title: "dataGovernanceTechnicalArea.tables",
      lineColor: COLORS.table,
      isActive: false,
      id: "table",
      cursor: true,
      isLink: true
    }
  ];

  tabElements: any[] = [
    {
      tabLabel: "dataGovernanceTechnicalArea.information",
      tabType: "list",
      tabContent: [],
    },
    {
      tabLabel: "dataGovernanceTechnicalArea.relathionships",
      tabType: "list",
      tabContent: [],
    },
    {
      tabLabel: "dataGovernanceTechnicalArea.entityDetail",
      tabType: "list",
      tabContent: [],
    }
  ];

  constructor(
    private eventsService: EventsService,
    private apiAtlasService: ApiAtlasService,
    private translateService: TranslateService,
    private apiDataService: ApiDataService
  ) { }

  ngOnInit(): void {
    this.loaded = true;
    this.getTranslations();

    this.eventsService.dataGovernanceTechnicalSection$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data !== undefined) {
          const entityType = data.entityType;
          const showGraph = data.showGraph;

          this.activateUpperMenuBackground(entityType);
          this.retrieveEntitiesList(entityType, showGraph);
        }

      });

    this.eventsService.networkGraphSelectedNode$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data !== undefined) {
          switch (data.breadcrumbAction) {
            case 'remove':
              this.removeBreadcrumbLabel(data.id);
              break;
            case 'reset':
              this.initBreadcrumbsLabels();
              break;
            default:
              break;
          }
          this.retrieveTechnicalEntity(data.id);
        }

      })

    this.apiDataService.getDataGovernanceTechnicalCategory()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data !== undefined) {
          this.retrieveEntitiesList(data, true);
          this.apiDataService.updateDataGovernanceTechnicalCategory(undefined);
        }
      });

    this.eventsService.technicalInputTermChanged$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data !== undefined) {
          this.filterTechnicalResults(data);
        }
      })

    this.apiDataService.getDataGovernanceEntity()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data !== undefined) {
          this.retrieveEntitiesList(data.entityType.entityType, data.entityType.showGraph, true, data.entityData.id);
          this.apiDataService.updateDataGovernanceEntity(undefined);
        }
      })  

  }

  private activateUpperMenuBackground(id: string) {
    for (let item of this.upperMenuOptions) {
      if (item.id === id) {
        item.isActive = true;
        this.technicalSearchBarTitle = item.title;
        this.technicalSearchBarColor = item.lineColor;
      } else {
        item.isActive = false;
      }
    }
  }

  private retrieveTechnicalEntity(guid: string) {
    this.graphLoaded = false;
    this.setTechnicalSearchBarBackground(guid);
    this.apiAtlasService.getTechnicalAreaInstance(guid)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data !== undefined) {
          this.entityTitle = data.name;
          this.entityGuid = guid;
          this.setEntityInformation(data);
          this.setEntityRelationships(data);
          this.setEntityDetail(data);
          this.addBreadcrumLabel(data.name, data.guid);
          //this.initBreadcrumbsLabels();
          this.graphLoaded = true;
        }
      });
  }

  private setEntityInformation(data: any) {
    let entityInfo: any = [];
    entityInfo =
      [{
        title: this.locationLabel,
        firstDescription: data.attributes?.location
      },
      {
        title: this.descriptionLabel,
        firstDescription: data.attributes?.description
      },
      {
        title: this.creationLabel,
        firstDescription: data.attributes?.creation
      },
      {
        title: this.modificationLabel,
        firstDescription: data.attributes?.modification
      },
      {
        title: this.ownerLabel,
        firstDescription: data.attributes?.owner
      },
      {
        title: this.hiddenLabel,
        firstDescription: data.attributes?.hidden
      },
      {
        title: this.idLabel,
        firstDescription: data.attributes?.id
      }]
    this.tabElements[0].tabLabel = this.informationLabel;
    this.tabElements[0].tabContent = entityInfo;
    this.setEntityType(data.type_mst_object);
    //this.activateUpperMenuBackground(data.type_mst_object.toLowerCase());
  }

  private setEntityRelationships(data: any) {
    let relationshipsInfo: any = [];

    if (data.hasOwnProperty('relationships')) {
      delete data.relationships.sections;
      const relationshipsArray: any = Object.values(data.relationships).flat();
      for (let relationship of relationshipsArray) {
        relationshipsInfo.push({
          name: relationship.name,
          id: relationship.guid,
          color: COLORS[relationship.type_mst_object.toLowerCase()],
          type: relationship.type_mst_object
        })
      }

      // Group array by entity type
      const relationshipsByType = _.chain(relationshipsInfo).groupBy('type').map((value, key) => ({ title: this.labelsArray[key.toLowerCase()], relationships: value })).value();

      this.tabElements[1].tabLabel = this.relationshipsLabel;
      this.tabElements[1].tabContent = relationshipsByType;

      let relationships = {
        name: data.name,
        color: COLORS[data.type_mst_object.toLowerCase()],
        id: data.guid,
        children: relationshipsInfo
      }

      this.data = relationships;
      this.onlyNodes = false;
      this.entityInfo = true;
    }

  }

  private setEntityDetail(data: any) {
    const entityDetail = _.cloneDeep(data);
    delete entityDetail.attributes.location;
    delete entityDetail.attributes.description;
    delete entityDetail.attributes.creation;
    delete entityDetail.attributes.modification;
    delete entityDetail.attributes.owner;
    delete entityDetail.attributes.hidden;
    delete entityDetail.attributes.id;
    delete entityDetail.attributes.name;
    delete entityDetail.attributes.type_mst_object;
    delete entityDetail.attributes.name_translation;
    delete entityDetail.attributes.order;
    delete entityDetail.attributes.forms;

    let entityDetailInfo = [];

    for (let item of Object.entries(entityDetail.attributes)) {
      const title = item[0];
      let description: any = item[1];

      if (typeof description === 'object') {
        description = description.map(v => v?.name).toString().split(',').join(', ');
      }
      entityDetailInfo.push({
        title: 'dataGovernanceTechnicalArea.' + title,
        firstDescription: description
      })
    }

    this.tabElements[2].tabLabel = this.entityDetailLabel;
    this.tabElements[2].tabContent = entityDetailInfo;

  }

  private retrieveEntitiesList(entityType: string, showGraph: boolean, searchEntity?: boolean, entityId?: string) {
    this.technicalSearchBarLoaded = false;
    this.graphLoaded = false;
    this.activateUpperMenuBackground(entityType);
    this.apiAtlasService.getTechnicalListType(entityType)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        const entitiesList = data;
        this.results = [];
        this.data.nodes = [];
        const parents = [];
        if (entitiesList !== undefined && entitiesList.length !== 0) {
          for (let [index, item] of entitiesList.entries()) {
            this.results.push({
              name: item.name,
              id: item.guid,
              active: false,
              display: true
            });
            parents.push({
              name: item.name,
              color: COLORS[entityType],
              id: item.guid
            });
          }
          this.data.nodes = parents;
          this.completeResultList.nodes = _.cloneDeep(this.data.nodes);
          this.onlyNodes = true;
          this.entityInfo = false;
          this.initBreadcrumbsLabels();

          if (searchEntity) {
            this.retrieveTechnicalEntity(entityId);
          }
          
        }
        this.technicalSearchBarLoaded = true;
        this.graphLoaded = showGraph ? true : false;
      });
  }

  private getTranslations() {
    const location = this.translateService.get('dataGovernanceTechnicalArea.location');
    const description = this.translateService.get('dataGovernanceTechnicalArea.description');
    const comments = this.translateService.get('dataGovernanceTechnicalArea.comments');
    const creation = this.translateService.get('dataGovernanceTechnicalArea.creationDate');
    const modification = this.translateService.get('dataGovernanceTechnicalArea.modificationHour');
    const owner = this.translateService.get('dataGovernanceTechnicalArea.owner');
    const hidden = this.translateService.get('dataGovernanceTechnicalArea.hidden');
    const id = this.translateService.get('dataGovernanceTechnicalArea.id');
    const versionId = this.translateService.get('dataGovernanceTechnicalArea.versionId');
    const report = this.translateService.get('dataGovernanceTechnicalArea.report');
    const attribute = this.translateService.get('dataGovernanceTechnicalArea.attribute');
    const metric = this.translateService.get('dataGovernanceTechnicalArea.metric');
    const fact = this.translateService.get('dataGovernanceTechnicalArea.fact');
    const table = this.translateService.get('dataGovernanceTechnicalArea.table');
    const reports = this.translateService.get('dataGovernanceTechnicalArea.reports');
    const attributes = this.translateService.get('dataGovernanceTechnicalArea.attributes');
    const metrics = this.translateService.get('dataGovernanceTechnicalArea.metrics');
    const facts = this.translateService.get('dataGovernanceTechnicalArea.facts');
    const tables = this.translateService.get('dataGovernanceTechnicalArea.tables');
    const information = this.translateService.get('dataGovernanceTechnicalArea.information');
    const relationships = this.translateService.get('dataGovernanceTechnicalArea.relationships');
    const entityDetail = this.translateService.get('dataGovernanceTechnicalArea.entityDetail');

    forkJoin([location, description, comments, creation, modification, owner, hidden, id, versionId, report, attribute, metric, fact, table, reports, attributes, metrics, facts, tables, information, relationships, entityDetail])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        this.locationLabel = result[0];
        this.descriptionLabel = result[1];
        this.commentsLabel = result[2];
        this.creationLabel = result[3];
        this.modificationLabel = result[4];
        this.ownerLabel = result[5];
        this.hiddenLabel = result[6];
        this.idLabel = result[7];
        this.versionIdLabel = result[8];
        this.reportLabel = result[9];
        this.attributeLabel = result[10];
        this.metricLabel = result[11];
        this.factLabel = result[12];
        this.tableLabel = result[13];
        this.reportsLabel = result[14];
        this.attributesLabel = result[15];
        this.metricsLabel = result[16];
        this.factsLabel = result[17];
        this.tablesLabel = result[18];
        this.informationLabel = result[19];
        this.relationshipsLabel = result[20];
        this.entityDetailLabel = result[21];

        this.labelsArray = {
          attribute: this.attributesLabel,
          report: this.reportsLabel,
          table: this.tablesLabel,
          fact: this.factsLabel,
          metric: this.metricsLabel
        }
      });
  }

  private setEntityType(id: string) {
    switch (id) {
      case 'Report':
        this.entityType = this.reportLabel;
        this.entityColor = COLORS.report;
        break;
      case 'Table':
        this.entityType = this.tableLabel;
        this.entityColor = COLORS.table;
        break;
      case 'Metric':
        this.entityType = this.metricLabel;
        this.entityColor = COLORS.metric;
        break;
      case 'Attribute':
        this.entityType = this.attributeLabel;
        this.entityColor = COLORS.attribute;
        break;
      case 'Fact':
        this.entityType = this.factLabel;
        this.entityColor = COLORS.fact;
        break;
      default:
        break;
    }
  }

  private initBreadcrumbsLabels() {
    this.breadcrumbs = [
      {
        name: this.technicalSearchBarTitle,
        active: false,
      }
    ]
  }

  private addBreadcrumLabel(name: string, id: string) {
    this.breadcrumbs.map(v => v.active = false);
    if (this.breadcrumbs.length === this.breadcrumbStackSize) {
      this.breadcrumbs.shift();
    }
    this.breadcrumbs.push({
      name: name,
      id: id,
      active: true
    })
  }

  private removeBreadcrumbLabel(id: string) {
    const index = this.breadcrumbs.findIndex(v => v.id === id);
    this.breadcrumbs = this.breadcrumbs.slice(0, index);
  }

  private setTechnicalSearchBarBackground(id: string) {
    for (let item of this.results) {
      if (item.id === id) {
        item.active = true;
      } else {
        item.active = false;
      }
    }
  }

  private filterTechnicalResults(key: string) {
    if (key.length !== 0) {
      const splitedKey = key.toLowerCase().split(" ");
      for (let item of this.results) {
        let positiveResults = 0;
        splitedKey.map(v => {
          if (item.name.toLowerCase().includes(v)) {
            positiveResults++;
          }
        });
        if (positiveResults === splitedKey.length) {
          item.display = true;
        } else {
          item.display = false;
        }
      }
    } else {
      this.results.map(v => v.display = true);
    }

    const filteredResults = this.results.filter(v => v.display === true);
    const parents = this.completeResultList.nodes.filter(v => {
      return filteredResults.some((f) => {
        return f.id === v.id;
      })
    })
    this.eventsService.filteredTechnicalResults$.emit({ nodes: parents });
    this.entityInfo = false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

export var checkboxes = [
    {
      id: 0,
      name: "M0",
      color: "m0",
      checked: true
    },
    {
      id: 1,
      name: "M1",
      color: "m1",
      checked: true
    },
    {
      id: 2,
      name: "M2",
      color: "m2",
      checked: true
    },
    {
      id: 3,
      name: "M3",
      color: "m3",
      checked: true
    },
    {
      id: 4,
      name: "M4",
      color: "m4",
      checked: true
    },
    {
      id: 5,
      name: "M5",
      color: "m5",
      checked: false
    },
    {
      id: 6,
      name: "M6",
      color: "m6",
      checked: false
    },
    {
      id: 7,
      name: "M7",
      color: "m7",
      checked: false
    },
    {
      id: 8,
      name: "M8",
      color: "m8",
      checked: false
    },
    {
      id: 9,
      name: "M9",
      color: "m9",
      checked: false
    },
    {
      id: 10,
      name: "M10",
      color: "m10",
      checked: false
    },
    {
      id: 11,
      name: "M11",
      color: "m11",
      checked: false
    },
    {
      id: 12,
      name: "M12",
      color: "m12",
      checked: false
    },
    {
      id: 13,
      name: "M13",
      color: "m13",
      checked: false
    },
    {
      id: 14,
      name: "M14",
      color: "m14",
      checked: false
    },
    {
      id: 15,
      name: "M15",
      color: "m15",
      checked: false
    },
    {
      id: 16,
      name: "M16-M24",
      color: "m16",
      checked: false
    },
    {
      id: 17,
      name: ">=M25",
      color: "m17",
      checked: false
    }
  ];
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { trim } from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ATLAS_ENDPOINTS, COLORS } from 'src/app/shared/globals/globals';
import { SinistersFunnelIndicator } from 'src/app/shared/models/sinisters-funnel-indicator';
import { SinistersFunnelExtraIndicator } from 'src/app/shared/models/sinisters-funnel-extra-indicator';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { CurrencyService } from 'src/app/shared/services/currency/currency.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { environment } from 'src/environments/environment';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';

@Component({
  selector: 'app-sinisters-main',
  templateUrl: './sinisters-main.component.html',
  styleUrls: ['./sinisters-main.component.scss']
})
export class SinistersMainComponent implements OnInit {

  // Labels for the options panel
  options: any[] = [
    { id: "all", name: "panels.all", selected: true },
    { id: "administrative", name: "panels.administrative", selected: false },
    { id: "judicial", name: "panels.judicial", selected: false },
  ];
  graphName: string = 'sinistersFunnel';

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any
  boquetsNumber: number = 4;
  selectedOption: string = 'all';
  litigationType: any[] = ['1', '2'];

  // Header KPI
  mainValue: string;
  firstPercentageValue: string;
  firstPercentagePrevious: string;
  firstPercentageTrendValue: number;
  firstPercentageTrendDirection: number;
  secondPercentageValue: string;
  secondPercentagePrevious: string;
  secondPercentageTrendValue: number;
  secondPercentageTrendDirection: number;
  funnelIndicatorData: any = {};
  indicatorLoaded: boolean = false;

  // Header extra KPI
  mainValueExtraKpi: string;
  mainValueUnitExtra: string;
  percentagePreviousExtra: string;
  percentageKpiExtra: string;
  trendKpiExtra: number;
  indicatorExtraLoaded: boolean = false;

  // Variables for the translated labels
  monthsLabel: any;
  denouncedLabel: string;
  openedLabel: string;
  reopenedLabel: string;
  pendingLabel: string;
  paidLabel: string;
  canceledLabel: string;
  prescribedLabel: string;
  rejectedLabel: string;
  volumeLabel: string;
  severityLabel: string;
  frequencyLabel: string;
  reserveLabel: string;
  amountPaidLabel: string;
  othersLabel: string;
  manageableLabel: string;
  notManageableLabel: string;

  // Funnel data
  funnelOptions: any = {};
  height: number = 120;
  width: number = 1240;
  data = {
    labels: [],
    subLabels: [],
    colors: [
      ['#cc4585D9', '#cc696cD9'],
      ['#6d1e84D9', '#be6fd3D9'],
      ['#3d6176D9', '#13b8b2D9'],
      ['#37897aD9', '#1ac990D9'],
      ['#61d1b5D9', '#9ce2d1D9']
    ],
    totalSinisterPerState: [],
    newSinisterPerState: [],
    bouquetsLabels: [],
    operationSymbol: ['+', '', '+', '-', '-', '-', '-', ''],
    totalNumberSinisterPerState: [],
  };

  totalNumberSinisterPerState: any[] = [];

  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  substateData: [];
  currencySymbol: string;
  funnelLoaded: boolean = false;
  subcategoryLoaded: boolean = false;
  bottomGraphLoaded: boolean = false;

  // Options for chart creation
  view = [1240, 120];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  legendTitle: string = '';
  legendPosition: string = '';
  showXAxisLabel: boolean = false;
  showYAxisLabel: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  yLeftAxisScaleFactor: string = '';
  yRightAxisScaleFactor: string = '';
  showGridLines: boolean = true;
  showRightGridLines: boolean = true;
  innerPadding: string = '10%';
  animations: boolean = false;
  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  tooltipDisabled: boolean = false;
  schemeType: string = "ordinal";
  roundDomains: boolean = true;
  noBarWhenZero: boolean = true;
  lineChartScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.lightBlue, COLORS.darkYellow]
  };
  comboBarScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.lightBlue, COLORS.darkBlue]
  };
  customColors: any = [];
  roundEdges: boolean = false;
  activeEntries: any = [];
  trimXAxisTicks: boolean = true;
  trimYAxisTicks: boolean = true;
  rotateXAxisTicks: boolean = false;
  maxXAxisTickLength: number = 16;
  maxYAxisTickLength: number = 16;
  barPadding: number = 8;
  addHorizontalGraph: boolean = false;

  groupedVerticalGraph: any = [];
  horizontalGraph: any[] = [];
  loaded: boolean = true;

  tooltipTitle: string;
  tooltipValue: string;

  // Traduction labels
  traductions: any = [
    'abbreviatedMonthNames',
    'labels.manageable',
    'labels.notManageable',
    'labels.denounced',
    'labels.opened',
    'labels.reopened',
    'labels.pending',
    'labels.paid',
    'labels.canceled',
    'labels.prescribed',
    'labels.rejected',
    'labels.volume',
    'labels.severity',
    'labels.frequency',
    'labels.reserve',
    'labels.amountPaid',
    'labels.others'
  ];

  // Variables to store graph data
  sinisterSubcategoryDetail: any;
  sinistersSubcategoryIDs = ['Denunciado', 'Aperturado', 'Reaperturado', 'Anulado', 'Pagado', 'Rechazado', 'Prescrito', 'Pendiente'];

  sinisterBottomGraph: any = [];
  commonIndicator: string = 'v';
  analysisStatus: any = ['1'];
  firstLoad: boolean = false;
  storedSubcategoryData: any = [];
  storedBottomGraphData: any = [];

  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "sinisters-detail--sinisters";

  // Default constructor
  constructor(
    private translateService: TranslateService,
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private magnitudeOrderPipe: MagnitudeOrderPipe,
    private utilsService: UtilsService,
    private eventsService: EventsService,
    private currencyService: CurrencyService,
    private i18n: I18nService,
    private atlasHelper: AtlasHelperService

  ) { }

  // OnInit
  ngOnInit(): void {
    this.subscribeToActiveLanguage();

    // Retrieve currency symbol
    this.currencyService.currencyInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => this.currencySymbol = data.symbol);

    this.funnelOptionsInitialization();
    this.getTranslations();

    // Event to detect filters changes
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data.length !== 0) {
            this.filtersValues = data;
            this.retrieveData();
          }
        });

    // Event to detect option changes
    this.eventsService.optionTabMultipleSelected$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data && data['graphName'] === this.graphName) {
            this.selectedOption = data['id'];
            this.setLitigationType();
            this.retrieveData();
          }
        }
      );

    // Event to detect a state change
    this.eventsService.sinisterFunnelStateChecked$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.getAnalysisStatus(data);
          this.retrieveSinisterSubcategoryData(data);
        }
      );

    // Event to detect a substate change
    this.eventsService.sinisterFunnelSubStateChecked$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          let substateId;
          const stateIndex = data[0].stateIndex;

          if (stateIndex === 7) {
            substateId = 'pending';
          } else {
            substateId = data[0].substateId;
          }

          this.getCommonIndicator(substateId);
          this.retrieveSinisterBottomGraph();
        }
      );
  }

  // Method to call the indicator and funnel data service
  private retrieveData() {
    this.retrieveFunnelData();
    this.retrieveFunnelIndicator();
    this.retrieveFunnelExtraIndicator();
  }

  // Method to retrive the funnel indicator data
  private retrieveFunnelIndicator() {
    this.indicatorLoaded = false;

    let filtersWithPreviousMonth = this.setFiltersWithPreviousMonth(this.filtersValues);

    this.apimstService.getSinistersFunnelIndicator('spm', filtersWithPreviousMonth, this.litigationType)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        const funnelIndicator: SinistersFunnelIndicator = data;

        this.mainValue = (funnelIndicator.value || trim(funnelIndicator.value).length > 0) ? this.magnitudeOrderPipe.transform(parseFloat(funnelIndicator.value.replace(/,/g, ''))) : '0';
        this.firstPercentageValue = (funnelIndicator.year_diff || trim(funnelIndicator.year_diff).length > 0) ? this.magnitudeOrderPipe.transform(parseFloat(funnelIndicator.year_diff.replace(/,/g, ''))) : '0';
        this.firstPercentagePrevious = 'PY';
        this.firstPercentageTrendValue = (funnelIndicator.year_diff_trend || trim(funnelIndicator.year_diff_trend).length > 0) ? parseFloat(funnelIndicator.year_diff_trend.replace(/,/g, '')) : 0;
        this.firstPercentageTrendDirection = (funnelIndicator.year_diff || trim(funnelIndicator.year_diff).length > 0) ? (this.utilsService.isNegative(funnelIndicator.year_diff) == false ? 1 : 0) : 0;
        this.secondPercentageValue = (funnelIndicator.month_diff || trim(funnelIndicator.month_diff).length > 0) ? this.magnitudeOrderPipe.transform(parseFloat(funnelIndicator.month_diff.replace(/,/g, ''))) : '0';
        this.secondPercentagePrevious = 'PM';
        this.secondPercentageTrendValue = (funnelIndicator.month_diff_trend || trim(funnelIndicator.month_diff_trend).length > 0) ? parseFloat(funnelIndicator.month_diff_trend.replace(/,/g, '')) : 0;
        this.secondPercentageTrendDirection = (funnelIndicator.month_diff || trim(funnelIndicator.month_diff).length > 0) ? (this.utilsService.isNegative(funnelIndicator.month_diff) == false ? 1 : 0) : 0;

        this.indicatorLoaded = true;
      }
      );
  }

  private retrieveFunnelExtraIndicator() {
    this.indicatorExtraLoaded = false;

    this.apimstService.getSinistersFunnelExtraIndicator('srect', this.filtersValues, this.litigationType)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        const funnelExtraIndicator: SinistersFunnelExtraIndicator = data;
        this.indicatorExtraLoaded = true;

        this.mainValueExtraKpi = (funnelExtraIndicator.value || trim(funnelExtraIndicator.value).length > 0) ? funnelExtraIndicator.value.replace(/,/g, '') : '0';
        this.mainValueUnitExtra = this.currencySymbol;
        this.percentagePreviousExtra = 'PM';
        this.percentageKpiExtra = (funnelExtraIndicator.diff || trim(funnelExtraIndicator.diff).length > 0) ? funnelExtraIndicator.diff.replace(/,/g, '') : '0';
        this.trendKpiExtra = (funnelExtraIndicator.diff_trend || trim(funnelExtraIndicator.diff_trend).length > 0) ? parseFloat(funnelExtraIndicator.diff_trend.replace(/,/g, '')) : 0;
      });
  }


  // Method to assignt the funnel graph options
  private funnelOptionsInitialization() {
    this.funnelOptions.gradientDirection = 'horizontal';
    this.funnelOptions.data = this.data;
    this.funnelOptions.displayPercent = false;
    this.funnelOptions.direction = 'horizontal';
    this.funnelOptions.height = this.height;
    this.funnelOptions.width = this.width;

  }

  // Method to retrieve the funnel graph data
  private retrieveFunnelData() {
    this.storedSubcategoryData = [];
    this.storedBottomGraphData = [];
    this.funnelLoaded = false;
    this.subcategoryLoaded = false;
    this.bottomGraphLoaded = false;
    this.analysisStatus = ['1'];
    this.commonIndicator = 'v';

    const funnelData = this.apimstService.getSinistersFunnelGraph('sbt', this.filtersValues, this.litigationType);        // Funnel data
    const funnelDataAmounts = this.apimstService.getSinistersFunnelGraph('sbta', this.filtersValues, this.litigationType);        // Funnel data amounts
    const funnelDataPendingAmount = this.apimstService.getSinistersFunnelGraph('sbtap', this.filtersValues, this.litigationType);        // Funnel data pending amount
    const subcategory = this.apimstService.getSinistersFunnelGraph('sbi', this.filtersValues, this.litigationType, this.analysisStatus);   // Second line data
    const subcategoryFrequency = this.apimstService.getSinistersFunnelGraph('sbif', this.filtersValues, this.litigationType, this.analysisStatus);   // Second line frequency data
    const bottomGraph = this.apimstService.getSinistersFunnelGraph('sbr', this.filtersValues, this.litigationType, this.analysisStatus, this.commonIndicator);   // Bottom graph

    forkJoin([funnelData, funnelDataAmounts, funnelDataPendingAmount, subcategory, subcategoryFrequency, bottomGraph])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          const sinisterFunnelData = result[0];
          const sinisterFunnelDataAmounts = result[1];
          const sinisterFunnelDataPendingAmount = result[2];
          let subcategoryData = result[3];
          const subcategoryFrequencyData = result[4];
          const sinisterBottomGraph = result[5];

          let emptyFrequency = { diff: "0%", diff_trend: "0", value: "0%" }
          subcategoryData.details[0].frequency = subcategoryFrequencyData.details.length != 0 ? subcategoryFrequencyData.details[0].frequency : emptyFrequency;

          this.setDataAmounts(sinisterFunnelDataAmounts.details, sinisterFunnelDataPendingAmount.details);
          this.retrieveSinisterFunnelValues(sinisterFunnelData, this.totalNumberSinisterPerState);
          this.editSinisterSubcategoryData(subcategoryData, 0);
          this.editSinisterBottomGraphData(sinisterBottomGraph);
        }
      );


    // this.apimstService.getSinistersFunnelGraph('sbt', this.filtersValues, this.litigationType)
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe(
    //     data => {
    //       const sinisterFunnelData = data;
    //       this.retrieveSinisterFunnelValues(sinisterFunnelData, this.totalNumberSinisterPerState);
    //     }
    //   );
  }

  private setDataAmounts(data: any = [], pendingTotal: any = {}) {
    this.totalNumberSinisterPerState = [
      data.filter(n => n.name == "Denunciado").length > 0 ? this.formatPipe.transform(parseFloat((data.filter(n => n.name == "Denunciado")[0].amount).replace(/,/g, ''))) + this.currencySymbol : "0",
      data.filter(n => n.name == "Aperturado").length > 0 ? this.formatPipe.transform(parseFloat((data.filter(n => n.name == "Aperturado")[0].amount).replace(/,/g, ''))) + this.currencySymbol : "0",
      data.filter(n => n.name == "Reaperturado").length > 0 ? this.formatPipe.transform(parseFloat((data.filter(n => n.name == "Reaperturado")[0].amount).replace(/,/g, ''))) + this.currencySymbol : "0",
      data.filter(n => n.name == "Anulado").length > 0 ? this.formatPipe.transform(parseFloat((data.filter(n => n.name == "Anulado")[0].amount).replace(/,/g, ''))) + this.currencySymbol : "0",
      data.filter(n => n.name == "Pagado").length > 0 ? this.formatPipe.transform(parseFloat((data.filter(n => n.name == "Pagado")[0].amount).replace(/,/g, ''))) + this.currencySymbol : "0",
      data.filter(n => n.name == "Rechazado").length > 0 ? this.formatPipe.transform(parseFloat((data.filter(n => n.name == "Rechazado")[0].amount).replace(/,/g, ''))) + this.currencySymbol : "0",
      data.filter(n => n.name == "Prescrito").length > 0 ? this.formatPipe.transform(parseFloat((data.filter(n => n.name == "Prescrito")[0].amount).replace(/,/g, ''))) + this.currencySymbol : "0",
      pendingTotal.length > 0 ? this.formatPipe.transform(parseFloat((pendingTotal[0].amount).replace(/,/g, ''))) + this.currencySymbol : "0"
    ];
  }

  // Method that calls sinisters funnel API service and transform data
  private retrieveSinisterFunnelValues(sinisterFunnelData, totalData) {

    if (sinisterFunnelData.details.length !== 0) {
      // Apply transformation to convert string to number
      const formatedValues = sinisterFunnelData.details.map(v => _.transform(v, function (result, value: string, idx) {
        if (idx.toString() !== 'name') {
          result[idx] = (value || trim(value).length > 0) ? parseFloat(value.replace(/,/g, '')) : 0;
        } else {
          result[idx] = value;
        }
      }));

      // Sort array to retrieve highest values for the state 'Denounced'
      const sorteredArray = this.sortArray(formatedValues);

      // Retrieve the bouquets labels
      let bouquetsLabels = sorteredArray.map(v => v.name);

      // Store the accumulated value of main value for all states
      const pending = sorteredArray.map(v => v?.pending).reduce((x, y) => x + y);
      const canceled = sorteredArray.map(v => v?.canceled).reduce((x, y) => x + y);
      const denounced = sorteredArray.map(v => v?.denounced).reduce((x, y) => x + y);
      const opened = sorteredArray.map(v => v?.opened).reduce((x, y) => x + y);
      const paid = sorteredArray.map(v => v?.paid).reduce((x, y) => x + y);
      const prescribed = sorteredArray.map(v => v?.prescribed).reduce((x, y) => x + y);
      const rejected = sorteredArray.map(v => v?.rejected).reduce((x, y) => x + y);
      const reopened = sorteredArray.map(v => v?.reopened).reduce((x, y) => x + y);

      const newSinisterPerState = [denounced, opened, reopened, canceled, paid, rejected, prescribed, pending];

      let pendingArray: any[] = [];
      let canceledArray: any[] = [];
      let denouncedArray: any[] = [];
      let openedArray: any[] = [];
      let paidArray: any[] = [];
      let prescribedArray: any[] = [];
      let rejectedArray: any[] = [];
      let reopenedArray: any[] = [];

      let accumulatedPending: number = 0;
      let accumulatedCanceled: number = 0;
      let accumulatedDenounced: number = 0;
      let accumulatedOpened: number = 0;
      let accumulatedPaid: number = 0;
      let accumulatedPrescribed: number = 0;
      let accumulatedRejected: number = 0;
      let accumulatedReopened: number = 0;

      // Retrieve the pending value for all the bouquets.
      // If the number of bouquets is highest of boquetsNumber, group them
      for (let [index, bouquet] of sorteredArray.entries()) {
        if (index < this.boquetsNumber) {
          pendingArray.push(bouquet.pending);
          canceledArray.push(bouquet.pending_canceled);
          denouncedArray.push(bouquet.pending_denounced);
          openedArray.push(bouquet.pending_opened);
          paidArray.push(bouquet.pending_paid);
          prescribedArray.push(bouquet.pending_prescribed);
          rejectedArray.push(bouquet.pending_rejected);
          reopenedArray.push(bouquet.pending_reopened);
        } else {
          accumulatedPending += bouquet.pending;
          accumulatedCanceled += bouquet.pending_canceled;
          accumulatedDenounced += bouquet.pending_denounced;
          accumulatedOpened += bouquet.pending_opened;
          accumulatedPaid += bouquet.pending_paid;
          accumulatedPrescribed += bouquet.pending_prescribed;
          accumulatedRejected += bouquet.pending_rejected;
          accumulatedReopened += bouquet.pending_reopened;
        }
      }

      if (sorteredArray.length > this.boquetsNumber) {
        pendingArray.push(accumulatedPending);
        canceledArray.push(accumulatedCanceled);
        denouncedArray.push(accumulatedDenounced);
        openedArray.push(accumulatedOpened);
        paidArray.push(accumulatedPaid);
        prescribedArray.push(accumulatedPrescribed);
        rejectedArray.push(accumulatedRejected);
        reopenedArray.push(accumulatedReopened);

        bouquetsLabels = bouquetsLabels.slice(0, this.boquetsNumber);
        bouquetsLabels.push(this.othersLabel);

      }

      const totalSinisterPerState = [denouncedArray, openedArray, reopenedArray, canceledArray, paidArray, rejectedArray, prescribedArray, pendingArray];

      this.data.labels = [this.denouncedLabel, this.openedLabel, this.reopenedLabel, this.canceledLabel, this.paidLabel, this.rejectedLabel, this.prescribedLabel, this.pendingLabel];
      this.data.subLabels = [
        [
          {
            id: 'volume',
            label: this.volumeLabel
          },
          {
            id: 'frequency',
            label: this.frequencyLabel
          }
        ],
        [
          {
            id: 'volume',
            label: this.volumeLabel
          },
          {
            id: 'severity',
            label: this.severityLabel
          },
          {
            id: 'frequency',
            label: this.frequencyLabel
          },
          {
            id: 'reservation_amount',
            label: this.reserveLabel
          }
        ],
        [
          {
            id: 'volume',
            label: this.volumeLabel
          },
          {
            id: 'severity',
            label: this.severityLabel
          },
          {
            id: 'frequency',
            label: this.frequencyLabel
          },
          {
            id: 'reservation_amount',
            label: this.reserveLabel
          },
          {
            id: 'payment_amount',
            label: this.amountPaidLabel
          }
        ],
        [
          {
            id: 'volume',
            label: this.volumeLabel
          },
          {
            id: 'severity',
            label: this.severityLabel
          },
          {
            id: 'frequency',
            label: this.frequencyLabel
          },
          {
            id: 'reservation_amount',
            label: this.reserveLabel
          },
          {
            id: 'payment_amount',
            label: this.amountPaidLabel
          }

        ],
        [
          {
            id: 'volume',
            label: this.volumeLabel
          },
          {
            id: 'severity',
            label: this.severityLabel
          },
          {
            id: 'frequency',
            label: this.frequencyLabel
          },

          {
            id: 'reservation_amount',
            label: this.reserveLabel
          },


          {
            id: 'payment_amount',
            label: this.amountPaidLabel
          }
        ],
        [
          {
            id: 'volume',
            label: this.volumeLabel
          },
          {
            id: 'severity',
            label: this.severityLabel
          },
          {
            id: 'frequency',
            label: this.frequencyLabel
          },
          {
            id: 'reservation_amount',
            label: this.reserveLabel
          }
        ],
        [
          {
            id: 'volume',
            label: this.volumeLabel
          },
          {
            id: 'severity',
            label: this.severityLabel
          },
          {
            id: 'frequency',
            label: this.frequencyLabel
          },
          {
            id: 'reservation_amount',
            label: this.reserveLabel
          }
        ],
        [
          {
            id: 'volume',
            label: this.volumeLabel
          },
          {
            id: 'severity',
            label: this.severityLabel
          },
          {
            id: 'frequency',
            label: this.frequencyLabel
          },
          {
            id: 'reservation_amount',
            label: this.reserveLabel
          },
          {
            id: 'payment_amount',
            label: this.amountPaidLabel
          }

        ]
      ];

      this.data.totalSinisterPerState = totalSinisterPerState;
      this.data.newSinisterPerState = newSinisterPerState;
      this.data.bouquetsLabels = bouquetsLabels;
      this.data.totalNumberSinisterPerState = totalData;
    }

    this.funnelLoaded = true;

  };

  // Metod to retrieve the sinister subcategory data from the API
  private retrieveSinisterSubcategoryData(categoryIndex: number) {
    if (categoryIndex === 7) {
      this.substateData = [];
      this.subcategoryLoaded = true;
      this.bottomGraphLoaded = true;
    } else {
      const subcategoryId = this.sinistersSubcategoryIDs[categoryIndex];
      const recoveredData = this.storedSubcategoryData.filter(v => v.id === subcategoryId);

      if (recoveredData.length !== 0) {
        this.substateData = recoveredData[0].data;
      } else {
        this.subcategoryLoaded = false;

        const subcategory = this.apimstService.getSinistersFunnelGraph('sbi', this.filtersValues, this.litigationType, this.analysisStatus);   // Second line data
        const subcategoryFrequency = this.apimstService.getSinistersFunnelGraph('sbif', this.filtersValues, this.litigationType, this.analysisStatus);   // Second line frequency data

        forkJoin([subcategory, subcategoryFrequency])
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(data => {
            let subcategoryData = data[0];
            const subcategoryFrequencyData = data[1];

            let emptyFrequency = { diff: "0%", diff_trend: "0", value: "0" }
            subcategoryData.details[0].frequency = subcategoryFrequencyData.details.length != 0 ? subcategoryFrequencyData.details[0].frequency : emptyFrequency;

            this.editSinisterSubcategoryData(subcategoryData, categoryIndex);
            // this.editSinisterSubcategoryData(data, categoryIndex)
          });
      }
    }
  }

  // Method to prepare the sinister subcategory data
  private editSinisterSubcategoryData(data: any, categoryIndex: number) {
    const subcategoryId = this.sinistersSubcategoryIDs[categoryIndex];
    const subcategoryData = data.details.filter(v => v.name === subcategoryId);
    const subcategoryList = this.data.subLabels[categoryIndex];

    let substateData: any = [];

    if (subcategoryData.length !== 0) {
      for (let item of subcategoryList) {
        const data = subcategoryData[0][item.id];
        if (data) {
          substateData.push({
            id: item.id,
            label: item.label,
            value: (data.value || trim(data.value).length > 0) ? parseFloat(data.value.replace(/,/g, '')) : 0,
            // value: (data.value || trim(data.value).length > 0) ? this.formatPipe.transform(parseFloat(data.value.replace(/,/g, ''))) : 0,


            diff: (data.diff || trim(data.diff).length > 0) ? parseFloat(data.diff.replace(/,/g, '')) : 0,
            diff_trend: (data.diff_trend || trim(data.diff_trend).length > 0) ? parseFloat(data.diff_trend) : 0,
            diff_direction: this.utilsService.isNegative(data.diff) == false ? 1 : 0,
            state_id: categoryIndex,
            subcategory_unit: this.assignSubcategoryUnit(item.id)
          });
        }
      }

      // this.formatPipe.transform(parseFloat(data.value.replace(/,/g, '')))


      this.storedSubcategoryData.push({
        id: subcategoryId,
        data: substateData
      });
    }
    this.substateData = substateData;
    this.subcategoryLoaded = true;
    this.bottomGraphLoaded = true;

  }

  // Metod to retrieve the sinister bottom graph data from the API
  private retrieveSinisterBottomGraph() {
    const recoveredData = this.storedBottomGraphData.filter(v => v.id === (this.analysisStatus + '-' + this.commonIndicator));
    if (recoveredData.length !== 0) {
      this.groupedVerticalGraph = recoveredData[0].data;
    } else {
      this.bottomGraphLoaded = false;
      this.apimstService.getSinistersFunnelGraph('sbr', this.filtersValues, this.litigationType, this.analysisStatus, this.commonIndicator)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          data => {
            this.editSinisterBottomGraphData(data)
          });
    }

  }

  // Method to prepare the bottom graph data
  private editSinisterBottomGraphData(data) {
    const sinisterBottomGraph = data;
    const bottomGraph: any = [];

    for (let month of sinisterBottomGraph.details) {
      const monthValue = month.month;
      const year = month.year;

      const series = [];
      if (month?.details.length !== 0) {
        month.details.map(v => {
          let name = '';
          const value = (v.value || trim(v.value).length > 0) ? parseFloat(v.value.replace(/,/g, '')) : 0;

          if (v.name === '0') {         // # 0 - No gestionable
            name = this.notManageableLabel;
          } else if (v.name === '1') {  // # 1 - Gestionable
            name = this.manageableLabel;
          }

          series.push({
            name: name,
            value: value
          })
        })
      }

      bottomGraph.push({
        name: this.monthsLabel[monthValue] + ' ' + year.slice(2, 4),
        series: series
      });

    }

    this.storedBottomGraphData.push({
      id: this.analysisStatus + '-' + this.commonIndicator,
      data: bottomGraph
    });

    this.groupedVerticalGraph = bottomGraph;
    if (this.subcategoryLoaded) {
      this.bottomGraphLoaded = true;
    }

  }

  // Method to get the labels translations
  private getTranslations() {
    this.translateService.stream(this.traductions)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        this.monthsLabel = result['abbreviatedMonthNames'];
        this.manageableLabel = result['labels.manageable'];
        this.notManageableLabel = result['labels.notManageable'];
        this.denouncedLabel = result['labels.denounced'];
        this.openedLabel = result['labels.opened'];
        this.reopenedLabel = result['labels.reopened'];
        this.pendingLabel = result['labels.pending'];
        this.paidLabel = result['labels.paid'];
        this.canceledLabel = result['labels.canceled'];
        this.prescribedLabel = result['labels.prescribed'];
        this.rejectedLabel = result['labels.rejected'];
        this.volumeLabel = result['labels.volume'];
        this.severityLabel = result['labels.severity'];
        this.frequencyLabel = result['labels.frequency'];
        this.reserveLabel = result['labels.reserve'];
        this.amountPaidLabel = result['labels.amountPaid'];
        this.othersLabel = result['labels.others'];
        this.assignColorBar();
      });
  }

  // Method for sorting data based on 'Denounced' value
  sortArray(array) {
    array = array.sort((a, b) => {
      if (a.denounced > b.denounced) {
        return -1
      } else if (b.denounced > a.denounced) {
        return 1
      } else {
        return 0;
      }
    });

    return array;
  }

  // Method to assign the litigation type according to the selected option
  setLitigationType() {
    switch (this.selectedOption) {
      case 'all':
        this.litigationType = ['1', '2'];
        break;
      case 'administrative':
        this.litigationType = ['2'];
        break;
      case 'judicial':
        this.litigationType = ['1'];
        break;
    }
  }

  // Method to assign the value for the analysis_status parameter
  getAnalysisStatus(index) {
    switch (index) {
      case 0:
        this.analysisStatus = ['1']; // analysis_status_denounced_id = 1
        break;
      case 1:
        this.analysisStatus = ['3']; // analysis_status_opened_id = 3
        break;
      case 2:
        this.analysisStatus = ['4']; // analysis_status_reopened_id = 4
        break;
      case 3:
        this.analysisStatus = ['2']; // analysis_status_canceled_id = 2
        break;
      case 4:
        this.analysisStatus = ['5']; // analysis_status_paid_id = 5
        break;
      case 5:
        this.analysisStatus = ['6']; // analysis_status_rejected_id = 6
        break;
      case 6:
        this.analysisStatus = ['7']; // analysis_status_prescribed_id = 7
        break;
      case 7:
        this.analysisStatus = []; // special case for pending sinisters
        break;
    }
  }

  // Method to assign the value of the common_indicator parameter
  getCommonIndicator(subcategory) {
    switch (subcategory) {
      case 'volume':
        this.commonIndicator = 'v';   // sinister_bouquet_indicator_volume="v"
        break;
      case 'severity':
        this.commonIndicator = 's';   // sinister_bouquet_indicator_severity="s"
        break;
      case 'frequency':
        this.commonIndicator = 'f';   // sinister_bouquet_indicator_frequency="f"
        break;
      case 'payment_amount':
        this.commonIndicator = 'pa';  // sinister_bouquet_indicator_payment_amount="pa"
        break;
      case 'reservation_amount':
        this.commonIndicator = 'ra';  // sinister_bouquet_indicator_reservation_amount="ra"
        break;
      case 'pending':
        this.commonIndicator = 'p';   // sinister_bouquet_indicator_pending="p"
        break;
    }
  }

  // Method to format the right y axis labels
  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value);
  }

  // Method to assign the bar graph color
  private assignColorBar() {
    this.customColors.push(
      {
        name: this.manageableLabel,
        value: COLORS.darkBlue
      },
      {
        name: this.notManageableLabel,
        value: COLORS.lightBlue
      }
    );
  }

  setFiltersWithPreviousMonth(filters: any[] = []) {
    let newFilters: any[] = _.cloneDeep(filters);
    let actualMonth: number = parseInt(filters.filter(f => f.name == "month")[0].value);
    let actualYear: number = parseInt(filters.filter(f => f.name == "year")[0].value);
    let previousMonth: string = actualMonth != 1 ? this.utilsService.padWithZeros(actualMonth - 1, 2) : "12";
    let previousYear: string = actualMonth == 1 ? (actualYear - 1).toString() : actualYear.toString();
    for (let filter of newFilters) {
      if (filter.name == "month") filter.value = previousMonth;
      if (filter.name == "year") filter.value = previousYear;
    }

    return newFilters;
  }

  // Method to assign the subcategory unit
  private assignSubcategoryUnit(subcategoryId: string): string {
    if ((subcategoryId === 'reservation_amount' || subcategoryId === 'ra') || (subcategoryId === 'payment_amount' || subcategoryId === 'pa')) {
      return this.currencySymbol;
    } else if (subcategoryId === 'frequency' || subcategoryId === 'f') {
      return '%';
    } else {
      return '';
    }
  }

  activate(e) {
    this.tooltipTitle = e.value.series + ' · ' + e.value.label;
    this.tooltipValue = Intl.NumberFormat("de-DE").format(e.value.value) + this.assignSubcategoryUnit(this.commonIndicator);
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => {
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

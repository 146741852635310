<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex">
            <div class="d-flex align-items-center">
                <span class="title section-card-subtitle">{{ 'panels.persistencePostRetention' | translate }}</span>
                <app-btn-gobernance-info 
                    class="ml-2" 
                    [endpoint]="endpoint" 
                    [endpointParams]="endpointParams"
                    *ngIf="hasGobernanceInfo">
                </app-btn-gobernance-info>
            </div>
            <mat-icon class="excel-icon ml-2" svgIcon="excel" (click)="exportAsXLSX()" *ngIf="loaded"></mat-icon>
        </div>
        <div class="d-flex">
            <div class="dropdown-container dropdown-light mr-2">
                <app-dropdown
                    [title]="title"
                    [items]="retentionReasonOption"
                    [dropdownId]="dropdownId">
                </app-dropdown>
            </div>        
            <div class="dropdown-container dropdown-light">
                <app-dropdown-multiple
                [title]="title"
                [placeholder]="placeholderName"
                [dropdownList]="dateOptions"
                [selectedItems]="selectedDateOptions"
                [enableCheckAll]="enableCheckAll"
                [limitSelection]="limitSelection"
                formName="cancellationPostRetentionPersistance">
                </app-dropdown-multiple>
            </div>
        </div>
    </div>


    <div class="mt-1">
        <persistence-postretention-graphic></persistence-postretention-graphic>
    </div>

</app-widget>
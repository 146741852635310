<app-widget>

    <!-- <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <span class="title section-card-title">{{ 'panels.lossRatio' | translate }}</span>
        <app-pager [pages]="pages" (pageChange)="getSection($event)"></app-pager>
    </div>

    <div class="sections-container">
        <app-loss-ratio-evolution *ngIf="pageName == 'lr-evolution'"></app-loss-ratio-evolution>
        <app-loss-ratio-line-graph *ngIf="pageName == 'lr-line-graph'"></app-loss-ratio-line-graph>
        <app-loss-ratio-table *ngIf="pageName == 'lr-table'"></app-loss-ratio-table>
    </div> -->

</app-widget>
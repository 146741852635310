<div class="d-flex justify-content-center mt-4">
    <component-loader [width]="450" [height]="229" *ngIf="!pieGraphLoaded"></component-loader>
    <div class="clients-occupation-product-pie-chart pie-graph-gen mt-1" *ngIf="pieGraphLoaded">
        <ngx-charts-pie-chart 
            [view]="viewPie" 
            [scheme]="colorSchemePie" 
            [results]="OccupationToPrint" 
            [gradient]="gradientPie"
            [legend]="showLegendPie"
            [legendTitle]="legendTitlePie" 
            [legendPosition]="legendPositionPie" 
            [labels]="showLabelsPie" 
            [doughnut]="isDoughnutPie"
            [arcWidth]="arcWidth"
            [tooltipDisabled]="tooltipDisabledPie"
            [animations]="animationsPie"
            [labelFormatting]="labelFormatting.bind(this, OccupationToPrint)"
            [customColors]="customColorsPieGraph"
            (activate)="activate($event)">
            <ng-template #tooltipTemplate let-model="model">
                <div class="pie-tooltip">
                    <span class="pie-tooltip-title d-block">{{ tooltipTitle }}</span>
                    <span class="pie-tooltip-data d-block">{{ tooltipNumber }}</span>
                </div>
            </ng-template>
        </ngx-charts-pie-chart>
        <span class="material-icons fullscreen-icon text-light" (click)="openPieChartOccupationDialog()" *ngIf="pieGraphLoaded">fullscreen</span>
    </div>
</div>
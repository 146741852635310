import { Component, Input, HostListener, } from '@angular/core';
import { BaseChartComponent, ViewDimensions, ColorHelper, calculateViewDimensions } from '@swimlane/ngx-charts';

import { curveLinear } from 'd3-shape';
import { scaleBand, scaleLinear, scalePoint, scaleTime } from 'd3-scale';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'stacked-vertical-horizontal-graph',
  templateUrl: './stacked-vertical-horizontal-graph.component.html',
  styleUrls: ['./stacked-vertical-horizontal-graph.component.scss'],
})
export class StackedVerticalHorizontalGraphComponent extends BaseChartComponent {
  
  // Input variables to configure the chart options
  // Show or hide the legend
  @Input() legend;

  // The legend title
  @Input() legendTitle;

  // The legend position ('below' or 'right')
  @Input() legendPosition;

  // Show or hide the X-axis
  @Input() xAxis;

  // Show or hide the Y-axis
  @Input() yAxis;

  // Show or hide the right Y-axis
  @Input() rightYAxis;

  // Show or hide the X-axis label
  @Input() showXAxisLabel;

  // Show or hide the Y-axis label
  @Input() showYAxisLabel;

  // Show or hide the Y-axis label for the right edge
  @Input() showRightYAxisLabel;

  // The X-axis label text
  @Input() xAxisLabel;

  // The Y-axis label text
  @Input() yAxisLabel;

  // The Y-axis label text
  @Input() barPadding;

  // The Y-axis label text for the right edge
  @Input() yAxisLabelRight;

  // Show or hide the tooltip
  @Input() tooltipDisabled;

  // Fill elements with a gradient instead of a solid color
  @Input() gradient;

  // Show or hide the grid lines
  @Input() showGridLines;

  // The color scale type. Can be either 'ordinal' or 'linear'
  @Input() schemeType;

  // The X-axis tick formatting
  @Input() xAxisTickFormatting;

  // The Y-axis tick formatting
  @Input() yAxisTickFormatting;

  // The Y-axis tick formatting for the right edge
  @Input() yRightAxisTickFormatting;

  // Round domaines for aligned gridlines
  @Input() roundDomains;

  // Color scheme for the horizontal lines representation
  @Input() colorSchemeLine;

  // Data for the horizontal line representation
  @Input() lineChart;

  // Y-axis scale factor for the left edge
  @Input() yLeftAxisScaleFactor;

  // Y-axis scale factor for the right edge
  @Input() yRightAxisScaleFactor;

  // Enable animations
  @Input() animations;

  // Hide bar if value is 0 and setting is true
  @Input() noBarWhenZero;

  // Predefined list of x axis tick values
  @Input() xAxisTicks

  @Input() rotateXAxisTicks

  // Show the total value in the tooltip
  @Input() showTotalInTooltip
  
  // Hide the horizontal line(s)
  @Input() hideHorizontalLine: boolean = false;

  // Show the value and its corresponding total percentage
  @Input() showValuesAndPercentageInTooltip: boolean = false;

  // Show the percentage symbol in tooltip
  @Input() showPercentageSymbolInTooltip: boolean = false;

  // Store 'Date' traduction
  @Input() dateName;

  // Component variables
  dims: ViewDimensions;
  curve: any = curveLinear;
  activeEntries: any[] = [];
  xScale: any;
  yScale: any;
  xDomain: any;
  yDomain: any;
  transform: string;
  colors: ColorHelper;
  colorsLine: ColorHelper;
  margin: any[] = [0, 0, 0, 0];
  xAxisHeight: number = 0;
  yAxisWidth: number = 20;
  legendOptions: any;
  scaleType: string = 'linear';
  xScaleLine: any;
  yScaleLine: any;
  xDomainLine: any;
  yDomainLine: any;
  seriesDomain: any;
  scaledAxis: any;
  combinedSeries: any;
  xSet: any;
  filteredDomain: any;
  hoveredVertical: any;
  yOrientLeft: string = 'left';
  yOrientRight: string = 'right';
  legendSpacing: number = 0;
  bandwidth: any;
  // barPadding: number = 8;
  rangeFillOpacity: number;
  yScaleMax: number;

  // Variables for the vertical graph
  showVerticalDataLabel: boolean = false;
  verticalDataLabelFormatting: any;
  verticalAnimations: boolean = false;
  verticalRoundEdges: boolean = false;
  verticalLegend: boolean = false;
  verticalLegendTitle: string = '';
  verticalLegendPosition: string = '';
  showVerticalXAxisLabel: boolean = false;
  showVerticalYAxisLabel: boolean = false;
  verticalXAxisLabel: string = "";
  verticalYAxisLabel: string = "";

  // Method to update the chart dimensions and options
  update(): void {
    super.update();

    // Calculation of dimensions
    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: this.legend,
      legendType: this.schemeType,
      legendPosition: this.legendPosition
    });

    // Calculation of the X and Y axis domains for the horizontal graph
    this.xDomainLine = this.getXDomainLine();
    this.yDomainLine = this.getYDomainLine();
    if (this.filteredDomain) {
      this.xDomainLine = this.filteredDomain;
    }

    // Calculation of the X and Y scales for the horizontal graph
    this.xScale = this.getXScale();
    this.yScale = this.getYScale();


    // Calculation of the scales for the vertical graph
    this.scaleLines();

    // Calculation of the X and Y axis domains for the vertical graph
    this.seriesDomain = this.getSeriesDomain();

    // Calculation of the graph colors
    this.setColors();

    // Calculation of the legend options
    this.legendOptions = this.getLegendOptions();

    // Transformation to center the graph
    this.transform = 'translate(60, 10)';

  }

  // Method to hide the horizontal graph circle data value when mouse leave
  @HostListener('mouseleave')
  hideCircles(): void {
    this.hoveredVertical = null;
  }

  // Method to show the horizontal graph circle data value when mouse leave
  updateHoveredVertical(item): void {
    this.hoveredVertical = item.value;
  }

  // Method for updating the domains
  private updateDomain(domain): void {
    this.filteredDomain = domain;
    this.xDomainLine = this.filteredDomain;
    this.xScaleLine = this.getXScaleLine(this.xDomainLine, this.dims.width);
  }

  // Method for calculating the scales of X and Y axis
  private scaleLines() {
    this.xScaleLine = this.getXScaleLine(this.xDomainLine, this.dims.width);
    this.yScaleLine = this.getYScaleLine(this.yDomainLine, this.dims.height);
  }

  // Method for adding the vertical series data to the common array
  getSeriesDomain(): any[] {
    this.combinedSeries = [];
    const horizontalSeries = this.lineChart.slice(0);

    for (let serie of horizontalSeries) {
      const values: any = [];
      const dates: any = [];
      for (let item of serie.series) {
        let dataValue = (item.value ? Intl.NumberFormat("de-DE").format(item.value) : 0);

        if (this.showPercentageSymbolInTooltip) {
          dataValue = dataValue +'%';
        }

        values.push({
          name: item.name, 
          value: (this.showValuesAndPercentageInTooltip ? '' : (item.name + ' - ')) + dataValue
        });

        if (this.showValuesAndPercentageInTooltip) {
          dates.push({
            name: item.name,
            value: item.name
          });
        }
        
      }

      if (this.showValuesAndPercentageInTooltip) {
        this.combinedSeries.push({
          name: this.dateName,
          series: dates
        });
      }

      this.combinedSeries.push({
        name: serie.name,
        series: values
      });
      
    }

    const verticalSeriesNumber = this.getVerticalSeriesNumber();

    if (this.showValuesAndPercentageInTooltip) {
      for (let _i = 0; _i < verticalSeriesNumber.length; _i++) {
        const values: any = [];
        for (let [_j, serie] of this.results.entries()) {
          const actionValue = serie.series[_i] ? serie.series[_i].value : 0;
          const percentageValue = (actionValue/horizontalSeries[0].series[_j].value*100).toFixed(2);
          values.push({
            name: serie.name, 
            value: Intl.NumberFormat("de-DE").format(actionValue) + ' (' + Intl.NumberFormat("de-DE").format(parseFloat(percentageValue)) + '%)'
          })
        }
        this.combinedSeries.push({
          name: verticalSeriesNumber[_i],
          series: values
        })
      }

    } else {
      for (let _i = 0; _i < verticalSeriesNumber.length; _i++) {
        const values: any = [];
        for (let serie of this.results) {
          let filteredValue = serie.series.filter(v => v.name === verticalSeriesNumber[_i]);
          values.push({
            name: serie.name, 
            value: serie.name + ' - ' + (filteredValue.length !== 0 ? Intl.NumberFormat("de-DE").format(filteredValue[0].value) : 0)
          });
        }
        this.combinedSeries.push({
          name: verticalSeriesNumber[_i],
          series: values
        });
      }

      if (this.showTotalInTooltip) { 

        let total: any = [];
        for (let serie of this.results) {
          let totalValue = 0;
          if (serie.series.length !== 0) {
            totalValue = serie.series.map(v => v.value).reduce((x,y) => x+y);
          }
          total.push({
            name: serie.name, 
            value: serie.name + ' - ' + Intl.NumberFormat("de-DE").format(totalValue)
          });
        }

        this.combinedSeries.push({
          name: 'Total',
          series: total
        });
      }
    }

    return this.combinedSeries.map(d => d.name);
  }

  // Method to determine if the data type is 'Date'
  private isDate(value): boolean {
    if (value instanceof Date) {
      return true;
    }

    return false;
  }

  // Method to determine the type of scale (data, linear or ordinal)
  private getScaleType(values): string {
    let date = true;
    let num = true;

    for (const value of values) {
      if (!this.isDate(value)) {
        date = false;
      }

      if (typeof value !== 'number') {
        num = false;
      }
    }

    if (date) return 'time';
    if (num) return 'linear';
    return 'ordinal';
  }

  // Method to get the X axis data domaine for the horizontal graph
  private getXDomainLine(): any[] {
    let values = [];

    if (this.lineChart.length !== 0) {
      for (const results of this.lineChart) {
        for (const d of results.series) {
          if (!values.includes(d.name)) {
            values.push(d.name);
          }
        }
      }
    } else {
      values = this.results.map(v => v.name);
    }

    this.scaleType = this.getScaleType(values);
    let domain = [];

    if (this.scaleType === 'time') {
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else if (this.scaleType === 'linear') {
      values = values.map(v => Number(v));
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else {
      domain = values;
    }

    this.xSet = values;
    return domain;
  }

  // Method to get the Y axis data domaine for the horizontal graph
  private getYDomainLine(): any[] {
    const domain = [];

    for (const results of this.lineChart) {
      for (const d of results.series) {
        if (domain.indexOf(d.value) < 0) {
          domain.push(d.value);
        }
        if (d.min !== undefined) {
          if (domain.indexOf(d.min) < 0) {
            domain.push(d.min);
          }
        }
        if (d.max !== undefined) {
          if (domain.indexOf(d.max) < 0) {
            domain.push(d.max);
          }
        }
      }
    }

    let min = Math.min(...domain);
    const max = Math.max(...domain);
    if (this.yRightAxisScaleFactor) {
      const minMax = this.yRightAxisScaleFactor(min, max);
      return [Math.min(0, minMax.min), minMax.max];
    } else {
      min = Math.min(0, min);
      return [min, max];
    }
  }

  // Method to retrieve the X axis scale for the horizontal graph
  private getXScaleLine(domain, width): any {
    let scale;
    if (this.bandwidth === undefined) {
      this.bandwidth = width - this.barPadding;
    }
    const offset = Math.floor((width + this.barPadding - (this.bandwidth + this.barPadding) * domain.length) / 2);

    if (this.scaleType === 'time') {
      scale = scaleTime().range([0, width]).domain(domain);
    } else if (this.scaleType === 'linear') {
      scale = scaleLinear().range([0, width]).domain(domain);

      if (this.roundDomains) {
        scale = scale.nice();
      }
    } else if (this.scaleType === 'ordinal') {
      scale = scalePoint()
        .range([offset + this.bandwidth / 2, width - offset - this.bandwidth / 2])
        .domain(domain);
    }

    return scale;
  }

  // Method to retrieve the Y axis scale for the line graph
  private getYScaleLine(domain, height): any {
    const scale = scaleLinear().range([height, 0]).domain(domain);

    return this.roundDomains ? scale.nice() : scale;
  }

  // Method for calculating the X axis scale
  private getXScale(): any {
    this.xDomain = this.getXDomain();
    const spacing = this.xDomain.length / (this.dims.width / this.barPadding + 1);
    return scaleBand().range([0, this.dims.width]).paddingInner(spacing).domain(this.xDomain);
  }

  // Method for calculating the Y axis scale
  private getYScale(): any {
    this.yDomain = this.getYDomain();

    if (this.rightYAxis) {
      const scale = scaleLinear().range([this.dims.height, 0]).domain(this.yDomain);
      return this.roundDomains ? scale.nice() : scale;
    } else {
      const min = Math.min(this.yDomain[0], this.yDomainLine[0]);
      const max = Math.max(this.yDomain[1], this.yDomainLine[1]);

      const scale = scaleLinear().range([this.dims.height, 0]).domain([min, max]);
      return this.roundDomains ? scale.nice() : scale;
    }

  }

  // Method to get the X axis data domain
  getXDomain(): any[] {
    return this.results.map(d => d.name);
  }

  // Method to get the Y axis data domain
  getYDomain() {
      const domain = [];
      let smallest = 0;
      let biggest = 0;
      for (const group of this.results) {
        let smallestSum = 0;
        let biggestSum = 0;
        for (const d of group.series) {
          if (d.value < 0) {
            smallestSum += d.value;
          } else {
            biggestSum += d.value;
          }
          smallest = d.value < smallest ? d.value : smallest;
          biggest = d.value > biggest ? d.value : biggest;
        }
        domain.push(smallestSum);
        domain.push(biggestSum);
      }
      domain.push(smallest);
      domain.push(biggest);

      const min = Math.min(0, ...domain);
      const max = this.yScaleMax ? Math.max(this.yScaleMax, ...domain) : Math.max(...domain);
      return [min, max];
      
  }

  // Method to set the colors of the graph
  private setColors(): void {
    let domain;
    if (this.schemeType === 'ordinal') {
      domain = this.xDomain;
    } else {
      domain = this.yDomain;
    }
    this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
    this.colorsLine = new ColorHelper(this.colorSchemeLine, this.schemeType, domain, this.customColors);
  }

  // Method to set the legend options
  private getLegendOptions() {
    const opts = {
      scaleType: this.schemeType,
      colors: undefined,
      domain: [],
      title: undefined,
      position: this.legendPosition
    };
    if (opts.scaleType === 'ordinal') {
      opts.domain = this.seriesDomain;
      opts.colors = this.colorsLine;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.seriesDomain;
      opts.colors = this.colors.scale;
    }
    return opts;
  }

  // Method for updating the horizontal graph width
  updateLineWidth(width): void {
    this.bandwidth = width;
    this.scaleLines();
  }

  // Method for updating the Y axis width
  updateYAxisWidth({ width }): void {
    this.yAxisWidth = width + 20;
    //this.update();
  }

  // Method for updating the X axis height
  updateXAxisHeight({ height }): void {
    this.xAxisHeight = height;
    //this.update();
  }

  // Method to get the number of vertical series
  private getVerticalSeriesNumber(): any {
    const values = []; 
    for (const period of this.results) {
      for (const d of period.series) {
        if (!values.includes(d.name)) {
          values.push(d.name);
        }
      }
    }
    return values;
  }

}
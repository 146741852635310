<div class="big-button-grid big-button-grid-sale">

    <div class="big-button-grid-main-container">
        <div class="big-button-grid-top">
            <div class="section-header-block d-flex justify-content-between align-items-center cursor-pointer" [routerLink]="['/sales/details']">
                <span class="title section-card-title-btn-grid">{{ 'panels.sales' | translate | uppercase }}</span>
                <mat-icon class="home-arrows-right arrow-round-right fill-white" svgIcon="arrow-right-round"></mat-icon>
            </div>
        
            <component-loader *ngIf="!loaded" class="mx-5 text-center transparent-background" [width]="" [height]="340"></component-loader>    

            <div *ngIf="loaded">
                <div class="home-kpi-block d-flex justify-content-between align-items-start">
                    <div class="col-6 pl-0 pr-2">
                        <app-kpi-home [item]="highLowPolicy"></app-kpi-home>
                    </div>
                    <div class="col-6 pl-2 pr-0">
                        <app-kpi-home [item]="policyQNumber"></app-kpi-home>
                    </div>
                </div>
                <div class="home-kpi-block d-flex justify-content-between align-items-start">
                    <div class="col-6 pl-0 pr-2">
                        <app-kpi-home [item]="bonusPxQ"></app-kpi-home>
                    </div>
                    <div class="col-6 pl-2 pr-0">
                        <app-kpi-home [item]="gwp"></app-kpi-home>
                    </div>
                </div>
                <div class="home-kpi-block d-flex justify-content-between align-items-start">
                    <div class="col-6 pl-0 pr-2">
                        <app-kpi-home [item]="projectionCrPlan"></app-kpi-home>
                    </div>
                    <div class="col-6 pl-2 pr-0">
                        <app-kpi-home [item]="projectionOmPlan"></app-kpi-home>
                    </div>
                </div>
            </div>
        </div>

        <div class="big-button-grid-bottom">
            <button 
                type="button" 
                class="btn btn-block btn-gradient-light mt-0 mb-3 d-flex justify-content-between align-items-center"
                [routerLink]="['/sales/details']">
                <app-legend name="panels.dailySale" color="transparent" type="round" gradient="h-gradient"></app-legend>
                <span class="material-icons">keyboard_arrow_right</span>
            </button>
            <button 
                type="button" 
                class="btn btn-block btn-gradient-light mt-0 d-flex justify-content-between align-items-center"
                [routerLink]="['/sales/mix-portfolio-gwp']">
                <app-legend name="panels.gwp" color="transparent" type="round" gradient="h-gradient"></app-legend>
                <span class="material-icons">keyboard_arrow_right</span>
            </button>
        </div>

    </div>

    <div class="big-button-grid-bg">
        <img src="assets/img/bg-sale.jpg">
    </div>

</div>
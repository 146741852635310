import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpHeaders
} from '@angular/common/http';
import { Observable, from, EMPTY } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { MsalService } from './msal.service';
import { Minimatch } from "minimatch";
import { AuthenticationResult, InteractionType } from "@azure/msal-browser";
import { Injectable, Inject } from '@angular/core';
import { MSAL_INTERCEPTOR_CONFIG } from './constants';
import { MsalInterceptorConfig } from './msal.interceptor.config';
import { Router } from '@angular/router';
import { EventsService } from '../../services/events/events.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class MsalInterceptor implements HttpInterceptor {

    sendLoginOk: boolean = false;

    constructor(
        @Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfig: MsalInterceptorConfig,
        private authService: MsalService,
        private router: Router,
        private eventsService: EventsService,
        private cookieService: CookieService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const scopes = this.getScopesForEndpoint(req.url);
        const account = this.authService.getAllAccounts()[0];

       
         if(req.url.includes('/oidc')){
            return next.handle(req);
         }

        
           // console.log('Interceptor: AdquireTokenSilent');
          // Note: For MSA accounts, include openid scope when calling acquireTokenSilent to return idToken
           return this.authService.acquireTokenSilent({scopes, account})
            .pipe(
                catchError((e) => {
                    if (this.msalInterceptorConfig.interactionType === InteractionType.Popup) {
                         return this.authService.acquireTokenPopup({...this.msalInterceptorConfig.authRequest, scopes});
                    }
                    const redirectStartPage = window.location.href;
                    this.authService.acquireTokenRedirect({...this.msalInterceptorConfig.authRequest, scopes, redirectStartPage});
                    return EMPTY;
                }),
                switchMap((result: AuthenticationResult) => {
                    if(!this.checkSessionCookies()){                        
                        this.navigateToLogin();
                        return;
                    }else{

                        if(this.sendLoginOk == false) {
                            this.eventsService.authenticationOk$.emit(true);
                            this.sendLoginOk = true;
                        }

                        const headers = req.headers
                        //.set('Authorization', 'Bearer '+this.cookieService.get('iSession'))
                        .set('iSession', this.cookieService.get('iSession'))
                        .set('jSessionID',this.cookieService.get('jSessionID'));
        
                        const requestClone = req.clone({headers});
                                    
            
                        return next.handle(requestClone);        
                                        
                    }
                })
            );        

        //}

        
        


    }

    checkSessionCookies(){
        const isession = this.cookieService.get('iSession');    
        const jsessionid = this.cookieService.get('jSessionID');  
        const accessToken = this.cookieService.get('access_token');    
        const idToken = this.cookieService.get('id_token');  

        if(isession && jsessionid && idToken && accessToken){
          return true;
        } else{

          return false;
        } 
      }     

    private getScopesForEndpoint(endpoint: string): Array<string>|null {
        const protectedResourcesArray = Array.from(this.msalInterceptorConfig.protectedResourceMap.keys());
        const keyMatchesEndpointArray = protectedResourcesArray.filter(key => {
            const minimatch = new Minimatch(key);
            return minimatch.match(endpoint) || endpoint.indexOf(key) > -1;
        });

        // process all protected resources and send the first matched resource
        if (keyMatchesEndpointArray.length > 0) {
            const keyForEndpoint = keyMatchesEndpointArray[0];
            if (keyForEndpoint) {
                return this.msalInterceptorConfig.protectedResourceMap.get(keyForEndpoint);
            }
        }

        return null;
    }

    private navigateToLogin() {
        this.eventsService.forceCheckLogin$.emit('');
        //this.router.navigate(['/login']);
        // this.eventsService.loginRedirection$.emit(true);
        // this.eventsService.loginRedirectionSubject$.next(true);
    }

}

import { Component, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { PortfolioIndicator } from 'src/app/shared/models/portfolio-indicator';
import { takeUntil } from 'rxjs/operators';
import { MESSAGES } from 'src/app/shared/globals/globals';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { environment } from '../../../../environments/environment';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import _ from 'lodash';

@Component({
  selector: 'app-portfolio-indicators',
  templateUrl: './portfolio-indicators.component.html',
  styleUrls: ['./portfolio-indicators.component.scss']
})
export class PortfolioIndicatorsComponent implements OnInit {

  data_1: any[] = [
    [ "Data 1 Column 1", "Data 1 Column 2", "Data 1 Column 3", "Data 1 Column 4", "Data 1 Column 5", "Data 1 Column 6", "Data 1 Column 7" ],
    [  1, 2, 3, 4, 5, 6, 7 ],
    [  8, 9, 10, 11, 12, 13, 14 ]
  ];

  data_2: any[] = [
    [ "Data 2 Column 1 bis", "Data 2 Column 2 bis", "Data 2 Column 3 bis", "Data 2 Column 4 bis", "Data 2 Column 5 bis", "Data 2 Column 6 bis", "Data 2 Column 7 bis" ],
    [  15, 16, 3, 4, 5, 6, 7 ],
    [  8, 9, 10, 11, 12, 13, 14 ]
  ];

  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "sale-portfolio--indicators";

  percentagePrevious: string = "labels.pm";

  data_total: any[] = [this.data_1, this.data_2];
  data_sheet_names: any[] = ['Portfolio', 'New business'];
  
  // Component variables 
  filtersValues: any;
  portfolio_indicators_valid='v';
  portfolio_indicators_newsale='n';
  portfolio_indicators_renovation='r';
  portfolio_indicators_cancellation='c';
  loadedValid: boolean = false;
  loadedNewSale: boolean = false;
  loadedRenovation: boolean = false;
  loadedCancellation: boolean = false;

  // Variables to store data
  portfolioIndicatorsValid: PortfolioIndicator;
  portfolioIndicatorsNewSale: PortfolioIndicator;
  portfolioIndicatorsRenovation: PortfolioIndicator;
  portfolioIndicatorsCancellation: PortfolioIndicator;

  titleValidCertificate: string = 'panels.policyPortfolio';
  titleValidPremium: string = 'panels.bonusTotalPortfolio';
  titleValidAvgPremium: string = 'panels.bonusAveragePortfolio';

  titleNewSaleCertificate: string = 'panels.policiesNewBusiness';
  titleNewSalePremium: string = 'panels.bonusTotalNewBusiness';
  titleNewSaleAvgPremium: string = 'panels.bonusAverageNewBusiness';

  titleRenovationCertificate: string = 'panels.policiesRenewal';
  titleRenovationPremium: string = 'panels.bonusTotalRenewal';
  titleRenovationAvgPremium: string = 'panels.bonusAverageRenewal';

  titleCancellationCertificate: string = 'panels.policiesCancelation';
  titleCancellationPremium: string = 'panels.bonusTotalCancelation';
  titleCancellationAvgPremium: string = 'panels.bonusAverageCancelation';

  // Conditions for print

  // Subscription
  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();

  // Default constructor
  constructor(
    public apimstService: ApimstService,
    public filtersService: FiltersService,
    public eventsService: EventsService,
    private excelService: ExcelService,
    private atlasHelper: AtlasHelperService,
    private i18n: I18nService
  ) { }

  // OnInit
  ngOnInit(): void {
    this.subscribeToActiveLanguage();

    this.subscription = this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        if (data.length !== 0) {
          this.loadedValid = false;
          this.loadedNewSale = false;
          this.loadedRenovation = false;
          this.loadedCancellation = false;

          this.retrievePortfolioIndicators(this.portfolio_indicators_valid, data);        // Portfolio
          this.retrievePortfolioIndicators(this.portfolio_indicators_newsale, data);      // New sale
          this.retrievePortfolioIndicators(this.portfolio_indicators_renovation, data);   // Renovation
          this.retrievePortfolioIndicators(this.portfolio_indicators_cancellation, data); // Cancellation
        }
      }
    )

  }

  // Function to fetch portfolio indicators data
  private retrievePortfolioIndicators(portfolio_indicator: string, filters: any) {

    this.subscription = this.apimstService.getPortfolioIndicators(portfolio_indicator, filters).subscribe(
      data => {
        let indicatorData: any = data;
        indicatorData != undefined ? indicatorData = data : indicatorData = {};

        switch(portfolio_indicator) {
          case this.portfolio_indicators_valid: {
            this.portfolioIndicatorsValid = indicatorData;
            this.loadedValid = true;
            break;
          }
          case this.portfolio_indicators_newsale: {
            this.portfolioIndicatorsNewSale = indicatorData;
            this.loadedNewSale = true;
            break;
          }
          case this.portfolio_indicators_renovation: {
            this.portfolioIndicatorsRenovation = indicatorData;
            this.loadedRenovation = true;
            break;
          }
          case this.portfolio_indicators_cancellation: {
            this.portfolioIndicatorsCancellation = indicatorData;
            this.loadedCancellation = true;
            break;
          }
          default: 
            break;
        }
      },

      error => console.log("An error ocurred while retrieving portfolio indicators data" + error)
    );
  }

  exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.data_total, this.data_sheet_names,  'portfolio_indicators', []);
 }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'numberFormat' })
export class NumberPipe implements PipeTransform {

    // Transform method - String to number with decimal symbol replacement
    transform(data: string): number {
        let value = data ? parseFloat(parseFloat(data.replace(/,/g, '')).toFixed(2)) : 0;
        return value;
    }
}

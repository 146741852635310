<div class="renewals-cancellations-wrapper">

    <div class="mt-3" *ngIf="!loaded">
        <component-loader [width]="" [height]="230"></component-loader>
    </div>

    <div class="legend-container container pt-2 pb-4 px-2" *ngIf="loaded">
        <div class="row justify-content-end align-items-center">
            <app-legend name="labels.nb" color="light-blue" type="round" gradient="v-gradient"></app-legend>
            <app-legend name="labels.renewal" color="dark-blue" type="round" gradient="v-gradient"></app-legend>
            <app-legend name="labels.cancellation" color="gray" type="round" gradient="v-gradient"></app-legend>
            <app-legend name="labels.churnRate" color="pink" type="line" gradient="h-gradient"></app-legend>
            <mat-icon *ngIf="loaded" class="excel-icon ml-2" svgIcon="excel" (click)="exportAsXLSX()"></mat-icon>
        </div>
    </div>
    <grouped-vertical-horizontal-graph-d-axis *ngIf="loaded"
    [view]="view"
    [scheme]="comboBarScheme"
    [results]="portfolioChurnGroupedVerticalGraph"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [barPadding]=1
    [groupPadding]=2
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [legendTitle]="legendTitle"
    [lineChart]="portfolioChurnHorizontalGraph"
    [showGridLines]="showGridLines"
    [showRightGridLines]="showRightGridLines"
    [customColors]="customColors"
    [roundEdges]="roundEdges"
    [activeEntries]="activeEntries"
    [trimXAxisTicks]="trimXAxisTicks"
    [trimYAxisTicks]="trimXAxisTicks"
    [rotateXAxisTicks]="rotateXAxisTicks"
    [maxXAxisTickLength]="maxXAxisTickLength"
    [maxYAxisTickLength]="maxYAxisTickLength"
    [tooltipDisabled]="tooltipDisabled"
    [roundDomains]="roundDomains"
    [yAxisTickFormatting]="yAxisTickFormatting"
    [yRightAxisTickFormatting]="yRightAxisTickFormatting"
    [showPercentageSymbolInTooltip]="showPercentageSymbolInTooltip"
    >
    </grouped-vertical-horizontal-graph-d-axis>
</div>

import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { Subject} from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { OK } from 'src/app/shared/globals/globals';
import { CountryLoadDialogComponent } from 'src/app/shared/dialogs/country-load-dialog/country-load-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CountryLoadEntity } from 'src/app/shared/models/country-load-entity';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from '../../services/events/events.service';
import _ from 'lodash';

@Component({
  selector: 'app-home-main-block',
  templateUrl: './home-main-block.component.html',
  styleUrls: ['./home-main-block.component.scss']
})
export class HomeMainBlockComponent implements OnInit {
  unsubscribe$: Subject<any> = new Subject<any>();
  loaded: boolean = false;
  filtersValues: any[];  
  labelColor: string;
  OK: string = OK;
  mainLabel1: string;
  mainLabel2: string;  
  modalView = [250, 250];
  countryLoadData: Array<CountryLoadEntity>;
  lastLoad: string;
  countryName: string;
  countries: any = [];

  dialogRef: MatDialogRef<CountryLoadDialogComponent>;

  constructor(
    private dialog: MatDialog,
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private translateService: TranslateService,   
    private events: EventsService, 
    @Inject(LOCALE_ID) public locale: string
  ) { }

  ngOnInit(): void {
    this.subscribeToFiltersValues();
    this.setInitCountryValues();
    this.retrieveCountryLoadReport(this.filtersValues);
  }

  subscribeToFiltersValues(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: filtersValues => {
          this.filtersValues = filtersValues;
          this.loaded = false;
        }
      })
  } 

  retrieveCountryLoadReport(filtersValues: any[] = []): void {

    this.apimstService.getCountryLoadReport(filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: data => {
          this.loaded = true;
          if(data == null || data.length == 0) return;
          let countryLoadData = data;
          let zeroItem: any[] = countryLoadData.filter(v => v.load_item === '0');
          let loadNumber = zeroItem[0].item_name.split(" ");
          this.translateService.get('home.lastLoad').pipe(takeUntil(this.unsubscribe$))
          .subscribe(data => this.lastLoad = data);
          let country = this.filtersValues.filter(v => v.name === 'country')[0].value[0];
          this.mainLabel1 = this.lastLoad + ' ' + country + ' (' + loadNumber[1] + ') ';
          this.mainLabel2 = formatDate(zeroItem[0].date, 'dd-MM-yyyy', this.locale) + ', ' + zeroItem[0].result;
          this.labelColor = zeroItem[0].result === OK ? 'resultOk' : 'resultKo';
          let countryLoadDataFiltered = countryLoadData.filter(v => v.load_item != '0');
          this.countryLoadData = countryLoadDataFiltered.sort((first, second) => 0 - (first.load_item > second.load_item ? -1 : 1));
        },
        error: error => console.log("An error occurred while retrieving country load data " + error)  
      }
    );
  }

  // Call to open the dialog with the country loads expanded
  openDialog() {
    this.dialogRef = this.dialog.open(CountryLoadDialogComponent, {
      data : {
        sectionTitle: 'home.countryDataLoad',
        view: this.modalView,
        countryLoadData: this.countryLoadData,
        countryName: this.countryName,
        countries: this.countries
      },
      panelClass: 'dialog-country-load'
    })

    this.dialogRef.componentInstance.selectedNewCountry.subscribe((countryId: any) => {
      let modifiedFiltersValues = _.cloneDeep(this.filtersValues);
      modifiedFiltersValues.map(v => {
        if (v.name === 'country') {
          v.value = [countryId];
        }
      });
      this.apimstService.getCountryLoadReport(modifiedFiltersValues)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: data => {
            let countryLoadData = data;
            let countryLoadDataFiltered = countryLoadData.filter(v => v.load_item != '0');
            this.dialogRef.componentInstance.data.countryLoadData = countryLoadDataFiltered.sort((first, second) => 0 - (first.load_item > second.load_item ? -1 : 1));
          },
          error: error => console.log("An error occurred while retrieving country load data " + error)  
        });
    });
  } 

  setInitCountryValues(): void {
    this.events.userInfoLoaded$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        const userInfo = data;
        
        const country = this.filtersValues.filter(v => v.name === 'country')
        if(country && country[0]){
          this.countryName = country[0].valueItems[0]['item_text'];
        }
        
        for(let country of userInfo['countries']) {
          this.countries.push({
            id: country['id'],
            name: country['desc']
          });
        }
      }
    )
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }  

}

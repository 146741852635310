<div class="fullscreen-container">
    <app-home-header></app-home-header>
    <app-home-main-block></app-home-main-block>
    <div class="bg-block">
        <img src="assets/img/bg-home.jpg">
        <div class="form-1"></div>
        <div class="form-2"></div>
    </div>
    <app-user-selector></app-user-selector>
    <app-version-container></app-version-container>
</div>
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { COLORS } from 'src/app/shared/globals/globals';
import { Subscription, Subject } from 'rxjs';
import { MonthlySalePieGraph } from 'src/app/shared/models/monthly-sale-pie-graph';
import { NumberPipe } from 'src/app/shared/pipes/number.pipe';
import { takeUntil } from 'rxjs/operators'
import { CurrencyService } from 'src/app/shared/services/currency/currency.service';
import { MatDialog } from '@angular/material/dialog';
import { PieChartDialogComponent } from 'src/app/shared/components/pie-chart-dialog/pie-chart-dialog.component';

@Component({
  selector: 'app-monthly-sale-pie-graph',
  templateUrl: './monthly-sale-pie-graph.component.html',
  styleUrls: ['./monthly-sale-pie-graph.component.scss']
})
export class MonthlySalePieGraphComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;
  loadedIndicators: boolean = false;
  loadedGraph: boolean = false;
  currencySymbol: string;
  showPieDialogButton: boolean = false;

  // Variable to store the data
  pieGraphData: any[];

  CRPremiumProductToPrint: any = [];
  CRCertificateProductToPrint: any = [];
  OMPremiumProductToPrint: any = [];
  OMCertificateProductToPrint: any = [];

  OMPremiumDiscreet: any = [];
  OMPremiumAccumulated: any = [];
  OMCertificateDiscreet: any = [];
  OMCertificateAccumulated: any = [];

  CRPremiumDiscreet: any = [];
  CRPremiumAccumulated: any = [];
  CRCertificateDiscreet: any = [];
  CRCertificateAccumulated: any = [];

  percentagePrevious: string = 'PY ';
  CRPremiumDiscreetPercentage: number;
  OMPremiumDiscreetPercentage: number;
  CRPremiumAccumulatedPercentage: number;
  OMPremiumAccumulatedPercentage: number;
  CRCertificateDiscreetPercentage: number;
  OMCertificateDiscreetPercentage: number;
  CRCertificateAccumulatedPercentage: number;
  OMCertificateAccumulatedPercentage: number;

  pieGraphPremiumDiscreet: any = [];
  pieGraphPremiumAccumulated: any = [];
  pieGraphCertificateDiscreet: any = [];
  pieGraphCertificateAccumulated: any = [];

  pieGraphPremiumDiscreetCR: any = [];
  pieGraphPremiumAccumulatedCR: any = [];
  pieGraphCertificateDiscreetCR: any = [];
  pieGraphCertificateAccumulatedCR: any = [];

  pieGraphPremiumDiscreetOM: any = [];
  pieGraphPremiumAccumulatedOM: any = [];
  pieGraphCertificateDiscreetOM: any = [];
  pieGraphCertificateAccumulatedOM: any = [];

  CRcheckboxChecked: boolean = true;
  OMcheckboxChecked: boolean = true;
  discreetAccumulatedOptionSelected: string = 'panels.discreet';
  bonusPolicyOptionSelected: string = 'panels.bonus';

  bonusOption: string = 'panels.bonus';
  policyOption: string = 'panels.policy';
  discreetOption: string = 'panels.discreet';
  accumulatedOption: string = 'panels.accumulated';

  graphName: string = 'yearSales';
  productTypeCR: string = 'cr';
  productTypeOM: string = 'om';
  selectedProductType: string = '';

  // Pie chart options
  view: any[] = [190, 150];
  gradient: boolean = false;
  showLegend: boolean = true;
  showLabels: boolean = false;
  isDoughnut: boolean = false;
  legendPosition: string = 'right';
  legendTitle: string = '';
  tooltipDisabled: boolean = false;
  animations: boolean = false;
  colorScheme = {
    domain: [COLORS.lightBlue, COLORS.lightestBlue, COLORS.lightOrange, COLORS.pink, COLORS.darkGreen]
  };
  arcWidth: number = 1.5;
  tooltipTitle: string;
  tooltipNumber: string;

  CRPercentage: string = '';
  OMPercentage: string = '';

  // Pie chart dialog options
  pieDialogView: any[] = [600, 380];
  pieDialogShowLabels: boolean = true;
  pieDialogLegendPosition: string = 'below';

  @ViewChild('CRcheckbox') CRcheckbox: ElementRef;
  @ViewChild('OMcheckbox') OMcheckbox: ElementRef;

  activate(e) {
    this.tooltipTitle = e['value'].label;
    this.tooltipNumber = Intl.NumberFormat("de-DE").format(e['value'].value) + "%";
  }

  // Default constructor
  constructor(
    private dialog: MatDialog,
    private events: EventsService,
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private numberFormat: NumberPipe,
    private currencyService: CurrencyService
  ) { }

  // OnInit
  ngOnInit(): void {

    // Subscribe to fetch currency changes
    this.currencyService.currencyInfo$
    .subscribe(
      currencyInfo => {
        this.currencySymbol = currencyInfo.symbol;
      }
    )

    // Event to detect filter changes
    this.subscription = this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        if (data.length !== 0) {
          this.filtersValues = data;
          this.resetVariables();
          this.getPieGraphDataAccordingToOptions();
          this.getIndicatorsDataAccordingToOptions();
        }
      }
    )

    // Event to detect 'Discreet/Accumulated' options
    this.events.policiesBonusOption$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        const option = data[0];
        const name = data[1];
         if ((option === this.discreetOption || option === this.accumulatedOption) && name === this.graphName) {
          this.discreetAccumulatedOptionSelected = option;
          this.getPieGraphDataAccordingToOptions();
          this.getIndicatorsDataAccordingToOptions();
        } else if ((option === this.policyOption || option === this.bonusOption) && name === this.graphName) {
          this.bonusPolicyOptionSelected = option;
          this.getPieGraphDataAccordingToOptions();
          this.getIndicatorsDataAccordingToOptions();
        }
      }
    );
  }

  // Method to retrieve the pie graph data according to the selected options
  private getPieGraphDataAccordingToOptions() {
    if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === '' && this.pieGraphPremiumDiscreet.length === 0) {
      this.retrievePieGraphData('p', 'd', ['c'], this.filtersValues); // Premium - Discreet - Channels - No product type
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === '' && this.pieGraphPremiumAccumulated.length === 0) {
      this.retrievePieGraphData('p', 'a', ['c'], this.filtersValues); // Premium - Accumulate - Channels - No product type
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === '' && this.pieGraphCertificateAccumulated.length === 0) {
      this.retrievePieGraphData('c', 'a', ['c'], this.filtersValues); // Certificate - Accumulate - Channels - No product type
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === '' && this.pieGraphCertificateDiscreet.length === 0) {
      this.retrievePieGraphData('c', 'd', ['c'], this.filtersValues); // Certificate - Discreet - Channels - No product type
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === 'cr' && this.pieGraphPremiumDiscreetCR.length === 0) {
      this.retrievePieGraphData('p', 'd', ['c'], this.filtersValues); // Premium - Discreet - Channels - product type CR
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === 'cr' && this.pieGraphPremiumAccumulatedCR.length === 0) {
      this.retrievePieGraphData('p', 'a', ['c'], this.filtersValues); // Premium - Accumulate - Channels - product type CR
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === 'cr' && this.pieGraphCertificateAccumulatedCR.length === 0) {
      this.retrievePieGraphData('c', 'a', ['c'], this.filtersValues); // Certificate - Accumulate - Channels - product type CR
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === 'cr' && this.pieGraphCertificateDiscreetCR.length === 0) {
      this.retrievePieGraphData('c', 'd', ['c'], this.filtersValues); // Certificate - Discreet - Channels - product type CR
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === 'om' && this.pieGraphPremiumDiscreetOM.length === 0) {
      this.retrievePieGraphData('p', 'd', ['c'], this.filtersValues); // Premium - Discreet - Channels - product type OM
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === 'om' && this.pieGraphPremiumAccumulatedOM.length === 0) {
      this.retrievePieGraphData('p', 'a', ['c'], this.filtersValues); // Premium - Accumulate - Channels - product type OM
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === 'om' && this.pieGraphCertificateAccumulatedOM.length === 0) {
      this.retrievePieGraphData('c', 'a', ['c'], this.filtersValues); // Certificate - Accumulate - Channels - product type OM
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === 'om' && this.pieGraphCertificateDiscreetOM.length === 0) {
      this.retrievePieGraphData('c', 'd', ['c'], this.filtersValues); // Certificate - Discreet - Channels - product type OM
    } else {
      this.assignPieGraphDataToPrint();
    }
  }

  // Method to retrieve the indicators data according to the selected options
  private getIndicatorsDataAccordingToOptions() {
    if (((this.bonusPolicyOptionSelected === this.bonusOption || this.bonusPolicyOptionSelected === this.policyOption)  && this.discreetAccumulatedOptionSelected === this.accumulatedOption) && (this.OMPremiumAccumulated.length === 0 && this.CRPremiumAccumulated.length === 0 && this.OMCertificateAccumulated.length === 0 && this.CRCertificateAccumulated.length === 0)) {
      this.retrieveIndicatorsData('p', 'a', ['p'], this.filtersValues); // Premium - Accumulate - Product type
      this.retrieveIndicatorsData('c', 'a', ['p'], this.filtersValues); // Certificate - Accumulate - Product type
    } else if (((this.bonusPolicyOptionSelected === this.bonusOption || this.bonusPolicyOptionSelected === this.policyOption)  && this.discreetAccumulatedOptionSelected === this.discreetOption) && (this.OMPremiumDiscreet.length === 0 && this.CRPremiumDiscreet.length === 0 && this.OMCertificateDiscreet.length === 0 && this.CRCertificateDiscreet.length === 0)) {
      this.retrieveIndicatorsData('p', 'd', ['p'], this.filtersValues); // Premium - Discreet - Product type
      this.retrieveIndicatorsData('c', 'd', ['p'], this.filtersValues); // Certificate - Discreet - Product type
    } else {
      this.assignIndicatorsDataToPrint();
    }
  }

  // Method to retrieve the differents values for the indicators
  private retrieveIndicatorsData(type_data: string, type_set: string, dimension: any, filters: any) {

    this.loadedIndicators = false;

    this.subscription = this.apimstService.getMonthlySalesYear(type_data, type_set, dimension, filters).subscribe(
      data => {
        let monthlySaleIndicatorsData : MonthlySalePieGraph = data;
        let OMData:any = monthlySaleIndicatorsData.details.filter(data => data.name === 'Open Market (OM)')[0];
        let CRData:any = monthlySaleIndicatorsData.details.filter(data => data.name === 'Credit Related (CR)')[0];

        if(!OMData)
          OMData = []

        if(!CRData)
          CRData = []

        const OMValue = OMData.current ? parseFloat(OMData.current.replace(/,/g, '')) : 0;
        const CRValue = CRData.current ? parseFloat(CRData.current.replace(/,/g, '')) : 0;
        const total = OMValue + CRValue;

        if (type_data === 'p' && type_set === 'd') {
          this.OMPremiumDiscreet = OMData;
          this.CRPremiumDiscreet = CRData;
          this.OMPremiumDiscreetPercentage = total !== 0 ? (OMValue / total)*100 : 0
          this.CRPremiumDiscreetPercentage = total !== 0 ? (CRValue / total)*100 : 0
          this.loadedIndicators = true;
        } else if (type_data === 'p' && type_set === 'a') {
          this.OMPremiumAccumulated = OMData;
          this.CRPremiumAccumulated = CRData;
          this.OMPremiumAccumulatedPercentage = total !== 0 ? (OMValue / total)*100 : 0
          this.CRPremiumAccumulatedPercentage = total !== 0 ? (CRValue / total)*100 : 0
          this.loadedIndicators = true;
        } else if (type_data === 'c' && type_set === 'd') {
          this.OMCertificateDiscreet = OMData;
          this.CRCertificateDiscreet = CRData;
          this.OMCertificateDiscreetPercentage = total !== 0 ? (OMValue / total)*100 : 0
          this.CRCertificateDiscreetPercentage = total !== 0 ? (CRValue / total)*100 : 0
          this.loadedIndicators = true;
        } else if (type_data === 'c' && type_set === 'a') {
          this.OMCertificateAccumulated = OMData;
          this.CRCertificateAccumulated = CRData;
          this.OMCertificateAccumulatedPercentage = total !== 0 ? (OMValue / total)*100 : 0
          this.CRCertificateAccumulatedPercentage = total !== 0 ? (CRValue / total)*100 : 0
          this.loadedIndicators = true;
        }

        this.assignIndicatorsDataToPrint();

      },

      error => console.log("An error ocurred while retrieving data for the the monthly sales indicators: " + error)

    );
  }

  // Method to retrieve the data for the pie graph
  private retrievePieGraphData(type_data: string, type_set: string, dimension: any, filters: any) {

    this.loadedGraph = false;

    this.subscription = this.apimstService.getMonthlySalesYear(type_data, type_set, dimension, filters).subscribe(
      data => {
        let monthlySalePieGraphData : MonthlySalePieGraph = data;
        this.showPieDialogButton = false;
        if (monthlySalePieGraphData.details.length !== 0) {
          let pieGraphData: any = [];
          let total = monthlySalePieGraphData.details.map(value => this.numberFormat.transform(value.current)).reduce((x,y) => x+y);
          for (let channel of monthlySalePieGraphData.details) {
            pieGraphData.push(
              {
                name: channel.name,
                value: ((this.numberFormat.transform(channel.current) / total) * 100).toFixed(2)
              }
            );
          }

          if (type_data === 'p' && type_set === 'd' && this.selectedProductType === '') {
            this.pieGraphPremiumDiscreet = pieGraphData;
            this.loadedGraph = true;
            if(this.pieGraphPremiumDiscreet.length > 0) { this.showPieDialogButton = true; }
          } else if (type_data === 'p' && type_set === 'a' && this.selectedProductType === '') {
            this.pieGraphPremiumAccumulated = pieGraphData;
            this.loadedGraph = true;
            if(this.pieGraphPremiumAccumulated.length > 0) { this.showPieDialogButton = true; }
          } else if (type_data === 'c' && type_set === 'd' && this.selectedProductType === '') {
            this.pieGraphCertificateDiscreet = pieGraphData;
            this.loadedGraph = true;
            if(this.pieGraphCertificateDiscreet.length > 0) { this.showPieDialogButton = true; }
          } else if (type_data === 'c' && type_set === 'a' && this.selectedProductType === '') {
            this.pieGraphCertificateAccumulated = pieGraphData;
            this.loadedGraph = true;
            if(this.pieGraphCertificateAccumulated.length > 0) { this.showPieDialogButton = true; }
          } else if (type_data === 'p' && type_set === 'd' && this.selectedProductType === this.productTypeCR) {
            this.pieGraphPremiumDiscreetCR = pieGraphData;
            this.loadedGraph = true;
            if(this.pieGraphPremiumDiscreetCR.length > 0) { this.showPieDialogButton = true; }
          } else if (type_data === 'p' && type_set === 'a' && this.selectedProductType === this.productTypeCR) {
            this.pieGraphPremiumAccumulatedCR = pieGraphData;
            this.loadedGraph = true;
            if(this.pieGraphPremiumAccumulatedCR.length > 0) { this.showPieDialogButton = true; }
          } else if (type_data === 'c' && type_set === 'd' && this.selectedProductType === this.productTypeCR) {
            this.pieGraphCertificateDiscreetCR = pieGraphData;
            this.loadedGraph = true;
            if(this.pieGraphCertificateDiscreetCR.length > 0) { this.showPieDialogButton = true; }
          } else if (type_data === 'c' && type_set === 'a' && this.selectedProductType === this.productTypeCR) {
            this.pieGraphCertificateAccumulatedCR = pieGraphData;
            this.loadedGraph = true;
            if(this.pieGraphCertificateAccumulatedCR.length > 0) { this.showPieDialogButton = true; }
          } else if (type_data === 'p' && type_set === 'd' && this.selectedProductType === this.productTypeOM) {
            this.pieGraphPremiumDiscreetOM = pieGraphData;
            this.loadedGraph = true;
            if(this.pieGraphPremiumDiscreetOM.length > 0) { this.showPieDialogButton = true; }
          } else if (type_data === 'p' && type_set === 'a' && this.selectedProductType === this.productTypeOM) {
            this.pieGraphPremiumAccumulatedOM = pieGraphData;
            this.loadedGraph = true;
            if(this.pieGraphPremiumAccumulatedOM.length > 0) { this.showPieDialogButton = true; }
          } else if (type_data === 'c' && type_set === 'd' && this.selectedProductType === this.productTypeOM) {
            this.pieGraphCertificateDiscreetOM = pieGraphData;
            this.loadedGraph = true;
            if(this.pieGraphCertificateDiscreetOM.length > 0) { this.showPieDialogButton = true; }
          } else if (type_data === 'c' && type_set === 'a' && this.selectedProductType === this.productTypeOM) {
            this.pieGraphCertificateAccumulatedOM = pieGraphData;
            this.loadedGraph = true;
            if(this.pieGraphCertificateAccumulatedOM.length > 0) { this.showPieDialogButton = true; }
          }

          this.assignPieGraphDataToPrint();
        } else {
          this.pieGraphData = [];
          this.loadedGraph = true;
        }


      },
      error => console.log("An error ocurred while retrieving data for the monthly sales pie graph: " + error)
    );
  }

  // Method to assign the data to print for the indicators
  private assignIndicatorsDataToPrint() {
    if (this.discreetAccumulatedOptionSelected === this.discreetOption) {
      this.OMPremiumProductToPrint = this.OMPremiumDiscreet;
      this.OMCertificateProductToPrint = this.OMCertificateDiscreet;
      this.CRPremiumProductToPrint = this.CRPremiumDiscreet;
      this.CRCertificateProductToPrint = this.CRCertificateDiscreet
    } else if (this.discreetAccumulatedOptionSelected === this.accumulatedOption) {
      this.OMPremiumProductToPrint = this.OMPremiumAccumulated;
      this.OMCertificateProductToPrint = this.OMCertificateAccumulated;
      this.CRPremiumProductToPrint = this.CRPremiumAccumulated;
      this.CRCertificateProductToPrint = this.CRCertificateAccumulated;
    }

    this.assignIndicatorsPercentageValue();
  }

  // Method to assign the data to print for the pie graph
  private assignPieGraphDataToPrint() {
    if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === '') {
      this.pieGraphData = this.pieGraphPremiumDiscreet;
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === '') {
      this.pieGraphData = this.pieGraphPremiumAccumulated;
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === '') {
      this.pieGraphData = this.pieGraphCertificateDiscreet;
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === '') {
      this.pieGraphData = this.pieGraphCertificateAccumulated;
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === this.productTypeCR) {
      this.pieGraphData = this.pieGraphPremiumDiscreetCR;
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === this.productTypeCR) {
      this.pieGraphData = this.pieGraphPremiumAccumulatedCR;
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === this.productTypeCR) {
      this.pieGraphData = this.pieGraphCertificateDiscreetCR;
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === this.productTypeCR) {
      this.pieGraphData = this.pieGraphCertificateAccumulatedCR;
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === this.productTypeOM) {
      this.pieGraphData = this.pieGraphPremiumDiscreetOM;
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === this.productTypeOM) {
      this.pieGraphData = this.pieGraphPremiumAccumulatedOM;
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === this.productTypeOM) {
      this.pieGraphData = this.pieGraphCertificateDiscreetOM;
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === this.productTypeOM) {
      this.pieGraphData = this.pieGraphCertificateAccumulatedOM;
    }

  }

  // Method to show/hide the product information
  checkboxSelected() {
    this.detectCheckedCheckbox();

    if (this.CRcheckboxChecked && this.OMcheckboxChecked) {
      this.events.monthlySaleProductType$.emit('');
      this.selectedProductType = '';
      this.filtersValues = this.filtersValues.filter(v => v.name !== 'product_type');
      this.getPieGraphDataAccordingToOptions();
    } else if (this.CRcheckboxChecked) {
      this.events.monthlySaleProductType$.emit(this.productTypeCR);
      this.selectedProductType = this.productTypeCR;
      this.filtersValues.push(
        {
          name: 'product_type',
          value: [2]
        }
      );
      this.getPieGraphDataAccordingToOptions();
    } else if (this.OMcheckboxChecked) {
      this.events.monthlySaleProductType$.emit(this.productTypeOM);
      this.selectedProductType = this.productTypeOM;
      this.filtersValues.push(
        {
          name: 'product_type',
          value: [1]
        }
      );
      this.getPieGraphDataAccordingToOptions();
    }

  }

  // Method to detect if the checkboxes are checked
  private detectCheckedCheckbox() {
    this.CRcheckbox.nativeElement.checked ? this.CRcheckboxChecked = true : this.CRcheckboxChecked = false;
    this.OMcheckbox.nativeElement.checked ? this.OMcheckboxChecked = true : this.OMcheckboxChecked = false;

    this.CRcheckboxChecked ? this.OMcheckbox.nativeElement.disabled = false : this.OMcheckbox.nativeElement.disabled = true;
    this.OMcheckboxChecked ? this.CRcheckbox.nativeElement.disabled = false : this.CRcheckbox.nativeElement.disabled = true;
  }

  // Method to format the labels for the pie graph
  labelFormatting(series: any[], name: string): string {
    const item = series.filter(data => data.name === name);
    if (item.length > 0) {
        return Intl.NumberFormat("de-DE").format(item[0].value) + "%";
    }
    return name;
  }

  // Method to reset the storage data variables
  private resetVariables() {
    this.OMPremiumDiscreet= [];
    this.OMPremiumAccumulated = [];
    this.OMCertificateDiscreet = [] ;
    this.OMCertificateAccumulated = [];

    this.CRPremiumDiscreet = [];
    this.CRPremiumAccumulated = [];
    this.CRCertificateDiscreet =  [];
    this.CRCertificateAccumulated = [];

    this.pieGraphPremiumDiscreet = [];
    this.pieGraphPremiumAccumulated = [];
    this.pieGraphCertificateDiscreet = [];
    this.pieGraphCertificateAccumulated = [];

    this.pieGraphPremiumDiscreetCR = [];
    this.pieGraphPremiumAccumulatedCR = [];
    this.pieGraphCertificateDiscreetCR = [];
    this.pieGraphCertificateAccumulatedCR = [];

    this.pieGraphPremiumDiscreetOM = [];
    this.pieGraphPremiumAccumulatedOM = [];
    this.pieGraphCertificateDiscreetOM = [];
    this.pieGraphCertificateAccumulatedOM = [];

    this.CRPremiumDiscreetPercentage = 0.00;
    this.OMPremiumDiscreetPercentage = 0.00;
    this.CRPremiumAccumulatedPercentage = 0.00;
    this.OMPremiumAccumulatedPercentage = 0.00;
    this.CRCertificateDiscreetPercentage = 0.00;
    this.OMCertificateDiscreetPercentage = 0.00;
    this.CRCertificateAccumulatedPercentage = 0.00;
    this.OMCertificateAccumulatedPercentage = 0.00;

    this.CRcheckboxChecked = true;
    this.OMcheckboxChecked = true;
    this.selectedProductType = '';

  }

  // Method to assign the indicator percentage value
  private assignIndicatorsPercentageValue() {
    if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.discreetOption) {
      this.CRPercentage = this.CRPremiumDiscreetPercentage?.toFixed(2);
      this.OMPercentage = this.OMPremiumDiscreetPercentage?.toFixed(2);
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.discreetOption) {
      this.CRPercentage = this.CRCertificateDiscreetPercentage?.toFixed(2);
      this.OMPercentage = this.OMCertificateDiscreetPercentage?.toFixed(2);
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption) {
      this.CRPercentage = this.CRPremiumAccumulatedPercentage?.toFixed(2);
      this.OMPercentage = this.OMPremiumAccumulatedPercentage?.toFixed(2);
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption) {
      this.CRPercentage = this.CRCertificateAccumulatedPercentage?.toFixed(2);
      this.OMPercentage = this.OMCertificateAccumulatedPercentage?.toFixed(2);
    }
  }

  openPieChartDialog(): void {
    this.dialog.open(PieChartDialogComponent, {
      data : {
        sectionTitle: 'panels.monthlySale',
        results: this.pieGraphData,
        view: this.pieDialogView,
        gradient: this.gradient,
        showLegend: this.showLegend,
        showLabels: this.pieDialogShowLabels,
        isDoughnut: this.isDoughnut,
        legendPosition: this.pieDialogLegendPosition,
        legendTitle: this.legendTitle,
        tooltipDisabled: this.tooltipDisabled,
        animations: this.animations,
        colorScheme: this.colorScheme,
        arcWidth: this.arcWidth,
        tooltipTitle: this.tooltipTitle,
        tooltipNumber: this.tooltipNumber

      },
      panelClass: ['mat-dialog-gen', 'mat-dialog-pie-gen', 'mat-dialog-pie-chart-monthly'],
    })
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

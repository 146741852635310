import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { EventsService } from 'src/app/shared/services/events/events.service';

@Injectable({
  providedIn: 'root'
})
export class SalesMainService {
  // salesSelectorItems: any[];

  salesSelectorItems = [
    {
      id: "p",
      type: 'sales-main',
      name: 'labels.productType',
      selected: true,
    },
    {
      id: "b",
      type: 'sales-main',
      name: 'labels.bouquet',
      selected: false,
    },
    {
      id: "c",
      type: 'sales-main',
      name: 'labels.channel',
      selected: false,
    }
  ];
  
  productTypeName: string;
  bouquetName: string;
  channelName: string;

  constructor(
    private translateService: TranslateService,
    private eventsService: EventsService
  ) { }

  getSalesSelectorItems() {
    const productTypeTranslation = this.translateService.get('labels.productType');
    const bouquetTranslation = this.translateService.get('labels.bouquet');
    const channelTranslation = this.translateService.get('labels.channel');
    const translations = [productTypeTranslation, bouquetTranslation, channelTranslation];

    forkJoin(translations)
    .subscribe({
      next: results => {
        this.productTypeName = results[0];
        this.bouquetName = results[1];
        this.channelName = results[2];

        // this.salesSelectorItems = [
        //   {
        //     id: "p",
        //     type: 'sales-main',
        //     name: this.productTypeName,
        //     selected: true,
        //   },
        //   {
        //     id: "b",
        //     type: 'sales-main',
        //     name: this.bouquetName,
        //     selected: true,
        //   },
        //   {
        //     id: "c",
        //     type: 'sales-main',
        //     name: this.channelName,
        //     selected: true,
        //   }
        // ];
      }
    });

    return this.salesSelectorItems;
  }

  setDefaultSelectedValue() {
    for( let item of this.salesSelectorItems ) item.selected = false;
    this.salesSelectorItems[0].selected = true;

    const option = {
      id: 'p',
      graphName: 'salesDetails'
    }

    this.eventsService.optionTabMultipleChanged$.next(option);
  }

}

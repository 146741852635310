<div class="mt-3">
    <component-loader [width]="580" [height]="210" *ngIf="!loaded"></component-loader>
    <div class="line-charts-gen dashed-order-2" *ngIf="loaded">
        <ngx-charts-line-chart
            [view]="view"
            [scheme]="colorScheme"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [showGridLines]="showGridLines"
            [showRefLines]="showRefLines"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [timeline]="timeline"
            [results]="graphInfo"
            [tooltipDisabled]="tooltipDisabled"
            [yAxisTickFormatting]="yAxisTickFormatting"
            [rotateXAxisTicks]="rotateXAxisTicks"
            >
        </ngx-charts-line-chart>
    </div>
</div>
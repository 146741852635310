<app-widget>
    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex">
            <div class="d-flex">
                <span class="title section-card-subtitle">{{ 'panels.chargeEffectiveness' | translate }}</span>
                <app-btn-gobernance-info 
                    class="ml-2" 
                    [endpoint]="endpoint" 
                    [endpointParams]="endpointParams"
                    *ngIf="hasGobernanceInfo">
                </app-btn-gobernance-info>
            </div>
            <mat-icon class="excel-icon ml-2 icon-margin" svgIcon="excel" (click)="exportAsXLSX()" *ngIf="loaded"></mat-icon>
        </div>
        <app-option-tab [leftOption] = amount [rightOption] = receipts [graphName] = graphName class = "option-tab"></app-option-tab>
    </div>

    <div class="mt-2">
        <cancellation-charge-effectiveness-graphic></cancellation-charge-effectiveness-graphic>
    </div>
</app-widget>
<app-wrapper>

    <div class="wrapper_grid">
        <div class="renewal">
            <app-renewal></app-renewal>
        </div>
        <div class="renewal-effectiveness">
            <app-renewal-effectiveness></app-renewal-effectiveness>
        </div>        
        <div class="renewal-per">
            <app-renewal-per></app-renewal-per>
        </div>
        <div class="evolutionary-renewalBy">
            <app-evolutionary-renewalby></app-evolutionary-renewalby>
        </div>
    </div>

</app-wrapper>
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-color-table-dialog',
  templateUrl: './color-table-dialog.component.html',
  styleUrls: ['./color-table-dialog.component.scss']
})
export class ColorTableDialogComponent implements OnInit {

  headerIndicators: any;
  tableInfo: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.headerIndicators = data.headerIndicators;
    this.tableInfo = data.tableInfo;
   }

  ngOnInit(): void {
  }

}

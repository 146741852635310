<div class="d-flex justify-content-center w-100 mt-3">
    <div class="mx-4">
        <component-loader [width]="85" [height]="58" *ngIf="!loaded"></component-loader>
        <app-icon-kpi
            [iconRoute]="indicators.men.iconRoute" 
            [iconWidth]="indicators.men.iconWidth" 
            [mainValue]="indicators.men.mainValue" 
            [mainValueUnit]="indicators.men.mainValueUnit" 
            [trend]="indicators.men.trend"
            [percentage]="indicators.men.percentage"
            *ngIf="loaded">
        </app-icon-kpi>
    </div>
    <div class="mx-4">
        <component-loader [width]="85" [height]="58" *ngIf="!loaded"></component-loader>
        <app-icon-kpi
            [iconRoute]="indicators.women.iconRoute" 
            [iconWidth]="indicators.women.iconWidth" 
            [mainValue]="indicators.women.mainValue" 
            [mainValueUnit]="indicators.women.mainValueUnit" 
            [trend]="indicators.women.trend"
            [percentage]="indicators.women.percentage"
            *ngIf="loaded">
        </app-icon-kpi>
    </div>
</div>
<div class="section-header-block d-flex justify-content-between align-items-center pb-2 w-100">
    <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex">
            <span class="title section-card-title">{{ 'panels.clientsPortfolio' | translate }}</span>
            <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams"
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </div>
        <div>
            <component-loader [width]="180" [height]="28" *ngIf="!kpiLoaded"></component-loader>
            <app-small-kpi 
                class="kpi-horizontal"
                [title]="'labels.netNewClients'" 
                [mainValue]="mainValueNewClientsNet" 
                [trend]="trendNewClientsNet"
                [percentage]="percentageNewClientsNet"
                *ngIf="kpiLoaded">
            </app-small-kpi>
        </div>
    </div>
</div>

<div class="ishikawa-block">
    <component-loader [width]="556" [height]="220" *ngIf="!loaded"></component-loader>
    <app-ishikawa-diagram [height]="ishikawaHeight" [items]="ishikawaItems" *ngIf="loaded"></app-ishikawa-diagram>
</div>
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() image: string = 'assets/img/sik-card-bg.png';
  @Input() title: string;
  @Input() description: string;
  @Input() buttonLabel: string;
  @Input() routerLink: string;
  @Input() link: string;

  constructor() { }

  ngOnInit(): void {
  }

  getImageStyle() {
    var styles = {
      'background-image': 'url('+ this.image + ')',
      'background-size': 'cover'
    }

    return styles;
  }

  goToLink() {
    if (this.link !== undefined) {
      window.open(this.link, '_blank');
    }
  }  

}

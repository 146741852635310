import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiDataService } from 'src/app/shared/services/api-data/api-data.service';

@Component({
  selector: 'app-portal-widget-technical-section',
  templateUrl: './portal-widget-technical-section.component.html',
  styleUrls: ['./portal-widget-technical-section.component.scss']
})
export class PortalWidgetTechnicalSectionComponent implements OnInit {

  @Input() sectionInformation;
  order: number;

  constructor(
    private router: Router,
    private apiDataService: ApiDataService 
  ) { }

  ngOnInit(): void {
    this.order = this.sectionInformation.order;
  }

  ngAfterViewInit() {
    const pipeColorElement = document.getElementById('color-pipe-' + this.order);
    pipeColorElement.style.background = this.sectionInformation.pipeColor;

    const sectionTitleElement = document.getElementById('section-title-' + this.order);
    sectionTitleElement.style.color = this.sectionInformation.titleColor;

    const backgroundElement = document.getElementById('widget-' + this.order);
    backgroundElement.style.background = this.sectionInformation.backgroundColor;
  }

  goToSectionDetail(id: string) {
    this.apiDataService.updateDataGovernanceTechnicalCategory(id);
    this.router.navigate(['/data-governance/technical']);
  }

}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FiltersService } from '../../services/filters/filters.service';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnInit {

  public activeLang = 'es';

  constructor(
    private translate: TranslateService
  ) {
    
  }
  ngOnInit() { 
    /*
     if(this.translate){
       this.translate.setDefaultLang(this.activeLang);
     }
    */
  }

}

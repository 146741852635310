<app-wrapper>

    <div class="wrapper_grid">
        <div class="clients-portfolio-distribution-by-holding">
            <app-clients-portfolio-distribution-by-holding></app-clients-portfolio-distribution-by-holding>
        </div>
        <div class="clients-experience-indicators">
            <app-clients-experience-indicators></app-clients-experience-indicators>
        </div>        
    </div>

</app-wrapper>
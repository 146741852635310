import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { requestResult } from 'src/app/shared/models/request-result';
import { requestState } from 'src/app/shared/models/request-state';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class RequestsResultsStoreService {

  requestsState$ = new BehaviorSubject<requestState[]>([{ endpoint: "", params: [], results: [] }]);

  constructor() { }

  getRequestsState() {
    return this.requestsState$.value;
  }

  addRequestState(state: any = { endpoint: "", params: [], results: [] }) {
    let requestsState: requestState[] = _.cloneDeep(this.requestsState$.value);
    requestsState.push(state);
    this.requestsState$.next(_.cloneDeep(requestsState));
  }

  searchRequest(endpoint: string = "", params: any[] = []) {
    const filteredValues = this.requestsState$.value
      .filter(e => endpoint == e.endpoint)
      .filter(e => JSON.stringify(_.sortBy(params)) == JSON.stringify(_.sortBy(e.params)));
    return filteredValues;
  }
  
  compareFiltersRawWithSupported(rawValues: any[], storeRequest: any[]) {
    let filtered: any[] = [];
    let keysToCompare: any[] = [];

    for (let rawVale of rawValues) {
      if (storeRequest[0].params.filter(e => e.name == rawVale.name).length > 0) {
        keysToCompare.push(rawVale)
      };
    }
    filtered = storeRequest
      .filter(e => JSON.stringify(_.sortBy(e.params, 'name')) == JSON.stringify(_.sortBy(keysToCompare, 'name')));

    return filtered;
  }

  formatParams(params: any = {}) {
    let filteredParams: any[] = [];
    for (let param of params) {
      filteredParams.push({
        name: param.name,
        value: param.value,
      })
    }
    return filteredParams;
  }

  getFiltersSupported(filters: any[] = []) {
    let formattedFiltersSupported: any[] = [];
    for (let filter of filters) {
      if (filter.name == "date") {
        for (const value in filter.values) {
          formattedFiltersSupported.push({
            name: value,
            value: filter.values[value]
          })
        }
      } else {
        if(filter.name != "currency") {
          formattedFiltersSupported.push({
            name: filter.name,
            value: typeof filter.values != "undefined" ? filter.values : []
          })
        } else {
          formattedFiltersSupported.push({
            name: filter.name,
            value: typeof filter.values != "undefined" ? filter.values.toString() : []
          })
        }
      }
    }
    return formattedFiltersSupported;
  }

  joinAdditionalParams(originArray: any[] = [], paramsToAdd: any[] = []) {
    let finalObject: any[] = [...originArray, ...paramsToAdd];
    return finalObject;
  }

}
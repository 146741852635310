import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clients-internal-process-quality',
  templateUrl: './clients-internal-process-quality.component.html',
  styleUrls: ['./clients-internal-process-quality.component.scss']
})
export class ClientsInternalProcessQualityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clients-experience-indicators',
  templateUrl: './clients-experience-indicators.component.html',
  styleUrls: ['./clients-experience-indicators.component.scss']
})
export class ClientsExperienceIndicatorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="icon-kpi justify-content-{{hAlign}} align-items-{{vAlign}}">
    <img [src]="iconRoute" [ngStyle]="getIconStyles()">
    <app-small-kpi 
        class="" 
        [mainValue]="mainValue"
        [mainValueUnit]="mainValueUnit"
        [trend]="trend"
        [percentagePrevious]="percentagePrevious"
        [percentage]="percentage"
    ></app-small-kpi>
</div>
export var items = [
    {
      title: "Tasa de reclamaciones cerradas sobre recibos emitidos (emitidos + cobrados)",
      main_value: "75%",
      second_value: "80%",
      trend_value: "1,3%",
      trend: 1,
      graphic_data: [
        {
          "name": "",
          "series": [
            {
              "name": "value1",
              "value": 62000000
            },
            {
              "name": "value2",
              "value": 33000000
            },
            {
              "name": "value3",
              "value": 50400000
            },
            {
              "name": "value4",
              "value": 70400000
            },
            {
              "name": "value5",
              "value": 20400000
            },
            {
              "name": "value6",
              "value": 80400000
            },
            {
              "name": "value7",
              "value": 50400000
            },
            {
              "name": "value8",
              "value": 20400000
            },
            {
              "name": "value9",
              "value": 80400000
            },
            {
              "name": "value10",
              "value": 30400000
            },
            {
              "name": "value11",
              "value": 60400000
            },
            {
              "name": "value12",
              "value": 40400000
            },
            {
              "name": "value13",
              "value": 30400000
            },
          ]
        }
      ]
    },
    {
      title: "Tiempo medio de emisión póliza",
      main_value: "75%",
      second_value: "80%",
      trend_value: "1,3%",
      trend: 1,
      graphic_data: [
        {
          "name": "",
          "series": [
            {
              "name": "value1",
              "value": 62000000
            },
            {
              "name": "value2",
              "value": 33000000
            },
            {
              "name": "value3",
              "value": 50400000
            },
            {
              "name": "value4",
              "value": 70400000
            },
            {
              "name": "value5",
              "value": 20400000
            },
            {
              "name": "value6",
              "value": 80400000
            },
            {
              "name": "value7",
              "value": 50400000
            },
            {
              "name": "value8",
              "value": 20400000
            },
            {
              "name": "value9",
              "value": 80400000
            },
            {
              "name": "value10",
              "value": 30400000
            },
            {
              "name": "value11",
              "value": 60400000
            },
            {
              "name": "value12",
              "value": 40400000
            },
            {
              "name": "value13",
              "value": 30400000
            },
          ]
        }
      ]
    },
    {
      title: "%Reclamaciones relacionado con venta",
      main_value: "20%",
      second_value: "61%",
      trend_value: "3,3%",
      trend: 0,
      graphic_data: [
        {
          "name": "",
          "series": [
            {
              "name": "value1",
              "value": 62000000
            },
            {
              "name": "value2",
              "value": 33000000
            },
            {
              "name": "value3",
              "value": 50400000
            },
            {
              "name": "value4",
              "value": 70400000
            },
            {
              "name": "value5",
              "value": 20400000
            },
            {
              "name": "value6",
              "value": 80400000
            },
            {
              "name": "value7",
              "value": 50400000
            },
            {
              "name": "value8",
              "value": 20400000
            },
            {
              "name": "value9",
              "value": 80400000
            },
            {
              "name": "value10",
              "value": 30400000
            },
            {
              "name": "value11",
              "value": 60400000
            },
            {
              "name": "value12",
              "value": 40400000
            },
            {
              "name": "value13",
              "value": 30400000
            },
          ]
        }
      ]
    },
    {
      title: "Reclamaciones nuevas relacionadas con Administración de pólizas",
      main_value: "52%",
      second_value: "62%",
      trend_value: "0,3%",
      trend: 1,
      graphic_data: [
        {
          "name": "",
          "series": [
            {
              "name": "value1",
              "value": 62000000
            },
            {
              "name": "value2",
              "value": 33000000
            },
            {
              "name": "value3",
              "value": 50400000
            },
            {
              "name": "value4",
              "value": 70400000
            },
            {
              "name": "value5",
              "value": 20400000
            },
            {
              "name": "value6",
              "value": 80400000
            },
            {
              "name": "value7",
              "value": 50400000
            },
            {
              "name": "value8",
              "value": 20400000
            },
            {
              "name": "value9",
              "value": 80400000
            },
            {
              "name": "value10",
              "value": 30400000
            },
            {
              "name": "value11",
              "value": 60400000
            },
            {
              "name": "value12",
              "value": 40400000
            },
            {
              "name": "value13",
              "value": 30400000
            },
          ]
        }
      ]
    },
    {
      title: "Tasa de reclamaciones cerradas sobre recibos emitidos (emitidos + cobrados)",
      main_value: "43%",
      second_value: "50%",
      trend_value: "0,2%",
      trend: 1,
      graphic_data: [
        {
          "name": "",
          "series": [
            {
              "name": "value1",
              "value": 62000000
            },
            {
              "name": "value2",
              "value": 33000000
            },
            {
              "name": "value3",
              "value": 50400000
            },
            {
              "name": "value4",
              "value": 70400000
            },
            {
              "name": "value5",
              "value": 20400000
            },
            {
              "name": "value6",
              "value": 80400000
            },
            {
              "name": "value7",
              "value": 50400000
            },
            {
              "name": "value8",
              "value": 20400000
            },
            {
              "name": "value9",
              "value": 80400000
            },
            {
              "name": "value10",
              "value": 30400000
            },
            {
              "name": "value11",
              "value": 60400000
            },
            {
              "name": "value12",
              "value": 40400000
            },
            {
              "name": "value13",
              "value": 30400000
            },
          ]
        }
      ]
    },
    {
      title: "Tiempo medio póliza",
      main_value: "67%",
      second_value: "72%",
      trend_value: "0,5%",
      trend: 1,
      graphic_data: [
        {
          "name": "",
          "series": [
            {
              "name": "value1",
              "value": 62000000
            },
            {
              "name": "value2",
              "value": 33000000
            },
            {
              "name": "value3",
              "value": 50400000
            },
            {
              "name": "value4",
              "value": 70400000
            },
            {
              "name": "value5",
              "value": 20400000
            },
            {
              "name": "value6",
              "value": 80400000
            },
            {
              "name": "value7",
              "value": 50400000
            },
            {
              "name": "value8",
              "value": 20400000
            },
            {
              "name": "value9",
              "value": 80400000
            },
            {
              "name": "value10",
              "value": 30400000
            },
            {
              "name": "value11",
              "value": 60400000
            },
            {
              "name": "value12",
              "value": 40400000
            },
            {
              "name": "value13",
              "value": 30400000
            },
          ]
        }
      ]
    },
    {
      title: "Tasa de reclamaciones cerradas sobre recibos emitidos (emitidos + cobrados)",
      main_value: "75%",
      second_value: "80%",
      trend_value: "1,3%",
      trend: 1,
      graphic_data: [
        {
          "name": "",
          "series": [
            {
              "name": "value1",
              "value": 62000000
            },
            {
              "name": "value2",
              "value": 33000000
            },
            {
              "name": "value3",
              "value": 50400000
            },
            {
              "name": "value4",
              "value": 70400000
            },
            {
              "name": "value5",
              "value": 20400000
            },
            {
              "name": "value6",
              "value": 80400000
            },
            {
              "name": "value7",
              "value": 50400000
            },
            {
              "name": "value8",
              "value": 20400000
            },
            {
              "name": "value9",
              "value": 80400000
            },
            {
              "name": "value10",
              "value": 30400000
            },
            {
              "name": "value11",
              "value": 60400000
            },
            {
              "name": "value12",
              "value": 40400000
            },
            {
              "name": "value13",
              "value": 30400000
            },
          ]
        }
      ]
    },
    {
      title: "TNPS Venta",
      main_value: "61%",
      second_value: "61%",
      trend_value: "0,4%",
      trend: 0,
      graphic_data: [
        {
          "name": "",
          "series": [
            {
              "name": "value1",
              "value": 62000000
            },
            {
              "name": "value2",
              "value": 33000000
            },
            {
              "name": "value3",
              "value": 50400000
            },
            {
              "name": "value4",
              "value": 70400000
            },
            {
              "name": "value5",
              "value": 20400000
            },
            {
              "name": "value6",
              "value": 80400000
            },
            {
              "name": "value7",
              "value": 50400000
            },
            {
              "name": "value8",
              "value": 20400000
            },
            {
              "name": "value9",
              "value": 80400000
            },
            {
              "name": "value10",
              "value": 30400000
            },
            {
              "name": "value11",
              "value": 60400000
            },
            {
              "name": "value12",
              "value": 40400000
            },
            {
              "name": "value13",
              "value": 30400000
            },
          ]
        }
      ]
    },
    {
      title: "Tasa de reclamaciones cerradas sobre recibos emitidos",
      main_value: "32%",
      second_value: "54%",
      trend_value: "1,7%",
      trend: 0,
      graphic_data: [
        {
          "name": "",
          "series": [
            {
              "name": "value1",
              "value": 62000000
            },
            {
              "name": "value2",
              "value": 33000000
            },
            {
              "name": "value3",
              "value": 50400000
            },
            {
              "name": "value4",
              "value": 70400000
            },
            {
              "name": "value5",
              "value": 20400000
            },
            {
              "name": "value6",
              "value": 80400000
            },
            {
              "name": "value7",
              "value": 50400000
            },
            {
              "name": "value8",
              "value": 20400000
            },
            {
              "name": "value9",
              "value": 80400000
            },
            {
              "name": "value10",
              "value": 30400000
            },
            {
              "name": "value11",
              "value": 60400000
            },
            {
              "name": "value12",
              "value": 40400000
            },
            {
              "name": "value13",
              "value": 30400000
            },
          ]
        }
      ]
    },
]

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorsService } from '../../services/errors/errors.service';

@Component({
  selector: 'app-errors-dialog',
  templateUrl: './errors-dialog.component.html',
  styleUrls: ['./errors-dialog.component.scss']
})
export class ErrorsDialogComponent implements OnInit {

  errors: any[];
  sectionTitle: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ErrorsDialogComponent>,
    private errorsService: ErrorsService
  ) { 
    this.sectionTitle = data.sectionTitle,
    this.errors = data.errors
  }

  ngOnInit(): void {}

  cleanList(): void {
    this.errorsService.cleanErrors();
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApimstService } from 'src/app/shared/services/apimst.service';

@Component({
  selector: 'app-reports-home',
  templateUrl: './reports-home.component.html',
  styleUrls: ['./reports-home.component.scss']
})
export class ReportsHomeComponent implements OnInit {

  routerLink: string = "/portal";
  headerTitle: string = 'home.reportsTitle';
  loaded: boolean = false;

  reports;
  unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private apimstService: ApimstService,
  ) { }

  ngOnInit(): void {
    this.apimstService.getUserInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        let countries = [];
        if (data.hasOwnProperty('countries')) {
          countries = data['countries'].map(v => v.id);
        }
          
        this.apimstService.getSharedReports(countries)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(data => {
            this.reports = data;
            this.loaded = true;
          });
      });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


}

import { Component, OnInit } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { AccumulatedCancellationReasonRate } from 'src/app/shared/models/accumulated-cancellation-reason-rate';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { trim } from 'lodash';
import { CancellationReasonRate } from 'src/app/shared/models/cancellation-reason-rate';
import * as _ from 'lodash';

@Component({
  selector: 'app-fixed-stock-indicators-graphic',
  templateUrl: './fixed-stock-indicators-graphic.component.html',
  styleUrls: ['./fixed-stock-indicators-graphic.component.scss']
})
export class FixedStockIndicatorsGraphicComponent implements OnInit {

  customColors: any = [];

  unsubscribe$: Subject<any> = new Subject<any>();
  monthNames: any;
  planName: string;
  month: number;
  year: number;
  xAxisDates: any = [];
  filtersValues: any;
  optionTabBonus: string = 'panels.bonus';
  optionTabPolicy: string = 'panels.policy';
  optionTabDiscreet: string = 'panels.discreet';
  optionTabAccumulated: string = 'panels.accumulated';
  bonusPolicySelectedOption: string = 'panels.bonus';
  bonusDiscreetAccumulatedSelectedOption: string = 'panels.discreet';
  // graphName: string = 'cancellationReasons';

  policy: string = 'panels.policy';
  bonus: string = 'panels.bonus';
  graphName: string = 'fixedStockPremiumCertificate';

  // Options for chart creation
  view = [530, 130];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  showRightYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  legendTitle: string = '';
  legendPosition: string = '';
  showXAxisLabel: boolean = false;
  showYAxisLabel: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  animations: boolean = false;
  schemeType: string = 'ordinal';
  colorScheme = {
    domain: [COLORS.darkBlue, COLORS.lightBlue]
  };
  yLeftAxisScaleFactor: string = '';
  yRightAxisScaleFactor: string = '';
  showGridLines: boolean = true;
  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  barPadding: number = 30;
  tooltipDisabled: boolean = false;
  roundDomains: boolean = false;
  noBarWhenZero: boolean = false;
  lineChartScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkBlue, COLORS.lightBlue, '#ffcc00', '#ff66ff']
  };
  comboBarScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkBlue, COLORS.lightBlue]
  };
  rotateXAxisTicks: boolean = false;
  showTotalInTooltip: boolean = true;
  showPercentageSymbolInTooltip: boolean = true;

  lineChartSeries: any[] = [];
  barChart: any[] = [];
  dropdownOptions: any = [];
  loaded: boolean = false;
  graphLoaded: boolean = false;

  voluntaryName: string;
  involuntaryName: string;
  companyDecisionName: string;
  churnRateName: string;
  accumulatedChurnRateName: string;

  cancellationReasonRate: any;
  premiumBarsData: any = [];
  certificateBarsData: any = [];
  premiumLinesData: any = [];
  certificateLinesData: any = [];

  dropdownSelectedValues: any = [];

  // Default constructor
  constructor(
    private filtersService: FiltersService,
    private eventsService: EventsService,
    private apimstService: ApimstService,
    private translateService: TranslateService
  ) { }

  // OnInit
  ngOnInit(): void {
    this.subscribeToFiltersValues();

    this.eventsService.policiesBonusOption$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const option = data[0];
          const name = data[1];
          if ((option === this.optionTabBonus || option === this.optionTabPolicy) && name === this.graphName) {
            this.bonusPolicySelectedOption = option;
            this.assignDataToPrint();
          }
        }
      );
  }

  subscribeToFiltersValues() {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const monthsTranslation = this.translateService.get('abbreviatedMonthNames')
          const voluntaryTranslation = this.translateService.get('labels.voluntary');
          const involuntaryTranslation = this.translateService.get('labels.involuntary');
          const companyDecisionTranslation = this.translateService.get('labels.companyDecision');
          const churnRateTranslation = this.translateService.get('labels.churnRate');
          const accChurnRateTranslation = this.translateService.get('labels.accumulatedChurnRate');
          const planTranslation = this.translateService.get('labels.plan');
          const translations = [monthsTranslation, voluntaryTranslation, involuntaryTranslation, companyDecisionTranslation, churnRateTranslation, accChurnRateTranslation, planTranslation];

          forkJoin(translations)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              {
                next: results => {
                  this.monthNames = results[0];
                  this.voluntaryName = results[1];
                  this.involuntaryName = results[2];
                  this.companyDecisionName = results[3];
                  this.churnRateName = results[4];
                  this.accumulatedChurnRateName = results[5];
                  this.planName = results[6];
                },
                complete: () => {
                  if (data.length !== 0) {
                    this.filtersValues = data;
                    this.month = parseInt(this.filtersValues.filter(v => v.name === 'month')[0].value);
                    this.year = parseInt(this.filtersValues.filter(v => v.name === 'year')[0].value);
                    this.calculateXAxisDates();
                    this.retrieveStockInfo();
                    this.assignColorBar();
                  }
                }
              }
            );
        }
      );
  }

  private retrieveStockInfo() {
    this.loaded = false;
    this.graphLoaded = false;

    const churnRate = this.apimstService.getFixedStockChurnRateData('cscr', this.filtersValues);
    const voluntaryInvoluntary = this.apimstService.getFixedStockChurnRateData('csces', this.filtersValues);

    forkJoin([churnRate, voluntaryInvoluntary])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: results => {
          this.loaded = true;
          this.graphLoaded = true;
          this.printLineChartData(results[0]);
          this.printBarChartData(results[1]);
          this.assignDataToPrint();
        },
        error: error => console.log("An error occurred while retrieving cancellations stock graphic data " + error)
      });
  }

  private printLineChartData(data: any[]) {
    let tempPremiumLine: any[] = [];
    let tempCertificateLine: any[] = [];
    let tempPremiumLineSeries: any[] = [];
    let tempCertificateLineSeries: any[] = [];

    for (let month of data) {
      tempPremiumLineSeries.push({
        name: this.monthNames[month.month] + month.year.slice(2, 4),
        value: month.premium ? parseFloat(month.premium.replace(/,/g, '')) : 0
      })
      tempCertificateLineSeries.push({
        name: this.monthNames[month.month] + month.year.slice(2, 4),
        value: month.certificate ? parseFloat(month.certificate.replace(/,/g, '')) : 0
      })
    }
    tempPremiumLine = [{ name: this.churnRateName, series: tempPremiumLineSeries }]
    tempCertificateLine = [{ name: this.churnRateName, series: tempCertificateLineSeries }]
    this.premiumLinesData = tempPremiumLine;
    this.certificateLinesData = tempCertificateLine;
  }

  private printBarChartData(data: any[]) {
    const groupedByMonthData = this.groupByMonth(data);
    let tempPremiumBar: any[] = [];
    let tempCertificateBar: any[] = [];

    for (let month of groupedByMonthData) {
      let tempSeriePremium = [];
      let tempSerieCertificate = [];

      for (let value of month.values) {
        tempSeriePremium.push({
          name: value.name,
          value: value.premium ? parseInt(value.premium.replace(/,/g, '')) : 0
        })
        tempSerieCertificate.push({
          name: value.name,
          value: value.certificate ? parseInt(value.certificate.replace(/,/g, '')) : 0
        })
      }
      tempPremiumBar.push({
        name: this.monthNames[month.values[0].month] + month.values[0].year.slice(2, 4),
        series: tempSeriePremium
      })
      tempCertificateBar.push({
        name: this.monthNames[month.values[0].month] + month.values[0].year.slice(2, 4),
        series: tempSerieCertificate
      })
    }
    this.premiumBarsData = tempPremiumBar;
    this.certificateBarsData = tempCertificateBar;
    // this.barChart = this.premiumBarsData;
  }

  groupByMonth(graphInfo: any) {
    let graphInfoCopy = graphInfo;
    let groupedByMonth: any[] = [];
    for (let item of graphInfoCopy) { item.group_by = item.month + '-' + item.year }
    groupedByMonth = _.chain(graphInfoCopy).groupBy("group_by").map((value, key) => ({ date: key, values: value })).value();

    return groupedByMonth;
  }

  // Method to assign data according to the selected option
  private assignDataToPrint() {
    if (this.bonusPolicySelectedOption === this.optionTabBonus) {
      this.barChart = this.premiumBarsData;
      this.lineChartSeries = this.premiumLinesData;
    } else if (this.bonusPolicySelectedOption === this.optionTabPolicy) {
      this.barChart = this.certificateBarsData;
      this.lineChartSeries = this.certificateLinesData;
    }
  }

  // Method to assign the colors to the graph bars
  private assignColorBar() {
    this.customColors.push(
      {
        name: "Baja involuntaria",
        value: COLORS.lightBlue
      },
      {
        name: "Baja voluntaria",
        value: COLORS.darkBlue
      },
      {
        name: "Decision de Compañía",
        value: COLORS.darkYellow
      },
      {
        name: this.churnRateName,
        value: COLORS.lightestBlue
      },
      {
        name: 'Total',
        value: COLORS.black
      })
  }

  // Method to format the right y axis labels with '%'
  yRightAxisTickFormatting(val) {
    return Intl.NumberFormat("de-DE").format(val).toLocaleString() + '%';
  }

  // Method to format the right y axis labels
  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value);
  }

  // Method to calculate all the days from the previous and current filtered month
  private calculateXAxisDates() {
    this.xAxisDates = [];
    let originalDate = new Date(this.year, this.month - 13);

    for (let _i = 0; _i < 13; _i++) {
      const month = (originalDate.getMonth() + 1).toString().length < 2 ? '0' + (originalDate.getMonth() + 1) : (originalDate.getMonth() + 1);
      this.xAxisDates.push(month + '/' + originalDate.getFullYear().toString());
      originalDate.setMonth(originalDate.getMonth() + 1);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
            <!-- <span class="title section-card-title">{{ 'panels.portfolioDistributionByHolding' | translate }}</span> -->
            <!-- <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams"
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info> -->
        </div>
    </div>

    <app-clients-experience-indicators-table></app-clients-experience-indicators-table>

</app-widget>

<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex">
            <span class="title section-card-title">{{ 'panels.churnRate' | translate }}</span>
            <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams"
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </div>
        <app-option-tab [leftOption] = discreet [rightOption] = accumulated [graphName] = graphName class = "option-tab align-items-center"></app-option-tab>
        <app-option-tab [leftOption] = bonus [rightOption] = policy [graphName] = graphName class = "option-tab"></app-option-tab>
    </div>

    <div class="d-flex">
        <!-- <app-churn-rate-plan-graph class="float-left"></app-churn-rate-plan-graph> -->
        <app-churn-rate-evolution-graph class="float-right mt-3"></app-churn-rate-evolution-graph>
    </div>

</app-widget>
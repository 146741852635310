<component-loader class="ml-4" [width]="" [height]="200" *ngIf="!loaded"></component-loader>

<div class="legends-container mt-2 mb-3 d-flex justify-content-end align-items-center" *ngIf="loaded">
  <app-legend class="mr-2" [name]="legend.name" [colorCode]="legend.color_code" [type]="legend.type" [gradient]="legend.gradient" *ngFor="let legend of legends"></app-legend>
  <!-- <mat-icon class="excel-icon ml-2" svgIcon="excel" (click)="exportAsXLSX()" *ngIf="loaded"></mat-icon> -->
</div>

<div class="combo-charts-d-axis-gen ml-n3">
    <stacked-vertical-horizontal-graph
      [view]="view"
      [scheme]="comboBarScheme"
      [colorSchemeLine]="lineChartScheme"
      [customColors]="customColors"
      [results]="barChart"
      [animations]="animations"
      [lineChart]="lineChartSeries"
      [yAxisTickFormatting]="yAxisTickFormatting"
      [yLeftAxisScaleFactor]="yLeftAxisScaleFactor"
      [yRightAxisScaleFactor]="yRightAxisScaleFactor"
      [yRightAxisTickFormatting]="yRightAxisTickFormatting"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [rightYAxis]="showRightYAxis"
      [legend]="showLegend"
      [legendTitle]="legendTitle"
      [legendPosition]="legendPosition"
      [showGridLines]="showGridLines"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [showRightYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [barPadding]="barPadding"
      [tooltipDisabled]="tooltipDisabled"
      [schemeType]="schemeType"
      [roundDomains]="roundDomains"
      [noBarWhenZero]="noBarWhenZero"
      [showTotalInTooltip]="showTotalInTooltip"
      *ngIf="loaded">
    </stacked-vertical-horizontal-graph>
</div>
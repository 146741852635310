<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="form">

    <div *ngFor="let metaInfo of metadata" class="form-row">
      <app-dynamic-form-metadata [metadata]="metaInfo" [form]="form"></app-dynamic-form-metadata>
    </div>

    <div class="form-row mt-2">
      <button type="submit" class="btn btn-bg-dark mt-0"
        [disabled]="!form.valid">{{ 'buttons.save' | translate }}</button>
    </div>

  </form>

  <div *ngIf="payLoad" class="form-row mt-3">
    <strong>Valores</strong>
    <br>
    <pre>{{ payLoad }}</pre>
  </div>
</div>

<div class="mt-1">
    <component-loader *ngIf="!loaded" [width]="400" [height]="150"></component-loader>
</div>

<div *ngIf="loaded" class="d-flex justify-content-end align-items-center">
    <app-legend class="mr-2" name="labels.average" color="light-green" type="line" gradient="v-gradient"></app-legend>
    <app-legend name="labels.claims" color="dark-green" type="line" gradient="h-gradient"></app-legend>
    <!-- <mat-icon *ngIf="loaded && qualityClaimsRolling.length !== 0" class="excel-icon ml-2" svgIcon="excel" (click)="exportAsXLSX()"></mat-icon> -->
</div>

<div class="line-charts-gen dashed-order-2" *ngIf="loaded">
    <ngx-charts-line-chart
        [view]="view"
        [scheme]="colorScheme"
        [legend]="legend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [showGridLines]="showGridLines"
        [showRefLines]="showRefLines"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [timeline]="timeline"
        [results]="qualityClaimsRolling"
        [tooltipDisabled]="tooltipDisabled"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [xAxisTicks]="xAxisTicksFinal"
        [rotateXAxisTicks]="rotateXAxisTicks"
        >
    </ngx-charts-line-chart>
</div>

<div class="mt-3 ml-n2 d-flex flex-wrap justify-content-start align-items-center">
    <app-legend class="mr-2" name="labels.bank" color="light-blue" type="round" gradient="v-gradient"></app-legend>
    <app-legend class="mr-2" name="labels.receipt" color="pink" type="round" gradient="h-gradient"></app-legend>
    <app-legend class="mr-2" name="labels.card" color="light-purple" type="round" gradient="h-gradient"></app-legend>
    <app-legend class="mr-2" name="labels.check" color="dark-yellow" type="round" gradient="h-gradient"></app-legend>
</div>

<div class="mt-3">
    <ngx-charts-pie-chart 
        [view]="view" 
        [scheme]="colorScheme" 
        [results]="pieGraphData" 
        [gradient]="gradient"
        [legend]="showLegend" 
        [legendPosition]="legendPosition" 
        [labels]="showLabels" 
        [doughnut]="isDoughnut"
        [arcWidth]="arcWidth"
        [tooltipDisabled]="tooltipDisabled"
        [animations]="animations"
        [labelFormatting]="labelFormatting.bind(this, pieGraphData)">
    </ngx-charts-pie-chart>
</div>
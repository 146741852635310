import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-difference-bar-label',
  templateUrl: './difference-bar-label.component.html',
  styleUrls: ['./difference-bar-label.component.scss']
})
export class DifferenceBarLabelComponent implements OnInit {

  @Input() labelDiff
  @Input() width;
  @Input() viewBox;

  // Default constructor
  constructor() { }

  // OnInit
  ngOnInit(): void {
  }

}

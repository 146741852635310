import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventsService } from '../../services/events/events.service';
import { Animations } from '../../animations/animations';
import { FiltersService } from '../../services/filters/filters.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    Animations.fadeInOut,
    Animations.dumpAnimation,
    Animations.animateFilters
  ]
})
export class HeaderComponent implements OnInit {
  
  filtersOn: Boolean;
  subscription: Subscription;
  showCountryData: boolean = false;

  showKpis: boolean = false;

  countryName = "Argentina";
  inactiveIcon = "outlined_flag";
  activeIcon = "flag";
  isActive = false;

  clients: any = {
    title: "Clientes",
    main_value: "24%",
  }

  promoters: any = {
    title: "Promotores",
    main_value: "24%",
  }

  policies: any = {
    title: "Pólizas",
    main_value: "24%",
  }

  claims: any = {
    title: "Reclamaciones",
    main_value: "24%",
  }

  constructor( private events: EventsService,
                private filtersService: FiltersService ) {  }

  ngOnInit(): void {
    this.subscription = this.filtersService.filtersBlockVisibility$.subscribe(show => {
      this.filtersOn = show;
    })
  }

  onOpen(event) {
    this.showCountryData = true;
  }

  onClose(event) {
    this.showCountryData = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

import { Injectable, } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MonthlySaleYear } from 'src/app/shared/models/monthly-sale-year';

@Injectable({
  providedIn: 'root'
})
export class ApiDataService {

  accumulatedMonthlySaleYear: MonthlySaleYear = undefined;
  discreetMonthlySaleYear: MonthlySaleYear = undefined;
  dataGovernanceBusinessCategory: any = undefined;
  headerTitle: string = undefined;
  dataGovernanceTechnicalCategory: any = undefined;
  dataGovernanceEntity: any = undefined;

  _accumulatedMonthlySaleYear = new BehaviorSubject<MonthlySaleYear>(this.accumulatedMonthlySaleYear);
  _discreetMonthlySaleYear = new BehaviorSubject<MonthlySaleYear>(this.discreetMonthlySaleYear);
  _dataGovernanceBusinessCategory = new BehaviorSubject<any>(this.dataGovernanceBusinessCategory);
  _headerTitle = new BehaviorSubject<string>(this.headerTitle);
  _dataGovernanceTechnicalCategory = new BehaviorSubject<any>(this.dataGovernanceTechnicalCategory);
  _dataGovernanceEntity = new BehaviorSubject<any>(this.dataGovernanceEntity);

  constructor() { }

  updateAccumulatedMonthlySaleYear(data: MonthlySaleYear) {
    this._accumulatedMonthlySaleYear.next(data);
  }

  updateDiscreetMonthlySaleYear(data: MonthlySaleYear) {
    this._discreetMonthlySaleYear.next(data);
  }

  updateDataGovernanceBusinessCategory(data: any) {
    this._dataGovernanceBusinessCategory.next(data);
  }

  updateHeaderTitle(data: any) {
    this._headerTitle.next(data);
  }

  updateDataGovernanceTechnicalCategory(data: any) {
    this._dataGovernanceTechnicalCategory.next(data);
  }

  updateDataGovernanceEntity(data: any) {
    this._dataGovernanceEntity.next(data);
  }

  getAccumulatedMonthlySaleYear() {
    return this._accumulatedMonthlySaleYear;
  }

  getDiscreetMonthlySaleYear() {
    return this._discreetMonthlySaleYear;
  }

  getDataGovernanceBusinessCategory() {
    return this._dataGovernanceBusinessCategory;
  }

  getHeaderTitle() {
    return this._headerTitle;
  }

  getDataGovernanceTechnicalCategory() {
    return this._dataGovernanceTechnicalCategory;
  }

  getDataGovernanceEntity() {
    return this._dataGovernanceEntity;
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-kpi-color',
  templateUrl: './kpi-color.component.html',
  styleUrls: ['./kpi-color.component.scss']
})
export class KpiColorComponent implements OnInit {

  @Input() title: string;
  @Input() mainValue;
  @Input() mainValueUnit: string;
  @Input() percentagePrevious = "";
  @Input() percentage;
  @Input() trend;

  trendValue: number;

  constructor() { }

  // absPercentageValue: number;

  ngOnInit(): void {
    //this.absPercentageValue = Math.abs(this.percentage);
  }

  ngOnChanges(): void  {
    if (typeof this.trend === 'string') {
      this.trendValue = parseFloat(this.trend);
    } else {
      this.trendValue = this.trend;
    }
  }

}

import { Component, OnInit } from "@angular/core";
import { Animations } from "../shared/animations/animations";
import { EventsService } from "../shared/services/events/events.service";

@Component({
  selector: "app-document-management",
  templateUrl: "./document-management.component.html",
  styleUrls: ["./document-management.component.scss"],
  animations: [
    Animations.animateLeftPanel
  ]
})
export class DocumentManagementComponent implements OnInit {
  showUserPanel: boolean = false;

  constructor(
    private events: EventsService,
  ) {}

  ngOnInit(): void {
    this.subscribeToUserPanelEvent();
  }

  subscribeToUserPanelEvent() {
    this.events.showUserPanel$
    .subscribe(event => {
      if (event) this.showUserPanel = true
      else this.showUserPanel = false
    })
  }

  openUserPanel(event) {
    if (event) this.events.showUserPanel$.emit(true);
  }
}

import { Injectable } from '@angular/core';
import { FILTERS_TEMPLATES } from 'src/app/shared/globals/globals';

@Injectable({
  providedIn: 'root'
})
export class FiltersHelperService {

  templates: any[] = FILTERS_TEMPLATES;
  defaultTemplate: string = 'tpl-sale-1';

  constructor() { }

  getFiltersTemplate( pageId: string ) {
    let templateName: string = "";
    let filteredResult: any = [];

    filteredResult = this.templates.filter(template => (template.name === pageId));
    templateName = filteredResult.length > 0 ? filteredResult[0].template : this.defaultTemplate;

    return templateName;
  }

  getYearSelectorValues() {
    let yearSelectorValues = [];
    let selectorItem: any;
    let today = new Date();
    let actualYear = today.getFullYear();
    let lowerLimitYear: number = 2018;

    for (let i = actualYear; i >= lowerLimitYear; i--) {
      selectorItem = {};
      selectorItem.id = i.toString();
      selectorItem.type = "year";
      selectorItem.name = i.toString();
      selectorItem.selected = false;
      yearSelectorValues.push(selectorItem);
    }  

    return yearSelectorValues;

  }

  filterDuplicates( object ) {
    const seen = new Set();
    const filteredArr = object.filter(el => {
      const duplicate = seen.has(el.item_id);
      seen.add(el.item_id);
      return !duplicate;
    });
    
    return filteredArr;   
  }

}

<app-wrapper>

    <div class="wrapper_grid">
        <div class="retention">
            <app-retention></app-retention>
        </div>
        <div class="voluntary-cancellation">
            <app-voluntary-cancellation></app-voluntary-cancellation>
        </div>        
        <div class="retention-per-action">
            <app-retention-per-action></app-retention-per-action>
        </div>
    </div>

</app-wrapper>
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MetadataBase } from '../../models/metadata-base';
import { MetadataControlService } from '../../services/forms/metadata-form/metadata-control.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {

  @Input() metadata: MetadataBase<string>[] | null = [];
  form!: FormGroup;
  payLoad = '';

  constructor(
    private mcs: MetadataControlService
  ) {}

  ngOnInit() {
    this.form = this.mcs.toFormGroup(this.metadata as MetadataBase<string>[]);
  }

  onSubmit() {
    this.payLoad = JSON.stringify(this.form.getRawValue());
  }

}

<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex">
            <span class="title section-card-subtitle">{{ 'panels.voluntaryCancellation' | translate }}</span>
            <app-btn-gobernance-info 
                    class="ml-2" 
                    [endpoint]="endpoint" 
                    [endpointParams]="endpointParams"
                    *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </div>
        <app-option-tab [leftOption] = bonus [rightOption] = policy [graphName] = graphName class = "option-tab align-items-center"></app-option-tab>
    </div>

    <div class="mt-2 d-flex justify-content-end align-items-center">
        <app-legend class="mr-2" name="labels.request" color="dark-blue" type="round" gradient="v-gradient"></app-legend>
        <app-legend class="mr-2" name="labels.retention" color="light-blue" type="round" gradient="h-gradient"></app-legend>
        <app-legend class="mr-2" name="labels.managedRate" color="dark-yellow" type="line" gradient="h-gradient"></app-legend>
        <app-legend name="labels.target" color="light-green" type="line" gradient="h-gradient"></app-legend>
    </div>

    <div class="mt-2 ">
        <app-voluntary-cancellation-record-graphic></app-voluntary-cancellation-record-graphic>
    </div>
    
</app-widget>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { TranslateService } from '@ngx-translate/core';
import { shareReplay, takeUntil } from 'rxjs/operators'
import { combineLatest, forkJoin, Subject } from 'rxjs'
import { MonthlyPaymentForms } from 'src/app/shared/models/monthly-payment-forms';
import { YearPaymentForms } from 'src/app/shared/models/year-payment-forms';
import { trim } from 'lodash';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { PieChartDialogComponent } from 'src/app/shared/components/pie-chart-dialog/pie-chart-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RejectionsMainService } from '../rejections-main.service';

@Component({
  selector: 'app-rejections-pie-graphs',
  templateUrl: './rejections-pie-graphs.component.html',
  styleUrls: ['./rejections-pie-graphs.component.scss']
})
export class RejectionsPieGraphsComponent implements OnInit {

  selectedOption = "rejectionsAmount";
  selectedGraph = "sinistersRejections";

  // Options for pie chart
  pieGraphData: any[];
  pieGraph: any[];
  showPieDialogButton: boolean = false;

  rawInfo: any;
  rawInfoReject: any;
  rawInfoAdministrative: any;

  type_data: string;
  type_report: string;
  rejection_reason: any[];

  // Pie chart options
  viewPie: any[] = [210, 120];
  gradientPie: boolean = false;
  showLegendPie: boolean = true;
  showLabelsPie: boolean = false;
  isDoughnutPie: boolean = false;
  isDoughnut: boolean = false;
  legendPositionPie: string = 'below';
  legendTitlePie: string = '';
  tooltipDisabledPie: boolean = false;
  animationsPie: boolean = false;
  colorSchemePie = {
    domain: [COLORS.lightBlue, COLORS.pink, COLORS.lightPurple, COLORS.lightOrange, COLORS.lightestBlue, COLORS.darkGreen, COLORS.lightGreen, COLORS.darkYellow, COLORS.darkBlue]
  };
  customColorsPieGraph: any = [];
  arcWidth: number = 1.5;

  tooltipTitle: string;
  tooltipNumber: string;

  // Pie chart dialog options
  pieDialogView: any[] = [600, 380];
  pieDialogShowLabels: boolean = true;
  pieDialogLegendPosition: string = 'below';

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;

  pieGraphLoaded: boolean = false;
  pieGraphLabels: any = [];

  barGraphToPrint: any = [];
  rejectDistributionToPrint: any = [];
  administrativeDistributionToPrint: any = [];
  rateToPrint: any = [];

  constructor(
    private dialog: MatDialog,
    private apimstService: ApimstService,
    private eventsService: EventsService,
    private filtersService: FiltersService,
    private rejectionsMainService: RejectionsMainService
  ) { }

  ngOnInit(): void {
    const filters$ = this.filtersService.filtersValues$;
    const selectedOption$ = this.rejectionsMainService.panelSelectedOption$;

    combineLatest([filters$, selectedOption$])
      .pipe(shareReplay(), takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.pieGraphLoaded = false;
          this.resetValues();

          this.filtersValues = data[0];
          if (this.filtersValues.length == 0) return;

          this.getSelectedOption(data[1]);
          this.retrieveRejectionsPieInfo("s", this.filtersValues);
        }
      )
  }

  resetValues() {
    this.rejectDistributionToPrint = [];
    this.administrativeDistributionToPrint = [];
  }

  getSelectedOption(option: any = {}): void {
    if (option['id'] == "rejectionsAmount") this.type_data = 'a'
    if (option['id'] == "rejectionsSinisters") this.type_data = 's'
  }

  retrieveRejectionsPieInfo(type_data: string = "", filtersValues: any): void {
    const rejectRequest = this.apimstService.getSinistersRejections(type_data, 'srp', filtersValues);
    const administrativeRequest = this.apimstService.getSinistersRejections(type_data, 'srpad', filtersValues);

    forkJoin([rejectRequest, administrativeRequest])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: data => {
          this.rawInfoReject = data[0];
          this.rawInfoAdministrative = data[1];
          this.pieGraphLoaded = true;

          if (this.rawInfoReject != null) this.createGraph(this.rawInfoReject, this.rejectDistributionToPrint)
          if (this.rawInfoAdministrative != null) this.createGraph(this.rawInfoAdministrative, this.administrativeDistributionToPrint)
        }
      })
  }

  createGraph(rawInfo: any[] = [], newIndicator: any[] = []) {
    for (let element of rawInfo) {
      newIndicator.push({
        name: element.name,
        value: element.percentage ? parseFloat(element.percentage) : 0
      })
    }
  }

  openPieChartRejectionsDialog(): void {
    this.dialog.open(PieChartDialogComponent, {
      data: {
        sectionTitle: 'panels.rejectionsDistributionTechnical',
        results: this.rejectDistributionToPrint,
        view: this.pieDialogView,
        gradient: this.gradientPie,
        showLegend: this.showLegendPie,
        showLabels: this.pieDialogShowLabels,
        isDoughnut: this.isDoughnut,
        legendPosition: this.pieDialogLegendPosition,
        legendTitle: this.legendTitlePie,
        tooltipDisabled: this.tooltipDisabledPie,
        animations: this.animationsPie,
        colorScheme: this.colorSchemePie,
        arcWidth: this.arcWidth,
        tooltipTitle: this.tooltipTitle,
        tooltipNumber: this.tooltipNumber
      },
      panelClass: ['mat-dialog-gen', 'mat-dialog-pie-gen', 'mat-dialog-pie-chart-rejections-distribution'],
    })
  }

  openPieChartClosingDialog(): void {
    this.dialog.open(PieChartDialogComponent, {
      data: {
        sectionTitle: 'panels.rejectionsClosingDistribution',
        results: this.administrativeDistributionToPrint,
        view: this.pieDialogView,
        gradient: this.gradientPie,
        showLegend: this.showLegendPie,
        showLabels: this.pieDialogShowLabels,
        isDoughnut: this.isDoughnut,
        legendPosition: this.pieDialogLegendPosition,
        legendTitle: this.legendTitlePie,
        tooltipDisabled: this.tooltipDisabledPie,
        animations: this.animationsPie,
        colorScheme: this.colorSchemePie,
        arcWidth: this.arcWidth,
        tooltipTitle: this.tooltipTitle,
        tooltipNumber: this.tooltipNumber
      },
      panelClass: ['mat-dialog-gen', 'mat-dialog-pie-gen', 'mat-dialog-pie-chart-rejections-distribution'],
    })
  }

  labelFormatting(series: any[], name: string): string {
    const item = series.filter(data => data.name === name);
    if (item.length > 0) {
      return Intl.NumberFormat("de-DE").format(item[0].value) + "%";
    }
    return name;
  }

  activate(e) {
    this.tooltipTitle = e['value'].label;
    this.tooltipNumber = Intl.NumberFormat("de-DE").format(e['value'].value) + "%";
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

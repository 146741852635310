<div class="header d-flex justify-content-start align-items-center mb-1">
    <span class="text-white h5 mb-0">{{ sectionTitle | translate }}</span>
    <span class="material-icons ml-2 fullscreen-icon text-light" [mat-dialog-close]="true">fullscreen_exit</span>
</div>

<div class="d-flex justify-content-end align-items-center mb-4 w-100" *ngIf="dialogLegends">
    <app-legend 
        class="mr-2" 
        [name]="dialogLegend.name" 
        [color]="dialogLegend.color" 
        [type]="dialogLegend.type" 
        [gradient]="dialogLegend.gradient"
        *ngFor="let dialogLegend of dialogLegends">
    </app-legend>
</div>

<div class="graph-container">
    <app-stacked-horizontal-negative-graph
        [view]="view"
        [scheme]="colorScheme"
        [schemeType]="schemeType"
        [results]="results"
        [gradient]="gradient"
        [xAxis]="xAxis"
        [yAxis]="xAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [animations]="animations"
        [showDataLabel] = "showDataLabel"
        [dataLabelFormatting] = "dataLabelFormatting"
        [roundDomains] = "roundDomains"
        [trimYAxisTicks]="trimYAxisTicks"
        [maxYAxisTickLength]="maxYAxisTickLength"
        [showGridLines]="showGridLines"
        [customColors]="customColors"
        [customColors]="customColors"
        [tooltipDisabled]="tooltipDisabled"
        [xAxisTickFormatting]="xAxisTickFormatting">
    </app-stacked-horizontal-negative-graph>
</div>
<component-loader [width]="" [height]="175" *ngIf="!loaded"></component-loader>
<div *ngIf="loaded">
    <div class="wrapper table-wrapper">
        <table class="table table-loss-ratio">
            <thead>
                <tr class="table-header">
                    <th [title]="'panels.branch' | translate">{{ 'panels.branch' | translate }}</th>
                    <th [title]="'panels.lossRatio' | translate">{{ 'panels.lossRatio' | translate }}</th>
                    <th [title]="'panels.comparative' | translate">{{ 'panels.comparative' | translate }}</th>
                    <th [title]="'panels.accumulatedM' | translate">{{ 'panels.accumulatedM' | translate }}</th>
                    <th [title]="'panels.variation' | translate">{{ 'panels.variation' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-body" *ngFor="let branch of tableInfo">
                    <td [title]="branch?.branch">{{ branch?.branch }}</td>
                    <td>{{ branch?.loss_ratio }}</td>
                    <td [ngClass]="{'text-brand-success': branch?.diffComparative === 1, 'text-brand-danger': branch?.diffComparative === 0}">{{ branch?.comparative }}</td>
                    <td>{{ branch?.accumulated }}</td>
                    <td [ngClass]="{'text-brand-success': branch?.diffAccumulated === 1, 'text-brand-danger': branch?.diffAccumulated === 0}">{{ branch?.variation }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { MetadataBase } from '../../../models/metadata-base';

@Injectable({
  providedIn: 'root'
})
export class MetadataControlService {

  constructor() { }

  toFormGroup(metadata: MetadataBase<string>[] ) {
    const group: any = {};

    metadata.forEach(metadata => {
      group[metadata.key] = metadata.required ? new FormControl(metadata.value || '', [Validators.required])
                                              : new FormControl(metadata.value || '');
    });
    return new FormGroup(group);
  }

}


<div class="d-flex justify-content-between align-items-center">
  <div class="mt-2 mb-2 d-flex flex-wrap justify-content-end align-items-center legends-container">
      <app-legend class="mr-1 mb-1" name="labels.voluntary" color="dark-blue" type="round" gradient="v-gradient"></app-legend>
      <app-legend class="mr-1 mb-1" name="labels.involuntary" color="light-blue" type="round" gradient="h-gradient"></app-legend>
      <app-legend class="mr-1 mb-1" name="labels.companyDecision" color="dark-yellow" type="round" gradient="h-gradient"></app-legend>
      <app-legend class="mr-2 mb-1" name="labels.churnRate" color="lightest-blue" type="line" gradient="h-gradient"></app-legend>
  </div>
    <app-option-tab 
    [leftOption]="bonus" 
    [rightOption]="policy" 
    [graphName]="graphName" 
    class="option-tab align-items-center" 
    [ngClass]="{'loading': !graphLoaded}">
  </app-option-tab>
</div>

<component-loader *ngIf="!loaded" [width]="580" [height]="170"></component-loader>
<div *ngIf="loaded">
    <stacked-vertical-horizontal-graph
      [view]="view"
      [scheme]="comboBarScheme"
      [colorSchemeLine]="lineChartScheme"
      [customColors]="customColors"
      [results]="barChart"
      [animations]="animations"
      [lineChart]="lineChartSeries"
      [yAxisTickFormatting]="yAxisTickFormatting"
      [yLeftAxisScaleFactor]="yLeftAxisScaleFactor"
      [yRightAxisScaleFactor]="yRightAxisScaleFactor"
      [yRightAxisTickFormatting]="yRightAxisTickFormatting"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [rightYAxis]="showRightYAxis"
      [legend]="showLegend"
      [legendTitle]="legendTitle"
      [legendPosition]="legendPosition"
      [showGridLines]="showGridLines"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [showRightYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [barPadding]="barPadding"
      [tooltipDisabled]="tooltipDisabled"
      [schemeType]="schemeType"
      [roundDomains]="roundDomains"
      [noBarWhenZero]="noBarWhenZero"
      [rotateXAxisTicks]="rotateXAxisTicks"
      [showTotalInTooltip]="showTotalInTooltip"
      [showPercentageSymbolInTooltip]="showPercentageSymbolInTooltip">
    </stacked-vertical-horizontal-graph>
</div>
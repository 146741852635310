import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PortfolioMixDialogComponent } from 'src/app/shared/dialogs/portfolio-mix-dialog/portfolio-mix-dialog.component';
import { ATLAS_ENDPOINTS, COLORS } from 'src/app/shared/globals/globals';
import { PortfolioSaleMix } from 'src/app/shared/models/portfolio-sales-mix';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import * as _ from 'lodash';
import { values } from 'lodash';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-clients-casualties-by-branch-graphic',
  templateUrl: './new-clients-casualties-by-branch-graphic.component.html',
  styleUrls: ['./new-clients-casualties-by-branch-graphic.component.scss']
})
export class NewClientsCasualtiesByBranchGraphicComponent implements OnInit {
  loaded: boolean = false;

  filtersValues: any;
  unsubscribe$: Subject<any> = new Subject<any>();

  dialogLegendsBouquet: any = [
    {
      name: 'labels.unsubscriptions',
      color: 'dark-blue',
      type: 'round',
      gradient: 'v-gradient'
    },
    {
      name: 'labels.highSubscriptions',
      color: 'light-blue',
      type: 'round',
      gradient: 'v-gradient'
    },
  ];

  dialogLegendsChannel: any = [
    {
      name: 'labels.portfolioChannelMix',
      color: 'light-blue',
      type: 'round',
      gradient: 'v-gradient'
    },
    {
      name: 'labels.sales',
      color: 'dark-blue',
      type: 'round',
      gradient: 'v-gradient'
    },
  ];

  channel_sale_loaded: boolean = false;
  bouquet_sale_loaded: boolean = false;
  channel_portfolio_loaded: boolean = false;
  bouquet_portfolio_loaded: boolean = false;

  graph_items = 4;
  graph_items_dialog = 12;

  others_label: string = '';
  portfolio_product_label: string = '';
  portfolio_channel_label: string = '';
  sales_label: string = '';
  bonus_label: string = '';
  policy_label: string = '';
  title_label: string = '';
  product_label: string = '';
  channel_label: string = '';

  subscriptionsName: string = "";
  unsubscriptionsName: string = "";
  restName: string = "";

  clientsDataRaw: any = [];
  month: string;
  year: string;
  clientsValuesAll: any;
  clientsValues: any;
  channelValues: any;
  clientsValuesDialog: any;
  channelValuesDialog: any;
  policiesBouquets: any = [];
  bonusBouquets: any = [];
  policiesChannels: any = [];
  bonusChannels: any = [];
  policiesBonusSelectedOption: string = 'panels.bonus';
  upperLegendTitle: string = 'labels.portfolioProductMix';
  lowerLegendTitle: string = 'labels.portfolioChannelMix';

  // Graph options
  view: any[] = [480, 200];
  xAxisTicks: any[];
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = '';
  showYAxisLabel = true;
  showDataLabel = true;
  animations = false;
  schemeType = 'ordinal';
  colorScheme = {
    selectable: true,
    group: 'ordinal',
    domain: ['#08d1c6', '#0990b7']
  };
  legend = false;
  legendTitle: string = '';
  legendPosition: string = '';
  xAxis;
  yAxis;
  yAxisLabel = '';
  tooltipDisabled: boolean = true;
  showGridLines: boolean = true;
  activeEntries: any[] = [];
  trimXAxisTicks: boolean = true;
  trimYAxisTicks: boolean = true;
  trimYAxisTicksDialog: boolean = false;
  rotateXAxisTicks: boolean = true;
  maxXAxisTickLength: number = 16;
  maxYAxisTickLength: number = 10;
  maxYAxisTickLengthDialog: number = 20;
  xAxisTickFormatting: any;
  yAxisTickFormatting: any;
  yAxisTicks: any[];
  barPadding = 8;
  roundDomains: boolean = true;
  xScaleMax: number;
  dataLabelFormatting: any;
  noBarWhenZero: boolean = true;
  customColors: any = [];

  // Variables to store the export data
  data_total: any[] = [];
  data_sheet_names: any[] = [];
  data_filter_details: any[] = [];

  activeLang: string;
  pageId: string = "clients-portfolio--new-clients-casualties-per-bouquet";
  hasGobernanceInfo: boolean = false;
  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  endpointParams: any = [];

  // Default constructor
  constructor(
    private apimstService: ApimstService,
    private events: EventsService,
    private filtersService: FiltersService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    private atlasHelper: AtlasHelperService,
    private i18n: I18nService
  ) { }

  // OnInit
  ngOnInit(): void {
    this.subscribeToActiveLanguage();
    this.subscribeToFiltersValues();
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => {
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  subscribeToFiltersValues(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.getTranslations();
          if (data.length !== 0) {
            this.loaded = false;
            this.filtersValues = data;
            this.resetVariables();
            this.retrieveClientsBouquet(data);
            this.assignColorBar();
          }
        }
      );
  }

  getTranslations() {
    const subscriptionsTranslation = this.translateService.get('labels.highSubscriptions');
    const unsubscriptionsTranslation = this.translateService.get('labels.unsubscriptions');
    const restTranslation = this.translateService.get('labels.rest');
    forkJoin([subscriptionsTranslation, unsubscriptionsTranslation, restTranslation])
      .subscribe(result => {
        this.subscriptionsName = result[0];
        this.unsubscriptionsName = result[1];
        this.restName = result[2];
      })
  }

  retrieveClientsBouquet(filters: any) {
    const subscriptionsRequest = this.apimstService.getClientsCasualtiesPerBouquet("cbs", filters);
    const unsubscriptionsRequest = this.apimstService.getClientsCasualtiesPerBouquet("cbu", filters);
    const requests = [subscriptionsRequest, unsubscriptionsRequest];

    forkJoin(requests)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data) => {
          this.loaded = true;

          if( this.isNotValidData(data) ) return;
          this.data_filter_details = data[0][0].filters;
          const subscriptionsRawInfo = data[0];
          const unsubscriptionsRawInfo = data[1];
          const finalInfo = this.setGraphInfo(subscriptionsRawInfo, unsubscriptionsRawInfo);

          this.clientsValuesAll = finalInfo;
          this.clientsValues = this.getInfoSlicedByNumber(finalInfo, this.graph_items);
          this.clientsValuesDialog = this.getInfoSlicedByNumber(finalInfo, this.graph_items_dialog);
        },
        error => console.log("An error ocurred while retrieving new clients & casualties per bouquet graph data: " + error)
      );
  }

  isNotValidData(data) {
    return data[0] == null || data[1] == null;
  }

  setGraphInfo(subscriptions: any[] = [], unsubscriptions: any[] = []) {
    const unsubscriptionsWithNumbers: any[] = this.setNumericValuesToArray(unsubscriptions, "valueUnsubscriptions");
    const subscriptionsWithNumbers: any[] = this.setNumericValuesToArray(subscriptions, "valueSubscriptions");
    const allInfo: any[] = this.joinArrays(unsubscriptionsWithNumbers, subscriptionsWithNumbers);
    const groupedInfoByBouquet: any[] = this.groupByBouquet(allInfo);
    const series = this.createSeries(groupedInfoByBouquet);
    const orderedInfoPyBouquet: any[] = this.orderBySubscriptions(series);

    return orderedInfoPyBouquet;
  }

  setNumericValuesToArray(array: any[] = [], valueKeyName: string = "value") {
    const isNegative = valueKeyName == "valueUnsubscriptions" ? true : false;
    const arrayWithNumbers = array.map(element => (
      {
        name: element.name,
        [`${valueKeyName}`]: !isNegative ? parseFloat(element.value.replace(/,/g, '')) : -parseFloat(element.value.replace(/,/g, ''))
      })
    );
    return arrayWithNumbers;
  }

  joinArrays(unsubscriptions: any[] = [], subscriptions: any[] = []) {
    return [...unsubscriptions, ...subscriptions];
  }

  groupByBouquet(array: any[] = []) {
    const groupedByBouquet = _.chain(array).groupBy("name").map((value, key) => ({ name: key, values: value })).value();
    return groupedByBouquet;
  }

  createSeries(array: any[] = []) {
    const tempSeriesArray = array.map(element => (
      {
        name: element.name,
        series: [
          {
            name: this.unsubscriptionsName,
            value: element.values[0]?.valueUnsubscriptions ? element.values[0].valueUnsubscriptions : 0
          },
          {
            name: this.subscriptionsName,
            value: element.values[1]?.valueSubscriptions ? element.values[1].valueSubscriptions : 0
          }
        ]
      }
    ));

    return tempSeriesArray;
  }

  orderBySubscriptions(array: any[] = []) {
    return _.orderBy(array, item => item.series[1].value, ['desc']);
  }

  getInfoSlicedByNumber(array: any[] = [], itemsNumberLimit: number = 4) {
    const mainItems: any[] = array.slice(0, itemsNumberLimit);
    const restItems: any[] = this.createRest(array.slice(itemsNumberLimit, array.length));
    const finalItems: any[] = [...mainItems, ...restItems];
    return finalItems;
  }

  createRest(array: any[] = []) {
    let unsubscriptionsTotal: number = 0;
    let subscriptionsTotal: number = 0;
    for (let item of array) {
      unsubscriptionsTotal = Math.abs(unsubscriptionsTotal) + Math.abs(item.series[0].value);
      subscriptionsTotal = subscriptionsTotal + item.series[1].value;
    }
    let tempItem: any = [{
      name: this.restName,
      series: [
        { name: this.unsubscriptionsName, value: -unsubscriptionsTotal },
        { name: this.subscriptionsName, value: subscriptionsTotal }
      ]
    }];
    return tempItem;
  }

  getFormattedResponseData(data: any = {}) {
    const arrayWithAccumulatedValues = this.createArrayWithAccumulatedValues(data);
    const orderedByAccumulatedValue = this.orderByAccumulatedValue(arrayWithAccumulatedValues);
    return orderedByAccumulatedValue;
  }

  createArrayWithAccumulatedValues(data: any[] = []) {
    let accumulated: any[] = [];
    let tempItem: any = {};

    for (let item of data) {
      tempItem = { name: "", certificate: 0, premium: 0, accumulated: 0 };
      tempItem.name = item.name,
        tempItem.certificate = parseFloat(item.certificate);
      tempItem.premium = parseFloat(item.premium);
      tempItem.accumulated = parseFloat(item.certificate) + parseFloat(item.premium);
      accumulated.push(tempItem);
    }
    return accumulated;
  }

  orderByAccumulatedValue(data: any[] = []) {
    return _.orderBy(data, ['accumulated'], ['desc']);
  }

  formatDataToGraph(data: any[] = []) {
    let formattedData: any[] = [];
    let item: any = { name: "", series: [] };
    for (let item of data) {
      item = {
        name: item.name,
        series: [{
          name: "clients",
          value: -(Math.random() * (40 - 0) + 0)
        },
        {
          name: "casualties",
          value: (Math.random() * (40 - 0) + 0)
        }]
      };
      formattedData.push(item);
    }


    const divided = [formattedData.slice(0, 2), formattedData.slice(2, formattedData.length)]


    return formattedData;
  }

  group_graph_items(array, number_items) {
    array = array.sort((a, b) => {
      if (a.series[1].value > b.series[1].value) {
        return -1
      } else if (b.series[1].value > a.series[1].value) {
        return 1
      } else {
        return 0;
      }
    });
    let output_array = [];
    let acummulated_series = [{ name: this.unsubscriptionsName, value: 0 }, { name: this.subscriptionsName, value: 0 }];
    for (let index = 0; index < array.length; index++) {
      if (index < number_items - 1) {
        output_array.push(array[index])
      } else if (index < array.length) {
        acummulated_series[0].value += array[index].series[0].value;
        acummulated_series[1].value += array[index].series[1].value;
      }
    }

    if (number_items < array.length) {
      output_array.push({ name: this.others_label, series: acummulated_series });
    }

    this.loaded = true;

    return output_array;
  }

  fill_data(array: any, name: string, index: number, value: string) {
    let element;
    for (let object of array) {
      if (object.name == name) {
        element = object;
      }
    }

    let calculated_value = (value && value.trim().length > 0) ? parseFloat(value) : 0

    if (!element) {
      element = { name: name, series: [{ name: 'clients', value: 0 }, { name: 'casualties', value: 0 }] }
      array.push(element);
    }

    let seriesName: string;
    if (index === 0) {
      calculated_value = -calculated_value;
      seriesName = 'clients';
    } else {
      seriesName = 'casualties';
    }
    element.series[index] = { name: seriesName, value: calculated_value };

  }

  private resetVariables() {
    this.clientsValues = [];
    this.channelValues = [];
    this.policiesBouquets = [];
    this.bonusBouquets = [];
    this.policiesChannels = [];
    this.bonusChannels = [];
    this.channel_sale_loaded = false
    this.bouquet_sale_loaded = false
    this.channel_portfolio_loaded = false
    this.bouquet_portfolio_loaded = false
  }

  private assignColorBar() {
    this.customColors.push(
      {
        name: this.subscriptionsName,
        value: COLORS.lightBlue
      },
      {
        name: this.unsubscriptionsName,
        value: COLORS.darkBlue
      }
    );
  }

  openDialogBouquet(): void {
    this.dialog.open(PortfolioMixDialogComponent, {
      data: {
        sectionTitle: 'panels.newClients&CasualtiesByBranch',
        view: [850, 550],
        scheme: this.colorScheme,
        schemeType: this.schemeType,
        results: this.clientsValuesDialog,
        gradient: this.gradient,
        xAxis: this.showXAxis,
        yAxis: this.showYAxis,
        legend: this.showLegend,
        showXAxisLabel: this.showXAxisLabel,
        showYAxisLabel: this.showYAxisLabel,
        xAxisLabel: this.xAxisLabel,
        yAxisLabel: this.yAxisLabel,
        animations: this.animations,
        showDataLabel: this.showDataLabel,
        roundDomains: this.roundDomains,
        trimYAxisTicks: this.trimYAxisTicksDialog,
        maxYAxisTickLength: this.maxYAxisTickLengthDialog,
        showGridLines: this.showGridLines,
        customColors: this.customColors,
        tooltipDisabled: this.tooltipDisabled,
        dialogLegends: this.dialogLegendsBouquet,
        dataLabelFormatting: (val) => Intl.NumberFormat("de-DE").format(parseFloat(val)),
        xAxisTickFormatting: (val) => Intl.NumberFormat("de-DE").format(parseFloat(val)),
      },
      panelClass: ['mat-dialog-gen', 'mat-dialog-portfolio-mix', 'mat-dialog-clients-bouquet'],
    })
  }

  private translateLabels() {
    const portfolioP = this.translateService.get('labels.portfolioProductMix');
    const portfolioC = this.translateService.get('labels.portfolioChannelMix');
    const sales = this.translateService.get('labels.sales');
    const bonus = this.translateService.get('panels.bonus');
    const policy = this.translateService.get('panels.policy');
    const title = this.translateService.get('panels.mixPortfolioSales');
    const product = this.translateService.get('filtersPanel.product');
    const channel = this.translateService.get('panels.channel');
    const others = this.translateService.get('labels.others');

    forkJoin([portfolioP, portfolioC, sales, bonus, policy, title, product, channel, others])
      .subscribe(result => {
        this.portfolio_product_label = result[0];
        this.portfolio_channel_label = result[1];
        this.sales_label = result[2];
        this.bonus_label = result[3];
        this.policy_label = result[4];
        this.title_label = result[5];
        this.product_label = result[6];
        this.channel_label = result[7];
        this.others_label = result[8];
      });
  }

  exportAsXLSX(): void {
    let clientsValuesAll: any[] = this.clientsValuesAll;

    const allBouquetValues = this.group_graph_items(clientsValuesAll, clientsValuesAll.length + 1);
    const bouquetsValuesToExport = this.generateExportData(allBouquetValues);

    const headers = ['', this.unsubscriptionsName, this.subscriptionsName];

    this.data_total = [[[headers], bouquetsValuesToExport].flat()];
    this.data_sheet_names = [this.unsubscriptionsName + " - " + this.subscriptionsName];

    this.excelService.exportAsExcelFile(this.data_total, this.data_sheet_names, 'clients_casualties_bouquet_chart', this.data_filter_details);
  }

  private generateExportData(array: any[]): any[] {
    let container: any[] = [];
    let row: any[] = [];
    for (let item of array) {
      const name = item.name;
      const portfolioValue = Math.abs(item.series[0].value);
      const salesValue = item.series[1].value;

      row = [name, portfolioValue, salesValue];
      container.push(row);
    }
    return container;
  }

  formatXAxisTick(val) {
    return Intl.NumberFormat("de-DE").format(parseFloat(val));
  }

  public formatDataLabel(value) {
    return Intl.NumberFormat("de-DE").format(parseFloat(value));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

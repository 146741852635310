import { Component, OnInit } from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { takeUntil } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { RetentionReason } from 'src/app/shared/models/retention-reason';
import { ExcelFileInfo } from 'src/app/shared/models/excel-file-info';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { environment } from 'src/environments/environment';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import _ from 'lodash';


@Component({
  selector: 'app-persistence-postretention',
  templateUrl: './persistence-postretention.component.html',
  styleUrls: ['./persistence-postretention.component.scss']
})
export class PersistencePostretentionComponent implements OnInit {

  loaded: boolean = false;

  excelDataLoaded: ExcelFileInfo;

  // Drowpdown options
  dropdownId: string = "retentionReasonPersistance";
  retentionReasonOption: any = [];
  title: string = "";
  allName: string;

  // Dropdown multiple
  placeholderName: string;
  dateOptions: any = [];
  selectedDateOptions: any = [];
  enableCheckAll: boolean = false;
  limitSelection: number = 5;

  // Subscription
  unsubscribe$: Subject<any> = new Subject<any>();

  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "cancellation-persistence--persistence-postretention";

  constructor(
    private eventsService: EventsService,
    private translateService: TranslateService,
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private excelService: ExcelService,
    private i18n: I18nService,
    private atlasHelper: AtlasHelperService
  ) { }

  ngOnInit(): void {

    const emission = this.translateService.get('labels.emissionMonth');
    const all = this.translateService.get('labels.allF');

    forkJoin([emission, all]).pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      results => {
        this.placeholderName = results[0];
        this.allName = results[1];
      });

    this.apimstService.getRetentionReasonMaster()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((data: Array<RetentionReason>) => 
      { 
        this.retentionReasonOption.push(
          {
            id: '',
            type: 'retention-reason',
            name: this.allName,
            selected: true
          }
        );
        for (let item of data) {
          this.retentionReasonOption.push(
            {
              id: item.retention_reason_id,
              type: 'retention-reason',
              name: item.retention_reason_desc,
              selected: false
            }
          );
        }
      },
    
      error => console.log("An error occurred while retrieving retention reason master: " + error)
    
    );

    this.eventsService.cancellationWithheldPersistance$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(data => {
      
      setTimeout(() => {
        this.dateOptions = data;
        this.selectedDateOptions = [this.dateOptions[12]];
      }, 3000);

    });

    this.subscribeToActiveLanguage();
    this.subscribeToExcelInfo();
    this.subscribeToFiltersValues();
  }

  subscribeToExcelInfo(): void {
    this.eventsService.persistencePostretentionExcelInfoLoaded$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: excelDataLoaded => {
          this.loaded = false;

          if (!excelDataLoaded.json) return;

          this.excelDataLoaded = excelDataLoaded;
          if (this.excelDataLoaded.json[0].length > 3) {
            this.loaded = true;
          }
        }
      })
  }

  subscribeToFiltersValues(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({ next: filters => this.loaded = false })
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.excelDataLoaded.json, this.excelDataLoaded.headers, this.excelDataLoaded.excelFileName, this.excelDataLoaded.filtersValues);
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  // OnDestroy
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

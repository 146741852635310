import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COLORS } from 'src/app/shared/globals/globals';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { lineChartSeries, barChart } from './data';
import { AccumulatedEvolutionaryRenewalBy } from 'src/app/shared/models/accumulated-evolutionary-renewal-by';
import * as _ from 'lodash';
import { items } from 'src/app/slas/slas-section/slas-main/data';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { ExcelFileInfo } from 'src/app/shared/models/excel-file-info';

@Component({
  selector: 'evolutionary-renewalby-graphic',
  templateUrl: './evolutionary-renewalby-graphic.component.html',
  styleUrls: ['./evolutionary-renewalby-graphic.component.scss']
})
export class EvolutionaryRenewalbyGraphicComponent implements OnInit {

  loaded: boolean = false;

  filtersValues: any;
  type_data: any;         // type_data: c=> poliza, p=> prima
  dimension: any[] = [];  // dimension: ['b']=>ramo, [“c“] => canal

  graphInfo: AccumulatedEvolutionaryRenewalBy;

  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();

  legends: any[] = [];

  graphInfoRaw: AccumulatedEvolutionaryRenewalBy[];
  rateInfoRaw: any;

  itemsNumberLimit: number = 3;

  optionTabBonus: string = 'panels.bonus';
  optionTabPolicy: string = 'panels.policy';
  optionTabBranch: string = 'panels.branch';
  optionTabChannel: string = 'panels.channel';
  graphName: string = 'evolutionaryRenewalBy';
  actualOption: string = this.optionTabBranch;

  selectorsValues = {channel: this.optionTabBranch, policy: this.optionTabBonus};
  selectorsValuesClone = {channel: this.optionTabBranch, policy: this.optionTabBonus};

  customColors: any = [];
  view = [530, 155];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  showRightYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  legendTitle: string = '';
  legendPosition: string = '';
  showXAxisLabel: boolean = false;
  showYAxisLabel: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  animations: boolean = false;
  schemeType: string = 'ordinal';
  // colorScheme = {
  //   domain: [COLORS.darkBlue, COLORS.lightBlue, COLORS.darkGreen, COLORS.lightGreen, COLORS.lightPurple, COLORS.lightOrange, COLORS.darkYellow]
  // };
  yLeftAxisScaleFactor: string = '';
  yRightAxisScaleFactor: string = '';
  showGridLines: boolean = true;
  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  barPadding: number = 10;
  tooltipDisabled: boolean = false;
  roundDomains: boolean = true;
  noBarWhenZero: boolean = true;
  lineChartScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkBlue, COLORS.lightBlue, COLORS.lightestBlue, COLORS.darkestGreen, COLORS.darkGreen, COLORS.lightGreen, COLORS.lightPurple, COLORS.gray]
  };
  comboBarScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkBlue, COLORS.lightBlue, COLORS.lightestBlue, COLORS.darkestGreen, COLORS.darkGreen, COLORS.lightGreen, COLORS.lightPurple, COLORS.gray]
  };
  showTotalInTooltip: boolean = true;

  lineChartSeries: any[] = lineChartSeries;
  barChart: any[] = barChart;

  data_total: any[] = [];
  data_sheet_names: any;
  data_filter_details: any;

  monthsNames: any;
  othersNames: any;
  renewalRateNames: any;
  totalNames: any;

  constructor(
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private translateService: TranslateService,
    private eventsService: EventsService,
    private excelService: ExcelService
  ) { }

   private assignColorBar(data: any) {
    let name: string = '';
    let names: any[] = [];
    for(let item of data) {
      for(let serie of item.series) {
        if(names.indexOf(serie.name) == -1 && serie.name != this.othersNames && serie.name != this.totalNames) {
          names.push(serie.name);
        }
      }
    }
    for (let [index, value] of names.entries()) {
      this.customColors.push({name: value, value: this.comboBarScheme.domain[index]});
    }
    this.customColors.push(
      {name: this.renewalRateNames,value: COLORS.darkYellow},
      {name: this.totalNames,value: COLORS.black}
    )
  }

  ngOnInit(): void {
    this.selectorsValuesClone = _.cloneDeep(this.selectorsValues);

    this.subscription = this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        if(data.length !== 0) {
          this.filtersValues = data;

          this.resetValues();

          this.checkSelectedOptions(this.selectorsValuesClone['channel'], this.selectorsValuesClone['policy']);
          this.retrieveGraphData(this.type_data, this.dimension, this.filtersValues);
        }
      });

      this.eventsService.policiesBonusOption$.subscribe(
        data => {
          const option = data[0];
          const name = data[1];
          const group = data[2];

          if(name == 'evolutionaryRenewalBy') {
            this.setSelectedOptions(option, group);
            this.retrieveGraphData(this.type_data, this.dimension, this.filtersValues);

            this.getInfoForExcel();

          }
        }
      );
  }

  private getDataToExport() {
    const headers = ['', this.barChart.map(v => v.name)];
    const values: any[] = [];
    const seriesLength = this.barChart[0].series.length;

    for (let _i = 0; _i < seriesLength; _i++) {
      const array = [this.barChart[0].series[_i].name];
      for (let item of this.barChart) {
        array.push(item.series[_i].value);
      }
      values.push(array);
    }

    this.data_total.push([headers.flat()], values);

    const horizontalData = this.lineChartSeries.map(v => {
      return [v.name, v.series.map(i => i.value)].flat();
    });

    this.data_total.push(horizontalData);
  }

  private checkSelectedOptions(channelOption: any, policyOption: any): void {
    if (channelOption === this.optionTabBranch) this.dimension = ['b'];
    if (channelOption === this.optionTabChannel) this.dimension = ['c'];
    if (policyOption === this.optionTabBonus) this.type_data = 'p';
    if (policyOption === this.optionTabPolicy) this.type_data = 'c';
  }

  private retrieveGraphData(type_data: string, dimension: any, filtersValues: any) {
    this.loaded = false;

    const getMainData = this.apimstService.getCancellationEvolutionaryRenewal(type_data, dimension, filtersValues);
    const getRateData = this.apimstService.getCancellationEvolutionaryRenewalRate(type_data, filtersValues);
    const infoRequests = [getMainData, getRateData];

    forkJoin(infoRequests)
    .subscribe(
      results => {
        this.graphInfoRaw = results[0];
        this.rateInfoRaw = results[1];

        if( !this.requestHasInfo() ) { this.loaded = true; return; }

        this.getTranslations();

        let groupByMonth: any[] = this.groupByMonth(this.graphInfoRaw);
        this.setBarData(groupByMonth);
        this.setLineData(this.rateInfoRaw);

        this.setLegends(groupByMonth);
        this.assignColorBar(this.barChart);

        if (this.barChart.length !== 0) { this.data_filter_details = this.graphInfoRaw[0].filters }

        this.getInfoForExcel();

        this.loaded = true;
      }
    )

  }

  // Method to format the right y axis labels with '%'
  yRightAxisTickFormatting(val) {
    return  val.toLocaleString() + '%';
  }

  // Method to format the right y axis labels
  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value);
  }

  requestHasInfo() {
    return  this.graphInfoRaw[0]?.name &&
            this.graphInfoRaw[0]?.month &&
            this.graphInfoRaw[0]?.value &&
            this.graphInfoRaw[0]?.year;
  }

  getTranslations(): void {
    const months = this.translateService.get('abbreviatedMonthNames');
    const others = this.translateService.get('labels.rest');
    const renewalRate = this.translateService.get('labels.renewalTax');
    const total = this.translateService.get('labels.total');
    const translations = [months, others, renewalRate, total];

    forkJoin(translations)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(results => {
      this.monthsNames = results[0];
      this.othersNames = results[1];
      this.renewalRateNames = results[2];
      this.totalNames = results[3];
    });
  }

  groupByMonth(graphInfo: any) {
    let graphInfoCopy = graphInfo;
    let groupedByMonth: any[] = [];
    for(let item of graphInfoCopy) {item.group_by = item.month + '-' + item.year}
    groupedByMonth = _.chain(graphInfoCopy).groupBy("group_by").map((value, key) => ({ date: key, values: value })).value();

    return groupedByMonth;
  }

  setBarData(data: any): void {
    let info = data;
    let barItem: any = { name: '', series: []};
    let barChart: any[] = [];
    let others: any[] = [];

    for(let item of info) {
      barItem = { name: '', series: []};
      barItem.name = this.getMonthYearName(item.date);
      barItem.series = [];

      for (let [index, value] of item.values.entries()) {
        if(index <= this.itemsNumberLimit) {
          barItem.series.push({ name: value.name, value: parseFloat(value.value.replace(/,/g, '')) });
        } else {
          others.push(parseFloat(value.value.replace(/,/g, '')));
        }
      }

      barItem.series.push({ name: this.othersNames, value: _.sum(others) });
      others = [];

      // let total: number = 0;
      // for(let sum of barItem.series) {
      //   total = total + sum.value;
      // }
      // barItem.series.splice(0, 0, { name: this.totalNames, value: total });

      barChart.push(barItem);
    }

    this.barChart = barChart;
  }

  setLineData(data: any): void {
    let info = data;
    let series: any[] = [];
    let lineChartSeries: any[] = [];
    for(let item of info) {
      series.push({ name: this.getMonthYearName(item.month + '-' + item.year), value: parseFloat(item.rate) })
    }
    lineChartSeries.push({ name: this.renewalRateNames, series: series })
    this.lineChartSeries = lineChartSeries;
  }

  setLegends(data: any) {
    let firstItem = data[0];
    let legends: any[] = [];
    let legend = { name: '', color_code: '', type: '', gradient: '' };

    for (let [index, item] of firstItem.values.entries()) {
      if(index <= this.itemsNumberLimit) {
        legends.push({
          name: item.name,
          color_code: this.comboBarScheme.domain[index],
          type: 'round',
          gradient: 'h-gradient'
        });
      }
    }
    legends.push({
      name: this.renewalRateNames,
      color_code: COLORS.darkYellow,
      type: 'line',
      gradient: 'h-gradient'
    });

    this.legends = legends;
  }

  getMonthYearName(date: string) {
    let splitedDate = date.split('-');
    let monthName = this.monthsNames[splitedDate[0]];
    let year = splitedDate[1].substr(splitedDate[1].length - 2);
    let newDate = `${monthName}${year}`;

    return newDate;
  }

  getInfoForExcel(): void {
    this.data_total = [];
    const title = this.translateService.get('panels.evolutionaryRenewal');
    const option = this.translateService.get(this.actualOption);
    const translations = [title, option];
    let titleLabel = '';
    let optionLabel = '';

    forkJoin(translations)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(result => {
      titleLabel = result[0];
      optionLabel = result[1];
    });

    this.getDataToExport();
    this.data_sheet_names = [titleLabel + ' - ' + optionLabel];

    let excelInfo: ExcelFileInfo = {json: [this.data_total.flat()], headers: this.data_sheet_names, excelFileName: 'evo_renewal_chart', filtersValues: this.data_filter_details, filters: true};
    this.eventsService.evolutionaryRenewalExcelInfoLoaded$.next(excelInfo);

  }

  exportAsXLSX() {
    this.getInfoForExcel();
    this.excelService.exportAsExcelFile([this.data_total.flat()], this.data_sheet_names,  'evo_renewal_chart', this.data_filter_details);
  }

  resetValues(): void {
    this.lineChartSeries = [];
    this.barChart= [];
  }

  setSelectedOptions(option: any, group: any): void {
    if( group == 'channelGroup' ) {
      this.checkSelectedOptions(option, this.selectorsValuesClone['policy']);
      this.selectorsValuesClone.channel = option;
      this.selectorsValuesClone.policy = this.selectorsValuesClone['policy'];
      this.actualOption = option;
    } else {
      this.checkSelectedOptions(this.selectorsValuesClone['channel'], option);
      this.selectorsValuesClone.channel = this.selectorsValuesClone['channel'];
      this.selectorsValuesClone.policy = option;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<component-loader [width]="" [height]="200" class="d-block mt-3" *ngIf="!loaded"></component-loader>

<div *ngIf="loaded" class="legends-container mt-2 d-flex justify-content-end align-items-center">
    <app-legend class="mr-2" name="labels.technicalRejectionTax" color="dark-green" type="line" gradient="v-gradient"></app-legend>
    <app-legend class="mr-2" name="panels.administrative" color="dark-blue" type="line" gradient="v-gradient"></app-legend>
    <app-legend class="mr-2" name="panels.paymentZero" color="light-green" type="line" gradient="v-gradient"></app-legend>
    <app-legend class="mr-2" name="panels.withdrawal" color="dark-yellow" type="line" gradient="v-gradient"></app-legend>
    <app-legend class="mr-2" name="panels.fraud" color="light-purple" type="line" gradient="v-gradient"></app-legend>
</div>

<div class="line-charts-gen mt-3" *ngIf="loaded">
    <ngx-charts-line-chart
        [view]="view"
        [scheme]="colorScheme"
        [legend]="legend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [showGridLines]="showGridLines"
        [showRefLines]="showRefLines"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [timeline]="timeline"
        [results]="sinistersRejections"
        [tooltipDisabled]="tooltipDisabled"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [rotateXAxisTicks]="rotateXAxisTicks"
        >
    </ngx-charts-line-chart>
</div>
<component-loader *ngIf="!loaded" [width]="580" [height]="200"></component-loader>

<!-- Checkboxes -->
<div class="checkboxes-container d-flex flex-wrap mt-2" *ngIf="loaded">
  <label class="main-checkbox checkbox-legend d-block" *ngFor="let checkbox of checkboxes; let i=index;">
      <app-legend [name]="checkbox.name" [color]="checkbox.color" type="round" gradient="v-gradient"></app-legend>
      <input #checkbox.name type="checkbox" [checked]="checkbox.checked" (click)="updateCheckedCheckboxes(checkbox.id)">
      <span class="checkmark"></span>
  </label>
</div>

<div class="d-flex" *ngIf="loaded">
  <ngx-charts-bar-vertical 
    class="churn-per-crop-graphic"
    [view]="view"
    [scheme]="colorScheme"
    [results]="harvestGraphToPrint"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [barPadding]="barPadding"
    [noBarWhenZero]="noBarWhenZero"
    [rotateXAxisTicks]="rotateXAxisTicks"
    [yAxisTickFormatting]="yAxisTickFormatting"
    [animations]="animations">
    <ng-template #tooltipTemplate let-model="model">
      <div class="pie-tooltip">
        <span class="d-block">{{ model.name }}</span>
        <span class="d-block">{{ formatNumber(model.value) }}{{ tooltipUnit }}</span>
      </div>
    </ng-template>
  </ngx-charts-bar-vertical>
</div>

<div *ngIf="loaded">
  <color-bar
      [title]="pastMonth"
      [items]="previousHarvestBarToPrint"
      >
  </color-bar>
  <color-bar
      [title]="actualMonth"
      [items]="actualHarvesBarToPrint"
      >
  </color-bar>
</div>
import { ThrowStmt } from '@angular/compiler';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InteractionType } from '@azure/msal-browser';
import { MsalBroadcastService, MsalService } from '../shared/external/msal';
import { MSAL_GUARD_CONFIG } from '../shared/external/msal/constants';
import { MsalGuardConfiguration } from '../shared/external/msal/msal.guard.config';
import { EventsService } from '../shared/services/events/events.service';
import {FormsModule, NgForm} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isIframe = false;
  loggedIn = false;

  @ViewChild('loginForm') loginForm: NgForm;
  @ViewChild('ssoForm') ssoForm;

  constructor(    
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private router: Router,
    private eventsService: EventsService ) { }

  ngOnInit(): void {


    console.log("EN INIT");


    this.authService.handleRedirectObservable().subscribe({
      next: (result) => {this.checkAccount(); if(this.loggedIn){ this.navigateToPrehome()}},
      error: (error) => {console.log(error)}
    }); 


    this.checkAccount();    

    if(!this.loggedIn){
       this.login();
     }       

    /*console.log("COMPROBAR EL LOGIN");

    if(!this.loggedIn){
      console.log("NO TIENE LOGIN");
      //this.loginSSOMst()
    }*/
  }

  checkAccount() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  login() {
    
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.loginPopup({...this.msalGuardConfig.authRequest})
        .subscribe(() => {this.navigateToPrehome()});
    } else {
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest});
    }
  }  


  loginSSOMst() {
    console.log(this.loginForm);
    console.log(this.ssoForm);

    // let hiddenForm:any = document.getElementById("ssoForm");
    // hiddenForm.submit();

  }    

  navigateToSales() {
    console.log('login redisrecto navigate to sales');
    this.router.navigate(['/sales/details']);
    this.eventsService.loginRedirectionSubject$.next(true);
  }

  navigateToPrehome() {
    console.log('login redisrecto navigate to prehome');
    this.router.navigate(['/portal']);
    this.eventsService.loginRedirectionSubject$.next(true);
  }

}

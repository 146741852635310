import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { PortfolioSaleMix } from 'src/app/shared/models/portfolio-sales-mix'; 
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COLORS } from 'src/app/shared/globals/globals'; 
import { MatDialog } from '@angular/material/dialog';
import { PortfolioMixDialogComponent } from 'src/app/shared/dialogs/portfolio-mix-dialog/portfolio-mix-dialog.component';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { environment } from '../../../../environments/environment';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import _ from 'lodash';

@Component({
  selector: 'app-portfolio-vs-sales-mix',
  templateUrl: './portfolio-vs-sales-mix.component.html',
  styleUrls: ['./portfolio-vs-sales-mix.component.scss']
})
export class PortfolioVsSalesMixComponent implements OnInit, OnDestroy {
  
  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "sale-portfolio--portfolio-vs-sale";

  loaded: boolean = false; 

  filtersValues: any;
  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();

  dialogLegendsBouquet: any = [
    {
      name: 'labels.portfolioProductMix',
      color: 'light-blue',
      type: 'round',
      gradient: 'v-gradient'
    },
    {
      name: 'labels.sales',
      color: 'dark-blue',
      type: 'round',
      gradient: 'v-gradient'
    },
  ];

  dialogLegendsChannel: any = [
    {
      name: 'labels.portfolioChannelMix',
      color: 'light-blue',
      type: 'round',
      gradient: 'v-gradient'
    },
    {
      name: 'labels.sales',
      color: 'dark-blue',
      type: 'round',
      gradient: 'v-gradient'
    },
  ];

  others_label: string = '';
  portfolio_product_label: string = '';
  portfolio_channel_label: string = '';
  sales_label: string = '';
  bonus_label: string = '';
  policy_label: string = '';
  title_label: string = '';
  product_label: string = '';
  channel_label: string = '';

  // Component variables
  leftOption: string = 'panels.bonus';
  rightOption: string = 'panels.policy';
  graphName: string = 'portfolioSales';

  // Control data retrieved
  channel_sale_loaded:boolean = false
  bouquet_sale_loaded:boolean = false
  channel_portfolio_loaded:boolean = false
  bouquet_portfolio_loaded:boolean = false

  // Number of items in the graph
  graph_items = 4
  graph_items_dialog = 12

  // Variable to save data
  portfolioSalesMixData: any = [];
  month: string;
  year: string;
  bouquetValues: any;
  channelValues: any;
  bouquetValuesDialog: any;
  channelValuesDialog: any;
  policiesBouquets: any = [];
  bonusBouquets: any = [];
  policiesChannels: any = [];
  bonusChannels: any = [];
  policiesBonusSelectedOption: string = 'panels.bonus';
  upperLegendTitle: string = 'labels.portfolioProductMix';
  lowerLegendTitle: string = 'labels.portfolioChannelMix';

  // Graph options
  view: any[] = [500, 190];
  xAxisTicks: any[];
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = '';
  showYAxisLabel = true;
  showDataLabel = true;
  animations = false;
  schemeType = 'ordinal';
  colorScheme = {
    selectable: true,
    group: 'ordinal',
    domain: ['#08d1c6', '#0990b7']
  };
  legend = false;
  legendTitle: string = '';
  legendPosition: string = '';
  xAxis;
  yAxis;
  yAxisLabel = '';
  tooltipDisabled: boolean = true;
  showGridLines: boolean = true;
  activeEntries: any[] = [];
  trimXAxisTicks: boolean = true;
  trimYAxisTicks: boolean = true;
  trimYAxisTicksDialog: boolean = false;
  rotateXAxisTicks: boolean = true;
  maxXAxisTickLength: number = 16;
  maxYAxisTickLength: number = 10;
  maxYAxisTickLengthDialog: number = 20;
  xAxisTickFormatting: any;
  yAxisTickFormatting: any;
  yAxisTicks: any[];
  barPadding = 8;
  roundDomains: boolean = true;
  xScaleMax: number;
  dataLabelFormatting: any;
  noBarWhenZero: boolean = true;
  customColors: any = [];

  // Variables to store the export data
  data_total: any[] = [];
  data_sheet_names: any[] = [];
  data_filter_details: any[] = [];

  // Default constructor
  constructor(
    private apimstService: ApimstService,
    private events: EventsService,
    private filtersService: FiltersService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    private atlasHelper: AtlasHelperService,
    private i18n: I18nService
    ) { }

  // OnInit
  ngOnInit(): void {
    this.translateLabels();
  
    this.subscribeToActiveLanguage();
   
    this.subscription = this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        if (data.length !== 0) {
          this.loaded = false;
          this.filtersValues = data;
          this.translateService.get('labels.others').subscribe(data => this.others_label = data);
          this.year = data.filter(data => data.name === 'year')[0].value;
          this.resetVariables();
          this.retrievePortfolioVsSalesMixGraph('s',['c'],data); // s -> sale; c -> channel; b -> bouquet
          this.retrievePortfolioVsSalesMixGraph('s',['b'],data);
          this.retrievePortfolioVsSalesMixGraph('p',['c'],data); // p -> portfolio
          this.retrievePortfolioVsSalesMixGraph('p',['b'],data);
      
        }
      }
    );    

    // Event to detect the selection of Policies/Bonus option
    this.events.policiesBonusOption$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        const option = data[0];
        const name = data[1];
        if (option === this.rightOption && name === this.graphName) {
          this.policiesBonusSelectedOption = option;
          this.bouquetValues = this.group_graph_items(this.policiesBouquets,this.graph_items);
          this.channelValues = this.group_graph_items(this.policiesChannels,this.graph_items);
          this.bouquetValuesDialog = this.group_graph_items(this.policiesBouquets,this.graph_items_dialog);
          this.channelValuesDialog = this.group_graph_items(this.policiesChannels,this.graph_items_dialog);
        } else if (option === this.leftOption && name === this.graphName) {
          this.policiesBonusSelectedOption = option;
          this.bouquetValues = this.group_graph_items(this.bonusBouquets,this.graph_items);
          this.channelValues = this.group_graph_items(this.bonusChannels,this.graph_items);
          this.bouquetValuesDialog = this.group_graph_items(this.bonusBouquets,this.graph_items_dialog);
          this.channelValuesDialog = this.group_graph_items(this.bonusChannels,this.graph_items_dialog);
        }
      }
    );

    this.assignColorBar();

  }

  // Method to retrieve the portfolio vs sales mix graph data
  retrievePortfolioVsSalesMixGraph(typeReport: string, dimension:any,filters: any) {    
    this.apimstService.getPortfolioVsSalesMix(typeReport, dimension,filters).subscribe(
      (data: PortfolioSaleMix ) => {
        this.portfolioSalesMixData = data;
        this.data_filter_details = data.filters;
        
        if(dimension[0]=='b' && typeReport=='s')
          this.bouquet_sale_loaded = true
        else if(dimension[0]=='b' && typeReport=='p')
          this.bouquet_portfolio_loaded = true
        else if(dimension[0]=='c' && typeReport=='s')
          this.channel_sale_loaded = true
        else if(dimension[0]=='c' && typeReport=='p')
          this.channel_portfolio_loaded = true

        // Bouquets
        if(dimension[0]=='b'){
          for (let bouquet of data.details) {
            if(typeReport=='s'){
              this.fill_data(this.policiesBouquets,bouquet.name,1,bouquet.certificate)
              this.fill_data(this.bonusBouquets,bouquet.name,1,bouquet.premium)
            }
            else if(typeReport=='p'){
              this.fill_data(this.policiesBouquets,bouquet.name,0,bouquet.certificate)
              this.fill_data(this.bonusBouquets,bouquet.name,0,bouquet.premium)
            }
              

          };  
        }

        // Channels
         if(dimension[0]=='c'){
          for (let channel of data.details) {
            if(typeReport=='s'){
              this.fill_data(this.policiesChannels,channel.name,1,channel.certificate)
              this.fill_data(this.bonusChannels,channel.name,1,channel.premium)
            }
            else if(typeReport=='p'){
              this.fill_data(this.policiesChannels,channel.name,0,channel.certificate)
              this.fill_data(this.bonusChannels,channel.name,0,channel.premium)
            }             

       
           }  
        }
    

        if (this.policiesBonusSelectedOption === this.rightOption) {
          if(this.channel_portfolio_loaded  && this.channel_sale_loaded 
            && this.bouquet_portfolio_loaded && this.bouquet_sale_loaded){
              // Leave only four elements elements
              this.channelValues = this.group_graph_items(this.policiesChannels,this.graph_items);
              this.bouquetValues = this.group_graph_items(this.policiesBouquets,this.graph_items);
              this.channelValuesDialog = this.group_graph_items(this.policiesChannels,this.graph_items_dialog);
              this.bouquetValuesDialog = this.group_graph_items(this.policiesBouquets,this.graph_items_dialog);
          }
        } else if (this.policiesBonusSelectedOption === undefined || this.policiesBonusSelectedOption === this.leftOption) {
          if(this.channel_portfolio_loaded  && this.channel_sale_loaded 
            && this.bouquet_portfolio_loaded && this.bouquet_sale_loaded){
              this.bouquetValues = this.group_graph_items(this.bonusBouquets,this.graph_items);
              this.channelValues = this.group_graph_items(this.bonusChannels,this.graph_items);
              this.bouquetValuesDialog = this.group_graph_items(this.bonusBouquets,this.graph_items_dialog);
              this.channelValuesDialog = this.group_graph_items(this.bonusChannels,this.graph_items_dialog);
          }
            
        }

          
      },
      error => console.log("An error ocurred while retrieving portfolio sales mix graph data: " + error)
    );
  }

  group_graph_items(array,number_items){
    array = array.sort((a,b) =>{ 
      if(a.series[1].value > b.series[1].value){
        return -1
      }else if (b.series[1].value > a.series[1].value){
        return 1
      }else{
        return 0;
      }
    });    
    let output_array=[];
    let acummulated_series=[{name: 'portfolio', value: 0},{name: 'sales', value: 0}];
    for(let index=0;index<array.length;index++){
      if(index<number_items-1){
        output_array.push(array[index])
      }else if (index < array.length) {
        acummulated_series[0].value+=array[index].series[0].value;
        acummulated_series[1].value+=array[index].series[1].value;
      }
    }

    if (number_items < array.length) {
      output_array.push({name: this.others_label,series: acummulated_series});
    }

    this.loaded = true;


    return output_array;
  }


  fill_data(array:any, name:string, index:number, value:string){
    let element;
    for (let object of array) {
      if(object.name==name){
        element = object;
      }
    }

    let calculated_value = (value && value.trim().length>0) ? parseFloat(value) : 0

    if(!element){
      element={name: name, series:[{name: 'portfolio', value:0},{name: 'sales', value:0}]}
      array.push(element);
    }

    let seriesName: string;
    if (index === 0) {
      calculated_value = -calculated_value;
      seriesName = 'portfolio';
    } else {
      seriesName = 'sales';
    }
    element.series[index] = {name: seriesName, value: calculated_value};

  }

  // Method to reset the data variables
  private resetVariables() { 
    this.bouquetValues = [];
    this.channelValues = [];
    this.policiesBouquets = [];
    this.bonusBouquets = [];
    this.policiesChannels = [];
    this.bonusChannels = [];
    this.channel_sale_loaded = false
    this.bouquet_sale_loaded = false
    this.channel_portfolio_loaded = false
    this.bouquet_portfolio_loaded = false
  
  }

  // Method to assign the bar graph colors
  private assignColorBar() {
    this.customColors.push(
      {
        name: 'portfolio',
        value: COLORS.lightBlue
      },
      {
        name: 'sales',
        value: COLORS.darkBlue
      }
    );
  }

  formatPercent(val) {
    if (val <= 100) {
      return Math.abs(val) + '%';
    }
  } 

  openDialogMixProduct(): void {
    this.dialog.open(PortfolioMixDialogComponent, {
      data : {
        sectionTitle: 'panels.mixPortfolioSales',
        view: [850, 550],
        scheme: this.colorScheme,
        schemeType: this.schemeType,
        results: this.bouquetValuesDialog,
        gradient: this.gradient,
        xAxis: this.showXAxis,
        yAxis: this.showYAxis,
        legend: this.showLegend,
        showXAxisLabel: this.showXAxisLabel,
        showYAxisLabel: this.showYAxisLabel,
        xAxisLabel: this.xAxisLabel,
        yAxisLabel: this.yAxisLabel,
        animations: this.animations,
        showDataLabel: this.showDataLabel,
        dataLabelFormatting: this.dataLabelFormatting,
        roundDomains: this.roundDomains,
        trimYAxisTicks: this.trimYAxisTicksDialog,
        maxYAxisTickLength: this.maxYAxisTickLengthDialog,
        showGridLines: this.showGridLines,
        customColors: this.customColors,
        tooltipDisabled: this.tooltipDisabled,
        dialogLegends: this.dialogLegendsBouquet
      },
      panelClass: ['mat-dialog-gen', 'mat-dialog-portfolio-mix'],
    })

  }

  openDialogMixChannel(): void {
    this.dialog.open(PortfolioMixDialogComponent, {
      data : {
        sectionTitle: 'panels.mixPortfolioSales',
        view: [850, 550],
        scheme: this.colorScheme,
        schemeType: this.schemeType,
        results: this.channelValuesDialog,
        gradient: this.gradient,
        xAxis: this.showXAxis,
        yAxis: this.showYAxis,
        legend: this.showLegend,
        showXAxisLabel: this.showXAxisLabel,
        showYAxisLabel: this.showYAxisLabel,
        xAxisLabel: this.xAxisLabel,
        yAxisLabel: this.yAxisLabel,
        animations: this.animations,
        showDataLabel: this.showDataLabel,
        dataLabelFormatting: this.dataLabelFormatting,
        roundDomains: this.roundDomains,
        trimYAxisTicks: this.trimYAxisTicksDialog,
        maxYAxisTickLength: this.maxYAxisTickLengthDialog,
        showGridLines: this.showGridLines,
        customColors: this.customColors,
        tooltipDisabled: this.tooltipDisabled,
        dialogLegends: this.dialogLegendsChannel
      },
      panelClass: ['mat-dialog-gen', 'mat-dialog-portfolio-mix'],
    })

  }

  // Method to translate the component labels
  private translateLabels() {
    const portfolioP = this.translateService.get('labels.portfolioProductMix');
    const portfolioC = this.translateService.get('labels.portfolioChannelMix');
    const sales = this.translateService.get('labels.sales');
    const bonus = this.translateService.get('panels.bonus');
    const policy = this.translateService.get('panels.policy');
    const title = this.translateService.get('panels.mixPortfolioSales');
    const product = this.translateService.get('filtersPanel.product');
    const channel = this.translateService.get('panels.channel');

    forkJoin([portfolioP, portfolioC, sales, bonus, policy, title, product, channel])
    .subscribe(result => {
      this.portfolio_product_label = result[0];
      this.portfolio_channel_label = result[1];
      this.sales_label = result[2];
      this.bonus_label = result[3];
      this.policy_label = result[4];
      this.title_label = result[5];
      this.product_label = result[6];
      this.channel_label = result[7];
    });
  }

  // Method to export data to XSLX
  exportAsXLSX():void {
    let productValues: any[] = [];
    let channelValues: any[] = [];
    let optionLabel: string =  ''; 
    if (this.policiesBonusSelectedOption === this.leftOption) {
      productValues = this.bonusBouquets;
      channelValues = this.bonusChannels;
      optionLabel = this.bonus_label;
    } else {
      productValues = this.policiesBouquets;
      channelValues = this.policiesChannels;
      optionLabel = this.policy_label;
    }

    const allChannelValues = this.group_graph_items(channelValues, channelValues.length+1);
    const allBouquetValues = this.group_graph_items(productValues, productValues.length+1);
    const channelValuesToExport = this.generateExportData(allChannelValues);
    const bouquetsValuesToExport = this.generateExportData(allBouquetValues);

    const productHeaders = ['', this.portfolio_product_label + ' (%)', this.sales_label + ' (%)'];
    const channelHeaders = ['', this.portfolio_channel_label + ' (%)', this.sales_label + ' (%)'];

    this.data_total = [[[productHeaders], bouquetsValuesToExport].flat(), [[channelHeaders], channelValuesToExport].flat()]; 
    this.data_sheet_names = [this.product_label + ' - ' + optionLabel, this.channel_label + ' - ' + optionLabel];

    this.excelService.exportAsExcelFile(this.data_total, this.data_sheet_names,  'portfolio_mix_chart', this.data_filter_details);
  }

  // Method to generate the array with the data to export 
  private generateExportData(array: any[]): any[] {
    let container: any[] = [];
    let row: any[] = [];
    for (let item of array) {
      const name = item.name;
      const portfolioValue = Math.abs(item.series[0].value);
      const salesValue = item.series[1].value;

      row = [name, portfolioValue, salesValue];
      container.push(row);
    }
    return container;
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

   // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

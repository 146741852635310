<div class="technical-search-bar" [ngStyle]="getStyles()">
  <div class="top">
    <app-color-line-tag [title]="title" [lineColor]="lineColor" [uppercase]="true">
    </app-color-line-tag>
    <app-input-box icon="search"></app-input-box>
  </div>
  <div class="bottom">
    <app-technical-search-bar-results [results]="results"></app-technical-search-bar-results>
  </div>
</div>

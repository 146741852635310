<div class="data-governance">
    <div class="section-header-block d-flex justify-content-start align-items-center pb-4">
        <span class="title section-card-title dg-gray-color">{{titleLabel}}</span>
        <mat-icon class="edit-icon ml-2 dg-gray-color" (click)="textEdition()" *ngIf="hasEditPermission">mode_edit</mat-icon>
    </div>

    <div class="d-flex">
        <div class="text-container">
            <div *ngIf="!openTextEditor" [innerHtml]="descriptionText"></div>
            <app-html-editor *ngIf="openTextEditor" [textEditor]="textEditor" [height]="textEditorHeight"
                [width]="textEditorWidth" [htmlContent]="descriptionText"></app-html-editor>
        </div>
        <div class="image-container mt-n4 ml-6">
            <div class="d-flex justify-content-between">
                <input class="input-file" id="inputFile" type="file" title="" (change)="encodeImageFileAsURL()" />
                <mat-icon class="input-file-icon dg-gray-color" *ngIf="hasEditPermission">mode_edit</mat-icon>
                <div class="dg-gray-color d-flex">
                    <span>{{ 'labels.openControlPanel' | translate }}</span>
                    <mat-icon class="open-in-new-icon ml-2 dg-gray-color">open_in_new</mat-icon>
                </div>
            </div>
            <div class="image-container-fixed">
                <img src={{descriptionImage}}>
            </div>
        </div>
    </div>
</div>
<p class="section-title mb-1">
    <span class="section-title--name">{{ 'panels.dailySale' | translate }}</span>
    <span *ngIf="loaded" class="material-icons ml-2 fullscreen-icon" (click)="openDialog()">fullscreen</span>
    <mat-icon *ngIf="loaded" class="excel-icon ml-2 mb-2" svgIcon="excel" (click)="exportAsXLSX()"></mat-icon>
</p>

<component-loader *ngIf="!loaded" [width]="" [height]="160"></component-loader>

<div class="ml-n3" *ngIf="loaded">
    <stacked-vertical-horizontal-graph
      [view]="view"
      [scheme]="comboBarScheme"
      [colorSchemeLine]="lineChartScheme"
      [customColors]="customColors"
      [results]="barChart"
      [animations]="animations"
      [lineChart]="lineChartSeries"
      [yAxisTickFormatting]="yAxisTickFormatting"
      [yLeftAxisScaleFactor]="yLeftAxisScaleFactor"
      [yRightAxisScaleFactor]="yRightAxisScaleFactor"
      [yRightAxisTickFormatting]="yRightAxisTickFormatting"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [legendTitle]="legendTitle"
      [legendPosition]="legendPosition"
      [showGridLines]="showGridLines"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [showRightYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [xAxisTicks]="xAxisTicks"
      [barPadding]="barPadding"
      [tooltipDisabled]="tooltipDisabled"
      [schemeType]="schemeType"
      [roundDomains]="roundDomains"
      [noBarWhenZero]="noBarWhenZero"
      [showTotalInTooltip]="showTotalInTooltip">
    </stacked-vertical-horizontal-graph>
  </div> 
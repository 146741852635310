import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataDiscoveryNewDossierDialogComponent } from 'src/app/shared/components/data-discovery-new-dossier-dialog/data-discovery-new-dossier-dialog.component';
import { DataDiscoveryUserFolder } from 'src/app/shared/models/data-discovery-user-folder';
import { ApimstService } from 'src/app/shared/services/apimst.service';

@Component({
  selector: 'app-data-discovery-home',
  templateUrl: './data-discovery-home.component.html',
  styleUrls: ['./data-discovery-home.component.scss']
})
export class DataDiscoveryHomeComponent implements OnInit {

  routerLink: string = "/portal";
  headerTitle: string = 'home.dataDiscoveryTitle';
  folders: Array<DataDiscoveryUserFolder>;
  loaded: boolean = false;
  createNewLabel: string = 'labels.createNew';

  unsubscribe$: Subject<any> = new Subject<any>();
  
  constructor(
    private dialog: MatDialog,
    private apimstService: ApimstService
  ) { }

  ngOnInit(): void {
    this.apimstService.getDataDiscoveryUserFolders()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.folders = data;
        console.log(data);
        this.loaded = true;
      });
  }

  newDossierDialog() {
    this.dialog.open(DataDiscoveryNewDossierDialogComponent, {
      data : {},
      panelClass: 'data-discovery-new-dossier-dialog'
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

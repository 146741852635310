<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex">
            <div class="d-flex align-items-center">
                <span class="title section-card-subtitle">{{ 'panels.clientsStock' | translate }}</span>
                <app-btn-gobernance-info 
                    class="ml-2" 
                    [endpoint]="endpoint" 
                    [endpointParams]="endpointParams"
                    *ngIf="hasGobernanceInfo">
                </app-btn-gobernance-info>
            </div>
            <mat-icon class="excel-icon ml-2" svgIcon="excel" (click)="exportAsXLSX()" *ngIf="loaded"></mat-icon>
        </div>
        <!-- <app-option-tab [leftOption] = amount [rightOption] = receipts [graphName] = graphName class = "option-tab align-items-center"></app-option-tab> -->
    </div>

    <div class="mt-2 ">
        <app-clients-stock-graphic></app-clients-stock-graphic>
    </div>

</app-widget>
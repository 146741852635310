<component-loader *ngIf="!loaded" [width]="" [height]="210"></component-loader>

<div class="d-flex justify-content-end align-items-center" *ngIf="loaded">
    <app-legend *ngFor = "let item of dropdownSelectedValues; let i = index" class="mr-2" name={{item}} color={{colorListCSS[i]}} type="line" gradient="v-gradient"></app-legend>
    <!-- <mat-icon *ngIf="persistanceNewSaleData.length !== 0" class="excel-icon ml-2" svgIcon="excel" (click)="exportAsXLSX()"></mat-icon> -->
</div>

<div class="line-charts-gen" *ngIf="loaded">
    <ngx-charts-line-chart
        [view]="view"
        [scheme]="colorScheme"
        [legend]="legend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [showGridLines]="showGridLines"
        [showRefLines]="showRefLines"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [yScaleMax]="yScaleMax"
        [timeline]="timeline"
        [results]="persistanceNewSaleData"
        [tooltipDisabled]="tooltipDisabled"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [customColors]="customColors"
        >
    </ngx-charts-line-chart>
</div>

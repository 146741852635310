import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, Output, EventEmitter } from '@angular/core';
import { EventsService } from 'src/app/shared/services/events/events.service';

@Component({
  selector: 'app-option-tab',
  templateUrl: './option-tab.component.html',
  styleUrls: ['./option-tab.component.scss']
})
export class OptionTabComponent implements OnInit {

  // Input variables for the left and right options
  @Input() leftOption: string;
  @Input() rightOption: string;
  @Input() graphName: string;
  @Input() buttonGroupName: string;

  // Element reference for the left and right options
  @ViewChild('left') left: ElementRef;
  @ViewChild('right') right: ElementRef;

  // Variable to notify an event when option changed
  @Output() optionEvent = new EventEmitter();

  // Default constructor
  constructor(
    private render: Renderer2,
    private events: EventsService) {}

  // OnInit
  ngOnInit(): void {
  }

  // Method to act when left option is selected
  selectLeft() {
    this.render.addClass(this.left.nativeElement, 'selected');
    this.render.removeClass(this.right.nativeElement, 'selected');
    this.optionEvent.emit(this.leftOption);
    this.events.policiesBonusOption$.emit([this.leftOption, this.graphName, this.buttonGroupName]);
  }

  // Method to act when right option is selected
  selectRight() {
    this.render.addClass(this.right.nativeElement, 'selected');
    this.render.removeClass(this.left.nativeElement, 'selected');
    this.optionEvent.emit(this.rightOption);
    this.events.policiesBonusOption$.emit([this.rightOption, this.graphName, this.buttonGroupName]);
  }

}

import { Component, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { EventsService } from '../../services/events/events.service';

@Component({
  selector: 'app-reset-filters-dialog',
  templateUrl: './reset-filters-dialog.component.html',
  styleUrls: ['./reset-filters-dialog.component.scss']
})
export class ResetFiltersDialogComponent implements OnInit {

  constructor(
    private cookieService: CookieService,
    private events: EventsService,
    public dialogRef: MatDialogRef<ResetFiltersDialogComponent>
  ) { }

  ngOnInit(): void {}

  refreshFilters(): void {
    // console.log('Refesh filters');
    this.events.refreshFilters$.emit(true);
    this.closeDialog();

  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}

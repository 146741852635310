<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex align-items-center">   
            <span class="title section-card-title">{{ 'panels.monthlySale' | translate }}</span>
            <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams"
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </div>
        <mat-icon class="excel-icon ml-n6" svgIcon="excel" (click)="exportAsXLSX()"></mat-icon>
        <app-option-tab [leftOption] = discreet [rightOption] = accumulated [graphName] = graphName class = "option-tab"></app-option-tab>
        <app-option-tab [leftOption] = bonus [rightOption] = policy [graphName] = graphName class = "option-tab align-items-center"></app-option-tab>
    </div>

    <div class="mb-3">
        <app-monthly-sale-pie-graph></app-monthly-sale-pie-graph>
    </div>

    <app-monthly-sale-table></app-monthly-sale-table>


</app-widget>
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrganizationSandwich } from 'src/app/shared/models/organization-sandwich';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { RequestsResultsStoreService } from 'src/app/shared/services/store/requests-results-store/requests-results-store.service';

@Component({
  selector: 'app-time-processes-sandwich',
  templateUrl: './time-processes-sandwich.component.html',
  styleUrls: ['./time-processes-sandwich.component.scss']
})
export class TimeProcessesSandwichComponent implements OnInit {

  unsubscribe$: Subject<any> = new Subject<any>();
  subscription: Subscription;

  translations: any[] = [
    'panels.clientTime',
    'panels.internalProcessingTime',
    'panels.ocurrenceNotification',
    'panels.paymentRejectionNotification',
    'panels.ocurrence',
    'panels.notification',
    'panels.documentation',
    'panels.paymentRejection'
  ];

  requestId: string = "sinisters-time-processes";

  indicatorsInfoRaw: any;

  loaded: boolean = false;

  clientTimeName: string = "";
  internalProcessingTimeName: string = "";
  ocurrenceNotificationName: string = "";
  paymentRejectionNotificationName: string = "";
  ocurrenceName: string = "";
  notificationName: string = "";
  documentationName: string = "";
  paymentRejectionName: string = "";

  percentagePrevious: string = "labels.pm";

  filtersValues: any;

  items: OrganizationSandwich = {
    top: [
      {
        title: "",
        quantity: "",
        percentage_previous: "",
        percentage: "",
        trend_direction: 1,
        column_width: 100,
      }
    ],
    middle: [
      {
        icon: "",
        title: "",
        icon_width: 30,
        column_width: 25,
        align: "center",
      }
    ],
    bottom: [
      {
        title: "",
        quantity: "",
        percentage_previous: "",
        percentage: "",
        trend_direction: 1,
        column_width: 100
      }
    ]
  }

  constructor(
    private translateService: TranslateService,
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private requestsStore: RequestsResultsStoreService
  ) { }

  ngOnInit(): void {

    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.translateService.get(this.translations)
            .subscribe({
              next: names => {
                this.clientTimeName = names['panels.clientTime'];
                this.internalProcessingTimeName = names['panels.internalProcessingTime'];
                this.ocurrenceNotificationName = names['panels.ocurrenceNotification'];
                this.paymentRejectionNotificationName = names['panels.paymentRejectionNotification'];
                this.ocurrenceName = names['panels.ocurrence'];
                this.notificationName = names['panels.notification'];
                this.documentationName = names['panels.documentation'];
                this.paymentRejectionName = names['panels.paymentRejection'];
              },
              complete: () => {
                if (data.length !== 0) {
                  this.filtersValues = data;
                }

                this.resetVariables();
                this.retrieveTimeProcesses(this.filtersValues);
              }
            })
        }
      )
  }

  private retrieveTimeProcesses(filtersValues: any[] = []) {
    this.loaded = false;
    
    this.apimstService.getSinistersTimeProcesses(filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: data => {
          this.indicatorsInfoRaw = data;
          if (this.indicatorsInfoRaw == null) { this.loaded = true; return; }

          this.loaded = true;
          this.createIndicators(this.indicatorsInfoRaw);
        }
      })
  }

  resetVariables() {
    this.items = {
      top: [
        {
          title: "",
          quantity: "",
          percentage_previous: "",
          percentage: "",
          trend_direction: 1,
          column_width: 100,
        }
      ],
      middle: [
        {
          icon: "",
          title: "",
          icon_width: 30,
          column_width: 25,
          align: "center",
        }
      ],
      bottom: [
        {
          title: "",
          quantity: "",
          percentage_previous: "",
          percentage: "",
          trend_direction: 1,
          column_width: 100
        }
      ]
    }
  }

  createIndicators(indicators: any) {
    this.items = {
      top: [
        {
          title: this.clientTimeName,
          quantity: `${this.isValidValue(indicators[0].customer_time) ? Intl.NumberFormat("de-DE").format(parseFloat(indicators[0].customer_time)) : ""} / ${this.isValidValue(indicators[0].customer_time_target) ? Intl.NumberFormat("de-DE").format(parseFloat(indicators[0].customer_time_target)) : ""}`,
          percentage_previous: this.percentagePrevious,
          percentage: this.isValidValue(indicators[0].customer_time_diff) ? indicators[0].customer_time_diff : "",
          trend_direction: this.isValidValue(indicators[0].customer_time_diff_trend) ? parseInt(indicators[0].customer_time_diff_trend) : 0,
          column_width: 65,
        },
        {
          title: this.internalProcessingTimeName,
          quantity: `${this.isValidValue(indicators[0].internal_time) ? Intl.NumberFormat("de-DE").format(parseFloat(indicators[0].internal_time)) : ''} / ${this.isValidValue(indicators[0].internal_time_target) ? Intl.NumberFormat("de-DE").format(parseFloat(indicators[0].internal_time_target)) : ""}`,
          percentage_previous: this.percentagePrevious,
          percentage: this.isValidValue(indicators[0].internal_time_diff) ? indicators[0].internal_time_diff : "",
          trend_direction: this.isValidValue(indicators[0].internal_time_diff_trend) ? parseInt(indicators[0].internal_time_diff_trend) : 0,
          column_width: 35,
        },
      ],
      middle: [
        {
          icon: "assets/icons/ocurrence.svg",
          title: this.ocurrenceName,
          icon_width: 30,
          column_width: 25,
          align: "start",
        },
        {
          icon: "assets/icons/notification.svg",
          title: this.notificationName,
          icon_width: 30,
          column_width: 18,
          align: "center",
        },
        {
          icon: "assets/icons/documentation.svg",
          title: this.documentationName,
          icon_width: 30,
          column_width: 30,
          align: "end",
        },
        {
          icon: "assets/icons/payment_rejection.svg",
          title: this.paymentRejectionName,
          icon_width: 30,
          column_width: 27,
          align: "end",
        },
      ],
      bottom: [
        {
          title: this.ocurrenceNotificationName,
          quantity: `${this.isValidValue(indicators[0].occurrence) ? Intl.NumberFormat("de-DE").format(parseFloat(indicators[0].occurrence)) : ''} / ${this.isValidValue(indicators[0].occurrence_target) ? Intl.NumberFormat("de-DE").format(parseFloat(indicators[0].occurrence_target)) : ""}`,
          percentage_previous: this.percentagePrevious,
          percentage: this.isValidValue(indicators[0].occurrence_diff) ? indicators[0].occurrence_diff : "",
          trend_direction: this.isValidValue(indicators[0].occurrence_diff_trend) ? parseInt(indicators[0].occurrence_diff_trend) : 0,
          column_width: 33
        },
        {
          title: this.paymentRejectionNotificationName,
          quantity: `${this.isValidValue(indicators[0].payment_notification) ? Intl.NumberFormat("de-DE").format(parseFloat(indicators[0].payment_notification)) : ''} / ${this.isValidValue(indicators[0].payment_notification_target) ? Intl.NumberFormat("de-DE").format(parseFloat(indicators[0].payment_notification_target)) : ""}`,
          percentage_previous: this.percentagePrevious,
          percentage: this.isValidValue(indicators[0].payment_notification_diff) ? indicators[0].payment_notification_diff : "",
          trend_direction: this.isValidValue(indicators[0].payment_notification_diff_trend) ? parseInt(indicators[0].payment_notification_diff_trend) : 0,
          column_width: 67
        }
      ]
    }
  }

  isValidValue(value: any) {
    const isValid = value != null && typeof value != "undefined" && value !== '';
    return isValid;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

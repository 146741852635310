import { Component, OnInit, Input } from '@angular/core';
import { FiltersService } from '../../services/filters/filters.service';
import { EventsService } from 'src/app/shared/services/events/events.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @Input() title: string;
  @Input() items: any[];
  @Input() dropdownId: string;

  selectedValue;
  
  displaySelected(e) {
    this.selectedValue = e.target.id;
  }

  // Method to emit an event when option selected
  eventEmitter(e) {
    switch(this.dropdownId) {
      case 'salesQualityClaims':
        this.eventsService.salesQualityClaims$.emit(e.target);
        break;
      case 'sinistersQualityClaims':
        this.eventsService.sinistersQualityClaims$.emit(e.target);
        break;
      case 'sinistersQualityNPS':
        this.eventsService.sinistersQualityNPS$.emit(e.target);
        break;
      case 'salesQualityNPS':
        this.eventsService.salesQualityNPS$.emit(e.target);
        break; 
      case 'slaTargetType': 
        this.eventsService.targetsType$.emit(e.target.value);
        break;  
      case 'retentionReasonPersistance':
        this.eventsService.retentionReasonPersistance$.emit(e.target);
        break; 
      case 'dropdownMonth':
        this.filtersService.filtersMonthValue$.next(e.target.value);
        break;
      case 'dropdownYear':
        this.filtersService.filtersYearValue$.next(e.target.value);
        break; 
      case 'dropdownCurrency':
        this.filtersService.filtersCurrencyValue$.next(e.target.value);
        this.filtersService.currencyFilterValues$.next(e.target.value);
        break; 
      case 'dropdownBudgets':
        this.filtersService.filtersBudgetsValue$.next(e.target.value);
        this.filtersService.budgetsFilterValues$.next( Array.from( e.target.value ));
        break; 
      case 'ageReasons':
        this.eventsService.ageReasons$.next(e.target.value)
        break; 
      case 'rejectionsReasons':
        this.eventsService.sinisterRejectioReason$.next(e.target.value)
        break; 
      case 'salesMain':
        this.eventsService.salesMainType$.next(e.target.value)
        break; 
      case 'clientsQualityClaims':
        this.eventsService.clientsQualityClaims$.next(e.target.value)
        break; 
      case 'clientsQualityNPS':
          this.eventsService.clientsSurveys$.next(e.target.value)
          break; 
      default:
        break;  
    }
  }

  constructor(
    private filtersService: FiltersService,
    private eventsService: EventsService
  ) { }

  ngOnInit(): void { }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorsService {

  errorsObject$ = new BehaviorSubject<Array<any>>([]);

  getErrors() {
    return this.errorsObject$.value;
  }

  setError(error: any) {
    this.errorsObject$.next(error);
  }

  cleanErrors(): void {
    this.errorsObject$.next([]);
  }

  constructor() { }
}

<component-loader *ngIf="!loaded" [width]="580" [height]="220"></component-loader>

<div class="ml-n4" *ngIf="loaded">

    <div class="d-flex justify-content-end align-items-center">
        <app-legend class="mr-2" *ngFor="let legend of legends" [name]=legend.name [color]=legend.color [colorCode]=legend.color_code [type]=legend.type [gradient]=legend.gradient></app-legend>
    </div>
  
    <stacked-vertical-horizontal-graph
        [view]="view"
        [scheme]="comboBarScheme"
        [colorSchemeLine]="lineChartScheme"
        [customColors]="customColors"
        [results]="verticalGraph"
        [animations]="animations"
        [lineChart]="horizontalGraph"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [yLeftAxisScaleFactor]="yLeftAxisScaleFactor"
        [yRightAxisScaleFactor]="yRightAxisScaleFactor"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [rightYAxis]="showRightYAxis"
        [legend]="showLegend"
        [legendTitle]="legendTitle"
        [legendPosition]="legendPosition"
        [showGridLines]="showGridLines"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [showRightYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [barPadding]="barPadding"
        [tooltipDisabled]="tooltipDisabled"
        [schemeType]="schemeType"
        [roundDomains]="roundDomains"
        [noBarWhenZero]="noBarWhenZero"
        [rotateXAxisTicks]="rotateXAxisTicks"
        [hideHorizontalLine]="hideHorizontalLine"
        [showValuesAndPercentageInTooltip]="showValuesAndPercentageInTooltip"
        [showPercentageSymbolInTooltip]="showPercentageSymbolInTooltip">
      </stacked-vertical-horizontal-graph>
  
  </div>


<div class="business-container light-background">
    <app-dg-header [headerTitle]="headerTitle" [headerSubtitle]="headerSubtitle" [area]="area" [routerLink]="routerLink" [hasSearchButton]="true"></app-dg-header>
    <app-business-upper-menu *ngIf="loaded" [ngClass]="!detailLoaded ? 'disabled-links' : ''" [title]="title" [colorStripe]="colorStripe" [sections]="upperMenuSections" [selectedSectionGuid]="selectedSectionGuid"></app-business-upper-menu>
    <app-business-side-menu *ngIf="loaded" [ngClass]="!detailLoaded ? 'disabled-links' : ''" [selectedSectionIndex]="categoryInformation.order"></app-business-side-menu>
    <div *ngIf="loaded" id="businessContent" class="business-content">
        <app-search-option-tab class="business-content-menu" [ngClass]="!detailLoaded ? 'disabled-links' : ''" [options]="sections" [sectionName]="sectionName"></app-search-option-tab>
        <div class="business-content-detail">
            <component-loader *ngIf="!detailLoaded" [height]="485" [lightStyle]="true"></component-loader>
            <app-business-main-information *ngIf="detailLoaded" [sectionInformation]="categoryInformation"></app-business-main-information>
        </div>
    </div>
</div>

<div class="app-loader" *ngIf="!loaded">
    <base-loader></base-loader>
</div>
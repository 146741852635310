import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import _ from 'lodash';

@Component({
  selector: 'app-churn-rate',
  templateUrl: './churn-rate.component.html',
  styleUrls: ['./churn-rate.component.scss']
})
export class ChurnRateComponent implements OnInit {
  
  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "cancellation-detail--churn-rate";

  // Component variables
  discreet: string = 'panels.discreet';
  accumulated: string = 'panels.accumulated';
  policy: string = 'panels.policy';
  bonus: string = 'panels.bonus';
  graphName: string = 'cancellationChurnRate';

  unsubscribe$: Subject<any> = new Subject<any>();

  // Default constructor
  constructor(
    private atlasHelper: AtlasHelperService,
    private i18n: I18nService
  ) { }

  // OnInit
  ngOnInit(): void {
    this.subscribeToActiveLanguage();
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LossRatioTable } from 'src/app/shared/models/loss-ratio-table';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-loss-ratio-table',
  templateUrl: './loss-ratio-table.component.html',
  styleUrls: ['./loss-ratio-table.component.scss']
})
export class LossRatioTableComponent implements OnInit {

  tableInfo: LossRatioTable[] = [];
  loaded: boolean = false;

  unsubscribe$: Subject<any> = new Subject<any>();

  filtersValues: any[] = [];

  constructor(
    private translateService: TranslateService,
    private api: ApimstService,
    private filtersService: FiltersService
  ) { }

  ngOnInit(): void {

    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data.length !== 0) {
            this.filtersValues = data;

            this.resetValues();
            this.retrieveLossRatioTable("lrb", this.filtersValues);
          }
        });
  }

  retrieveLossRatioTable(type_report: string = "", filtersValues: any[] = []): void {
    this.loaded = false;

    this.api.getSinistersLossRatio(type_report, filtersValues)
      .subscribe({
        next: data => {

          // let temp = [
          //   {
          //     "name": "VIDA",
          //     "value": "",
          //     "previous": "256.43%",
          //     "diff": "-256.43%",
          //     "diff_trend": "0",
          //   },
          //   {
          //     "name": "ACCIDENTES PERSONALES",
          //     "value": "",
          //     "previous": "154.44%",
          //     "diff": "-154.44%",
          //     "diff_trend": "0"
          //   },
          //   {
          //     "name": "ROBO Y OTROS",
          //     "value": "",
          //     "previous": "86.55%",
          //     "diff": "-86.55%",
          //     "diff_trend": "0"
          //   },
          //   {
          //     "name": "VIVIENDA",
          //     "value": "",
          //     "previous": "118.61%",
          //     "diff": "-118.61%",
          //     "diff_trend": "0"
          //   },
          //   {
          //     "name": "COMERCIO A PRIMER RIESGO",
          //     "value": "",
          //     "previous": "428.33%",
          //     "diff": "-428.33%",
          //     "diff_trend": "0"
          //   },
          //   {
          //     "name": "No aplica",
          //     "value": "",
          //     "previous": "40.00%",
          //     "diff": "-40.00%",
          //     "diff_trend": "0"
          //   }
          // ]

          this.createTable(data);
          this.loaded = true;
        }
      })
  }

  createTable(info: any[] = []) {
    if (info == null) return;
    for(let serie of info) {
      this.tableInfo.push({
        branch: serie.name ? serie.name: "",
        loss_ratio: serie.value ? serie.value : "",
        comparative: serie.diff ? serie.diff : "",
        diffComparative: serie.diff_trend ? parseInt(serie.diff_trend) : 1,
        accumulated: serie.value_accumulated ? serie.value_accumulated : "",
        diffAccumulated: serie.diff_trend_accumulated ? parseInt(serie.diff_trend_accumulated) : 1,
        variation: serie.previous_accumulated ? serie.previous_accumulated : ""
      })
    }
  }

  resetValues() {
    this.tableInfo = [];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

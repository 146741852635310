import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RejectionsKpi } from 'src/app/shared/models/rejections-kpi';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-rejections-kpi',
  templateUrl: './rejections-kpi.component.html',
  styleUrls: ['./rejections-kpi.component.scss']
})
export class RejectionsKpiComponent implements OnInit {

  @Input() rejectionKpi: RejectionsKpi = {};
  
  amountInfoCopy: any = {};
  sinistersInfoCopy: any = {};

  kpiInfo: any = {};

  type_data: string;
  type_report: string;

  selectedOption = "rejectionsAmount";
  graphName: string = 'sinistersRejections';
  selectedGraph = "sinistersRejections";

  kpiLoaded: boolean = false;

  kpiTitle: string;
  
  percentagePrevious: string = "labels.pm";

  subscriptionFilters: Subscription;
  subscriptionRequest: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();

  filtersValues: any;

  constructor(
    private translateService: TranslateService,
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    this.kpiLoaded = false;

    // this.subscriptionFilters = this.filtersService.filtersValues$
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe({
    //     next: data => {
    //       this.translateService.get('panels.technicalRejectionTax')
    //         .subscribe({
    //           next: translation => this.kpiTitle = translation,
    //           complete: () => {
    //             this.clearInfoCopies();
    
    //             if ( data.length == 0 ) return;
    //             this.filtersValues = data;
    
    //             this.getSelectedOption();
    //             this.retrieveRejectionsKpi(this.type_data, this.filtersValues);
    //           }
    //         })
    //       }
    //     }
    //   );

    //   this.eventsService.optionTabMultipleSelected$
    //   .subscribe ({
    //     next: option => {
    //       this.selectedOption = option.id;
    //       this.selectedGraph = option.graphName;

    //       const isInButtonGroup = this.selectedOption == option.id && this.selectedGraph == this.graphName;
    //       if(!isInButtonGroup) return;
            
    //       this.getSelectedOption();
    //       this.createFromCopies();
    //     }
    //   })

  }

  retrieveRejectionsKpi(type_data: string, filtersValues: any): void {
    this.kpiLoaded = false;
    
    this.subscriptionRequest = this.apimstService.getSinistersRejections(type_data, 'srt', filtersValues)
      .subscribe({
        next: kpiInfo => {
          this.kpiInfo = kpiInfo;
          this.createKpi(this.kpiInfo);
          
          this.kpiLoaded = true;
        },
        error: error => console.log('An error occurred while retrieving rejections indicator ' + error),
        complete: () => {
          this.storeOptionValues();
        }
      })
  }

  createFromCopies(): void {
    let isAmountFilled = this.selectedOption == "rejectionsAmount" && this.amountInfoCopy.kpiInfo;
    let isSinistersFilled = this.selectedOption == "rejectionsSinisters" && this.sinistersInfoCopy.kpiInfo;

    if (isAmountFilled) {
      this.createCopy(this.amountInfoCopy);
    } else if (isSinistersFilled) {
      this.createCopy(this.sinistersInfoCopy);
    } else {
      this.retrieveRejectionsKpi(this.type_data, this.filtersValues);
    }
  }

  createCopy(copy: any): void {
    this.createKpi(copy.kpiInfo);
  }

  getSelectedOption(): void {
    if (this.selectedOption == "rejectionsAmount") this.type_data = 'a'
    if (this.selectedOption == "rejectionsSinisters") this.type_data = 's'
  }

  clearInfoCopies(): void {
    this.amountInfoCopy = {};
    this.sinistersInfoCopy = {};
  }

  storeOptionValues(): void {
    let infoStored = { kpiInfo: [] };
    let isAmount = this.selectedOption == "rejectionsAmount" && !this.amountInfoCopy.kpiInfo;
    let isSinisters = this.selectedOption == "rejectionsSinisters" && !this.sinistersInfoCopy.kpiInfo;
    infoStored.kpiInfo = this.kpiInfo;

    if (isAmount) this.amountInfoCopy = Object.assign({}, infoStored);
    if (isSinisters) this.sinistersInfoCopy = Object.assign({}, infoStored);
  }

  createKpi(kpiInfo: any = {}): void {
    this.rejectionKpi.title = this.kpiTitle;
    this.rejectionKpi.mainValue = this.isValidValue(kpiInfo.value) ? String(parseFloat(kpiInfo.value)) : '';
    this.rejectionKpi.mainValueUnit = "%";
    this.rejectionKpi.trend = this.isValidValue(kpiInfo.trend) ? parseFloat(kpiInfo.trend) : 0;
    this.rejectionKpi.percentage = this.isValidValue(kpiInfo.diff) ? String(parseFloat(kpiInfo.diff)) : '';
  }

  isValidValue(value: any) {
    const valid = value != '' && typeof value != 'undefined' && value != null;
    return valid;
  } 

  ngOnDestroy() {
    // this.subscriptionFilters.unsubscribe();
    // this.subscriptionRequest.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<div class="d-flex card-container">
    <div>
        <img src="assets/img/report-icon.png" (click)="goToFolder(folder.url)">
    </div>
    <div class="text-container">
        <span class="d-block text-white">{{ folder.name }}</span>
        <span class="d-block">{{ folder.description }}</span>
        <span class="d-block">{{ 'dataDiscovery.owner' | translate }}: {{ folder.owner }}</span>
        <span class="d-block">{{ 'dataDiscovery.modified' | translate }}: {{ folder.date_modified }}</span>
    </div>
   
</div>
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss']
})
export class InfoPanelComponent implements OnInit {
  @Input() width: string = "365";
  @Input() height: string = "560";
  @Input() title: string = "";
  @Input() subtitle: string = "";
  @Input() headerColor: string = "#111111";
  @Input() headerBgColor: string = "#555555";
  @Input() bgColor: string = "#ffffff";
  @Input() tabElements: any[];

  constructor() {
  }

  ngOnInit(): void {

  }

  getStyles() {

    var styles;

      styles = {
        'width.px': this.width,
        'height.px': this.height,
        'background-color': this.bgColor,
      }

    return styles;
  }

  getHeaderStyles() {

    var styles;

      styles = {
        'background-color': this.headerColor,
      }

    return styles;
  }

}

<app-widget>

  <div class="section-header-block d-flex justify-content-between align-items-center">
    <div class="d-flex">
      <span class="title section-card-title">{{ 'panels.sale' | translate }}</span>
      <app-btn-gobernance-info 
        class="ml-2" 
        [endpoint]="endpoint" 
        [endpointParams]="endpointParams"
        *ngIf="hasGobernanceInfo">
      </app-btn-gobernance-info>
    </div>
    
    <app-option-tab-multiple [options]="salesMainItems" [graphName]="graphName"></app-option-tab-multiple>

    <app-option-tab [leftOption]="leftOption" [rightOption]="rightOption" 
      [graphName]="graphName" class="option-tab ml-4" 
      (optionEvent)="optionDetector($event)">
    </app-option-tab>
  </div>
  
  <div class="legend-container legend-container-monthly-months container pt-3 px-2">
    <component-loader *ngIf="!legendLoaded" [width]="" [height]="25"></component-loader>
    <div class="row justify-content-end align-items-center" *ngIf="legendLoaded">
      <app-legend *ngFor="let item of legendsToPrint.slice(0, 5)" name={{item.name}} color={{item.color}} type={{item.type}} gradient={{item.gradient}}></app-legend>
      <div appTooltip class="d-flex" *ngIf="legendsToPrint.length != 0">
        <span class="material-icons text-white btn-legends-tooltip ml-1" *ngIf="legendsToPrint.length > 5">more</span>
        <ng-template #tooltipTemplate>   
            <div class="tooltip tooltip-monthly-months">
                <div class="tooltip-monthly-months-legends d-flex flex-wrap align-items-center">
                  <app-legend *ngFor="let item of legendsToPrint" name={{item.name}} color={{item.color}} type={{item.type}} gradient={{item.gradient}}></app-legend>
                </div>
            </div>    
        </ng-template>  
      </div>
    </div>
  </div>

  <div class="element mb-0">
    <app-monthly-sale-graph [policyBonus]="chosenOption" (monthlyLegend)="getChildLegends($event)"></app-monthly-sale-graph>
  </div>

  <div class="element mt-2 mb-0 mt-4">
    <app-daily-sale-graph [policyBonus]="chosenOption" (dailyLegend)="getChildLegends($event)"></app-daily-sale-graph>
  </div>

  <div class="element mt-4 mb-0">
    <app-sales-vs-plan-projection-graph></app-sales-vs-plan-projection-graph>
  </div>

</app-widget>

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, forkJoin, Subject, Subscription } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { RejectionsKpi } from 'src/app/shared/models/rejections-kpi';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { RejectionsMainService } from '../rejections-main.service';

@Component({
  selector: 'app-rejections-indicators-main',
  templateUrl: './rejections-indicators-main.component.html',
  styleUrls: ['./rejections-indicators-main.component.scss']
})
export class RejectionsIndicatorsMainComponent implements OnInit {

  panelOptions: any[] = [
    { id: "rejectionsAmount", name: "panels.amount", selected: true },
    { id: "rejectionsSinisters", name: "panels.sinisters", selected: false },
  ];
  graphName: string = 'sinistersRejections';

  selectedOption = "rejectionsAmount";
  selectedGraph = "sinistersRejections";
  selectedReason: any[] = [];

  subscriptionFilters: Subscription;
  subscriptionRequest: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();

  filtersValues: any;

  type_data: string;
  type_report: string;
  rejection_reason: any[];

  loaded: boolean = false;
  rejectionKpi: RejectionsKpi = {};
  kpiTitle: string;
  indicators: any[];
  evolutionGraph: any = [];
  percentageKpi: string;

  monthsNames: any;
  administrativeName: string = "";
  paymentZeroName: string = "";
  withdrawalName: string = "";
  fraudName: string = "";

  graphDifferenceInfo: any;
  graphInfo: any;
  indicatorsInfo: any;

  kpiInfoRaw: any;
  indicatorsInfoRaw: any = [];

  translationsMatch = [
    { name: 'Administrativo', translation: this.administrativeName },
    { name: 'Pago 0', translation: this.paymentZeroName },
    { name: 'Desistimiento', translation: this.withdrawalName },
    { name: 'Fraude', translation: this.fraudName }
  ]

  constructor(
    private translateService: TranslateService,
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private eventsService: EventsService,
    private utilsService: UtilsService,
    private events: EventsService,
    private rejectionsMainService: RejectionsMainService
  ) { }

  ngOnInit(): void {
    const filters$ = this.filtersService.filtersValues$;
    const selectedOption$ = this.rejectionsMainService.panelSelectedOption$;

    combineLatest([filters$, selectedOption$])
      .pipe(shareReplay(), takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.resetValues();
          this.getTranslations();
          this.filtersValues = data[0];
          this.loaded = true;
          if (this.filtersValues.length == 0) return;
          
          this.getSelectedOption(data[1]);
          this.retrieveRejectionsInfo("s", this.filtersValues);
        }
      )
  }

  resetValues() {
    this.rejectionKpi = {};
    this.evolutionGraph = [];
    this.percentageKpi = "";
    this.indicators = [];
  }

  getTranslations() {
    const translations = ['panels.technicalRejectionTax', 'abbreviatedMonthNames', 'panels.administrative', 'panels.paymentZero', 'panels.withdrawal', 'panels.fraud'];
    this.translateService.get(translations)
      .pipe(
        shareReplay(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: translation => {
          this.kpiTitle = translation['panels.technicalRejectionTax'];
          this.monthsNames = translation['abbreviatedMonthNames'];
          this.administrativeName = translation['panels.administrative'];
          this.paymentZeroName = translation['panels.paymentZero'];
          this.withdrawalName = translation['panels.withdrawal'];
          this.fraudName = translation['panels.fraud'];
          this.translationsMatch = [
            { name: 'Restricción técnica', translation: this.kpiTitle },
            { name: 'Administrativo', translation: this.administrativeName },
            { name: 'Pago 0', translation: this.paymentZeroName },
            { name: 'Desistimiento', translation: this.withdrawalName },
            { name: 'Fraude', translation: this.fraudName }
          ]
        }
      })
  }

  getSelectedOption(option: any = {}): void {
    if (option['id'] == "rejectionsAmount") this.type_data = 'a'
    if (option['id'] == "rejectionsSinisters") this.type_data = 's'
  }

  getSelectedReason(reason: any[] = []): void {
    this.rejection_reason = reason[0] != "-999" ? reason : [];
  }

  retrieveRejectionsInfo(type_data: string = "", filtersValues: any): void {
    this.loaded = false;

    const indicatorsInfoRequest = this.apimstService.getSinistersRejections(type_data, 'srm', filtersValues);
    const graphInfoRequest = this.apimstService.getSinistersRejections(type_data, 'srr', filtersValues);
    const graphDifferenceRequest = this.apimstService.getSinistersRejections(type_data, 'srt', filtersValues);

    this.subscriptionRequest = forkJoin([indicatorsInfoRequest, graphInfoRequest, graphDifferenceRequest])
      .subscribe({
        next: results => {
          this.indicatorsInfo = results[0];
          this.graphInfo = results[1];

          // this.graphInfo = [
          //   {
          //       "name": "No asignado",
          //       "value": "-0.11%"
          //   }, {
          //       "name": "Administrativo",
          //       "value": "-9.88%"
          //   }, {
          //       "name": "Restricci\u00f3n t\u00e9cnica",
          //       "value": "-2.82%"
          //   }, {
          //       "name": "Pago 0",
          //       "value": "-0.13%"
          //   }, {
          //       "name": "Desistimiento",
          //       "value": "5.10%"
          //   }, {
          //       "name": "Fraude",
          //       "value": "20.15%"
          //   }]
          

          this.graphDifferenceInfo = results[2];
          this.loaded = true;

          if (this.indicatorsInfo != null) {
            // this.handleKpiInfo(this.indicatorsInfo);
            this.handleIndicatorsInfo(this.indicatorsInfo);
          }

          if (this.graphInfo != null) {
            this.handleGraphInfo(this.graphInfo, this.graphDifferenceInfo);
          }
        },
        error: error => console.log('An error occurred while retrieving rejections info ' + error)
      })
  }

  handleKpiInfo(kpi: any = []): void {
    kpi = kpi ? kpi.filter(n => n.name == 'Restricción técnica')[0] : {};
    this.createKpi(kpi);
  }

  handleIndicatorsInfo(indicators: any = []): void {
    indicators = indicators ? indicators.filter(n => n.name === 'Restricción técnica' || n.name === 'Administrativo' || n.name === 'Pago 0') : []
    this.createIndicators(indicators);
  }

  handleGraphInfo(info: any = [], difference: any): void {
    const filteredInfo = info.filter(n => n.name === 'Administrativo' || n.name === 'Pago 0' || n.name === 'Restricción técnica' || n.name === 'Desistimiento' || n.name === 'Fraude');

    let graphValues = [];
    graphValues = filteredInfo.map(
      indicator => (
        {
          name: this.searchTranslation(indicator.name),
          value: indicator.value ? parseFloat(indicator.value) : 0
        })
    )

    if( filteredInfo.filter(value => value.name == "Fraude").length == 0 ) {
      graphValues.push({
        name: this.fraudName,
        value: 0
      })
    }

    const month = this.utilsService.padWithZeros(this.getFiltersMonth(), 2);
    graphValues.unshift({
      name: `${this.monthsNames[month]} ${this.getFiltersYear() - 1}`,
      value: difference[0].previous ? parseFloat(difference[0].previous) : 0
    }
    )
    graphValues.push({
      name: `${this.monthsNames[month]} ${this.getFiltersYear()}`,
      value: difference[0].value ? parseFloat(difference[0].value) : 0
    }
    )

    this.createGraph(graphValues);
    this.createGraphLabel(difference[0].diff);
  }

  createKpi(kpiInfo: any = {}): void {
    this.rejectionKpi.title = this.kpiTitle;
    this.rejectionKpi.mainValue = this.isValidValue(kpiInfo.value) ? String(parseFloat(kpiInfo.value)) : '';
    this.rejectionKpi.mainValueUnit = "%";
    this.rejectionKpi.trend = this.isValidValue(kpiInfo.diff_trend) ? parseFloat(kpiInfo.diff_trend) : 0;
    this.rejectionKpi.percentage = this.isValidValue(kpiInfo.diff) ? String(parseFloat(kpiInfo.diff)) : '';
  }

  createIndicators(indicatorsInfo: any = []): void {
    let indicatorsValues = [];
    indicatorsValues = indicatorsInfo
      .map(indicator => (
        {
          title: this.searchTranslation(indicator.name),
          mainValue: String(parseFloat(indicator.value)),
          mainValueUnit: "%",
          trend: parseInt(indicator.diff_trend),
          percentage: indicator.diff
        })
      );

    this.indicators = indicatorsValues;
  }

  createGraph(values: any = []): void {
    this.evolutionGraph = values;
  }

  createGraphLabel(label: string = ""): void {
    this.percentageKpi = label;
  }

  getFiltersMonth() {
    let filters: any = {};
    let monthNumber: number;
    filters = this.filtersValues.filter(f => f.name == 'month')[0];
    monthNumber = filters.value;

    return monthNumber;
  }

  getFiltersYear() {
    let filters: any = {};
    let year: number;
    filters = this.filtersValues.filter(f => f.name == 'year')[0];
    year = parseInt(filters.value);

    return year;
  }

  searchTranslation(text: string = "") {
    let trans: string = "";
    const filteredTranslation = this.translationsMatch
      .filter(t => t.name.trim().toLocaleLowerCase() == text.trim().toLocaleLowerCase())
    trans = filteredTranslation ? filteredTranslation[0].translation : trans;

    return trans;
  }

  isValidValue(value: any) {
    const valid = value != '' && typeof value != 'undefined' && value != null;
    return valid;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

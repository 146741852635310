import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RejectionsMainService {
  graphName: string = 'sinistersRejections';
  panelOptions: any[] = [
    { id: "rejectionsAmount", name: "panels.amount", selected: true, graphName: this.graphName },
    { id: "rejectionsSinisters", name: "panels.sinisters", selected: false, graphName: this.graphName }
  ];
  panelSelectedOption$ = new BehaviorSubject<any>(this.panelOptions[0]);

  constructor() {}

  // setGraphName() {
  //   this.graphName = 'sinistersRejections';
  // }

  getGraphName() {
    return this.graphName;
  }

  // setPanelOptions() {
  //   this.panelOptions = [
  //     { id: "rejectionsAmount", name: "panels.amount", selected: true, graphName: this.graphName },
  //     { id: "rejectionsSinisters", name: "panels.sinisters", selected: false, graphName: this.graphName }
  //   ];
  // }

  // getPanelOptions() {
  //   return this.panelOptions;
  // }

}

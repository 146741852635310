import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { trim } from 'lodash';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COLORS } from 'src/app/shared/globals/globals';
import { CancellationChargeActitityEntity } from 'src/app/shared/models/cancellation-charge-ativity-entity';
import { ExcelFileInfo } from 'src/app/shared/models/excel-file-info';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { lineChartSeries, barChart } from './data';

@Component({
  selector: 'app-clients-stock-graphic',
  templateUrl: './clients-stock-graphic.component.html',
  styleUrls: ['./clients-stock-graphic.component.scss']
})
export class ClientsStockGraphicComponent implements OnInit {
  view = [580, 200];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  legendTitle: string = '';
  legendPosition: string = '';
  showXAxisLabel: boolean = false;
  showYAxisLabel: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  yLeftAxisScaleFactor: string = '';
  yRightAxisScaleFactor: string = '';
  showGridLines: boolean = true;
  showRightGridLines: boolean = true;
  innerPadding: string = '10%';
  animations: boolean = false;
  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  tooltipDisabled: boolean = false;
  schemeType: string = "ordinal";
  roundDomains: boolean = true;
  noBarWhenZero: boolean = true;
  lineChartScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.lightPurple, COLORS.darkYellow, COLORS.lightBlue, '#08ded1']
  };
  comboBarScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.lightBlue, COLORS.lightBlue, '#085da6', '#0869aa']
  };
  customColors: any = [];
  roundEdges: boolean = false;
  activeEntries: any = [];
  trimXAxisTicks: boolean = true;
  trimYAxisTicks: boolean = true;
  rotateXAxisTicks: boolean = false;
  maxXAxisTickLength: number = 16;
  maxYAxisTickLength: number = 16;
  barPadding: number = 8;
  showPercentageSymbolInTooltip: boolean = false;

  // Variables for saving the filter option
  optionTabPolicy = 'panels.receipts';
  optionTabBonus = 'panels.amount';
  actualOption: string = 'panels.amount';
  graphName: string = 'chargeActivity';

  // Subscription
  unsubscribe$: Subject<any> = new Subject<any>();

  groupedVerticalGraph: any = [];
  horizontalGraph: any = [];
  loaded: boolean = false;

  policyVerticalGraphData: any = [];
  bonusVerticalGraphData: any = [];
  policyHorizontalGraphData: any = [];
  bonusHorizontalGraphData: any = [];

  monthNames: string;
  chargedName: string;
  sentToChargeName: string;
  taxName: string;
  targetName: string;

  highSubscriptionsName: string = "";
  unsubscriptionsName: string = "";
  reactivatedName: string = "";
  stockName: string = "";
  tenureName: string = "";
  abbreviatedMonthNames: any[];

  filtersValues: any;

  // Variable to export data
  data_total: any[] = [];
  data_sheet_names: any;
  data_filter_details: any;

  constructor(
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private eventsService: EventsService,
    private translateService: TranslateService,
    private excelService: ExcelService
  ) { }

  ngOnInit(): void {
    this.subscribeToFiltersValues();
  }

  subscribeToFiltersValues() {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data.length !== 0) {
            this.filtersValues = data;
            this.resetVariables();
            this.retrieveStockGraphData(data);
          }
        }
      );
  }

  getTranslations(): void {
    const highSubscriptionsTranslation = this.translateService.get('labels.highSubscriptions');
    const unsubscriptionsTranslation = this.translateService.get('labels.unsubscriptions');
    const reactivatedTranslation = this.translateService.get('labels.reactivatedMP');
    const stockTranslation = this.translateService.get('labels.stock');
    const tenureTranslation = this.translateService.get('labels.tenure');
    const abbreviatedMonthsTranslation = this.translateService.get('abbreviatedMonthNames');

    forkJoin([highSubscriptionsTranslation, unsubscriptionsTranslation, reactivatedTranslation, stockTranslation, tenureTranslation, abbreviatedMonthsTranslation])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        {
          next: results => {
            this.highSubscriptionsName = results[0];
            this.unsubscriptionsName = results[1];
            this.reactivatedName = results[2];
            this.stockName = results[3];
            this.tenureName = results[4];
            this.abbreviatedMonthNames = results[5];
          }
        }
      );
  }

  private retrieveStockGraphData(filtersValues: any) {
    this.loaded = false;

    const clientSubscribeEvolutionRequest = this.apimstService.getClientsStock("cse", filtersValues);
    const clientUnsubscribeEvolution = this.apimstService.getClientsStock("cue", filtersValues);
    const clientStockEvolution = this.apimstService.getClientsStock("cste", filtersValues);
    const requests = [clientSubscribeEvolutionRequest, clientUnsubscribeEvolution, clientStockEvolution];

    forkJoin(requests)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: data => {
          this.loaded = true;
          this.getTranslations();
          if (data == null || data[0] == null) return;
          this.data_filter_details = data[0][0].filters ? data[0][0].filters : [];
          this.createGraph(data);
          this.assignColorBar();
          this.getInfoForExcel();
        },
        error: error => console.log("An error ocurred while retrieving clients stock graph data: " + error)
      })
  }

  createGraph(data: any[] = []) {
    const clientSubscribeEvolution = data[0];
    const clientUnsubscribeEvolution = data[1];
    const clientStockEvolution = data[2];
    const barChartGraph = this.createBarChart(clientSubscribeEvolution, clientUnsubscribeEvolution);
    const lineChartGraph = this.createLineChart(clientStockEvolution);

    this.groupedVerticalGraph = barChartGraph;
    this.horizontalGraph = lineChartGraph;
  }

  createBarChart(subscribeData: any[] = [], unsubscribeData: any[] = []) {
    let combinedArray: any[] = []
    let tempArray: any[] = [];

    subscribeData.forEach((value, index) => {
      combinedArray.push({
        month: value.month,
        year: value.year,
        valueSubscribe: value.value,
        valueUnsubscribe: unsubscribeData[index].value,
        valueReactivated: value.reactivated
      })
    });

    for (let item of combinedArray) {
      tempArray.push({
        name:  `${this.abbreviatedMonthNames[item.month]}${item.year.slice(2, 4)}`,
        series: [
          {
            name: this.highSubscriptionsName,
            value: item.valueSubscribe ? parseFloat(item.valueSubscribe.replace(/,/g, '')) : 0
          },
          {
            name: this.unsubscriptionsName,
            value: item.valueUnsubscribe ? parseFloat(item.valueUnsubscribe.replace(/,/g, '')) : 0
          },
          {
            name: this.reactivatedName,
            value: item.valueReactivated ? parseFloat(item.valueReactivated.replace(/,/g, '')) : 0
          }
        ]
      })
    }

    return tempArray;
  }

  createLineChart(stockData: any[] = []) {
    let stockItem: any = { name: this.stockName, series: []};
    let tenureItem: any = { name: this.tenureName, series: []};

    for (let item of stockData) {
      stockItem.series.push({
        name: `${this.abbreviatedMonthNames[item.month]}${item.year.slice(2, 4)}`,
        value: item.value ? parseFloat(item.value.replace(/,/g, '')) : 0
      })
    }

    // for (let item of tenureData) {
    //   tenureItem.series.push({
    //     name: `${this.abbreviatedMonthNames[item.month]}${item.year.slice(2, 4)}`,
    //     value: item.value ? parseFloat(item.value.replace(/,/g, '')) : 0
    //   })
    // }

    const tempArray: any[] = [stockItem];
    return tempArray;
  }

  private retrieveGraphData(type_data: string, filtersValues: any) {
    this.loaded = false;
    this.apimstService.getCancellationCollectionRolling(type_data, filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const graphData: Array<CancellationChargeActitityEntity> = data;
          const verticalGraphData: any = [];
          const taxHorizontalGraphData: any = [];
          const targetHorizontalGraphData: any = [];
          const horizontalGraphData: any = []

          if (graphData.length !== 0) {
            this.data_filter_details = graphData[0].filters;
          }

          for (let group of graphData) {
            taxHorizontalGraphData.push(
              {
                name: this.monthNames[group.month] + ' ' + group.year.slice(2, 4),
                value: (group.rate || trim(group.rate).length > 0) ? parseFloat(group.rate.replace(/,/g, '')) : 0
              }
            );
            targetHorizontalGraphData.push(
              {
                name: this.monthNames[group.month] + ' ' + group.year.slice(2, 4),
                value: (group.target || trim(group.target).length > 0) ? parseFloat(group.target.replace(/,/g, '')) : 0
              }
            );

            verticalGraphData.push(
              {
                name: this.monthNames[group.month] + ' ' + group.year.slice(2, 4),
                series: [
                  {
                    name: this.sentToChargeName,
                    value: (group.issued || trim(group.issued).length > 0) ? parseFloat(group.issued.replace(/,/g, '')) : 0
                  },
                  {
                    name: this.chargedName,
                    value: (group.charged || trim(group.charged).length > 0) ? parseFloat(group.charged.replace(/,/g, '')) : 0
                  }
                ]
              }
            );
          }
          horizontalGraphData.push(
            {
              name: this.taxName,
              series: taxHorizontalGraphData
            },
            {
              name: this.targetName,
              series: targetHorizontalGraphData
            }
          );
          this.assignColorBar();


          // Bonus data (p -> premium)
          if (type_data === 'p') {
            this.bonusVerticalGraphData = verticalGraphData;
            this.bonusHorizontalGraphData = horizontalGraphData;

          } else if (type_data === 'c') { // Policy data (c -> certificate)
            this.policyVerticalGraphData = verticalGraphData;
            this.policyHorizontalGraphData = horizontalGraphData;

          }
          this.loaded = true;

          this.assignDataToPrint();

          this.getInfoForExcel();

        },
        error => console.log("An error ocurred while retrieving portfolio churn graph data: " + error)
      );
  }

  private assignDataToPrint() {
    if (this.actualOption === this.optionTabPolicy) {
      this.groupedVerticalGraph = this.policyVerticalGraphData;
      this.horizontalGraph = this.policyHorizontalGraphData;
    } else if (this.actualOption === this.optionTabBonus) {
      this.groupedVerticalGraph = this.bonusVerticalGraphData;
      this.horizontalGraph = this.bonusHorizontalGraphData;
    }
  }

  // Method to assign the colors to the graph bars
  private assignColorBar() {

    this.customColors.push(
      {
        name: this.highSubscriptionsName,
        value: COLORS.lightBlue
      },
      {
        name: this.unsubscriptionsName,
        value: COLORS.darkBlue
      },
      {
        name: this.reactivatedName,
        value: COLORS.darkYellow
      },
      {
        name: this.stockName,
        value: COLORS.lightestBlue
      },
      {
        name: this.tenureName,
        value: COLORS.lightPurple
      })
  }

  yRightAxisTickFormatting(val) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(val);
  }

  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value);
  }

  private resetVariables() {
    this.groupedVerticalGraph = [];
    this.horizontalGraph = [];
    this.bonusHorizontalGraphData = [];
    this.policyHorizontalGraphData = [];
    this.bonusVerticalGraphData = [];
    this.policyVerticalGraphData = [];
  }

  getInfoForExcel(): void {
    this.data_total = [];
    const title = this.translateService.get('panels.clientsStock');

    let titleLabel = '';

    forkJoin([title])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        titleLabel = result[0];
      });

    this.getDataToExport();
    this.data_sheet_names = [titleLabel];

    let excelInfo: ExcelFileInfo = { json: [this.data_total.flat()], headers: this.data_sheet_names, excelFileName: 'clients_stock_chart', filtersValues: this.data_filter_details, filters: true };
    this.eventsService.clientsStockExcelInfoLoaded$.next(excelInfo);
  }

  // Method to export the graph data to an Excel file
  exportAsXLSX() {
    this.getInfoForExcel();
    this.excelService.exportAsExcelFile([this.data_total.flat()], this.data_sheet_names, 'clients_stock_chart', this.data_filter_details);
  }

  // Method to retrieve the data to export
  private getDataToExport() {
    const headers = ['', this.groupedVerticalGraph.map(v => v.name)];
    const values: any[] = [];

    for (let _i = 0; _i < 3; _i++) {
      const array = [this.groupedVerticalGraph[0].series[_i].name];
      for (let item of this.groupedVerticalGraph) {
        array.push(item.series[_i].value);
      }
      values.push(array);
    }

    this.data_total.push([headers.flat()], values);

    const horizontalData = this.horizontalGraph.map(v => {
      return [v.name, v.series.map(i => i.value)].flat();
    });

    this.data_total.push(horizontalData);

  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<div class="dg-business-upper-menu d-flex align-items-center">
    <div class="color-stripe" id="color-stripe">

    </div>
    <!-- <app-hamburguer-menu></app-hamburguer-menu> -->
    <div class="title">
        <span>{{title}}</span>
    </div>
    <div class="menu-options">
        <app-search-option-tab [options]="options" [sectionName]="sectionName"></app-search-option-tab>
    </div>
</div>

<div class="big-button-grid big-button-grid-backoffice">

    <div class="big-button-grid-main-container">
        <div class="big-button-grid-top">
            <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
                <span class="title section-card-title-btn-grid">{{ 'panels.backoffice' | translate | uppercase }}</span>
            </div>
        </div>
    </div>

    <div class="big-button-grid-bg">
        <img src="assets/img/bg-backoffice.jpg">
    </div>

</div>
<component-loader [width]="" [height]="103" *ngIf="!loaded"></component-loader>
<p class="section-title mb-0" *ngIf="loaded">{{ 'panels.claimsPercentage' | translate }}</p>
<div class="velocimeter-container velocimeter-medium mt-2" *ngIf="loaded">
    <velocimeter
        [viewBox]="viewBox"
        [strokeWidth]="strokeWidth"
        [target]="target"
        [currentValue]="currentValue"
        [mainValue]="qualityClaim?.value"
        [secondValue]="qualityClaim?.target"
        [diff]="qualityClaim?.diff"
        [trend]="qualityClaim?.diff_trend"
        [hasInfo]="hasInfo"
        [hAlign]="hAlign"
        [vAlign]="vAlign"
        leftValue=""
        [barOrientation]="barOrientation"
        [showSecondValue]="showSecondValue">
    </velocimeter>
</div>

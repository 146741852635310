<base-loader *ngIf="!loaded"></base-loader>

<ng-container *ngIf="loaded">
  <header class="d-flex justify-content-between">
    <h5 class="h3 text-white mt-2 mb-0">{{ sectionTitle | translate }}</h5>
    <span class="h5 btn-close material-icons" (click)="closeDialog()">close</span>
  </header>
  <div class="tabs-container position-relative">
    <app-color-link text="labels.completeInfo" icon="open_in_new" color="primary" (click)="getMoreInfo()"></app-color-link>
    <app-tab-group [tabElements]="tabElements"></app-tab-group>
  </div>
</ng-container>

import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
  selector: 'app-small-kpi',
  templateUrl: './small-kpi.component.html',
  styleUrls: ['./small-kpi.component.scss']
})
export class SmallKpiComponent implements OnInit {

  @Input() title: string;
  @Input() mainValue;
  @Input() mainValueUnit: string;
  @Input() secondaryValue: string;
  @Input() percentage: string;
  @Input() percentagePrevious: string;
  @Input() trend: number;
  @Input() hasPercentage: boolean = true;
  @Input() addMagnitudeOrder: boolean = true;

  absPercentageValue: number;
  percentageValue: number;
  trendValue: number;
  trendDirection: number = 1;

  constructor(
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.checkKpi();
  }

  ngOnChanges() {
    this.checkKpi();
  }

  checkKpi() {

    // Check positive values
    if( this.utilsService.isNegative(this.percentage) == false ) {
      this.trendDirection = 1;
    } else {
      this.trendDirection = 0;
    }

    // Check trend value
    this.percentageValue = this.percentage ? parseFloat(this.percentage.replace(/,/g, '')) : 0;
    this.absPercentageValue = Math.abs(this.percentageValue);
    if (typeof this.trend === 'string') {
      this.trendValue = Number(this.trend);
    } else {
      this.trendValue = this.trend;
    }
  }

}

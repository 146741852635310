<app-wrapper>

    <div class="wrapper_grid">
        <div class="clients-voc-claims">
            <app-clients-voc-claims></app-clients-voc-claims>
        </div>
        <div class="clients-voc-nps">
            <app-clients-voc-nps></app-clients-voc-nps>
        </div>        
    </div>

</app-wrapper>
<div class="section-title mb-0 justify-content-between">
  <span class="section-title--name">{{ "panels.saleVsPlan" | translate }} {{ selectedBudgetFilterText }}</span>
  <label class="main-checkbox d-block small"
    >{{ "%" | translate }}
    <input
      #percentageCheckbox
      type="checkbox"
      name="percentage"
      checked="checked"
      (click)="checkboxSelected()"
    />
    <span class="checkmark"></span>
  </label>
</div>

<component-loader *ngIf="!loaded" [width]="" [height]="50"></component-loader>

<div *ngIf="loaded" class="sales-projection-chart thermometer-gen">
  <linear-gauge-graph
    class="linearGaugeGraph"
    [view]="view"
    [scheme]="colorScheme"
    [value]="progress"
    [min]="min"
    [max]="max"
    [center]="value"
    [units]="units"
  >
  </linear-gauge-graph>
</div>

import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { trim } from "lodash";
import { forkJoin, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ApimstService } from "src/app/shared/services/apimst.service";
import { EventsService } from "src/app/shared/services/events/events.service";
import { FiltersService } from "src/app/shared/services/filters/filters.service";
import { MagnitudeOrderPipe } from "src/app/shared/pipes/magnitude-order.pipe";

@Component({
  selector: "app-sales-vs-plan-projection-graph",
  templateUrl: "./sales-vs-plan-projection-graph.component.html",
  styleUrls: ["./sales-vs-plan-projection-graph.component.scss"],
})
export class SalesVsPlanProjectionGraph implements OnInit, OnDestroy {
  // Subscription
  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();

  loaded = false;

  filtersValues: any;

  actualOption: string = "panels.bonus";
  optionTabPolicy = "panels.policy";
  optionTabBonus = "panels.bonus";
  graphName = "salesDetails";

  // Number Format pipe
  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  numbers: any;

  // Graph Data
  policyValue = null;
  policyMin = null;
  policyMax = null;
  policyProgress = null;
  bonusValue = null;
  bonusMin = null;
  bonusMax = null;
  bonusProgress = null;

  percentageCheckboxValue = true;

  // Options for chart creation
  view: any[] = [600, 50];
  colorScheme = {
    domain: ["white", "green"],
  };
  value: number = 1366485;
  min: number = 0;
  max: number = 1600000;
  progress: number = 0;
  units = "";

  selectedBudgetFilterText: string;

  @ViewChild("percentageCheckbox") percentageCheckbox: ElementRef;

  // Default constructor
  constructor(
    private apimstService: ApimstService,
    private eventsService: EventsService,
    private filtersService: FiltersService
  ) {}

  // OnInit
  ngOnInit(): void {
    this.subscription = this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.units = "";
        if (data.length !== 0) {
          this.filtersValues = data;
          this.resetVariables();
          if (this.actualOption == this.optionTabBonus)
            this.retrieveGraphData("p", data); // (p -> premium)
          else if (this.actualOption == this.optionTabPolicy)
            this.retrieveGraphData("c", data);
          const selectedBudgetFilter = data.filter(v => v.name === 'budgets');
          if (selectedBudgetFilter !== null && selectedBudgetFilter !== undefined) {
            this.selectedBudgetFilterText = selectedBudgetFilter[0]?.valueItems[0]?.item_text;
          }
        }
      });

    this.eventsService.policiesBonusOption$.subscribe((data) => {
      const option = data[0];
      const name = data[1];
      if (
        (option === this.optionTabBonus || option === this.optionTabPolicy) &&
        data != this.actualOption &&
        name === this.graphName
      ) {
        this.actualOption = option;
        if (this.actualOption == this.optionTabBonus) {
          if (!this.bonusValue) {
            this.retrieveGraphData("p", this.filtersValues); // (c -> certificate)
          } else {
            this.assignDataToPrint();
          }
        } else if (this.actualOption == this.optionTabPolicy) {
          if (!this.policyValue) {
            this.retrieveGraphData("c", this.filtersValues); // (c -> certificate)
          } else {
            this.assignDataToPrint();
          }
        }
      }
    });
  }

  // Method for retrieving the data information for the monthly graph
  private retrieveGraphData(typeData: string, filters: any) {
    this.loaded = false;

    const plan = this.apimstService.getSalesBudget(typeData, "b", filters);
    const progress = this.apimstService.getSalesBudget(typeData, "bp", filters);

    this.subscription = forkJoin([plan, progress]).subscribe(
      (results) => {
        const planData: any = results[0];
        const progressData: any = results[1];

        // Bonus data (p -> premium)
        if (typeData === "p") {
          this.bonusMax =
            planData.max || trim(planData.max).length > 0
              ? parseFloat(planData.max.replace(/,/g, ''))
              : 0;
          this.bonusMin =
            planData.min || trim(planData.min).length > 0
              ? parseFloat(planData.min.replace(/,/g, ''))
              : 0;
          this.bonusValue =
            planData.value || trim(planData.value).length > 0
              ? parseFloat(planData.value.replace(/,/g, ''))
              : 0;
          this.bonusProgress =
            progressData.value || trim(progressData.value).length > 0
              ? parseFloat(progressData.value.replace(/,/g, ''))
              : 0;
          if (this.bonusProgress > this.bonusMax)
            this.bonusMax = this.bonusProgress;
          this.loaded = true;
        } else if (typeData === "c") {
          // Policy data (c -> certificate)
          this.policyMax =
            planData.max || trim(planData.max).length > 0
              ? parseFloat(planData.max.replace(/,/g, ''))
              : 0;
          this.policyMin =
            planData.min || trim(planData.min).length > 0
              ? parseFloat(planData.min.replace(/,/g, ''))
              : 0;
          this.policyValue =
            planData.value || trim(planData.value).length > 0
              ? parseFloat(planData.value.replace(/,/g, ''))
              : 0;
          this.policyProgress =
            progressData.value || trim(progressData.value).length > 0
              ? parseFloat(progressData.value.replace(/,/g, ''))
              : 0;
          if (this.policyProgress > this.policyMax)
            this.policyMax = this.policyProgress;
          this.loaded = true;
        }
        this.assignDataToPrint();
      },
      (error) =>
        console.log("An error ocurred while retrieving graph data: " + error)
    );
  }

  // Method to assign the data to print
  private assignDataToPrint() {
    if (this.actualOption === this.optionTabPolicy) {
      if (this.percentageCheckboxValue) {
        this.max = Math.round((this.policyMax * 100) / this.policyValue);
        this.min = 0;
        this.value = 100;
        this.progress = (this.policyProgress * 100) / this.policyValue;
        this.units = "%";
        return;
      }
      this.max = this.policyMax;
      this.min = this.policyMin;
      this.value = this.policyValue;
      this.progress = this.policyProgress;
      this.units = "";
    } else if (this.actualOption === this.optionTabBonus) {
      if (this.percentageCheckboxValue) {
        this.max = Math.round((this.bonusMax * 100) / this.bonusValue);
        this.min = 0;
        this.value = 100;
        this.progress = (this.bonusProgress * 100) / this.bonusValue;
        this.units = "%";
        return;
      }
      this.max = this.bonusMax;
      this.min = this.bonusMin;
      this.value = this.bonusValue;
      this.progress = this.bonusProgress;
    }
  }

  // Method to reset the data storage variables
  private resetVariables() {
    this.policyValue = null;
    this.policyMin = null;
    this.policyMax = null;
    this.policyProgress = null;
    this.bonusValue = null;
    this.bonusMin = null;
    this.bonusMax = null;
    this.bonusProgress = null;
  }

  checkboxSelected() {
    this.units = "";
    this.percentageCheckboxValue =
      this.percentageCheckbox.nativeElement.checked;

    this.assignDataToPrint();
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

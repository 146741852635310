import { Component, Inject, OnInit } from '@angular/core';
import { pendingAgeQuantity } from 'src/app/shared/models/pending-age-quantity';
import { COLORS } from 'src/app/shared/globals/globals';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { RejectionsPendingAgeService } from '../rejections-pending-age.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-pending-age-block',
  templateUrl: './pending-age-block.component.html',
  styleUrls: ['./pending-age-block.component.scss']
})
export class PendingAgeBlockComponent implements OnInit {

  amountInfoCopy: any = {};
  sinistersInfoCopy: any = {};

  selectedOption = "amount";
  graphName: string = 'sinistersPendingAge';
  selectedGraph = "sinistersPendingAge";

  dropdownselectedOption = "1";

  type_data: string;
  type_report: string;
  type_reason: any[] = [];
  type_reason_detail: any[] = [];

  unsubscribe$: Subject<any> = new Subject<any>();

  pendingSinisters: any;
  pendingSinistersPercentage: any;

  kpiInfoLoaded: boolean = false;
  kpisInfoLoaded: boolean = false;
  treeInfoLoaded: boolean = false;
  treeValidData: boolean = false;

  kpiInfo: pendingAgeQuantity = {};
  kpisInfoA: pendingAgeQuantity = {};
  kpisInfoM: pendingAgeQuantity = {};

  previousYearLabel: string = "labels.py";
  previousMonthLabel: string = "labels.pm";

  filtersValues: any;

  tooltipValueSuffix: string = "%";

  // Graph options
  view: any[] = [340, 225];
  colorScheme = {
    domain: [COLORS.darkestGreen, COLORS.darkGreen, COLORS.lightGreen, COLORS.lightBlue, COLORS.lightestBlue]
  };
  gradient: boolean = false;
  animations: boolean = true;

  pendingAgeTreeInfo: any[] = [];
  legendsTreeMap: any[];

  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  constructor(
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    @Inject(DOCUMENT) private document: Document,
    private rejectionsPendingAgeService: RejectionsPendingAgeService
  ) { }

  ngOnInit(): void {

    this.kpiInfoLoaded = false;
    this.kpisInfoLoaded = false;
    this.treeInfoLoaded = false;

    const filtersService$ = this.filtersService.filtersValues$;
    const optionTabSelection$ = this.rejectionsPendingAgeService.panelSelectedOption$;
    const dropdownSelection$ = this.rejectionsPendingAgeService.dropdownSelectedOption$;

    combineLatest([filtersService$, optionTabSelection$, dropdownSelection$])
      .pipe(shareReplay(), takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.filtersValues = data[0];
          const optionTabSelected: any = data[1];
          this.dropdownselectedOption = data[2];

          if (this.filtersValues.length === 0) return;

          this.selectedOption = optionTabSelected.id;
          const graphName = optionTabSelected.graphName;

          if (this.graphName !== graphName) return;

          this.retrieveTimeProcesses(
            this.getSelectedOption(),
            this.getDropdownSelectedOption()['type_report'],
            this.getDropdownSelectedOption()['type_reason'],
            this.getDropdownSelectedOption()['type_reason_detail'],
            this.filtersValues
          );
          setTimeout(() => { this.setCellBorderRadius() }, 100);
        }
      );
  }

  ngOnChanges(): void {
    setTimeout(() => { this.setCellBorderRadius() }, 100);
  }

  setCellBorderRadius(): void {
    let cells = this.document.querySelectorAll('.cell');
    [].forEach.call(cells, function (cell) {
      cell.setAttribute("rx", '8');
    });
  }

  createCopy(copy: any): void {
    this.createKpi(copy.sinistersInfo);
    this.createIndicator(copy.sinistersInfo);
    this.createGraphic(copy.sinistersPercentageInfo);
    this.createLegends(copy.sinistersPercentageInfo);
  }

  getSelectedOption() {
    if (this.selectedOption == "amount") this.type_data = 'a';
    if (this.selectedOption == "sinisters") this.type_data = 's';
    return this.type_data;
  }

  getDropdownSelectedOption() {
    if (this.dropdownselectedOption == "1") { this.type_reason = ['4', '5', '6']; this.type_reason_detail = []; this.type_report = 'spapen' };
    if (this.dropdownselectedOption == "2") { this.type_reason = []; this.type_reason_detail = ['6']; this.type_report = 'spa' };
    if (this.dropdownselectedOption == "3") { this.type_reason = []; this.type_reason_detail = ['10']; this.type_report = 'spa' };
    if (this.dropdownselectedOption == "4") { this.type_reason = []; this.type_reason_detail = ['7']; this.type_report = 'spa' };
    return { type_reason: this.type_reason, type_reason_detail: this.type_reason_detail, type_report: this.type_report };
  }

  retrieveTimeProcesses(type_data: string, type_report: string, sinister_substate: any[], sinister_substate_detail: any[], filtersValues: any): void {
    this.onLoading();

    const typeReportPercentage = type_report == "spapen" ? "spappen" : "spap";

    const pendingSinisters = this.apimstService.getSinistersPendingAge(type_data, type_report, sinister_substate, sinister_substate_detail, filtersValues);
    const pendingSinistersPercentage = this.apimstService.getSinistersPendingAge(type_data, typeReportPercentage, sinister_substate, sinister_substate_detail, filtersValues);

    forkJoin([pendingSinisters, pendingSinistersPercentage])
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(shareReplay(1))
      .subscribe({
        next: results => {
          this.pendingSinisters = results[0];
          this.pendingSinistersPercentage = results[1];

          if (this.pendingSinisters != null) {
            this.createKpi(this.pendingSinisters[0]);
            this.createIndicator(this.pendingSinisters[0]);
          }
          if (this.pendingSinistersPercentage != null) {
            this.createGraphic(this.pendingSinistersPercentage);
            this.createLegends(this.pendingSinistersPercentage);
            this.treeValidData = true;
          } else {
            this.treeValidData = false;
          }

          this.kpiInfoLoaded = true;
          this.kpisInfoLoaded = true;
          this.treeInfoLoaded = true;

          setTimeout(() => { this.setCellBorderRadius() }, 100);
        }
      })

  }

  onLoading() {
    this.kpiInfoLoaded = false;
    this.kpisInfoLoaded = false;
    this.treeInfoLoaded = false;
  }

  joinParams() {
    let params = _.cloneDeep(this.filtersValues);
    let paramsNew = {
      name: "type_data",
      value: this.getSelectedOption(),
      valueItems: []
    }
    params.push(paramsNew);
    return params;
  }

  createElements() {
    this.createKpi(this.pendingSinisters[0]);
    this.createIndicator(this.pendingSinisters[0]);
    this.kpiInfoLoaded = true;
    this.kpisInfoLoaded = true;

    this.createGraphic(this.pendingSinistersPercentage.details);
    this.createLegends(this.pendingSinistersPercentage.details);
    this.treeInfoLoaded = true;
  }

  createKpi(kpiInfo: any = {}): void {
    this.kpiInfo.quantity = kpiInfo.value ? String(parseFloat(kpiInfo.value.replace(/-|,/g, ''))) : '0';
    this.kpiInfo.symbol = "";
    this.kpiInfo.percentage = "";
    this.kpiInfo.trend = 1;
  }

  createIndicator(kpiInfo: any = {}): void {
    this.kpisInfoA.quantity = "";
    this.kpisInfoA.second_quantity = "";
    this.kpisInfoA.symbol = "";
    this.kpisInfoA.percentage_previous = this.previousYearLabel;
    this.kpisInfoA.percentage = kpiInfo.year_diff;
    this.kpisInfoA.trend = parseInt(kpiInfo.year_diff_trend);

    this.kpisInfoM.quantity = "";
    this.kpisInfoM.second_quantity = "";
    this.kpisInfoM.symbol = "";
    this.kpisInfoM.percentage_previous = this.previousMonthLabel;
    this.kpisInfoM.percentage = kpiInfo.month_diff;
    this.kpisInfoM.trend = parseInt(kpiInfo.month_diff_trend);
  }

  createGraphic(graphInfo: any = []): void {
    let graphValues = [];

    graphValues = graphInfo
      .map(indicator => ({ name: indicator.name, value: parseFloat(indicator.value) }));

    this.pendingAgeTreeInfo = graphValues.length > 0 ? graphValues : [{ name: '-', value: 100 }];
  }

  createLegends(graphInfo: any = {}): void {
    let legendsTreeMap = [];

    for (const [i, v] of graphInfo.entries()) {
      legendsTreeMap.push({
        name: v.name,
        color_code: this.colorScheme.domain[i],
        type: 'round',
        gradient: 'h-gradient'
      })
    }

    this.legendsTreeMap = legendsTreeMap;
  }

  labelFormatting(c) {
    return `${(c.label)}`;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

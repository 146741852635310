import { Component, OnInit } from '@angular/core';
import { conforms } from 'lodash';
import { EventsService } from '../../services/events/events.service';
import { LoginService } from '../../services/login/login.service';

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss']
})
export class UserSelectorComponent implements OnInit {

  toggleMenu: boolean = false;
  username: string = "";

  constructor(
    private loginService: LoginService,
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    this.eventsService.userInfoLoaded$
      .subscribe(
        data => {
          this.username = data['username'];

          if(this.username != undefined) {
            this.getInitials( this.username );
          }
        }
      )
  }

  openUserMenu(): void {
    this.toggleMenu = !this.toggleMenu;
  }

  openLogoutModal(): void {
    this.loginService.openLogoutModal();
  }

  getInitials( name: string ): void {
    let separatedByPointName: any = [];
    let entireName: string = "";

    if(name.includes('.')) {
      name.split('.');
      separatedByPointName = name.split('.');
      for(name of separatedByPointName) {
        entireName = entireName + name.substring(0, 1);
      }
    } else {
      entireName = name.substring(0, 2);
    }
    
    this.username = entireName;
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { OrganizationSandwich } from 'src/app/shared/models/organization-sandwich';

@Component({
  selector: 'app-organization-sandwich',
  templateUrl: './organization-sandwich.component.html',
  styleUrls: ['./organization-sandwich.component.scss']
})
export class OrganizationSandwichComponent implements OnInit {

  @Input() items: OrganizationSandwich;
  emptyValue: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}

import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  activeLanguage = 'es';
  private language = new BehaviorSubject<string>(this.activeLanguage);
  public language$ = this.language.asObservable();

  constructor(
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  changeLanguage(language: string) {
      this.language.next(language);
      this.translateService.use(language);
      this.document.documentElement.lang = language;
  }

  getLang(): Observable<any> {
      return this.language$;
  }

  getLangValue() {
    return this.language.value;
}

}

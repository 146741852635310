<div class="submenu-container" *ngIf="menuOn">
  <ul class="submenu-container-list list-unstyled pt-4 pr-4 pb-0 pl-4 mb-0">

    <!-- First level -->
    <div *ngFor="let item of navigationMenu; let i=index">
      <li class="submenu-item text-white h5 m-0 py-2 d-flex flex-column" *ngIf="item.active">
        
        <!-- With direct link -->
        <span class="submenu-main-section d-flex w-100" *ngIf="item.link != ''" [routerLink]="item.link" (click)="toggle(item, navigationMenu); resetMenu()">
          <span class="menu-flag {{item.flag}}"></span>
          <span class="submenu-section-name">{{ item.name | translate }}</span>
        </span>

        <!-- With no direct link (has children) -->
        <span class="submenu-main-section d-flex w-100" *ngIf="item.link == ''" (click)="toggle(item, navigationMenu);">
          <span class="menu-flag {{item.flag}}"></span>
          <span class="submenu-section-name">{{ item.name | translate }}</span>
        </span>

        <!-- Second level -->
        <div *ngIf="item.show">
          <ul class="list-unstyled ml-2 mt-2 w-100 mt-3" *ngIf="item?.children?.length > 0">
            <div *ngFor="let child of item.children">
              <li class="submenu-item text-white h6 d-flex" *ngIf="child.active" [routerLink]="child.link" (click)="resetMenu()">
                <span class="submenu-childsection-name">{{ child.name | translate }}</span>
              </li>
            </div>
          </ul>
        </div>
      </li>
    </div>

  </ul>
  <ul class="submenu-container-list list-unstyled pt-0 pr-4 pb-4 pl-4 pt-0">
    <li class="submenu-item text-white h5 m-0 py-2 d-flex flex-column">
      <span class="submenu-main-section d-flex w-100 align-items-start login-icon" (click)="openLogoutModal()">
          <span class="material-icons ml-n2 mr-2 d-inline-block icon">login</span>
          <span class="submenu-section-name">
            {{ 'menu.logout' | translate }}
            <span class="submenu-section-name d-block small text-light" *ngIf="username">({{ username }})</span>
          </span>
      </span>
    </li>      
  </ul>


</div>

<div class="subheader-background" *ngIf="menuOn" (click)="hideMenu()"></div>

import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ContentChild,
    TemplateRef
  } from '@angular/core';
  import { scaleBand, scaleLinear } from 'd3-scale';
  import { trigger, style, animate, transition } from '@angular/animations';
  import { calculateViewDimensions, ViewDimensions, ColorHelper, BaseChartComponent, DataItem } from '@swimlane/ngx-charts';

  @Component({
    selector: 'app-wok-graph',
  templateUrl: './wok-graph.component.html',
  styleUrls: ['./wok-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition(':leave', [
        style({
          opacity: 1
        }),
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
  })
  export class WokGraphComponent extends BaseChartComponent {
    @Input() legend = false;
    @Input() legendTitle: string = 'Legend';
    @Input() legendPosition: string = 'right';
    @Input() xAxis;
    @Input() yAxis;
    @Input() showXAxisLabel;
    @Input() showYAxisLabel;
    @Input() xAxisLabel;
    @Input() yAxisLabel;
    @Input() tooltipDisabled: boolean = false;
    @Input() gradient: boolean;
    @Input() showGridLines: boolean = true;
    @Input() activeEntries: any[] = [];
    @Input() schemeType: string;
    @Input() trimXAxisTicks: boolean = true;
    @Input() trimYAxisTicks: boolean = true;
    @Input() rotateXAxisTicks;
    @Input() maxXAxisTickLength: number = 8;
    @Input() maxYAxisTickLength: number = 16;
    @Input() xAxisTickFormatting: any;
    @Input() yAxisTickFormatting: any;
    @Input() xAxisTicks: any[];
    @Input() yAxisTicks: any[];
    @Input() barPadding = 8;
    @Input() roundDomains: boolean = false;
    @Input() roundEdges: boolean = false;
    @Input() yScaleMax: number;
    @Input() yScaleMin: number;
    @Input() showDataLabel: boolean = false;
    @Input() dataLabelFormatting: any;
    @Input() noBarWhenZero: boolean = false;
    @Input() labelDiff: string;
    @Input() viewBox: any;
    @Input() textLabel: string;
    @Input() dataLabelUnit: string;
  
    @Output() activate: EventEmitter<any> = new EventEmitter();
    @Output() deactivate: EventEmitter<any> = new EventEmitter();
  
    @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;
  
    dims: ViewDimensions;
    xScale: any;
    yScale: any;
    xDomain: any;
    yDomain: any;
    transform: string;
    colors: ColorHelper;
    margin: any[] = [10, 20, 10, 20];
    xAxisHeight: number = 0;
    yAxisWidth: number = 0;
    legendOptions: any;
    dataLabelMaxHeight: any = { negative: 0, positive: 0 };
    barWidthForUpperLabel: number;
  
    update(): void {
      super.update();
  
      if (!this.showDataLabel) {
        this.dataLabelMaxHeight = { negative: 0, positive: 0 };
      }
      this.margin = [10 + this.dataLabelMaxHeight.positive, 20, 10 + this.dataLabelMaxHeight.negative, 20];
  
      this.dims = calculateViewDimensions({
        width: this.width,
        height: this.height,
        margins: this.margin,
        showXAxis: this.xAxis,
        showYAxis: this.yAxis,
        xAxisHeight: this.xAxisHeight,
        yAxisWidth: this.yAxisWidth,
        showXLabel: this.showXAxisLabel,
        showYLabel: this.showYAxisLabel,
        showLegend: this.legend,
        legendType: this.schemeType,
        legendPosition: this.legendPosition
      });
  
      this.formatDates();
  
      if (this.showDataLabel) {
        this.dims.height -= this.dataLabelMaxHeight.negative;
      }
      this.xScale = this.getXScale();
      this.yScale = this.getYScale();
  
      this.setColors();
      this.legendOptions = this.getLegendOptions();
  
      this.transform = `translate(${this.dims.xOffset} , ${this.margin[0] + this.dataLabelMaxHeight.negative})`;
    }
  
    getXScale(): any {
      this.xDomain = this.getXDomain();
      const spacing = this.xDomain.length / (this.dims.width / this.barPadding + 1);
      return scaleBand().range([0, this.dims.width]).paddingInner(spacing).domain(this.xDomain);
    }
  
    getYScale(): any {
      this.yDomain = this.getYDomain();
      const scale = scaleLinear().range([this.dims.height, 0]).domain(this.yDomain);
      return this.roundDomains ? scale.nice() : scale;
    }
  
    getXDomain(): any[] {
      return this.results.map(d => d.label);
    }
  
    getYDomain(): [number, number] {
      const domain = [];
      let smallestSum = 0;
      let biggestSum = 0;
      for (let _i = 0; _i < this.results.length-1; _i++) {
        if (this.results[_i].value < 0) {
          smallestSum += this.results[_i].value;
        } else {
          biggestSum += this.results[_i].value;
        }
      }

      domain.push(smallestSum);
      domain.push(biggestSum);
  
      let min = this.yScaleMin ? Math.min(this.yScaleMin, ...domain) : Math.min(0, ...domain);
      if (this.yAxisTicks && !this.yAxisTicks.some(isNaN)) {
        min = Math.min(min, ...this.yAxisTicks);
      }
  
      let max = this.yScaleMax ? Math.max(this.yScaleMax, ...domain) : Math.max(0, ...domain);
      if (this.yAxisTicks && !this.yAxisTicks.some(isNaN)) {
        max = Math.max(max, ...this.yAxisTicks);
      }
      return [min, max];
    }
  
    onClick(data: DataItem) {
      this.select.emit(data);
    }
  
    setColors(): void {
      let domain;
      if (this.schemeType === 'ordinal') {
        domain = this.xDomain;
      } else {
        domain = this.yDomain;
      }
  
      this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
    }
  
    getLegendOptions() {
      const opts = {
        scaleType: this.schemeType,
        colors: undefined,
        domain: [],
        title: undefined,
        position: this.legendPosition
      };
      if (opts.scaleType === 'ordinal') {
        opts.domain = this.xDomain;
        opts.colors = this.colors;
        opts.title = this.legendTitle;
      } else {
        opts.domain = this.yDomain;
        opts.colors = this.colors.scale;
      }
      return opts;
    }
  
    updateYAxisWidth({ width }): void {
      this.yAxisWidth = width;
      this.update();
    }
  
    updateXAxisHeight({ height }): void {
      this.xAxisHeight = height;
      this.update();
    }
  
    onDataLabelMaxHeightChanged(event) {
      if (event.size.negative) {
        this.dataLabelMaxHeight.negative = Math.max(this.dataLabelMaxHeight.negative, event.size.height);
      } else {
        this.dataLabelMaxHeight.positive = Math.max(this.dataLabelMaxHeight.positive, event.size.height);
      }
      if (event.index === this.results.length - 1) {
        setTimeout(() => this.update());
      }
    }
  
    onActivate(item, fromLegend = false) {
      item = this.results.find(d => {
        if (fromLegend) {
          return d.label === item.name;
        } else {
          return d.name === item.name;
        }
      });
  
      const idx = this.activeEntries.findIndex(d => {
        return d.name === item.name && d.value === item.value && d.series === item.series;
      });
      if (idx > -1) {
        return;
      }
  
      this.activeEntries = [item, ...this.activeEntries];
      this.activate.emit({ value: item, entries: this.activeEntries });
    }
  
    onDeactivate(item, fromLegend = false) {
      item = this.results.find(d => {
        if (fromLegend) {
          return d.label === item.name;
        } else {
          return d.name === item.name;
        }
      });
  
      const idx = this.activeEntries.findIndex(d => {
        return d.name === item.name && d.value === item.value && d.series === item.series;
      });
  
      this.activeEntries.splice(idx, 1);
      this.activeEntries = [...this.activeEntries];
  
      this.deactivate.emit({ value: item, entries: this.activeEntries });
    }

    barWidth(value: number) {
      this.barWidthForUpperLabel = value;
    }
  }
  
<div class="organization-container">
    <div class="top d-flex">
        <div 
            class="column column-top text-center" 
            *ngFor="let topItem of items.top"
            [ngStyle]="{'width.%': topItem.column_width}"
            >
                <div class="column-inner">
                    <app-small-kpi 
                        class="indicator-widget-element d-inline-block" 
                        [title]=topItem.title 
                        [mainValue]=emptyValue
                        [secondaryValue]=topItem.quantity 
                        [trend]=topItem.trend_direction 
                        [percentagePrevious]=topItem.percentage_previous 
                        [percentage]=topItem.percentage 
                    ></app-small-kpi>
                    <div class="vertical-line vertical-line-top"><div class="v-line"></div></div>
                </div>
        </div>
    </div>

    <div class="middle d-flex">
        <div 
            class="column column-middle text-center" 
            [ngStyle]="{'width.%': middleItem.column_width}"
            *ngFor="let middleItem of items.middle"
            >
                <div class="column-inner" [ngClass]="'justify-content-' + middleItem.align">
                    <div class="image-block">
                        <img [src]=middleItem.icon />
                        <span class="icon-title d-block text-light small">{{ middleItem.title }}</span>
                    </div>
                </div>
        </div>
    </div>

    <div class="bottom d-flex">
        <div 
            class="column column-top text-center" 
            *ngFor="let bottomItem of items.bottom"
            [ngStyle]="{'width.%': bottomItem.column_width}"
            >
                <div class="column-inner">
                    <div class="vertical-line vertical-line-bottom"><div class="v-line"></div></div>
                    <app-small-kpi 
                        class="indicator-widget-element d-inline-block" 
                        [title]=bottomItem.title 
                        [mainValue]=emptyValue
                        [secondaryValue]=bottomItem.quantity 
                        [trend]=bottomItem.trend_direction
                        [percentagePrevious]=bottomItem.percentage_previous 
                        [percentage]=bottomItem.percentage 
                    ></app-small-kpi>
                </div>
        </div>
    </div>    


</div>
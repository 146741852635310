<div class="velocimeter-row d-flex justify-content-{{hAlign}} align-items-{{vAlign}}">
  <div class="velocimeter-container position-relative">
  
    <!-- Velocimeter -->
    <svg [attr.viewBox]="viewBox" class="velocimeter">
      <g fill-opacity="0" [attr.stroke-width]="strokeWidth">
        
        <!-- Main path -->
        <path
          class="first" 
          d="M5 50a45 45 0 1 1 90 0"
        />
    
        <!-- Progress path -->
        <path 
          d="M5 50a45 45 0 1 1 90 0" 
          stroke-dasharray="142" 
          stroke-dashoffset="142"
          [ngClass]="{'progress-green': trend === '1', 'progress-red': trend === '0'}"
          [ngStyle]="{'stroke-dashoffset': target}"
        />
    
        <!-- Marker path -->
        <path 
          class="marker" 
          d="M5 50a45 45 0 1 1 90 0" 
          stroke-dasharray="142" 
          stroke-dashoffset="142"
        />
    
        <!-- Velocimeter line -->
        <line 
          class="velocimeter-line" 
          x1="5%" 
          y1="100%" 
          x2="50%" 
          y2="100%" 
          marker-start="url(#marker)"
          
          [ngStyle]="{'transform': 'rotate(' + currentValue + 'deg)'}"

          
          />
      </g>
    
      <defs>
        <marker id="marker" class="marker" markerWidth="20" markerHeight="20" refX="6" refY="6" markerUnits="userSpaceOnUse">
                <rect x="0" y="5" width="13" height="3" rx="2" ry="2" fill="white" stroke="5" />
        </marker>
      </defs>
    
    </svg>
  
    <!-- Center data -->
    <div class="velocimeter-data-container w-100 text-center">
      <span class="main-percentage text-white h3">{{ mainValue | decimalThousandFormat}}%</span>
      <span class="percentage-separator text-light h4" *ngIf="showSecondValue"> / </span>
      <span class="sub-percentage text-light h4" *ngIf="showSecondValue">{{ secondValue | decimalThousandFormat}}%</span>
      <div class="mt-n2">
        <app-small-kpi [mainValue]="leftValue" [percentage]="diff" [trend]="trend" class="kpi"></app-small-kpi>
      </div>
    </div>
  
  </div>
  
  <div *ngIf="hasInfo" class="velocimeter-info pl-3 text-center">
    <span class="info-title text-white">{{infoTitle | translate}}</span>
    <div class="d-block">
      <span class="info-percentage text-white h3 d-inline-block mr-1">{{infoPercentage | decimalThousandFormat}}%</span>
      <span class="info-values text-white h5">{{infoActualValue}}/{{infoTotalValue}}</span>
    </div>
  </div>

</div>

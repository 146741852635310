import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { MetadataBase } from '../../models/metadata-base';

@Component({
  selector: 'app-dynamic-form-metadata',
  templateUrl: './dynamic-form-metadata.component.html',
  styleUrls: ['./dynamic-form-metadata.component.scss']
})
export class DynamicFormMetadataComponent implements OnInit {
  @Input() metadata!: MetadataBase<string>;
  @Input() form!: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

  get isValid() { return this.form.controls[this.metadata.key].valid; }

}

import { Component, Input, OnInit } from '@angular/core';
import { EventsService } from '../../services/events/events.service';

@Component({
  selector: 'app-search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.scss']
})
export class SearchButtonComponent implements OnInit {

  @Input() isDataGovernancePortal: boolean = false;

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
  }

  showSearcher() {
    this.eventsService.openSearchPanel$.emit(true);
  };

}

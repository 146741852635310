export var checkboxes = [
    {
      id: '1',
      name: "1",
      color: "",
      checked: false
    },
    {
      id: '2',
      name: "2",
      color: "",
      checked: false
    },
    {
      id: '3',
      name: "3",
      color: "",
      checked: false
    },
    {
      id: '4',
      name: "4",
      color: "",
      checked: false
    },
    {
      id: '>=5',
      name: ">=5",
      color: "",
      checked: false
    },
  ];
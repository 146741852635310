import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss']
})
export class TabGroupComponent implements OnInit {
 
  @Input() tabElements: any[];

  constructor() { }

  ngOnInit(): void {}

}

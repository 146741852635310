import { Injectable } from '@angular/core';
import { Date } from 'src/app/shared/models/date';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  // Function to calculate the day
  public calculateDay(year: number, month: number): string {
    const today = new Date();
    let day: string;
    if (today.getMonth() === month-1 && today.getFullYear() === year) {
      day = today.getDate().toString();
    } else {
      day = (new Date(year, month, 0).getDate()).toString();
    }
    return day;
  }

  // Retrieve last day of previous month form actual date
  public getLastDayDate(): any {
    let actualDate = this.getActualDate();
    let lastDayDate: Date = {};

    // This block allows to calculate the previous month before the the current date
    // From 28-09-2022 filters take the value of the current month
    //this.setLastMonthPreviousDate(lastDayDate, actualDate);
    //return lastDayDate;

    return actualDate;
  }

  public setLastMonthPreviousDate(lastDayDate: Date, actualDate: Date): void {
    lastDayDate.month = actualDate.month == 1 ? lastDayDate.month = 12 : actualDate.month - 1;
    lastDayDate.year = lastDayDate.month == 12 ? actualDate.year - 1 : actualDate.year;
    lastDayDate.day = (new Date(lastDayDate.year, lastDayDate.month, 0).getDate());
  }

  // Retrieve actual date
  public getActualDate(): any {
    const today = new Date();
    let actualDate: Date = {};
    actualDate.day = today.getDate();
    actualDate.month = today.getMonth() + 1;
    actualDate.year = today.getFullYear();

    return actualDate;
  }

}

<div class="widget">
    <div class="widget_header" *ngIf=title>
        <p class="widget_header-title">{{title}}</p>
        <p class="widget_header-button">Ver detalle <span></span></p>
    </div>
    <div class="widget_content">
        <!-- <app-huge-kpi></app-huge-kpi> -->
        <!-- <app-big-kpi></app-big-kpi> -->
        <!-- <app-regular-kpi></app-regular-kpi> -->
        <!-- <app-small-kpi></app-small-kpi> -->

        <ng-content></ng-content>

    </div>

    <ng-content></ng-content>

</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-quality-complaints',
  templateUrl: './sales-quality-complaints.component.html',
  styleUrls: ['./sales-quality-complaints.component.scss']
})
export class SalesQualityComplaintsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class = "table-wrapper">
    <component-loader [width]="" [height]="590" *ngIf="!loaded"></component-loader>
    <div *ngIf="loaded">
        <table class="table table-clientometer">
            <thead>
                <tr class = "table-header">
                    <th>{{ 'panels.clientsExperienceIndicators' | translate }}</th>
                    <th>%</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-body very-dissatisfied" *ngFor="let item of tableSeries.very_dissatisfied.values; let i = index">
                    <td>{{ item.name }}</td>
                    <td>{{ item.value }}</td>
                    <td [attr.rowspan]="tableSeries.very_dissatisfied.values.length" *ngIf="i == 0">
                        <div class="d-flex justify-content-center align-items-center">
                            <span class="main-quantity text-white">{{ tableSeries.very_dissatisfied.value }}%</span>
                            <span class="material-icons text-white icon">sentiment_very_dissatisfied</span>
                        </div>
                    </td>
                </tr>
                <tr class="table-body dissatisfied" *ngFor="let item of tableSeries.dissatisfied.values; let i = index">
                    <td>{{ item.name }}</td>
                    <td>{{ item.value }}</td>
                    <td [attr.rowspan]="tableSeries.dissatisfied.values.length" *ngIf="i == 0">
                        <div class="d-flex justify-content-center align-items-center">
                            <span class="main-quantity text-white">{{ tableSeries.dissatisfied.value }}%</span>
                            <span class="material-icons text-white icon">sentiment_dissatisfied</span>
                        </div>
                    </td>
                </tr>
                <tr class="table-body satisfied" *ngFor="let item of tableSeries.satisfied.values; let i = index">
                    <td>{{ item.name }}</td>
                    <td>{{ item.value }}</td>
                    <td [attr.rowspan]="tableSeries.satisfied.values.length" *ngIf="i == 0">
                        <div class="d-flex justify-content-center align-items-center">
                            <span class="main-quantity text-white">{{ tableSeries.satisfied.value }}%</span>
                            <span class="material-icons text-white icon">sentiment_satisfied</span>
                        </div>
                    </td>
                </tr>
                <tr class="table-body very-satisfied" *ngFor="let item of tableSeries.very_satisfied.values; let i = index">
                    <td>{{ item.name }}</td>
                    <td>{{ item.value }}</td>
                    <td [attr.rowspan]="tableSeries.very_satisfied.values.length" *ngIf="i == 0">
                        <div class="d-flex justify-content-center align-items-center">
                            <span class="main-quantity text-white">{{ tableSeries.very_satisfied.value }}%</span>
                            <span class="material-icons text-white icon">sentiment_very_satisfied</span>
                        </div>
                    </td>
                </tr>

                <!-- <ng-container *ngIf="checkSectionValues(tableSeries.neutral.values)"> -->
                    <tr class="table-body neutral" *ngFor="let item of tableSeries.neutral.values; let i = index">
                        <td>{{ item.name }}</td>
                        <td>{{ item.value }}</td>
                        <td [attr.rowspan]="tableSeries.neutral.values.length" *ngIf="i == 0">
                            <div class="d-flex justify-content-center align-items-center">
                                <span class="main-quantity text-white">{{ tableSeries.neutral.value }}%</span>
                                <span class="material-icons text-white icon">sentiment_neutral</span>
                            </div>
                        </td>
                    </tr>
                <!-- </ng-container> -->


            </tbody>
        </table>
    </div>
</div>
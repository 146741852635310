import { Component, OnInit, OnDestroy } from '@angular/core';
import { single } from './data';
import { calculateViewDimensions, ViewDimensions } from '@swimlane/ngx-charts';
import { COLORS } from 'src/app/shared/globals/globals';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'


@Component({
  selector: 'app-churn-rate-plan-graph',
  templateUrl: './churn-rate-plan-graph.component.html',
  styleUrls: ['./churn-rate-plan-graph.component.scss']
})
export class ChurnRatePlanGraphComponent implements OnInit, OnDestroy {

  single: any[];
  unsubscribe$: Subject<any> = new Subject<any>();
  monthNames: any;
  planName: string;
  month: string;
  year: number;
  filtersValues: any;
  optionTabBonus: string = 'panels.bonus';
  optionTabPolicy: string = 'panels.policy';
  optionTabDiscreet: string = 'panels.discreet';
  optionTabAccumulated: string = 'panels.accumulated';
  bonusPolicySelectedOption: string = 'panels.bonus';
  bonusDiscreetAccumulatedSelectedOption: string = 'panels.discreet';

  width: number = 200;
  height: number = 200;
  view: any[] = [235, 140];
  dims: ViewDimensions;
  margins: any = [0, 0, 0, 0];
  customColors: any = [];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = false;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';
  colorScheme: any = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  roundDomains: boolean = true;
  showDataLabel: boolean = true;
  barPadding: number = 30;
  roundEdges: boolean = false;
  rotateXAxisTicks: boolean = false;

  labelWidth: number = 235;
  labelDiff: number = 4;
  viewBox1: any = [37, -3, 50, 20];
  viewBox2: any = [-18, -3, 100, 20];
  viewBox3: any = [0, -3, 50, 20];

  constructor(
    private filtersService: FiltersService,
    private eventsService: EventsService,
    private apimstService: ApimstService,
    private translateService: TranslateService
  ) {
    Object.assign(this, { single })
  }

  ngOnInit(): void {
    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margins
    });

    const months = this.translateService.get('abbreviatedMonthNames')
    const plan = this.translateService.get('labels.plan');

    forkJoin([months, plan]).subscribe(results => {
      this.monthNames = results[0];
      this.planName = results[1];
      this.assignColorBar();
    });

    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data.length !== 0) {
            this.filtersValues = data;
            //this.resetVariables();
            //this.retrieveMonthlyGraphData('p', data); // (p -> premium)
          }
        }
      );

    this.eventsService.policiesBonusOption$.subscribe(
      data => {
        if (data === this.optionTabBonus || data === this.optionTabPolicy) {
          this.bonusPolicySelectedOption = data;
          // if (this.policyVerticalBars.length === 0 && this.policyHorizontalBars.length === 0) {
          //   this.retrieveMonthlyGraphData('c', this.filtersValues); // (c -> certificate)
          // } else {
          //   this.assignDataToPrint();
          // }
        }
      }
    );
  }


  // Method to assign the colors to the graph bars
  private assignColorBar() {
    this.customColors.push(
      {
        name: this.monthNames[this.month] + ' ' + (this.year - 1),
        value: COLORS.darkGreen
      },
      {
        name: this.monthNames[this.month] + ' ' + (this.year),
        value: COLORS.lightGreen
      },
      {
        name: this.planName + this.monthNames[this.month],
        value: '#a0c2d5'
      },
      {
        name: this.planName + this.year,
        value: '#ff4394'
      })
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancellation-quality',
  templateUrl: './cancellation-quality.component.html',
  styleUrls: ['./cancellation-quality.component.scss']
})
export class CancellationQualityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'
import { QualityVelocimeter } from 'src/app/shared/models/quality-velocimeter';
import { EventsService } from 'src/app/shared/services/events/events.service';

@Component({
  selector: 'cancellation-quality-claims-velocimeter',
  templateUrl: './cancellation-quality-claims-velocimeter.component.html',
  styleUrls: ['./cancellation-quality-claims-velocimeter.component.scss']
})
export class CancellationQualityClaimsVelocimeterComponent implements OnInit, OnDestroy {

  // ViewBox
  viewBox: string = "0 0 100 50";
  strokeWidth: number = 8;

  target: string;
  mainValue: string;
  secondValue: string;
  trend: string;
  leftValue;
  hasInfo: boolean = false;
  currentValue;
  hAlign: string = "center";
  vAlign: string = "center";
  barOrientation: number;

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;

  qualityClaim: QualityVelocimeter;
  qualityClaimSelected: any = [];
  loaded: boolean = false;

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private eventsService: EventsService,
  ) { }

  ngOnInit(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data.length !== 0) {
            this.filtersValues = data;
            this.loaded = false;
            this.retrieveQualityClaims(this.qualityClaimSelected, this.filtersValues);

          }
        }
      );

    this.eventsService.salesQualityClaims$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.qualityClaimSelected = [];
        if (data.value !== '') {
          this.qualityClaimSelected.push(data.value);
        }
        this.retrieveQualityClaims(this.qualityClaimSelected, this.filtersValues);
      });

  }

  // Method to retrieve the quality claim data
  private retrieveQualityClaims(qualityClaimSelected: [], filtersValues: any) {
    this.loaded = false;
    this.apimstService.getCancellationQualityClaims(qualityClaimSelected, filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data: QualityVelocimeter) => {
          this.qualityClaim = data;
          if (parseFloat(this.qualityClaim.value) <= parseFloat(this.qualityClaim.target)) {
            this.target = (parseFloat(this.qualityClaim.target)*1.8).toString();
            this.currentValue = (parseFloat(this.qualityClaim.value)*1.8).toString();
            this.barOrientation = 1;
          } else {
            this.target = (parseFloat(this.qualityClaim.target)*1.8).toString();
            this.currentValue = (parseFloat(this.qualityClaim.value)*1.8).toString();
            this.barOrientation = 0;
          }

          this.loaded = true;

        },
        error => console.log("An error ocurred while retrieving cancellation quality claims indicator data: " + error)

      );
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

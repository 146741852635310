import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RejectionsIndicator } from 'src/app/shared/models/rejections-indicator';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-rejections-indicators',
  templateUrl: './rejections-indicators.component.html',
  styleUrls: ['./rejections-indicators.component.scss']
})
export class RejectionsIndicatorsComponent implements OnInit {

  @Input() indicators: any = [];

  amountInfoCopy: any = {};
  sinistersInfoCopy: any = {};

  loaded: boolean = false;

  subscriptionFilters: Subscription;
  subscriptionRequest: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();

  kpisInfo: any = {};

  percentagePrevious: string = "labels.pm";

  type_data: string;
  type_report: string;

  selectedOption = "rejectionsAmount";
  graphName: string = 'sinistersRejections';
  selectedGraph = "sinistersRejections";

  translations: any[] = ['panels.paymentZero', 'panels.administrativeF'];
  paymentZeroName: string = "";
  administrativeName: string = "";

  filtersValues: any;

  paymentZeroIndicator: RejectionsIndicator = {};
  administrativeIndicator: RejectionsIndicator = {};

  constructor(
    private translateService: TranslateService,
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    this.loaded = false;

    // this.subscriptionFilters = this.filtersService.filtersValues$
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe({
    //     next: data => {
    //       this.clearInfoCopies();

    //       if (data.length == 0) return;
    //       this.filtersValues = data;

    //       this.getSelectedOption();
    //       this.retrieveRejectionsIndicators(this.type_data, this.filtersValues);
    //     }
    //   }
    //   );

    //   this.eventsService.optionTabMultipleSelected$
    //   .subscribe ({
    //     next: option => {
    //       this.selectedOption = option.id;
    //       this.selectedGraph = option.graphName;

    //       const isInButtonGroup = this.selectedOption == option.id && this.selectedGraph == this.graphName;
    //       if(!isInButtonGroup) return;
            
    //       this.getSelectedOption();
    //       this.createFromCopies();
    //     }
    //   })

  }

  retrieveRejectionsIndicators(type_data: string, filtersValues: any): void {
    this.loaded = false;

    this.subscriptionRequest = this.apimstService.getSinistersRejections(type_data, 'srm', filtersValues)
      .subscribe({
        next: kpisInfo => {
          this.kpisInfo = kpisInfo;

          let details: any[] = this.kpisInfo.details ? this.kpisInfo.details : [{name: "", value: "", diff: "", diff_trend: ""}];
          this.createIndicators(details);
          
          this.loaded = true;
        },
        error: error => console.log('An error occurred while retrieving rejections indicators ' + error),
        complete: () => {
          this.storeOptionValues();
        }
      })
  }

  getSelectedOption(): void {
    if (this.selectedOption == "rejectionsAmount") this.type_data = 'a'
    if (this.selectedOption == "rejectionsSinisters") this.type_data = 's'
  }

  createIndicators(kpisInfo: any = []): void {
    let indicatorsValues = [];

    indicatorsValues = kpisInfo
      .map(indicator => (
        { 
          title: indicator.name, 
          mainValue: String(parseFloat(indicator.value)),
          mainValueUnit: "%",
          trend: parseInt(indicator.diff_trend),
          percentage: indicator.diff
        })
      );
    
      this.indicators = indicatorsValues;
  }

  storeOptionValues(): void {
    let infoStored = { kpiInfo: [] };
    let isAmount = this.selectedOption == "rejectionsAmount" && !this.amountInfoCopy.kpiInfo;
    let isSinisters = this.selectedOption == "rejectionsSinisters" && !this.sinistersInfoCopy.kpiInfo;
    infoStored.kpiInfo = this.kpisInfo;

    if (isAmount) this.amountInfoCopy = Object.assign({}, infoStored);
    if (isSinisters) this.sinistersInfoCopy = Object.assign({}, infoStored);
  }

  clearInfoCopies(): void {
    this.amountInfoCopy = {};
    this.sinistersInfoCopy = {};
  }

  createFromCopies(): void {
    let isAmountFilled = this.selectedOption == "rejectionsAmount" && this.amountInfoCopy.kpiInfo;
    let isSinistersFilled = this.selectedOption == "rejectionsSinisters" && this.sinistersInfoCopy.kpiInfo;

    if (isAmountFilled) {
      this.createCopy(this.amountInfoCopy.kpiInfo.details);
    } else if (isSinistersFilled) {
      this.createCopy(this.sinistersInfoCopy.kpiInfo.details);
    } else {
      this.retrieveRejectionsIndicators(this.type_data, this.filtersValues);
    }
  }

  createCopy(copy: any): void {
    this.createIndicators(copy);
  }

  ngOnDestroy() {
    // this.subscriptionFilters.unsubscribe();
    // this.subscriptionRequest.unsubscribe();
  }

}

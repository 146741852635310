<div class="mt-3" *ngIf="!loaded">
    <component-loader [width]="" [height]="190"></component-loader>
</div>

<div class="d-flex justify-content-between" *ngIf="loaded">
    <app-small-kpi [mainValue]="leftValue" [mainValueUnit] = "currencySymbol" [percentagePrevious]="percentagePrevious" [percentage]="leftPercentage" [trend]="trend" class="kpi"></app-small-kpi>
    <div class="d-flex align-items-center">
        <app-legend class="mr-2" name="GWP" color="light-blue" type="round" gradient="v-gradient"></app-legend>
        <app-legend name="Plan" color="dark-yellow" type="line" gradient="h-gradient"></app-legend>
        <!-- <mat-icon *ngIf="loaded" class="excel-icon ml-2" svgIcon="excel" (click)="exportAsXLSX()"></mat-icon> -->
    </div>
</div>

<div class="d-flex justify-content-start" *ngIf="loaded">
    <div *ngIf="showGraphic">
        <stacked-vertical-horizontal-graph
            [view]="view"
            [scheme]="comboBarScheme"
            [colorSchemeLine]="lineChartScheme"
            [customColors]="customColors"
            [results]="barChart"
            [animations]="animations"
            [lineChart]="lineChartSeries"
            [yAxisTickFormatting]="yAxisTickFormatting"
            [yLeftAxisScaleFactor]="yLeftAxisScaleFactor"
            [yRightAxisScaleFactor]="yRightAxisScaleFactor"
            [yRightAxisTickFormatting]="yRightAxisTickFormatting"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [legendTitle]="legendTitle"
            [legendPosition]="legendPosition"
            [showGridLines]="showGridLines"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [showRightYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [barPadding]="barPadding"
            [tooltipDisabled]="tooltipDisabled"
            [schemeType]="schemeType"
            [roundDomains]="roundDomains"
            [noBarWhenZero]="noBarWhenZero">
        </stacked-vertical-horizontal-graph>
    </div>
</div>
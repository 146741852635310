<div class="kpi-header-single mx-2">
    <div class="d-block kpi-header-single--title-block">
        <span class="kpi-header-single--title text-light mr-1">{{ item.name | translate:{year:item.year} }}</span>
        <span class="text-mid">{{ item.month | translate }} {{ item.year }}</span>
    </div>
    <div class="d-block mt-1">
        <span class="kpi-header-single--quantity text-white mr-1 d-block" *ngIf="item.value != 0">{{ item.valuePrefix }}{{ item.value }}</span>
        <span class="item-diff" *ngIf="item.diff" [ngClass]="{'text-brand-success': item.diff_trend == 1, 'text-brand-danger': item.diff_trend == 0}">
            <span class="material-icons icon" *ngIf="item.diff_direction == 1">arrow_drop_up</span>
            <span class="material-icons icon" *ngIf="item.diff_direction == 0">arrow_drop_down</span>
            <span class="item-diff-label">{{ item.diff }}</span>
        </span>
    </div>
</div>
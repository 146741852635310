import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventsService } from 'src/app/shared/services/events/events.service';

@Component({
  selector: 'app-business-side-menu',
  templateUrl: './business-side-menu.component.html',
  styleUrls: ['./business-side-menu.component.scss']
})
export class BusinessSideMenuComponent implements OnInit {

  @Input() selectedSectionIndex = 0;

  // Labels for the options panel
  options: any[];
  sectionName: string = 'businessSideMenu';

  detailOptions: any[] = [
    { id: "description", name: "labels.description", selected: true },
    { id: "filters", name: "filtersPanel.filters", selected: false },
    { id: "businessCatalogue", name: "labels.businessCatalogue", selected: false },
    { id: "technicalCatalogue", name: "labels.technicalCatalogue", selected: false},
    { id: "technical", name: "labels.technical", selected: false},

  ];

  controlPanelOptions: any[] = [
    { id: "description", name: "labels.description", selected: true },
    { id: "filters", name: "filtersPanel.filters", selected: false }
  ]

  unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    if(this.selectedSectionIndex !== 0) {
      this.options = _.cloneDeep(this.detailOptions);
    } else {
      this.options = _.cloneDeep(this.controlPanelOptions);
    }

    this.eventsService.searchOptionSelected$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((data: any) => {
      if (data.id === 'controlPanel' && data.sectionName === 'businessDetail') {
        this.options = _.cloneDeep(this.controlPanelOptions);
      } else if (data.sectionName === 'businessDetail') {
        this.options = _.cloneDeep(this.detailOptions);
      } else if (data.sectionName === 'businessUpperMenu') {
        this.options = _.cloneDeep(this.controlPanelOptions);
      }
    });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

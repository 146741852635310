<div class = "mt-0 d-block">
    <div class = "table-wrapper">

        <component-loader *ngIf="!loadedBouquets" [width]="" [height]="160"></component-loader>

        <div *ngIf="loadedBouquets && bouquetsToPrint.length !== 0">
            <table class = "table table-branch" >
                <thead>
                    <tr class = "table-header">
                        <th [title]="'panels.branch' | translate">{{ 'panels.branch' | translate }}</th>
                        <th [title]="'headers.month' | translate">{{ headers.previousMonth }}</th>
                        <th [title]="'headers.month' | translate">{{ headers.actualMonth }}</th>
                        <th [title]="headers.diff">{{ headers.diff }}</th>
                        <th [title]="'labels.plan' | translate">{{ headers.plan }}</th>
                        <th [title]="headers.total_diff">{{ headers.totalDiff }}</th>
                    </tr>

                    <tr class = "table-sub-header">
                        <td [title]="'labels.total' | translate">{{ bouquetsTotalToPrint?.name }}</td>
                        <td>{{ bouquetsTotalToPrint?.previous | numberFormat | magnitudeOrder }}</td>
                        <td>{{ bouquetsTotalToPrint?.current | numberFormat | magnitudeOrder }}</td>
                        <td [ngClass] = "{'text-brand-success': bouquetsTotalToPrint?.diff_sign === 1, 'text-brand-danger': bouquetsTotalToPrint?.diff_sign === 0}">{{ bouquetsTotalToPrint?.diff | numberFormat | magnitudeOrder }}</td>
                        <td>{{ bouquetsTotalToPrint?.budget | numberFormat | magnitudeOrder }}</td>
                        <td [ngClass] = "{'text-brand-success': bouquetsTotalToPrint?.budget_diff_sign === 1, 'text-brand-danger': bouquetsTotalToPrint?.budget_diff_sign === 0}">{{ bouquetsTotalToPrint?.budget_diff | numberFormat | magnitudeOrder }}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr class = "table-body" *ngFor = "let bouquet of bouquetsToPrint">
                        <td [title]="bouquet?.name">{{ bouquet?.name }}</td>
                        <td>{{ bouquet?.previous | numberFormat | magnitudeOrder }}</td>
                        <td>{{ bouquet?.current | numberFormat | magnitudeOrder }}</td>
                        <td [ngClass] = "{'text-brand-success': bouquet?.diff_trend === '1', 'text-brand-danger': bouquet?.diff_trend === '0'}">{{ bouquet?.diff | numberFormat | magnitudeOrder}}</td>
                        <td>{{ bouquet?.budget | numberFormat | magnitudeOrder }}</td>
                        <td [ngClass] = "{'text-brand-success': bouquet?.budget_diff_trend === '1', 'text-brand-danger': bouquet?.budget_diff_trend === '0'}">{{ bouquet?.budget_diff | numberFormat | magnitudeOrder }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class = "table-wrapper">


        <component-loader *ngIf="!loadedChannels" [width]="" [height]="160"></component-loader>

        <div *ngIf="loadedChannels && channelsToPrint.length !== 0">
            <table class = "table table-channel">
                <thead>
                    <tr class = "table-header">
                        <th [title]="'panels.channel' | translate">{{ 'panels.channel' | translate }}</th>
                        <th [title]="'headers.month' | translate">{{ headers.previousMonth }}</th>
                        <th [title]="'headers.month' | translate">{{ headers.actualMonth }}</th>
                        <th [title]="headers.diff">{{ headers.diff }}</th>
                        <th [title]="'labels.plan' | translate">{{ headers.plan }}</th>
                        <th [title]="headers.total_diff">{{ headers.totalDiff }}</th>
                    </tr>

                    <tr class = "table-sub-header">
                        <td [title]="'labels.total' | translate">{{ channelsTotalToPrint?.name }}</td>
                        <td>{{ channelsTotalToPrint?.previous | numberFormat | magnitudeOrder }}</td>
                        <td>{{ channelsTotalToPrint?.current | numberFormat | magnitudeOrder }}</td>
                        <td [ngClass] = "{'text-brand-success': channelsTotalToPrint?.diff_sign === 1, 'text-brand-danger': channelsTotalToPrint?.diff_sign === 0}">{{ channelsTotalToPrint?.diff | numberFormat | magnitudeOrder }}</td>
                        <td>{{ channelsTotalToPrint?.budget | numberFormat | magnitudeOrder }}</td>
                        <td [ngClass] = "{'text-brand-success': channelsTotalToPrint?.budget_diff_sign === 1, 'text-brand-danger': channelsTotalToPrint?.budget_diff_sign === 0}">{{ channelsTotalToPrint?.budget_diff | numberFormat | magnitudeOrder}}</td>
                    </tr>
                </thead>

                <tbody>
                    <tr class = "table-body" *ngFor = "let channel of channelsToPrint">
                        <td [title]="channel.name">{{ channel.name }}</td>
                        <td>{{ channel?.previous | numberFormat | magnitudeOrder }}</td>
                        <td>{{ channel?.current | numberFormat | magnitudeOrder}}</td>
                        <td [ngClass] = "{'text-brand-success': channel?.diff_trend === '1', 'text-brand-danger': channel?.diff_trend === '0'}">{{ channel.diff | numberFormat | magnitudeOrder}}</td>
                        <td [title]="channel?.budget">{{ channel?.budget | numberFormat | magnitudeOrder }}</td>
                        <td [ngClass] = "{'text-brand-success': channel?.budget_diff_trend === '1', 'text-brand-danger': channel?.budget_diff_trend === '0'}" [title]="channel?.plan_diff">{{ channel?.budget_diff | numberFormat | magnitudeOrder}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>

</div>

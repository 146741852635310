import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FiltersService } from '../filters/filters.service';

import { CurrencyInfo } from '../../models/currency';


@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  currencyInfo$ = new BehaviorSubject<CurrencyInfo>( {iso: "", symbol: ""} );

  constructor(
    private filtersService: FiltersService,
  ) { }
}

import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { ApiAtlasService } from 'src/app/shared/services/api-atlas/api-atlas.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-data-governance-info-page',
  templateUrl: './data-governance-info-page.component.html',
  styleUrls: ['./data-governance-info-page.component.scss']
})
export class DataGovernanceInfoPageComponent implements OnInit {

  @Input() titleLabel: string;
  @Input() descriptionText: string;
  @Input() descriptionImage;
  @Input() language: string;
  @Input() guid: string;

  openTextEditor: boolean = false;
  openImageEditor: boolean = false;
  textEditorHeight: '300px';
  textEditorWidth: '650px';
  imageEditorHeight: '300px';
  imageEditorWidth: '500px';
  textEditor: boolean = true;
  hasEditPermission: boolean = false;

  unsubscribe$: Subject<any> = new Subject<any>();
  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;

  // Default constructor
  constructor(
    private eventsService: EventsService,
    private apiAtlasService: ApiAtlasService
  ) { }

  // OnInit
  ngOnInit(): void {

    this.subscribeToUserInfoLoaded();

    // Detect HTML edition cancellation
    this.eventsService.cancelHtmlTextEdition$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data.cancel) this.openTextEditor = false;
      });

    // Detect HTML edition storage  
    this.eventsService.saveHtmlTextEdition$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data.edition) {
          this.descriptionText = data.content;
          this.openTextEditor = false;
          const attributes = {
            desc_html: this.descriptionText
          }
          const updatedData = {
            updatedSection: 'description',
            updatedContent: this.descriptionText
          }
          this.apiAtlasService.updateDataGovernanceInfo(this.language, this.guid, attributes, this.endpoint).pipe(takeUntil(this.unsubscribe$)).subscribe();
          this.eventsService.updateBusinessInformation$.emit(updatedData);
        }
      })
  }

  subscribeToUserInfoLoaded(): void {
    this.eventsService.userInfoLoaded$
      .subscribe(
        userInfo => {
          this.hasEditPermission = userInfo.edit_permission == "1" ? true: false;
        }
      )
  }

  // Open HTML text editor
  textEdition() {
    this.openTextEditor = true;
  }

  // Method to encode images in base64
  encodeImageFileAsURL() {
    const file = document.getElementById('inputFile')['files'];
    if (file.length > 0) {
      const fileToLoad = file[0];
      const fileReader = new FileReader();
      
      fileReader.onload = (fileLoadedEvent) => {
        var srcData = fileLoadedEvent.target.result;
        this.descriptionImage = srcData;
        const attributes = {
          desc_image: this.descriptionImage
        }
        this.apiAtlasService.updateDashboardSectionInfo(this.language, this.guid, attributes).pipe(takeUntil(this.unsubscribe$)).subscribe();
        const updatedData = {
          updatedSection: 'image',
          updatedContent: this.descriptionImage
        }
        this.eventsService.updateBusinessInformation$.emit(updatedData);
      }
      const imageData = fileReader.readAsDataURL(fileToLoad);
      
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

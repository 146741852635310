import { Component, OnInit, OnDestroy } from '@angular/core';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'
import { QualityVelocimeter } from 'src/app/shared/models/quality-velocimeter';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { QualityResponseRate } from 'src/app/shared/models/quality-response-rate';
import { SemicirclePie } from 'src/app/shared/models/semicircle-pie';
import { COLORS } from 'src/app/shared/globals/globals';
import { TranslateService } from '@ngx-translate/core';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';

@Component({
  selector: 'app-sinisters-polls-velocimeter',
  templateUrl: './sinisters-polls-velocimeter.component.html',
  styleUrls: ['./sinisters-polls-velocimeter.component.scss']
})
export class SinistersPollsVelocimeterComponent implements OnInit {

  tnpsItems: SemicirclePie[] = [];
  legends: any[] = [];
  tnpsKpi: any = {};

  // ViewBox
  viewBox: string = "0 0 100 50";
  strokeWidth: number = 8;

  // Values
  target: string;
  mainValue: string;
  secondValue: string;
  trend: string;
  leftValue: string;
  hasInfo: boolean = true;
  currentValue: string;
  hAlign: string = "start";
  vAlign: string = "center";
  infoTitle: string = 'labels.responseRate';

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;

  qualityNPS: QualityVelocimeter;
  qualityNPSSelected: any = [];
  loaded: boolean = false;
  responseRate: QualityResponseRate;
  barOrientation: number;

  promotersName: string = "";
  neutralsName: string = "";
  detractorsName: string = "";

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private eventsService: EventsService,
    private translateService: TranslateService,
    private magnitudeOrderPipe: MagnitudeOrderPipe
  ) { }

  ngOnInit(): void {

    this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        const promotersT = this.translateService.get('labels.promoters');
        const neutralsT = this.translateService.get('labels.neutrals');
        const detractorsT = this.translateService.get('labels.detractors');

        forkJoin([promotersT, neutralsT, detractorsT]).pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            {
              next: results => {
                this.promotersName = results[0];
                this.neutralsName = results[1];
                this.detractorsName = results[2];
              },
              complete: () => {
                if (data.length !== 0) {
                  this.filtersValues = data;
                  this.qualityNPSSelected = [];
                  this.retrieveQualityNPS(this.qualityNPSSelected, this.filtersValues);
                }
              }
            })
      }
    );

    this.eventsService.sinistersQualityNPS$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(data => {
      this.qualityNPSSelected = [];
      if (data.value !== 'all') {
        this.qualityNPSSelected.push(data.value);
      }
      this.retrieveQualityNPS(this.qualityNPSSelected, this.filtersValues);
    });

  }

  // Method to retrieve the quality NPS data
  private retrieveQualityNPS(qualityNPSSelected: [], filtersValues: any) {
    this.loaded = false;
    this.apimstService.getSinistersQualityNPS(qualityNPSSelected, "sst", filtersValues)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (data: QualityVelocimeter) => {
        this.qualityNPS = data[0];

        this.createSemicirclePie(this.qualityNPS);
        this.createSemicirclePieKpi(this.qualityNPS);
        this.createLegends(this.qualityNPS);

        this.target = (parseFloat(this.qualityNPS.target)*1.8).toString(); // 1.8 => 180º = 100%
        this.currentValue = (parseFloat(this.qualityNPS.value)*1.8).toString();

        this.loaded = true;

      },
      error => console.log("An error ocurred while retrieving quality NPS indicator data: " + error)

    );

    this.apimstService.getSinistersQualityNPS(qualityNPSSelected, "ssr", filtersValues)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (data: QualityResponseRate) => {
        if( !data[0].hasOwnProperty('response_rate') ) return;

        const formattedData = {
          response_rate: data[0].response_rate,
          surveys_answered: data[0].surveys_answered || data[0].surveys_answered != "" ? Intl.NumberFormat("de-DE").format(parseFloat(data[0].surveys_answered.replace(/,/g, ''))): "",
          surveys_sent: data[0].surveys_sent || data[0].surveys_sent != "" ? Intl.NumberFormat("de-DE").format(parseFloat(data[0].surveys_sent.replace(/,/g, ''))) : "",
          year: data[0].year,
          month: data[0].month,
        }

        this.responseRate = formattedData;
      },
      error => console.log("An error ocurred while retrieving quality response rate data: " + error)
    );
  }

  createSemicirclePie(info: any = {}) {
    this.tnpsItems = [
      {
        name: this.promotersName,
        percentage: info.promotor ? parseFloat(info.promotor) : 0,
        accumulatedPercentage: 0,
        color: this.getColor(this.promotersName)
      },
      {
        name: this.neutralsName,
        percentage: info.neutral ? parseFloat(info.neutral) : 0,
        accumulatedPercentage: 0,
        color: this.getColor(this.neutralsName)
      },
      {
        name: this.detractorsName,
        percentage: info.detractor ? parseFloat(info.detractor) : 0,
        accumulatedPercentage: 0,
        color: this.getColor(this.detractorsName)
      },
    ]

    let accumulatedItems = [];
    let accumulatedPercentage: number = 0;
    for(let item of this.tnpsItems) {
      accumulatedPercentage = accumulatedPercentage + item.percentage;
      accumulatedItems.push(
        {
          name: item.name,
          percentage: item.percentage,
          accumulatedPercentage: accumulatedPercentage/100 * 180,
          color: item.color
        }
      )
    }
    this.tnpsItems = accumulatedItems;
  }

  createSemicirclePieKpi(kpi: any) {
    this.tnpsKpi = {
      mainValue: kpi.tnps ? kpi.tnps : "0",
      mainValueUnit: '%',
      secondaryValue: kpi.target ? "/ " + this.magnitudeOrderPipe.transform(parseFloat(kpi.target.replace(/,/g, ''))) + "%" : "",
      percentage: '0',
      trend: 1,
      hasPercentage: false
    };
  }

  createLegends(info: any) {
    this.legends = [
      {
        name: this.promotersName + " (" + Intl.NumberFormat("de-DE").format(this.tnpsItems[0].percentage) + "%)",
        color_code: this.getColor(this.promotersName),
        type: 'round',
        gradient: 'h-gradient'
      },
      {
        name: this.neutralsName + " (" + Intl.NumberFormat("de-DE").format(this.tnpsItems[1].percentage) + "%)",
        color_code: this.getColor(this.neutralsName),
        type: 'round',
        gradient: 'h-gradient'
      },
      {
        name: this.detractorsName + " (" + Intl.NumberFormat("de-DE").format(this.tnpsItems[2].percentage) + "%)",
        color_code: this.getColor(this.detractorsName),
        type: 'round',
        gradient: 'h-gradient'
      }
    ]
  }

  getColor(label: string) {
    let color: string;
    let colors: any[] = [
      { name: this.promotersName, color: COLORS.darkGreen },
      { name: this.neutralsName, color: COLORS.darkYellow },
      { name: this.detractorsName, color: COLORS.darkRed }
    ];

    let matchedLabel = colors.filter(l => l.name == label)

    return matchedLabel[0].color;
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<label 
    for="multiple-selection" 
    class="multiple-selection-title h5 text-white d-flex justify-content-between mb-0 pl-2"
    *ngIf="title">
    <span class="title">{{ title }}</span>
    <span class="material-icons cross-icon" *ngIf="selectedItems.length > 0" (click)="onDeSelectAll($event)">cancel</span>
</label>
<ng-multiselect-dropdown 
    [placeholder]="placeholder" 
    [settings]="dropdownSettings"
    [data]="dropdownList" 
    [(ngModel)]="selectedItems"
    (onSelect)="onItemSelect($event)"
    (onDeSelect)="onItemDeSelect($event)" 
    (onSelectAll)="onSelectAll($event)"
    (onDeSelectAll)="onDeSelectAll($event)">
</ng-multiselect-dropdown>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sinisters-rejections',
  templateUrl: './sinisters-rejections.component.html',
  styleUrls: ['./sinisters-rejections.component.scss']
})
export class SinistersRejectionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EventsService } from 'src/app/shared/services/events/events.service';

@Injectable({
  providedIn: 'root'
})
export class RejectionsPendingAgeService implements OnInit {
  graphName: string = 'sinistersPendingAge';

  requestParams$ = new BehaviorSubject<any>([{ params: [], value: [] }]);
  panelSelectedOption$ = new BehaviorSubject<any>({ id: "amount", name: "panels.amount", selected: true, graphName: this.graphName });
  dropdownSelectedOption$ = new BehaviorSubject<any>('1');

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    // this.eventsService.optionTabMultipleChanged$.next(this.panelSelectedOption$.value);
    // this.dropdownSelectedOption$.next("1");
  }

  addRequestResult(result: any) {
    this.requestParams$.next({ ...this.requestParams$.value,  result});
  }

}

<component-loader *ngIf="!loaded" [width]="" [height]="160"></component-loader>

<div *ngIf="loaded">
    <grouped-vertical-horizontal-graph-d-axis
        [view]="view"
        [scheme]="comboBarScheme"
        [results]="groupedVerticalGraph"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [yRightAxisTickFormatting]="yRightAxisTickFormatting"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [legend]="showLegend"
        [barPadding]=1
        [groupPadding]=5
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [legendTitle]="legendTitle"
        [lineChart]="horizontalGraph"
        [showGridLines]="showGridLines"
        [showRightGridLines]="showRightGridLines"
        [customColors]="customColors"
        [roundEdges]="roundEdges"
        [activeEntries]="activeEntries"
        [trimXAxisTicks]="trimXAxisTicks"
        [trimYAxisTicks]="trimYAxisTicks"
        [rotateXAxisTicks]="rotateXAxisTicks"
        [maxXAxisTickLength]="maxXAxisTickLength"
        [maxYAxisTickLength]="maxYAxisTickLength"
        [tooltipDisabled]="tooltipDisabled"
        [roundDomains]="roundDomains">
    </grouped-vertical-horizontal-graph-d-axis>
</div>
<app-widget>

  <highcharts-chart 
    class="map-gen" 
    [Highcharts]="Highcharts" 
    [constructorType]="chartConstructor"
    [options]="chartOptions" 
    style="width: 100%; height: 100%; display: block;">
  </highcharts-chart>

</app-widget>

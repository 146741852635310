import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CancellationDetailComponent } from './cancellation/cancellation-detail/cancellation-detail.component';
import { LoginComponent } from './login/login.component';
import { SalesPageOneComponent } from './sales/sales-page-one/sales-page-one.component';
import { SalesPortfolioComponent } from './sales/sales-portfolio/sales-portfolio.component';
import { SalesQualityComplaintsComponent } from './sales/sales-quality-complaints/sales-quality-complaints.component';
import { SalesMixPortfolioGwpComponent } from './sales/sales-mix-portfolio-gwp/sales-mix-portfolio-gwp.component';
import { CancellationChargeComponent } from './cancellation/cancellation-charge/cancellation-charge.component';
import { CancellationRetentionComponent } from './cancellation/cancellation-retention/cancellation-retention.component';
import { CancellationRenewalComponent } from './cancellation/cancellation-renewal/cancellation-renewal.component';
import { CancellationPersistenceComponent } from './cancellation/cancellation-persistence/cancellation-persistence.component';
import { CancellationQualityComponent } from './cancellation/cancellation-quality/cancellation-quality.component';
import { SlasSectionComponent } from './slas/slas-section/slas-section.component';
import { PreHomeComponent } from './pre-home/pre-home.component';
import { HomeComponent } from './home/home.component';
import { AppComponent } from './app.component';
import { SinistersDetailComponent } from './sinisters/sinisters-detail/sinisters-detail.component';
import { SinistersRejectionsComponent } from './sinisters/sinisters-rejections/sinisters-rejections.component';
import { SinistersQualityComponent } from './sinisters/sinisters-quality/sinisters-quality.component';
import { UserInfoResolver } from './shared/resolvers/user-info.resolver';
import { SinistersMapComponent } from './sinisters/sinisters-map/sinisters-map.component';
import { DataGovernanceHomeComponent } from './data-governance/data-governance-home/data-governance-home.component';
import { CancellationFixedStockComponent } from './cancellation/cancellation-fixed-stock/cancellation-fixed-stock.component';
import { SinistersLossRatioComponent } from './sinisters/sinisters-loss-ratio/sinisters-loss-ratio.component';
import { DataGovernanceBusinessComponent } from './data-governance/data-governance-business/data-governance-business.component';
import { DataGovernanceTechnicalComponent } from './data-governance/data-governance-technical/data-governance-technical.component';
import { ClientsPortfolioComponent } from './clients/clients-portfolio/clients-portfolio.component';
import { ClientsOccupationComponent } from './clients/clients-occupation/clients-occupation.component';
import { ClientsVocComponent } from './clients/clients-voc/clients-voc.component';
import { ClientsInternalProcessQualityComponent } from './clients/clients-internal-process-quality/clients-internal-process-quality.component';
import { ClientsPortfolioDistributionComponent } from './clients/clients-portfolio-distribution/clients-portfolio-distribution.component';
import { ClientsPenetrationSegmentationComponent } from './clients/clients-penetration-segmentation/clients-penetration-segmentation.component';
import { DataDiscoveryHomeComponent } from './data-discovery/data-discovery-home/data-discovery-home.component';
import { ReportsHomeComponent } from './reports/reports-home/reports-home.component';
import { ControlPanelComponent } from './control-panel/control-panel.component';
import { CancellationRetentionAccordingToRecordComponent } from './cancellation/cancellation-retention-according-to-record/cancellation-retention-according-to-record.component';
import { DocumentManagementHomeComponent } from './document-management/document-management-home/document-management-home.component';
import { DocumentManagementComponent } from './document-management/document-management.component';
import { DocumentManagementFileManagerComponent } from './document-management/document-management-file-manager/document-management-file-manager.component';
import { DocumentManagementMetadataEditorComponent } from './document-management/document-management-metadata-editor/document-management-metadata-editor.component';

export const routes: Routes = [
  { 
    path: '', 
    component: LoginComponent,
  },
  { 
    path: 'portal',
    component: HomeComponent,
    resolve: { infoUser: UserInfoResolver },
    data: {
      id: 'portal',
      title: 'Portal',
      parentTitle: 'Portal',
      parentTitleT: 'panels.portal',
      animation: 'Portal'
    } 
  },
  { 
    path: 'prehome', 
    component: PreHomeComponent,
    resolve: { infoUser: UserInfoResolver },
    data: {
      id: 'prehome',
      title: 'Prehome',
      parentTitle: 'Prehome',
      parentTitleT: 'panels.prehome',
      animation: 'PreHome'
    } 
  },
  { 
    path: 'login', 
    component: LoginComponent,
    resolve: { infoUser: UserInfoResolver },
    data: {
      id: 'login',
      title: 'Login',
      parentTitle: 'Login',
      parentTitleT: 'panels.sales',
      animation: 'Login'
    } 
  },
  {
    path: 'sales',
    resolve: { infoUser: UserInfoResolver },
    data: {
      id: 'sales',
      title: 'Sales',
      parentTitle: 'Sales',
      parentTitleT: 'panels.sales',
      animation: 'Sales'
    },
    children: [
      {
        path: 'details',
        component: SalesPageOneComponent,
        data: {
          id: 'sale-detail',
          title: 'Sale detail',
          parentTitle: 'Sale',
          parentTitleT: 'panels.sales',
          animation: 'SalesDetail'
        }
      },
      {
        path: 'portfolio',
        component: SalesPortfolioComponent,
        data: {
          id: 'sale-portfolio',
          title: 'Sale Portfolio',
          parentTitle: 'Sale',
          parentTitleT: 'panels.sales',
          animation: 'SalesPortfolio'
        }
      },
      {
        path: 'mix-portfolio-gwp',
        component: SalesMixPortfolioGwpComponent,
        data: {
          id: 'sale-mix-portfolio-gwp',
          title: 'Sale Mix portfolio GWP',
          parentTitle: 'Sale',
          parentTitleT: 'panels.sales',
          animation: 'SalesMixPortfolioGwp'
        }
      },
      {
        path: 'quality-complaints',
        component: SalesQualityComplaintsComponent,
        data: {
          id: 'sale-quality-complaints',
          title: 'Sale Quality & complaints',
          parentTitle: 'Sale',
          parentTitleT: 'panels.sales',
          animation: 'SalesQualityComplaints'
        }
      },
    ]
  },
  {
    path: 'cancellation',
    resolve: { infoUser: UserInfoResolver },
    data: {
      id: 'cancellation',
      title: 'Cancellation',
      parentTitle: 'Cancellation',
      parentTitleT: 'panels.cancellation',
      animation: 'Cancellation'
    },
    children: [
      {
        path: 'fixed-stock',
        component: CancellationFixedStockComponent,
        data: {
          id: 'cancellation-fixed-stock',
          title: 'Cancellation fixed stock',
          parentTitle: 'Cancellation',
          parentTitleT: 'panels.cancellation',
          animation: 'CancellationFixedStock'
        }
      },
      {
        path: 'retention-according-to-record',
        component: CancellationRetentionAccordingToRecordComponent,
        data: {
          id: 'cancellation-retention-according-to-record',
          title: 'Cancellation retention according to record',
          parentTitle: 'Cancellation',
          parentTitleT: 'panels.cancellation',
          animation: 'CancellationRetentionAccordingToRecord'
        }
      },
      {
        path: 'retention',
        component: CancellationRetentionComponent,
        data: {
          id: 'cancellation-retention',
          title: 'Cancellation retention',
          parentTitle: 'Cancellation',
          parentTitleT: 'panels.cancellation',
          animation: 'CancellationRetention'
        }
      },
      {
        path: 'charge',
        component: CancellationChargeComponent,
        data: {
          id: 'cancellation-charge',
          title: 'Cancellation charge',
          parentTitle: 'Cancellation',
          parentTitleT: 'panels.cancellation',
          animation: 'CancellationCharge'
        }
      },
      {
        path: 'renewal',
        component: CancellationRenewalComponent,
        data: {
          id: 'cancellation-renewal',
          title: 'Cancellation renewal',
          parentTitle: 'Cancellation',
          parentTitleT: 'panels.cancellation',
          animation: 'CancellationRenewal'
        }
      },
      {
        path: 'persistence',
        component: CancellationPersistenceComponent,
        data: {
          id: 'cancellation-persistence',
          title: 'Cancellation persistence',
          parentTitle: 'Cancellation',
          parentTitleT: 'panels.cancellation',
          animation: 'CancellationPersistence'
        }
      },
      {
        path: 'details',
        component: CancellationDetailComponent,
        data: {
          id: 'cancellation-detail',
          title: 'Cancellation detail',
          parentTitle: 'Cancellation',
          parentTitleT: 'panels.cancellation',
          animation: 'CancellationDetail'
        }
      },
      {
        path: 'quality',
        component: CancellationQualityComponent,
        data: {
          id: 'cancellation-quality',
          title: 'Cancellation quality',
          parentTitle: 'Cancellation',
          parentTitleT: 'panels.cancellation',
          animation: 'CancellationQuality'
        }
      },
    ]
  },
  {
    path: 'sinisters',
    resolve: { infoUser: UserInfoResolver },
    data: {
      id: 'sinisters',
      title: 'Sinisters',
      parentTitle: 'Sinisters',
      parentTitleT: 'panels.sinisters',
      animation: 'Siniters'
    },
    children: [
      {
        path: 'details',
        component: SinistersDetailComponent,
        data: {
          id: 'sinisters-detail',
          title: 'Sinisters detail',
          parentTitle: 'Sinisters',
          parentTitleT: 'panels.sinisters',
          animation: 'SinitersDetail'
        }
      },
      {
        path: 'rejections',
        component: SinistersRejectionsComponent,
        data: {
          id: 'sinisters-rejections',
          title: 'Sinisters rejections',
          parentTitle: 'Sinisters rejections',
          parentTitleT: 'panels.sinisters',
          animation: 'SinitersRejections'
        }
      },
      {
        path: 'quality',
        component: SinistersQualityComponent,
        data: {
          id: 'sinisters-quality',
          title: 'Sinisters quality',
          parentTitle: 'Sinisters quality',
          parentTitleT: 'panels.sinisters',
          animation: 'SinitersQuality'
        }
      },
      {
        path: 'loss-ratio',
        component: SinistersLossRatioComponent,
        data: {
          id: 'sinisters-loss-ratio',
          title: 'Sinisters loss ratio',
          parentTitle: 'Sinisters loss ratio',
          parentTitleT: 'panels.sinisters',
          animation: 'SinitersLossRatio'
        }
      },
      {
        path: 'map',
        component: SinistersMapComponent,
        data: {
          id: 'sinisters-map',
          title: 'Sinisters map',
          parentTitle: 'Sinisters map',
          parentTitleT: 'panels.sinisters',
          animation: 'SinitersMap'
        }
      },


    ]
  },  
  { 
    path: 'slas', 
    component: SlasSectionComponent,
    resolve: { infoUser: UserInfoResolver },
    data: {
      id: 'slas',
      title: 'SLAs',
      parentTitle: 'SLAs',
      parentTitleT: 'panels.slas',
      animation: 'SlasSection'
    } 
  },
  {
    path: 'data-governance',
    resolve: { infoUser: UserInfoResolver },
    data: {
      id: 'data-governance-portal',
      title: 'Data governance',
      parentTitle: 'Data governance',
      parentTitleT: 'home.dataGovernanceTitle',
      animation: 'DataGovernanceSection'
    },
    children: [
      {
        path: 'portal',
        component: DataGovernanceHomeComponent,
        data: {
          id: 'data-governance-portal',
          title: 'Data governance portal',
          parentTitle: 'Data governance',
          parentTitleT: 'dataGovernanceSection.dataGovernance',
          animation: 'DataGovernanceSection'
        }
      },
      {
        path: 'business',
        component: DataGovernanceBusinessComponent,
        data: {
          id: 'data-governance-business',
          title: 'Data governance business area',
          parentTitle: 'Data governance business area',
          parentTitleT: 'dataGovernanceSection.dataGovernance',
          animation: 'DataGovernanceSection'
        }
      },
      {
        path: 'technical',
        component: DataGovernanceTechnicalComponent,
        data: {
          id: 'data-governance-technical',
          title: 'Data governance technical area',
          parentTitle: 'Data governance technical area',
          parentTitleT: 'dataGovernanceSection.dataGovernance',
          animation: 'DataGovernanceSection'
        }
      }
    ]
  },
  {
    path: 'clients',
    resolve: { infoUser: UserInfoResolver },
    data: {
      id: 'clients',
      title: 'Clients',
      parentTitle: 'Clients',
      parentTitleT: 'panels.clients',
      animation: 'Clients'
    },
    children: [
      {
        path: 'clients-portfolio',
        component: ClientsPortfolioComponent,
        data: {
          id: 'clients-portfolio',
          title: 'Clients portfolio',
          parentTitle: 'Clients',
          parentTitleT: 'panels.clients',
          animation: 'ClientsPortfolio'
        }
      },
      {
        path: 'clients-occupation',
        component: ClientsOccupationComponent,
        data: {
          id: 'clients-occupation',
          title: 'Clients occupation',
          parentTitle: 'Clients',
          parentTitleT: 'panels.clients',
          animation: 'ClientsOccupation'
        }
      },
      {
        path: 'clients-voc',
        component: ClientsVocComponent,
        data: {
          id: 'clients-voc',
          title: 'Clients VoC',
          parentTitle: 'Clients',
          parentTitleT: 'panels.clients',
          animation: 'ClientsVoc'
        }
      },
      {
        path: 'clients-internal-process-quality',
        component: ClientsInternalProcessQualityComponent,
        data: {
          id: 'clients-internal-process-quality',
          title: 'Clients internal process quality',
          parentTitle: 'Clients',
          parentTitleT: 'panels.clients',
          animation: 'ClientsInternalProcessQuality'
        }
      },
      {
        path: 'clients-penetration-segmentation',
        component: ClientsPenetrationSegmentationComponent,
        data: {
          id: 'clients-penetration-segmentation',
          title: 'Clients penetration & segmentation',
          parentTitle: 'Clients',
          parentTitleT: 'panels.clients',
          animation: 'ClientsPenetrationSegmentation'
        }
      },
      {
        path: 'clients-portfolio-distribution',
        component: ClientsPortfolioDistributionComponent,
        data: {
          id: 'clients-portfolio-distribution',
          title: 'Clients portfolio distribution',
          parentTitle: 'Clients',
          parentTitleT: 'panels.clients',
          animation: 'ClientsDistribution'
        }
      }
    ]
  },
  {
    path: 'data-discovery',
    resolve: { infoUser: UserInfoResolver },
    data: {
      id: 'data-discovery-portal',
      title: 'Data discovery',
      parentTitle: 'Data discovery',
      parentTitleT: 'home.dataDiscoveryTitle',
      animation: 'DataDiscoverySection'
    },
    children: [
      {
        path: 'portal',
        component: DataDiscoveryHomeComponent,
        data: {
          id: 'data-discovery-portal',
          title: 'Data discovery portal',
          parentTitle: 'Data discovery',
          parentTitleT: 'home.dataDiscoveryTitle',
          animation: 'DataDiscoveryPortal'
        }
      }
    ]
  },
  {
    path: 'reports',
    resolve: { infoUser: UserInfoResolver },
    data: {
      id: 'reports-portal',
      title: 'Reports',
      parentTitle: 'Reports',
      parentTitleT: 'home.reportsTitle',
      animation: 'ReportsSection'
    },
    children: [
      {
        path: 'portal',
        component: ReportsHomeComponent,
        data: {
          id: 'reports-portal',
          title: 'Reports portal',
          parentTitle: 'Reports discovery',
          parentTitleT: 'home.reportsTitle',
          animation: 'ReportsPortal'
        }
      }
    ]
  },
  {
    path: 'control-panel',
    component: ControlPanelComponent,
    resolve: { infoUser: UserInfoResolver },
    data: {
      id: 'control-panel',
      title: 'Control panel',
      parentTitle: 'Control panel',
      parentTitleT: 'home.managementTitle',
      animation: 'ControlPanelSection'
    }
  },

  {
    path: 'document-management',
    resolve: { infoUser: UserInfoResolver },
    component: DocumentManagementComponent,
    data: {
      id: 'document-management',
      title: 'Data governance',
      parentTitle: 'Data governance',
      parentTitleT: 'home.dataGovernanceTitle',
      animation: 'DataManagementSection'
    },
    children: [
      {
        path: 'portal',
        component: DocumentManagementHomeComponent,
        data: {
          id: 'document-management-portal',
          title: 'Data management portal',
          parentTitle: 'Data management home',
          parentTitleT: 'documentManagementSection.documentManagement',
          animation: 'DataManagementHomeSection',
        }
      },
      {
        path: 'file-manager',
        component: DocumentManagementFileManagerComponent,
        data: {
          id: 'document-management-file-manager',
          title: 'Data management file manager',
          parentTitle: 'Data management file manager',
          parentTitleT: 'documentManagementSection.fileManager',
          animation: 'DataManagementFileManagerSection',
        }
      },
      {
        path: 'metadata-editor',
        component: DocumentManagementMetadataEditorComponent,
        data: {
          id: 'document-management-metadata-editor',
          title: 'Data management metadata editor',
          parentTitle: 'Data management metadata editor',
          parentTitleT: 'documentManagementSection.metadataEditor',
          animation: 'DataManagementMetadataEditorSection',
        }
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

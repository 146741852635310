import { Component, OnInit, OnDestroy } from '@angular/core';
import { multi } from './data';
import { COLORS } from 'src/app/shared/globals/globals';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'
import { QualityRolling } from 'src/app/shared/models/quality-rolling';
import { EventsService } from 'src/app/shared/services/events/events.service'; 
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cancellation-quality-claims-graphic',
  templateUrl: './cancellation-quality-claims-graphic.component.html',
  styleUrls: ['./cancellation-quality-claims-graphic.component.scss']
})
export class CancellationQualityClaimsGraphicComponent implements OnInit, OnDestroy {

  multi: any[];
  view: any[] = [580, 180];

  // options
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  showGridLines: boolean = true;
  showRefLines: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  timeline: boolean = false;
  tooltipDisabled: boolean = false;
  yScaleMin: number = 80;
  yScaleMax: number = 100;
  rotateXAxisTicks: boolean = false;

  colorScheme = {
    domain: [COLORS.darkGreen, COLORS.lightGreen, '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;
  monthNames: any;
  averageName: string;
  claimsName: string;
  qualityClaimsRolling: any = [];
  loaded: boolean = false;
  qualityClaimSelected: any = [];
  xAxisTicksFinal: any = [];
  xAxisTicksValues: any = [];

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private eventsService: EventsService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const months = this.translateService.get('abbreviatedMonthNames');
          const average = this.translateService.get('labels.average');
          const claims = this.translateService.get('labels.claims');
      
          forkJoin([months, average, claims])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: results => {
                this.monthNames = results[0];
                this.averageName = results[1];
                this.claimsName = results[2];
              },
              complete: () => {
                if (data.length !== 0) {
                  this.filtersValues = data;
                  this.retrieveQualityClaims(this.qualityClaimSelected, this.filtersValues);
                }
              }
            });
        }
      );

    this.eventsService.salesQualityClaims$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(data => {
      this.qualityClaimSelected = [];
      if (data.value !== '') {
        this.qualityClaimSelected.push(data.value);
      }
      this.retrieveQualityClaims(this.qualityClaimSelected, this.filtersValues);
    });  
  }

  // Method to retrieve the quality claim data
  private retrieveQualityClaims(qualityClaimSelected: [], filtersValues: any) {
    this.loaded = false;
    this.apimstService.getCancellationQualityClaimsRolling(qualityClaimSelected, filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data: Array<QualityRolling>) => {
          let rateSeries: any = [];
          let avgRateSeries: any = [];
          this.qualityClaimsRolling = [];
          this.xAxisTicksValues = [];
          this.xAxisTicksFinal = [];
          for(let month of data) {
            rateSeries.push({
              name: this.monthNames[month.month] + ' ' + month.year.slice(2, 4),
              value: month.rate ? parseFloat(month.rate) : 0
            });

            avgRateSeries.push({
              name: this.monthNames[month.month] + ' ' + month.year.slice(2, 4),
              value: month.rate_avg ? parseFloat(month.rate_avg) : 0
            });
            this.xAxisTicksValues.push(this.monthNames[month.month] + ' ' + month.year.slice(2, 4));
            this.getXAxisValues();
          }

          this.qualityClaimsRolling.push(
            {
              name: this.claimsName,
              series: rateSeries
            },
            {
              name: this.averageName,
              series: avgRateSeries
            }
          );
          this.loaded = true;
        },

        error => console.log("An error ocurred while retrieving quality claims rolling data: " + error)


      );
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // Method to format the y axis ticks with %
  yAxisTickFormatting(val) {
    return val.toLocaleString() + "%";
  }

  // Method to get only one x axis value in two 
  private getXAxisValues(): void {
    this.xAxisTicksFinal = [];
    for (var _i = 0; _i < this.xAxisTicksValues.length; _i += 2) {
      this.xAxisTicksFinal.push(this.xAxisTicksValues[_i]);
    } 
  }

}

import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiAtlasService } from 'src/app/shared/services/api-atlas/api-atlas.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';

@Component({
  selector: 'app-portal-main-block',
  templateUrl: './portal-main-block.component.html',
  styleUrls: ['./portal-main-block.component.scss']
})
export class PortalMainBlockComponent implements OnInit {

  unsubscribe$: Subject<any> = new Subject<any>();
  orderedCategories: any = [];
  activeLanguage: string;
  technicalArea: string = 'dataGovernanceSection.technicalArea';
  businessArea: string = 'dataGovernanceSection.businessArea';
  selectedArea: string;
  notSelectedArea: string;

  technicalSections = [
    {
      order: 1,
      title: 'dataGovernanceTechnicalArea.reports',
      titleColor: '#FFFFFF',
      pipeColor: '#00C9A8',
      backgroundColor: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.12) 100%), rgba(8, 52, 87, 0.5)',
      image: 'assets/icons/calculator.svg',
      id: "report"
    },
    {
      order: 2,
      title: 'dataGovernanceTechnicalArea.tables',
      titleColor: '#203440',
      pipeColor: '#5A01CB',
      backgroundColor: 'linear-gradient(0deg, rgba(8, 52, 87, 0.1), rgba(8, 52, 87, 0.1)), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.12) 100%)',
      image: 'assets/icons/table.svg',
      id: "table"
    },
    {
      order: 3,
      title: 'dataGovernanceTechnicalArea.attributes',
      titleColor: '#FFFFFF',
      pipeColor: '#C900B5',
      backgroundColor: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.12) 100%), #406880',
      image: 'assets/icons/cube.svg',
      id: "attribute"
    },
    {
      order: 4,
      title: 'dataGovernanceTechnicalArea.metrics',
      titleColor: '#FFFFFF',
      pipeColor: '#C90000',
      backgroundColor: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.12) 100%), rgba(8, 52, 87, 0.9)',
      image: 'assets/icons/metrics.svg',
      id: "metric"
    },
    {
      order: 5,
      title: 'dataGovernanceTechnicalArea.facts',
      titleColor: '#FFFFFF',
      pipeColor: '#C9B500',
      backgroundColor: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.12) 100%), rgba(8, 52, 87, 0.5)',
      image: 'assets/icons/facts.svg',
      id: "fact"
    }
  ];

  constructor(
    private apiAtlasService: ApiAtlasService,
    private eventsService: EventsService,
    private i18n: I18nService
  ) { }

  ngOnInit(): void {
    this.selectedArea = this.businessArea;
    this.notSelectedArea = this.technicalArea;
    this.subscribeToActiveLanguage();
  }

  private subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLanguage = language;
        this.getDashboardCategories();
      }
    })
  }

  private getDashboardCategories() {
    this.apiAtlasService.getDashboardCategories(this.activeLanguage)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        const unorderedCategories = data;
        if (Object.keys(unorderedCategories).length !== 0) {
          this.orderedCategories = unorderedCategories.sort((a,b) => {
            if (a.order < b.order) {
              return -1;
            } else {
              return 1;
            }
          });
          this.eventsService.dataGovernancePortalLoaded$.emit(true);
        }
      });
  }

  changeArea() {
    if (this.selectedArea === this.businessArea) {
      this.selectedArea = this.technicalArea;
      this.notSelectedArea = this.businessArea;
    } else {
      this.selectedArea = this.businessArea;
      this.notSelectedArea = this.technicalArea;
    }
  }

  // selectTechnicalSection(id: string) {
  //   this.eventsService.dataGovernanceTechnicalSection$.emit(id);
  // }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'velocimeter',
  templateUrl: './velocimeter.component.html',
  styleUrls: ['./velocimeter.component.scss']
})
export class VelocimeterComponent implements OnInit {

  @Input() viewBox;
  @Input() strokeWidth: number;
  @Input() target: number;
  @Input() currentValue: number;
  @Input() mainValue: number;
  @Input() secondValue: number;
  @Input() diff: number;
  @Input() trend: string;
  @Input() leftValue: number = null;
  @Input() leftPercentage;  
  @Input() hasInfo;
  @Input() hAlign: string;
  @Input() vAlign: string;
  @Input() infoTitle: string;
  @Input() infoPercentage: number;
  @Input() infoActualValue: string;
  @Input() infoTotalValue: string;
  @Input() barOrientation: number;
  @Input() showSecondValue: boolean = true;

  percentageTemp: number;
  infoPercentageValue: number;

  constructor() { }

  ngOnInit(): void {
    // this.percentageTemp = 142 - (this.percentage * 142 / 100)
    if (this.barOrientation === 0) {
      this.percentageTemp = 0 - (this.target * 71 / 90);
    } else {
      this.percentageTemp = 142 - (this.target * 71 / 90);
    }
    
    this.target = this.percentageTemp;


  }

}

export var tableInfo = [
    {
        values: [
            { name: "Ene20" },
            { name: "515" },
            { name: "50" },
            { name: "15,4" },
            { name: "33,5" },
            { name: "4,50" },
            { name: "51,5" },
            { name: "11,5" },
            { name: "7,4" },
            { name: "80,02" },
            { name: "9,5" },
            { name: "10,52" },
            { name: "23,64" },
            { name: "12" },
            { name: "40" },
            { name: "50,12" },
            { name: "11" },
            { name: "45,45" },
            { name: "56" },
            { name: "88,98" },
            { name: "55,41" },
            { name: "12,31" },
            { name: "21,12" },
            { name: "52,3" },
            { name: "5,2" },
            { name: "10,6" }
        ]
    },
    {
        values: [
            { name: "Feb20" },
            { name: "800" },
            { name: "50" },
            { name: "15,4" },
            { name: "33,5" },
            { name: "4,50" },
            { name: "51,5" },
            { name: "11,5" },
            { name: "7,4" },
            { name: "80,02" },
            { name: "9,5" },
            { name: "10,52" },
            { name: "23,64" },
            { name: "12" },
            { name: "40" },
            { name: "50,12" },
            { name: "11" },
            { name: "45,45" },
            { name: "56" },
            { name: "88,98" },
            { name: "55,41" },
            { name: "12,31" },
            { name: "21,12" },
            { name: "52,3" },
            { name: "5,2" },
        ]
    },
    {
        values: [
            { name: "Mar20" },
            { name: "245" },
            { name: "50" },
            { name: "15,4" },
            { name: "33,5" },
            { name: "4,50" },
            { name: "51,5" },
            { name: "11,5" },
            { name: "7,4" },
            { name: "80,02" },
            { name: "9,5" },
            { name: "10,52" },
            { name: "23,64" },
            { name: "12" },
            { name: "40" },
            { name: "50,12" },
            { name: "11" },
            { name: "45,45" },
            { name: "56" },
            { name: "88,98" },
            { name: "55,41" },
            { name: "12,31" },
            { name: "21,12" },
            { name: "52,3" },
        ]
    },
    {
        values: [
            { name: "Abr20" },
            { name: "380" },
            { name: "50" },
            { name: "15,4" },
            { name: "33,5" },
            { name: "4,50" },
            { name: "51,5" },
            { name: "11,5" },
            { name: "7,4" },
            { name: "80,02" },
            { name: "9,5" },
            { name: "10,52" },
            { name: "23,64" },
            { name: "12" },
            { name: "40" },
            { name: "50,12" },
            { name: "11" },
            { name: "45,45" },
            { name: "56" },
            { name: "88,98" },
            { name: "55,41" },
            { name: "12,31" },
            { name: "21,12" },
        ]
    },
    {
        values: [
            { name: "May20" },
            { name: "214" },
            { name: "50" },
            { name: "15,4" },
            { name: "33,5" },
            { name: "4,50" },
            { name: "51,5" },
            { name: "11,5" },
            { name: "7,4" },
            { name: "80,02" },
            { name: "9,5" },
            { name: "10,52" },
            { name: "23,64" },
            { name: "12" },
            { name: "40" },
            { name: "50,12" },
            { name: "11" },
            { name: "45,45" },
            { name: "56" },
            { name: "88,98" },
            { name: "55,41" },
            { name: "12,31" },
        ]
    },
    {
        values: [
            { name: "Jun20" },
            { name: "235" },
            { name: "50" },
            { name: "15,4" },
            { name: "33,5" },
            { name: "4,50" },
            { name: "51,5" },
            { name: "11,5" },
            { name: "7,4" },
            { name: "80,02" },
            { name: "9,5" },
            { name: "10,52" },
            { name: "23,64" },
            { name: "12" },
            { name: "40" },
            { name: "50,12" },
            { name: "11" },
            { name: "45,45" },
            { name: "56" },
            { name: "88,98" },
            { name: "55,41" },
        ]
    },
    {
        values: [
            { name: "Jul20" },
            { name: "235" },
            { name: "50" },
            { name: "15,4" },
            { name: "33,5" },
            { name: "4,50" },
            { name: "51,5" },
            { name: "11,5" },
            { name: "7,4" },
            { name: "80,02" },
            { name: "9,5" },
            { name: "10,52" },
            { name: "23,64" },
            { name: "12" },
            { name: "40" },
            { name: "50,12" },
            { name: "11" },
            { name: "45,45" },
            { name: "56" },
            { name: "88,98" },
        ]
    },
    {
        values: [
            { name: "Ago20" },
            { name: "235" },
            { name: "50" },
            { name: "15,4" },
            { name: "33,5" },
            { name: "4,50" },
            { name: "51,5" },
            { name: "11,5" },
            { name: "7,4" },
            { name: "80,02" },
            { name: "9,5" },
            { name: "10,52" },
            { name: "23,64" },
            { name: "12" },
            { name: "40" },
            { name: "50,12" },
            { name: "11" },
            { name: "45,45" },
            { name: "56" },
        ]
    },
    {
        values: [
            { name: "Sep20" },
            { name: "235" },
            { name: "50" },
            { name: "15,4" },
            { name: "33,5" },
            { name: "4,50" },
            { name: "51,5" },
            { name: "11,5" },
            { name: "7,4" },
            { name: "80,02" },
            { name: "9,5" },
            { name: "10,52" },
            { name: "23,64" },
            { name: "12" },
            { name: "40" },
            { name: "50,12" },
            { name: "11" },
            { name: "45,45" },
        ]
    },
    {
        values: [
            { name: "Oct20" },
            { name: "235" },
            { name: "50" },
            { name: "15,4" },
            { name: "33,5" },
            { name: "4,50" },
            { name: "51,5" },
            { name: "11,5" },
            { name: "7,4" },
            { name: "80,02" },
            { name: "9,5" },
            { name: "10,52" },
            { name: "23,64" },
            { name: "12" },
            { name: "40" },
            { name: "50,12" },
            { name: "11" },
        ]
    }
]

export var response = [
    {
        "ref_date": "2020-02",
        "year": "2020",
        "month": "02",
        "month_order": "2",
        "value": "4.15%"
    },
    {
        "ref_date": "2020-02",
        "year": "2020",
        "month": "03",
        "month_order": "3",
        "value": "18.82%"
    },
    {
        "ref_date": "2020-02",
        "year": "2020",
        "month": "04",
        "month_order": "4",
        "value": "21.67%"
    },
    {
        "ref_date": "2020-03",
        "year": "2020",
        "month": "02",
        "month_order": "2",
        "value": "14.15%"
    },
    {
        "ref_date": "2020-03",
        "year": "2020",
        "month": "03",
        "month_order": "3",
        "value": "58.82%"
    },
    {
        "ref_date": "2020-03",
        "year": "2020",
        "month": "04",
        "month_order": "4",
        "value": "71.67%"
    }
]
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  // Inputs
  technicalInputTermChanged$ = new EventEmitter<string>();

  // Filters
  showFilters$ = new EventEmitter<boolean>();
  filtersUpdated$ = new EventEmitter<boolean>();
  monthlySaleYearDataUpdated$ = new EventEmitter<boolean>();
  filterDropdownEvent$ = new EventEmitter<object>();

  // Menu
  showMenu$ = new EventEmitter<boolean>();

  // Tabs
  importReceiptsOption$ = new EventEmitter<string>();
  optionTabMultipleSelected$ = new EventEmitter<object>();
  optionTabMultipleChanged$ = new BehaviorSubject<object>([]);
  policiesBonusOption$ = new EventEmitter<any>();
  searchOptionSelected$ = new EventEmitter<Object>();

  // Title
  headerTitle$ = new EventEmitter<string>();

  // Dropdowns
  salesQualityClaims$ = new EventEmitter<any>();
  salesQualityNPS$ = new EventEmitter<any>();
  sinistersQualityClaims$ = new EventEmitter<any>();
  sinistersQualityNPS$ = new EventEmitter<any>();
  retentionReasonPersistance$ = new EventEmitter<any>();
  ageReasons$ = new Subject<any>();
  sinisterRejectioReason$ = new BehaviorSubject<any[]>([]);
  salesMainType$ = new BehaviorSubject<any[]>([]);
  clientsQualityClaims$ = new BehaviorSubject<string>("");
  clientsSurveys$ = new BehaviorSubject<string>("");
  multiDropdownEvent$ = new BehaviorSubject<any>({ item: {}, items: [] });
  clientsSantanderSegmentation$ = new BehaviorSubject<any>([]);
  clientsZurichSegmentation$ = new BehaviorSubject<any>([]);
  clientsSantanderSegmentationPieChart$ = new BehaviorSubject<any>([]);

  // Login
  loginRedirectionSubject$ = new BehaviorSubject<boolean>(false);
  loginRedirection$ = new EventEmitter<boolean>();
  userInfoLoaded$ = new BehaviorSubject<any>({});
  userInfoLoadedSubject$ = new Subject<any>();
  authenticationOk$ = new EventEmitter<boolean>();
  
  // Cancellation Reasons
  cancellationReasons$ = new EventEmitter<any>();

  // SLAs
  targetsType$ = new EventEmitter<any>();
  
  // Cancellation persistance
  cancellationTotalPersistance$ = new EventEmitter<any>();
  cancellationNewPersistance$ = new EventEmitter<any>();
  cancellationWithheldPersistance$ = new EventEmitter<any>();
  cancellationPostRetentionPersistance$ = new EventEmitter<any>();
  cancellationMonthlyPersistence$ = new EventEmitter<any>();
  fixedStockPersistence$ = new EventEmitter<any>();
  sinistersFrequencyPerMonth$ = new EventEmitter<any>();

  // Monthly sale product type
  monthlySaleProductType$ = new EventEmitter<any>();
  
  // Force check login
  forceCheckLogin$ = new EventEmitter<any>();

  // Excel events
  monthlySaleTableExcelButton$ = new EventEmitter<boolean>();
  gwpExcelInfoLoaded$ = new BehaviorSubject<any>({});
  salesClaimsExcelInfoLoaded$ = new BehaviorSubject<any>({});
  salesTnpsExcelInfoLoaded$ = new BehaviorSubject<any>({});
  retentionPerActionExcelInfoLoaded$ = new BehaviorSubject<any>({});
  retentionPerActionRecordExcelInfoLoaded$ = new BehaviorSubject<any>({});
  chargeActivityExcelInfoLoaded$ = new BehaviorSubject<any>({});
  chargeEffectivenessExcelInfoLoaded$ = new BehaviorSubject<any>({});
  evolutionaryRenewalExcelInfoLoaded$ = new BehaviorSubject<any>({});
  persistenceTotalExcelInfoLoaded$ = new BehaviorSubject<any>({});
  persistencePostretentionExcelInfoLoaded$ = new BehaviorSubject<any>({});
  persistenceNewSaleExcelInfoLoaded$ = new BehaviorSubject<any>({});
  persistenceMonthlyExcelInfoLoaded$ = new BehaviorSubject<any>({});
  fixedStockPersistenceExcelInfoLoaded$ = new BehaviorSubject<any>({});
  sinistersFrequencyPerMonthExcelInfoLoaded$ = new BehaviorSubject<any>({});
  clientsStockExcelInfoLoaded$ = new BehaviorSubject<any>({});

  // Refresh filters
  refreshFilters$ = new EventEmitter<boolean>();

  // Horizontal funnel graph - sinister state check event
  sinisterFunnelStateChecked$ = new EventEmitter<any>();

  // Horizontal funnel graph - sinister substate check event
  sinisterFunnelSubStateChecked$ = new EventEmitter<any>();
  
  // HTML editor
  cancelHtmlTextEdition$ = new EventEmitter<any>();
  saveHtmlTextEdition$ = new EventEmitter<any>();
  updateBusinessInformation$ = new EventEmitter<any>();

  // Panels loading
  rejectionsLoading$ = new EventEmitter<boolean>(true);
  pendingAgeLoading$ = new EventEmitter<boolean>(true);

  // Search event
  openSearchPanel$ = new EventEmitter<boolean>();
  searchAreaType$ = new EventEmitter<boolean>();

  // Data governance portal
  dataGovernancePortalLoaded$ = new EventEmitter<boolean>();

  // Data governance business section
  categoryDGBusinessDashboard$ = new EventEmitter<any>();

  // Data governance info dialog section
  dataGovernanceInfoAvailable$ = new EventEmitter<boolean>();

  // Data governance technical section
  dataGovernanceTechnicalSection$ = new EventEmitter<any>();
  filteredTechnicalResults$ = new EventEmitter<any>();

  // Network graph node selection event
  networkGraphSelectedNode$ = new EventEmitter<any>();

  // Table color zoom event
  tableColorZoom$ = new EventEmitter<any>();

  // Document management user panel
  showUserPanel$ = new EventEmitter<boolean>();

  // Drag&Drop event
  filesDropped$ = new EventEmitter<any>();

  // Monthly & daily sales graphic custom colors event
  dailySaleGraphicCustomColor$ = new EventEmitter<any>();
  monthlySaleGraphicCustomColor$ = new EventEmitter<any>();
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { utils } from 'protractor';
import { combineLatest, forkJoin, Subject, Subscription } from 'rxjs';
import { shareReplay, take, takeUntil } from 'rxjs/operators';
import { COLORS } from 'src/app/shared/globals/globals';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { RejectionsMainService } from '../rejections-main.service';
import { multi } from './data';

@Component({
  selector: 'app-rejections-line-graph',
  templateUrl: './rejections-line-graph.component.html',
  styleUrls: ['./rejections-line-graph.component.scss']
})
export class RejectionsLineGraphComponent implements OnInit {
  view: any[] = [580, 180];

  compareDateLabel: string = "";
  actualDateLabel: string = "";

  rawInfo: any;

  subscriptionRequest: Subscription;

  selectedOption = "rejectionsAmount";
  selectedGraph = "sinistersRejections";
  selectedReason: any[] = [];

  type_data: string;
  type_report: string;
  rejection_reason: any[];

  // options
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  showGridLines: boolean = true;
  showRefLines: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  yScaleMax: number = 100;
  timeline: boolean = false;
  tooltipDisabled: boolean = false;
  rotateXAxisTicks: boolean = false;

  colorScheme = {
    domain: [COLORS.darkGreen, COLORS.darkBlue, COLORS.lightGreen, COLORS.darkYellow, COLORS.lightPurple ]
  };

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;
  monthNames: any;

  technicalRejectionTaxName: string = "";
  administrativeName: string = "";
  paymentZeroName: string = "";
  withdrawalName: string = "";
  fraudName: string = "";

  averageName: string;
  claimsName: string;
  claimReasonName: string;
  settledInTimeName: string;
  settledInTimeSinistersName: string;
  settledInTimeAvgSinistersName: string;
  sinistersClaimsRolling: any = [];
  loaded: boolean = false;
  qualityClaimSelected: any = [];
  xAxisTicksFinal: any = [];
  xAxisTicksValues: any = [];

  sinistersRejections: any = [];

  data_total: any[] = [];
  data_sheet_names: any[] = [];
  data_filter_details: any[] = [];
  claimReason: string = 'Todas';

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private excelService: ExcelService,
    private events: EventsService,
    private rejectionsMainService: RejectionsMainService
  ) { }

  ngOnInit(): void {

    const filters$ = this.filtersService.filtersValues$;
    const selectedOption$ = this.rejectionsMainService.panelSelectedOption$;

    combineLatest([filters$, selectedOption$])
      .pipe(shareReplay(), takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.loaded = false;
          this.sinistersRejections = [];
          this.resetValues();
          this.getTranslations();

          this.filtersValues = data[0];
          if (this.filtersValues.length == 0) return;

          this.getSelectedOption(data[1]);
          this.retrieveRejectionsGraph("s", this.filtersValues);
        }
      )
  }

  getSelectedOption(option: any = {}): void {
      if (option['id'] == "rejectionsAmount") this.type_data = 'a'
      if (option['id'] == "rejectionsSinisters") this.type_data = 's'
  }

  getSelectedReason(reason: any[] = []): void {
    this.rejection_reason = reason[0] != "-999" ? reason : [];
  }

  getTranslations() {
    const monthsTranslation = this.translateService.get('abbreviatedMonthNames');
    const technicalRejectionTaxTranslation = this.translateService.get('panels.technicalRejectionTax');
    const administrativeTranslation = this.translateService.get('panels.administrative');
    const paymentZeroTranslation = this.translateService.get('panels.paymentZero');
    const withdrawalTranslation = this.translateService.get('panels.withdrawal');
    const fraudTranslation = this.translateService.get('panels.fraud');

    forkJoin([monthsTranslation, technicalRejectionTaxTranslation, administrativeTranslation, paymentZeroTranslation, withdrawalTranslation, fraudTranslation])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: results => {
          this.monthNames = results[0];
          this.technicalRejectionTaxName = results[1];
          this.administrativeName = results[2];
          this.paymentZeroName = results[3];
          this.withdrawalName = results[4];
          this.fraudName = results[5];
        }
      });
  }

  retrieveRejectionsGraph(type_data: string = "", filtersValues: any): void {
    this.loaded = false;

    this.apimstService.getSinistersRejections(type_data, 'sre', filtersValues)
      .pipe(
        shareReplay(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: graphData => {
          this.loaded = false;
          this.rawInfo = graphData;

          if (this.rawInfo == null) { this.loaded = true; return; }
          this.createGraph();

          this.loaded = true;
        }
      })
  }

  createGraph() {
    const technicalRestrictionRaw = this.rawInfo.filter(e => e.name == "Restricción técnica")
    const administrativeRaw = this.rawInfo.filter(e => e.name == "Administrativo");
    const zeroPaymentRaw = this.rawInfo.filter(e => e.name == "Pago 0")
    const withdrawalRaw = this.rawInfo.filter(e => e.name == "Desistimiento")
    const fraudRaw = this.rawInfo.filter(e => e.name == "Fraude")

    let technicalRestrictionRawSeries: any = { name: this.technicalRejectionTaxName, series: [] }
    let administrativeSeries: any = { name: this.administrativeName, series: [] }
    let zeroPaymentSeries: any = { name: this.paymentZeroName, series: [] }
    let withdrawalSeries: any = { name: this.withdrawalName, series: [] }
    let fraudSeries: any = { name: this.fraudName, series: [] }

    for (let e of technicalRestrictionRaw) {
      technicalRestrictionRawSeries.series.push({
        name: this.monthNames[e.month] + e.year.slice(2, 4),
        value: e.value ? parseFloat(e.value) : 0
      })
    }

    for (let e of zeroPaymentRaw) {
      zeroPaymentSeries.series.push({
        name: this.monthNames[e.month] + e.year.slice(2, 4),
        value: e.value ? parseFloat(e.value) : 0
      })
    }

    for (let e of administrativeRaw) {
      administrativeSeries.series.push({
        name: this.monthNames[e.month] + e.year.slice(2, 4),
        value: e.value ? parseFloat(e.value) : 0
      })
    }

    for (let e of withdrawalRaw) {
      withdrawalSeries.series.push({
        name: this.monthNames[e.month] + e.year.slice(2, 4),
        value: e.value ? parseFloat(e.value) : 0
      })
    }

    if( fraudRaw.length > 0 ) {
      for (let e of fraudRaw) {
        fraudSeries.series.push({
          name: this.monthNames[e.month] + e.year.slice(2, 4),
          value: e.value ? parseFloat(e.value) : 0
        })
      }
    } else {
      for (let e of technicalRestrictionRaw) {
        fraudSeries.series.push({
          name: this.monthNames[e.month] + e.year.slice(2, 4),
          value: 0
        })
      }
    }

    this.sinistersRejections = [
      technicalRestrictionRawSeries,
      administrativeSeries,
      zeroPaymentSeries,
      withdrawalSeries,
      fraudSeries
    ];
  }

  handleInfo(rawInfo: any) {
    return rawInfo;
  }

  createLineGraph(rawInfo: any) {
    this.sinistersClaimsRolling = multi;
  }

  resetValues() {
    this.sinistersRejections = [];
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  yAxisTickFormatting(val) {
    return val.toLocaleString() + "%";
  }

  exportAsXLSX(): void {
    this.data_sheet_names = [this.claimsName];
    this.excelService.exportAsExcelFile(this.data_total, this.data_sheet_names, 'sinisters_quality_chart', this.data_filter_details);
  }

}

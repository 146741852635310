import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'magnitudeOrder' })
export class MagnitudeOrderPipe implements PipeTransform {

    // Variable with the magnitude ordres   
    magnitudeOrder = [
        { value: 1e12, letter: 'B' },
        { value: 1e9, letter: 'MM' },
        { value: 1e6, letter: 'M' },
        { value: 1e3, letter: 'K' }
    ];

    transform(value: number): string {
        for (const order of this.magnitudeOrder) {
            if (Math.abs(value) >= order.value) {
                const finalValue = parseFloat((value / order.value).toFixed(1)) ;
                return (Intl.NumberFormat("de-DE").format(finalValue) + order.letter);
            }
        }

        return (Intl.NumberFormat("de-DE").format(value));

    }
}
<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex">
            <span class="title section-card-title">{{ 'panels.cancellationCuality' | translate }}</span>
            <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams"
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </div>
    </div>

    <div class="mt-n2 mb-2 d-flex justify-content-center align-items-end">
        <component-loader *ngIf="!qualityEarlyFallIndicatorLoaded" class="mx-5 text-center" [width]="150" [height]="100"></component-loader>
        <app-kpi-color *ngIf="qualityEarlyFallIndicatorLoaded" class="mx-6 text-center" [title]="'panels.earlyFallWithMonths'" [mainValue]="qualityEarlyFallIndicator?.value" [mainValueUnit]="'%'" [percentagePrevious]="percentagePrevious" [percentage]="qualityEarlyFallIndicator?.diff" [trend]="qualityEarlyFallIndicator?.diff_trend"></app-kpi-color>
        
        <component-loader *ngIf="!qualityCanceledPolicyDurationIndicatorLoaded" class="mx-5 text-center" [width]="150" [height]="100"></component-loader>
        <app-kpi-color *ngIf="qualityCanceledPolicyDurationIndicatorLoaded" class="mx-6 text-center" [title]="qualityCanceledPolicyDurationIndicatorTitle" [mainValue]="qualityCanceledPolicyDurationIndicator?.value" [mainValueUnit]="' ' + qualityCanceledPolicyDurationIndicator?.unit" [percentagePrevious]="percentagePrevious" [percentage]="qualityCanceledPolicyDurationIndicator?.diff" [trend]="qualityCanceledPolicyDurationIndicator?.diff_trend"></app-kpi-color>
        
        <component-loader *ngIf="!qualityPortolioProyectedDurationIndicatorLoaded" class="mx-5 text-center" [width]="150" [height]="100"></component-loader>
        <app-kpi-color *ngIf="qualityPortolioProyectedDurationIndicatorLoaded" class="mx-6 text-center" [title]="qualityPortolioProyectedDurationIndicatorTitle" [mainValue]="qualityPortolioProyectedDurationIndicator?.value" [mainValueUnit]="' ' + qualityPortolioProyectedDurationIndicator?.unit" [percentagePrevious]="percentagePrevious" [percentage]="qualityPortolioProyectedDurationIndicator?.diff" [trend]="qualityPortolioProyectedDurationIndicator?.diff_trend"></app-kpi-color>
    </div>

</app-widget>
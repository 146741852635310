<div class="mt-3">
    <component-loader [width]="" [height]="540" *ngIf="!loaded"></component-loader>
    <div class="table-grid" *ngIf="loaded">
        <table class="phone-attention-table table">
            <thead>
                <tr class="table-header">
                    <th colspan="2">Atención telefónica</th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-body">
                    <td>
                        <app-small-kpi 
                            [title]="dropoutRateAttentionTitle"
                            [mainValue]="dropoutRateAttentionMainValue"
                            [mainValueUnit]="dropoutRateAttentionMainValueUnit"
                            [trend]="dropoutRateAttentionTrend"
                            [percentage]="dropoutRateAttentionPercentage"
                        ></app-small-kpi>
                    </td>
                    <td>
                        <app-small-kpi 
                            [title]="dropoutRateSinistersTitle"
                            [mainValue]="dropoutRateSinistersMainValue"
                            [mainValueUnit]="dropoutRateSinistersMainValueUnit"
                            [trend]="dropoutRateSinistersTrend"
                            [percentage]="dropoutRateSinistersPercentage"
                        ></app-small-kpi>
                    </td>
                </tr>
                <tr class="table-body">
                    <td>
                        <app-small-kpi 
                            [title]="serviceLevelAttentionTitle"
                            [mainValue]="serviceLevelAttentionMainValue"
                            [mainValueUnit]="serviceLevelAttentionMainValueUnit"
                            [trend]="serviceLevelAttentionTrend"
                            [percentage]="serviceLevelAttentionPercentage"
                        ></app-small-kpi>
                    </td>
                    <td>
                        <app-small-kpi 
                            [title]="serviceLevelSinistersTitle"
                            [mainValue]="serviceLevelSinistersMainValue"
                            [mainValueUnit]="serviceLevelSinistersMainValueUnit"
                            [trend]="serviceLevelSinistersTrend"
                            [percentage]="serviceLevelSinistersPercentage"
                        ></app-small-kpi>
                    </td>
                </tr>
            </tbody>
        </table>
    
        <table class="sinisters-table table">
            <thead>
                <tr class="table-header">
                    <th colspan="2">Siniestros</th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-body">
                    <td>
                        <app-small-kpi 
                            [title]="rejectionsTitle"
                            [mainValue]="rejectionsMainValue"
                            [mainValueUnit]="rejectionsMainValueUnit"
                            [trend]="1"
                        ></app-small-kpi>
                    </td>
                </tr>
                <tr class="table-body">
                    <td>
                        <app-small-kpi 
                            [title]="processingTimeTitle"
                            [mainValue]="processingTimeMainValue"
                            [mainValueUnit]="processingTimeMainValueUnit"
                            [trend]="processingTimeTrend"
                            [percentage]="processingTimePercentage"
                        ></app-small-kpi>
                    </td>
                </tr>
            </tbody>
        </table>
    
        <table class="sale-table table">
            <thead>
                <tr class="table-header">
                    <th colspan="2">Venta</th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-body">
                    <td>
                        <app-small-kpi 
                            [title]="earlyFallTitle"
                            [mainValue]="earlyFallMainValue"
                            [mainValueUnit]="earlyFallMainValueUnit"
                            [trend]="earlyFallTrend"
                            [percentage]="earlyFallPercentage"
                        ></app-small-kpi>
                    </td>
                </tr>
            </tbody>
        </table>
    
        <table class="policies-table table">
            <thead>
                <tr class="table-header">
                    <th colspan="2">Admin. Pólizas</th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-body">
                    <td>
                        <app-small-kpi 
                            [title]="endorsementsRateTitle"
                            [mainValue]="endorsementsRateMainValue"
                            [mainValueUnit]="endorsementsRateMainValueUnit"
                            [trend]="endorsementsRateTrend"
                            [percentage]="endorsementsRatePercentage"
                        ></app-small-kpi>
                    </td>
                </tr>
                <tr class="table-body">
                    <td>
                        <app-small-kpi 
                            [title]="doubledRateTitle"
                            [mainValue]="doubledRateMainValue"
                            [mainValueUnit]="doubledRateMainValueUnit"
                            [trend]="doubledRateTrend"
                            [percentage]="doubledRatePercentage"
                        ></app-small-kpi>
                    </td>
                </tr>
                <tr class="table-body">
                    <td>
                        <app-small-kpi 
                            [title]="renewalRateTitle"
                            [mainValue]="renewalRateMainValue"
                            [mainValueUnit]="renewalRateMainValueUnit"
                            [trend]="renewalRateTrend"
                            [percentage]="renewalRatePercentage"
                        ></app-small-kpi>
                    </td>
                </tr>
            </tbody>
        </table>
    
        <table class="policies-table table">
            <thead>
                <tr class="table-header">
                    <th colspan="2">Asistencia</th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-body">
                    <td>
                        <app-small-kpi 
                            [title]="averageTimeAttendanceTitle"
                            [mainValue]="averageTimeAttendanceMainValue"
                            [mainValueUnit]="averageTimeAttendanceMainValueUnit"
                            [trend]="averageTimeAttendanceTrend"
                            [percentage]="averageTimeAttendancePercentage"
                        ></app-small-kpi>
                    </td>
                </tr>
                <tr class="table-body">
                    <td>
                        <app-small-kpi 
                            [title]="assistsConsumedPercentageTitle"
                            [mainValue]="assistsConsumedPercentageMainValue"
                            [mainValueUnit]="assistsConsumedPercentageMainValueUnit"
                            [trend]="assistsConsumedPercentageTrend"
                            [percentage]="assistsConsumedPercentagePercentage"
                        ></app-small-kpi>
                    </td>
                </tr>
                <tr class="table-body">
                    <td>
                        <app-small-kpi 
                            [title]="attendanceWaiversPercentageTitle"
                            [mainValue]="attendanceWaiversPercentageMainValue"
                            [mainValueUnit]="attendanceWaiversPercentageMainValueUnit"
                            [trend]="attendanceWaiversPercentageTrend"
                            [percentage]="attendanceWaiversPercentagePercentage"
                        ></app-small-kpi>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
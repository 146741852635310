<div [formGroup]="form">
    <label [attr.for]="metadata.key">{{ metadata.label | translate }} <sup *ngIf="metadata.required">*</sup> </label>
  
    <div [ngSwitch]="metadata.controlType">
  
      <input *ngSwitchCase="'textbox'" [formControlName]="metadata.key"
              [id]="metadata.key" [type]="metadata.type">
  
      <select [id]="metadata.key" *ngSwitchCase="'dropdown'" [formControlName]="metadata.key">
        <option *ngFor="let opt of metadata.options" [value]="opt.key">{{opt.value}}</option>
      </select>
  
    </div>

    <div class="errorMessage" *ngIf="!isValid">{{ 'forms.fieldNameIsMandatory' | translate:{'fieldName':metadata.label} }}</div>
    
  </div>
<ul class="semicircle-pie" [ngStyle]="{'width.px': width, 'height.px': height }">
  <li
    [ngStyle]="{'border-color': item.color, 'transform': 'rotate(' + item.accumulatedPercentage + 'deg)'}"
    *ngFor="let item of items; let i = index ">
    <span>{{ item.percentage + item.percentage }} - {{ item.name }}</span>
  </li>
  <app-small-kpi 
    [mainValue]="kpiInfo.mainValue" 
    [mainValueUnit]="kpiInfo.mainValueUnit" 
    [secondaryValue]="kpiInfo.secondaryValue" 
    [percentage]="kpiInfo.percentage" 
    [trend]="kpiInfo.trend" 
    [hasPercentage]="kpiInfo.hasPercentage" 
    class="kpi"
    *ngIf="kpiInfo">
  </app-small-kpi>
</ul>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiAtlasService {

  // Base URL to connect with the Atlas API
  baseURL: string = environment.azureApiUriAtlas;

  // URL to referencing dashboard page
  dashboardsURL: string = 'dashboards/';

  // URL to referencing the 'categories' section
  categoriesURL: string = 'categories';

  // URL to referencing the category detail
  dashboardURL: string = 'dashboard';

  // URL to referencing the dashboard section detail
  dashboardSectionURL: string = 'dashboard_section';

  // URL to referencing the description filter update
  filtersURL: string = 'filters';

  // URL to referencing the technical area
  technicalURL: string = 'technical/';

  // URL to referencing an instance of the technical area
  instanceURL: string = 'instance';

  // URL to referencing the list type for technical entities
  listTypeURL: string = 'list_type';

  constructor(
    private httpClient: HttpClient
  ) { }

  // Service to retrieve the categories info for the data governance dashboard
  public getDashboardCategories(language: string): Observable<any> {
    let body: any = {language: language};
    return this.httpClient.post(this.baseURL + this.dashboardsURL + this.categoriesURL, body);
  }

  // Service to retrieve section info from Atlas
  public getSectionInfo(endPoint: string = "", params: any[] = []): Observable<any> {
    let body: any = {};
    for (let param of params) {
      const name = param.name;
      const value = param.value;
      body[name] = value;
    }
    return this.httpClient.post(endPoint, body);
  }
  
  // Service to retrieve the dashboard detail info
  public getDashboardDetail(language: string, guid: string): Observable<any> {
    let body: any = {language: language, guid: guid};
    return this.httpClient.post(this.baseURL + this.dashboardsURL + this.dashboardURL, body);
  }

  // Service to retrieve the dashboard section detail info
  public getDashboardSectionDetail(language: string, guid: string): Observable<any> {
    let body: any = {language: language, guid: guid};
    return this.httpClient.post(this.baseURL + this.dashboardsURL + this.dashboardSectionURL, body);
  }

  // Service to update the dashboard description/image
    public updateDashboardInfo(language: string, guid: string, attributes: any): Observable<any> {
    let body: any = {language: language, guid: guid, attributes: attributes};
    return this.httpClient.put(this.baseURL + this.dashboardsURL + this.dashboardURL, body);
  }

  // Service to update the dashboard section description/image
  public updateDashboardSectionInfo(language: string, guid: string, attributes: any): Observable<any> {
    let body: any = {language: language, guid: guid, attributes: attributes};
    return this.httpClient.put(this.baseURL + this.dashboardsURL + this.dashboardSectionURL, body);
  }

  // Service to update data governance information for a section, filter, ... 
  public updateDataGovernanceInfo(language: string, guid: string, attributes: any, endpoint: string): Observable<any> {
    let body: any = {language: language, guid: guid, attributes: attributes};
    return this.httpClient.put(endpoint, body);
  }

  // Service to retrieve a technical area instance
  public getTechnicalAreaInstance(guid: string): Observable<any> {
    let body: any = {guid: guid};
    return this.httpClient.post(this.baseURL + this.technicalURL + this.instanceURL, body);
  }

  // Service to retrieve the list type for technical entities
  public getTechnicalListType(entity_type: string): Observable<any> {
    let body: any = {entity_type: entity_type};
    return this.httpClient.post(this.baseURL + this.technicalURL + this.listTypeURL, body);
  }

}

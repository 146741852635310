<app-wrapper>

    <div class="wrapper_grid">
        <div class="clients" [ngClass]="{'btn-home-disabled' : !clientsActive}">
            <app-btn-clients></app-btn-clients>
        </div>
        <div class="sale" [ngClass]="{'btn-home-disabled' : !salesActive}">
            <app-btn-sale></app-btn-sale>
        </div>        
        <div class="cancellation" [ngClass]="{'btn-home-disabled' : !cancellationActive}">
            <app-btn-cancellation></app-btn-cancellation>
        </div>
        <div class="sinisters" [ngClass]="{'btn-home-disabled' : !sinistersActive}">
            <app-btn-sinister></app-btn-sinister>
        </div>
        <div class="backoffice" [ngClass]="{'btn-home-disabled' : !backofficeActive}">
            <app-btn-backoffice></app-btn-backoffice>
        </div>
    </div>

</app-wrapper>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'
import { QualityRolling } from 'src/app/shared/models/quality-rolling';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { ExcelFileInfo } from 'src/app/shared/models/excel-file-info';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';

@Component({
  selector: 'app-sinisters-polls-graphic',
  templateUrl: './sinisters-polls-graphic.component.html',
  styleUrls: ['./sinisters-polls-graphic.component.scss']
})
export class SinistersPollsGraphicComponent implements OnInit {

  // view: any[] = [400, 170];
  customColors: any = [];

  // options
  legend: boolean = false;
  showLabels: boolean = false;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showRefLines: boolean = false;
  timeline: boolean = false;
  rotateXAxisTicks: boolean = false;

  colorScheme = {
    domain: [COLORS.darkGreen, COLORS.darkYellow, COLORS.darkRed, COLORS.lightBlue, '#a8385d', '#aae3f5']
  };

  // Options for chart creation
  view = [410, 105];
  modalView = [1210, 580];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  legendTitle: string = '';
  legendPosition: string = '';
  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  yLeftAxisScaleFactor: string = '';
  yRightAxisScaleFactor: string = '';
  yRightAxisTickFormatting: string = '';
  showGridLines: boolean = true;
  innerPadding: string = '10%';
  animations: boolean = false;
  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  barPadding: number = 8;
  tooltipDisabled: boolean = false;
  schemeType: string = "ordinal";
  roundDomains: boolean = true;
  noBarWhenZero: boolean = true;
  barChart: any[] = [];
  lineChartSeries: any[] = [];
  lineChartScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkYellow, COLORS.lightPurple, '#0099cc', '#08ded1']
  };
  comboBarScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkGreen, COLORS.darkYellow, COLORS.darkRed, COLORS.lightBlue, '#a8385d', '#aae3f5']
  };
  showTotalInTooltip: boolean = false;

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;
  monthNames: any;
  tnpsName: string;
  promotersName: string;
  neutralsName: string;
  detractorsName: string;
  tnpsReasonName: string;
  pollsTitle: string;
  qualityNPSRolling: any = [];
  qualityNPSRollingWithAverage: any = [];
  loaded: boolean = false;
  qualityNPSSelected: any = [];
  xAxisTicksFinal: any = [];
  xAxisTicksValues: any = [];

  data_total: any[] = [];
  data_sheet_names: any[] = [];
  data_filter_details: any[] = [];
  tnpsReason: string = 'Venta OM';

  promotersSeries: any = [];
  neutralsSeries: any = [];
  detractorsSeries: any = [];
  averageSeries: any = [];

  promotersToExport: any[] = [];
  neutralsToExport: any[] = [];
  detractorsToExport: any[] = [];
  averageToExport: any[] = [];

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private translateService: TranslateService,
    private eventsService: EventsService,
    private excelService: ExcelService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {

    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const months = this.translateService.get('abbreviatedMonthNames');
          const tnps = this.translateService.get('labels.tnps');
          const promoters = this.translateService.get('labels.promoters');
          const polls = this.translateService.get('panels.polls');
          const tnpsReason = this.translateService.get('labels.tnpsReason');
          const neutrals = this.translateService.get('labels.neutrals');
          const detractors = this.translateService.get('labels.detractors');

          forkJoin([months, tnps, promoters, polls, tnpsReason, neutrals, detractors]).pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              {
                next: results => {
                  this.monthNames = results[0];
                  this.tnpsName = results[1];
                  this.promotersName = results[2];
                  this.pollsTitle = results[3];
                  this.tnpsReasonName = results[4];
                  this.neutralsName = results[5];
                  this.detractorsName = results[6];
                },
                complete: () => {
                  if (data.length !== 0) {
                    this.loaded = false;
                    this.filtersValues = data;
                    this.resetVariables();
                    this.qualityNPSSelected = [];
                    this.retrieveQualityNPS(this.qualityNPSSelected, this.filtersValues);
                  }
                }
              }
            );
        }
      );

    this.eventsService.sinistersQualityNPS$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.qualityNPSSelected = [];
        if (data.value !== 'all') {
          this.qualityNPSSelected.push(data.value);
        }
        this.tnpsReason = data.selectedOptions[0].label;
        this.resetVariables();
        this.retrieveQualityNPS(this.qualityNPSSelected, this.filtersValues);
      });

  }

  // Method to retrieve the quality NPS data
  private retrieveQualityNPS(qualityNPSSelected: [], filtersValues: any) {
    this.loaded = false;
    this.apimstService.getSinistersQualityNPS(qualityNPSSelected, "sse", filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data: Array<QualityRolling>) => {
          let rateSeries: any = [];
          let avgRateSeries: any = [];
          let rateToExport: any[] = [this.promotersName + ' (%)'];
          let avgRateToExport: any[] = [this.tnpsName + ' (%)'];
          this.promotersToExport = [this.promotersName + ' (%)'];
          this.neutralsToExport = [this.neutralsName + ' (%)'];
          this.detractorsToExport = [this.detractorsName + ' (%)'];
          this.averageToExport = [this.tnpsName + ' (%)'];

          let headers: any[] = [''];

          this.data_total = [];
          this.qualityNPSRolling = [];
          this.xAxisTicksValues = [];
          this.xAxisTicksFinal = [];

          if (data.length !== 0) {
            this.data_filter_details = data[0].filters;

            let month: any = {};
            for (let month of data) {
              const date = this.monthNames[month.month] + ' ' + month.year.slice(2, 4);
              const rate = month.rate ? parseFloat(month.rate) : 0;
              const avgRate = month.rate_avg ? parseFloat(month.rate_avg) : 0;

              rateSeries.push({
                name: date,
                value: rate
              });

              avgRateSeries.push({
                name: date,
                value: avgRate
              });

              headers.push(date);
              rateToExport.push(rate);
              avgRateToExport.push(avgRate);

              this.xAxisTicksValues.push(date);

            }

            this.qualityNPSRolling.push(
              {
                name: this.promotersName,
                series: rateSeries
              },
              {
                name: this.tnpsName,
                series: avgRateSeries
              }
            );

            this.createGraph(data);
            this.createLineGraph(data);

            this.assignColorBar();

            this.getXAxisValues();

            this.createDataToExcel(data);
            this.data_total.push([[this.tnpsReasonName, this.tnpsReason], [], headers, this.promotersToExport, this.neutralsToExport, this.detractorsToExport, this.averageToExport]);

            this.data_sheet_names = [this.pollsTitle];
            let excelInfo: ExcelFileInfo = { json: this.data_total, headers: this.data_sheet_names, excelFileName: 'sales_quality_t-nps_chart', filtersValues: this.data_filter_details, filters: true };
            this.eventsService.salesTnpsExcelInfoLoaded$.next(excelInfo);
          }

          this.loaded = true;
        },

        error => console.log("An error ocurred while retrieving quality NPS rolling data: " + error)


      );
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // Method to formath the yAxis ticks
  yAxisTickFormatting(val) {
    return val.toLocaleString() + "%";
  }

  // Method to get only one x axis value in two 
  private getXAxisValues(): void {
    for (var _i = 0; _i < this.xAxisTicksValues.length; _i += 2) {
      this.xAxisTicksFinal.push(this.xAxisTicksValues[_i]);
    }
  }

  createGraph(data: any = {}) {
    this.qualityNPSRolling = [];
    this.qualityNPSRollingWithAverage = [];

    this.promotersSeries = [];
    this.neutralsSeries = [];
    this.detractorsSeries = [];
    this.averageSeries = [];

    for (let month of data) {
      const date = this.monthNames[month.month] + ' ' + month.year.slice(2, 4);
      this.qualityNPSRolling.push({
        name: date,
        series: [
          { name: this.promotersName, value: month.promotor ? parseFloat(month.promotor) : 0 },
          { name: this.neutralsName, value: month.neutral ? parseFloat(month.neutral) : 0 },
          { name: this.detractorsName, value: month.detractor ? parseFloat(month.detractor) : 0 }
        ]
      });
    }
  }

  createLineGraph(data: any = {}) {
    this.lineChartSeries.push({ name: this.tnpsName, series: [] })

    for (let month of data) {
      const date = this.monthNames[month.month] + ' ' + month.year.slice(2, 4);
      this.lineChartSeries[0].series.push({
        name: date,
        value: month.tnps ? parseFloat(month.tnps) : 0
      })
    }
  }

  createDataToExcel(data: any) {
    this.promotersSeries.push({
      name: this.promotersName,
      series: []
    });
    for (let promoterItem of data) {
      const date = this.monthNames[promoterItem.month] + ' ' + promoterItem.year.slice(2, 4);
      const promoter = promoterItem.promoter ? parseFloat(promoterItem.promoter) : 0;
      this.promotersSeries[0].series.push({
        name: date,
        value: promoter
      });
      this.promotersToExport.push(promoter);
    }

    this.neutralsSeries.push({
      name: this.neutralsName,
      series: []
    });
    for (let neutralItem of data) {
      const date = this.monthNames[neutralItem.month] + ' ' + neutralItem.year.slice(2, 4);
      const neutral = neutralItem.neutral ? parseFloat(neutralItem.neutral) : 0;
      this.neutralsSeries[0].series.push({
        name: date,
        value: neutral
      });
      this.neutralsToExport.push(neutral);
    }

    this.detractorsSeries.push({
      name: this.detractorsName,
      series: []
    });
    for (let detractorItem of data) {
      const date = this.monthNames[detractorItem.month] + ' ' + detractorItem.year.slice(2, 4);
      const detractor = detractorItem.detractor ? parseFloat(detractorItem.detractor) : 0;
      this.detractorsSeries[0].series.push({
        name: date,
        value: detractor
      });
      this.detractorsToExport.push(detractor);
    }

    this.averageSeries.push({
      name: this.tnpsName,
      series: []
    });
    for (let averageItem of data) {
      const date = this.monthNames[averageItem.month] + ' ' + averageItem.year.slice(2, 4);
      const average = averageItem.rate ? parseFloat(averageItem.rate) : 0;
      this.averageSeries[0].series.push({
        name: date,
        value: average
      });
      this.averageToExport.push(average);
    }
  }

  onActivate(e) {
    // console.log("activate")
    // console.log(e)
  }

  onSelect(e) {
    // console.log("click")
    // console.log(e)
  }

  setTooltipRate(model: any) {
    let values: any[] = [];

    for (let item of this.averageSeries[0].series) {
      if (item.name == model[0].name) {
        return item.value
      }
    }

    return;
  }

  private assignColorBar() {
    this.customColors.push(
      {
        name: this.promotersName,
        value: COLORS.darkGreen
      },
      {
        name: this.neutralsName,
        value: COLORS.darkYellow
      },
      {
        name: this.detractorsName,
        value: COLORS.darkRed
      },
      {
        name: this.tnpsName,
        value: COLORS.pink
      }
    )
  }

  private resetVariables() {
    this.qualityNPSRolling = [];
    this.lineChartSeries = [];
    this.data_total = [];
    this.data_sheet_names = [];
  }

  // Method to export data to XSLX
  exportAsXLSX(): void {
    this.data_sheet_names = [this.pollsTitle];
    this.excelService.exportAsExcelFile(this.data_total, this.data_sheet_names, 'sales_quality_t-nps_chart', this.data_filter_details);
  }

}

<div class="content">
  <app-header></app-header>
  <!-- <button mat-raised-button (click)="login()">Login</button>
  <button mat-raised-button (click)="logout()">Logout</button> -->
  <main [@routeAnimations]="prepareRoute(outlet)" class="main-outlet" style="height: 100%">
    <router-outlet #outlet="outlet"></router-outlet>
  </main>
  
  <!-- <app-wrapper>
  </app-wrapper> -->
  <app-translation></app-translation>
  
  <app-notification-button></app-notification-button>
</div>

<div class="app-loader" *ngIf="!appLoaded"><base-loader></base-loader></div>
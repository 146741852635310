<div class="widget">
    <div id="widget-container" class="widget-container">
        <div class="section-header-block d-flex justify-content-between align-items-center mb-4">
            <span class="title dg-section-card-title" [ngStyle]="getFontColor(order)">{{ title }}</span>
            <span id="title-underline-{{order}}" class="title-underline"></span>    
        </div>
        <div class="dashboards" [ngStyle]="getFontColor(order)">
            <span *ngFor="let dashboard of dashboards" class="d-flex">
                <span class="dashboard-section d-flex" (click)="goToSectionDetail(dashboard.guid)">{{dashboard.name}}<mat-icon class="right-arrow-icon ml-2">keyboard_arrow_right</mat-icon></span>
            </span>
        </div>
        <div class="image-background">
            <img src={{image}}>
        </div>
    </div>
</div>

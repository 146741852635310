<app-wrapper>

    <div class="wrapper_grid">
        <div class="persistence-total">
            <app-persistence-total></app-persistence-total>
        </div>
        <div class="persistence-postretention">
            <app-persistence-postretention></app-persistence-postretention>
        </div>        
        <div class="persistence-new-sale">
            <app-persistence-new-sale></app-persistence-new-sale>
        </div>
        <div class="persistence-monthly">
            <app-persistence-monthly></app-persistence-monthly>
        </div>
    </div>

</app-wrapper>
import { Component, OnInit } from '@angular/core';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { environment } from 'src/environments/environment.prod';
import { RejectionsPendingAgeService } from './rejections-pending-age.service';

@Component({
  selector: 'app-rejections-pending-age',
  templateUrl: './rejections-pending-age.component.html',
  styleUrls: ['./rejections-pending-age.component.scss']
})
export class RejectionsPendingAgeComponent implements OnInit {

  isLoading: boolean = false;
  loaded: boolean = false;

  panelOptions: any[] = [];
  graphName: string = 'sinistersPendingAge';

  dropdownTitle: string = "";
  ageReasons: any[] = [];
  dropdownId: string = "ageReasons";

  unsubscribe$: Subject<any> = new Subject<any>();

  pendingName: string = "";
  paidName: string = "";
  rejectionName: string = "";
  ocurrenceName: string = "";

  filtersValues: any[] = [];

  pageName: string;
  pages = [
    { name: 'lr-block' },
    { name: 'lr-evolution' }
  ]

  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "sinisters-rejections--pending-age";

  constructor(
    private events: EventsService,
    private rejectionsPendingAgeService: RejectionsPendingAgeService,
    private i18n: I18nService,
    private atlasHelper: AtlasHelperService
  ) { }

  ngOnInit(): void {
    this.subscribeToActiveLanguage();

    setTimeout(() => {
      this.rejectionsPendingAgeService.panelSelectedOption$.next({ id: "amount", name: "panels.amount", selected: true, graphName: this.graphName });
      this.rejectionsPendingAgeService.dropdownSelectedOption$.next('1');
    }, 25);

    this.panelOptions = this.getButtonGroupValues();
    this.ageReasons = this.getSelectorValues();
    this.loaded = true;

    this.events.optionTabMultipleChanged$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: selectedOption => {
          if (selectedOption['graphName'] == this.graphName) {
            this.rejectionsPendingAgeService.panelSelectedOption$.next(selectedOption);
          }
        }
      });

    this.events.ageReasons$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: selectedOption => {
          this.rejectionsPendingAgeService.dropdownSelectedOption$.next(selectedOption);
        }
      });
  }

  getButtonGroupValues() {
    return [
      { id: "amount", name: "panels.amount", selected: true, graphName: this.graphName },
      { id: "sinisters", name: "panels.sinisters", selected: false, graphName: this.graphName },
    ];
  }

  getSelectorValues() {
    return [
      {
        id: '1',
        type: 'sinister-age-reason',
        name: 'panels.pending',
        selected: true
      },
      {
        id: '2',
        type: 'sinister-age-reason',
        name: 'panels.paid',
        selected: false
      },
      // {
      //   id: '3',
      //   type: 'sinister-age-reason',
      //   name: 'panels.ocurrence',
      //   selected: false
      // },
      {
        id: '4',
        type: 'sinister-age-reason',
        name: 'panels.rejection',
        selected: false
      }
    ];
  }

  getSection(event): void {
    this.pageName = event.name;
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Component, OnInit } from '@angular/core';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-santander-clients-penetration',
  templateUrl: './santander-clients-penetration.component.html',
  styleUrls: ['./santander-clients-penetration.component.scss']
})
export class SantanderClientsPenetrationComponent implements OnInit {
  
  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "clients-penetration-segmentation--santander-penetration";

  unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private i18n: I18nService,
    private atlasHelper: AtlasHelperService
  ) { }

  ngOnInit(): void {
    this.subscribeToActiveLanguage();
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: language => {
          this.activeLang = language;
          this.updateAtlasInfoParams(this.pageId, this.activeLang);
        }
      })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

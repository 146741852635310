<component-loader [width]="" [height]="290" class="d-block mt-3" *ngIf="!loaded"></component-loader>

<div class="checkboxes-container d-flex flex-wrap justify-content-end w-100 mt-4" *ngIf="loaded">
    <h6 class="text-light mr-2 mb-0">{{ 'panels.policiesNumber' | translate }}</h6>
    <label class="main-checkbox checkbox-legend d-block ml-3" *ngFor="let checkbox of checkboxes; let i=index;">
        <span class="text-light">{{ checkbox.name }}</span>
        <input #checkbox.name type="checkbox" [checked]="checkbox.checked" (click)="updateCheckedCheckboxes(checkbox.id, $event)">
        <span class="checkmark"></span>
    </label>
</div>

<div class="line-charts-gen line-charts-clients-portfolio-distribution mt-3 ml-n1" *ngIf="loaded">
    <ngx-charts-area-chart
        [view]="view"
        [scheme]="colorScheme"
        [customColors]="customColors"
        [legend]="legend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [timeline]="timeline"
        [results]="tenureDistribution"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [autoScale]="autoScale">
        <ng-template #seriesTooltipTemplate let-model="model">
            <div class="area-tooltip">
                <div class="series-tooltip-template-inner">  
                    <span class="d-flex align-items-center my-1" *ngFor="let item of model">
                        <span class="tooltip-color-tag" 
                            [ngStyle]="{'background-color': item.color}">
                        </span> 
                        <span class="tooltip-tag">{{ item.series }}: {{ formatValue(item.value) }}</span>
                    </span>
                    <span class="d-flex align-items-center my-1">
                        <span class="tooltip-color-tag" 
                            [ngStyle]="{'background-color': '#000'}">
                        </span> 
                        <span class="tooltip-tag">{{ clientsNumberTotal }}: {{ getTotal(model) }}</span>
                    </span>
                </div>
            </div>
        </ng-template>
    </ngx-charts-area-chart>
</div>
import { Component, OnInit } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';

@Component({
  selector: 'cancellation-charges-pie-chart',
  templateUrl: './cancellation-charges-pie-chart.component.html',
  styleUrls: ['./cancellation-charges-pie-chart.component.scss']
})
export class CancellationChargesPieChartComponent implements OnInit {

  pieGraphData: any[];

  // Pie chart options
  view: any[] = [180, 130];
  gradient: boolean = false;
  showLegend: boolean = false;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';
  tooltipDisabled: boolean = true;
  animations: boolean = false;
  colorScheme = {
    domain: [COLORS.lightBlue, COLORS.lightGreen, COLORS.lightOrange, COLORS.pink]
  };
  arcWidth: number = 1.5;

  constructor() { }

  ngOnInit(): void {
    this.pieGraphData = [
      {
          name: 'Red',
          value: 25
      },
      {
          name: 'CC',
          value: 25
      },
      {
          name: 'Digital',
          value: 15
      },
      {
          name: 'ATM',
          value: 35
      }
    ];
  }

  labelFormatting(series: any[], name: string): string {
    const item = series.filter(data => data.name === name);
    if (item.length > 0) {
        return item[0].value + "%";
    }
    return name;
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { forkJoin, Subscription, Subject } from 'rxjs';
import { PortfolioGWPMixEvolution } from 'src/app/shared/models/portfolio-gwp-mix-evolution';
import { PortfolioGWPMixEvolutionDetails } from 'src/app/shared/models/portfolio-gwp-mix-evolution-details';
import { TranslateService } from '@ngx-translate/core';
import { NumberPipe } from 'src/app/shared/pipes/number.pipe';
import { takeUntil } from 'rxjs/operators'
import { CurrencyService } from 'src/app/shared/services/currency/currency.service';
import { COLORS } from 'src/app/shared/globals/globals';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';


@Component({
  selector: 'app-portfolio-graph',
  templateUrl: './portfolio-graph.component.html',
  styleUrls: ['./portfolio-graph.component.scss']
})
export class PortfolioGraphComponent implements OnInit, OnDestroy {

  currencySymbol: string = '';
  currencySubscription: Subscription;

  mainValueKpi: string = '';
  mainValueUnit: string = '';
  percentageKpi: string = '';
  trendKpi: number = 0;
  viewBox: any = [0, 0, 400, 10];
  loaded: boolean = false;

  // Number of items in the graph
  graph_items = 4

  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();
  bouquetsData: PortfolioGWPMixEvolution;
  referenceData: PortfolioGWPMixEvolution;

  // Graph options
  view: any[] = [400, 150]
  schemeType: string = 'ordinal';
  gradient: boolean = true;
  showXAxis: boolean = true;
  showYAxis: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  showYAxisLabel: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  xAxisTicks: any;
  animations: boolean = false;
  showDataLabel: boolean = true;
  dataLabelFormatting: any;
  noBarWhenZero: boolean = false;
  colorScheme = {
    domain: [COLORS.lightGreen, COLORS.walkGreen, COLORS.walkRed, COLORS.darkGreen]
  };
  dataLabelUnit: string;

  portfolioGWPMixEvolution: PortfolioGWPMixEvolution;
  evolutionGraph: any = [];

  percentagePrevious: string = "labels.pm"

  monthNames: any;
  others_label: string;
  titleLabel: string;

   // Variables to store data to export
   data_total: any[] = [];
   data_sheet_names: any[] = [];
   data_filter_details: any[] = [];

  // Default constructor
  constructor(
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private translateService: TranslateService,
    private numberFormat: NumberPipe,
    private currencyService: CurrencyService,
    private excelService: ExcelService
  ) { }

  // OnInit
  ngOnInit(): void {

    this.currencySubscription = this.currencyService.currencyInfo$
    .subscribe(
      currencyInfo => {
        this.currencySymbol = currencyInfo.symbol;
        this.dataLabelUnit = this.currencySymbol;
      }
    )

    // Event to detect filter changes
    this.subscription = this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {

        const months = this.translateService.get('abbreviatedMonthNames');
        const others = this.translateService.get('labels.others');
        const title = this.translateService.get('labels.portfolioEvolution');

        forkJoin([months, others, title])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          {
            next: result => {
              this.monthNames = result[0];
              this.others_label = result[1];
              this.titleLabel = result[2];
            },
            complete: () => {
              if (data.length !== 0) {
                this.loaded = false;
                const bouquets = this.apimstService.getPortfolioGWPEvolution(['b'], data);
                const reference = this.apimstService.getPortfolioGWPEvolution([''], data);

                forkJoin([bouquets, reference]).subscribe(
                  results => {
                    this.bouquetsData = results[0];
                    this.referenceData = results[1];
                    this.data_filter_details = this.referenceData.filters;

                    this.retrievePortfolioGWPMixEvolution(this.bouquetsData, this.referenceData);
                  },

                  error => console.log("An error ocurred while retrieving portfolio evolution graph data " + error)
                )
              }
            }
          }
        )
      }
    );
  }

  // Method to retrieve the portfolio vs GWP Mix evolution data
  private retrievePortfolioGWPMixEvolution(bouquets: PortfolioGWPMixEvolution, reference: PortfolioGWPMixEvolution) {
    this.evolutionGraph = [];
    this.data_total = [];
    const graphData: any = [];

    let bouquetsDetails: Array<PortfolioGWPMixEvolutionDetails> = [];
    if (bouquets.details !== undefined && bouquets.details.length !== 0) {
      bouquetsDetails = bouquets.details;
    }

    let referenceDetails: any = [];
    if (reference.details !== undefined && reference.details.length !== 0) {
      referenceDetails = reference.details[0];
    }

    if (referenceDetails.length !== 0) {
      this.mainValueKpi = referenceDetails?.current;
      this.mainValueUnit = this.currencySymbol;
      this.percentageKpi = referenceDetails?.diff;
      this.trendKpi = referenceDetails?.diff_trend ? parseFloat(referenceDetails.diff_trend.replace(/,/g, '')) : 0;

      // Previous year
      graphData.push(
        {
          name: this.monthNames[reference?.month] + ' ' + (parseInt(reference?.year)-1),
          value: referenceDetails?.previous ? parseFloat(referenceDetails?.previous.replace(/,/g, '')) : 0
        }
      )
    }

    // Bouquet bars
    if (bouquetsDetails.length !== 0) {
      const grouped_bouquets = this.group_graph_items(bouquetsDetails, this.graph_items);

      for (let bouquet of grouped_bouquets) {
        graphData.push(
          {
            name: bouquet.name,
            value: this.numberFormat.transform(bouquet.diff)
          }
        );
      }
    }

    // Current year
    if (referenceDetails.length !== 0) {
      graphData.push(
        {
          name: this.monthNames[reference?.month]+ ' ' + parseInt(reference?.year),
          value: referenceDetails?.current ? parseFloat(referenceDetails?.current.replace(/,/g, '')) : 0
        }
      )
    }

    for (let item of graphData) {
      this.data_total.push([item.name, item.value]);
    }
    this.data_total = [this.data_total];

    this.evolutionGraph = graphData;

    this.loaded = true;

  }

  // Method to group elements
  private group_graph_items(array, number_items) {
    array = array.sort((a,b) =>{
      if(Math.abs(parseFloat(a.diff.replace(/,/g, ''))) > Math.abs(parseFloat(b.diff.replace(/,/g, '')))){
        return -1
      }else if (Math.abs(parseFloat(b.diff.replace(/,/g, ''))) > Math.abs(parseFloat(a.diff.replace(/,/g, '')))){
        return 1
      }else{
        return 0;
      }
    });
    let output_array=[]
    let acummulated_bouquets: number = 0;
    for(let index=0;index<array.length;index++){
      if(index<number_items-1){
        output_array.push(array[index])
      }else{
        acummulated_bouquets += this.numberFormat.transform(array[index].diff);
      }
    }
    output_array.push({name: this.others_label, diff: acummulated_bouquets.toString()});

    return output_array;
  }

  // Method to export data to XSLX
  exportAsXLSX():void {
    this.data_sheet_names = [this.titleLabel];
    this.excelService.exportAsExcelFile(this.data_total, this.data_sheet_names, 'portfolio_evolution_chart', this.data_filter_details);
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.currencySubscription.unsubscribe();
  }

}

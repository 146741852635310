import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ContentChild,
  TemplateRef,
  HostListener
} from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { BaseChartComponent, ViewDimensions, ColorHelper, calculateViewDimensions } from '@swimlane/ngx-charts';
import { curveLinear } from 'd3-shape';
import { scaleBand, scaleLinear, scalePoint, scaleTime } from 'd3-scale';

@Component({
  selector: 'grouped-vertical-horizontal-graph-d-axis',
  templateUrl: './grouped-vertical-horizontal-graph-d-axis.component.html',
  styleUrls: ['./grouped-vertical-horizontal-graph-d-axis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition(':leave', [
        style({
          opacity: 1,
          transform: '*'
        }),
        animate(500, style({ opacity: 0, transform: 'scale(0)' }))
      ])
    ])
  ]
})
export class GroupedVerticalHorizontalGraphDAxisComponent extends BaseChartComponent {

  // Input values
  @Input() legend = false;
  @Input() legendTitle: string = 'Legend';
  @Input() legendPosition: string = 'right';
  @Input() xAxis;
  @Input() yAxis;
  @Input() showXAxisLabel;
  @Input() showYAxisLabel;
  @Input() xAxisLabel;
  @Input() yAxisLabel;
  @Input() tooltipDisabled;
  @Input() scaleType = 'ordinal';
  @Input() gradient;
  @Input() showGridLines;
  @Input() activeEntries;
  @Input() schemeType;
  @Input() trimXAxisTicks;
  @Input() trimYAxisTicks;
  @Input() rotateXAxisTicks;
  @Input() maxXAxisTickLength;
  @Input() maxYAxisTickLength;
  @Input() xAxisTickFormatting;
  @Input() yAxisTickFormatting;
  @Input() xAxisTicks;
  @Input() yAxisTicks;
  @Input() groupPadding = 16;
  @Input() barPadding = 8;
  @Input() roundDomains;
  @Input() roundEdges;
  @Input() yScaleMax;
  @Input() showDataLabel;
  @Input() dataLabelFormatting;
  @Input() noBarWhenZero;
  @Input() showRightYAxisLabel;
  @Input() yAxisLabelRight;
  @Input() lineChart;
  @Input() showRightGridLines;
  @Input() yRightAxisTickFormatting;
  @Input() onlyPoints: boolean = false;
  @Input() showPercentageSymbolInTooltip: boolean = true;
  @Input() addHorizontalGraph: boolean = true;

  // Allows to have the same scale on both Y-axis
  @Input() synchronizedYAxis: boolean = false;

  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;

  // Component variables
  dims: ViewDimensions;
  curve: any = curveLinear;
  groupDomain: any[];
  innerDomain: any[];
  valuesDomain: any[];
  groupScale: any;
  innerScale: any;
  valueScale: any;
  transform: string;
  transformLine: string;
  colors: ColorHelper;
  colorsLine: ColorHelper;
  margin = [10, 20, 10, 20];
  xAxisHeight: number = 0;
  yAxisWidth: number = 0;
  legendOptions: any;
  dataLabelMaxHeight: any = { negative: 0, positive: 0 };
  yOrientRight: string = 'right';
  yDomainLine: any;
  yScaleLine: any;
  xDomainLine: any;
  xScaleLine: any;
  combinedSeries: any;
  seriesDomain: any;
  rangeFillOpacity: number;
  bandwidth: any;
  xSet: any;
  hoveredVertical: any;

  // Method that 
  update(): void {
    super.update();

    if (!this.showDataLabel) {
      this.dataLabelMaxHeight = { negative: 0, positive: 0 };
    }
    this.margin = [10 + this.dataLabelMaxHeight.positive, 20, 10 + this.dataLabelMaxHeight.negative, 20];

    // Dimensions calculation
    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: this.legend,
      legendType: this.schemeType,
      legendPosition: this.legendPosition
    });

    if (this.showDataLabel) {
      this.dims.height -= this.dataLabelMaxHeight.negative;
    }

    this.formatDates();

    // Domain and scale calculation for the grouped vertial graph
    this.groupDomain = this.getGroupDomain();
    this.innerDomain = this.getInnerDomain();
    this.valuesDomain = this.getValueDomain();

    this.groupScale = this.getGroupScale();
    this.innerScale = this.getInnerScale();
    this.valueScale = this.getValueScale();

    // Domain and scale calculation for the horizontal graph
    this.yDomainLine = this.getYDomainLine();
    this.yScaleLine = this.getYScaleLine();
    this.seriesDomain = this.getSeriesDomain();
    this.xDomainLine = this.getXDomainLine();
    this.xScaleLine = this.getXScaleLine();
  
    // Colors and legend configuration
    this.setColors();
    this.legendOptions = this.getLegendOptions();
    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0] + this.dataLabelMaxHeight.negative})`;
    let offsetLine = (this.groupScale(this.groupDomain[1]) - this.groupScale(this.groupDomain[0]) - 8)/2;
    this.transformLine = `translate(${this.dims.xOffset + offsetLine} , ${this.margin[0] + this.dataLabelMaxHeight.negative})`;
  }

  // Method to hide the horizontal graph circle data value when mouse leave
  @HostListener('mouseleave')
  hideCircles(): void {
    this.hoveredVertical = null;
  }

   // Method to show the horizontal graph circle data value when mouse leave
   updateHoveredVertical(item): void {
    this.hoveredVertical = item.value;
  }

  onDataLabelMaxHeightChanged(event, groupIndex) {
    if (event.size.negative) {
      this.dataLabelMaxHeight.negative = Math.max(this.dataLabelMaxHeight.negative, event.size.height);
    } else {
      this.dataLabelMaxHeight.positive = Math.max(this.dataLabelMaxHeight.positive, event.size.height);
    }
    if (groupIndex === this.results.length - 1) {
      setTimeout(() => this.update());
    }
  }

  // Method to calculate the scale for the grouped bars
  getGroupScale(): any {
    const spacing = this.groupDomain.length / (this.dims.height / this.groupPadding + 1);

    return scaleBand()
      .rangeRound([0, this.dims.width])
      .paddingInner(spacing)
      .paddingOuter(spacing / 2)
      .domain(this.groupDomain);
  }

  // Method to calculate the scale for the individual bars
  getInnerScale(): any {
    const width = this.groupScale.bandwidth();
    const spacing = this.innerDomain.length / (width / this.barPadding + 1);
    return scaleBand().rangeRound([0, width]).paddingInner(spacing).domain(this.innerDomain);
  }

  // Method to retrieve the X axis labels
  getValueScale(): any {
    const scale = scaleLinear().range([this.dims.height, 0]).domain(this.valuesDomain);
    return this.roundDomains ? scale.nice() : scale;
  }

  // Method to calculate the grouped bar domain
  getGroupDomain() {
    const domain = [];
    for (const group of this.results) {
      if (!domain.includes(group.label)) {
        domain.push(group.label);
      }
    }

    return domain;
  }

  // Method to calculate the individual bars domain
  getInnerDomain() {
    const domain = [];
    for (const group of this.results) {
      for (const d of group.series) {
        if (!domain.includes(d.label)) {
          domain.push(d.label);
        }
      }
    }

    return domain;
  }

  // Method to retrieve the values for the vertical bars
  getValueDomain() {
    const domain = [];
    for (const group of this.results) {
      for (const d of group.series) {
        if (!domain.includes(d.value)) {
          domain.push(d.value);
        }
      }
    }

    const min = Math.min(0, ...domain);
    const max = this.yScaleMax ? Math.max(this.yScaleMax, ...domain) : Math.max(0, ...domain);

    return [min, max];
  }

  // Method to translate the graph
  groupTransform(group) {
    return `translate(${this.groupScale(group.label)}, 0)`;
  }

  onClick(data, group?) {
    if (group) {
      data.series = group.name;
    }

    this.select.emit(data);
  }

  trackBy(index, item) {
    return item.name;
  }

  // Method to set the differents colors of the graph
  setColors(): void {
    let domain;
    if (this.schemeType === 'ordinal') {
      domain = this.innerDomain;
    } else {
      domain = this.valuesDomain;
    }

    this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
    this.colorsLine = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
  }

  // Method to determinate the legend options
  getLegendOptions() {
    const opts = {
      scaleType: this.schemeType,
      colors: undefined,
      domain: [],
      title: undefined,
      position: this.legendPosition
    };
    if (opts.scaleType === 'ordinal') {
      opts.domain = this.innerDomain;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.valuesDomain;
      opts.colors = this.colors.scale;
    }

    return opts;
  }

  updateYAxisWidth({ width }) {
    this.yAxisWidth = width;
    this.update();
  }

  updateXAxisHeight({ height }) {
    this.xAxisHeight = height;
    this.update();
  }

  onActivate(event, group, fromLegend = false) {
    const item = Object.assign({}, event);
    if (group) {
      item.series = group.name;
    }

    const items = this.results
      .map(g => g.series)
      .flat()
      .filter(i => {
        if (fromLegend) {
          return i.label === item.name;
        } else {
          return i.name === item.name && i.series === item.series;
        }
      });

    this.activeEntries = [...items];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }

  onDeactivate(event, group, fromLegend = false) {
    const item = Object.assign({}, event);
    if (group) {
      item.series = group.name;
    }

    this.activeEntries = this.activeEntries.filter(i => {
      if (fromLegend) {
        return i.label !== item.name;
      } else {
        return !(i.name === item.name && i.series === item.series);
      }
    });

    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }

  // Method to calculate the Y axis domain of the horizontal graph
  private getYDomainLine() {
    const domain = [];
    for (const results of this.lineChart) {
      for (const d of results.series) {
        if (domain.indexOf(d.value) < 0) {
          domain.push(d.value);
        }
        if (d.min !== undefined) {
          if (domain.indexOf(d.min) < 0) {
            domain.push(d.min);
          }
        }
        if (d.max !== undefined) {
          if (domain.indexOf(d.max) < 0) {
            domain.push(d.max);
          }
        }
      }
    }
    let min = Math.min(...domain);
    const max = Math.max(...domain);
    min = Math.min(0, min);
    return [min, max];
  }

  // Method to calculate the Y axis scale of the horizontal graph
  private getYScaleLine() {
    const scale = scaleLinear().range([this.dims.height, 0]).domain(this.yDomainLine);

    return this.roundDomains ? scale.nice() : scale;
  }

  private getSeriesDomain(): any[] {
    this.combinedSeries = [];
    const horizontalSeries = this.lineChart.slice(0);

    for (let serie of horizontalSeries) {
      const values: any = [];
      for (let item of serie.series) {
        let dataValue = (item.value ? Intl.NumberFormat("de-DE").format(item.value) : 0);

        if (this.showPercentageSymbolInTooltip) {
          dataValue = dataValue + '%';
        }

        values.push({
          name: item.name, 
          value: item.name + ' - ' + dataValue
        })
      }
      this.combinedSeries.push({
        name: serie.name,
        series: values
      })
    }

    const verticalSeriesNumber = this.getVerticalSeriesNumber();

    for (let _i = 0; _i < verticalSeriesNumber.length; _i++) {
      const values: any = [];
      for (let serie of this.results) {
        values.push({
          name: serie.name, 
          value: serie.name + ' - ' + (serie.series[_i] ? Intl.NumberFormat("de-DE").format(serie.series[_i].value) : 0)
        })
      }
      this.combinedSeries.push({
        name: verticalSeriesNumber[_i],
        series: values
      })
    }
    return this.combinedSeries.map(d => d.name);


  }

  // Method to get the X axis data domaine for the horizontal graph
  private getXDomainLine(): any[] {
    let values = [];

    for (const results of this.lineChart) {
      for (const d of results.series) {
        if (!values.includes(d.name)) {
          values.push(d.name);
        }
      }
    }

    this.scaleType = this.getScaleType(values);
    let domain = [];

    if (this.scaleType === 'time') {
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else if (this.scaleType === 'linear') {
      values = values.map(v => Number(v));
      const min = Math.min(...values);
      const max = Math.max(...values);
      domain = [min, max];
    } else {
      domain = values;
    }

    this.xSet = values;
    return domain;
  }

  // Method to retrieve the X axis scale for the horizontal graph
  private getXScaleLine(): any {
    let scale;
    if (this.bandwidth === undefined) {
      this.bandwidth = this.width - this.barPadding;
    }
    const offset = Math.floor((this.width + this.barPadding - (this.bandwidth + this.barPadding) * this.xDomainLine.length) / 2);

    if (this.scaleType === 'time') {
      scale = scaleTime().range([0, this.width]).domain(this.xDomainLine);
    } else if (this.scaleType === 'linear') {
      scale = scaleLinear().range([0, this.width]).domain(this.xDomainLine);

      if (this.roundDomains) {
        scale = scale.nice();
      }
    } else if (this.scaleType === 'ordinal') {
      scale = scalePoint()
        .range([offset + this.bandwidth / 2, this.width - offset - this.bandwidth / 2])
        .domain(this.xDomainLine);
    }

    return scale;
  }

  // Method to determine the type of scale (data, linear or ordinal)
  private getScaleType(values): string {
    let date = true;
    let num = true;

    for (const value of values) {
      if (!this.isDate(value)) {
        date = false;
      }

      if (typeof value !== 'number') {
        num = false;
      }
    }

    if (date) return 'time';
    if (num) return 'linear';
    return 'ordinal';
  }

  // Method to determine if the data type is 'Date'
  private isDate(value): boolean {
    if (value instanceof Date) {
      return true;
    }

    return false;
  }

  // Method to get the number of vertical series
  private getVerticalSeriesNumber(): any {
    const values = []; 
    for (const period of this.results) {
      for (const d of period.series) {
        if (!values.includes(d.name)) {
          values.push(d.name);
        }
      }
    }
    return values;
  }
  
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { trim } from 'lodash';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COLORS } from 'src/app/shared/globals/globals';
import { CancellationChargeActitityEntity } from 'src/app/shared/models/cancellation-charge-ativity-entity';
import { ExcelFileInfo } from 'src/app/shared/models/excel-file-info';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-cancellation-charge-activity-graphic',
  templateUrl: './cancellation-charge-activity-graphic.component.html',
  styleUrls: ['./cancellation-charge-activity-graphic.component.scss']
})
export class CancellationChargeActivityGraphicComponent implements OnInit, OnDestroy  {


   // Options for chart creation
   view = [580, 200];
   showXAxis: boolean = true;
   showYAxis: boolean = true;
   gradient: boolean = true;
   showLegend: boolean = false;
   legendTitle: string = '';
   legendPosition: string = '';
   showXAxisLabel: boolean = false;
   showYAxisLabel: boolean = false;
   xAxisLabel: string = '';
   yAxisLabel: string = '';
   yLeftAxisScaleFactor: string = '';
   yRightAxisScaleFactor: string = '';
   showGridLines: boolean = true;
   showRightGridLines: boolean = true;
   innerPadding: string = '10%';
   animations: boolean = false;
   showRightYAxisLabel: boolean = false;
   yAxisLabelRight: string = '';
   tooltipDisabled: boolean = false;
   schemeType: string = "ordinal";
   roundDomains: boolean = true;
   noBarWhenZero: boolean = true;
   lineChartScheme = {
     selectable: true,
     group: 'ordinal',
     domain: [COLORS.lightPurple, COLORS.darkYellow, COLORS.lightBlue, '#08ded1']
   };
   comboBarScheme = {
     selectable: true,
     group: 'ordinal',
     domain: [COLORS.lightBlue, COLORS.lightBlue, '#085da6', '#0869aa']
   };
   customColors: any = [];
   roundEdges: boolean = false;
   activeEntries: any = [];
   trimXAxisTicks: boolean = true;
   trimYAxisTicks: boolean = true;
   rotateXAxisTicks: boolean = false;
   maxXAxisTickLength: number = 16;
   maxYAxisTickLength: number = 16;
   barPadding: number = 8;

  // Variables for saving the filter option
  optionTabPolicy = 'panels.receipts';
  optionTabBonus = 'panels.amount';
  actualOption: string = 'panels.amount';
  graphName: string = 'chargeActivity';

  // Subscription
  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();

  groupedVerticalGraph: any = [];
  horizontalGraph: any = [];  
  loaded: boolean = false;

  policyVerticalGraphData: any = [];
  bonusVerticalGraphData: any = [];
  policyHorizontalGraphData: any = [];
  bonusHorizontalGraphData: any = [];

  monthNames: string;
  chargedName: string;
  sentToChargeName: string;
  taxName: string;
  targetName: string;

  filtersValues: any;

  // Variable to export data
  data_total: any[] = [];
  data_sheet_names: any;
  data_filter_details: any;

  constructor(
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private eventsService: EventsService,
    private translateService: TranslateService,
    private excelService: ExcelService
  ) { }

  ngOnInit(): void {
    //this.assignColorBar();

    this.subscription = this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const charged = this.translateService.get('labels.charged')
          const sentToCharge = this.translateService.get('labels.sentToCharge')
          const tax = this.translateService.get('labels.tax')
          const target = this.translateService.get('labels.target')
          const months = this.translateService.get('abbreviatedMonthNames')

          forkJoin([charged, sentToCharge, tax, target, months])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              {
                next: results => {
                  this.chargedName = results[0];
                  this.sentToChargeName = results[1];
                  this.taxName = results[2];
                  this.targetName = results[3];
                  this.monthNames = results[4];
                  this.assignColorBar();
                },
                complete: () => {
                  if(data.length !== 0) {
                    this.filtersValues = data;
                    this.resetVariables();
                    if(this.actualOption == this.optionTabBonus)
                      this.retrieveGraphData('p', data); // (p -> premium)
                    else if(this.actualOption == this.optionTabPolicy)
                      this.retrieveGraphData('c', data); 
                  }
                }
              }
            );
        }
      );

    this.eventsService.policiesBonusOption$.subscribe(
      data => {
        const option = data[0];
        const name = data[1];
        if ((option === this.optionTabBonus || option === this.optionTabPolicy) 
            && (option != this.actualOption) && name === this.graphName){
          this.actualOption = option;              
          if(this.actualOption == this.optionTabBonus){
            if (this.bonusHorizontalGraphData.length === 0 && this.bonusVerticalGraphData.length === 0) {
              this.retrieveGraphData('p', this.filtersValues); // (c -> certificate)
              this.getInfoForExcel();
            } else {
              this.assignDataToPrint();
              this.getInfoForExcel();
            }  
          }else if(this.actualOption == this.optionTabPolicy){
            if (this.policyVerticalGraphData.length === 0 && this.policyHorizontalGraphData.length === 0) {
              this.retrieveGraphData('c', this.filtersValues); // (c -> certificate)
              this.getInfoForExcel();
            } else {
              this.assignDataToPrint();
              this.getInfoForExcel();
            }  
          }
        }
      }
    );        

  }

  private retrieveGraphData(type_data:string, filtersValues: any) {
    this.loaded = false;
    this.subscription = this.apimstService.getCancellationCollectionRolling(type_data,filtersValues).subscribe(
      data => { 
        const graphData: Array<CancellationChargeActitityEntity> = data;
        const verticalGraphData: any = [];
        const taxHorizontalGraphData: any = [];
        const targetHorizontalGraphData: any = [];
        const horizontalGraphData:any = []

        if (graphData.length !== 0) {
          this.data_filter_details = graphData[0].filters;
        }

        for (let group of graphData) {
          taxHorizontalGraphData.push(
            {
              name: this.monthNames[group.month] + ' ' + group.year.slice(2,4),
              value: (group.rate || trim(group.rate).length>0)?parseFloat(group.rate.replace(/,/g, '')):0
            }
          );
          targetHorizontalGraphData.push(
            {
              name: this.monthNames[group.month] + ' ' + group.year.slice(2,4),
              value: (group.target || trim(group.target).length>0)?parseFloat(group.target.replace(/,/g, '')):0
            }
          );

          verticalGraphData.push(
            {
              name: this.monthNames[group.month] + ' ' + group.year.slice(2,4),
              series: [
                {
                  name: this.sentToChargeName,
                  value: (group.issued || trim(group.issued).length>0) ? parseFloat(group.issued.replace(/,/g, '')) : 0
                },
                {
                  name: this.chargedName,
                  value: (group.charged || trim(group.charged).length>0) ?  parseFloat(group.charged.replace(/,/g, '')) : 0
                }
              ]
            }
          );
        }
        horizontalGraphData.push(
          {
            name: this.taxName,
            series: taxHorizontalGraphData
          },
          {
            name: this.targetName,
            series: targetHorizontalGraphData
          }
        );        
        this.assignColorBar();


        // Bonus data (p -> premium)
        if (type_data === 'p') {
          this.bonusVerticalGraphData = verticalGraphData;
          this.bonusHorizontalGraphData = horizontalGraphData;
          
        } else if (type_data === 'c') { // Policy data (c -> certificate)
          this.policyVerticalGraphData = verticalGraphData;
          this.policyHorizontalGraphData = horizontalGraphData;
          
        }
        this.loaded = true;

        this.assignDataToPrint();

        this.getInfoForExcel();

      },
      error => console.log("An error ocurred while retrieving portfolio churn graph data: " + error)
    );
  }

  private assignDataToPrint() {
    if (this.actualOption === this.optionTabPolicy) {
      this.groupedVerticalGraph = this.policyVerticalGraphData;
      this.horizontalGraph = this.policyHorizontalGraphData;
    } else if (this.actualOption === this.optionTabBonus) {
      this.groupedVerticalGraph = this.bonusVerticalGraphData;
      this.horizontalGraph = this.bonusHorizontalGraphData;
    }
  }

  // Method to assign the colors to the graph bars
  private assignColorBar() {
    
    this.customColors.push(
      {
        name: this.chargedName,
        value: COLORS.darkBlue
      },
      {
        name: this.sentToChargeName,
        value: COLORS.lightBlue
      },
      {
        name: this.taxName,
        value: COLORS.darkYellow
      },
      {
        name: this.targetName,
        value: COLORS.lightGreen
      })
  }

  // Method to format the right y axis labels with '%'
  yRightAxisTickFormatting(val) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return  formatPipe.transform(val) + '%'; 
  }

  // Method to format the right y axis labels
  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value);
  }

  // Method to reset the data storage variables
  private resetVariables() {
    this.groupedVerticalGraph = [];
    this.horizontalGraph = [];  
    this.bonusHorizontalGraphData = [];
    this.policyHorizontalGraphData = [];
    this.bonusVerticalGraphData = [];
    this.policyVerticalGraphData = [];        
  }

  getInfoForExcel(): void {
    this.data_total = [];
    const title = this.translateService.get('panels.chargeActivity');
    const option = this.translateService.get(this.actualOption);
        
    let titleLabel = '';
    let optionLabel = '';

    forkJoin([title, option])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(result => {
      titleLabel = result[0];
      optionLabel = result[1];
    });

    this.getDataToExport();
    this.data_sheet_names = [titleLabel + ' - ' + optionLabel];

    let excelInfo: ExcelFileInfo = {json: [this.data_total.flat()], headers: this.data_sheet_names, excelFileName: 'charge_activity_chart', filtersValues: this.data_filter_details, filters: true};
    this.eventsService.chargeActivityExcelInfoLoaded$.next(excelInfo);

  }

  // Method to export the graph data to an Excel file
  exportAsXLSX() {
    this.getInfoForExcel();
    this.excelService.exportAsExcelFile([this.data_total.flat()], this.data_sheet_names,  'charge_activity_chart', this.data_filter_details);
  }

  // Method to retrieve the data to export
  private getDataToExport() {
    const headers = ['', this.groupedVerticalGraph.map(v => v.name)];
    const values: any[] = [];

    for (let _i = 0; _i < 2; _i++) {
      const array = [this.groupedVerticalGraph[0].series[_i].name];
      for (let item of this.groupedVerticalGraph) {
        array.push(item.series[_i].value);
      }
      values.push(array);
    }

    this.data_total.push([headers.flat()], values);
    
    const horizontalData = this.horizontalGraph.map(v => {
      return [v.name + ' (%)', v.series.map(i => i.value)].flat();
    });

    this.data_total.push(horizontalData);

  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

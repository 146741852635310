import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-thermometer',
  templateUrl: './thermometer.component.html',
  styleUrls: ['./thermometer.component.scss']
})
export class ThermometerComponent implements OnInit {
  @Input() height: string = "20";
  @Input() borderRadius: string = "10";
  @Input() leftColor: string = "#4c8489";
  @Input() rightColor: string = "#88a8ab";
  @Input() markerPosition: string = "0";
  @Input() textBottomLeft: string = "";
  @Input() textBottomRight: string = "";

  lines = Array;
  num: number = 19;

  constructor() { }

  ngOnInit(): void {
  }

  getContainerStyles() {
    let styles = {
      'border-radius.px': this.borderRadius,
    }
    return styles;
  }

  getInnerContainerStyles() {
    let styles = {
      'height.px': this.height,
      'border-radius.px': this.borderRadius,
      'background-color': this.leftColor,
      'background': `linear-gradient(90deg, ${this.leftColor} 0%, ${this.rightColor} 100%)`,
    }
    return styles;
  }

  getMarkerStyles() {
    let styles = {
      'left': `calc(${this.markerPosition}% - 3px)`,
    }
    return styles;
  }
  

}

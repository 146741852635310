<div class="fancy-selector">
    <div class="btn-fancy-selector">
        <app-btn-filter
            [title]="title"
            [inactiveIcon]="inactiveIcon"
            [activeIcon]="activeIcon"
            [isActive]="isActive"
            (open)="onOpen($event)"
            (close)="onClose($event)">
        </app-btn-filter>
    </div>
    <div class="fancy-selector-content" *ngIf="showMenu">
        <span class="menu-item text-white my-2 d-block" *ngFor="let menuItem of menuItems" (click)="selectItem($event, menuItem)">
            {{ menuItem.name }}
        </span>
    </div>
</div>
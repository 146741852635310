import { Component, Input, OnInit } from '@angular/core';
import _ from 'lodash';
import { Breadcrumb } from 'src/app/shared/models/breadcrumbs';
import { EventsService } from '../../services/events/events.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() breadcrumbs: Breadcrumb[] = [];
  @Input() color: string = "#95acb9";
  @Input() activeColor: string = "#40687f";
  itemColor: string = "";
  showEllipsis: boolean = false;
  reducedBreadcrumbs: any;
  selectedBreadcrumbId: string;

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void { 
    if (this.breadcrumbs.length > 2) {
      this.showEllipsis = true;
      this.reducedBreadcrumbs = _.cloneDeep(this.breadcrumbs).splice(this.breadcrumbs.length-2);
    } else {
      this.reducedBreadcrumbs = this.breadcrumbs;
    }
    this.breadcrumbs = this.breadcrumbs.slice(1);
    this.selectedBreadcrumbId = this.breadcrumbs.filter(v => v.active === true)[0]?.id;
  }

  getNameStyles(breadcrumb: Breadcrumb) {
    this.itemColor = breadcrumb.active ? this.activeColor : this.color;
    let styles;
    styles = {
      'color': this.itemColor,
      'cursor': breadcrumb.id ? 'pointer' : 'auto'
    }
    return styles;
  }

  getSeparatorStyles() {
    let styles;
    styles = {
      'color': this.color,
    }
    return styles;
  }

  getEllipsisStyles() {
    let styles;
    styles = {
      'color': '#95acb9'
    }
    return styles;
  }

  getBreadcrumbListStyles(breadcrumb) {
    let styles;
    styles = {
      'color': this.activeColor,
      'cursor': breadcrumb.id ? 'pointer' : 'auto'
    }
    return styles;
  }

  goToEntityInfo(id: string) {
    if (id && id !== this.selectedBreadcrumbId) {
      const data = {
        breadcrumbAction: 'remove',
        id: id
      }
      this.eventsService.networkGraphSelectedNode$.emit(data);
    }
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { single } from './data';
import { calculateViewDimensions, ViewDimensions } from '@swimlane/ngx-charts';
import { COLORS } from 'src/app/shared/globals/globals';
import { forkJoin, Subject } from 'rxjs';
import { trim } from 'lodash';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators'
import { CollectionRate } from 'src/app/shared/models/collection-rate';

@Component({
  selector: 'cancellation-charge-block-graph',
  templateUrl: './cancellation-charge-block-graph.component.html',
  styleUrls: ['./cancellation-charge-block-graph.component.scss']
})
export class CancellationChargeBlockGraphComponent implements OnInit, OnDestroy {

  single: any[];

  // Options for the graph
  width: number = 200;
  height: number = 200;
  view: any[] = [330, 120];
  dims: ViewDimensions;
  margins: any = [0, 0, 0, 0];
  customColors: any = [];
  showXAxis: boolean = true;
  showYAxis: boolean = false;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';
  colorScheme: any = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  roundDomains: boolean = true;
  showDataLabel: boolean = true;
  barPadding: number = 75;
  roundEdges: boolean = false;
  rotateXAxisTicks: boolean = false;

  // Labels options
  labelWidth: number = 330;
  labelDiff_01: number = 0.34;
  labelDiff_02: number = 1.51;
  viewBox1: any = [43, -3, 117, 20];
  viewBox2: any = [-43, -3, 117, 20];
  // viewBox3: any = [0, -3, 50, 20];


  unsubscribe$: Subject<any> = new Subject<any>();
  targetName: string;
  monthNames: any;
  filtersValues: any;
  month: string;
  year: string;
  loaded: boolean = false;

  amountGraphData: any = [];
  receiptsGraphData: any = [];
  amountYearDiff: number;
  receiptYearDiff: number;
  amountTargetDiff: number;
  receiptTargetDiff: number;

  graphDataToPrint: any = [];
  yearDiff: number;
  targetDiff: number;

  // Option tabs
  actualOption: string = 'panels.amount';
  optionTabAmount: string = 'panels.amount';
  optionTabReceipt: string = 'panels.receipts';
  graphName: string = 'collectionRateCharge';

  constructor(
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private eventsService: EventsService,
    private translateService: TranslateService
  ) {
    Object.assign(this, { single })
  }

  ngOnInit(): void {
    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margins
    });

    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const target = this.translateService.get('labels.target');
          const months = this.translateService.get('abbreviatedMonthNames')
      
          forkJoin([target, months]).pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              {
                next: results => {
                  this.targetName = results[0];
                  this.monthNames = results[1];
                },
                complete: () => {
                  if (data.length !== 0) {
                    this.filtersValues = data;
                    this.month = this.filtersValues.filter(v => v.name === 'month')[0].value;
                    this.year = this.filtersValues.filter(v => v.name === 'year')[0].value;
                    this.receiptsGraphData = [];
                    this.amountGraphData = [];
                    if (this.actualOption === this.optionTabAmount) {
                      this.retrieveCollectionRate('p', this.filtersValues);
                    } else if (this.actualOption === this.optionTabReceipt) {
                      this.retrieveCollectionRate('c', this.filtersValues);
                    }
                    
                  }
                }
              }
            );
        });

    this.eventsService.policiesBonusOption$.subscribe(
      data => {
        const option = data[0];
        const name = data[1];
        if ((option === this.optionTabAmount || option === this.optionTabReceipt) &&  name === this.graphName) {
          this.actualOption = option;
          this.assignSelectedData();
        }
      }
    );
  }

  // Method to retrieve the collection rate
  private retrieveCollectionRate(type_data: string, filtersValues: any) {

    this.loaded = false;

    this.apimstService.getCollectionRate(type_data, filtersValues)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (data: CollectionRate) => {
        const collectionRate = data;

        let dataGraph: any = [];

        // Diffs
        const yearDiff = (collectionRate.diff || trim(collectionRate.diff).length>0) ? parseFloat(collectionRate.diff.replace(/,/g, '')) : 0;
        const targetDiff = (collectionRate.target_diff || trim(collectionRate.target_diff).length>0) ? parseFloat(collectionRate.target_diff.replace(/,/g, '')) : 0;

        // Previous year
        dataGraph.push(
          {
            name: collectionRate.month ? (this.monthNames[collectionRate.month] + ' ' + (parseFloat(this.year)-1).toString().slice(2,4)) : '',
            value: (collectionRate.previous || trim(collectionRate.previous).length>0) ? parseFloat(collectionRate.previous.replace(/,/g, '')) : 0
          }
        );

        // Actual year
        dataGraph.push(
          {
            name: collectionRate.month ? (this.monthNames[collectionRate.month] + ' ' + parseFloat(this.year).toString().slice(2,4)) : ' ',
            value: (collectionRate.value || trim(collectionRate.value).length>0) ? parseFloat(collectionRate.value.replace(/,/g, '')) : 0
          }
        );

        // Target
        dataGraph.push(
          {
            name: this.targetName,
            value: (collectionRate.target || trim(collectionRate.target).length>0) ? parseFloat(collectionRate.target.replace(/,/g, '')) : 0
          }
        );

        if (type_data === 'p') {
          this.amountGraphData = dataGraph;
          this.amountTargetDiff = targetDiff;
          this.amountYearDiff = yearDiff;

        } else if (type_data === 'c') {
          this.receiptsGraphData = dataGraph;
          this.receiptTargetDiff = targetDiff;
          this.receiptYearDiff = yearDiff;
        }

        this.assignDataToPrint();
        this.assignColorBar();
        
        this.loaded = true;

      },

      error => console.log('An error ocurred while retrieving collection rate data ' + error)
      
      );

  }

  // Method to assign data to print
  private assignDataToPrint() {
    if (this.actualOption === this.optionTabReceipt) {
      this.graphDataToPrint = this.receiptsGraphData;
      this.yearDiff = this.receiptYearDiff;
      this.targetDiff = this.receiptTargetDiff;
    } else if (this.actualOption === this.optionTabAmount) {
      this.graphDataToPrint = this.amountGraphData;
      this.yearDiff = this.amountYearDiff;
      this.targetDiff = this.amountTargetDiff;
    }
  }

  // Method to assign data according to the selected option
  private assignSelectedData() {
    if (this.actualOption === this.optionTabReceipt && this.receiptsGraphData.length === 0) {
      this.retrieveCollectionRate('c', this.filtersValues);
    } else if (this.actualOption === this.optionTabAmount && this.amountGraphData.length === 0) {
      this.retrieveCollectionRate('p', this.filtersValues);
    } else {
      this.assignDataToPrint();
    }
  }

  // Method to assign the colors to the graph bars
  private assignColorBar() {
    this.customColors.push(
      {
        name: this.monthNames[this.month] + ' ' + (parseFloat(this.year) -1).toString().slice(2,4),
        value: COLORS.darkGreen
      },
      {
        name: this.monthNames[this.month] + ' ' + (this.year).slice(2,4),
        value: COLORS.lightGreen
      },
      {
        name: this.targetName,
        value: COLORS.darkGreen
      })
  }

  formatDataLabel(value) {
    return Intl.NumberFormat("de-DE").format(value) + '%';
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<component-loader *ngIf="!loaded" [width]="580" [height]="160"></component-loader>

<div *ngIf="loaded">
    <stacked-vertical-horizontal-graph
      [view]="view"
      [scheme]="comboBarScheme"
      [colorSchemeLine]="lineChartScheme"
      [customColors]="customColors"
      [results]="barChart"
      [animations]="animations"
      [lineChart]="lineChartSeries"
      [yAxisTickFormatting]="yAxisTickFormatting"
      [yLeftAxisScaleFactor]="yLeftAxisScaleFactor"
      [yRightAxisScaleFactor]="yRightAxisScaleFactor"
      [yRightAxisTickFormatting]="yRightAxisTickFormatting"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [rightYAxis]="showRightYAxis"
      [legend]="showLegend"
      [legendTitle]="legendTitle"
      [legendPosition]="legendPosition"
      [showGridLines]="showGridLines"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [showRightYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [barPadding]="barPadding"
      [tooltipDisabled]="tooltipDisabled"
      [schemeType]="schemeType"
      [roundDomains]="roundDomains"
      [noBarWhenZero]="noBarWhenZero"
      [rotateXAxisTicks]="rotateXAxisTicks"
      [showTotalInTooltip]="showTotalInTooltip"
      [showPercentageSymbolInTooltip]="showPercentageSymbolInTooltip">
    </stacked-vertical-horizontal-graph>
</div>
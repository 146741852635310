<div class="mt-1">
    <component-loader *ngIf="!loaded" [width]="410" [height]="170"></component-loader>
</div>

<div *ngIf="loaded" class="d-flex justify-content-end align-items-center">
    <app-legend class="mr-2" name="labels.tnps" color="pink" type="line" gradient="v-gradient"></app-legend>
</div>

<div class="ml-n4 combo-chart-nps-sales line-charts-gen dashed-order-2 ng-star-inserted" *ngIf="loaded">
    <stacked-vertical-horizontal-graph
        [view]="view"
        [scheme]="comboBarScheme"
        [colorSchemeLine]="lineChartScheme"
        [customColors]="customColors"
        [results]="qualityNPSRolling"
        [animations]="animations"
        [lineChart]="lineChartSeries"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [yLeftAxisScaleFactor]="yLeftAxisScaleFactor"
        [yRightAxisScaleFactor]="yRightAxisScaleFactor"
        [yRightAxisTickFormatting]="yRightAxisTickFormatting"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [legendTitle]="legendTitle"
        [legendPosition]="legendPosition"
        [showGridLines]="showGridLines"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [showRightYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [tooltipDisabled]="tooltipDisabled"
        [barPadding]="barPadding"
        [schemeType]="schemeType"
        [roundDomains]="roundDomains"
        [noBarWhenZero]="noBarWhenZero"
        [showTotalInTooltip]="showTotalInTooltip">
    </stacked-vertical-horizontal-graph>
</div>

<!-- <div class="line-charts-gen dashed-order-2" *ngIf="loaded">

    <ngx-charts-area-chart-stacked
        [view]="view"
        [scheme]="colorScheme"
        [legend]="legend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [xAxisTicks]="xAxisTicksFinal"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [timeline]="timeline"
        [results]="qualityNPSRolling"
        (select)="onSelect($event)"
        (activate)="onActivate($event)">
        <ng-template #seriesTooltipTemplate let-model="model">
            <div class="series-tooltip-template-inner">  
                <span class="d-flex align-items-center my-1" *ngFor="let item of model">
                    <span class="tooltip-color-tag" 
                        [ngStyle]="{'background-color': item.color}">
                    </span> 
                    <span>{{ item.series }}: {{ item.value }}</span>
                </span>
                <span class="d-flex align-items-center my-1">
                    <span class="tooltip-color-tag" 
                        [ngStyle]="{'background-color': '#000000'}">
                    </span> 
                    <span>{{ averageName }}: {{ setTooltipRate(model) }}</span>
                </span>
            </div>
        </ng-template>
    </ngx-charts-area-chart-stacked>

</div> -->
<p class="section-title">
    <!-- {{ 'panels.monthlySale' | translate }} -->
    {{ sectionTitle | translate }}
    <span class="material-icons ml-2 fullscreen-icon" [mat-dialog-close]="true" >fullscreen_exit</span>
</p>

<div class="pie-graph-gen mt-n3">
    <ngx-charts-pie-chart 
        [view]="view" 
        [scheme]="colorScheme" 
        [results]="results" 
        [gradient]="gradient"
        [legend]="showLegend" 
        [legendPosition]="legendPosition" 
        [legendTitle]="legendTitle"
        [labels]="showLabels" 
        [doughnut]="isDoughnut"
        [arcWidth]="arcWidth"
        [tooltipDisabled]="tooltipDisabled"
        [animations]="animations"
        [labelFormatting]="labelFormatting.bind(this, results)"
        (activate)="activate($event)">
        <ng-template #tooltipTemplate let-model="model">
            <div class="pie-tooltip">
                <span class="pie-tooltip-title d-block">{{ tooltipTitle }}</span>
                <span class="pie-tooltip-data d-block">{{ tooltipNumber }}</span>
            </div>
        </ng-template>
    </ngx-charts-pie-chart>
</div>
import { Component, OnInit, EventEmitter } from '@angular/core';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fixed-stock-churn-rate-per-crop',
  templateUrl: './fixed-stock-churn-rate-per-crop.component.html',
  styleUrls: ['./fixed-stock-churn-rate-per-crop.component.scss']
})
export class FixedStockChurnRatePerCropComponent implements OnInit {

  // Component variables
  policy: string = 'panels.policy';
  bonus: string = 'panels.bonus';
  graphName: string = 'fixedStockHarvest';

  churnRatePerCropExcelButton: EventEmitter<any> = new EventEmitter();
  loadedGraph: boolean;

  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "cancellation-fixed-stock--fixed-stock-churn-rate-per-crop";

  unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private i18n: I18nService,
    private atlasHelper: AtlasHelperService
  ) { }

  ngOnInit(): void {
    this.subscribeToActiveLanguage();
  }

  // Method to emit an event when Excel export button is clicked
  exportAsXLSX() {
    this.churnRatePerCropExcelButton.emit(true);
  }

  // Method to detect if the graph has been loaded
  detectLoadedGraph(loaded: boolean) {
    if (loaded) {
      this.loadedGraph = true;
    }
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

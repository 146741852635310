import { Component, OnInit } from '@angular/core';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { environment } from 'src/environments/environment';
import { RejectionsMainService } from './rejections-main.service';

@Component({
  selector: 'app-rejections-main',
  templateUrl: './rejections-main.component.html',
  styleUrls: ['./rejections-main.component.scss']
})
export class RejectionsMainComponent implements OnInit {

  pageName: string;
  pages = [
    { name: 'lr-evolution' },
    { name: 'lr-line-graph' },
    { name: 'lr-pie-graphs' }
  ]

  graphName: string = 'sinistersRejections';
  panelOptions: any[] = [
    { id: "rejectionsAmount", name: "panels.amount", selected: true, graphName: this.graphName },
    { id: "rejectionsSinisters", name: "panels.sinisters", selected: false, graphName: this.graphName }
  ];

  loaded: boolean = false;

  dropdownTitle: string = "";
  rejectionsReasons: any[] = [];
  dropdownId: string = "rejectionsReasons";
  allM: string = "";

  selectorRawInfo: any[] = [];

  isLoading: boolean = false;

  amountInfoCopy: any = {};
  sinistersInfoCopy: any = {};

  unsubscribe$: Subject<any> = new Subject<any>();

  filtersValues: any;

  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "sinisters-rejections--rejections-and-closings";
  
  constructor(
    private eventsService: EventsService,
    private rejectionsMainService: RejectionsMainService,
    private i18n: I18nService,
    private atlasHelper: AtlasHelperService
  ) { }

  ngOnInit(): void {
    this.subscribeToActiveLanguage();
    this.eventsService.optionTabMultipleChanged$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: selectedOption => {
          if (selectedOption['graphName'] == this.graphName) {
            this.rejectionsMainService.panelSelectedOption$.next(selectedOption);
          }
        }
      })
      
    this.rejectionsMainService.panelSelectedOption$.next(this.panelOptions[0]);
  }

  getSection(event): void {
    this.pageName = event.name;
  }

  isValidValue(value: any = {}) {
    const isValid = value.rejection_reason_id != "-1" && value.rejection_reason_id != "-2";
    return isValid;
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}


<div class="mt-3">
    <component-loader [width]="580" [height]="210" *ngIf="!loaded"></component-loader>
    <div *ngIf="loaded">
        <div class="wrapper">
            <table class="table table-loss-ratio">
                <thead>
                    <tr class="table-header">
                        <th [title]="'panels.branch' | translate">{{ 'panels.branch' | translate }}</th>
                        <th [title]="'panels.branch' | translate">{{ 'panels.lossRatio' | translate }}</th>
                        <th [title]="'panels.branch' | translate">{{ 'panels.comparative' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-body" *ngFor="let branch of tableInfo">
                        <td [title]="branch?.branch">{{ branch?.branch }}</td>
                        <td>{{ branch?.loss_ratio }}</td>
                        <td>{{ branch?.comparativa }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-monthly-sales-graph-dialog',
  templateUrl: './monthly-sales-graph-dialog.component.html',
  styleUrls: ['./monthly-sales-graph-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MonthlySalesGraphDialogComponent implements OnInit {

  // Graph options
  sectionTitle: string;
  view: any[];
  showXAxis: boolean;
  showYAxis: boolean;
  gradient: boolean;
  showLegend: boolean;
  legendTitle: string;
  legendPosition: string;
  showXAxisLabel: boolean;
  showYAxisLabel: boolean;
  yAxisTickFormatting: string;
  yLeftAxisScaleFactor: string;
  yRightAxisScaleFactor: string;
  yRightAxisTickFormatting: string;
  showGridLines: boolean;
  showRightYAxisLabel: boolean;
  innerPadding: string;
  xAxisLabel: string;
  yAxisLabel: string;
  yAxisLabelRight: string;
  barPadding: number = 8;
  tooltipDisabled: boolean;
  roundDomains: boolean;
  animations: boolean;
  noBarWhenZero: boolean;
  barChart: any[];
  lineChartSeries: any[];
  lineChartScheme: any[];
  comboBarScheme: any[];
  schemeType: string;
  customColors: any;
  dialogLegends: any;
  showTotalInTooltip: boolean = true;

  // Default constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) data
  ) {
      this.sectionTitle = data.sectionTitle,
      this.view = data.view,
      this.showXAxis = data.showXAxis,
      this.showYAxis = data.showYAxis,
      this.gradient = data.gradient,
      this.showLegend = data.showLegend,
      this.legendTitle = data.legendTitle,
      this.legendPosition = data.legendPosition,
      this.showXAxisLabel = data.showXAxisLabel,
      this.showYAxisLabel = data.showYAxisLabel,
      this.yAxisTickFormatting = data.yAxisTickFormatting,
      this.yLeftAxisScaleFactor = data.yLeftAxisScaleFactor,
      this.yRightAxisScaleFactor = data.yRightAxisScaleFactor,
      this.yRightAxisTickFormatting = data.yRightAxisTickFormatting,
      this.showGridLines = data.showGridLines,
      this.showRightYAxisLabel = data.showRightYAxisLabel,
      this.innerPadding = data.innerPadding,
      this.xAxisLabel = data.xAxisLabel,
      this.yAxisLabel = data.yAxisLabel,
      this.yAxisLabelRight = data.yAxisLabelRight,
      this.tooltipDisabled = data.tooltipDisabled,
      this.roundDomains = data.roundDomains,
      this.animations = false,
      this.noBarWhenZero = data.noBarWhenZero,
      this.barChart = data.barChart,
      this.lineChartSeries = data.lineChartSeries,
      this.lineChartScheme = data.lineChartScheme,
      this.comboBarScheme = data.comboBarScheme,
      this.schemeType = data.schemeType,
      this.customColors = data.customColors,
      this.dialogLegends = data.dialogLegends
   }

  // OnInit 
  ngOnInit(): void {
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs'
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { takeUntil } from 'rxjs/operators'
import { trim } from 'lodash';
import { CurrencyService } from 'src/app/shared/services/currency/currency.service';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { EventsService } from 'src/app/shared/services/events/events.service';

@Component({
  selector: 'app-btn-sale',
  templateUrl: './btn-sale.component.html',
  styleUrls: ['./btn-sale.component.scss']
})
export class BtnSaleComponent implements OnInit, OnDestroy {

  highLowPolicy: any = {}
  policyQNumber: any = {}
  bonusPxQ: any = {}
  gwp: any = {}
  projectionCrPlan: any = {}
  projectionOmPlan: any = {}
  currencySymbol: string = '';
  percentagePrevious: string = "labels.pm";

  // Format pipe
  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  // Check number symbol
  isNegative: boolean = false;

  // Subscription
  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;
  loaded: boolean = false;
  currencySubscription: Subscription
  userInfoSubscription: Subscription

  constructor(
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private currencyService: CurrencyService,
    private utilsService: UtilsService,
    private events: EventsService
  ) { }

  ngOnInit(): void {

    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data.length !== 0) {
            this.filtersValues = data;
            this.retrieveHomeSales();
          }
        }
      );

      this.currencySubscription = this.currencyService.currencyInfo$
      .subscribe(
        currencyInfo => {
          this.currencySymbol = currencyInfo.symbol;
        }
      )

  }


  // Method to retrieve the home sale indicators
  private retrieveHomeSales() {

    this.loaded = false;

    const highLowPolicy = this.apimstService.getHomeSaleIndicators('sp', this.filtersValues);
    const policyQNumber = this.apimstService.getHomeSaleIndicators('ccn', this.filtersValues);
    const bonusPxQ = this.apimstService.getHomeSaleIndicators('cpn', this.filtersValues);
    const gwp = this.apimstService.getGWPResume('gs', this.filtersValues);
    const projectionPlan = this.apimstService.getSalesBudgetProgress('p', this.filtersValues);

    forkJoin([highLowPolicy, policyQNumber, bonusPxQ, gwp, projectionPlan])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        results => {

          let highLowPolicyData;
          let policyQNumberData;
          let bonusPxQData;
          let gwpData;
          let projectionPlanData;

          results[0] ? highLowPolicyData = results[0] : highLowPolicyData = {};
          results[1] ? policyQNumberData = results[1] : policyQNumberData = {};
          results[2] ? bonusPxQData = results[2] : bonusPxQData = {};
          results[3] ? gwpData = results[3] : gwpData = {};
          results[4] ? projectionPlanData = results[4] : projectionPlanData = {};

          const projectionCR = projectionPlanData.filter(v => v.name === 'Credit Related (CR)')[0];
          const projectionOM = projectionPlanData.filter(v => v.name === 'Open Market (OM)')[0];

          this.highLowPolicy = {
            title: 'kpisHeader.hightLowPolicy',
            main_value: (highLowPolicyData.value || trim(highLowPolicyData.value).length > 0) ? this.formatPipe.transform( parseFloat(highLowPolicyData.value.replace(/,/g, '')) ) : -1,
            trend_value_previous: this.percentagePrevious,
            trend_value: (highLowPolicyData.diff || trim(highLowPolicyData.diff).length > 0) ? this.formatPipe.transform( parseFloat(highLowPolicyData.diff.replace(/-|,/g, '')) ) : 0,
            trend_color: (highLowPolicyData.diff_trend || trim(highLowPolicyData.diff_trend).length > 0) ? parseFloat(highLowPolicyData.diff_trend.replace(/,/g, '')) : 0,
            trend_direction: this.utilsService.isNegative( highLowPolicyData.diff ) == false ? 1 : 0
          };

          this.policyQNumber = {
            title: 'labels.policyQNumber',
            main_value: (policyQNumberData.value || trim(policyQNumberData.value).length > 0) ? this.formatPipe.transform( parseFloat(policyQNumberData.value.replace(/,/g, '')) ) : -1,
            trend_value_previous: this.percentagePrevious,
            trend_value: (policyQNumberData.diff || trim(policyQNumberData.diff).length > 0) ? this.formatPipe.transform( parseFloat(policyQNumberData.diff.replace(/-|,/g, '')) ) : 0,
            trend_color: (policyQNumberData.diff_trend || trim(policyQNumberData.diff_trend).length > 0) ? parseFloat(policyQNumberData.diff_trend.replace(/,/g, '')) : 0,
            trend_direction: this.utilsService.isNegative( policyQNumberData.diff ) == false ? 1 : 0
          };

          this.bonusPxQ = {
            title: 'labels.bonusPxQ',
            main_value: (bonusPxQData.value || trim(bonusPxQData.value).length > 0) ? this.formatPipe.transform( parseFloat(bonusPxQData.value.replace(/,/g, '')) ) : -1,
            trend_value_previous: this.percentagePrevious,
            trend_value: (bonusPxQData.diff || trim(bonusPxQData.diff).length > 0) ? this.formatPipe.transform( parseFloat(bonusPxQData.diff.replace(/-|,/g, '')) ) : 0,
            trend_color: (bonusPxQData.diff_trend || trim(bonusPxQData.diff_trend).length > 0) ? parseFloat(bonusPxQData.diff_trend.replace(/,/g, '')) : 0,
            trend_direction: this.utilsService.isNegative( bonusPxQData.diff ) == false ? 1 : 0,
            currency: this.currencySymbol
          };

          if( gwpData.hasOwnProperty('gwp')) {
            this.gwp = {
              title: 'panels.gwp',
              main_value: (gwpData.gwp || trim(gwpData.gwp).length > 0) ? this.formatPipe.transform( parseFloat( gwpData.gwp.toString().replace(/,/g, '') ) ) : -1,
              trend_value_previous: this.percentagePrevious,
              trend_value: (gwpData.gwp_diff || trim(gwpData.gwp_diff).length > 0) ? this.formatPipe.transform( parseFloat(gwpData.gwp_diff.toString().replace(/-|,/g, '')) ) : 0,
              trend_color: (gwpData.gwp_diff_trend || trim(gwpData.gwp_diff_trend).length > 0) ? parseFloat(gwpData.gwp_diff_trend.toString().replace(/,/g, '')) : 0,
              trend_direction: this.utilsService.isNegative( gwpData.gwp_diff.toString() ) == false ? 1 : 0,
              currency: this.currencySymbol
            };
          } else {
            this.gwp = {
              title: 'panels.gwp',
              main_value: -1,
              trend_value: 0,
              trend_color: 0,
              trend_direction: 0,
            };
          }

          if( projectionCR != undefined ) {
            this.projectionCrPlan = {
              title: 'labels.projectionCrPlan',
              main_value: (projectionCR.value || trim(projectionCR.value).length > 0) ? this.formatPipe.transform( parseFloat(projectionCR.value.replace(/,/g, '')) ) : -1,
              trend_value_previous: this.percentagePrevious,
              trend_value: (projectionCR.diff || trim(projectionCR.diff).length > 0) ? this.formatPipe.transform( parseFloat(projectionCR.diff.replace(/-|,/g, '')) ) : 0,
              trend_color: (projectionCR.target_diff_trend || trim(projectionCR.target_diff_trend).length > 0) ? parseFloat(projectionCR.target_diff_trend.replace(/,/g, '')) : 0,
              trend_direction: this.utilsService.isNegative( projectionCR.diff ) == false ? 1 : 0,
              currency: this.currencySymbol
            };
          } else {
            this.projectionCrPlan = {
              title: 'labels.projectionCrPlan',
              main_value: -1,
              trend_value: 0,
              trend_color: 0,
              trend_direction: 1,
              currency: this.currencySymbol
            };
          }

          if( projectionOM != undefined ) {
            this.projectionOmPlan = {
              title: 'labels.projectionOmPlan',
              main_value: (projectionOM.value || trim(projectionOM.value).length > 0) ? this.formatPipe.transform( parseFloat(projectionOM.value.replace(/,/g, '')) ) : -1,
              trend_value_previous: this.percentagePrevious,
              trend_value: (projectionOM.diff || trim(projectionOM.diff).length > 0) ? this.formatPipe.transform( parseFloat(projectionOM.diff.replace(/-|,/g, '')) ) : 0,
              trend_color: (projectionOM.target_diff_trend || trim(projectionOM.target_diff_trend).length > 0) ? parseFloat(projectionOM.target_diff_trend.replace(/,/g, '')) : 0,
              trend_direction: this.utilsService.isNegative( projectionOM.diff ) == false ? 1 : 0,
              currency: this.currencySymbol
            };
          } else {
            this.projectionOmPlan = {
              title: 'labels.projectionOmPlan',
              main_value: -1,
              trend_value: 0,
              trend_color: 0,
              trend_direction: 1,
              currency: this.currencySymbol
            };
          }

          this.loaded = true;

        }
      );
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if(this.currencySubscription) this.currencySubscription.unsubscribe();
    if(this.userInfoSubscription) this.userInfoSubscription.unsubscribe();
  }

}

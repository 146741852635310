<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex">
            <div class="d-flex align-items-center">
                <span class="title section-card-title">{{ 'panels.churnRatePerCrop' | translate }}</span>
                <app-btn-gobernance-info 
                    class="ml-2" 
                    [endpoint]="endpoint" 
                    [endpointParams]="endpointParams"
                    *ngIf="hasGobernanceInfo">
                </app-btn-gobernance-info>
            </div>
            <mat-icon *ngIf="loadedGraph" class="excel-icon ml-2 icon-margin" svgIcon="excel" (click)="exportAsXLSX()"></mat-icon>
        </div>
        <app-option-tab [leftOption]=bonus [rightOption]=policy [graphName]=graphName class="option-tab align-items-center"></app-option-tab>
    </div>


    <!-- Bar graphic -->
    <div class="d-flex mt-2 mb-1">
        <app-churn-rate-per-crop-graphic [excelExportEvent]="churnRatePerCropExcelButton" (loadedGraph)="detectLoadedGraph($event)"></app-churn-rate-per-crop-graphic>
    </div>

    <!-- Colors bar graphic -->
    <!-- <div class="mt-1 mb-1">
        <app-churn-rate-per-crop-colors-bar></app-churn-rate-per-crop-colors-bar>
    </div> -->

</app-widget>
<div class="big-button-grid big-button-grid-cancellation">

    <div class="big-button-grid-main-container">
        <div class="big-button-grid-top">
            <div class="section-header-block d-flex justify-content-between align-items-center cursor-pointer" [routerLink]="['/cancellation/fixed-stock']">
                <span class="title section-card-title-btn-grid">{{ 'panels.cancellation' | translate | uppercase }}<sup>*</sup> </span>
                <mat-icon class="home-arrows-right arrow-round-right fill-white" svgIcon="arrow-right-round"></mat-icon>
            </div>

            <component-loader *ngIf="!loaded" class="mx-5 text-center transparent-background" [width]="" [height]="340"></component-loader>
            <div *ngIf="loaded">
                <div class="home-kpi-block d-flex justify-content-between align-items-start">
                    <div class="col-6 pl-0 pr-2">
                        <app-kpi-home [item]="cancellationRate"></app-kpi-home>
                    </div>
                    <div class="col-6 pl-2 pr-0">
                        <app-kpi-home [item]="cancellationBonusRate"></app-kpi-home>
                    </div>
                </div>
                <div class="home-graph-container">
                    <div class="home-graph">
                        <ngx-charts-line-chart
                            [scheme]="colorScheme"
                            [legend]="legend"
                            [showXAxisLabel]="showXAxisLabel"
                            [showYAxisLabel]="showYAxisLabel"
                            [xAxis]="xAxis"
                            [yAxis]="yAxis"
                            [xAxisLabel]="xAxisLabel"
                            [yAxisLabel]="yAxisLabel"
                            [timeline]="timeline"
                            [results]="cancellationRateRolling"
                            >
                        </ngx-charts-line-chart>
                    </div>
                </div>
                <div class="home-kpi-block d-flex justify-content-between align-items-start mt-0">
                    <div class="col-6 pl-0 pr-2">
                        <app-kpi-home [item]="cancellationsMonth"></app-kpi-home>
                    </div>
                    <div class="col-6 pl-2 pr-0">
                        <app-kpi-home [item]="cancellationsPlan"></app-kpi-home>
                    </div>
                </div>
                <div class="home-kpi-block d-flex justify-content-between align-items-start">
                    <!-- <div class="col-6 pl-0 pr-2">
                        <app-kpi-home [item]="cancellationsTarget"></app-kpi-home>
                    </div> -->
                    <div class="col-6 pl-0 pr-2">
                        <app-kpi-home [item]="policyAverageDuration"></app-kpi-home>
                    </div>
                    <div class="col-6 pl-2 pr-0">
                        <app-kpi-home [item]="earlyFall"></app-kpi-home>
                    </div>
                </div>
                <!-- <div class="home-kpi-block d-flex justify-content-between align-items-start">
                    <div class="col-6 pl-0 pr-2">
                        <app-kpi-home [item]="earlyFall"></app-kpi-home>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="big-button-grid-bottom">
            <span class="small text-light d-block mb-1">* {{ 'labels.fixedStockInfo' | translate }}</span>
            <button 
                type="button" 
                class="btn btn-block btn-gradient-light mt-0 mb-3 d-flex justify-content-between align-items-center"
                [routerLink]="['/cancellation/fixed-stock']">
                <app-legend name="panels.fixedStockChurnRate" color="transparent" type="round" gradient="h-gradient"></app-legend>
                <span class="material-icons">keyboard_arrow_right</span>
            </button>
            <button 
                type="button" 
                class="btn btn-block btn-gradient-light mt-0 d-flex justify-content-between align-items-center"
                [routerLink]="['/cancellation/persistence']">
                <app-legend name="menu.persistence" color="transparent" type="round" gradient="h-gradient"></app-legend>
                <span class="material-icons">keyboard_arrow_right</span>
            </button>
        </div>

    </div>

    <div class="big-button-grid-bg">
        <img src="assets/img/bg-cancellation.jpg">
    </div>

</div>
<div class="hamburguer-menu d-flex align-items-center">
    <span class="hamburguer-icon material-icons mr-4 menu-icon" (click)="openMenu()">dehaze</span>
    <div class="text-block">
        <span class="menu-title d-flex align-items-center mb-0">
            <span class="title">{{ title }}</span>
            <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams" 
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </span>
        <span class="menu-date d-block text-light">{{ monthDate | translate }} {{  yearDate }}</span>
        <span class="menu-currency d-block text-light" *ngIf="currencyIso">{{ countryName }} - {{ currencyIso }} ({{ currencySymbol }})</span>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { trim } from 'lodash';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { CurrencyService } from 'src/app/shared/services/currency/currency.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-btn-sinister',
  templateUrl: './btn-sinister.component.html',
  styleUrls: ['./btn-sinister.component.scss']
})
export class BtnSinisterComponent implements OnInit {

  // Format pipe
  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  sinistersActive: boolean = environment.menuVisibility.sinisters;

  // Check number symbol
  isNegative: boolean = false;

  claimsPaidAmount: any = {};
  technicalRejectionRate: any = {};
  lossRatio: any = {};
  claimPercentage: any = {};
  closingRatioQuantity: any = {};
  closingRatio: any = {};

  currencySymbol: string = "";
  percentageSymbol: string = "%";

  percentagePrevious: string = "labels.pm";
  closedName: string = "";
  paidPlusRejectedName: string = "";

  // Subscription
  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;
  loaded: boolean = false;
  currencySubscription: Subscription
  userInfoSubscription: Subscription

  constructor(
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private currencyService: CurrencyService,
    private utilsService: UtilsService,
    private events: EventsService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {

    if (this.sinistersActive) {
      this.filtersService.filtersValues$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          data => {
            this.getTranslations();

            if (data.length !== 0) {
              this.filtersValues = data;
              this.retrieveHomeSinisters();
            }
          }
        );

      this.currencySubscription = this.currencyService.currencyInfo$
        .subscribe(
          currencyInfo => {
            this.currencySymbol = currencyInfo.symbol;
          }
        )
    }

  }

  getTranslations(): void {
    const closedTRequest = this.translateService.get('labels.closed');
    const paidPlusRejectedTRequest = this.translateService.get('labels.paidPlusRejected');
    forkJoin([closedTRequest, paidPlusRejectedTRequest])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(results => {
        this.closedName = results[0];
        this.paidPlusRejectedName = results[1];
      });
  }

  private retrieveHomeSinisters() {
    this.loaded = false;

    const claimsPaidAmountRequest = this.apimstService.getHomeSinistersIndicators('spa', this.filtersValues);
    const technicalRejectionRateRequest = this.apimstService.getHomeSinistersIndicators('str', this.filtersValues);
    //const lossRatioRequest = this.apimstService.getSinistersLossRatio('lrr', this.filtersValues);
    const claimPercentageRequest = this.apimstService.getHomeSinistersIndicators('scper', this.filtersValues);
    const closingRatioRequest = this.apimstService.getHomeSinistersIndicators('scra', this.filtersValues);
    const requests = [claimsPaidAmountRequest, technicalRejectionRateRequest, claimPercentageRequest, closingRatioRequest]

    forkJoin(requests)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        results => {
          const claimsPaidAmountData = results[0] ? results[0] : {};
          const technicalRejectionRateData = results[1] ? results[1] : {};
          //const lossRatioData = results[2] ? results[2] : {};
          const claimPercentageData = results[2] ? results[2] : {};
          const closingRatioData = results[3] ? results[3] : {};

          this.claimsPaidAmount = {
            title: 'labels.paidSinistersAmount',
            main_value: (claimsPaidAmountData.value || trim(claimsPaidAmountData.value).length > 0) ? this.formatPipe.transform(parseFloat(claimsPaidAmountData.value.replace(/,/g, ''))) : -1,
            trend_value_previous: this.percentagePrevious,
            trend_value: (claimsPaidAmountData.diff || trim(claimsPaidAmountData.diff).length > 0) ? this.formatPipe.transform(parseFloat(claimsPaidAmountData.diff.replace(/-|,/g, ''))) : 0,
            trend_color: (claimsPaidAmountData.diff_trend || trim(claimsPaidAmountData.diff_trend).length > 0) ? parseFloat(claimsPaidAmountData.diff_trend.replace(/,/g, '')) : 0,
            trend_direction: this.utilsService.isNegative(claimsPaidAmountData.diff) == false ? 1 : 0,
            currency: this.currencySymbol
          };

          this.technicalRejectionRate = {
            title: 'labels.technicalRejectionRate',
            main_value: (technicalRejectionRateData.value || trim(technicalRejectionRateData.value).length > 0) ? this.formatPipe.transform(parseFloat(technicalRejectionRateData.value.replace(/,/g, ''))) : -1,
            trend_value_previous: this.percentagePrevious,
            trend_value: (technicalRejectionRateData.diff || trim(technicalRejectionRateData.diff).length > 0) ? this.formatPipe.transform(parseFloat(technicalRejectionRateData.diff.replace(/-|,/g, ''))) : 0,
            trend_color: (technicalRejectionRateData.diff_trend || trim(technicalRejectionRateData.diff_trend).length > 0) ? parseFloat(technicalRejectionRateData.diff_trend.replace(/,/g, '')) : 0,
            trend_direction: this.utilsService.isNegative(technicalRejectionRateData.diff) == false ? 1 : 0,
            main_value_suffix: this.percentageSymbol
          };

          // this.lossRatio = {
          //   title: 'labels.lossRatio',
          //   main_value: (lossRatioData[0].value || trim(lossRatioData[0].value).length > 0) ? this.formatPipe.transform(parseFloat(lossRatioData[0].value.replace(/,/g, ''))) : -1,
          //   trend_value_previous: this.percentagePrevious,
          //   trend_value: (lossRatioData[0].diff || trim(lossRatioData[0].diff).length > 0) ? this.formatPipe.transform(parseFloat(lossRatioData[0].diff.replace(/-|,/g, ''))) : 0,
          //   trend_color: (lossRatioData[0].diff_trend || trim(lossRatioData[0].diff_trend).length > 0) ? parseFloat(lossRatioData[0].diff_trend.replace(/,/g, '')) : 0,
          //   trend_direction: this.utilsService.isNegative(lossRatioData[0].diff) == false ? 1 : 0,
          //   main_value_suffix: this.percentageSymbol
          // };

          this.claimPercentage = {
            title: 'labels.claimsPercentageSinisters',
            main_value: (claimPercentageData.value || trim(claimPercentageData.value).length > 0) ? this.formatPipe.transform(parseFloat(claimPercentageData.value.replace(/,/g, ''))) : -1,
            trend_value_previous: this.percentagePrevious,
            trend_value: (claimPercentageData.diff || trim(claimPercentageData.diff).length > 0) ? this.formatPipe.transform(parseFloat(claimPercentageData.diff.replace(/-|,/g, ''))) : 0,
            trend_color: (claimPercentageData.diff_trend || trim(claimPercentageData.diff_trend).length > 0) ? parseFloat(claimPercentageData.diff_trend.replace(/,/g, '')) : 0,
            trend_direction: this.utilsService.isNegative(claimPercentageData.diff) == false ? 1 : 0,
            main_value_suffix: this.percentageSymbol
          };

          this.closingRatioQuantity = {
            title: 'labels.closingRatio',
            main_value: (closingRatioData.value || trim(closingRatioData.value).length > 0) ? this.formatPipe.transform(parseFloat(closingRatioData.value.replace(/,/g, ''))) + " " : -1,
            trend_value_previous: "",
            trend_value: undefined,
            trend_color: 0,
            trend_direction: 0,
            main_value_small: (this.closedName).toLowerCase()
          };

          this.closingRatio = {
            title: 'labels.paidPlusRejected',
            main_value: (closingRatioData.previous || trim(closingRatioData.previous).length > 0) ? this.formatPipe.transform(parseFloat(closingRatioData.previous.replace(/,/g, ''))) : -1,
            trend_value_previous: this.percentagePrevious,
            trend_value: (closingRatioData.diff || trim(closingRatioData.diff).length > 0) ? this.formatPipe.transform(parseFloat(closingRatioData.diff.replace(/-|,/g, ''))) : 0,
            trend_color: (closingRatioData.diff_trend || trim(closingRatioData.diff_trend).length > 0) ? parseFloat(closingRatioData.diff_trend.replace(/,/g, '')) : 0,
            trend_direction: this.utilsService.isNegative(closingRatioData.diff) == false ? 1 : 0,
            main_value_suffix: this.percentageSymbol
          };

          this.loaded = true;
        }
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.currencySubscription) this.currencySubscription.unsubscribe();
    if (this.userInfoSubscription) this.userInfoSubscription.unsubscribe();
  }

}

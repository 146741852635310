
<div class="clients-thermomether-block mt-3">
    
    <component-loader [width]="461" [height]="112" *ngIf="!loaded"></component-loader>
    <div class="mb-3" *ngIf="loaded">
        <div class="mb-1">
            <app-small-kpi
                [title]="indicators.age.title" 
                [mainValue]="indicators.age.mainValue" 
                [mainValueUnit]="indicators.age.mainValueUnit" 
                [percentage]="indicators.age.percentage" 
                [trend]="indicators.age.trend"
                [hasPercentage]="indicators.age.hasPercentage">
            </app-small-kpi>
        </div>
        <app-thermometer
            [markerPosition]="indicators.age.markerPosition"
            [textBottomLeft]="indicators.age.textBottomLeft"
            [textBottomRight]="indicators.age.textBottomRight">
        </app-thermometer>
    </div>

    <component-loader [width]="461" [height]="112" *ngIf="!loaded"></component-loader>
    <div *ngIf="loaded">
        <div class="mb-1">
            <app-small-kpi
                [title]="indicators.seniority.title" 
                [mainValue]="indicators.seniority.mainValue" 
                [mainValueUnit]="indicators.seniority.mainValueUnit" 
                [percentage]="indicators.seniority.percentage" 
                [trend]="indicators.seniority.trend">
            </app-small-kpi>
        </div>
        <app-thermometer
            [markerPosition]="indicators.seniority.markerPosition"
            [textBottomLeft]="indicators.seniority.textBottomLeft"
            [textBottomRight]="indicators.seniority.textBottomRight">
        </app-thermometer>
    </div>

</div>
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _, { trim } from 'lodash';
import { combineLatest, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { COLORS } from 'src/app/shared/globals/globals';
import { PendingAgeEvolution } from 'src/app/shared/models/pending-age-evolution';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { RejectionsPendingAgeService } from '../rejections-pending-age.service';

@Component({
  selector: 'app-pending-age-evolution',
  templateUrl: './pending-age-evolution.component.html',
  styleUrls: ['./pending-age-evolution.component.scss']
})
export class PendingAgeEvolutionComponent implements OnInit {

  view: any[] = [580, 170];
  colorScheme = {
    domain: [COLORS.darkestGreen, COLORS.darkGreen, COLORS.lightGreen, COLORS.lightBlue, COLORS.lightestBlue]
  };

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';
  animations: boolean = true;
  barPadding: number = 8;
  customColors: any = [];
  showRightYAxis: boolean = false;
  legendTitle: string = '';
  legendPosition: string = '';
  schemeType: string = 'ordinal';
  yLeftAxisScaleFactor: string = '';
  yRightAxisScaleFactor: string = '';
  showGridLines: boolean = true;
  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  tooltipDisabled: boolean = false;
  roundDomains: boolean = false;
  noBarWhenZero: boolean = false;
  lineChartScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkBlue]
  };
  comboBarScheme = {
    selectable: true,
    group: 'ordinal',
    domain: ['#5AA454', '#C7B42C', '#AAAAAA']
  };
  rotateXAxisTicks: boolean = false;
  hideHorizontalLine: boolean = true;
  showValuesAndPercentageInTooltip: boolean = false;
  showPercentageSymbolInTooltip: boolean = true;

  loaded: boolean = false;
  unsubscribe$: Subject<any> = new Subject<any>();

  selectedOption = "amount";
  graphName: string = 'sinistersPendingAge';
  filtersValues: any;
  verticalGraph: any = [];
  horizontalGraph: any = [];

  dropdownselectedOption = "1";

  type_data: string;
  type_reason: any[] = [];
  type_report: string = "";
  type_reason_detail: any[] = [];

  abbreviatedMonthsLabels: any;
  pendingAgeLabels: any = [];
  legends: any = [];

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private translateService: TranslateService,
    private rejectionsPendingAgeService: RejectionsPendingAgeService
  ) { }

  ngOnInit(): void {
    const filtersService$ = this.filtersService.filtersValues$;
    const optionTabSelection$ = this.rejectionsPendingAgeService.panelSelectedOption$;
    const dropdownSelection$ = this.rejectionsPendingAgeService.dropdownSelectedOption$;

    combineLatest([filtersService$, optionTabSelection$, dropdownSelection$])
      .pipe(shareReplay(), takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.translation();
          this.filtersValues = data[0];
          const optionTabSelected: any = data[1];
          this.dropdownselectedOption = data[2];

          if (this.filtersValues.length === 0) return;

          this.selectedOption = optionTabSelected.id;
          const graphName = optionTabSelected.graphName;

          if (this.graphName !== graphName) return;

          this.retrievePendingAgeEvolution(
            this.getSelectedOption(),
            this.getDropdownSelectedOption()['type_report'],
            this.getDropdownSelectedOption()['type_reason'],
            this.getDropdownSelectedOption()['type_reason_detail'],
            this.filtersValues
          );

        }
      );
  }

  private translation() {
    this.translateService.get('abbreviatedMonthNames')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => this.abbreviatedMonthsLabels = data)
  }

  private retrievePendingAgeEvolution(type_data: string, type_report: string, sinister_substate: any[], sinister_substate_detail: any[], filtersValues: any): void {
    this.loaded = false;

    this.apimstService.getSinistersPendingAge(type_data, type_report, sinister_substate, sinister_substate_detail, filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: Array<PendingAgeEvolution>) => {

        if (data) {

          for (let item of data) {
            item.groupBy = this.abbreviatedMonthsLabels[item.month] + ' ' + item.year.slice(2, 4);
          }

          const groupedData = _.chain(data).groupBy('groupBy').map((value, key) => ({ date: key, values: value })).value();
          let pendingAgeData = [];

          for (let item of groupedData) {
            let pendingAgeSerie = [];

            for (let serie of item.values) {
              pendingAgeSerie.push({
                name: serie.name,
                value: (serie.value || trim(serie.value).length > 0) ? parseFloat(serie.value.replace(/,/g, '')) : 0
              });
            }

            pendingAgeData.push({
              name: item.date,
              series: pendingAgeSerie
            })

          }
          this.pendingAgeLabels = groupedData[0].values.map(v => v.name);
          this.createLegends();
          this.verticalGraph = pendingAgeData;
        }

        this.loaded = true;

      });
  }

  private getSelectedOption() {
    if (this.selectedOption == "amount") this.type_data = 'a';
    if (this.selectedOption == "sinisters") this.type_data = 's';
    return this.type_data;
  }

  private getDropdownSelectedOption() {
    if (this.dropdownselectedOption == "1") { this.type_reason = ['4', '5', '6']; this.type_reason_detail = []; this.type_report = "spaepen" };
    if (this.dropdownselectedOption == "2") { this.type_reason = []; this.type_reason_detail = ['6']; this.type_report = "spae" };
    if (this.dropdownselectedOption == "3") { this.type_reason = []; this.type_reason_detail = ['10']; this.type_report = "spae" };
    if (this.dropdownselectedOption == "4") { this.type_reason = []; this.type_reason_detail = ['7']; this.type_report = "spae" };
    return { type_reason: this.type_reason, type_reason_detail: this.type_reason_detail, type_report: this.type_report };
  }

  private createLegends(): void {
    this.legends = [];
    this.customColors = [];

    for (const [i, v] of this.pendingAgeLabels.entries()) {
      this.legends.push({
        name: v,
        color_code: this.colorScheme.domain[i],
        type: 'round',
        gradient: 'h-gradient'
      });

      this.customColors.push({
        name: v,
        value: this.colorScheme.domain[i]
      });
    }
  }

  // Method to format the right y axis labels
  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value) + '%';
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

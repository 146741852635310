import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-kpis-graphic-container',
  templateUrl: './kpis-graphic-container.component.html',
  styleUrls: ['./kpis-graphic-container.component.scss']
})
export class KpisGraphicContainerComponent implements OnInit {

  @Input() items: any[];

  constructor() { }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import { COUNTRY_ZONES } from 'src/app/shared/globals/globals';
import argentinaMap from "@highcharts/map-collection/countries/ar/ar-all.geo.json";
import brazilMap from "@highcharts/map-collection/countries/br/br-all.geo.json";
import chileMap from "@highcharts/map-collection/countries/cl/cl-all.geo.json";
import mexicoMap from "@highcharts/map-collection/countries/mx/mx-all.geo.json";
import uruguayMap from "@highcharts/map-collection/countries/uy/uy-all.geo.json";
import { FiltersService } from '../filters/filters.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  COUNTRY_ZONES = COUNTRY_ZONES;

  constructor(
    private filtersService: FiltersService
  ) { }

  getCountryMapZones(countryCode: string = "") {
    const filteredCountryZones = COUNTRY_ZONES.filter(m => m.countryCode == countryCode);
    return filteredCountryZones[0]?.zones ? filteredCountryZones[0].zones : [];
  }

  getMapByCountryCode(countryCode: string = "") {
    let map: any;
    switch (countryCode) {
      case 'ARG': map = argentinaMap; break;
      case 'BRA': map = brazilMap; break;
      case 'CHL': map = chileMap; break;
      case 'MEX': map = mexicoMap; break;
      case 'URY': map = uruguayMap; break;
    }
    return map;
  }

  getUserSelectedCountry(filtersValues: any[] = []) {
    let country = filtersValues.filter(c => c.name == "country")[0]?.value[0];
    if (typeof country == "undefined") {
      country = this.filtersService.selectedIndexCountry$.value;
    }
    return country;
  }

  setMapZoneValues(zones: any[] = [], sourceData: any[] = []) {
    let mapSeries = [];
    for (let zone of zones) {

      let item: any[] = [];
      item.push(zone);
      item.push(this.getZoneValue(zone, sourceData));
      mapSeries.push(item);
    }
    return mapSeries;
  }

  getZoneValue(zone: any = "", sourceZone: any = {}) {
    const filteredValues = sourceZone.filter(z => z.id == zone);
    if (filteredValues.length == 0) return 0;
    return typeof filteredValues[0].value != "undefined" ? this.formatValue(filteredValues[0].value) : 0;
  }

  private formatValue(value: string): number {
    if (value.endsWith('%')) {
      value = value.slice(0, value.length-1);
    }

    return parseFloat(value.replace(/,/g, ''));

  }

  getAccumulatedValue(data): number {
    let accumulatedValue = 0;
    data.map(z => {
      if (z.value !== undefined) {
        accumulatedValue += parseFloat(z.value.replace(/,/g, ''));
      }
    });
    return accumulatedValue;
  }

  setValuesSuffix(unit: string = "") {
    return unit;
  }

}

<ngx-charts-tree-map
  [view]="view"
  [scheme]="scheme"
  [results]="results"
  [gradient]="gradient"
  [animations]="animations"
  [labelFormatting]="labelFormatting"
  (select)="onSelect($event)">
  <ng-template #tooltipTemplate let-model="model">
    <span class="name d-block">{{ model.name }}</span>
    <span class="value d-block">{{ formatValue(model.value) }}{{ tooltipValueSuffix }}</span>
  </ng-template>
</ngx-charts-tree-map>
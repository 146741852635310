<div class="big_kpi">
    <p class="big_kpi-title">No liquidamos en tiempo</p>
    <div class="big_kpi-percentage">
        <span class="big_kpi-percentage-first">6,2%</span>
        <span class="material-icons big_kpi-percentage-arrow">
            play_arrow
            </span>
        <span class="big_kpi-percentage-second">0.1%</span>
    </div>
</div>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { items } from './data';
import { forkJoin, Subject } from 'rxjs';
import { trim } from 'lodash';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators'
import { SLAsIndicator } from 'src/app/shared/models/slas-indicator';
import { TargetType } from 'src/app/shared/models/target-type';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';

@Component({
  selector: 'app-slas-main',
  templateUrl: './slas-main.component.html',
  styleUrls: ['./slas-main.component.scss']
})
export class SlasMainComponent implements OnInit, OnDestroy {

  titleSelector: string = "dropdowns.targetsType";
  itemsSelector: any[] = [];
  dropdownId: string = 'slaTargetType';

  // SLAs items info
  items = items;

  // Format number
  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  // Check number symbol
  isNegative: boolean = false;

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;
  loaded: boolean = false;
  SLAsData: any = [];
  targetType: any = ['1'];
  monthNames: any;

  percentagePrevious: string = "labels.pm";

  constructor(
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private eventsService: EventsService,
    private translateService: TranslateService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {

    this.translateService.get('abbreviatedMonthNames')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(data => this.monthNames = data);

    this.eventsService.targetsType$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data.length !== 0) {
            this.targetType = [data];
            this.retrieveSLAs(this.filtersValues, this.targetType);
          }
        }
      );

    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data.length !== 0) {
            this.filtersValues = data;
            this.retrieveSLAs(this.filtersValues, this.targetType);
          }
        });

    this.apimstService.getTargetTypeMaster()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data: Array<TargetType>) => {
          if (data.length !== 0) {
            this.itemsSelector = [];
            for (let target of data) {
              this.itemsSelector.push(
                {
                  id: target.target_type_id,
                  type: 'target-type',
                  name: target.target_type_desc,
                  selected: false
                }
              );
            }
          }

        }
      );

  }

  // Method to retrieve the SLAs data
  private retrieveSLAs(filtersValues: any, targetType: string) {
    this.loaded = false;
    const indicators = this.apimstService.getSLAsIndicators(targetType, filtersValues);
    const graphs = this.apimstService.getSLAsGraphs(targetType, filtersValues);

    forkJoin([indicators, graphs])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        results => {
          const indicators: Array<SLAsIndicator> = results[0];
          const graphs = results[1];
          this.SLAsData = [];

          let helper = {};
          let slaGraphsData = graphs.reduce(function (r, o) {
            var key = o.name;
            if (!helper[key]) {
              helper[key] = graphs.filter(v => v.name === key);
              r.push(helper[key]);
            }
            return r;
          }, []);

          let slaGraphs = [];
          for (let item of slaGraphsData) {

            let series = [];
            for (let month of item) {
              series.push(
                {
                  name: this.monthNames[month.month] + ' ' + month.year,
                  value: (month.value || trim(month.value).length > 0) ? parseFloat(month.value.replace(/,/g, '')) : 0
                }
              );
            }
            slaGraphs.push({
              name: item[0]?.name,
              series: series
            });
          }

          for (let item of indicators) {
            let sla: any = {};
            sla.title = item.name;
            sla.main_value = (item.value || trim(item.value).length > 0) ? this.formatPipe.transform(parseFloat(item.value.replace(/,/g, ''))) : -1;
            sla.unit = (item.unit || trim(item.unit).length > 0) ? item.unit.toLowerCase() : "";
            sla.unit_size = this.utilsService.lengthGreaterThanOne(item.unit) == false ? 1 : 0;
            sla.second_value = (item.target || trim(item.target).length > 0) ? this.formatPipe.transform(parseFloat(item.target.replace(/,/g, ''))) : -1;
            sla.trend_value_previous = this.percentagePrevious;
            sla.trend_value = (item.diff || trim(item.diff).length > 0) ? this.formatPipe.transform(parseFloat(item.diff.replace(/,/g, ''))) + "%" : -1;
            sla.trend = (item.diff_trend || trim(item.diff_trend).length > 0) ? parseFloat(item.diff_trend.replace(/,/g, '')) : -1;
            sla.trend_direction = this.utilsService.isNegative( item.diff ) == false ? 1 : 0;
            sla.graphic_data = [slaGraphs.filter(v => v.name === item.name)[0]];
            this.SLAsData.push(sla);
          }

          this.loaded = true;
        }
      );
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-technical-upper-menu',
  templateUrl: './technical-upper-menu.component.html',
  styleUrls: ['./technical-upper-menu.component.scss']
})
export class TechnicalUpperMenuComponent implements OnInit {

  @Input() width: string = "1366";
  @Input() height: string = "64";
  @Input() menuOptions;

  paddingRight = 42;

  
  constructor() { }

  ngOnInit(): void {
  }

  getStyles() {
    var styles = {
      'width.px': this.width,
      'height.px': this.height
    }
    return styles;
  }

}

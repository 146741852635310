import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancellation-persistence',
  templateUrl: './cancellation-persistence.component.html',
  styleUrls: ['./cancellation-persistence.component.scss']
})
export class CancellationPersistenceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<ngx-charts-chart 
  [view]="[width, height]"
  [showLegend]="legend"
  [legendOptions]="legendOptions"
  [activeEntries]="activeEntries"
  [animations]="animations"
  >
<svg:g [attr.transform]="transform" class="bar-chart chart">
  <svg:g
    ngx-charts-x-axis
    *ngIf="xAxis"
    [xScale]="xScale"
    [dims]="dims"
    [showGridLines]="showGridLines"
    [showLabel]="showXAxisLabel"
    [labelText]="xAxisLabel"
    [trimTicks]="trimXAxisTicks"
    [rotateTicks]="rotateXAxisTicks"
    [maxTickLength]="maxXAxisTickLength"
    [tickFormatting]="xAxisTickFormatting"
    [ticks]="xAxisTicks"
    
  ></svg:g>
  <svg:g
    ngx-charts-y-axis 
    *ngIf="yAxis"
    [yScale]="yScale"
    [dims]="dims"
    [showLabel]="showYAxisLabel"
    [labelText]="yAxisLabel"
    [trimTicks]="trimYAxisTicks"
    [maxTickLength]="maxYAxisTickLength"
    [tickFormatting]="yAxisTickFormatting"
    [ticks]="yAxisTicks"
    [yAxisOffset]="dataLabelMaxWidth.negative"
    
  ></svg:g>
  <svg:g
    *ngFor="let group of results; let index = index; trackBy: trackBy"
    [@animationState]="'active'"
    [attr.transform]="groupTransform(group)"
  >
    <svg:g
      stacked-horizontal-negative-graph-bars
      type="stacked"
      [xScale]="xScale"
      [yScale]="yScale"
      [colors]="colors"
      [series]="group.series"
      [activeEntries]="activeEntries"
      [dims]="dims"
      [gradient]="gradient"
      [tooltipDisabled]="tooltipDisabled"
      [tooltipTemplate]="tooltipTemplate"
      [seriesName]="group.name"
      [animations]="animations"
      [showDataLabel]="showDataLabel"
      [dataLabelFormatting]="dataLabelFormatting"
      [noBarWhenZero]="noBarWhenZero"
      (select)="onClick($event, group)"
      (activate)="onActivate($event, group)"
      (deactivate)="onDeactivate($event, group)"
      
    />
    </svg:g>
  </svg:g>
</ngx-charts-chart>
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { ApimstService } from './shared/services/apimst.service';
import { filter, map, takeUntil } from "rxjs/operators";
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from './shared/services/events/events.service';
import { Subject, forkJoin } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { MENU } from 'src/app/shared/globals/globals';
import { NavigationService } from './shared/services/navigation/navigation.service';
import { CookieService } from 'ngx-cookie-service';
import { fakeAsync } from '@angular/core/testing';
import { environment } from 'src/environments/environment';
import { FiltersService } from './shared/services/filters/filters.service';

import { Animations } from './shared/animations/animations';
import { IconsService } from './shared/services/icons/icons.service';
import { I18nService } from './shared/services/i18n/i18n.service';
import { ApiDataService } from './shared/services/api-data/api-data.service';
import { MsalBroadcastService, MsalService } from './shared/external/msal';
import { AuthenticationResult, EventMessage, EventType, InteractionType, SilentRequest } from '@azure/msal-browser';
import { MsalGuardConfiguration } from './shared/external/msal/msal.guard.config';
import { MSAL_GUARD_CONFIG } from './shared/external/msal/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    Animations.fader
  ]
})
export class AppComponent implements OnInit, OnDestroy  {
  title = '';
  isIframe = false;
  loggedIn = false;
  private readonly _destroying$ = new Subject<void>();
  menu: any[] =  MENU;
  activeLang: string;
  userCurrency: string;
  userCurrencyDescription: string;
  userLanguage: string;
  userRaw: any;
  currencyRaw: any;
  budgetsRaw: any;
  appLoaded: boolean = false;
  loginInProgress:boolean = false;

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,
    private events: EventsService,
    private httpClient: HttpClient,
    private navigationService: NavigationService,
    private cookieService: CookieService,
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private iconsService: IconsService,
    private I18nService: I18nService,
    private apiDataService: ApiDataService
  ) {}

  ngOnInit() { 

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.SSO_SILENT_SUCCESS),        
      )
      .subscribe((result: EventMessage) => {
        let authResult:AuthenticationResult = <AuthenticationResult>result.payload; 
        this.llamadaOidc(authResult.accessToken,authResult.idToken);
      });

    
    this.isIframe = window !== window.parent && !window.opener;

    this.iconsService.registerIcons();


    if(!this.checkSessionCookies()){
      this.login();
    }else{
      this.loadUserInfo();
      this.changePage();      
    }
    




    this.events.forceCheckLogin$
    .subscribe(
      data => {        
        this.login();
      });    
    

  }  

  loadUserInfo(){
    let getUserInfo = this.apimstService.getUserInfo();
    let getBudgetsFilters = this.filtersService.getBudgetsFilters();

    forkJoin([getUserInfo, getBudgetsFilters])
      .subscribe(
        results => {
          this.userRaw = results[0];
          this.budgetsRaw = results[1];

          // this.userRaw = {"countries":[{"currency":{"id":"3","desc":"Peso Argentino (ARS)","iso":"ARS","symbol":"$"},"language":{"id":"es","desc":"español (o castellano)"},"id":"ARG","desc":"Argentina"}],"username":"miguel.medina","currency_id":"3","currency_desc":"Peso Argentino (ARS)","currency_iso":"ARS","currency_symbol":"$","language_id":"es","language_desc":"español (o castellano)"}
          // this.userRaw = {"countries": [{"currency": {"id": "3", "desc": "Peso Argentino (ARS)", "iso": "ARS", "symbol": "$"}, "language": {"id": "es", "desc": "espa\u00f1ol (o castellano)"}, "id": "ARG", "desc": "Argentina"}, {"currency": {"id": "4", "desc": "Peso Chileno (CLP)", "iso": "CLP", "symbol": "$"}, "language": {"id": "es", "desc": "espa\u00f1ol (o castellano)"}, "id": "CHL", "desc": "Chile"}, {"currency": {"id": "6", "desc": "Peso Uruguayo (UYU)", "iso": "UYU", "symbol": "$"}, "language": {"id": "es", "desc": "espa\u00f1ol (o castellano)"}, "id": "URY", "desc": "Uruguay"}], "username": "miguel.medina", "currency_id": "3", "currency_desc": "Peso Argentino (ARS)", "currency_iso": "ARS", "currency_symbol": "$", "language_id": "es", "language_desc": "espa\u00f1ol (o castellano)", "edit_permission": "1"}

          let userCountryId = "1";
          if(this.userRaw['countries'][0]){
            this.userCurrency = this.userRaw['countries'][0].currency.id;
            this.userCurrencyDescription = this.userRaw['countries'][0].currency.desc;                          
            this.userLanguage = this.userRaw['countries'][0].language.id;
            userCountryId = this.userRaw['countries'][0].id;
          }else{
            this.userCurrency = "1";
          }
          
          this.filtersService.selectedIndexCurrency$.next(this.userCurrency);
          this.filtersService.filtersCurrencyValue$.next(this.userCurrency);
          this.filtersService.currencyFilterValues$.next(this.userCurrency);


          if (localStorage.getItem('selectedLanguage') !== null) {
            this.userLanguage = localStorage.getItem('selectedLanguage');
            localStorage.removeItem('selectedLanguage');
          }          
          this.activeLang = ( this.userLanguage != "" && this.userLanguage != undefined ) ? this.userLanguage : "es";

          // this.activeLang = 'pt';
          this.I18nService.changeLanguage(this.activeLang);

          // Add currency & budgets to filters values
          let initFilters = this.filtersService.filtersValues$.value;

          for (var i in initFilters) {
            if (initFilters[i].name == 'currency') {
              initFilters[i].value = this.userCurrency;
              initFilters[i].valueItems = [ { item_id: this.userCurrency, item_text: this.userCurrencyDescription } ];
            }
            if (initFilters[i].name == 'budgets') {
              initFilters[i].value = this.filtersService.budgetsValue;
              initFilters[i].valueItems = [ { item_id: this.filtersService.selectedIndexBudgets$.value.toString(), item_text: this.filtersService.getFieldString(this.filtersService.budgetsValue.toString(), this.budgetsRaw, 'budget_id', 'budget_desc') } ];
            }
          }

          // Add country to filters values
          // this.userRaw['countries'] = [
          //   {id: "URY", desc: "Uruguay"},
          //   {id: "CH", desc: "Chile"},
          //   {id: "ARG", desc: "Argentina"},
          //   {id: "BRA", desc: "Brasil"},
          // ]

          let selectedCountry = JSON.parse(localStorage.getItem('selectedCountry'));
          localStorage.removeItem('selectedCountry');
          
          if( this.userRaw['countries'].length > 0 ) {
            let countryValues: any[] = [];
            let countryValueItems: any[] = [];
            countryValues.push(this.userRaw['countries'][0].id);
            countryValueItems.push({item_id: this.userRaw['countries'][0].id, item_text: this.userRaw['countries'][0].desc });
            initFilters.push({ name: 'country', value: countryValues, valueItems: countryValueItems })
            this.filtersService.countryValue = this.userRaw['countries'][0].id;
            this.filtersService.countryFilterValues$.next(this.userRaw['countries'][0].id);
          }
          this.filtersService.selectedIndexCountry$.next(selectedCountry !== null ? selectedCountry : userCountryId);
          this.filtersService.filtersValues$.next(initFilters);
          
          

          this.events.userInfoLoaded$.next(this.userRaw);
          this.events.userInfoLoadedSubject$.next(this.userRaw);

          this.appLoaded = true;
  
        }
      )
  }

removeSessionCookies(){
  this.cookieService.delete('iSession');
  this.cookieService.delete('jSessionID');
}

removeMSALSessionStorage(){
    let keys = [];
    for (var i = 0; i < sessionStorage.length; i++) {
      keys.push(sessionStorage.key(i));
    }

    for (var i = 0; i < keys.length; i++) {      
      sessionStorage.removeItem(keys[i]);
    }  
}
  checkAccount() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  login() {
    if(this.loginInProgress){
      return;
    }
        this.loginInProgress = true;
        this.changePage();
        this.checkAccount();
        if(this.loggedIn){
          const accessTokenRequest = {
            scopes: [],
            account: this.authService.instance.getAllAccounts()[0],
          };          
          //this.msalGuardConfig.authRequest.account = this.authService.instance.getAllAccounts()[0];
          this.authService.acquireTokenSilent({...(<SilentRequest>accessTokenRequest)}).subscribe((result)=>{
              this.cookieService.set("access_token",result.accessToken);
              this.cookieService.set("id_token",result.idToken);                      
              this.llamadaOidc(result.accessToken,result.idToken);
          });
        }else{
          if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            this.authService.loginPopup({...this.msalGuardConfig.authRequest})
              .subscribe(() => console.log("Logueado"));
          } else {            
            this.authService.loginRedirect({...this.msalGuardConfig.authRequest});
          }
        }
  }

  postData(url: string, body: any) {
    return this.httpClient.post(url, body, 
      {         
        observe: 'response', 
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest' }
        )/*, withCredentials: false*/
      });      
  }

  llamadaOidc(access_token:string, id_token: string){
    const body: any = {
      "access_token": access_token,
      "refresh_token": "",
      "expires_in": 3599,
      /*"scope": "openid profile email groups",*/
      "token_type": "Bearer",
      "id_token": id_token
      };
    
      const url = environment.azureApiUri+"common/oidc_micro";
      this.postData(url, body).subscribe((response: HttpResponse<any>) => {
        this.loginInProgress = false;
        if(response.status === 200){
          this.cookieService.set("iSession",response.body.iSession);
          this.cookieService.set("jSessionID",response.body.jSessionID);

          this.loadUserInfo();
          this.changePage();
        }        

      }, error => {
        this.loginInProgress = false;
      });
  
  

  }

  logout() {
    //this.authService.logout();
  }

  llamar(){
    // console.log("helloworld")
    // // Zurich
    // //return this.httpClient.get("https://sds-apis.azure-api.net/ms-atlas-api/helloworld");                                                                    

    // // Local 
    // const body: any = {year: 2020, month: "05"};
    // return this.httpClient.post("https://sikapi-apim.azure-api.net/af-sikapiapp/gwp",body).subscribe(
    //   data => { 
    //     console.log(data)
    //   },
    //   error => {console.log(error); console.log("An error ocurred while retrieving data for the sales pie graph and the table: " + error)}
    // );                                                                     
    //this.changePage()    
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  changePage() {

      // console.log("ChangePage Route");
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
                let child = this.activatedRoute.firstChild;
                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                    } else if (child.snapshot.data && child.snapshot.data['title']) {
                        return child.snapshot.data;
                    } else {
                        return null;
                    }
                }
                return null;
            })
      ).subscribe( (data: any) => {
        if (data) {

          // Add id to body depending on the view
          this.document.body.id = '';
          this.document.body.id = data['id'];

          // Modify page title depending on the view
          this.titleService.setTitle(data['title'] + ' - zssik-app');

          // Modify header bar title depending on the view
          this.translate.stream(data['parentTitleT'])
          .subscribe(
            (title:string) => {
              this.events.headerTitle$.emit(title);
              this.apiDataService.updateHeaderTitle(title);
            }
          )

          // Look for ID in children & fetch parent ID
          let id = data['id'];
          let parentId;
          let childId;

          for (let menuItem of this.menu) {
            if(menuItem['children'].length > 0) {
              for (let child of menuItem['children']) {
                if( child['id'] == id ) {
                  parentId = menuItem['id'];
                  childId = id;
                  break;
                }
              }
            }
          } 

          // Send page ID to observable
          this.navigationService.pageId$.next( id );

          // Use parent ID to fetch children info 
          let activeItemsNumber = 0;
          let activeItems: any[] = [];
          let navigationItems: any[] = [];
          let activeItemIndex: number = 0;
          let counter: number = 0;

          for (let menuItem of this.menu) {
            if(menuItem['id'] ==  parentId) {
              for (let child of menuItem['children']) {
                if(child['active'] == true) {
                  counter = counter + 1;
                  if(childId == child['id']) {
                    activeItemIndex = counter;
                  }
                  activeItems.push(child);
                  activeItemsNumber = activeItemsNumber + 1;
                }
              }
            }
          } 

          // Build object with navigation items
          navigationItems.push({
            itemsNumber: activeItemsNumber,
            activeItemIndex: activeItemIndex,
            items: activeItems
          });

          this.navigationService.navigationItems$.next(navigationItems);

        }
      });
  }

  checkSessionCookies(){
    const isession = this.cookieService.get('iSession');    
    const jsessionid = this.cookieService.get('jSessionID');  
    const accessToken = this.cookieService.get('access_token');    
    const idToken = this.cookieService.get('id_token');  

    if(isession && jsessionid && idToken && accessToken){

      return true;
    } else{

      return false;
    } 
  } 

}

import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-sinisters-claims-velocimeter',
  templateUrl: './sinisters-claims-velocimeter.component.html',
  styleUrls: ['./sinisters-claims-velocimeter.component.scss']
})
export class SinistersClaimsVelocimeterComponent implements OnInit {

  // ViewBox
  viewBox: string = "0 0 100 50";
  strokeWidth: number = 8;

  // Values
  target: string;
  mainValue: string;
  secondValue: string;
  trend: string;
  leftValue;
  hasInfo: boolean = false;
  currentValue;
  hAlign: string = "start";
  vAlign: string = "center";
  barOrientation: number;

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;

  qualityClaim: any = {};
  qualityClaimSelected: any = [];
  loaded: boolean = false;

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private eventsService: EventsService,
  ) { }

  ngOnInit(): void {

    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data.length !== 0) {
            this.filtersValues = data;
            this.loaded = false;
            this.qualityClaimSelected = [];
            this.retrieveQualityClaims(this.qualityClaimSelected, this.filtersValues);
          }
        }
      );

    this.eventsService.sinistersQualityClaims$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.qualityClaimSelected = [];
        // if (data.value !== '') {
        //   this.qualityClaimSelected.push(data.value);
        // }
        if(data.value !== '') this.qualityClaimSelected = [data.value];
        this.retrieveQualityClaims(this.qualityClaimSelected, this.filtersValues);
      });

  }

  // Method to retrieve the quality claim data
  private retrieveQualityClaims(qualityClaimSelected: [], filtersValues: any) {

    this.loaded = false;
    this.apimstService.getSinistersQualityClaims(qualityClaimSelected, "sci", filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const rawData = data;
          this.loaded = true;
          this.resetValues();

          if (this.isNotValidData(rawData)) { return }

          if (parseFloat(rawData[0].value) <= parseFloat(rawData[0].target)) {
            this.target = (parseFloat(rawData[0].target) * 1.8).toString();
            this.currentValue = (parseFloat(rawData[0].value) * 1.8).toString();
            this.barOrientation = 1;
          } else {
            this.target = (parseFloat(rawData[0].target) * 1.8).toString();
            this.currentValue = (parseFloat(rawData[0].value) * 1.8).toString();
            this.barOrientation = 0;
          }

          this.createIndicator(rawData[0]);
          this.loaded = true;
        },
        error => console.log("An error ocurred while retrieving sinisters quality claims indicator data: " + error)

      );
  }

  resetValues() {
    this.qualityClaim.value = "";
    this.qualityClaim.target = "";
  }

  createIndicator(info: any = {}) {
    this.qualityClaim.value = info.value && info.value != "" ? parseFloat(info.value) : "0";
    this.qualityClaim.target = info.target && info.target != "" ? parseFloat(info.value) : "0";
  }

  isNotValidData(data: any) {
    return data == null || typeof data == "undefined" || data == undefined;
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wok-graph-upper-label',
  templateUrl: './wok-graph-upper-label.component.html',
  styleUrls: ['./wok-graph-upper-label.component.scss']
})
export class WokGraphUpperLabelComponent implements OnInit {

  @Input() labelDiff;
  @Input() viewBox;
  @Input() width;
  @Input() textLabel;

  constructor() { }

  ngOnInit(): void {
  }

}

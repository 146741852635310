import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { QualitySurveyType } from 'src/app/shared/models/quality-survey-type';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cancellation-nps',
  templateUrl: './cancellation-nps.component.html',
  styleUrls: ['./cancellation-nps.component.scss']
})
export class CancellationNpsComponent implements OnInit, OnDestroy {

  title: string = "";
  dropdownId: string = 'salesQualityNPS';
  totalLabel: string;

  unsubscribe$: Subject<any> = new Subject<any>();
  qualityNPSReasons: any = [];
  loaded: boolean = false;

  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "cancellation-quality--cancellation-nps";

  // Default constructor
  constructor(
    private apimstService: ApimstService,
    private translateService: TranslateService,
    private i18n: I18nService,
    private atlasHelper: AtlasHelperService
  ) { }

  // OnInit
  ngOnInit(): void {
    this.subscribeToActiveLanguage();
    
    this.translateService.get('labels.total')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => this.totalLabel = data);

    this.retrieveQualityNPSReason();

  }

  // Method to retrieve the survey type master
  private retrieveQualityNPSReason() {
    this.loaded = false;
    this.apimstService.getSurveyTypeMaster().pipe(takeUntil(this.unsubscribe$)).subscribe(
      (data: Array<QualitySurveyType>) => {
        this.qualityNPSReasons = [];
        const cancellationQualitySurveyType = data.filter(v => v.survey_type_id === '3');
        for (let type of cancellationQualitySurveyType) {
          this.qualityNPSReasons.push(
            {
              id: type.survey_subtype_id,
              parentId: type.survey_type_id,
              type: 'sale-nps',
              name: type.survey_subtype_desc,
              selected: false
            }
          );
        }
        this.qualityNPSReasons.push(
          {
            id: 'all',
            type: 'sale-nps',
            name: 'labels.total',
            selected: true
          }
        );
        this.loaded = true;
      },

      error => console.log("An error ocurred while retrieving cancellation quality NPS reasons data " + error)
    );
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

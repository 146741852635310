import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { ElementRef, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subscription, throwError, EMPTY, NEVER, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EventsService } from '../services/events/events.service';
import { MatDialog } from '@angular/material/dialog';
import { SessionExpiredDialogComponent } from '../components/session-expired-dialog/session-expired-dialog.component';
import { DOCUMENT } from '@angular/common';
import { ErrorsService } from '../services/errors/errors.service';

@Injectable()
export class MSTReponseInterceptor implements HttpInterceptor {

    sessionExpired$ = new BehaviorSubject<boolean>(false);
    sessionExpiredSubscription: Subscription;
    errorObject: any;
    userInfoLoaded: boolean = false;
    excludedUrls: any[] = ['common/user_info', 'masters/currency', 'masters/budget'];

    constructor(
        private router: Router,
        private cookieService: CookieService,
        private events: EventsService,
        private errorsService: ErrorsService,
        private dialog: MatDialog,
        @Inject(DOCUMENT) private document: Document
    ) {

        // Subscribe to session expired event
        this.sessionExpiredSubscription = this.sessionExpired$
        .subscribe(
            data => {
                if(data) {
                    this.openSessionExpiredDialog();
                }
            }
        )

        this.events.userInfoLoaded$
            .subscribe(
                data => {
                    if (data.hasOwnProperty('currency_id')) {
                        this.userInfoLoaded = true;
                    }
                }
            )

     }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if(this.validUrlsToCheck(req)) {
            const request = req.clone();
            if(this.userInfoLoaded == false) {
                return EMPTY;
            }
        }
        
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                this.errorObject = {};
                const request = req.clone();

                if (error.status == 401) {
                    console.log("No está autenticado");
                    this.sessionExpired$.next(true);
                    this.sessionExpiredSubscription.unsubscribe();  
                }
                if (error.status == 500 || error.status == 404) {
                    this.addError(error);
                    return of(new HttpResponse({
                        body: request.body
                    }));
                }

                return throwError(error);
            })

        ) as Observable<HttpEvent<any>>;
        

    }

    private navigateToLogin() {
        console.log("MSTReponseInterceptor: navigate to login");
        this.cookieService.delete('iSession', '/');
        this.cookieService.delete('jSessionID', '/');

        this.events.forceCheckLogin$.emit('');
        //window.location.reload();
        // this.eventsService.loginRedirection$.emit(true);
        // this.eventsService.loginRedirectionSubject$.next(true);
    }

    private openSessionExpiredDialog(): void {
        let backDrop;
        console.log("Session expired");
        const dialogRef = this.dialog.open(SessionExpiredDialogComponent, {
            disableClose: true,
            panelClass: ['mat-dialog-gen', 'mat-dialog-session-expired'],
            backdropClass: 'backdrop-dialog-session-expired',
        });
        backDrop = document.querySelector('.cdk-overlay-container');
        backDrop.classList.add('overlay-dialog-session-expired');

        dialogRef.afterClosed().subscribe(result => {
            backDrop.classList.remove('overlay-dialog-session-expired');
        });
    }

    private addError(error: any): void {
        this.errorObject.status = error.status;
        this.errorObject.statusText = error.statusText;
        this.errorObject.url = error.url;

        const currentValue = this.errorsService.getErrors();
        const updatedValue = [...currentValue, this.errorObject];
        this.errorsService.setError(updatedValue);
    }

    private validUrlsToCheck(req: HttpRequest<any>) {
        let isValid: boolean = false;
        let isUserInfoUrl = req.url.indexOf('common/user_info') !== -1;
        let isCurrencyUrl = req.url.indexOf('masters/currency') !== -1;
        let isBudgetUrl = req.url.indexOf('masters/budget') !== -1;
        let isIndicatorsUrl = req.url.indexOf('common/indicators') !== -1;
        let isSalesUrl = req.url.indexOf('/sales/') !== -1;
        let isCancellationUrl = req.url.indexOf('/cancellations/') !== -1;
        let isSinistersUrl = req.url.indexOf('/sinisters/') !== -1;
        let isClientsUrl = req.url.indexOf('/clients/') !== -1;

        if(
            !isUserInfoUrl && 
            !isCurrencyUrl && 
            !isBudgetUrl && 
            (isIndicatorsUrl || isSalesUrl || isCancellationUrl || isSinistersUrl || isClientsUrl)) {
                isValid = true; 
            }
        return isValid;
    }

}

// if (event instanceof HttpResponse) {
//     let camelCaseObject = mapKeys(event.body, (v, k) => camelCase(k));
//     const modEvent = event.clone({ body: camelCaseObject });

//     return modEvent;
//   }

<app-widget>
    <div class="section-header-block d-flex justify-content-start align-items-center pb-2">
        <div class="d-flex">
            <span class="title section-card-title">{{ 'panels.closedPerMonthOfComplaint' | translate }}</span>
            <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams"
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </div>
        <span *ngIf="tableLoaded" class="material-icons ml-2 fullscreen-icon text-white" (click)="openTableZoom()">fullscreen</span>
    </div>

    <div class="mt-3 table-wrapper table-closed-wrapper">
        <app-sinisters-closed-per-month-table (tableLoaded)="tableLoadedEvent($event)"></app-sinisters-closed-per-month-table>
    </div>

</app-widget>
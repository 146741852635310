<component-loader [width]="" [height]="125" *ngIf="!loaded"></component-loader>
<div class="d-flex justify-content-center" *ngIf="loaded">
    <div class="legends-container d-flex mb-3">
        <app-legend
            [name]="legend.name" 
            [colorCode]="legend.color_code" 
            [type]="legend.type" 
            [gradient]="legend.gradient" 
            *ngFor="let legend of legends">
        </app-legend>
    </div>
</div>
<div class="d-flex justify-content-center align-items-center mt-2" *ngIf="loaded">
    <div class="mr-3">
        <app-semicircle-pie
            [items]="tnpsItems"
            [kpiInfo]="tnpsKpi"
            [width]="130">
        </app-semicircle-pie>
    </div>
    <div class="d-flex flex-column">
        <app-velocimeter-info
            [infoTitle]="infoTitle"
            [infoActualValue]="responseRate?.surveys_answered"
            [infoTotalValue]="responseRate?.surveys_sent"
            [infoPercentage]="responseRate?.response_rate"
            class="velocimeter-info-medium">
        </app-velocimeter-info>
    </div>
</div>

<!-- <velocimeter *ngIf="loaded"
    [viewBox]="viewBox"
    [strokeWidth]="strokeWidth"
    [target]="target"
    [currentValue]="currentValue"
    [mainValue]="qualityNPS?.value"
    [secondValue]="qualityNPS?.target"
    [diff]="qualityNPS?.diff"
    [trend]="qualityNPS?.diff_trend"
    [hasInfo]="hasInfo"
    [hAlign]="hAlign"
    [vAlign]="vAlign"
    [infoTitle]="infoTitle"
    [infoActualValue]="responseRate?.surveys_answered"
    [infoTotalValue]="responseRate?.surveys_sent"
    [infoPercentage]="responseRate?.response_rate"
    [barOrientation]="barOrientation"
    >
</velocimeter> -->




<!-- <component-loader [width]="" [height]="55" *ngIf="!loaded"></component-loader>
<div class="d-flex" *ngIf="loaded">
    <velocimeter
        class="velocimeter-small"
        [viewBox]="viewBox"
        [strokeWidth]="strokeWidth"
        [target]="target"
        [currentValue]="currentValue"
        [mainValue]="qualityClaim?.value"
        [secondValue]="qualityClaim?.target"
        [diff]="qualityClaim?.diff"
        [trend]="qualityClaim?.diff_trend"
        [hasInfo]="hasInfo"
        [hAlign]="hAlign"
        [vAlign]="vAlign"
        [barOrientation]="barOrientation"
        >
    </velocimeter>
    <app-small-kpi 
        class="indicator-widget-element velocimeter-kpi d-inline-block ml-4" 
        [title]=qualitySinisterIndicator.title 
        [mainValue]=qualitySinisterIndicator.main_value 
        [mainValueUnit]=qualitySinisterIndicator.main_value_unit 
        [trend]=qualitySinisterIndicator.trend
        [percentagePrevious]=qualitySinisterIndicator.percentage_previous 
        [percentage]=qualitySinisterIndicator.percentage 
    ></app-small-kpi>
</div> -->

import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-sinisters-quality-velocimeter',
  templateUrl: './sinisters-quality-velocimeter.component.html',
  styleUrls: ['./sinisters-quality-velocimeter.component.scss']
})
export class SinistersQualityVelocimeterComponent implements OnInit {

  // ViewBox
  viewBox: string = "0 0 100 50";
  strokeWidth: number = 8;
  
  // Values
  target: string;
  mainValue: string;
  secondValue: string;
  trend: string;
  leftValue;
  hasInfo: boolean = false;
  currentValue;
  hAlign: string = "center";
  vAlign: string = "center";
  barOrientation: number;

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;

  qualityClaim: any = {};
  qualityClaimSelected: any = [];
  qualitySinisterIndicator: any = {};
  percentagePrevious: string = "labels.pm";

  loaded: boolean = false;

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private eventsService: EventsService,
  ) { }

  ngOnInit(): void {

    this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        if (data.length !== 0) {
          this.filtersValues = data;

          this.resetValues();
          this.retrieveSinistersQualityIndicator("cot", this.filtersValues);  
        }
      }
    );

  }

  // Method to retrieve the quality claim data
  private retrieveSinistersQualityIndicator(type_report: string, filtersValues: any) {
    this.loaded = false;

    this.apimstService.getSinistersSinistersQuality(type_report, filtersValues)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (data) => {
        if(data == null) {
          this.setEmptyData();
          this.loaded = true;
          return;
        }

        if(typeof data != "undefined" && data != null) {
          this.qualityClaim = data[0];
        } else {
          this.setEmptyData();
        }

        if (parseFloat(this.qualityClaim.value) <= parseFloat(this.qualityClaim.target)) {
          this.target = (parseFloat(this.qualityClaim.target)*1.8).toString();
          this.currentValue = (parseFloat(this.qualityClaim.value)*1.8).toString();
          this.barOrientation = 1;
        } else {
          this.target = (parseFloat(this.qualityClaim.target)*1.8).toString();
          this.currentValue = (parseFloat(this.qualityClaim.value)*1.8).toString();
          this.barOrientation = 0;
        }

        this.createIndicator(this.qualityClaim);

        this.loaded = true;
      }, 
      error => console.log("An error ocurred while retrieving sinisters quality velocimeter indicator data: " + error)
        
    );
  }

  createIndicator(indicator: any): void {
    this.qualitySinisterIndicator.title = 'panels.settledInTime';
    this.qualitySinisterIndicator.main_value = this.isValidValue(indicator.value) ? indicator.value : "";
    this.qualitySinisterIndicator.main_value_unit = '%';
    this.qualitySinisterIndicator.percentage_previous = this.percentagePrevious;
    this.qualitySinisterIndicator.percentage = this.isValidValue(indicator.diff) ? indicator.diff : "";
    this.qualitySinisterIndicator.trend = this.isValidValue(indicator.diff_trend) ? parseInt(indicator.diff_trend) : 0;
  }

  setEmptyData() {
    this.qualityClaim.title = 'panels.settledInTime';
    this.qualityClaim.main_value = '0';
    this.qualityClaim.main_value_unit = '';
    this.qualityClaim.target = '0';
    this.qualityClaim.percentage_previous = '';
    this.qualityClaim.percentage = '0';
    this.qualityClaim.trend = 0;

    this.target = "0";
    this.currentValue = "0";
    this.barOrientation = 0;
  }

  resetValues() {
    this.qualitySinisterIndicator = {};
  }

  isValidValue(value: any) {
    const isValid = value != null && typeof value != "undefined";
    return isValid;
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

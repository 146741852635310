// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //authority: 'https://login.microsoftonline.com/6a240b9c-22e3-4f76-a965-bed3882cd878',
  //clientId: 'b971ebb6-f86f-470e-adee-4e4b091b3237',
  //redirectUri: 'http://localhost:4200',
  //redirectUri: 'http://ms-atlas-frontend-prueba-restore-bd8b.azurewebsites.net',
  //redirectUri: 'https://sikwebdev.azurewebsites.net',
  
  // Civica
  //azureApiUriAtlas: 'https://sikapi-apim.azure-api.net/af-sikapiapp-atlas/',
  azureApiUriAtlas: '',
  // Zurich
  //azureApiUriAtlas: 'https://sds-apis.azure-api.net/atlas-api/',
  //azureApiUri: 'http://localhost:7071/api/',
  // redirectUri: 'http://localhost:4200',
  //azureApiKey: 'api://9946859a-d900-42d4-96f6-ee05752440f6/user_impersonation',
  //microstrategyURL: 'https://micro.pruebas.zsseguros.com',
  marketsURL: 'https://micro.pruebas.zsseguros.com/MicroStrategySSO/servlet/mstrWeb?Server=SDSSANDBOX.ZSCCOE.COM&Project=Mercados&Port=0&evt=3140&src=mstrWeb.3140&documentID=DECB0C0111EBA1016F3E0080EF45D45F',
  misURL: 'https://micro.pruebas.zsseguros.com/MicroStrategySSO/servlet/mstrWeb?evt=3186&src=mstrWeb.3186&subscriptionID=34483E9245411B98B43B9287977B655F&Server=SDSSANDBOX.ZSCCOE.COM&Project=ZS%20MIS&Port=0&share=1',
  
  
    /* Nuevo DEV */
  microstrategyURL: "https://micro.pruebas.zsseguros.com",  
  authority:"https://login.microsoftonline.com/9ef0d257-39bc-470d-8e6d-4f03476e240a",
  clientId: "fe6bbfc3-07fc-407b-87ab-751d0851e486",
  //azureApiKey: "api://35f8dbb2-152a-4c35-b0ec-1ba58149bdc7/user_impersonation",
  //azureApiKey: "api://37b2a82f-06ec-4d32-b3a2-41e1e316042e/user_impersonation",
  //azureApiKey: "api://259c2fda-c2a4-4a03-ad46-d23416b076e2/user_impersonation",
  redirectUri: 'https://sds.pruebas.zsseguros.com',
  //redirectUri: 'http://localhost:4200',
  azureApiUri: 'https://sds-apim-dev.azure-api.net/fa-sik-ms-api-dev-new/',   

  


  menuVisibility: {
    portal: true,
    prehome: true,
    sale: true,
    saleDetail: true,
    wallet: true,
    walletGwpMix: true,
    qualityClaims: true,
    cancellation: true,
    cancelDetail: true,
    retention: true,
    retentionAccordingToRecord: true,
    charge: true,
    renewal: true,
    persistence: true,
    fixedStock: true,
    cancellationCuality: true,
    sinisters: true,
    sinistersDetails: true,
    sinistersRejections: true,
    sinistersQuality: true,
    sinistersMap: false,
    sinistersLossRatio: false,
    slas: true,
    clients: true,
    clientsPortoflio: true,
    clientsOccupation: true,
    clientsVoc: true,
    clientsInternalProcessQuality: false,
    clientsPenetrationSegmentation: true,
    clientsPortfolioDistribution: true,
    backoffice: false,
  },
  requestsCaching: true,
  documentManagement: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

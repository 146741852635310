import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorsService } from '../../services/errors/errors.service';
import { ErrorsDialogComponent } from '../errors-dialog/errors-dialog.component';

@Component({
  selector: 'app-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss']
})
export class NotificationButtonComponent implements OnInit {

  errors: any[];

  showNotifications(): void {
    let backDrop;
    const dialogRef = this.dialog.open(ErrorsDialogComponent, {
      data : {
        sectionTitle: 'dialogs.notifications',
        errors: this.errors
      },
      panelClass: ['mat-dialog-gen', 'mat-dialog-errors'],
    })
    backDrop = document.querySelector('.cdk-overlay-container');
    backDrop.classList.add('overlay-dialog-errors');

    dialogRef.afterClosed().subscribe(result => {
      backDrop.classList.remove('overlay-dialog-errors');
    });
  }

  constructor(
    private errorsService: ErrorsService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.errorsService.errorsObject$
      .subscribe(
        data => {
          this.errors = data;
        }
      )

  }

}

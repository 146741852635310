import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COLORS } from 'src/app/shared/globals/globals';
import { ApiAtlasService } from '../../services/api-atlas/api-atlas.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiDataService } from '../../services/api-data/api-data.service';
import { Router } from '@angular/router';
import { NavigationService } from '../../services/navigation/navigation.service';
import { FiltersService } from '../../services/filters/filters.service';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {

  sectionTitle: string = "";
  info: any = [];
  endpoint: string;
  endpointParams: any;
  tabElements = [];
  section: any;
  completeInfo: any;

  descriptionName: string = "";
  businessCatalogName: string = "";
  filtersName: string = "";
  technicalCatalogName: string = "";
  activePageIndex: number;
  dashboardGuid: string;

  loaded: boolean = false;

  unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private atlasApi: ApiAtlasService,
    private dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private translateService: TranslateService,
    private apiDataService: ApiDataService,
    private router: Router,
    private navigationService: NavigationService,
    private filtersService: FiltersService
  ) {
    this.sectionTitle = data.sectionTitle;
    this.info = data.results;
    this.endpoint = data.endpoint;
    this.endpointParams = data.endpointParams;
  }

  ngOnInit(): void {
    this.loaded = false;

    const description = this.translateService.get('tabs.description');
    const businessCatalog = this.translateService.get('labels.businessCatalogue');
    const filters = this.translateService.get('tabs.filters');
    const technicalCatalog = this.translateService.get('labels.technicalCatalogue');
    const translations = [description, businessCatalog, filters, technicalCatalog];

    this.navigationService.navigationItems$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.activePageIndex = data[0].activeItemIndex;
      });

    this.filtersService.selectedIndexCountry$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        localStorage.setItem("selectedCountry", JSON.stringify(data));
      })

    forkJoin(translations)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: translations => {
          this.descriptionName = translations[0];
          this.businessCatalogName = translations[1];
          this.filtersName = translations[2];
          this.technicalCatalogName = translations[3];
        },
        complete: () => this.retrieveDialogInfo(this.endpoint, this.endpointParams)
      });

  }

  retrieveDialogInfo(endpoint: string, params: any) {
    this.atlasApi.getSectionInfo(endpoint, params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: data => {
          this.section = data;

          if (this.section.relationships?.hasOwnProperty('sections')) {
            this.section.order = 0;
            this.dashboardGuid = this.section.guid;
          } else if (this.section.relationships?.hasOwnProperty('dashboard')) {
            this.dashboardGuid = this.section.relationships.dashboard[0].guid;
          }

          this.setDialogTitle(this.section.name);
          this.createTabs(this.section);
        },

        complete: () => this.loaded = true
      })

  }

  retrieveCompleteInfo(params: any) {
    if (this.completeInfo === undefined) {
      const language = params.filter(v => v.name === 'language')[0]?.value;

      this.atlasApi.getDashboardCategories(language)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(data => {
          const categories = data;
          let filteredCategory = categories.filter(v => v.dashboards.filter(d => d.guid === this.dashboardGuid)[0]);
          if (filteredCategory.length !== 0) {
            const guid = filteredCategory[0].dashboards.filter(v => v.order === this.activePageIndex.toString())[0];

            this.atlasApi.getDashboardDetail(language, guid.guid).pipe(takeUntil(this.unsubscribe$)).subscribe(
              data => {
                const dashboard = data;
                this.section.relationships.sections = dashboard.relationships.sections;
                this.section.controlPanelGuid = dashboard.guid;
                this.section.color = filteredCategory[0].color;
                this.section.title = filteredCategory[0].name;
                this.section.dashboards = filteredCategory[0].dashboards;
                this.completeInfo = this.section;

                localStorage.setItem('dataGovernanceInfo', JSON.stringify(this.completeInfo));
                localStorage.setItem('selectedLanguage', language);
                this.apiDataService.updateDataGovernanceBusinessCategory(undefined);

                this.loaded = true;

                const url = this.router.serializeUrl(this.router.createUrlTree(['/data-governance/business']));
                window.open(url, '_blank');
              }
            );
          }
        });
      localStorage.removeItem('dataGovernanceInfo');
      this.apiDataService.updateDataGovernanceBusinessCategory(undefined);
    } else {
      localStorage.setItem('dataGovernanceInfo', JSON.stringify(this.completeInfo));
      this.apiDataService.updateDataGovernanceBusinessCategory(undefined);

      this.loaded = true;

      const url = this.router.serializeUrl(this.router.createUrlTree(['/data-governance/business']));
      window.open(url, '_blank');
    }

  }

  setDialogTitle(name: string = "") {
    this.sectionTitle = name;
  }

  createTabs(data: any) {
    this.createDescriptionTab(data.description_html);
    this.createFiltersTab(data.relationships?.filters);
    this.createBusinessCatalogTab(data.relationships?.concepts);
    this.createTechnicalCatalogTab(data.relationships?.metrics);
  }

  createDescriptionTab(description: string = "") {
    if (description == "") return;
    this.tabElements.push({
      tabLabel: this.descriptionName,
      tabType: "raw",
      tabContent: description,
      moreInfo: true
    });
  }

  createBusinessCatalogTab(concepts: any[] = []) {
    let tabContent = [];
    concepts.sort((a, b) => {
      if (parseInt(a.internal_id) < parseInt(b.internal_id)) {
        return -1;
      } else {
        return 1;
      }
    });
    for (const concept of concepts) {
      tabContent.push({
        title: concept.internal_id + ' - ' + concept.name,
        firstDescription: concept.rel_description_html,
        bgColor: COLORS.secondary,
        textColor: COLORS.white
      })
    }

    if (tabContent.length == 0) return;
    this.tabElements.push({
      tabLabel: this.businessCatalogName,
      tabType: "list",
      tabContent: tabContent,
      moreInfo: false
    });
  }

  createFiltersTab(filters: any[] = []) {
    let tabContent = [];
    filters.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else {
        return 1;
      }
    });
    for (const filter of filters) {
      tabContent.push({
        title: filter.name,
        firstDescription: filter.rel_description_html,
        bgColor: COLORS.secondary,
        textColor: COLORS.white
      })
    }

    if (tabContent.length == 0) return;
    this.tabElements.push({
      tabLabel: this.filtersName,
      tabType: "list",
      tabContent: tabContent,
      moreInfo: false
    });

  }

  createTechnicalCatalogTab(concepts: any[] = []) {
    let tabContent = [];
    concepts.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else {
        return 1;
      }
    });
    for (const concept of concepts) {
      tabContent.push({
        title: concept.name,
        firstDescription: concept.formula,
        bgColor: COLORS.secondary,
        textColor: COLORS.white
      })
    }

    if (tabContent.length == 0) return;
    this.tabElements.push({
      tabLabel: this.technicalCatalogName,
      tabType: "list",
      tabContent: tabContent,
      moreInfo: false
    });

  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getMoreInfo() {
    this.loaded = false;
    this.retrieveCompleteInfo(this.endpointParams);
  }

}

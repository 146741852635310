import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { first, retry, retryWhen, skip, take } from "rxjs/operators";
import { error } from "selenium-webdriver";
import { ApimstService } from "../services/apimst.service";
import { EventsService } from "../services/events/events.service";

@Injectable({
    providedIn: 'root'
})
export class UserInfoResolver implements Resolve<[]> {

    userHasInfo: boolean = false;

    constructor(
        private apiService: ApimstService,
        private events: EventsService
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<[]> {

        if(this.events.userInfoLoaded$.value.countries){
            return this.events.userInfoLoaded$.asObservable().pipe(first());
        } else {
            return this.events.userInfoLoadedSubject$.asObservable().pipe(first());
        }
    }


}
import { Component, NgModule, OnInit } from '@angular/core';
import { ApimstService } from '../../services/apimst.service';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { EventsService } from '../../services/events/events.service';
import { map, take } from 'lodash';
import { filter, first, shareReplay } from 'rxjs/operators';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { FiltersService } from '../../services/filters/filters.service';
import * as _ from 'lodash';
import { DateService } from '../../services/date/date.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-kpis-header',
  templateUrl: './kpis-header.component.html',
  styleUrls: ['./kpis-header.component.scss']
})

export class KpisHeaderComponent implements OnInit {

  activeCountry$ = new BehaviorSubject<Array<any>>([]);

  // Store data from requests
  indicatorsRequests: any[] = [];
  suscribeUnsuscribePolicy: any;
  portfolioDiff: any;
  policiesPortfolio: any;
  averageDuration: any;
  suscribeUnsuscribeCustomer: any;
  portfolioCustomerDiff: any;
  clientsPortfolio: any;

  suscribeUnsuscribePolicyObject: any = {};
  portfolioDiffObject: any = {};
  portfolioPoliciesObject: any = {};
  averageDurationObject: any = {};
  suscribeUnsuscribeCustomerObject: any = {};
  portfolioCustomerDiffObject: any = {};
  portfolioClientsObject: any = {};

  headerIndicatorsObject: any[] = [];

  perYearPrefix: string = "PY";
  perMonthPrefix: string = "PM";
  yearToDatePrefix: string = "YTD";

  emptyMonth = "";
  emptyYear = "";

  countryFilter: any[] = [];
  appliedFilters: any[] = [];
  filtersValues: any[] = [];

  loaded: boolean;

  // Format pipe
  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  monthNames = [
    "monthsNames.january",
    "monthsNames.february",
    "monthsNames.march",
    "monthsNames.april",
    "monthsNames.may",
    "monthsNames.june",
    "monthsNames.july",
    "monthsNames.august",
    "monthsNames.september",
    "monthsNames.october",
    "monthsNames.november",
    "monthsNames.december"
  ];
  daysName: string = '';
  filtersApplied$ = new BehaviorSubject<any>( [{}] );

  today = new Date();
  year = this.today.getFullYear();
  month = this.monthNames[this.today.getMonth()];
  kpis: any[] = [];

  subscription: Subscription;

  constructor(
    private apimstService: ApimstService,
    private evenstService: EventsService,
    private utilsService: UtilsService,
    private dateService: DateService,
    private filtersService: FiltersService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    // this.retrieveHeaderIndicatorsData();

    this.subscribeToLoginRedirection();
    // this.subscribeToUserInfoLoaded();
    this.subscribeToFiltersValues();
  }

  retrieveHeaderIndicatorsData() {
    this.loaded = false;

    this.getTranslations();

    this.handleRequests();

    console.log("Indicator Request");
    console.log(this.indicatorsRequests);

    /*let results: any[] = [];
    this.apimstService.getHeaderIndicators("sp", this.appliedFilters).subscribe(result =>{
      results[0]=result;
      this.matchRequestResults(results);

      this.kpis = [];
      this.setIndicatorsValues();


      this.loadedKpis = true;
    });*/

    // Fork join doens't work
    /*let results: any[] = [];
    for(let i:number = 0; i<this.indicatorsRequests.length; i++){
      console.log("Indicator request: "+i);
      console.log(this.indicatorsRequests[i]);
      this.indicatorsRequests[i].subscribe(result =>{

        console.log("***************** indicatorsRequests result");
        console.log(result);

        results[i]=result;
        this.matchRequestResults(results);

        this.kpis = [];
        this.setIndicatorsValues();


        this.loadedKpis = true;
      });
    }*/
    this.subscription = forkJoin(this.indicatorsRequests)
      .subscribe(results => {

        console.log("Indicator Request results");

        this.matchRequestResults(results);

        this.kpis = [];
        this.setIndicatorsValues();

        this.loaded = true;
    });


  }

  getTranslations(): void {
    this.subscription = this.translateService.get('labels.days').subscribe(days => this.daysName = days);
  }

  subscribeToFiltersValues(): void {
    this.filtersService.filtersValues$
      .subscribe({
        next: filtersValues => {
          this.filtersValues = filtersValues;

          const isValidFilter = data => data.name == "country" ||
                                data.name == "year" ||
                                data.name == "month" ||
                                data.name == "day" ||
                                data.name == "currency";

          if(this.filtersValues.length != 0) {
            this.appliedFilters = filtersValues.filter(isValidFilter);

            // if(JSON.stringify(this.appliedFilters) != JSON.stringify(this.filtersApplied$.value)) {
            //   this.filtersApplied$.next(this.appliedFilters);
            //   this.retrieveHeaderIndicatorsData();
            // } else {
            //   this.filtersApplied$.next(this.appliedFilters);
            // }
            console.log("3");
            this.retrieveHeaderIndicatorsData();

          }
        }
      })
  }

  subscribeToLoginRedirection(): void {
    this.evenstService.loginRedirectionSubject$
      .subscribe(
        data => {
          if (data == true) {
            console.log("1");
            this.retrieveHeaderIndicatorsData();
          }
        }
      )
  }

  subscribeToUserInfoLoaded(): void {
    this.evenstService.userInfoLoaded$
      .subscribe(
        data => {
          console.log("2");
          this.retrieveHeaderIndicatorsData();
        }
      )
  }

  handleRequests() {
    let getSuscribeUnsuscribePolicy = this.apimstService.getHeaderIndicators("sp", this.appliedFilters);      // Suscribe/Unsuscribe Policy
    let getPortfolioDiff = this.apimstService.getHeaderIndicators("pd", this.appliedFilters);                 // Portfolio diff Policy

    let getPoliciesPortfolio = this.apimstService.getHeaderIndicators("spm", this.appliedFilters);            // Policies portfolio

    let getAverageDuration = this.apimstService.getHeaderIndicators("ad", this.appliedFilters);               // Average duration
    let getSuscribeUnsuscribeCustomer = this.apimstService.getHeaderIndicators("sc", this.appliedFilters);    // Suscribe/Unsuscribe Customer
    let getPortfolioCustomerDiff = this.apimstService.getHeaderIndicators("pc", this.appliedFilters);         // Portfolio Customer Diff

    let getClientsPortfolio = this.apimstService.getHeaderIndicators("scy", this.appliedFilters);             // Clients portfolio

    this.indicatorsRequests = [
      getSuscribeUnsuscribePolicy,
      getPortfolioDiff,
      getPoliciesPortfolio,
      getAverageDuration,
      getSuscribeUnsuscribeCustomer,
      getPortfolioCustomerDiff,
      getClientsPortfolio
    ]
  }

  matchRequestResults(results: any): void {
    this.suscribeUnsuscribePolicy = results[0];
    this.portfolioDiff = results[1];
    this.policiesPortfolio = results[2];
    this.averageDuration = results[3];
    this.suscribeUnsuscribeCustomer = results[4];
    this.portfolioCustomerDiff = results[5];
    this.clientsPortfolio = results[6];
  }

  setIndicatorsValues(): void {
    this.setUnsuscribePolicyValues();
    this.setPortfolioDiff();
    this.setPortfolioPolicy();
    this.setAverageDuration();
    this.setSuscribeUnsuscribeCustomer();
    this.setPortfolioCustomerDiff();
    this.setPortfolioClients();
  }

  setUnsuscribePolicyValues(): void {
    this.suscribeUnsuscribePolicyObject.name = "kpisHeader.hightLowPolicy";
    this.suscribeUnsuscribePolicyObject.month = this.emptyMonth;
    this.suscribeUnsuscribePolicyObject.year = this.emptyYear;
    if (this.isValid(this.suscribeUnsuscribePolicy)) {
      this.suscribeUnsuscribePolicyObject.value = this.suscribeUnsuscribePolicy['value'] != null ? this.formatPipe.transform(parseFloat(this.suscribeUnsuscribePolicy['value'].replace(/,/g, ''))) : "0";
      this.suscribeUnsuscribePolicyObject.diff = this.suscribeUnsuscribePolicy['diff'] != null ? this.perYearPrefix + " " + this.formatPipe.transform(parseFloat(this.suscribeUnsuscribePolicy['diff'].replace(/-|,/g, ''))) + "%" : "";
      this.suscribeUnsuscribePolicyObject.diff_trend = parseInt(this.suscribeUnsuscribePolicy['diff_trend']);
      this.suscribeUnsuscribePolicyObject.diff_direction = this.utilsService.isNegative(this.suscribeUnsuscribePolicy['diff'].toString()) == false ? 1 : 0;
    } else {
      this.suscribeUnsuscribePolicyObject.value = "0";
      this.suscribeUnsuscribePolicyObject.diff = "";
      this.suscribeUnsuscribePolicyObject.diff_trend = 1;
      this.suscribeUnsuscribePolicyObject.diff_direction = 0;
    }

    this.kpis.push(this.suscribeUnsuscribePolicyObject);
  }

  setPortfolioDiff(): void {
    this.portfolioDiffObject.name = "kpisHeader.walletVariationPolicies";
    this.portfolioDiffObject.month = this.emptyMonth;
    this.portfolioDiffObject.year = this.emptyYear;
    if (this.isValid(this.portfolioDiff)) {
      this.portfolioDiffObject.value = this.portfolioDiff['value'] != null ? this.formatPipe.transform(parseFloat(this.portfolioDiff['value'].replace(/,/g, ''))) : "0";
      this.portfolioDiffObject.diff = this.portfolioDiff['diff'] != null ? this.perYearPrefix + " " + this.formatPipe.transform(parseFloat(this.portfolioDiff['diff'].replace(/-|,/g, ''))) + "%" : "";
      this.portfolioDiffObject.diff_trend = parseInt(this.portfolioDiff['diff_trend']);
      this.portfolioDiffObject.diff_direction = this.utilsService.isNegative(this.portfolioDiff['diff'].toString()) == false ? 1 : 0;
    } else {
      this.portfolioDiffObject.value = "0";
      this.portfolioDiffObject.diff = "";
      this.portfolioDiffObject.diff_trend = 1;
      this.portfolioDiffObject.diff_direction = 0;
    }

    this.kpis.push(this.portfolioDiffObject);
  }

  setPortfolioPolicy(): void {
    this.portfolioPoliciesObject.name = "kpisHeader.portfolioPolicies";
    this.portfolioPoliciesObject.month = this.emptyMonth;
    this.portfolioPoliciesObject.year = this.emptyYear;
    if (this.isValid(this.policiesPortfolio)) {
      this.portfolioPoliciesObject.value = this.policiesPortfolio['value'] != null ? this.formatPipe.transform(parseFloat(this.policiesPortfolio['value'].replace(/,/g, ''))) : "0";
      this.portfolioPoliciesObject.diff = this.policiesPortfolio['diff'] != null ? this.perYearPrefix + " " + this.formatPipe.transform(parseFloat(this.policiesPortfolio['diff'].replace(/-|,/g, ''))) + "%" : "";
      this.portfolioPoliciesObject.diff_trend = parseInt(this.policiesPortfolio['diff_trend']);
      this.portfolioPoliciesObject.diff_direction = this.utilsService.isNegative(this.policiesPortfolio['diff'].toString()) == false ? 1 : 0;
    } else {
      this.portfolioPoliciesObject.value = "0";
      this.portfolioPoliciesObject.diff = "";
      this.portfolioPoliciesObject.diff_trend = 1;
      this.portfolioPoliciesObject.diff_direction = 0;
    }

    this.kpis.push(this.portfolioPoliciesObject);
  }

  setAverageDuration(): void {
    this.averageDurationObject.name = "kpisHeader.averageDurationCurrentPolicies";
    this.averageDurationObject.month = this.emptyMonth;
    this.averageDurationObject.year = this.emptyYear;
    if (this.isValid(this.averageDuration)) {
      this.averageDurationObject.value = this.averageDuration['value'] != null ? this.formatPipe.transform(parseFloat(this.averageDuration['value'].replace(/,/g, ''))) + " " + this.daysName : "0";
      this.averageDurationObject.diff = this.averageDuration['diff'] != null ? this.perMonthPrefix + " " + this.formatPipe.transform(parseFloat(this.averageDuration['diff'].replace(/-|,/g, ''))) + "%" : "";
      this.averageDurationObject.diff_trend = parseInt(this.averageDuration['diff_trend']);
      this.averageDurationObject.diff_direction = this.utilsService.isNegative(this.averageDuration['diff'].toString()) == false ? 1 : 0;
    } else {
      this.averageDurationObject.value = "0";
      this.averageDurationObject.diff = "";
      this.averageDurationObject.diff_trend = 1;
      this.averageDurationObject.diff_direction = 0;
    }

    this.kpis.push(this.averageDurationObject);
  }

  setSuscribeUnsuscribeCustomer(): void {
    this.suscribeUnsuscribeCustomerObject.name = "kpisHeader.hightLowClients";
    this.suscribeUnsuscribeCustomerObject.month = this.emptyMonth;
    this.suscribeUnsuscribeCustomerObject.year = this.emptyYear;
    if (this.isValid(this.suscribeUnsuscribeCustomer)) {
      this.suscribeUnsuscribeCustomerObject.value = this.suscribeUnsuscribeCustomer['value'] != null ? this.formatPipe.transform(parseFloat(this.suscribeUnsuscribeCustomer['value'].replace(/[{()}]|,/g, ''))) : "0";
      this.suscribeUnsuscribeCustomerObject.diff = this.suscribeUnsuscribeCustomer['diff'] != null ? this.perYearPrefix + " " + this.formatPipe.transform(parseFloat(this.suscribeUnsuscribeCustomer['diff'].replace(/-|,/g, ''))) + "%" : "";
      this.suscribeUnsuscribeCustomerObject.diff_trend = parseInt(this.suscribeUnsuscribeCustomer['diff_trend']);
      this.suscribeUnsuscribeCustomerObject.diff_direction = this.utilsService.isNegative(this.suscribeUnsuscribeCustomer['diff'].toString()) == false ? 1 : 0;
    } else {
      this.suscribeUnsuscribeCustomerObject.value = "0";
      this.suscribeUnsuscribeCustomerObject.diff = "";
      this.suscribeUnsuscribeCustomerObject.diff_trend = 1;
      this.suscribeUnsuscribeCustomerObject.diff_direction = 0;
    }

    this.kpis.push(this.suscribeUnsuscribeCustomerObject);
  }

  setPortfolioCustomerDiff(): void {
    this.portfolioCustomerDiffObject.name = "kpisHeader.clientWalletVariation";
    this.portfolioCustomerDiffObject.month = this.emptyMonth;
    this.portfolioCustomerDiffObject.year = this.emptyYear;
    if (this.isValid(this.portfolioCustomerDiff)) {
      this.portfolioCustomerDiffObject.value = this.portfolioCustomerDiff['value'] != null ? this.formatPipe.transform(parseFloat(this.portfolioCustomerDiff['value'].replace(/[{()}]|,/g, ''))) : "0";
      this.portfolioCustomerDiffObject.diff = this.portfolioCustomerDiff['diff'] != null ? this.perYearPrefix + " " + this.formatPipe.transform(parseFloat(this.portfolioCustomerDiff['diff'].replace(/-|,/g, ''))) + "%" : "";
      this.portfolioCustomerDiffObject.diff_trend = parseInt(this.portfolioCustomerDiff['diff_trend']);
      this.portfolioCustomerDiffObject.diff_direction = this.utilsService.isNegative(this.portfolioCustomerDiff['diff'].toString()) == false ? 1 : 0;
    } else {
      this.portfolioCustomerDiffObject.value = "0";
      this.portfolioCustomerDiffObject.diff = "";
      this.portfolioCustomerDiffObject.diff_trend = 1;
      this.portfolioCustomerDiffObject.diff_direction = 0;
    }

    this.kpis.push(this.portfolioCustomerDiffObject);
  }

  setPortfolioClients(): void {
    this.portfolioClientsObject.name = "kpisHeader.portfolioClients";
    this.portfolioClientsObject.month = this.emptyMonth;
    this.portfolioClientsObject.year = this.emptyYear;
    if (this.isValid(this.clientsPortfolio)) {
      this.portfolioClientsObject.value = this.clientsPortfolio['value'] != null ? this.formatPipe.transform(parseFloat(this.clientsPortfolio['value'].replace(/,/g, ''))) : "0";
      this.portfolioClientsObject.diff = this.clientsPortfolio['diff'] != null ? this.perYearPrefix + " " + this.formatPipe.transform(parseFloat(this.clientsPortfolio['diff'].replace(/-|,/g, ''))) + "%" : "";
      this.portfolioClientsObject.diff_trend = parseInt(this.clientsPortfolio['diff_trend']);
      this.portfolioClientsObject.diff_direction = this.utilsService.isNegative(this.clientsPortfolio['diff'].toString()) == false ? 1 : 0;
    } else {
      this.portfolioClientsObject.value = "0";
      this.portfolioClientsObject.diff = "";
      this.portfolioClientsObject.diff_trend = 1;
      this.portfolioClientsObject.diff_direction = 0;
    }

    this.kpis.push(this.portfolioClientsObject);
  }

  isValid(indicator: any) {
    const isValid = indicator.hasOwnProperty('value') &&
                    indicator.hasOwnProperty('diff') &&
                    indicator.hasOwnProperty('diff_trend') &&
                    indicator['value'] != "" &&
                    indicator['diff'] != "" &&
                    indicator['diff_trend'] != "";
    return isValid;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

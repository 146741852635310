import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { Subject } from 'rxjs'
import { environment } from 'src/environments/environment';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import _ from 'lodash';

@Component({
  selector: 'app-cancellation-reasons',
  templateUrl: './cancellation-reasons.component.html',
  styleUrls: ['./cancellation-reasons.component.scss']
})
export class CancellationReasonsComponent implements OnInit, OnDestroy {

  bonus: string = 'panels.bonus';
  policy: string = 'panels.policy';
  graphName: string = 'cancellationReasons';
  unsubscribe$: Subject<any> = new Subject<any>();
  cancellationReasonsItems: any;
  selectedCancellationReasons: any = [];

  title: string = "";

  activeLang: string;
  pageId: string = 'cancellation-detail--cancellation-reasons';
  hasGobernanceInfo: boolean = false;
  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  endpointParams: any = [];

  // Default constructor
  constructor(
    private eventsService: EventsService,
    private i18n: I18nService,
    private atlasHelper: AtlasHelperService
  ) { }

  // OnInit
  ngOnInit(): void {
    this.subscribeToActiveLanguage();

    this.eventsService.cancellationReasons$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(data => {
      this.cancellationReasonsItems = data;
      this.selectedCancellationReasons = data;
    });
    
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

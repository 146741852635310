
<component-loader *ngIf="!loaded" [width]="" [height]="160"></component-loader>

<div *ngIf="loaded" class="mt-2 d-flex justify-content-end align-items-center">
    <app-legend class="mr-2" name="labels.charged" color="dark-blue" type="round" gradient="v-gradient"></app-legend>
    <app-legend class="mr-2" name="labels.sentToCharge" color="light-blue" type="round" gradient="h-gradient"></app-legend>
    <app-legend class="mr-2" name="labels.tax" color="dark-yellow" type="line" gradient="h-gradient"></app-legend>
    <app-legend name="labels.target" color="light-green" type="line" gradient="h-gradient"></app-legend>
    <!-- <mat-icon *ngIf="loaded" class="excel-icon ml-2" svgIcon="excel" (click)="exportAsXLSX()"></mat-icon> -->
</div>

<div *ngIf="loaded">
    <grouped-vertical-horizontal-graph-d-axis
        [view]="view"
        [scheme]="comboBarScheme"
        [results]="groupedVerticalGraph"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [yRightAxisTickFormatting]="yRightAxisTickFormatting"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [legend]="showLegend"
        [barPadding]=1
        [groupPadding]=2
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [legendTitle]="legendTitle"
        [lineChart]="horizontalGraph"
        [showGridLines]="showGridLines"
        [showRightGridLines]="showRightGridLines"
        [customColors]="customColors"
        [roundEdges]="roundEdges"
        [activeEntries]="activeEntries"
        [trimXAxisTicks]="trimXAxisTicks"
        [trimYAxisTicks]="trimYAxisTicks"
        [rotateXAxisTicks]="rotateXAxisTicks"
        [maxXAxisTickLength]="maxXAxisTickLength"
        [maxYAxisTickLength]="maxYAxisTickLength"
        [tooltipDisabled]="tooltipDisabled"
        [roundDomains]="roundDomains"
        >
    </grouped-vertical-horizontal-graph-d-axis>
</div>
<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <span class="title section-card-title">{{ 'panels.issuanceTargets' | translate }}</span>
        <div class="dropdown-container">
            <app-dropdown
                [title]="titleSelector"
                [items]="itemsSelector"
                [dropdownId]="dropdownId">
            </app-dropdown>
        </div>
    </div>

    <component-loader [width]="1240" [height]="520" *ngIf="!loaded"></component-loader>

    <div class="kpis-graphic-container-main mt-3" *ngIf="loaded">
        <app-kpis-graphic-container [items]="SLAsData"></app-kpis-graphic-container>
    </div>

</app-widget>
<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex align-items-center">
            <span class="title section-card-title">{{ 'panels.SantanderClientPenetration' | translate }}</span>
            <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams"
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </div>
    </div>

    <app-santander-clients-penetration-main></app-santander-clients-penetration-main>

</app-widget>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-discovery-card',
  templateUrl: './data-discovery-card.component.html',
  styleUrls: ['./data-discovery-card.component.scss']
})
export class DataDiscoveryCardComponent implements OnInit {

  @Input() folder;

  constructor() { }

  ngOnInit(): void {
  }

  goToFolder(url: string) {
    window.open(url, '_blank');
  }


}

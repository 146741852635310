import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-double-percentage-kpi',
  templateUrl: './double-percentage-kpi.component.html',
  styleUrls: ['./double-percentage-kpi.component.scss']
})
export class DoublePercentageKpiComponent implements OnInit {

  @Input() mainValue;
  @Input() firstPercentageValue: string;
  @Input() firstPercentagePrevious: string;
  @Input() firstPercentageTrendValue: number;
  @Input() firstPercentageTrendDirection: number;
  @Input() secondPercentageValue: string;
  @Input() secondPercentagePrevious: string;
  @Input() secondPercentageTrendValue: number;
  @Input() secondPercentageTrendDirection: number;

  constructor() { }

  ngOnInit(): void { }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';
import { forkJoin, Subject } from 'rxjs'
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { takeUntil } from 'rxjs/operators'
import { trim } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { CurrencyService } from 'src/app/shared/services/currency/currency.service';

@Component({
  selector: 'app-btn-cancellation',
  templateUrl: './btn-cancellation.component.html',
  styleUrls: ['./btn-cancellation.component.scss']
})
export class BtnCancellationComponent implements OnInit, OnDestroy {

  cancellationRate: any = {}
  cancellationBonusRate: any = {}
  cancellationsMonth: any = {}
  cancellationsPlan: any = {}
  cancellationsTarget: any = {}
  policyAverageDuration: any = {}
  earlyFall: any = {}
  percentagePrevious: string = "labels.pm";
  currencySymbol: string = '';
  percentageSymbol: string = '%';

  // Format pipe
  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  // Check number symbol
  isNegative: boolean = false;

  // options
  view: any[] = [100, 300];
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = false;
  xAxis: boolean = false;
  yAxis: boolean = false;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  timeline: boolean = false;

  colorScheme = {
    domain: [COLORS.white]
  };

  // Subscription
  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;
  cancellationRateRolling: any = [];
  loaded: boolean = false;
  monthNames: any;
  planName: string;
  cancellationName: string;
  monthNamesWithNumber: any;
  rateName: any;

  constructor(
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private translateService: TranslateService,
    private utilsService: UtilsService,
    private currencyService: CurrencyService
  ) { }

  ngOnInit(): void {

    this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        if (data.length !== 0) {
          this.filtersValues = data;
          this.retrieveCancellationHomeData();
        }
      }
    );

    this.currencyService.currencyInfo$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      currencyInfo => {
        this.currencySymbol = currencyInfo.symbol;
      }
    )

  }

  private retrieveCancellationHomeData() {

    this.loaded = false;

    this.getTranslations();

    const cancellationRate = this.apimstService.getCancellationQualityIndicator('cri', this.filtersValues);
    const cancellationBonusRate = this.apimstService.getCancellationQualityIndicator('crip', this.filtersValues);
    const policyAverageDuration = this.apimstService.getCancellationQualityIndicator('cad', this.filtersValues);
    const earlyFall = this.apimstService.getQualityIndicators('ef', this.filtersValues);
    const cancellationsTarget = this.apimstService.getCancellationQualityIndicator('cti', this.filtersValues);
    const cancellationRateRolling = this.apimstService.getCancellationRateRolling(this.filtersValues);
    const cancellationsNumber = this.apimstService.getCancellationQualityIndicator('ccai', this.filtersValues);
    const requests = [cancellationRate, cancellationBonusRate, policyAverageDuration, earlyFall, cancellationsTarget, cancellationRateRolling, cancellationsNumber];

    forkJoin(requests)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        results => {
          let cancellationRateData;
          let cancellationBonusRateData;
          let policyAverageDurationData;
          let earlyFallData;
          let cancellationTargetData;
          let cancellationRateRollingData;
          let cancellationsNumberData;

          results[0] ? cancellationRateData = results[0] : cancellationRateData = {};
          results[1] ? cancellationBonusRateData = results[1] : cancellationBonusRateData = {};
          results[2] ? policyAverageDurationData = results[2] : policyAverageDurationData = {};
          results[3] ? earlyFallData = results[3] : earlyFallData = {};
          results[4] ? cancellationTargetData = results[4] : cancellationTargetData = {};
          results[5] ? cancellationRateRollingData = results[5] : cancellationRateRollingData = {};
          results[6] ? cancellationsNumberData = results[6] : cancellationsNumberData = {};

          this.cancellationRate = {
            title: 'labels.cancellationRatePolicy',
            main_value: (cancellationRateData.value || trim(cancellationRateData.value).length > 0) ? this.formatPipe.transform( parseFloat(cancellationRateData.value.replace(/,/g, '')) ) + "%" : -1,
          };

          this.cancellationBonusRate = {
            title: 'labels.cancellationRateBonus',
            main_value: (cancellationBonusRateData.value || trim(cancellationBonusRateData.value).length > 0) ? this.formatPipe.transform( parseFloat(cancellationBonusRateData.value.replace(/,/g, '')) ) + "%" : -1,
          };

          this.policyAverageDuration = {
            title: 'labels.policyAverageDuration',
            main_value: (policyAverageDurationData.value || trim(policyAverageDurationData.value).length > 0) ? this.formatPipe.transform( parseFloat(policyAverageDurationData.value.replace(/,/g, '')) ) : -1,
            trend_value_previous: this.percentagePrevious,
            trend_value: (policyAverageDurationData.diff || trim(policyAverageDurationData.diff).length > 0) ? this.formatPipe.transform( parseFloat(policyAverageDurationData.diff.replace(/-|,/g, '')) ) : 0,
            trend_color: (policyAverageDurationData.diff_trend || trim(policyAverageDurationData.diff_trend).length > 0) ? parseFloat(policyAverageDurationData.diff_trend.replace(/-|,/g, '')) : 0,
            trend_direction: this.utilsService.isNegative( policyAverageDurationData.diff ) == false ? 1 : 0
          };

          this.earlyFall = {
            title: 'panels.earlyFallWithMonths',
            main_value: (earlyFallData.value || trim(earlyFallData.value).length > 0) ? this.formatPipe.transform( parseFloat(earlyFallData.value.replace(/,/g, '')) ) : -1,
            trend_value_previous: this.percentagePrevious,
            trend_value: (earlyFallData.diff || trim(earlyFallData.diff).length > 0) ? this.formatPipe.transform( parseFloat(earlyFallData.diff.replace(/-|,/g, '')) ) : 0,
            trend_color: (earlyFallData.diff_trend || trim(earlyFallData.diff_trend).length > 0) ? parseFloat(earlyFallData.diff_trend.replace(/-|,/g, '')) : 0,
            trend_direction: this.utilsService.isNegative( earlyFallData.diff ) == false ? 1 : 0,
            currency: this.percentageSymbol
          };

          this.cancellationsMonth = {
            title: this.cancellationName + ' ' + this.monthNamesWithNumber[this.handlePreviousMonth( parseInt(cancellationsNumberData.month) )],
            main_value: (cancellationsNumberData.value || trim(cancellationsNumberData.value).length > 0) ? this.formatPipe.transform( parseFloat(cancellationsNumberData.value.replace(/,/g, '')) ) : -1,
            trend_value_previous: this.percentagePrevious,
            trend_value: (cancellationsNumberData.diff || trim(cancellationsNumberData.diff).length > 0) ? this.formatPipe.transform( parseFloat(cancellationsNumberData.diff.replace(/-|,/g, '')) ) : 0,
            trend_color: (cancellationsNumberData.diff_trend || trim(cancellationsNumberData.diff_trend).length > 0) ? parseFloat(cancellationsNumberData.diff_trend.replace(/-|,/g, '')) : 0,
            trend_direction: this.utilsService.isNegative( cancellationsNumberData.diff ) == false ? 1 : 0,
            currency: this.currencySymbol
          }

          this.cancellationsPlan = {
            title: this.cancellationName + ' ' + this.monthNamesWithNumber[this.handlePreviousMonth( parseInt(cancellationTargetData.month) )] + ' ' + this.planName,
            main_value: (cancellationTargetData.value || trim(cancellationTargetData.value).length > 0) ? this.formatPipe.transform( parseFloat(cancellationTargetData.value.replace(/,/g, '')) ) : -1,
            trend_value_previous: this.percentagePrevious,
            trend_value: (cancellationTargetData.diff || trim(cancellationTargetData.diff).length > 0) ? this.formatPipe.transform( parseFloat(cancellationTargetData.diff.replace(/-|,/g, '')) ) : 0,
            trend_color: (cancellationTargetData.target_diff_trend || trim(cancellationTargetData.target_diff_trend).length > 0) ? parseFloat(cancellationTargetData.diff_trend.replace(/-|,/g, '')) : 0,
            trend_direction: this.utilsService.isNegative( cancellationTargetData.target_diff ) == false ? 1 : 0
          };

          if (cancellationRateRollingData.length !== 0 && Array.isArray(cancellationRateRollingData)) {
            let rateSeries: any = [];
            let targetSeries: any = [];
            for (let month of cancellationRateRollingData) {
              rateSeries.push(
                {
                  name: this.monthNames[month.month] + ' ' + month.year.slice(2,4),
                  value: (month.rate || trim(month.rate).length > 0) ? parseFloat(month.rate.replace(/-|,/g, '')) : 0
                }
              );

              targetSeries.push(
                {
                  name: this.monthNames[month.month] + ' ' + month.year.slice(2,4),
                  value: (month.target || trim(month.target).length > 0) ? parseFloat(month.target.replace(/-|,/g, '')) : 0
                }
              );
            }

            this.cancellationRateRolling = [];
            this.cancellationRateRolling.push(
              {
                name: this.rateName,
                series: rateSeries
              },
              {
                name: this.planName,
                series: targetSeries
              }
            );
          }

          this.loaded = true;



        }
      );
  }

  handlePreviousMonth( index: number ) {
    if( index > 0 ) {
      index = index-1;
    } else {
      index = 11;
    }
    return index;
  }

  getTranslations(): void {
    const months = this.translateService.get('abbreviatedMonthNames');
    const cancellation = this.translateService.get('panels.cancellations');
    const plan = this.translateService.get('labels.plan');
    const monthsWithNumber = this.translateService.get('monthNamesWihtNumber');
    const rateTitle = this.translateService.get('labels.cancellationRatePolicy');

    forkJoin([months, cancellation, plan, monthsWithNumber, rateTitle]).pipe(takeUntil(this.unsubscribe$))
    .subscribe(results =>
      {
        this.monthNames = results[0];
        this.cancellationName = results[1];
        this.planName = results[2];
        this.monthNamesWithNumber = results[3];
        this.rateName = results[4];

      });
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<div class="header d-flex justify-content-between align-items-center">
  <div class="header-left">
    <app-hamburguer-menu></app-hamburguer-menu>
    <div class="header-bg">
      <div class="header-bg-bar"></div>
      <div class="header-bg-color"></div>
      <div class="header-bg-end"></div>
    </div>
  </div>
  <div class="header-right">
    <app-kpis-header></app-kpis-header>

    <div class="country-block d-none">
      <div class="d-flex justify-content-between align-items-center pr-4">
          <div class="country-block-data d-flex align-items-center">
            <div class="d-flex align-items-center" *ngIf="showCountryData">
              <app-kpi-home [item]="clients"></app-kpi-home>
              <app-kpi-home [item]="promoters"></app-kpi-home>
              <app-kpi-home [item]="policies"></app-kpi-home>
              <app-kpi-home [item]="claims"></app-kpi-home>
            </div>
          </div>
          <app-btn-filter
            [title]="countryName"
            [inactiveIcon]="inactiveIcon"
            [activeIcon]="activeIcon"
            [isActive]="isActive"
            (open)="onOpen($event)"
            (close)="onClose($event)">
          </app-btn-filter>
      </div>
    </div>

    <app-filter-button></app-filter-button>
  </div>
  
  <app-header-submenu></app-header-submenu>
</div>

<app-side-filters *ngIf="filtersOn == true" [@animateFilters]></app-side-filters>
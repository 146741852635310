import { Component, Input, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EventsService } from '../../services/events/events.service';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss']
})
export class HtmlEditorComponent implements OnInit {

  @Input() htmlContent;
  @Input() width;
  @Input() height;
  @Input() textEditor;
  @Input() elementGuid;

  // HTML editor options
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '150px',
    minHeight: '',
    maxHeight: '200px',
    width: '',
    minWidth: '0',
    placeholder: '',
    translate: 'no',
    enableToolbar: true,
    showToolbar: true,
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [],
      [
        'insertImage',
        'insertVideo',
        'toggleEditorMode'
      ]
    ],
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' }
    ],

    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',

  };

  // Default constructor
  constructor(
    private eventsService: EventsService
  ) { }

  // OnInit
  ngOnInit(): void {
    this.config.width = this.width;
    this.config.minWidth = this.width;
  }

  // Event to detect that the edition has been canceled
  cancelEdition() {      
    const data = {
      cancel: true,
      elementGuid: this.elementGuid
    };
    this.eventsService.cancelHtmlTextEdition$.emit(data);
  }

  // Event to detect that the edition has been saved
  saveEdition() {
    const data = {
      edition: true,
      content: this.htmlContent,
      elementGuid: this.elementGuid
    };
    this.eventsService.saveHtmlTextEdition$.emit(data);
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-clients-voc',
  templateUrl: './clients-voc.component.html',
  styleUrls: ['./clients-voc.component.scss']
})
export class ClientsVocComponent implements OnInit, OnDestroy  {

  disableProductTypeBlock: Boolean;

  constructor(private filtersService: FiltersService) { 
    this.filtersService.disableProductTypeBlock.subscribe(disableProductTypeBlock => this.disableProductTypeBlock = disableProductTypeBlock)
  }

  ngOnInit(): void {
    this.filtersService.setDisableProductTypeBlock(true);
  }

  ngOnDestroy() {
    this.filtersService.setDisableProductTypeBlock(false);
  }

}

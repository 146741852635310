import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highmaps';
import { COLORS } from 'src/app/shared/globals/globals';
import { MapService } from 'src/app/shared/services/map/map.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApimstService } from 'src/app/shared/services/apimst.service';

@Component({
  selector: 'app-clients-stock-by-geo-map',
  templateUrl: './clients-stock-by-geo-map.component.html',
  styleUrls: ['./clients-stock-by-geo-map.component.scss']
})
export class ClientsStockByGeoMapComponent implements OnInit {

  loaded: boolean = false;
  isValidMap: boolean = false;

  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = "mapChart";
  chartOptions: Highcharts.Options;
  countryMap: any;
  mapSeries: any[] = [];
  valueSuffix: string = "";
  accumulatedValue: number = 0;

  filtersValues: any[] = [];
  countryFilter: string = "";

  minValue: number = 0;
  maxValue: number = 0;

  unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private mapService: MapService,
    private filtersService: FiltersService,
    private api: ApimstService,
  ) { }

  ngOnInit(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.loaded = false;
          this.isValidMap = false;

          if (data.length !== 0) {
            this.filtersValues = data;

            this.resetValues();
            this.retrieveLossRatioKpi(this.filtersValues);
          }
        }
      );
  }

  resetValues() {
    this.countryMap = {};
    this.mapSeries = [];
  }

  retrieveLossRatioKpi(filtersValues: any[] = []): void {
    this.loaded = false;
    this.isValidMap = false;

    this.api.getClientsStockByGeolocation("csl", filtersValues)
      .subscribe({
        next: data => {
          if (!this.isValidData(data)) { this.loaded = true; return }
          
          let zones: any[] = [];
          this.countryFilter = this.mapService.getUserSelectedCountry(this.filtersValues);
          zones = this.mapService.getCountryMapZones(this.countryFilter);
          this.countryMap = this.mapService.getMapByCountryCode(this.countryFilter);
          this.mapSeries = this.mapService.setMapZoneValues(zones, data);
          this.accumulatedValue = this.mapService.getAccumulatedValue(data);

          this.setchartOptions();
          
          this.loaded = true;
          this.isValidMap = true;
        },
        error: (error) => {
          this.loaded = true;
          this.isValidMap = false;
          console.log("An error ocurred while retrieving clients stock info per geolocation data: ");
          console.log(error);
        }
      })
  }

  isValidData(data) {
    const isValid = typeof data != "undefined" && data != null;
    return isValid;
  }

  setchartOptions() {
    this.chartOptions = {
      colorAxis: {
        // min: 0,
        // max: this.maxValue,
        // type: 'linear',
        // minColor: '#efecf3',
        // maxColor: '#990041',
      },
      chart: {
        map: this.countryMap,
        events: {
          render: function () {
            this.reflow()
          }
        }
      },
      title: {
        text: ""
      },
      subtitle: {
        text:
          ''
      },
      mapNavigation: {
        enabled: false,
        buttonOptions: {
          alignTo: "spacingBox"
        }
      },
      legend: {
        enabled: true
      },
      tooltip: {
        formatter: function(d) {
          const percentage =(d.chart.hoverPoint.value/this.accumulatedValue)*100;
          const formatedPercentage =  Intl.NumberFormat("de-DE").format(parseFloat(percentage.toFixed(2)));
          return '<span style="color:' + d.chart.hoverPoint.color + '">\u25CF</span></br>' 
            + d.chart.hoverPoint.name + ': ' + '<b>' + Intl.NumberFormat("de-DE").format(d.chart.hoverPoint.value) + '</b>' 
            + ' (' + formatedPercentage + '%)';
        }.bind(this)
      },
      credits: {
        enabled: false
      },
      series: [
        {
          type: "map",
          name: "",
          states: {
            hover: {
              color: COLORS.darkBlue
            }
          },
          dataLabels: {
            enabled: true,
            format: "{point.name}"
          },
          allAreas: false,
          data: this.mapSeries
        }
      ]
    };

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-churn-rate-per-crop-colors-bar',
  templateUrl: './churn-rate-per-crop-colors-bar.component.html',
  styleUrls: ['./churn-rate-per-crop-colors-bar.component.scss']
})
export class ChurnRatePerCropColorsBarComponent implements OnInit {

  pastMonth: string = "Julio 2019";
  actualMonth: string = "Julio 2020";

  items = [
    {
      color: '#b01818',
      percentage: 20,
    },
    {
      color: '#e31f1f',
      percentage: 30,
    },
    {
      color: '#ff2828',
      percentage: 10,
    },
    {
      color: '#ff4848',
      percentage: 40,
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { FileHandle } from 'src/app/shared/models/file-handle';

@Component({
  selector: 'app-document-management-file-manager',
  templateUrl: './document-management-file-manager.component.html',
  styleUrls: ['./document-management-file-manager.component.scss']
})
export class DocumentManagementFileManagerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  filesDropped(files: FileHandle[]): void {
    console.log(files);
  }

}

<header class="document-management-header d-flex flex-column w-100 justify-content-between align-items-start px-3 py-4">
    <div class="d-flex w-100 justify-content-between align-items-center">
        <a [routerLink]="['/portal']" class="document-management-logo-container d-flex align-items-center">
            <img class="z-logo" src={{zLogoSrc}}>
            <img class="santander-logo" src={{santanderLogoSrc}}>
        </a>
        <div class="document-management-header-links">
            <ng-container *ngFor="let menuLink of menuLinks">
                <a class="document-management-header-link text-dark text-decoration-none cursor-pointer px-4" [routerLink]="menuLink.link" routerLinkActive="active-link" *ngIf="menuLink.visible">
                    {{ menuLink.name | translate | uppercase }}
                </a>
            </ng-container>
        </div>
    </div>
    <div class="px-6 py-3">
        <h2 class="document-management-header-title mb-0">{{ 'documentManagementSection.documentManagement' | translate }}</h2>
    </div>
</header>
<div class="small_kpi">
    <div class="d-block small_kpi--title-block" *ngIf="title" >
        <span class="text-light mr-1 title">{{ title | translate }}</span>
    </div>
    <div class="small_kpi-main" *ngIf="(mainValue != null && (trendValue === 1 || trendValue === 0))">
        <span *ngIf="mainValue != null && mainValue != '' && addMagnitudeOrder" class="small_kpi-first">{{mainValue | numberFormat | magnitudeOrder}}{{mainValueUnit}}</span>
        <span *ngIf="mainValue != null && mainValue != '' && !addMagnitudeOrder" class="small_kpi-first">{{ mainValue }}{{ mainValueUnit }}</span>
        <span *ngIf="mainValue == null || mainValue == ''" class="small_kpi-first"></span>
        <span class="small_kpi-second">{{secondaryValue}}</span>
        <span 
            class="material-icons" 
            [ngClass]="{'small_kpi-arrow-green': trendValue > 0, 'small_kpi-arrow-red': trendValue === 0, 'small_kpi-up': trendDirection === 1, 'small_kpi-down': trendDirection === 0}"
            *ngIf="hasPercentage">
            play_arrow
            </span>
        <span *ngIf="absPercentageValue != null && hasPercentage"
            class="small_kpi-third" 
            [ngClass]="{'small_kpi-third-green': trendValue > 0, 'small_kpi-third-red': trendValue === 0}"><span *ngIf="percentagePrevious">{{percentagePrevious | translate}} </span>{{absPercentageValue | magnitudeOrder}}%</span>
    </div>    
</div>

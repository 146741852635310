import { Component, OnInit, OnDestroy } from '@angular/core';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'
import { QualityIndicator } from 'src/app/shared/models/quality-indicator';
import { TranslateService } from '@ngx-translate/core';
import { viewClassName } from '@angular/compiler';
import { environment } from 'src/environments/environment';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import _ from 'lodash';

@Component({
  selector: 'app-cancellation-quality-block',
  templateUrl: './cancellation-quality-block.component.html',
  styleUrls: ['./cancellation-quality-block.component.scss']
})
export class CancellationQualityBlockComponent implements OnInit, OnDestroy {

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;
  monthsNames: any;
  daysName: string;
  year: string;
  month: string;
  cancellationPolicyName: string;
  portfolioDurationName: string;

  qualityCanceledPolicyDurationIndicatorTitle: string;
  qualityPortolioProyectedDurationIndicatorTitle: string;

  qualityEarlyFallIndicator: QualityIndicator;
  qualityCanceledPolicyDurationIndicator: QualityIndicator;
  qualityPortolioProyectedDurationIndicator: QualityIndicator;

  qualityEarlyFallIndicatorLoaded: boolean = false;
  qualityCanceledPolicyDurationIndicatorLoaded: boolean = false;
  qualityPortolioProyectedDurationIndicatorLoaded: boolean = false;
  
  percentagePrevious: string = "labels.pm";

  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "cancellation-quality--cancellation-quality-block";

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private translateService: TranslateService,
    private i18n: I18nService,
    private atlasHelper: AtlasHelperService
  ) { }

  ngOnInit(): void {
    this.subscribeToActiveLanguage();

    this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        if (data.length !== 0) {
          this.getTranslations();

          this.filtersValues = data;
          this.year = this.filtersValues.filter(v => v.name === 'year')[0].value;
          this.month = this.filtersValues.filter(v => v.name === 'month')[0].value;
          this.qualityEarlyFallIndicatorLoaded = false;
          this.qualityCanceledPolicyDurationIndicatorLoaded = false;
          this.qualityPortolioProyectedDurationIndicatorLoaded = false;

          // Premium - Discreet calls
          this.retrieveQualityIndicator('cef', data); // Quality early fall
          this.retrieveQualityIndicator('cad', data); // Quality policy cancellations average duration
          this.retrieveQualityIndicator('cap', data); // Quality portfolio proyected average duration
        }
      }
    );
  }

  getTranslations(): void {
    const months = this.translateService.get('abbreviatedMonthNames');
    const cancellationPolicy = this.translateService.get('panels.averageDurationCancellationPolicy');
    const portfolioDuration = this.translateService.get('panels.averageProjectedPortfolioDuration');
    const days = this.translateService.get('labels.days');

    forkJoin([months, cancellationPolicy, portfolioDuration, days])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(results => {
      this.monthsNames = results[0];
      this.cancellationPolicyName = results[1];
      this.portfolioDurationName = results[2];
      this.daysName = results[3];
    });
  }

  // Method to retrieve the quality indicator data
  private retrieveQualityIndicator(quality_indicator: string, filtersValues: any) {
    this.apimstService.getCancellationQualityIndicator(quality_indicator, filtersValues)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (data: QualityIndicator) => {
        switch(quality_indicator) {
          case 'cef':  // Cancellation early fall
            this.qualityEarlyFallIndicator = data;
            this.qualityEarlyFallIndicatorLoaded = true;
            break;
          case 'cad':  // Cancellation average duration
            this.qualityCanceledPolicyDurationIndicator = data;
            this.qualityCanceledPolicyDurationIndicator.unit = this.daysName;
            this.qualityCanceledPolicyDurationIndicatorLoaded = true;
            break;
          case 'cap':  // Cancellation average portfolio
            this.qualityPortolioProyectedDurationIndicator = data;
            this.qualityPortolioProyectedDurationIndicator.unit = this.daysName;
            this.qualityPortolioProyectedDurationIndicatorLoaded = true;
            break;
          default:
            break;  
        }
        this.qualityCanceledPolicyDurationIndicatorTitle = this.cancellationPolicyName + ' ' + this.monthsNames[this.month] + ' ' + this.year;
        this.qualityPortolioProyectedDurationIndicatorTitle = this.portfolioDurationName + ' ' + this.year;
      }
    );
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

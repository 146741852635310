import { Component, OnInit, ViewEncapsulation, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CountryLoadEntity } from 'src/app/shared/models/country-load-entity';

@Component({
  selector: 'app-country-load-dialog',
  templateUrl: './country-load-dialog.component.html',
  styleUrls: ['./country-load-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CountryLoadDialogComponent implements OnInit {

  @Output() selectedNewCountry = new EventEmitter<any>();

  // Graph options
  sectionTitle: string;
  view: any[];
  countryLoadData: Array<CountryLoadEntity>;
  countryName: string;
  inactiveIcon: string = "outlined_flag";
  activeIcon: string = "flag";
  isActive: boolean = false;
  countries: any = [];
  
  // Default constructor
  constructor(
    @Inject(MAT_DIALOG_DATA) public data
  ) {
      this.sectionTitle = data.sectionTitle,
      this.view = data.view,
      this.countryLoadData = data.countryLoadData,
      this.countryName = data.countryName,
      this.countries = data.countries
   }

  // OnInit 
  ngOnInit(): void {
  }

  selectCountry(event) {
    this.data.countryLoadData = undefined;
    this.selectedNewCountry.emit(event.id);
  }

}

import { Component, OnInit } from '@angular/core';

import { MetadataBase } from '../../shared/models/metadata-base';

import { TextboxMetadata } from '../../shared/classes/metadata-textbox';
import { DropdownMetadata } from '../../shared/classes/metadata-dropdown';

@Component({
  selector: 'app-document-management-metadata-editor',
  templateUrl: './document-management-metadata-editor.component.html',
  styleUrls: ['./document-management-metadata-editor.component.scss']
})
export class DocumentManagementMetadataEditorComponent implements OnInit {

  metadata: MetadataBase<string>[] = [

    new TextboxMetadata({
      key: 'name',
      label: 'Nombre',
      value: 'Name',
      type: 'text',
      required: true,
      order: 1
    }),

    new TextboxMetadata({
      key: 'description',
      label: 'Description',
      value: "Description",
      type: 'text',
      required: true,
      order: 2
    }),

    new DropdownMetadata({
      key: 'documentType',
      label: 'Tipo de documento',
      value: "pdf",
      options: [
        {key: 'pdf',  value: 'PDF'},
        {key: 'xls',  value: 'XLS'}
      ],
      order: 3
    }),

  ];

  constructor() { }

  ngOnInit(): void {
  }

}

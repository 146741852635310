import { Component, OnInit } from '@angular/core';
import { version } from '../../../../../package.json';
import { releaseDate } from '../../../../../package.json';

@Component({
  selector: 'app-version-container',
  templateUrl: './app-version-container.component.html',
  styleUrls: ['./app-version-container.component.scss']
})
export class AppVersionContainerComponent implements OnInit {

  version: string = version;
  releaseDate: string = releaseDate;

  constructor() { }

  ngOnInit(): void { }

}

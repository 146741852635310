import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COLORS } from 'src/app/shared/globals/globals';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-loss-ratio-line-graph',
  templateUrl: './loss-ratio-line-graph.component.html',
  styleUrls: ['./loss-ratio-line-graph.component.scss']
})
export class LossRatioLineGraphComponent implements OnInit {

  loaded: boolean = false;
  
  results: any[] = [];

  unsubscribe$: Subject<any> = new Subject<any>();

  lossRatioName: string = "";
  abbreviatedMonthNames: any[];

  filtersValues: any[] = [];

  graphInfo: any[] = [];

  // options
  view: any[] = [570, 220];
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = false;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  showGridLines: boolean = true;
  showRefLines: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  timeline: boolean = false;
  tooltipDisabled: boolean = false;
  rotateXAxisTicks: boolean = false;
  colorScheme = {
    domain: [COLORS.lightGreen, COLORS.lightGreen]
  };

  constructor(
    private translateService: TranslateService,
    private api: ApimstService,
    private filtersService: FiltersService
  ) { }

  ngOnInit(): void {
    this.loaded = false;

    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const lossRatio = this.translateService.get('panels.lossRatio');
          const abbreviatedMonths = this.translateService.get('abbreviatedMonthNames');
          const translations = [lossRatio, abbreviatedMonths];
      
          forkJoin(translations)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              {
                next: results => {
                  this.lossRatioName = results[0];
                  this.abbreviatedMonthNames = results[1];
                },
                complete: () => {
                  if (data.length !== 0) {
                    this.filtersValues = data;
                  
                    this.resetValues();
                    this.retrieveLossRatioGraphInfo("lre", this.filtersValues);
                  }
                }
              }
            );
        });
  }

  // Method to formath the yAxis ticks
  yAxisTickFormatting(val) {
    return val.toLocaleString() + "%";
  }

  retrieveLossRatioGraphInfo(type_report: string = "", filtersValues: any[] = []) {
    this.loaded = false;

    this.api.getSinistersLossRatio(type_report, filtersValues)
      .subscribe({
        next: data => {
          this.createGraph(data);
          this.loaded = true;
        }
      })
  }

  createGraph(graphInfo: any[] = []) {
    let graphSeries: any[] = [];

    for(let serie of graphInfo) {
      graphSeries.push({
        name: serie.month && serie.year ? this.createTickName(serie.month, serie.year) : "",
        value: serie.value ? parseFloat(serie.value) : 0,
      })
    }
    this.graphInfo.push({
      name: this.lossRatioName,
      series: graphSeries
    });
  }

  createTickName(month: string = "", year: string = "") {
    let name: string = `${this.abbreviatedMonthNames[month]}${year.slice(2,4)}`;
    return name;
  }

  resetValues() {
    this.graphInfo = [];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

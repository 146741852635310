import { Component, OnInit } from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { CurrencyService } from 'src/app/shared/services/currency/currency.service';

@Component({
  selector: 'renewal-funnel',
  templateUrl: './renewal-funnel.component.html',
  styleUrls: ['./renewal-funnel.component.scss']
})
export class RenewalFunnelComponent implements OnInit {

  // Loader
  loaded: boolean;

  // Items to retrieve data
  items: any = [];
  itemsBonus: any[] = [];
  itemsPolicy: any[] = [];

  cancellationRenovationSusceptible: any = {};
  cancellationRenovated: any = {};

  cancellationRenovationSusceptibleBonus: any = [];
  cancellationRenovationSusceptiblePolicy: any = []
  cancellationRenovatedBonus: any = [];
  cancellationRenovatedPolicy: any = [];

  percentagePrevious: string = "labels.pm";

  // Option tabs
  actualOption: string = 'panels.bonus';
  optionTabBonus: string = 'panels.bonus';
  optionTabPolicy: string = 'panels.policy';
  optionToFetch: string = 'p';
  graphName: string = 'renewalRateGraph';

  // Filters value
  filtersValues: any;

  // Subscription
  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();

  // Format numbers
  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  // Check number symbol
  isNegative: boolean = false;

  constructor(
    private apimstService: ApimstService,
    private eventsService: EventsService,
    private filtersService: FiltersService,
    private utilsService: UtilsService,
    private currencyService: CurrencyService
  ) { }

  ngOnInit(): void {

    this.subscription = this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data.length !== 0) {
            this.filtersValues = data;
            this.resetVariables();
            this.assignData();
          }
        }
      );

      this.subscription = this.eventsService.policiesBonusOption$.subscribe(
        data => {
          const option = data[0];
          const name = data[1];
          if ((option === this.optionTabBonus || option === this.optionTabPolicy) && name === this.graphName ) {
            this.actualOption = option;
            this.assignData();
          }
        }
      );
  }

  // Method for retrieving the data information for the funnel
  retrieveFunnelData(type_data, data) {

    // Cancellation renovation susceptible and cancellation renovated
    let cancellationRenovationSusceptible = this.apimstService.getChargeFunnelIndicators(type_data, 'crs', data);   // Cancellation renovation susceptible
    let cancellationRenovated = this.apimstService.getChargeFunnelIndicators(type_data, 'crv', data);      // Cancellation renovated

    // Show loader
    this.loaded = false;

    forkJoin([cancellationRenovationSusceptible, cancellationRenovated])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(results => {
        const cancellationRenovationSusceptibleResult = results[0];
        const cancellationRenovatedResult = results[1];

        // Currency symbol for the 'Bonus' values
        const currencySymbol = this.getCurrencySymbol();

        // Create items array
        this.cancellationRenovationSusceptible.title = "funnels.susceptibleRenewal";
        this.cancellationRenovationSusceptible.main_value = cancellationRenovationSusceptibleResult['value'] != null && cancellationRenovationSusceptibleResult['value'] != "" ? this.formatPipe.transform( parseFloat( cancellationRenovationSusceptibleResult['value'].replace(/,/g, '') ) ) + currencySymbol : '-1';
        this.cancellationRenovationSusceptible.second_value_prefix = this.percentagePrevious;
        this.cancellationRenovationSusceptible.second_value = cancellationRenovationSusceptibleResult['diff'] != null ? this.formatPipe.transform( parseFloat( cancellationRenovationSusceptibleResult['diff'].replace(/-|,/g, '')) ) + "%" : '0';
        this.cancellationRenovationSusceptible.trend = cancellationRenovationSusceptibleResult['diff_trend'] != null ? parseInt(cancellationRenovationSusceptibleResult['diff_trend']) : 0;
        this.cancellationRenovationSusceptible.trend_direction = this.utilsService.isNegative( cancellationRenovationSusceptibleResult['diff'] ) == false ? 1 : 0;

        this.cancellationRenovated.title = "funnels.renewal";
        this.cancellationRenovated.main_value = cancellationRenovatedResult['value'] != null && cancellationRenovatedResult['value'] != "" ? this.formatPipe.transform( parseFloat( cancellationRenovatedResult['value'].replace(/,/g, '') ) ) + currencySymbol : '-1';
        this.cancellationRenovated.second_value_prefix = this.percentagePrevious;
        this.cancellationRenovated.second_value = cancellationRenovatedResult['diff'] != null ? this.formatPipe.transform( parseFloat( cancellationRenovatedResult['diff'].replace(/-|,/g, '')) ) + "%" : '0';
        this.cancellationRenovated.trend = cancellationRenovatedResult['diff_trend'] != null ? parseInt(cancellationRenovatedResult['diff_trend']) : 0;
        this.cancellationRenovated.trend_direction = this.utilsService.isNegative( cancellationRenovatedResult['diff'] ) == false ? 1 : 0;

        const itemData: any = [];
        itemData.push(this.cancellationRenovationSusceptible);
        itemData.push(this.cancellationRenovated);

        if (this.actualOption === this.optionTabBonus) {
          this.itemsBonus = JSON.parse(JSON.stringify(itemData));
        } else if (this.actualOption === this.optionTabPolicy) {
          this.itemsPolicy = JSON.parse(JSON.stringify(itemData));
        }

        this.assignDataToPrint();

      });

    }

    // Method to add currency symbol if 'Bonus' selected
    private getCurrencySymbol() {
      let currencySymbol: string = '';

      if (this.actualOption === this.optionTabBonus) {
        this.currencyService.currencyInfo$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(currencyInfo =>
            currencySymbol = currencyInfo.symbol
          );
      }
      return currencySymbol;
    }

  // Method to reset the data storage variables
  private resetVariables() {
    this.itemsBonus = [];
    this.itemsPolicy = [];
  }

  // Method to assign the data to print
  private assignDataToPrint() {
    if (this.actualOption === this.optionTabBonus) {
      this.items = this.itemsBonus;
      this.loaded = true;
    } else if (this.actualOption === this.optionTabPolicy) {
      this.items = this.itemsPolicy;
      this.loaded = true;
    }
  }

  // Method to assing data
  private assignData() {
    if (this.actualOption === this.optionTabBonus && this.itemsBonus.length === 0) {
      this.retrieveFunnelData('p', this.filtersValues);
    } else if (this.actualOption === this.optionTabPolicy && this.itemsPolicy.length === 0) {
      this.retrieveFunnelData('c', this.filtersValues);
    } else {
      this.assignDataToPrint();
    }
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

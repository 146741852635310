import { Component, OnInit } from '@angular/core';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { response } from 'src/app/sinisters/sinisters-quality/closed-per-month-of-complaint/sinisters-closed-per-month-table/data';
import { environment } from 'src/environments/environment';
import { ClientsVocClaimsService } from './clients-voc-claims.service';

@Component({
  selector: 'app-clients-voc-claims',
  templateUrl: './clients-voc-claims.component.html',
  styleUrls: ['./clients-voc-claims.component.scss']
})
export class ClientsVocClaimsComponent implements OnInit {

  claimReasonsLoaded: boolean = false;

  optionsPanelLoading: boolean = false;

  filtersValues: any[] = [];

  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "clients-voc--claims";

  unsubscribe$: Subject<any> = new Subject<any>();

  graphName: string = 'clientsClaims';
  panelOptions: any[] = [];

  clientsClaimsItems: any[] = [];
  selectedClientsClaimsItems: any[] = [];
  selectedClientsClaimsItemsLength: number = 0;

  selectorTitle: string = "";
  clientsClaimReasons: any[] = [];
  clientsDropdownId: string = "clientsQualityClaims";

  clientsDropdownTitle: string = "";
  itemsClientsClaims: any[] = [
    { id: "Asistencias", type: "clientClaim", name: "Asistencias", selected: true },
    { id: "Cancelación", type: "clientClaim", name: "Cancelación", selected: false },
    { id: "Problemas con la Información", type: "clientClaim", name: "Problemas con la Información", selected: false },
    { id: "Renovación", type: "clientClaim", name: "Renovación", selected: false },
    { id: "Siniestros", type: "clientClaim", name: "Siniestros", selected: false },
    { id: "Ventas", type: "clientClaim", name: "Ventas", selected: false },
  ]

  claim_subreason: any = {};

  constructor(
    private i18n: I18nService,
    private atlasHelper: AtlasHelperService,
    private clientsVocClaimsService: ClientsVocClaimsService,
    private eventsService: EventsService,
    private apimstService: ApimstService,
    private filtersService: FiltersService
  ) { }

  ngOnInit(): void {
    this.subscribeToActiveLanguage();
    this.subscribeToFiltersValues();
  }

  subscribeToFiltersValues() {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: filtersValues => {
          this.claimReasonsLoaded = false;

          this.apimstService.getClientsQualityClaims("ccpe", filtersValues)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: data => {
                this.claimReasonsLoaded = true;
                this.resetValues();
                data = this.getClaimReasonsMaster(data);                
                this.itemsClientsClaims = this.getClaimReasonsFormatted(data);
                if (this.itemsClientsClaims.length > 0) {
                  this.setSelectedReason(this.itemsClientsClaims[0].id);
                }
              }
            })
        }
      })
  }

  resetValues() {
    this.itemsClientsClaims = [];
  }

  getClaimReasonsMaster(data: any[] = []) {
    let array : any[];
    if(data == null ) {
      return [...new Set(array)];
    }
    array = data
      .filter(item => item.name != "No asignado" && item.name != "No aplica")
      .map(item => item.name)
    return [...new Set(array)];
  }

  getClaimReasonsFormatted(data: any[] = []) {
    return data.map(
      item => ({
        id: item,
        type: "clientClaim",
        name: item,
        selected: false
      })
    )
  }

  getSelectionIds(selectedItems: any[] = []) {
    return selectedItems.map(item => item.item_id)
  }

  updateSelectedItems(option) {
    let selectedClientsClaimsItems = this.selectedClientsClaimsItems;
    switch (option.item.form_action) {
      case 'selectAll':
        selectedClientsClaimsItems = option.items;
        break;
      case 'select':
        if (selectedClientsClaimsItems.filter(n => n.item_id == option.item.item_id).length == 0) {
          selectedClientsClaimsItems.push(option.item)
        }
        break;
      case 'deselect':
        selectedClientsClaimsItems = selectedClientsClaimsItems.filter(n => n.item_id != option.item.item_id);
        break;
      case 'deselectAll':
        selectedClientsClaimsItems = [];
        break;
    }
    return selectedClientsClaimsItems;
  }

  setSelectedReason(option: string = "") {
    this.clientsVocClaimsService.setSelectedReason(option);
  }

  setDefaultSwitcherOptions(): void {
    this.graphName = this.clientsVocClaimsService.getGraphName();
    this.panelOptions = this.clientsVocClaimsService.activateFirstOption();
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: language => {
          this.activeLang = language;
          this.updateAtlasInfoParams(this.pageId, this.activeLang);
        }
      })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

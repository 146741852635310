
<div class="header d-flex justify-content-between align-items-center">
  <span class="text-white h5 mb-0">{{ sectionTitle | translate }}</span>
  <span class="icon material-icons d-inline-block text-light" (click)="cleanList()">cleaning_services</span>   
</div>

<div class="dialog-button-container mt-3">
  <ul class="items-list mb-0">
    <li class="item text-light" *ngFor="let error of errors">
        <span class="item-block">
            <span class="icon material-icons d-inline-block mr-2">error_outline</span>   
            <span class="item-text">{{ error.status }}</span>   
        </span>
        <span class="item-block">
            <span class="icon material-icons d-inline-block mr-2">subject</span>   
            <span class="item-text">{{ error.statusText }}</span>   
        </span>
        <span class="item-block">
            <span class="icon material-icons d-inline-block mr-2">content_paste</span>   
            <span class="item-text">{{ error.url }}</span>   
        </span>
    </li>
  </ul>
</div>


<!-- status: 404
statusText: "Resource Not Found"
url: "https://sikapi-apim.azure-api.net/af-sikapiapp/sales/daily_" -->
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-icon-kpi',
  templateUrl: './icon-kpi.component.html',
  styleUrls: ['./icon-kpi.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IconKpiComponent implements OnInit {
  @Input() title: string;
  @Input() mainValue;
  @Input() mainValueUnit: string;
  @Input() secondaryValue: string;
  @Input() percentage: string;
  @Input() percentagePrevious: string;
  @Input() trend: number;
  @Input() hasPercentage: boolean = true;
  @Input() iconRoute: string = "";
  @Input() iconWidth: string = "16";
  @Input() iconMarginRight: string = "17";
  @Input() hAlign: string = "center"; // "center" / "start" / "end"
  @Input() vAlign: string = "center"; // "center" / "start" / "end"

  constructor() { }

  ngOnInit(): void {
  }

  getIconStyles() {
    let styles;
    styles = {
      'width.px': this.iconWidth,
      'margin-right.px': this.iconMarginRight
    }
    return styles;
  }

}

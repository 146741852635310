<component-loader [width]="" [height]="65" *ngIf="!loaded"></component-loader>

<div class="d-flex" *ngIf="loaded">

    <velocimeter
        class="velocimeter-small"
        [viewBox]="viewBox"
        [strokeWidth]="strokeWidth"
        [target]="target"
        [currentValue]="currentValue"
        [mainValue]="qualityClaim?.value"
        [secondValue]="qualityClaim?.target"
        [diff]="qualityClaim?.diff"
        [trend]="qualityClaim?.diff_trend"
        [hasInfo]="hasInfo"
        [hAlign]="hAlign"
        [vAlign]="vAlign"
        [barOrientation]="barOrientation"
        >
    </velocimeter>

    <app-small-kpi 
        class="indicator-widget-element velocimeter-kpi d-inline-block ml-4" 
        [title]=qualitySinisterIndicator.title 
        [mainValue]=qualitySinisterIndicator.main_value 
        [mainValueUnit]=qualitySinisterIndicator.main_value_unit 
        [trend]=qualitySinisterIndicator.trend
        [percentagePrevious]=qualitySinisterIndicator.percentage_previous 
        [percentage]=qualitySinisterIndicator.percentage 
    ></app-small-kpi>
    
</div>


import { Component, Input, OnInit } from '@angular/core';
import { EventsService } from '../../services/events/events.service';

@Component({
  selector: 'app-search-option-tab',
  templateUrl: './search-option-tab.component.html',
  styleUrls: ['./search-option-tab.component.scss']
})
export class SearchOptionTabComponent implements OnInit {

  @Input() options: any[];
  @Input() sectionName: string;

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
  }

  selectOption(option: any, options: any) {

    // Add/remove selected class to elements
    for(let option of options) {
      option.selected = false;
    }
    option.selected = true;
    option.sectionName = this.sectionName;
    // Emit selected option value
    this.eventsService.searchOptionSelected$.emit(option);

  }

}

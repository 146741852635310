<div class="home-main-block-container">
    <div class="title-block">
        <span class="dg-home-main-block-title">{{ 'panels.portal' | translate }}<br>{{ 'dataGovernanceSection.dataGovernance' | translate }}</span>   
    </div>
    <div class="subtitle-links d-flex justify-content-between">
        <span>{{ selectedArea | translate}}</span>
        <div class="d-flex area-link" (click)="changeArea()">
            <span class="">{{ notSelectedArea | translate}}</span>
            <mat-icon class="open-in-new-icon ml-2 mt-1">open_in_new</mat-icon>
        </div>
    </div>
    <div *ngIf="selectedArea === businessArea" class="grid-layout mt-5">
        <app-portal-widget-section *ngFor="let category of orderedCategories" [categoryInformation]="category"></app-portal-widget-section>
    </div>
    <div *ngIf="selectedArea === technicalArea" class="technical-grid-layout mt-5">
        <app-portal-widget-technical-section *ngFor="let section of technicalSections" [sectionInformation]="section" ></app-portal-widget-technical-section>
    </div>
</div>
<component-loader [width]="" [height]="65" *ngIf="!loaded"></component-loader>

<div class="d-flex justify-content-center" *ngIf="loaded">
    <div class="legends-container legends-container-small d-flex">
        <app-legend
            [name]="legend.name" 
            [colorCode]="legend.color_code" 
            [type]="legend.type" 
            [gradient]="legend.gradient" 
            *ngFor="let legend of legends">
        </app-legend>
    </div>
</div>
<div class="d-flex justify-content-center align-items-center mt-3" *ngIf="loaded">
    <div class="mr-3">
        <app-semicircle-pie
            class="semicircle-pie-medium"
            [items]="tnpsItems"
            [kpiInfo]="tnpsKpi"
            [width]="120">
        </app-semicircle-pie>
    </div>
    <div class="d-flex flex-column">
        <app-velocimeter-info
            class="velocimeter-info-medium"
            [infoTitle]="infoTitle"
            [infoActualValue]="responseRate?.surveys_answered"
            [infoTotalValue]="responseRate?.surveys_sent"
            [infoPercentage]="responseRate?.response_rate">
        </app-velocimeter-info>
    </div>
</div>
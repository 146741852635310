import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject, forkJoin, BehaviorSubject } from 'rxjs';
import { COLORS } from 'src/app/shared/globals/globals';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { NumberPipe } from 'src/app/shared/pipes/number.pipe';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators'
import { PortfolioGWP } from 'src/app/shared/models/portfolio-gwp';
import { trim } from 'lodash';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { CurrencyService } from 'src/app/shared/services/currency/currency.service';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { ExcelFileInfo } from 'src/app/shared/models/excel-file-info';

@Component({
  selector: 'gwp-graph',
  templateUrl: './gwp-graph.component.html',
  styleUrls: ['./gwp-graph.component.scss']
})
export class GwpGraphComponent implements OnInit, OnDestroy {

  currencySymbol: string = "";
  percentagePrevious: string = "labels.pm"

  // Months to map
  monthNames = [];
  planName: string;
  titleLabel: string;

  month: string;
  year: string;

  leftValue: string;
  leftPercentage: string;
  trend: number;
  loaded: boolean = false;

  showGraphic: boolean = false;

  customColors: any = [];

  // Options for chart creation
  view = [1200, 160];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  legendTitle: string = '';
  legendPosition: string = '';
  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  yLeftAxisScaleFactor: string = '';
  yRightAxisScaleFactor: string = '';
  yRightAxisTickFormatting: string = '';
  showGridLines: boolean = true;
  innerPadding: string = '10%';
  animations: boolean = false;
  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  barPadding: number = 60;
  tooltipDisabled: boolean = false;
  schemeType: string = "ordinal";
  roundDomains: boolean = false;
  noBarWhenZero: boolean = true;
  barChart: any[] = [];
  lineChartSeries: any[] = [];
  lineChartScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.lightPurple, COLORS.darkYellow, COLORS.lightBlue, '#08ded1']
  };
  comboBarScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.lightBlue, COLORS.lightBlue, '#085da6', '#0869aa']
  };

  // Subscription
  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();

  data_total: any[] = [];
  data_sheet_names: any[] = [];
  data_filter_details: any[] = [];

  // Function to fetch portfolio cancellations data
  private retrieveGWP(filtersValues: any) {

    // Retrieve graph data
    this.apimstService.getGWP('g', filtersValues)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (data: PortfolioGWP) => {

        this.loaded = false;
        this.barChart = [];
        this.lineChartSeries = [];
        let targetData =  [];
        this.data_total = [];
        this.data_filter_details = data.filters;
        const headersToExport: any[] = [''];
        const gwpToExport: any[] = [this.titleLabel];
        const targetToExport: any[] = [this.planName];

        // Bars values
        for (let item of data.details) {

          const gwpValue = this.numberFormat.transform(item.gwp);
          let series = [{
            'name': 'GWP',
            'value': gwpValue
          }];

          const dateValue = this.monthNames[item.month] + item.year.substring(2,4);
          this.barChart.push(
            {
            'name': dateValue,
            'series': series
            }
          )

          const targetValue = (item.target || trim(item.target).length>0) ?  parseFloat(item.target.replace(/,/g, '')) : 0;
          targetData.push({
            'name': dateValue,
            'value': targetValue
          });

          headersToExport.push(dateValue);
          gwpToExport.push(gwpValue);
          targetToExport.push(targetValue);
        }

          // TODO Lines values
          this.lineChartSeries.push({
            'name': this.planName,
            'series': targetData
          });

        this.data_total.push(headersToExport, gwpToExport, targetToExport);
        this.data_total = [this.data_total];

        this.data_sheet_names = [this.titleLabel];
        let excelInfo: ExcelFileInfo = {json: this.data_total, headers: this.data_sheet_names, excelFileName: 'gwp_chart', filtersValues: this.data_filter_details, filters: true};
        this.events.gwpExcelInfoLoaded$.next(excelInfo);

        // Show graphic
        this.showGraphic = true;
        this.loaded = true;


      },
      error => console.log("An error ocurred while retrieving gwp graph data: " + error));

      this.apimstService.getGWP('gs', filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data: PortfolioGWP) => {
          this.leftValue = data.gwp;
          this.leftPercentage = data.gwp_diff;
          this.trend = parseInt(data.gwp_diff_trend);
        },

        error => console.log("An error ocurred while retrieven gwp summary data: " + error)
      );

  }

  // Default constructor
  constructor(
    public apimstService: ApimstService,
    public filtersService: FiltersService,
    public eventsService: EventsService,
    public numberFormat: NumberPipe,
    public translateService: TranslateService,
    private currencyService: CurrencyService,
    private excelService: ExcelService,
    private events: EventsService,
  ) { }

  // OnInit
  ngOnInit(): void {

    this.currencyService.currencyInfo$
    .subscribe(
      currencyInfo => {
        this.currencySymbol = currencyInfo.symbol;
      }
    )

    this.subscription = this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        const months = this.translateService.get('abbreviatedMonthNames');
        const plan = this.translateService.get('labels.plan');
        const title = this.translateService.get('labels.gwp');

        this.subscription = forkJoin([months, plan, title])
          .subscribe(
            {
              next: results => {
                this.monthNames = results[0];
                this.planName = results[1];
                this.titleLabel = results[2];
                this.assignColorBar();
              },
              complete: () => {
                if (data.length !== 0) {
                  this.loaded = false;
                  this.retrieveGWP(data);
                }
              }
            }
          );
      }
    );
  }

  // Method to assign the colors to the graph bars
  private assignColorBar() {
    this.customColors.push(
      {
        name: 'GWP',
        value: COLORS.lightBlue
      },
      {
        name: this.planName,
        value: COLORS.darkYellow
      })
  }

  // Method to format the right y axis labels
  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value);
  }

  // Method to export data to XSLX
  exportAsXLSX():void {
    this.data_sheet_names = [this.titleLabel];
    this.excelService.exportAsExcelFile(this.data_total, this.data_sheet_names, 'gwp_chart', this.data_filter_details);
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

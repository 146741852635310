export var rightRouting = [
    {
        name: 'details',
        value: 'sales/portfolio'
    },
    {
        name: 'portfolio',
        value: 'sales/mix-portfolio-gwp'
    },
    {
        name: 'mix-portfolio-gwp',
        value: 'sales/quality-complaints'
    }
]
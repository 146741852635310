<div class="mt-3">
    <div class="kpi-row">
        <component-loader [width]="140" [height]="50" *ngIf="!kpiLoaded"></component-loader>
        <app-small-kpi [mainValue]="kpiInfo.main_value" [mainValueUnit] = "kpiInfo.main_value_symbol" [percentagePrevious]="percentagePrevious" [percentage]="kpiInfo.percentage" [trend]="kpiInfo.trend" *ngIf="kpiLoaded"></app-small-kpi>
    </div>
    <div class="evolution-row d-flex justify-content-center mt-n2">
        <component-loader [width]="585" [height]="170" *ngIf="!evolutionLoaded"></component-loader>
        <div class="line-charts-gen wok-gen loss-ratio-evolution-graph only-border-bar-3 only-border-bar-4 mt-2" *ngIf="evolutionLoaded">
            <app-difference-bar-label [width] = "labelWidth" [viewBox] = "viewBox2" [labelDiff] = "targetDiff"></app-difference-bar-label>
            <app-difference-bar-label [width] = "labelWidth" [viewBox] = "viewBox1" [labelDiff] = "yearDiff"></app-difference-bar-label>
            <ngx-charts-bar-vertical
              [view]="view"
              [scheme]="colorScheme"
              [results]="graphDataToPrint"
              [gradient]="gradient"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="showLegend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              [roundDomains]="roundDomains"
              [showDataLabel]="showDataLabel"
              [barPadding]="barPadding"
              [customColors] = "customColors"
              [roundEdges] = "roundEdges"
              [rotateXAxisTicks] = "rotateXAxisTicks">
              <svg:g height=200 width=300>
                <line x1=0 x2=300 y1=40 y2=100 style="stroke:#ced4da; stroke-width:1"></line>
              </svg:g>
            </ngx-charts-bar-vertical>
            <div class="wok-x-axis"></div>
          </div>
    </div>
</div>

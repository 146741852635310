import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tile-button-group',
  templateUrl: './tile-button-group.component.html',
  styleUrls: ['./tile-button-group.component.scss']
})
export class TileButtonGroupComponent implements OnInit {
  @Input() items: any[];
  @Input() gap: number = 2;
  @Input() tileHeight: number = 222;
  @Output() itemClicked = new EventEmitter<any>();

  defaultBackgroundColor: string = "#ffffff";

  constructor() { }

  ngOnInit(): void {
    this.items = this.splitIntoGroups(this.items, 7);
  }

  splitIntoGroups(items: any[] = [], chunk_size: number = 0) {
    let index = 0;
    let arrayLength = items.length;
    let tempArray = [];
    for (index = 0; index < arrayLength; index += chunk_size) {
        let myChunk = items.slice(index, index+chunk_size);
        tempArray.push(myChunk);
    }
    return tempArray;
  }

  getContainerStyles() {
    return {
      'gap': `${this.gap}px ${this.gap}px`
    }
  }

  getTileAreaStyles() {
    return {
      'height.px': this.tileHeight
    }
  }

  getBgColorStyles(bgColor: string) {
    return {
      'background-color': bgColor != "" ? bgColor : this.defaultBackgroundColor
    }
  }

  isValidValue(value: string) {
    return typeof value != 'undefined' && value != "" && value;
  }

  handleItemClick(item: any) {
    this.itemClicked.emit(item)
  }

}

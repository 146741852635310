<div class="wrapper_grid">

    <div class="antiquity">
        <app-widget></app-widget>
    </div>
    <div class="quality">
        <app-widget></app-widget>
    </div>
    <div class="processes">
        <app-widget></app-widget>
    </div>
    
</div>

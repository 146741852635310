import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';

@Injectable({
  providedIn: 'root'
})
export class ClientsVocClaimsService {
  graphName: string = 'clientsClaims';
  panelOptions: any[] = [
    { id: "rejectionsCompany", name: "panels.company", selected: true, graphName: this.graphName },
    { id: "rejectionsRegulator", name: "panels.regulator", selected: false, graphName: this.graphName }
  ];
  panelSelectedOption$ = new BehaviorSubject<any>(this.panelOptions[0]);
  claimsReasonSelected$ = new BehaviorSubject<any>([]);

  claimReasonDefaultValue: string = "Asistencias";
  itemsClientsClaims: any[] = []

  dropdownOptions = [{
    item_id: "0",
    item_text: "Todas"
  }];

  constructor(
    private apimstService: ApimstService,
    private eventsService: EventsService
  ) {}

  getGraphName() {
    return this.graphName;
  }

  getSwitherOptions() {
    return this.panelOptions;
  }

  activateFirstOption() {
    for (let option of this.panelOptions) {option.selected = false;}
    this.panelOptions[0].selected = true;
    return this.panelOptions;
  }

  getDropdownDefaultOptions() {
    return this.dropdownOptions;
  }

  getClientsClaimsReasonsMaster() {
    return this.apimstService.getClientsClaimsReasonsMaster();
  }

  setInitialTypeSelected() {
    this.panelSelectedOption$.next(this.panelOptions[0]);
  }

  getClaimsReasons() {
    return this.itemsClientsClaims;
  }

  setSelectedReason(option: string = "") {
    // for (let item of this.itemsClientsClaims) item.selected = false;
    // this.itemsClientsClaims.filter( item => item.id == option )[0].selected = true;
    this.eventsService.clientsQualityClaims$.next(option);
  }

}

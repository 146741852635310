<app-widget>

    <div class="section-header-block d-flex align-items-center pb-2">
        <span class="title section-card-title">{{ 'panels.portfolio' | translate }}</span>
        <app-btn-gobernance-info 
          class="ml-2" 
          [endpoint]="endpoint" 
          [endpointParams]="endpointParams"
          *ngIf="hasGobernanceInfo">
        </app-btn-gobernance-info>
    </div>

    <div class="d-flex">
        <div class="pl-3">
            <app-portfolio-graph></app-portfolio-graph>
            <app-renewals-cancellations></app-renewals-cancellations>
        </div>
    </div>

</app-widget>

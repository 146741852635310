<app-wrapper>

    <div class="wrapper_grid">
        <div class="fixed-stock-churn-rate">
            <app-fixed-stock-churn-rate></app-fixed-stock-churn-rate>
        </div>
        <div class="fixed-stock-churn-rate-per-crop">
            <app-fixed-stock-churn-rate-per-crop></app-fixed-stock-churn-rate-per-crop>
        </div>        
        <div class="fixed-stock-indicators">
            <app-fixed-stock-indicators></app-fixed-stock-indicators>
        </div>
        <div class="fixed-stock-persistence">
            <app-fixed-stock-persistence></app-fixed-stock-persistence>
        </div>
    </div>

</app-wrapper>
<component-loader *ngIf="!loaded" [width]="580" [height]="220"></component-loader>

<div class="mt-2 d-flex justify-content-end align-items-center" *ngIf="loaded">
  <app-legend *ngFor="let label of reducedLabelsList; let i = index" class="mr-2" name={{reducedLabelsList[i]}} color={{colorListCSS[i]}} type="round" gradient="v-gradient"></app-legend>
  <app-legend class="mr-2" name="labels.target" color="light-blue" type="line" gradient="h-gradient"></app-legend>
  <app-legend name="labels.tax" color="dark-yellow" type="line" gradient="h-gradient"></app-legend>
  <!-- <mat-icon *ngIf="loaded" class="excel-icon ml-2" svgIcon="excel" (click)="exportAsXLSX()"></mat-icon> -->
</div>

<div class="combo-charts-d-axis-gen" *ngIf="loaded">
    <stacked-vertical-horizontal-graph
      [view]="view"
      [scheme]="comboBarScheme"
      [colorSchemeLine]="lineChartScheme"
      [customColors]="customColors"
      [results]="barChart"
      [animations]="animations"
      [lineChart]="lineChartSeries"
      [yAxisTickFormatting]="yAxisTickFormatting"
      [yLeftAxisScaleFactor]="yLeftAxisScaleFactor"
      [yRightAxisScaleFactor]="yRightAxisScaleFactor"
      [yRightAxisTickFormatting]="yRightAxisTickFormatting"
      [yAxisTickFormatting]="yAxisTickFormatting"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [rightYAxis]="showRightYAxis"
      [legend]="showLegend"
      [legendTitle]="legendTitle"
      [legendPosition]="legendPosition"
      [showGridLines]="showGridLines"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [showRightYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [barPadding]="barPadding"
      [tooltipDisabled]="tooltipDisabled"
      [schemeType]="schemeType"
      [roundDomains]="roundDomains"
      [noBarWhenZero]="noBarWhenZero"
      [showPercentageSymbolInTooltip]="showPercentageSymbolInTooltip">
    </stacked-vertical-horizontal-graph>
</div>
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { CarouselModule } from 'primeng/carousel';

import { HeaderComponent } from './shared/components/header/header.component';
import { WrapperComponent } from './shared/components/wrapper/wrapper.component';
import { WidgetComponent } from './shared/components/widget/widget.component';
import { GridSinisterOneComponent } from './grid-sinister-one/grid-sinister-one.component';
import { LogoComponent } from './shared/components/logo/logo.component';
import { HamburguerMenuComponent } from './shared/components/hamburguer-menu/hamburguer-menu.component';
import { ReferenceTitleComponent } from './shared/components/reference-title/reference-title.component';
import { FilterButtonComponent } from './shared/components/filter-button/filter-button.component';
import { FilterTagComponent } from './shared/components/filter-tag/filter-tag.component';
import { HugeKpiComponent } from './shared/components/huge-kpi/huge-kpi.component';
import { BigKpiComponent } from './shared/components/big-kpi/big-kpi.component';
import { RegularKpiComponent } from './shared/components/regular-kpi/regular-kpi.component';
import { SmallKpiComponent } from './shared/components/small-kpi/small-kpi.component';


import { SalesPageOneComponent } from './sales/sales-page-one/sales-page-one.component';
import { SalesComponent } from './sales/sales-page-one/sales/sales.component';
import { MonthlySaleGraphComponent } from './sales/sales-page-one/sales/monthly-sale-graph/monthly-sale-graph.component';
import { SalesVsPlanProjectionGraph } from './sales/sales-page-one/sales/sales-vs-plan-projection-graph/sales-vs-plan-projection-graph.component';
import { DailySaleGraphComponent } from './sales/sales-page-one/sales/daily-sale-graph/daily-sale-graph.component';
import { OptionTabComponent } from './shared/components/option-tab/option-tab.component';
import { StackedVerticalHorizontalGraphComponent } from './shared/components/stacked-vertical-horizontal-graph/stacked-vertical-horizontal-graph.component';
import { StackedVerticalBarComponent } from './shared/components/stacked-vertical-horizontal-graph/stacked-vertical-bar.component';
import { VerticalBarComponent } from './shared/components/vertical-horizontal-lines-bars-graph/vertical-bar.component';
import { KpisHeaderComponent } from './shared/components/kpis-header/kpis-header.component';
import { KpiHeaderSingleComponent } from './shared/components/kpi-header-single/kpi-header-single.component';
import { SideFiltersComponent } from './shared/components/side-filters/side-filters.component';
import { DropdownComponent } from './shared/components/dropdown/dropdown.component';
import { DropdownMultipleComponent } from './shared/components/dropdown-multiple/dropdown-multiple.component';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { DailySalesGraphDialogComponent } from './shared/dialogs/daily-sales-graph-dialog/daily-sales-graph-dialog.component';
import { MonthlySalesGraphDialogComponent } from './shared/dialogs/monthly-sales-graph-dialog/monthly-sales-graph-dialog.component';
import { MonthlySaleComponent } from './sales/sales-page-one/monthly-sale/monthly-sale.component';
import { SalesPortfolioComponent } from './sales/sales-portfolio/sales-portfolio.component';
import { LinearGaugeGraphComponent } from './shared/components/linear-gauge-graph/linear-gauge-graph.component';
import { CountryLoadDialogComponent } from './shared/dialogs/country-load-dialog/country-load-dialog.component';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslationComponent } from './shared/components/translation/translation.component';
import { HeaderSubmenuComponent } from './shared/components/header-submenu/header-submenu/header-submenu.component';
import { MonthlySalePieGraphComponent } from './sales/sales-page-one/monthly-sale/monthly-sale-pie-graph/monthly-sale-pie-graph.component';
import { MagnitudeOrderPipe } from './shared/pipes/magnitude-order.pipe';
import { MonthlySaleTableComponent } from './sales/sales-page-one/monthly-sale/monthly-sale-table/monthly-sale-table.component';
import { PortfolioIndicatorsComponent } from './sales/sales-portfolio/portfolio-indicators/portfolio-indicators.component';
import { IndicatorWidgetComponent } from './shared/components/indicator-widget/indicator-widget.component';
import { LegendComponent } from './shared/components/legend/legend/legend.component';
import { SalesQualityComplaintsComponent } from './sales/sales-quality-complaints/sales-quality-complaints.component';
import { QualityComponent } from './sales/sales-quality-complaints/quality/quality.component';
import { QualityInternalProcessComponent } from './sales/sales-quality-complaints/quality-internal-process/quality-internal-process.component';
import { QualityClaimsComponent } from './sales/sales-quality-complaints/quality-claims/quality-claims.component';
import { NpsSaleComponent } from './sales/sales-quality-complaints/nps-sale/nps-sale.component';
import { KpiColorComponent } from './shared/components/kpi-color/kpi-color.component';
import { CancellationDetailComponent } from './cancellation/cancellation-detail/cancellation-detail.component';
import { ChurnRateComponent } from './cancellation/cancellation-detail/churn-rate/churn-rate.component';
import { ChurnRatePerCropComponent } from './cancellation/cancellation-detail/churn-rate-per-crop/churn-rate-per-crop.component';
import { CancellationReasonsComponent } from './cancellation/cancellation-detail/cancellation-reasons/cancellation-reasons.component';
import { CancellationBranchChannelComponent } from './cancellation/cancellation-detail/cancellation-branch-channel/cancellation-branch-channel.component';
import { SalesMixPortfolioGwpComponent } from './sales/sales-mix-portfolio-gwp/sales-mix-portfolio-gwp.component';
import { PortfolioComponent } from './sales/sales-mix-portfolio-gwp/portfolio/portfolio.component';
import { GwpComponent } from './sales/sales-mix-portfolio-gwp/gwp/gwp.component';
import { VerticalHorizontalLinesBarsGraphComponent } from './shared/components/vertical-horizontal-lines-bars-graph/vertical-horizontal-lines-bars-graph.component';
import { PortfolioVsSalesMixComponent } from './sales/sales-portfolio/portfolio-vs-sales-mix/portfolio-vs-sales-mix.component';
import { StackedHorizontalNegativeGraphComponent } from './shared/components/stacked-horizontal-negative-graph/stacked-horizontal-negative-graph.component';
import { StackedHorizontalNegativeGraphBarsComponent } from './shared/components/stacked-horizontal-negative-graph/stacked-horizontal-negative-graph-bars.component';
import { RenewalsCancellationsComponent } from './sales/sales-mix-portfolio-gwp/portfolio/renewals-cancellations/renewals-cancellations.component';
import { GroupedVerticalHorizontalGraphDAxisComponent } from './shared/components/grouped-vertical-horizontal-graph-d-axis/grouped-vertical-horizontal-graph-d-axis.component';
import { QualityClaimsGraphicComponent } from './sales/sales-quality-complaints/quality-claims/quality-claims-graphic/quality-claims-graphic.component';
import { NpsSaleGraphicComponent } from './sales/sales-quality-complaints/nps-sale/nps-sale-graphic/nps-sale-graphic.component';
import { WokGraphComponent } from './shared/components/wok-graph/wok-graph.component';
import { WokGraphBarDifferenceComponent } from './shared/components/wok-graph/wok-graph-bar-difference.component';
import { WokGraphUpperLabelComponent } from './shared/components/wok-graph-upper-label/wok-graph-upper-label.component';
import { PortfolioGraphComponent } from './sales/sales-mix-portfolio-gwp/portfolio/portfolio-graph/portfolio-graph.component';
import { QualityClaimsVelocimeterComponent } from './sales/sales-quality-complaints/quality-claims/quality-claims-velocimeter/quality-claims-velocimeter.component';
import { VelocimeterComponent } from './shared/components/velocimeter/velocimeter.component';
import { NpsSaleVelocimeterComponent } from './sales/sales-quality-complaints/nps-sale/nps-sale-velocimeter/nps-sale-velocimeter.component';
import { GwpGraphComponent } from './sales/sales-mix-portfolio-gwp/gwp/gwp-graph/gwp-graph.component';
import { CancellationBranchChannelGraphicComponent } from './cancellation/cancellation-detail/cancellation-branch-channel/cancellation-branch-channel-graphic/cancellation-branch-channel-graphic.component';
import { ComponentLoaderComponent } from './shared/components/component-loader/component-loader.component';
import { ChurnRatePerCropGraphicComponent } from './cancellation/cancellation-detail/churn-rate-per-crop/churn-rate-per-crop-graphic/churn-rate-per-crop-graphic.component';
import { ChurnRatePerCropColorsBarComponent } from './cancellation/cancellation-detail/churn-rate-per-crop/churn-rate-per-crop-colors-bar/churn-rate-per-crop-colors-bar.component';
import { ColorBarComponent } from './shared/components/color-bar/color-bar.component';

import { MsalService, MSAL_INSTANCE, MsalGuard, MsalInterceptor, MsalBroadcastService } from './shared/external/msal';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from './shared/external/msal/constants';
import { MsalGuardConfiguration } from './shared/external/msal/msal.guard.config';
import { MsalInterceptorConfig } from './shared/external/msal/msal.interceptor.config';
import { CancellationChargeComponent } from './cancellation/cancellation-charge/cancellation-charge.component';
import { CancellationChargeBlockComponent } from './cancellation/cancellation-charge/cancellation-charge-block/cancellation-charge-block.component';
import { CancellationChargeActivityComponent } from './cancellation/cancellation-charge/cancellation-charge-activity/cancellation-charge-activity.component';
import { CancellationChargesComponent } from './cancellation/cancellation-charge/cancellation-charges/cancellation-charges.component';
import { CancellationChargeEffectivenessComponent } from './cancellation/cancellation-charge/cancellation-charge-effectiveness/cancellation-charge-effectiveness.component';

import { ChurnRatePlanGraphComponent } from './cancellation/cancellation-detail/churn-rate/churn-rate-plan-graph/churn-rate-plan-graph.component';
import { ChurnRateEvolutionGraphComponent } from './cancellation/cancellation-detail/churn-rate/churn-rate-evolution-graph/churn-rate-evolution-graph.component';
import { DifferenceBarLabelComponent } from './shared/components/difference-bar-label/difference-bar-label.component';
import { CancellationChargesPieChartComponent } from './cancellation/cancellation-charge/cancellation-charges/cancellation-charges-pie-chart/cancellation-charges-pie-chart.component';
import { CancellationReasonsGraphicComponent } from './cancellation/cancellation-detail/cancellation-reasons/cancellation-reasons-graphic/cancellation-reasons-graphic.component';
import { CancellationChargeActivityGraphicComponent } from './cancellation/cancellation-charge/cancellation-charge-activity/cancellation-charge-activity-graphic/cancellation-charge-activity-graphic.component';
import { NumberPipe } from './shared/pipes/number.pipe';
import { DecimalThousandPipe } from './shared/pipes/decimal-thousand.pipe';

import { environment } from './../environments/environment';
import { BaseLoaderComponent } from './shared/components/base-loader/base-loader.component';
import { CancellationChargesGraphicComponent } from './cancellation/cancellation-charge/cancellation-charges/cancellation-charges-graphic/cancellation-charges-graphic.component';
import { CancellationRetentionComponent } from './cancellation/cancellation-retention/cancellation-retention.component';
import { RetentionComponent } from './cancellation/cancellation-retention/retention/retention.component';
import { VoluntaryCancellationComponent } from './cancellation/cancellation-retention/voluntary-cancellation/voluntary-cancellation.component';
import { RetentionPerActionComponent } from './cancellation/cancellation-retention/retention-per-action/retention-per-action.component';
import { CancellationRenewalComponent } from './cancellation/cancellation-renewal/cancellation-renewal.component';
import { RenewalComponent } from './cancellation/cancellation-renewal/renewal/renewal.component';
import { RenewalEffectivenessComponent } from './cancellation/cancellation-renewal/renewal-effectiveness/renewal-effectiveness.component';
import { RenewalPerComponent } from './cancellation/cancellation-renewal/renewal-per/renewal-per.component';
import { EvolutionaryRenewalbyComponent } from './cancellation/cancellation-renewal/evolutionary-renewalby/evolutionary-renewalby.component';
import { CancellationPersistenceComponent } from './cancellation/cancellation-persistence/cancellation-persistence.component';
import { PersistenceTotalComponent } from './cancellation/cancellation-persistence/persistence-total/persistence-total.component';
import { PersistencePostretentionComponent } from './cancellation/cancellation-persistence/persistence-postretention/persistence-postretention.component';
import { PersistenceNewSaleComponent } from './cancellation/cancellation-persistence/persistence-new-sale/persistence-new-sale.component';
import { CancellationQualityComponent } from './cancellation/cancellation-quality/cancellation-quality.component';
import { CancellationQualityBlockComponent } from './cancellation/cancellation-quality/cancellation-quality-block/cancellation-quality-block.component';
import { CancellationQualityClaimsComponent } from './cancellation/cancellation-quality/cancellation-quality-claims/cancellation-quality-claims.component';
import { CancellationNpsComponent } from './cancellation/cancellation-quality/cancellation-nps/cancellation-nps.component';
import { PersistenceTotalGraphicComponent } from './cancellation/cancellation-persistence/persistence-total/persistence-total-graphic/persistence-total-graphic.component';
import { PersistenceNewSaleGraphicComponent } from './cancellation/cancellation-persistence/persistence-new-sale/persistence-new-sale-graphic/persistence-new-sale-graphic.component';
import { PersistencePostretentionGraphicComponent } from './cancellation/cancellation-persistence/persistence-postretention/persistence-postretention-graphic/persistence-postretention-graphic.component';
import { CancellationQualityClaimsVelocimeterComponent } from './cancellation/cancellation-quality/cancellation-quality-claims/cancellation-quality-claims-velocimeter/cancellation-quality-claims-velocimeter.component';
import { CancellationQualityClaimsGraphicComponent } from './cancellation/cancellation-quality/cancellation-quality-claims/cancellation-quality-claims-graphic/cancellation-quality-claims-graphic.component';
import { CancellationNpsGraphicComponent } from './cancellation/cancellation-quality/cancellation-nps/cancellation-nps-graphic/cancellation-nps-graphic.component';
import { CancellationNpsVelocimeterComponent } from './cancellation/cancellation-quality/cancellation-nps/cancellation-nps-velocimeter/cancellation-nps-velocimeter.component';
import { RenewalEffectivenessGraphicComponent } from './cancellation/cancellation-renewal/renewal-effectiveness/renewal-effectiveness-graphic/renewal-effectiveness-graphic.component';
import { RenewalPerPieChartComponent } from './cancellation/cancellation-renewal/renewal-per/renewal-per-pie-chart/renewal-per-pie-chart.component';
import { RenewalPerGraphicComponent } from './cancellation/cancellation-renewal/renewal-per/renewal-per-graphic/renewal-per-graphic.component';
import { VoluntaryCancellationGraphicComponent } from './cancellation/cancellation-retention/voluntary-cancellation/voluntary-cancellation-graphic/voluntary-cancellation-graphic.component';
import { EvolutionaryRenewalbyGraphicComponent } from './cancellation/cancellation-renewal/evolutionary-renewalby/evolutionary-renewalby-graphic/evolutionary-renewalby-graphic.component';
import { CancellationChargeEffectivenessGraphicComponent } from './cancellation/cancellation-charge/cancellation-charge-effectiveness/cancellation-charge-effectiveness-graphic/cancellation-charge-effectiveness-graphic.component';
import { RetentionPerActionGraphicComponent } from './cancellation/cancellation-retention/retention-per-action/retention-per-action-graphic/retention-per-action-graphic.component';
import { KpisFunnelComponent } from './shared/components/kpis-funnel/kpis-funnel.component';
import { RenewalFunnelComponent } from './cancellation/cancellation-renewal/renewal/renewal-funnel/renewal-funnel.component';
import { RenewalGraphComponent } from './cancellation/cancellation-renewal/renewal/renewal-graph/renewal-graph.component';
import { CancellationChargeBlockFunnelComponent } from './cancellation/cancellation-charge/cancellation-charge-block/cancellation-charge-block-funnel/cancellation-charge-block-funnel.component';
import { CancellationChargeBlockGraphComponent } from './cancellation/cancellation-charge/cancellation-charge-block/cancellation-charge-block-graph/cancellation-charge-block-graph.component';
import { RetentionFunnelComponent } from './cancellation/cancellation-retention/retention/retention-funnel/retention-funnel.component';
import { RetentionGraphicComponent } from './cancellation/cancellation-retention/retention/retention-graphic/retention-graphic.component';
import { OptionTabMultipleComponent } from './shared/components/option-tab-multiple/option-tab-multiple.component';
import { SlasSectionComponent } from './slas/slas-section/slas-section.component';
import { SlasMainComponent } from './slas/slas-section/slas-main/slas-main.component';
import { KpisGraphicContainerComponent } from './shared/components/kpis-graphic-container/kpis-graphic-container.component';
import { KpisGraphicCardComponent } from './shared/components/kpis-graphic-card/kpis-graphic-card.component';
import { PreHomeComponent } from './pre-home/pre-home.component';
import { BtnClientsComponent } from './pre-home/btn-clients/btn-clients.component';
import { BtnSaleComponent } from './pre-home/btn-sale/btn-sale.component';
import { BtnCancellationComponent } from './pre-home/btn-cancellation/btn-cancellation.component';
import { BtnSinisterComponent } from './pre-home/btn-sinister/btn-sinister.component';
import { BtnBackofficeComponent } from './pre-home/btn-backoffice/btn-backoffice.component';
import { KpiHomeComponent } from './shared/components/kpi-home/kpi-home.component';
import { BtnFilterComponent } from './shared/components/btn-filter/btn-filter.component';
import { CookieService } from 'ngx-cookie-service';
import { AppVersionContainerComponent } from './shared/components/app-version-container/app-version-container.component';
import { FancySelectorComponent } from './shared/components/fancy-selector/fancy-selector.component';
import { LogoutDialogComponent } from './shared/components/logout-dialog/logout-dialog.component';
import { MSTReponseInterceptor } from './shared/interceptors/mst-response.interceptor';
import { SessionExpiredDialogComponent } from './shared/components/session-expired-dialog/session-expired-dialog.component';
import { HomeComponent } from './home/home.component';
import { HomeHeaderComponent } from './shared/components/home-header/home-header.component';
import { HomeMainBlockComponent } from './shared/components/home-main-block/home-main-block.component';
import { UserSelectorComponent } from './shared/components/user-selector/user-selector.component';
import { PieChartDialogComponent } from './shared/components/pie-chart-dialog/pie-chart-dialog.component';
import { MessageBoxComponent } from './shared/components/message-box/message-box.component';
import { NotificationButtonComponent } from './shared/components/notification-button/notification-button.component';
import { ErrorsDialogComponent } from './shared/components/errors-dialog/errors-dialog.component';
import { PortfolioMixDialogComponent } from './shared/dialogs/portfolio-mix-dialog/portfolio-mix-dialog.component';
import { FilterListPipe } from './shared/pipes/filter-list.pipe';
import { ResetFiltersDialogComponent } from './shared/components/reset-filters-dialog/reset-filters-dialog.component';
import { TagComponent } from './shared/components/tag/tag.component';
import { SinistersDetailComponent } from './sinisters/sinisters-detail/sinisters-detail.component';
import { SinistersMainComponent } from './sinisters/sinisters-detail/sinisters-main/sinisters-main.component';
import { SinistersRejectionsComponent } from './sinisters/sinisters-rejections/sinisters-rejections.component';
import { RejectionsMainComponent } from './sinisters/sinisters-rejections/rejections-main/rejections-main.component';
import { RejectionsPendingAgeComponent } from './sinisters/sinisters-rejections/rejections-pending-age/rejections-pending-age.component';
import { RejectionsLossRatioComponent } from './sinisters/sinisters-rejections/rejections-loss-ratio/rejections-loss-ratio.component';
import { RejectionsTimeProcessesComponent } from './sinisters/sinisters-rejections/rejections-time-processes/rejections-time-processes.component';
import { SinistersQualityComponent } from './sinisters/sinisters-quality/sinisters-quality.component';
import { SinistersQualityMainComponent } from './sinisters/sinisters-quality/sinisters-quality-main/sinisters-quality-main.component';
import { SinistersClaimsPercentageComponent } from './sinisters/sinisters-quality/sinisters-claims-percentage/sinisters-claims-percentage.component';
import { SinistersClaimsComponent } from './sinisters/sinisters-quality/sinisters-claims/sinisters-claims.component';
import { SinistersPollsComponent } from './sinisters/sinisters-quality/sinisters-polls/sinisters-polls.component';
import { OrganizationSandwichComponent } from './shared/components/organization-sandwich/organization-sandwich.component';
import { TimeProcessesSandwichComponent } from './sinisters/sinisters-rejections/rejections-time-processes/time-processes-sandwich/time-processes-sandwich.component';
import { PagerComponent } from './shared/components/pager/pager.component';
import { LossRatioTableComponent } from './sinisters/sinisters-rejections/rejections-loss-ratio/loss-ratio-table/loss-ratio-table.component';
import { LossRatioLineGraphComponent } from './sinisters/sinisters-rejections/rejections-loss-ratio/loss-ratio-line-graph/loss-ratio-line-graph.component';
import { LossRatioEvolutionComponent } from './sinisters/sinisters-rejections/rejections-loss-ratio/loss-ratio-evolution/loss-ratio-evolution.component';
import { RejectionsKpiComponent } from './sinisters/sinisters-rejections/rejections-main/rejections-kpi/rejections-kpi.component';
import { RejectionsIndicatorsComponent } from './sinisters/sinisters-rejections/rejections-main/rejections-indicators/rejections-indicators.component';
import { RejectionsWalkComponent } from './sinisters/sinisters-rejections/rejections-main/rejections-walk/rejections-walk.component';
import { PendingAgeBlockComponent } from './sinisters/sinisters-rejections/rejections-pending-age/pending-age-block/pending-age-block.component';
import { TreeMapComponent } from './shared/components/tree-map/tree-map.component';
import { SinistersClaimsVelocimeterComponent } from './sinisters/sinisters-quality/sinisters-claims/sinisters-claims-velocimeter/sinisters-claims-velocimeter.component';
import { SinistersClaimsGraphicComponent } from './sinisters/sinisters-quality/sinisters-claims/sinisters-claims-graphic/sinisters-claims-graphic.component';
import { SinistersPollsGraphicComponent } from './sinisters/sinisters-quality/sinisters-polls/sinisters-polls-graphic/sinisters-polls-graphic.component';
import { SinistersPollsVelocimeterComponent } from './sinisters/sinisters-quality/sinisters-polls/sinisters-polls-velocimeter/sinisters-polls-velocimeter.component';
import { SinistersQualityVelocimeterComponent } from './sinisters/sinisters-quality/sinisters-quality-main/sinisters-quality-velocimeter/sinisters-quality-velocimeter.component';
import { SinistersQualityGraphicComponent } from './sinisters/sinisters-quality/sinisters-quality-main/sinisters-quality-graphic/sinisters-quality-graphic.component';
import { DoublePercentageKpiComponent } from './shared/components/double-percentage-kpi/double-percentage-kpi.component';
import { HorizontalFunnelGraphComponent } from './shared/components/horizontal-funnel-graph/horizontal-funnel-graph.component';
import { SemicirclePieComponent } from './shared/components/semicircle-pie/semicircle-pie.component';
import { VelocimeterInfoComponent } from './shared/components/velocimeter-info/velocimeter-info.component';
import { BtnGobernanceInfoComponent } from './shared/components/btn-gobernance-info/btn-gobernance-info.component';
import { InfoDialogComponent } from './shared/components/info-dialog/info-dialog.component';
import { TabGroupComponent } from './shared/components/tab-group/tab-group.component';
import { ColorLinkComponent } from './shared/components/color-link/color-link.component';
import { TileListComponent } from './shared/components/tile-list/tile-list.component';
import { TileListItemComponent } from './shared/components/tile-list/tile-list-item/tile-list-item.component';
import { PersistenceMonthlyComponent } from './cancellation/cancellation-persistence/persistence-monthly/persistence-monthly.component';
import { PersistenceMonthlyGraphicComponent } from './cancellation/cancellation-persistence/persistence-monthly/persistence-monthly-graphic/persistence-monthly-graphic.component';
import { SinistersMapComponent } from './sinisters/sinisters-map/sinisters-map.component';
import { MapComponent } from './sinisters/sinisters-map/map/map.component';
import { InfoHeaderComponent } from './shared/components/info-header/info-header.component';
import { FilterInfoDialogComponent } from './shared/components/filter-info-dialog/filter-info-dialog.component';
import { HtmlEditorComponent } from './shared/components/html-editor/html-editor.component';
import { DataGovernanceInfoPageComponent } from './data-governance/data-governance-info-page/data-governance-info-page.component';
import { DataGovernanceHomeComponent } from './data-governance/data-governance-home/data-governance-home.component';
import { PortalMainBlockComponent } from './data-governance/data-governance-home/portal-main-block/portal-main-block.component';
import { PortalWidgetSectionComponent } from './data-governance/data-governance-home/portal-widget-section/portal-widget-section.component';
import { SearcherComponent } from './shared/components/searcher/searcher.component';
import { SearchButtonComponent } from './shared/components/search-button/search-button.component';
import { SearchOptionTabComponent } from './shared/components/search-option-tab/search-option-tab.component';
import { ClosedPerMonthOfComplaintComponent } from './sinisters/sinisters-quality/closed-per-month-of-complaint/closed-per-month-of-complaint.component';
import { SinistersClosedPerMonthTableComponent } from './sinisters/sinisters-quality/closed-per-month-of-complaint/sinisters-closed-per-month-table/sinisters-closed-per-month-table.component';
import { CancellationFixedStockComponent } from './cancellation/cancellation-fixed-stock/cancellation-fixed-stock.component';
import { FixedStockChurnRateComponent } from './cancellation/cancellation-fixed-stock/fixed-stock-churn-rate/fixed-stock-churn-rate.component';
import { FixedStockChurnRatePerCropComponent } from './cancellation/cancellation-fixed-stock/fixed-stock-churn-rate-per-crop/fixed-stock-churn-rate-per-crop.component';
import { FixedStockPersistenceComponent } from './cancellation/cancellation-fixed-stock/fixed-stock-persistence/fixed-stock-persistence.component';
import { FixedStockIndicatorsComponent } from './cancellation/cancellation-fixed-stock/fixed-stock-indicators/fixed-stock-indicators.component';
import { FixedStockChurnRateGraphicsComponent } from './cancellation/cancellation-fixed-stock/fixed-stock-churn-rate/fixed-stock-churn-rate-graphics/fixed-stock-churn-rate-graphics.component';
import { FixedStockChurnRatePerCropGraphicComponent } from './cancellation/cancellation-fixed-stock/fixed-stock-churn-rate-per-crop/fixed-stock-churn-rate-per-crop-graphic/fixed-stock-churn-rate-per-crop-graphic.component';
import { FixedStockPersistenceGraphicComponent } from './cancellation/cancellation-fixed-stock/fixed-stock-persistence/fixed-stock-persistence-graphic/fixed-stock-persistence-graphic.component';
import { SinistersLossRatioComponent } from './sinisters/sinisters-loss-ratio/sinisters-loss-ratio.component';
import { OperationalLossRatioComponent } from './sinisters/sinisters-loss-ratio/operational-loss-ratio/operational-loss-ratio.component';
import { SinistersLossRatioInfoComponent } from './sinisters/sinisters-loss-ratio/sinisters-loss-ratio-info/sinisters-loss-ratio-info.component';
import { LossRatioInfoEvolutionComponent } from './sinisters/sinisters-loss-ratio/sinisters-loss-ratio-info/loss-ratio-info-evolution/loss-ratio-info-evolution.component';
import { LossRatioInfoLineGraphComponent } from './sinisters/sinisters-loss-ratio/sinisters-loss-ratio-info/loss-ratio-info-line-graph/loss-ratio-info-line-graph.component';
import { LossRatioInfoTableComponent } from './sinisters/sinisters-loss-ratio/sinisters-loss-ratio-info/loss-ratio-info-table/loss-ratio-info-table.component';
import { RejectionsFrequencyEvolutionComponent } from './sinisters/sinisters-rejections/rejections-frequency-evolution/rejections-frequency-evolution.component';
import { RejectionsFrequencyEvolutionGraphicComponent } from './sinisters/sinisters-rejections/rejections-frequency-evolution/rejections-frequency-evolution-graphic/rejections-frequency-evolution-graphic.component';
import { DataGovernanceBusinessComponent } from './data-governance/data-governance-business/data-governance-business.component';
import { DgHeaderComponent } from './shared/components/dg-header/dg-header.component';
import { BusinessUpperMenuComponent } from './data-governance/data-governance-business/business-upper-menu/business-upper-menu.component';
import { BusinessSideMenuComponent } from './data-governance/data-governance-business/business-side-menu/business-side-menu.component';
import { DgInfoCardComponent } from './shared/components/dg-info-card/dg-info-card.component';
import { BusinessMainInformationComponent } from './data-governance/data-governance-business/business-main-information/business-main-information.component';
import { LossRatioMapComponent } from './sinisters/sinisters-loss-ratio/sinisters-loss-ratio-info/loss-ratio-map/loss-ratio-map.component';
import { RejectionsIndicatorsMainComponent } from './sinisters/sinisters-rejections/rejections-main/rejections-indicators-main/rejections-indicators-main.component';
import { RejectionsLineGraphComponent } from './sinisters/sinisters-rejections/rejections-main/rejections-line-graph/rejections-line-graph.component';
import { RejectionsPieGraphsComponent } from './sinisters/sinisters-rejections/rejections-main/rejections-pie-graphs/rejections-pie-graphs.component';
import { InfoPanelComponent } from './shared/components/info-panel/info-panel.component';
import { InfoPanelTabsComponent } from './shared/components/info-panel/info-panel-tabs/info-panel-tabs.component';
import { InfoPanelListComponent } from './shared/components/info-panel/info-panel-list/info-panel-list.component';
import { InfoPanelListItemComponent } from './shared/components/info-panel/info-panel-list/info-panel-list-item/info-panel-list-item.component';
import { TechnicalSearchBarComponent } from './shared/components/technical-search-bar/technical-search-bar.component';
import { ColorLineTagComponent } from './shared/components/color-line-tag/color-line-tag.component';
import { InputBoxComponent } from './shared/components/input-box/input-box.component';
import { TechnicalSearchBarResultsComponent } from './shared/components/technical-search-bar/technical-search-bar-results/technical-search-bar-results.component';
import { TechnicalSearchBarResultComponent } from './shared/components/technical-search-bar/technical-search-bar-results/technical-search-bar-result/technical-search-bar-result.component';
import { BreadcrumbsComponent } from './shared/components/breadcrumbs/breadcrumbs.component';
import { PortalWidgetTechnicalSectionComponent } from './data-governance/data-governance-home/portal-widget-technical-section/portal-widget-technical-section.component';
import { NetworkGraphComponent } from './shared/components/network-graph/network-graph.component';
import { DataGovernanceTechnicalComponent } from './data-governance/data-governance-technical/data-governance-technical.component';
import { ClientsPortfolioComponent } from './clients/clients-portfolio/clients-portfolio.component';
import { ClientsPortfolioMainComponent } from './clients/clients-portfolio/clients-portfolio-main/clients-portfolio-main.component';
import { ClientsStockComponent } from './clients/clients-portfolio/clients-stock/clients-stock.component';
import { ClientsStockByProductClassBranchComponent } from './clients/clients-portfolio/clients-stock-by-product-class-branch/clients-stock-by-product-class-branch.component';
import { NewClientsCasualtiesByBranchComponent } from './clients/clients-portfolio/new-clients-casualties-by-branch/new-clients-casualties-by-branch.component';
import { ClientsOccupationComponent } from './clients/clients-occupation/clients-occupation.component';
import { ClientsOccupationMainComponent } from './clients/clients-occupation/clients-occupation-main/clients-occupation-main.component';
import { ClientsStockByGeoComponent } from './clients/clients-occupation/clients-stock-by-geo/clients-stock-by-geo.component';
import { ClientsVocComponent } from './clients/clients-voc/clients-voc.component';
import { ClientsVocClaimsComponent } from './clients/clients-voc/clients-voc-claims/clients-voc-claims.component';
import { ClientsVocNpsComponent } from './clients/clients-voc/clients-voc-nps/clients-voc-nps.component';
import { ClientsInternalProcessQualityComponent } from './clients/clients-internal-process-quality/clients-internal-process-quality.component';
import { ClientsInternalProcessQualityMainComponent } from './clients/clients-internal-process-quality/clients-internal-process-quality-main/clients-internal-process-quality-main.component';
import { ClientsPortfolioDistributionComponent } from './clients/clients-portfolio-distribution/clients-portfolio-distribution.component';
import { ClientsPortfolioDistributionByHoldingComponent } from './clients/clients-portfolio-distribution/clients-portfolio-distribution-by-holding/clients-portfolio-distribution-by-holding.component';
import { ClientsExperienceIndicatorsComponent } from './clients/clients-portfolio-distribution/clients-experience-indicators/clients-experience-indicators.component';
import { ClientsStockGraphicComponent } from './clients/clients-portfolio/clients-stock/clients-stock-graphic/clients-stock-graphic.component';
import { ClientsPenetrationSegmentationComponent } from './clients/clients-penetration-segmentation/clients-penetration-segmentation.component';
import { SantanderVsZsSegmentationComponent } from './clients/clients-penetration-segmentation/santander-vs-zs-segmentation/santander-vs-zs-segmentation.component';
import { SantanderClientsPenetrationComponent } from './clients/clients-penetration-segmentation/santander-clients-penetration/santander-clients-penetration.component';
import { SantanderSegmentationComponent } from './clients/clients-penetration-segmentation/santander-segmentation/santander-segmentation.component';
import { IshikawaDiagramComponent } from './shared/components/ishikawa-diagram/ishikawa-diagram.component';
import { ClientsPortfolioIshikawaDiagramComponent } from './clients/clients-portfolio/clients-portfolio-main/clients-portfolio-ishikawa-diagram/clients-portfolio-ishikawa-diagram.component';
import { TechnicalMainInformationComponent } from './data-governance/data-governance-technical/technical-main-information/technical-main-information.component';
import { TechnicalUpperMenuComponent } from './shared/components/technical-upper-menu/technical-upper-menu.component';
import { FixedStockIndicatorsMainComponent } from './cancellation/cancellation-fixed-stock/fixed-stock-indicators/fixed-stock-indicators-main/fixed-stock-indicators-main.component';
import { FixedStockIndicatorsGraphicComponent } from './cancellation/cancellation-fixed-stock/fixed-stock-indicators/fixed-stock-indicators-graphic/fixed-stock-indicators-graphic.component';
import { TooltipDirective } from './shared/directives/tooltip/tooltip.directive';
import { NewClientsCasualtiesByBranchGraphicComponent } from './clients/clients-portfolio/new-clients-casualties-by-branch/new-clients-casualties-by-branch-graphic/new-clients-casualties-by-branch-graphic.component';
import { ClientsStockByProductClassBranchPieGraphicsComponent } from './clients/clients-portfolio/clients-stock-by-product-class-branch/clients-stock-by-product-class-branch-pie-graphics/clients-stock-by-product-class-branch-pie-graphics.component';
import { ClientsOccupationMainIndicatorsComponent } from './clients/clients-occupation/clients-occupation-main/clients-occupation-main-indicators/clients-occupation-main-indicators.component';
import { IconKpiComponent } from './shared/components/icon-kpi/icon-kpi.component';
import { ClientsOccupationMainPieComponent } from './clients/clients-occupation/clients-occupation-main/clients-occupation-main-pie/clients-occupation-main-pie.component';
import { ClientsOccupationMainThermometerComponent } from './clients/clients-occupation/clients-occupation-main/clients-occupation-main-thermometer/clients-occupation-main-thermometer.component';
import { ThermometerComponent } from './shared/components/thermometer/thermometer.component';
import { ClientsStockByGeoMapComponent } from './clients/clients-occupation/clients-stock-by-geo/clients-stock-by-geo-map/clients-stock-by-geo-map.component';
import { ClientsVocClaimsVelocimeterComponent } from './clients/clients-voc/clients-voc-claims/clients-voc-claims-velocimeter/clients-voc-claims-velocimeter.component';
import { ClientsVocClaimsGraphicComponent } from './clients/clients-voc/clients-voc-claims/clients-voc-claims-graphic/clients-voc-claims-graphic.component';
import { ClientsVocClaimsPolarComponent } from './clients/clients-voc/clients-voc-claims/clients-voc-claims-polar/clients-voc-claims-polar.component';
import { PolarChartComponent } from './shared/components/polar-chart/polar-chart.component';
import { ClientsVocVelocimeterComponent } from './clients/clients-voc/clients-voc-nps/clients-voc-velocimeter/clients-voc-velocimeter.component';
import { ClientsVocGraphicComponent } from './clients/clients-voc/clients-voc-nps/clients-voc-graphic/clients-voc-graphic.component';
import { ClientsVocPolarComponent } from './clients/clients-voc/clients-voc-nps/clients-voc-polar/clients-voc-polar.component';
import { SearchResultComponent } from './shared/components/search-result/search-result.component';
import { ClientsInternalProcessQualityTableComponent } from './clients/clients-internal-process-quality/clients-internal-process-quality-main/clients-internal-process-quality-table/clients-internal-process-quality-table.component';
import { ClientsPortfolioDistributionByHoldingPieComponent } from './clients/clients-portfolio-distribution/clients-portfolio-distribution-by-holding/clients-portfolio-distribution-by-holding-pie/clients-portfolio-distribution-by-holding-pie.component';
import { ClientsPortfolioDistributionByHoldingIndicatorsComponent } from './clients/clients-portfolio-distribution/clients-portfolio-distribution-by-holding/clients-portfolio-distribution-by-holding-indicators/clients-portfolio-distribution-by-holding-indicators.component';
import { ClientsExperienceIndicatorsTableComponent } from './clients/clients-portfolio-distribution/clients-experience-indicators/clients-experience-indicators-table/clients-experience-indicators-table.component';
import { IconRepeaterComponent } from './shared/components/icon-repeater/icon-repeater.component';
import { SantanderClientsPenetrationMainComponent } from './clients/clients-penetration-segmentation/santander-clients-penetration/santander-clients-penetration-main/santander-clients-penetration-main.component';
import { DataDiscoveryHomeComponent } from './data-discovery/data-discovery-home/data-discovery-home.component';
import { DataDiscoveryCardComponent } from './shared/components/data-discovery-card/data-discovery-card.component';
import { DataDiscoveryNewDossierDialogComponent } from './shared/components/data-discovery-new-dossier-dialog/data-discovery-new-dossier-dialog.component';
import { ReportsHomeComponent } from './reports/reports-home/reports-home.component';
import { ControlPanelComponent } from './control-panel/control-panel.component';
import { CardComponent } from './shared/components/card/card.component';
import { ClientsPortfolioDistributionByHoldingLineGraphComponent } from './clients/clients-portfolio-distribution/clients-portfolio-distribution-by-holding/clients-portfolio-distribution-by-holding-line-graph/clients-portfolio-distribution-by-holding-line-graph.component';
import { CancellationRetentionAccordingToRecordComponent } from './cancellation/cancellation-retention-according-to-record/cancellation-retention-according-to-record.component';
import { RetentionAccordingToRecordComponent } from './cancellation/cancellation-retention-according-to-record/retention-according-to-record/retention-according-to-record.component';
import { RetentionPerActionRecordComponent } from './cancellation/cancellation-retention-according-to-record/retention-per-action-record/retention-per-action-record.component';
import { VoluntaryCancellationRecordComponent } from './cancellation/cancellation-retention-according-to-record/voluntary-cancellation-record/voluntary-cancellation-record.component';
import { RetentionAccordingToRecordFunnelComponent } from './cancellation/cancellation-retention-according-to-record/retention-according-to-record/retention-according-to-record-funnel/retention-according-to-record-funnel.component';
import { RetentionAccordingToRecordGraphicComponent } from './cancellation/cancellation-retention-according-to-record/retention-according-to-record/retention-according-to-record-graphic/retention-according-to-record-graphic.component';
import { VoluntaryCancellationRecordGraphicComponent } from './cancellation/cancellation-retention-according-to-record/voluntary-cancellation-record/voluntary-cancellation-record-graphic/voluntary-cancellation-record-graphic.component';
import { RetentionPerActionRecordGraphicComponent } from './cancellation/cancellation-retention-according-to-record/retention-per-action-record/retention-per-action-record-graphic/retention-per-action-record-graphic.component';
import { ColorTableDialogComponent } from './shared/dialogs/color-table-dialog/color-table-dialog.component';
import { ColorTableComponent } from './shared/components/color-table/color-table.component';
import { PendingAgeEvolutionComponent } from './sinisters/sinisters-rejections/rejections-pending-age/pending-age-evolution/pending-age-evolution.component';
import { HomeHeaderMenuComponent } from './shared/components/home-header/home-header-menu/home-header-menu.component';
import { DocumentManagementComponent } from './document-management/document-management.component';
import { DocumentManagementHomeComponent } from './document-management/document-management-home/document-management-home.component';
import { DocumentManagementFileManagerComponent } from './document-management/document-management-file-manager/document-management-file-manager.component';
import { DocumentManagementHeaderComponent } from './document-management/document-management-header/document-management-header.component';
import { DocumentManagementSectionContainerComponent } from './document-management/document-management-section-container/document-management-section-container.component';
import { UserBubbleComponent } from './shared/components/user-bubble/user-bubble.component';
import { DocumentManagementUserPanelComponent } from './document-management/document-management-user-panel/document-management-user-panel.component';
import { SantanderVsZsSegmentationGraphicComponent } from './clients/clients-penetration-segmentation/santander-vs-zs-segmentation/santander-vs-zs-segmentation-graphic/santander-vs-zs-segmentation-graphic.component';
import { SantanderSegmentationGraphicComponent } from './clients/clients-penetration-segmentation/santander-segmentation/santander-segmentation-graphic/santander-segmentation-graphic.component';
import { TileButtonGroupComponent } from './shared/components/tile-button-group/tile-button-group.component';
import { ClientsVocGroupedGraphicComponent } from './clients/clients-voc/clients-voc-nps/clients-voc-grouped-graphic/clients-voc-grouped-graphic.component';
import { DocumentManagementMetadataEditorComponent } from './document-management/document-management-metadata-editor/document-management-metadata-editor.component';
import { DynamicFormMetadataComponent } from './shared/components/dynamic-form-metadata/dynamic-form-metadata.component';
import { DynamicFormComponent } from './shared/components/dynamic-form/dynamic-form.component';
import { DragAndDropDirective }  from './shared/directives/drag-and-drop/drag-and-drop.directive';
import { ClientsVocGraphicTotalComponent } from './clients/clients-voc/clients-voc-nps/clients-voc-graphic-total/clients-voc-graphic-total.component';
import { ClientsVocVelocimeterTotalComponent } from './clients/clients-voc/clients-voc-nps/clients-voc-velocimeter-total/clients-voc-velocimeter-total.component';

function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // clientId: '6226576d-37e9-49eb-b201-ec1eeb0029b6',
      // redirectUri: 'http://localhost:4200'

/*    //Civica
      authority: 'https://login.microsoftonline.com/6a240b9c-22e3-4f76-a965-bed3882cd878',
      clientId: 'b971ebb6-f86f-470e-adee-4e4b091b3237',
      redirectUri: 'http://localhost:4200'          */

      //ZS Azure
      // authority: 'https://login.microsoftonline.com/9ef0d257-39bc-470d-8e6d-4f03476e240a',
      // clientId: '3cd199b2-5db3-4e76-8485-35fa7ef7158d',
      // redirectUri: 'https://ms-atlas-frontend.azurewebsites.net/'

      // Environment variables
      authority: environment.authority,
      clientId: environment.clientId,
      redirectUri: environment.redirectUri,
      postLogoutRedirectUri: environment.redirectUri

    },cache: {
      cacheLocation: "localStorage",
    }
  });
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfig {
  const protectedResourceMap = new Map<string, Array<string>>();
  //protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  //Civica
  //protectedResourceMap.set('https://sikapi-apim.azure-api.net/', ['api://9946859a-d900-42d4-96f6-ee05752440f6/user_impersonation']);

  //ZS Azure
  // protectedResourceMap.set('https://sds-apis.azure-api.net/sik-ms-api', ['api://35f8dbb2-152a-4c35-b0ec-1ba58149bdc7/user_impersonation']);

  // Environment variable
  //protectedResourceMap.set(environment.azureApiUri, [environment.azureApiKey]);
  //protectedResourceMap.set(environment.azureApiUriAtlas, [environment.azureApiKey]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + Date.now());
}



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    WrapperComponent,
    WidgetComponent,
    GridSinisterOneComponent,
    LogoComponent,
    HamburguerMenuComponent,
    ReferenceTitleComponent,
    FilterButtonComponent,
    FilterTagComponent,
    HugeKpiComponent,
    BigKpiComponent,
    RegularKpiComponent,
    SmallKpiComponent,
    SalesPageOneComponent,
    SalesComponent,
    MonthlySaleGraphComponent,
    SalesVsPlanProjectionGraph,
    DailySaleGraphComponent,
    OptionTabComponent,
    StackedVerticalHorizontalGraphComponent,
    StackedVerticalBarComponent,
    KpisHeaderComponent,
    KpiHeaderSingleComponent,
    SideFiltersComponent,
    DropdownComponent,
    DropdownMultipleComponent,
    DailySalesGraphDialogComponent,
    MonthlySalesGraphDialogComponent,
    MonthlySaleComponent,
    SalesPortfolioComponent,
    TranslationComponent,
    HeaderSubmenuComponent,
    LinearGaugeGraphComponent,
    MonthlySalePieGraphComponent,
    MagnitudeOrderPipe,
    FilterListPipe,
    LegendComponent,
    MonthlySaleTableComponent,
    PortfolioIndicatorsComponent,
    IndicatorWidgetComponent,
    SalesQualityComplaintsComponent,
    QualityComponent,
    QualityInternalProcessComponent,
    QualityClaimsComponent,
    NpsSaleComponent,
    KpiColorComponent,
    CancellationDetailComponent,
    ChurnRateComponent,
    ChurnRatePerCropComponent,
    CancellationReasonsComponent,
    CancellationBranchChannelComponent,
    SalesMixPortfolioGwpComponent,
    PortfolioComponent,
    VerticalBarComponent,
    GwpComponent,
    VerticalHorizontalLinesBarsGraphComponent,
    PortfolioVsSalesMixComponent,
    StackedHorizontalNegativeGraphComponent,
    StackedHorizontalNegativeGraphBarsComponent,
    RenewalsCancellationsComponent,
    GroupedVerticalHorizontalGraphDAxisComponent,
    QualityClaimsGraphicComponent,
    NpsSaleGraphicComponent,
    GwpComponent,
    WokGraphComponent,
    WokGraphBarDifferenceComponent,
    WokGraphUpperLabelComponent,
    PortfolioGraphComponent,
    QualityClaimsVelocimeterComponent,
    VelocimeterComponent,
    NpsSaleVelocimeterComponent,
    GwpGraphComponent,
    PortfolioGraphComponent,
    GroupedVerticalHorizontalGraphDAxisComponent,
    CancellationBranchChannelGraphicComponent,
    ComponentLoaderComponent,
    CancellationChargeComponent,
    CancellationChargeBlockComponent,
    CancellationChargeActivityComponent,
    CancellationChargesComponent,
    CancellationChargeEffectivenessComponent,
    ChurnRatePerCropGraphicComponent,
    ChurnRatePerCropColorsBarComponent,
    ColorBarComponent,
    ChurnRatePerCropGraphicComponent,
    ChurnRatePerCropColorsBarComponent,
    ColorBarComponent,
    ChurnRatePlanGraphComponent,
    ChurnRateEvolutionGraphComponent,
    DifferenceBarLabelComponent,
    CancellationChargesPieChartComponent,
    CancellationReasonsGraphicComponent,
    CancellationChargeActivityGraphicComponent,
    NumberPipe,
    BaseLoaderComponent,
    CancellationChargesGraphicComponent,
    CancellationRetentionComponent,
    RetentionComponent,
    VoluntaryCancellationComponent,
    RetentionPerActionComponent,
    DecimalThousandPipe,
    CancellationRenewalComponent,
    RenewalComponent,
    RenewalEffectivenessComponent,
    RenewalPerComponent,
    EvolutionaryRenewalbyComponent,
    CancellationPersistenceComponent,
    PersistenceTotalComponent,
    PersistencePostretentionComponent,
    PersistenceNewSaleComponent,
    CancellationQualityComponent,
    CancellationQualityBlockComponent,
    CancellationQualityClaimsComponent,
    CancellationNpsComponent,
    PersistenceTotalGraphicComponent,
    PersistenceNewSaleGraphicComponent,
    PersistencePostretentionGraphicComponent,
    CancellationQualityClaimsVelocimeterComponent,
    CancellationQualityClaimsGraphicComponent,
    CancellationNpsGraphicComponent,
    CancellationNpsVelocimeterComponent,
    RenewalEffectivenessGraphicComponent,
    RenewalPerPieChartComponent,
    RenewalPerGraphicComponent,
    VoluntaryCancellationGraphicComponent,
    EvolutionaryRenewalbyGraphicComponent,
    CancellationChargeEffectivenessGraphicComponent,
    RetentionPerActionGraphicComponent,
    KpisFunnelComponent,
    RenewalFunnelComponent,
    RenewalGraphComponent,
    CancellationChargeBlockFunnelComponent,
    CancellationChargeBlockGraphComponent,
    RetentionFunnelComponent,
    RetentionGraphicComponent,
    OptionTabMultipleComponent,
    SlasSectionComponent,
    SlasMainComponent,
    KpisGraphicContainerComponent,
    KpisGraphicCardComponent,
    PreHomeComponent,
    BtnClientsComponent,
    BtnSaleComponent,
    BtnCancellationComponent,
    BtnSinisterComponent,
    BtnBackofficeComponent,
    KpiHomeComponent,
    BtnFilterComponent,
    AppVersionContainerComponent,
    FancySelectorComponent,
    LogoutDialogComponent,
    SessionExpiredDialogComponent,
    HomeComponent,
    HomeHeaderComponent,
    HomeMainBlockComponent,
    UserSelectorComponent,
    PieChartDialogComponent,
    MessageBoxComponent,
    NotificationButtonComponent,
    ErrorsDialogComponent,
    PortfolioMixDialogComponent,
    FilterListPipe,
    ResetFiltersDialogComponent,
    TagComponent,
    SinistersDetailComponent,
    SinistersMainComponent,
    SinistersRejectionsComponent,
    RejectionsMainComponent,
    RejectionsPendingAgeComponent,
    RejectionsLossRatioComponent,
    RejectionsTimeProcessesComponent,
    SinistersQualityComponent,
    SinistersQualityMainComponent,
    SinistersClaimsPercentageComponent,
    SinistersClaimsComponent,
    SinistersPollsComponent,
    OrganizationSandwichComponent,
    TimeProcessesSandwichComponent,
    PagerComponent,
    LossRatioTableComponent,
    LossRatioLineGraphComponent,
    LossRatioEvolutionComponent,
    RejectionsKpiComponent,
    RejectionsIndicatorsComponent,
    RejectionsWalkComponent,
    PendingAgeBlockComponent,
    TreeMapComponent,
    SinistersClaimsVelocimeterComponent,
    SinistersClaimsGraphicComponent,
    SinistersPollsGraphicComponent,
    SinistersPollsVelocimeterComponent,
    SinistersQualityVelocimeterComponent,
    SinistersQualityGraphicComponent,
    DoublePercentageKpiComponent,
    HorizontalFunnelGraphComponent,
    SemicirclePieComponent,
    VelocimeterInfoComponent,
    BtnGobernanceInfoComponent,
    InfoDialogComponent,
    TabGroupComponent,
    ColorLinkComponent,
    TileListComponent,
    TileListItemComponent,
    PersistenceMonthlyComponent,
    PersistenceMonthlyGraphicComponent,
    SinistersMapComponent,
    MapComponent,
    InfoHeaderComponent,
    FilterInfoDialogComponent,
    HtmlEditorComponent,
    DataGovernanceInfoPageComponent,
    DataGovernanceHomeComponent,
    PortalMainBlockComponent,
    PortalWidgetSectionComponent,
    SearcherComponent,
    SearchButtonComponent,
    SearchOptionTabComponent,
    ClosedPerMonthOfComplaintComponent,
    SinistersClosedPerMonthTableComponent,
    CancellationFixedStockComponent,
    FixedStockChurnRateComponent,
    FixedStockChurnRatePerCropComponent,
    FixedStockPersistenceComponent,
    FixedStockIndicatorsComponent,
    FixedStockChurnRateGraphicsComponent,
    FixedStockChurnRatePerCropGraphicComponent,
    FixedStockPersistenceGraphicComponent,
    SinistersLossRatioComponent,
    OperationalLossRatioComponent,
    SinistersLossRatioInfoComponent,
    LossRatioInfoEvolutionComponent,
    LossRatioInfoLineGraphComponent,
    LossRatioInfoTableComponent,
    RejectionsFrequencyEvolutionComponent,
    RejectionsFrequencyEvolutionGraphicComponent,
    DataGovernanceBusinessComponent,
    DgHeaderComponent,
    BusinessUpperMenuComponent,
    BusinessSideMenuComponent,
    DgInfoCardComponent,
    BusinessMainInformationComponent,
    LossRatioMapComponent,
    RejectionsIndicatorsMainComponent,
    RejectionsLineGraphComponent,
    RejectionsPieGraphsComponent,
    InfoPanelComponent,
    InfoPanelTabsComponent,
    InfoPanelListComponent,
    InfoPanelListItemComponent,
    TechnicalSearchBarComponent,
    ColorLineTagComponent,
    InputBoxComponent,
    TechnicalSearchBarResultsComponent,
    TechnicalSearchBarResultComponent,
    BreadcrumbsComponent,
    PortalWidgetTechnicalSectionComponent,
    NetworkGraphComponent,
    DataGovernanceTechnicalComponent,
    ClientsPortfolioComponent,
    ClientsPortfolioMainComponent,
    ClientsStockComponent,
    ClientsStockByProductClassBranchComponent,
    NewClientsCasualtiesByBranchComponent,
    ClientsOccupationComponent,
    ClientsOccupationMainComponent,
    ClientsStockByGeoComponent,
    ClientsVocComponent,
    ClientsVocClaimsComponent,
    ClientsVocNpsComponent,
    ClientsInternalProcessQualityComponent,
    ClientsInternalProcessQualityMainComponent,
    ClientsPortfolioDistributionComponent,
    ClientsPortfolioDistributionByHoldingComponent,
    ClientsExperienceIndicatorsComponent,
    ClientsStockGraphicComponent,
    ClientsPenetrationSegmentationComponent,
    SantanderVsZsSegmentationComponent,
    SantanderClientsPenetrationComponent,
    SantanderSegmentationComponent,
    IshikawaDiagramComponent,
    ClientsPortfolioIshikawaDiagramComponent,
    TechnicalMainInformationComponent,
    TechnicalUpperMenuComponent,
    FixedStockIndicatorsMainComponent,
    FixedStockIndicatorsGraphicComponent,
    TooltipDirective,
    NewClientsCasualtiesByBranchGraphicComponent,
    ClientsStockByProductClassBranchPieGraphicsComponent,
    ClientsOccupationMainIndicatorsComponent,
    IconKpiComponent,
    ClientsOccupationMainPieComponent,
    ClientsOccupationMainThermometerComponent,
    ThermometerComponent,
    ClientsStockByGeoMapComponent,
    ClientsVocClaimsVelocimeterComponent,
    ClientsVocClaimsGraphicComponent,
    ClientsVocClaimsPolarComponent,
    PolarChartComponent,
    ClientsVocVelocimeterComponent,
    ClientsVocGraphicComponent,
    ClientsVocPolarComponent,
    SearchResultComponent,
    ClientsInternalProcessQualityTableComponent,
    ClientsPortfolioDistributionByHoldingPieComponent,
    ClientsPortfolioDistributionByHoldingIndicatorsComponent,
    ClientsExperienceIndicatorsTableComponent,
    IconRepeaterComponent,
    SantanderClientsPenetrationMainComponent,
    DataDiscoveryHomeComponent,
    DataDiscoveryCardComponent,
    DataDiscoveryNewDossierDialogComponent,
    ReportsHomeComponent,
    ControlPanelComponent,
    CardComponent,
    DataDiscoveryHomeComponent,
    DataDiscoveryCardComponent,
    DataDiscoveryNewDossierDialogComponent,
    ClientsPortfolioDistributionByHoldingLineGraphComponent,
    CancellationRetentionAccordingToRecordComponent,
    RetentionAccordingToRecordComponent,
    RetentionPerActionRecordComponent,
    VoluntaryCancellationRecordComponent,
    RetentionAccordingToRecordFunnelComponent,
    RetentionAccordingToRecordGraphicComponent,
    VoluntaryCancellationRecordGraphicComponent,
    RetentionPerActionRecordGraphicComponent,
    DataDiscoveryNewDossierDialogComponent,
    ColorTableDialogComponent,
    ColorTableComponent,
    PendingAgeEvolutionComponent,
    HomeHeaderMenuComponent,
    DocumentManagementComponent,
    DocumentManagementHomeComponent,
    DocumentManagementFileManagerComponent,
    DocumentManagementHeaderComponent,
    DocumentManagementSectionContainerComponent,
    UserBubbleComponent,
    DocumentManagementUserPanelComponent,
    SantanderVsZsSegmentationGraphicComponent,
    SantanderSegmentationGraphicComponent,
    ClientsVocGroupedGraphicComponent,
    DocumentManagementMetadataEditorComponent,
    DynamicFormMetadataComponent,
    DynamicFormComponent,
    TileButtonGroupComponent,
    ClientsVocGroupedGraphicComponent,
    DragAndDropDirective,
    ClientsVocGraphicTotalComponent,
    ClientsVocVelocimeterTotalComponent,
    CountryLoadDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,    
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatDialogModule,
    MatTabsModule,
    MatIconModule,
    MatMenuModule,
    HighchartsChartModule,  
    CarouselModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularEditorModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: {
        interactionType: InteractionType.Redirect
      } as MsalGuardConfiguration
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MSTReponseInterceptor,
      multi: true
    },  
    {
      provide: MatDialogRef,
      useValue: {}
    },  
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    NumberPipe,
    DecimalThousandPipe,
    CookieService,
    MagnitudeOrderPipe,
    FilterListPipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DailySalesGraphDialogComponent,
    MonthlySalesGraphDialogComponent,
    CountryLoadDialogComponent
  ]
})
export class AppModule { }

<app-wrapper>

    <div class="wrapper_grid">
        <div class="occupation-main">
            <app-clients-occupation-main></app-clients-occupation-main>
        </div>
        <div class="clients-stock-by-geo">
            <app-clients-stock-by-geo></app-clients-stock-by-geo>
        </div>        
    </div>

</app-wrapper>
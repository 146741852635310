<app-wrapper>

    <div class="wrapper_grid">
        <div class="cancellation-charge">
            <app-cancellation-charge-block></app-cancellation-charge-block>
        </div>
        <div class="cancellation-charge-activity">
            <app-cancellation-charge-activity></app-cancellation-charge-activity>
        </div>        
        <div class="cancellation-charges">
            <app-cancellation-charges></app-cancellation-charges>
        </div>
        <div class="cancellation-charge-effectiveness">
            <app-cancellation-charge-effectiveness></app-cancellation-charge-effectiveness>
        </div>
    </div>

</app-wrapper>
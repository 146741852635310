<app-wrapper>

    <div class="wrapper_grid">
        <div class="cancellation-quality">
            <app-cancellation-quality-block></app-cancellation-quality-block>
        </div>
        <div class="cancellation-quality-claims">
            <app-cancellation-quality-claims></app-cancellation-quality-claims>
        </div>        
        <div class="cancellation-nps">
            <app-cancellation-nps></app-cancellation-nps>
        </div>
    </div>

</app-wrapper>
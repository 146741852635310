<app-wrapper>

    <div class="wrapper_grid">
        <div class="sales">
            <app-sales></app-sales>
        </div>

        <div class="monthSales">
            <app-monthly-sale></app-monthly-sale>
        </div>

    </div>

</app-wrapper>
<div class="search-container" [@slideToLeft]>
    <div class="close-searcher-btn" (click)="closePanel()">
        <mat-icon class="close-icon">close</mat-icon>
    </div>
    <div class="search-panel-header">
        <div class="d-flex justify-content-between align-items-center">
            <span class="search-panel-title">{{ 'searchPanel.title' | translate | uppercase }}</span>
        </div>
        <div class="search-input">
            <div class="input-group">
                <input type="search" #searchInput class="input-group-text" placeholder="Search" aria-label="Search" />
                <button type="button" class="btn input-group-btn" (click)="search()">{{ 'labels.search' | translate }}</button>
            </div>
        </div>
        <app-search-option-tab *ngIf="area === 'portal'" [options]="options" [sectionName]="sectionName"></app-search-option-tab>
    </div>
    <div class="search-panel-body">
        <div class="search-result-number mt-n2">
            <span>{{ searchResultNumber }}</span>
            <div class="mt-2">
                <app-search-result *ngFor="let result of results" [resultInfo]="result"></app-search-result>
            </div>
        </div>
    </div>
</div>

<div class="bg-overlay" *ngIf="openPanel" [@fadeInOut] (click)="closePanel()"></div>
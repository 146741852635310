<div class="dg-header">
  <div class="d-flex justify-content-between">
    <div class="d-flex">
      <div class="logo-block">
        <img src="assets/img/logo_z.png">
        <img src="assets/img/logo_santander.png">
      </div>
      <div class="title d-flex ml-4">
        <span class="mr-3 d-flex">
          <mat-icon class="left-arrow-icon" [routerLink]="routerLink">keyboard_arrow_left</mat-icon>
        </span>
        <span>{{ headerTitle | translate}} </span><span *ngIf="headerSubtitle">&nbsp;- {{ headerSubtitle | translate }}</span>
      </div>
    </div>
    <div *ngIf="hasSearchButton" class="d-flex">
      <app-search-button class="search-button" [isDataGovernancePortal]="isDataGovernancePortal"></app-search-button>
    </div>
  </div>
</div>

<app-searcher *ngIf="openSearchPanel" [@animateFilters] [area]="area"></app-searcher>
<div class="mt-3">
    <component-loader [width]="" [height]="220" *ngIf="!loaded"></component-loader>
    <ng-container *ngIf="loaded">
        <div class="indicators-row d-flex justify-content-between" *ngFor="let item of indicators">
            <div class="icon-repeater">
                <app-icon-repeater 
                    [itemsNumber]="indicatorsMaxNumber"
                    [activeNumber]="item.itemsNumber"
                    [activatedImageSource]="indicatorsActivatedImageSource"
                    [deactivatedImageSource]="indicatorsDeactivatedImageSource"
                    [imageWidth]="indicatorsImageWidth">
                </app-icon-repeater>
            </div>
            <div class="kpi-container">
                <app-small-kpi 
                    [title]="item.title" 
                    mainValue="" 
                    [secondaryValue]="item.value" 
                    [percentage]="item.percentage"
                    [trend]="item.trend">
                </app-small-kpi>
            </div>
        </div>
    </ng-container>
</div>


<div class="side-filters h-100" *ngIf="filtersOn == true" [@slideToLeft]>
  <div class="bg-secondary h-100 p-4 d-flex flex-column justify-content-between">

    <base-loader *ngIf="!loaded"></base-loader>

    <div class="top-section">

      <div class="filters-header-container d-flex justify-content-between align-items-center">
        <span class="title section-card-title">{{ 'filtersPanel.title' | translate }}</span>
        <fancy-selector [ngClass]="{'country-disabled': countries.length <= 1}" [title]="countryName"
          [inactiveIcon]="inactiveIcon" [activeIcon]="activeIcon" [isActive]="isActive" [menuItems]="countries"
          (select)="selectCountry($event)">
        </fancy-selector>
      </div>

      <div class="filters-container mt-3">

        <!-- Template Sale 1 -->
        <div class="filters-3-columns" *ngIf="templateName == 'tpl-sale-1'">

          <div class="filter-row big-row header-row">
            <!-- Time header -->
            <div class="filter-block col-6">
              <div class="filter">
                <app-info-header title="filtersPanel.time"></app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row separator-row">
            <!-- Year -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].year | translate" [dropdownId]="dropdownYear" [items]="itemsYear">
                </app-dropdown>
              </div>
            </div>

            <!-- Month -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].month | translate" [dropdownId]="dropdownMonth" [items]="itemsMonth">
                </app-dropdown>
              </div>
            </div>
          </div>

          <div class="filter-row big-row header-row">
            <!-- Product header -->
            <div class="filter-block col">
              <div class="filter">
                <app-info-header title="filtersPanel.product" icon="help" dialogTitle="hierarchy.product"
                  image="product-hierarchy">
                </app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Product category -->
            <div class="filter-block col-3 product-disabled">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productCategory | translate" placeholder="-"
                  [dropdownList]="productCategoryItems" [selectedItems]="selectedItemsProductCategory"
                  formName="productCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product subcategory -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productSubCategory | translate" placeholder="-"
                  [dropdownList]="productSubCategoryItems" [selectedItems]="selectedItemsProductSubCategory"
                  formName="productSubCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product family -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productFamily | translate" placeholder="-"
                  [dropdownList]="productFamilyItems" [selectedItems]="selectedItemsProductFamily"
                  formName="productFamilyForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product technical -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productTechnical | translate" placeholder="-"
                  [dropdownList]="productTechnicalItems" [selectedItems]="selectedItemsProductTechnical"
                  formName="productTechnicalForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row separator-row">

            <!-- Product Bouquet Business -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetBusiness | translate" placeholder="-"
                  [dropdownList]="bouquetBusinessItems" [selectedItems]="selectedItemsBouquetBusiness"
                  formName="bouquetBusinessForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product Bouquet LOBS -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetLobs | translate" placeholder="-"
                  [dropdownList]="bouquetLobsItems" [selectedItems]="selectedItemsBouquetLobs"
                  formName="bouquetLobsForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product type -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productType | translate" placeholder="-"
                  [dropdownList]="productTypeItems" [selectedItems]="selectedItemsProductType"
                  formName="productTypeForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row header-row">
            <!-- Channel header -->
            <div class="filter-block col">
              <div class="filter">
                <app-info-header title="filtersPanel.channel" icon="help" dialogTitle="hierarchy.channel"
                  image="channel-hierarchy">
                </app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Family Channel -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].familyChannel | translate" placeholder="-"
                  [dropdownList]="familyChannelItems" [selectedItems]="selectedItemsFamilyChannel"
                  formName="familyChannelForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Macro Channel -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].macroChannel | translate" placeholder="-"
                  [dropdownList]="macroChannelItems" [selectedItems]="selectedItemsMacroChannel"
                  formName="macroChannelForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Channel -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].channel | translate" placeholder="-"
                  [dropdownList]="channelItems" [selectedItems]="selectedItemsChannel" formName="channelForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Point Channel -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].pointChannel | translate" placeholder="-"
                  [dropdownList]="pointChannelItems" [selectedItems]="selectedItemsPointChannel"
                  formName="pointChannelForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row separator-row">

            <!-- Territorial -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].territorial | translate" placeholder="-"
                  [dropdownList]="territorialItems" [selectedItems]="selectedItemsTerritorial"
                  formName="territorialForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Zone -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].zone | translate" placeholder="-" [dropdownList]="zoneItems"
                  [selectedItems]="selectedItemsZone" formName="zoneForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Corporate Society -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].corporateSociety | translate" placeholder="-"
                  [dropdownList]="corporateSocietyItems" [selectedItems]="selectedItemsSocietyCorporate"
                  formName="societyCorporateForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row header-row">
            <!-- Other header -->
            <div class="filter-block col-6">
              <div class="filter">
                <app-info-header title="filtersPanel.others"></app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Currency -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].currency | translate" [dropdownId]="dropdownCurrency"
                  [items]="itemsCurrency"></app-dropdown>
              </div>
            </div>

            <!-- Budgets -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].budgets | translate" [dropdownId]="dropdownBudgets"
                  [items]="itemsBudgets"></app-dropdown>
              </div>
            </div>

          </div>

        </div>

        <!-- Template Sale 2 -->
        <div class="filters-3-columns" *ngIf="templateName == 'tpl-sale-2'">
          
          <div class="filter-row big-row header-row">
            <!-- Time header -->
            <div class="filter-block col-6">
              <div class="filter">
                <app-info-header title="filtersPanel.time"></app-info-header>
              </div>
            </div>
          </div>
          
          <div class="filter-row big-row separator-row">

            <!-- Year -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].year | translate" [dropdownId]="dropdownYear" [items]="itemsYear">
                </app-dropdown>
              </div>
            </div>

            <!-- Month -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].month | translate" [dropdownId]="dropdownMonth" [items]="itemsMonth">
                </app-dropdown>
              </div>
            </div>

          </div>

          <div class="filter-row big-row header-row">
            <!-- Product header -->
            <div class="filter-block col">
              <div class="filter">
                <app-info-header title="filtersPanel.product" icon="help" dialogTitle="hierarchy.product"
                  image="product-hierarchy">
                </app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Product category -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productCategory | translate" placeholder="-"
                  [dropdownList]="productCategoryItems" [selectedItems]="selectedItemsProductCategory"
                  formName="productCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product subcategory -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productSubCategory | translate" placeholder="-"
                  [dropdownList]="productSubCategoryItems" [selectedItems]="selectedItemsProductSubCategory"
                  formName="productSubCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product family -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productFamily | translate" placeholder="-"
                  [dropdownList]="productFamilyItems" [selectedItems]="selectedItemsProductFamily"
                  formName="productFamilyForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product technical -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productTechnical | translate" placeholder="-"
                  [dropdownList]="productTechnicalItems" [selectedItems]="selectedItemsProductTechnical"
                  formName="productTechnicalForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row separator-row">

            <!-- Product Bouquet Business -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetBusiness | translate" placeholder="-"
                  [dropdownList]="bouquetBusinessItems" [selectedItems]="selectedItemsBouquetBusiness"
                  formName="bouquetBusinessForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product Bouquet LOBS -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetLobs | translate" placeholder="-"
                  [dropdownList]="bouquetLobsItems" [selectedItems]="selectedItemsBouquetLobs"
                  formName="bouquetLobsForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product type -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productType | translate" placeholder="-"
                  [dropdownList]="productTypeItems" [selectedItems]="selectedItemsProductType"
                  formName="productTypeForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row header-row">
            <!-- Channel header -->
            <div class="filter-block col">
              <div class="filter">
                <app-info-header title="filtersPanel.channel" icon="help" dialogTitle="hierarchy.channel"
                  image="channel-hierarchy">
                </app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Family Channel -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].familyChannel | translate" placeholder="-"
                  [dropdownList]="familyChannelItems" [selectedItems]="selectedItemsFamilyChannel"
                  formName="familyChannelForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Macro Channel -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].macroChannel | translate" placeholder="-"
                  [dropdownList]="macroChannelItems" [selectedItems]="selectedItemsMacroChannel"
                  formName="macroChannelForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Channel -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].channel | translate" placeholder="-"
                  [dropdownList]="channelItems" [selectedItems]="selectedItemsChannel" formName="channelForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Point Channel -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].pointChannel | translate" placeholder="-"
                  [dropdownList]="pointChannelItems" [selectedItems]="selectedItemsPointChannel"
                  formName="pointChannelForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row separator-row">

            <!-- Territorial -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].territorial | translate" placeholder="-"
                  [dropdownList]="territorialItems" [selectedItems]="selectedItemsTerritorial"
                  formName="territorialForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Zone -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].zone | translate" placeholder="-" [dropdownList]="zoneItems"
                  [selectedItems]="selectedItemsZone" formName="zoneForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Corporate Society -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].corporateSociety | translate" placeholder="-"
                  [dropdownList]="corporateSocietyItems" [selectedItems]="selectedItemsSocietyCorporate"
                  formName="societyCorporateForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row header-row">
            <!-- Other header -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-info-header title="filtersPanel.others"></app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Currency -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].currency | translate" [dropdownId]="dropdownCurrency"
                  [items]="itemsCurrency"></app-dropdown>
              </div>
            </div>

          </div>

        </div>

        <!-- Template Sale 3 -->
        <div class="filters-3-columns" *ngIf="templateName == 'tpl-sale-3'">
          
          <div class="filter-row big-row header-row">
            <!-- Time header -->
            <div class="filter-block col-6">
              <div class="filter">
                <app-info-header title="filtersPanel.time"></app-info-header>
              </div>
            </div>
          </div>
          
          <div class="filter-row big-row separator-row">

            <!-- Year -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].year | translate" [dropdownId]="dropdownYear" [items]="itemsYear">
                </app-dropdown>
              </div>
            </div>

            <!-- Month -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].month | translate" [dropdownId]="dropdownMonth" [items]="itemsMonth">
                </app-dropdown>
              </div>
            </div>

          </div>

          <div class="filter-row big-row header-row">
            <!-- Product header -->
            <div class="filter-block col">
              <div class="filter">
                <app-info-header title="filtersPanel.product" icon="help" dialogTitle="hierarchy.product"
                  image="product-hierarchy">
                </app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Product category -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productCategory | translate" placeholder="-"
                  [dropdownList]="productCategoryItems" [selectedItems]="selectedItemsProductCategory"
                  formName="productCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product subcategory -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productSubCategory | translate" placeholder="-"
                  [dropdownList]="productSubCategoryItems" [selectedItems]="selectedItemsProductSubCategory"
                  formName="productSubCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product family -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productFamily | translate" placeholder="-"
                  [dropdownList]="productFamilyItems" [selectedItems]="selectedItemsProductFamily"
                  formName="productFamilyForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product technical -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productTechnical | translate" placeholder="-"
                  [dropdownList]="productTechnicalItems" [selectedItems]="selectedItemsProductTechnical"
                  formName="productTechnicalForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row separator-row">

            <!-- Product Bouquet Business -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetBusiness | translate" placeholder="-"
                  [dropdownList]="bouquetBusinessItems" [selectedItems]="selectedItemsBouquetBusiness"
                  formName="bouquetBusinessForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product Bouquet LOBS -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetLobs | translate" placeholder="-"
                  [dropdownList]="bouquetLobsItems" [selectedItems]="selectedItemsBouquetLobs"
                  formName="bouquetLobsForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product type -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productType | translate" placeholder="-"
                  [dropdownList]="productTypeItems" [selectedItems]="selectedItemsProductType"
                  formName="productTypeForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row header-row">
            <!-- Other header -->
            <div class="filter-block col-6">
              <div class="filter">
                <app-info-header title="filtersPanel.others"></app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Currency -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].currency | translate" [dropdownId]="dropdownCurrency"
                  [items]="itemsCurrency"></app-dropdown>
              </div>
            </div>

            <!-- Budgets -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].budgets | translate" [dropdownId]="dropdownBudgets"
                  [items]="itemsBudgets"></app-dropdown>
              </div>
            </div>

          </div>

        </div>

        <!-- Template Sale 4 -->
        <div class="filters-3-columns" *ngIf="templateName == 'tpl-sale-4'">
          
          <div class="filter-row big-row header-row">
            <!-- Time header -->
            <div class="filter-block col-6">
              <div class="filter">
                <app-info-header title="filtersPanel.time"></app-info-header>
              </div>
            </div>
          </div>
          
          <div class="filter-row big-row separator-row">

            <!-- Year -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].year | translate" [dropdownId]="dropdownYear" [items]="itemsYear">
                </app-dropdown>
              </div>
            </div>

            <!-- Month -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].month | translate" [dropdownId]="dropdownMonth" [items]="itemsMonth">
                </app-dropdown>
              </div>
            </div>

          </div>

          <div class="filter-row big-row header-row">
            <!-- Product header -->
            <div class="filter-block col">
              <div class="filter">
                <app-info-header title="filtersPanel.product" icon="help" dialogTitle="hierarchy.product"
                  image="product-hierarchy">
                </app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Product category -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productCategory | translate" placeholder="-"
                  [dropdownList]="productCategoryItems" [selectedItems]="selectedItemsProductCategory"
                  formName="productCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product subcategory -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productSubCategory | translate" placeholder="-"
                  [dropdownList]="productSubCategoryItems" [selectedItems]="selectedItemsProductSubCategory"
                  formName="productSubCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product family -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productFamily | translate" placeholder="-"
                  [dropdownList]="productFamilyItems" [selectedItems]="selectedItemsProductFamily"
                  formName="productFamilyForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product technical -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productTechnical | translate" placeholder="-"
                  [dropdownList]="productTechnicalItems" [selectedItems]="selectedItemsProductTechnical"
                  formName="productTechnicalForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row separator-row">

            <!-- Product Bouquet Business -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetBusiness | translate" placeholder="-"
                  [dropdownList]="bouquetBusinessItems" [selectedItems]="selectedItemsBouquetBusiness"
                  formName="bouquetBusinessForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product Bouquet LOBS -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetLobs | translate" placeholder="-"
                  [dropdownList]="bouquetLobsItems" [selectedItems]="selectedItemsBouquetLobs"
                  formName="bouquetLobsForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product type -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productType | translate" placeholder="-"
                  [dropdownList]="productTypeItems" [selectedItems]="selectedItemsProductType"
                  formName="productTypeForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row header-row">
            <!-- Other header -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-info-header title="filtersPanel.others"></app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Currency -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].currency | translate" [dropdownId]="dropdownCurrency"
                  [items]="itemsCurrency"></app-dropdown>
              </div>
            </div>

          </div>

        </div>

        <!-- Template Cancellation 1 -->
        <div class="filters-3-columns" *ngIf="templateName == 'tpl-cancellation-1'">
         
          <div class="filter-row big-row header-row">
            <!-- Time header -->
            <div class="filter-block col-6">
              <div class="filter">
                <app-info-header title="filtersPanel.time"></app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row separator-row">
            <!-- Year -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].year | translate" [dropdownId]="dropdownYear" [items]="itemsYear">
                </app-dropdown>
              </div>
            </div>

            <!-- Month -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].month | translate" [dropdownId]="dropdownMonth" [items]="itemsMonth">
                </app-dropdown>
              </div>
            </div>
          </div>

          <div class="filter-row big-row header-row">
            <!-- Product header -->
            <div class="filter-block col">
              <div class="filter">
                <app-info-header title="filtersPanel.product" icon="help" dialogTitle="hierarchy.product"
                  image="product-hierarchy">
                </app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row" [ngClass]="{'product-disabled' : disableProductTypeBlock}">

            <!-- Product category -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productCategory | translate" placeholder="-"
                  [dropdownList]="productCategoryItems" [selectedItems]="selectedItemsProductCategory"
                  formName="productCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product subcategory -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productSubCategory | translate" placeholder="-"
                  [dropdownList]="productSubCategoryItems" [selectedItems]="selectedItemsProductSubCategory"
                  formName="productSubCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product family -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productFamily | translate" placeholder="-"
                  [dropdownList]="productFamilyItems" [selectedItems]="selectedItemsProductFamily"
                  formName="productFamilyForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product technical -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productTechnical | translate" placeholder="-"
                  [dropdownList]="productTechnicalItems" [selectedItems]="selectedItemsProductTechnical"
                  formName="productTechnicalForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row separator-row"  [ngClass]="{'product-disabled' : disableProductTypeBlock}">

            <!-- Product Bouquet Business -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetBusiness | translate" placeholder="-"
                  [dropdownList]="bouquetBusinessItems" [selectedItems]="selectedItemsBouquetBusiness"
                  formName="bouquetBusinessForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product Bouquet LOBS -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetLobs | translate" placeholder="-"
                  [dropdownList]="bouquetLobsItems" [selectedItems]="selectedItemsBouquetLobs"
                  formName="bouquetLobsForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product type -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productType | translate" placeholder="-"
                  [dropdownList]="productTypeItems" [selectedItems]="selectedItemsProductType"
                  formName="productTypeForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row header-row">
            <!-- Channel header -->
            <div class="filter-block col">
              <div class="filter">
                <app-info-header title="filtersPanel.channel" icon="help" dialogTitle="hierarchy.channel"
                  image="channel-hierarchy">
                </app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Family Channel -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].familyChannel | translate" placeholder="-"
                  [dropdownList]="familyChannelItems" [selectedItems]="selectedItemsFamilyChannel"
                  formName="familyChannelForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Macro Channel -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].macroChannel | translate" placeholder="-"
                  [dropdownList]="macroChannelItems" [selectedItems]="selectedItemsMacroChannel"
                  formName="macroChannelForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Channel -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].channel | translate" placeholder="-"
                  [dropdownList]="channelItems" [selectedItems]="selectedItemsChannel" formName="channelForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Point Channel -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].pointChannel | translate" placeholder="-"
                  [dropdownList]="pointChannelItems" [selectedItems]="selectedItemsPointChannel"
                  formName="pointChannelForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row separator-row">
            
            <!-- Territorial -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].territorial | translate" placeholder="-"
                  [dropdownList]="territorialItems" [selectedItems]="selectedItemsTerritorial"
                  formName="territorialForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Zone -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].zone | translate" placeholder="-" [dropdownList]="zoneItems"
                  [selectedItems]="selectedItemsZone" formName="zoneForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row header-row">
            <!-- Other header -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-info-header title="filtersPanel.others"></app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Currency -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].currency | translate" [dropdownId]="dropdownCurrency"
                  [items]="itemsCurrency"></app-dropdown>
              </div>
            </div>

          </div>

        </div>

        <!-- Template Cancellation 2 -->
        <div class="filters-3-columns" *ngIf="templateName == 'tpl-cancellation-2'">
         
          <div class="filter-row big-row header-row">
            <!-- Time header -->
            <div class="filter-block col-6">
              <div class="filter">
                <app-info-header title="filtersPanel.time"></app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row separator-row">
            <!-- Year -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].year | translate" [dropdownId]="dropdownYear" [items]="itemsYear">
                </app-dropdown>
              </div>
            </div>

            <!-- Month -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].month | translate" [dropdownId]="dropdownMonth" [items]="itemsMonth">
                </app-dropdown>
              </div>
            </div>
          </div>

          <div class="filter-row big-row header-row">
            <!-- Product header -->
            <div class="filter-block col">
              <div class="filter">
                <app-info-header title="filtersPanel.product" icon="help" dialogTitle="hierarchy.product"
                  image="product-hierarchy">
                </app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Product category -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productCategory | translate" placeholder="-"
                  [dropdownList]="productCategoryItems" [selectedItems]="selectedItemsProductCategory"
                  formName="productCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product subcategory -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productSubCategory | translate" placeholder="-"
                  [dropdownList]="productSubCategoryItems" [selectedItems]="selectedItemsProductSubCategory"
                  formName="productSubCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product family -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productFamily | translate" placeholder="-"
                  [dropdownList]="productFamilyItems" [selectedItems]="selectedItemsProductFamily"
                  formName="productFamilyForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product technical -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productTechnical | translate" placeholder="-"
                  [dropdownList]="productTechnicalItems" [selectedItems]="selectedItemsProductTechnical"
                  formName="productTechnicalForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row separator-row">

            <!-- Product Bouquet Business -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetBusiness | translate" placeholder="-"
                  [dropdownList]="bouquetBusinessItems" [selectedItems]="selectedItemsBouquetBusiness"
                  formName="bouquetBusinessForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product Bouquet LOBS -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetLobs | translate" placeholder="-"
                  [dropdownList]="bouquetLobsItems" [selectedItems]="selectedItemsBouquetLobs"
                  formName="bouquetLobsForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product type -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productType | translate" placeholder="-"
                  [dropdownList]="productTypeItems" [selectedItems]="selectedItemsProductType"
                  formName="productTypeForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row header-row">
            <!-- Channel header -->
            <div class="filter-block col">
              <div class="filter">
                <app-info-header title="filtersPanel.channel" icon="help" dialogTitle="hierarchy.channel"
                  image="channel-hierarchy">
                </app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Family Channel -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].familyChannel | translate" placeholder="-"
                  [dropdownList]="familyChannelItems" [selectedItems]="selectedItemsFamilyChannel"
                  formName="familyChannelForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Macro Channel -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].macroChannel | translate" placeholder="-"
                  [dropdownList]="macroChannelItems" [selectedItems]="selectedItemsMacroChannel"
                  formName="macroChannelForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Channel -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].channel | translate" placeholder="-"
                  [dropdownList]="channelItems" [selectedItems]="selectedItemsChannel" formName="channelForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Point Channel -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].pointChannel | translate" placeholder="-"
                  [dropdownList]="pointChannelItems" [selectedItems]="selectedItemsPointChannel"
                  formName="pointChannelForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row separator-row">

            <!-- Territorial -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].territorial | translate" placeholder="-"
                  [dropdownList]="territorialItems" [selectedItems]="selectedItemsTerritorial"
                  formName="territorialForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Zone -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].zone | translate" placeholder="-" [dropdownList]="zoneItems"
                  [selectedItems]="selectedItemsZone" formName="zoneForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row header-row">
            <!-- Other header -->
            <div class="filter-block col-9">
              <div class="filter">
                <app-info-header title="filtersPanel.others"></app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Currency -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].currency | translate" [dropdownId]="dropdownCurrency"
                  [items]="itemsCurrency"></app-dropdown>
              </div>
            </div>

            <!-- Payment methods -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].paymentMethods | translate" placeholder="-"
                  [dropdownList]="paymentMethodsItems" [selectedItems]="selectedItemsPaymentMethods"
                  formName="paymentMethodsForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Payment forms -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].paymentForms | translate" placeholder="-"
                  [dropdownList]="paymentFormsItems" [selectedItems]="selectedItemsPaymentForms"
                  formName="paymentFormsForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

        </div>

        <!-- Template Cancellation 3 -->
        <div class="filters-3-columns" *ngIf="templateName == 'tpl-cancellation-3'">
         
          <div class="filter-row big-row header-row">
            <!-- Time header -->
            <div class="filter-block col-6">
              <div class="filter">
                <app-info-header title="filtersPanel.time"></app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row separator-row">
            <!-- Year -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].year | translate" [dropdownId]="dropdownYear" [items]="itemsYear">
                </app-dropdown>
              </div>
            </div>

            <!-- Month -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].month | translate" [dropdownId]="dropdownMonth" [items]="itemsMonth">
                </app-dropdown>
              </div>
            </div>
          </div>

          <div class="filter-row big-row header-row">
            <!-- Product header -->
            <div class="filter-block col">
              <div class="filter">
                <app-info-header title="filtersPanel.product" icon="help" dialogTitle="hierarchy.product"
                  image="product-hierarchy">
                </app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Product category -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productCategory | translate" placeholder="-"
                  [dropdownList]="productCategoryItems" [selectedItems]="selectedItemsProductCategory"
                  formName="productCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product subcategory -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productSubCategory | translate" placeholder="-"
                  [dropdownList]="productSubCategoryItems" [selectedItems]="selectedItemsProductSubCategory"
                  formName="productSubCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product family -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productFamily | translate" placeholder="-"
                  [dropdownList]="productFamilyItems" [selectedItems]="selectedItemsProductFamily"
                  formName="productFamilyForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product technical -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productTechnical | translate" placeholder="-"
                  [dropdownList]="productTechnicalItems" [selectedItems]="selectedItemsProductTechnical"
                  formName="productTechnicalForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row separator-row">

            <!-- Product Bouquet Business -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetBusiness | translate" placeholder="-"
                  [dropdownList]="bouquetBusinessItems" [selectedItems]="selectedItemsBouquetBusiness"
                  formName="bouquetBusinessForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product Bouquet LOBS -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetLobs | translate" placeholder="-"
                  [dropdownList]="bouquetLobsItems" [selectedItems]="selectedItemsBouquetLobs"
                  formName="bouquetLobsForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product type -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productType | translate" placeholder="-"
                  [dropdownList]="productTypeItems" [selectedItems]="selectedItemsProductType"
                  formName="productTypeForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row header-row">
            <!-- Other header -->
            <div class="filter-block col-6">
              <div class="filter">
                <app-info-header title="filtersPanel.others"></app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Currency -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].currency | translate" [dropdownId]="dropdownCurrency"
                  [items]="itemsCurrency"></app-dropdown>
              </div>
            </div>

            <!-- Claim Origin Type -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].claimOriginType | translate" placeholder="-"
                  [dropdownList]="claimOriginTypeItems" [selectedItems]="selectedItemsClaimOriginType"
                  formName="claimOriginTypeForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

        </div>

        <!-- Template Cancellation 4 -->
        <div class="filters-3-columns" *ngIf="templateName == 'tpl-cancellation-4'">
          <div class="filter-row big-row header-row">
            <!-- Time header -->
            <div class="filter-block col-6">
              <div class="filter">
                <app-info-header title="filtersPanel.time"></app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row separator-row">
            <!-- Year -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].year | translate" [dropdownId]="dropdownYear" [items]="itemsYear">
                </app-dropdown>
              </div>
            </div>

            <!-- Month -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].month | translate" [dropdownId]="dropdownMonth" [items]="itemsMonth">
                </app-dropdown>
              </div>
            </div>
          </div>

          <div class="filter-row big-row header-row">
            <!-- Product header -->
            <div class="filter-block col">
              <div class="filter">
                <app-info-header title="filtersPanel.product" icon="help" dialogTitle="hierarchy.product"
                  image="product-hierarchy">
                </app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Product category -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productCategory | translate" placeholder="-"
                  [dropdownList]="productCategoryItems" [selectedItems]="selectedItemsProductCategory"
                  formName="productCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product subcategory -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productSubCategory | translate" placeholder="-"
                  [dropdownList]="productSubCategoryItems" [selectedItems]="selectedItemsProductSubCategory"
                  formName="productSubCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product family -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productFamily | translate" placeholder="-"
                  [dropdownList]="productFamilyItems" [selectedItems]="selectedItemsProductFamily"
                  formName="productFamilyForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product technical -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productTechnical | translate" placeholder="-"
                  [dropdownList]="productTechnicalItems" [selectedItems]="selectedItemsProductTechnical"
                  formName="productTechnicalForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row separator-row">

            <!-- Product Bouquet Business -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetBusiness | translate" placeholder="-"
                  [dropdownList]="bouquetBusinessItems" [selectedItems]="selectedItemsBouquetBusiness"
                  formName="bouquetBusinessForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product Bouquet LOBS -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetLobs | translate" placeholder="-"
                  [dropdownList]="bouquetLobsItems" [selectedItems]="selectedItemsBouquetLobs"
                  formName="bouquetLobsForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product type -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productType | translate" placeholder="-"
                  [dropdownList]="productTypeItems" [selectedItems]="selectedItemsProductType"
                  formName="productTypeForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row header-row">
            <!-- Other header -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-info-header title="filtersPanel.others"></app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Currency -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].currency | translate" [dropdownId]="dropdownCurrency"
                  [items]="itemsCurrency"></app-dropdown>
              </div>
            </div>

          </div>

        </div>

        <!-- Template Cancellation 5 -->
        <div class="filters-3-columns" *ngIf="templateName == 'tpl-cancellation-5'">
         
          <div class="filter-row big-row header-row">
            <!-- Time header -->
            <div class="filter-block col-6">
              <div class="filter">
                <app-info-header title="filtersPanel.time"></app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row separator-row">
            <!-- Year -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].year | translate" [dropdownId]="dropdownYear" [items]="itemsYear">
                </app-dropdown>
              </div>
            </div>

            <!-- Month -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].month | translate" [dropdownId]="dropdownMonth" [items]="itemsMonth">
                </app-dropdown>
              </div>
            </div>
          </div>

          <div class="filter-row big-row header-row">
            <!-- Product header -->
            <div class="filter-block col">
              <div class="filter">
                <app-info-header title="filtersPanel.product" icon="help" dialogTitle="hierarchy.product"
                  image="product-hierarchy">
                </app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Product category -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productCategory | translate" placeholder="-"
                  [dropdownList]="productCategoryItems" [selectedItems]="selectedItemsProductCategory"
                  formName="productCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product subcategory -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productSubCategory | translate" placeholder="-"
                  [dropdownList]="productSubCategoryItems" [selectedItems]="selectedItemsProductSubCategory"
                  formName="productSubCategoryForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product family -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productFamily | translate" placeholder="-"
                  [dropdownList]="productFamilyItems" [selectedItems]="selectedItemsProductFamily"
                  formName="productFamilyForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product technical -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productTechnical | translate" placeholder="-"
                  [dropdownList]="productTechnicalItems" [selectedItems]="selectedItemsProductTechnical"
                  formName="productTechnicalForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row separator-row">

            <!-- Product Bouquet Business -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetBusiness | translate" placeholder="-"
                  [dropdownList]="bouquetBusinessItems" [selectedItems]="selectedItemsBouquetBusiness"
                  formName="bouquetBusinessForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product Bouquet LOBS -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].bouquetLobs | translate" placeholder="-"
                  [dropdownList]="bouquetLobsItems" [selectedItems]="selectedItemsBouquetLobs"
                  formName="bouquetLobsForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Product type -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].productType | translate" placeholder="-"
                  [dropdownList]="productTypeItems" [selectedItems]="selectedItemsProductType"
                  formName="productTypeForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

          <div class="filter-row big-row header-row">
            <!-- Other header -->
            <div class="filter-block col-9">
              <div class="filter">
                <app-info-header title="filtersPanel.others"></app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row">

            <!-- Currency -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].currency | translate" [dropdownId]="dropdownCurrency"
                  [items]="itemsCurrency"></app-dropdown>
              </div>
            </div>

            <!-- Payment methods -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].paymentMethods | translate" placeholder="-"
                  [dropdownList]="paymentMethodsItems" [selectedItems]="selectedItemsPaymentMethods"
                  formName="paymentMethodsForm">
                </app-dropdown-multiple>
              </div>
            </div>

            <!-- Payment forms -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown-multiple [title]="titles[0].paymentForms | translate" placeholder="-"
                  [dropdownList]="paymentFormsItems" [selectedItems]="selectedItemsPaymentForms"
                  formName="paymentFormsForm">
                </app-dropdown-multiple>
              </div>
            </div>

          </div>

        </div>

        <!-- Template SLAs 1 -->
        <div class="filters-3-columns" *ngIf="templateName == 'tpl-slas-1'">
          
          <div class="filter-row big-row header-row">
            <!-- Time header -->
            <div class="filter-block col-6">
              <div class="filter">
                <app-info-header title="filtersPanel.time"></app-info-header>
              </div>
            </div>
            <div class="filter-block col-3 ml-auto">
              <div class="filter">
                <app-info-header title="filtersPanel.others"></app-info-header>
              </div>
            </div>
          </div>

          <div class="filter-row big-row separator-row">
            <!-- Year -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].year | translate" [dropdownId]="dropdownYear" [items]="itemsYear">
                </app-dropdown>
              </div>
            </div>

            <!-- Month -->
            <div class="filter-block col-3">
              <div class="filter">
                <app-dropdown [title]="titles[0].month | translate" [dropdownId]="dropdownMonth" [items]="itemsMonth">
                </app-dropdown>
              </div>
            </div>

            <!-- Currency -->
            <div class="filter-block col-3 ml-auto">
              <div class="filter">
                <app-dropdown [title]="titles[0].currency | translate" [dropdownId]="dropdownCurrency"
                  [items]="itemsCurrency"></app-dropdown>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>
    <div class="bottom-section">

      <!-- <div class="row mb-2">
        <div class="col">
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-sm btn-outline-light mt-0 mx-1" (click)="changeLanguage('es')">ES</button>
            <button type="button" class="btn btn-sm btn-outline-light mt-0 mx-1" (click)="changeLanguage('pt')">PT</button>
          </div>
        </div>
      </div> -->

      <div class="row button-row ml-auto">
        <div class="col-6">
          <button type="button" class="btn btn-block btn-outline-light mt-0"
            (click)="cancelFilters()">{{ 'buttons.cancel' | translate }}</button>
        </div>
        <div class="col-6">
          <button type="button" class="btn btn-block btn-gradient mt-0"
            (click)="applyFilters()">{{ 'buttons.applyFilters' | translate }}</button>
        </div>
      </div>
    </div>

    <app-version-container></app-version-container>

  </div>
</div>

<div class="filters-bg-overlay" *ngIf="filtersOn == true" [@fadeInOut] (click)="cancelFilters()"></div>

<app-document-management-section-container>
    <p>File manager content</p>

    <!-- Drag and drop testing area. To suppress when content added -->
    <div class="dropzone" appDragAndDrop (files)="filesDropped($event)">
        <div class="text-wrapper">
            <div class="centered">Drop your file here!</div>
        </div>
    </div>
    
</app-document-management-section-container>
<component-loader [width]="" [height]="65" *ngIf="!loaded"></component-loader>

<velocimeter
    class="velocimeter-medium"
    [viewBox]="viewBox"
    [strokeWidth]="strokeWidth"
    [target]="target"
    [currentValue]="currentValue"
    [mainValue]="qualityClaim?.value"
    [secondValue]="qualityClaim?.target"
    [diff]="qualityClaim?.diff"
    [trend]="qualityClaim?.diff_trend"
    [hasInfo]="hasInfo"
    [hAlign]="hAlign"
    [vAlign]="vAlign"
    [barOrientation]="barOrientation"
    *ngIf="loaded">
</velocimeter>
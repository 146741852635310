import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'decimalThousandFormat' })
export class DecimalThousandPipe implements PipeTransform {

    // Transform method - String to number with decimal symbol replacement
    transform(data: any): string {
        if(!data)
            return "0";

        if (typeof data === 'string') {
            let input = data;
            if (data.trim()[data.length -1]=="%"){
                input = data.substr(0,data.length -1)
            }
            let value = input ? parseFloat(parseFloat(input.replace(/,/g, '')).toFixed(2)) : 0;
            let output = Intl.NumberFormat("de-DE").format(value);
            // if(data.trim()[data.length -1]=="%")
            //     output = output +"%";
            return output;
        } else {
            let value = data.toFixed(2);
            return Intl.NumberFormat("de-DE").format(value);
        }
    }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-mix-portfolio-gwp',
  templateUrl: './sales-mix-portfolio-gwp.component.html',
  styleUrls: ['./sales-mix-portfolio-gwp.component.scss']
})
export class SalesMixPortfolioGwpComponent implements OnInit {

  // Default constructor
  constructor() { }

  // OnInit
  ngOnInit(): void {
  }

}

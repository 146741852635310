import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-repeater',
  templateUrl: './icon-repeater.component.html',
  styleUrls: ['./icon-repeater.component.scss']
})
export class IconRepeaterComponent implements OnInit {
  @Input() itemsNumber: number = 10;
  @Input() activeNumber: number = 2;
  @Input() activatedImageSource: string = "assets/icons/men.svg";
  @Input() deactivatedImageSource: string = "assets/icons/men-solid.svg";
  @Input() imageWidth: string = "16";

  lines = Array;
  num: number = 19;

  constructor() { }

  ngOnInit(): void {
  }

  getImageStyles() {
    let styles = {
      'width.px': this.imageWidth ? this.imageWidth: '',
    }
    return styles;
  }

}

<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex">
            <span class="title section-card-title">{{ 'panels.charge' | translate }}</span>
            <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams"
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </div>    
        <app-option-tab [leftOption] = amount [rightOption] = receipts [graphName] = graphName class = "option-tab align-items-center"></app-option-tab>
    </div>

    <div class="mt-4 d-flex align-items-stretch">
        <div class="col-4 mt-2">
            <cancellation-charge-block-funnel></cancellation-charge-block-funnel>
        </div>
        <div class="col-8 pl-6">
            <cancellation-charge-block-graph></cancellation-charge-block-graph>
        </div>
    </div>

</app-widget>
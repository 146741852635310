<svg:g
  ngx-charts-bar
  *ngFor="let bar of bars; trackBy: trackBy"
  [width]="bar.width"
  [height]="bar.height"
  [x]="bar.x"
  [y]="bar.y"
  [fill]="bar.color"
  [data]="bar.data"
  [orientation]="'horizontal'"
  [roundEdges]="bar.roundEdges"
  [gradient]="gradient"
  [ariaLabel]="bar.ariaLabel"
  ngx-tooltip
  [tooltipDisabled]="tooltipDisabled"
  [tooltipPlacement]="tooltipPlacement"
  [tooltipType]="tooltipType"
  [tooltipTitle]="tooltipTemplate ? undefined : bar.tooltipText"
  [tooltipTemplate]="tooltipTemplate"
  [tooltipContext]="bar.data"
  [noBarWhenZero]="noBarWhenZero"
></svg:g>
<svg:g *ngIf="showDataLabel">
  <svg:g
    ngx-charts-bar-label
    *ngFor="let b of barsForDataLabels; let i = index; trackBy: trackDataLabelBy"
    [barX]="b.x"
    [barY]="b.y"
    [barWidth]="b.widthRight"
    [barHeight]="b.height"
    [value]="b.totalRight"
    [valueFormatting]="dataLabelFormatting"
    [orientation]="'horizontal'"
    (dimensionsChanged)="dataLabelWidthChanged.emit({ size: $event, index: i })"
  />
</svg:g>
<svg:g *ngIf="showDataLabel">
  <svg:g
    ngx-charts-bar-label
    *ngFor="let b of barsForDataLabels; let i = index; trackBy: trackDataLabelBy"
    [barX]="b.x - 10"
    [barY]="b.y"
    [barWidth]="b.widthLeft"
    [barHeight]="b.height"
    [value]="b.totalLeft"
    [valueFormatting]="dataLabelFormatting"
    [orientation]="'horizontal'"
    (dimensionsChanged)="dataLabelWidthChanged.emit({ size: $event, index: i })"
  />
</svg:g>
<div class="message-box-container" [ngClass]="{'flex-row': direction == 'row', 'flex-column': direction == 'column' || direction == ''}" [ngStyle]="getStyles()">
    <span 
        class="icon material-icons text-light d-inline-block" 
        *ngIf="icon">
            {{ icon }}
    </span>
    <span 
        class="message text-light" 
        [ngClass]="{'mt-1': icon, 'ml-2': direction == 'row'}"
        *ngIf="message">
            {{ message | translate }}
    </span>
</div>
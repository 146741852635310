<div class="line-charts-gen mt-2" style="width: 585px;">
    <div class="float-left">
    <component-loader *ngIf="!planGraphLoaded" [width]="235" [height]="200"></component-loader>
    <div class="plan-graph" *ngIf="planGraphLoaded">
        <app-difference-bar-label [width] = "planGraphUpperLabelWidth" [viewBox] = "planGraphViewBox2" [labelDiff] = "planGraphUpperLabels?.avgPlanDiffLabel"></app-difference-bar-label>
        <app-difference-bar-label [width] = "planGraphUpperLabelWidth" [viewBox] = "planGraphViewBox3" [labelDiff] = "planGraphUpperLabels?.planDiffLabel"></app-difference-bar-label>
        <app-difference-bar-label [width] = "planGraphUpperLabelWidth" [viewBox] = "planGraphViewBox1" [labelDiff] = "planGraphUpperLabels?.previousYearDiffNumber"></app-difference-bar-label>
        <ngx-charts-bar-vertical class="line-charts-gen only-border-bar-3 only-border-bar-4"
            [view]="planGraphView"
            [scheme]="planGraphColorScheme"
            [results]="planGraph"
            [gradient]="planGraphGradient"
            [xAxis]="planGraphShowXAxis"
            [yAxis]="planGraphShowYAxis"
            [legend]="planGraphShowLegend"
            [showXAxisLabel]="planGraphShowXAxisLabel"
            [showYAxisLabel]="planGraphShowYAxisLabel"
            [xAxisLabel]="planGraphXAxisLabel"
            [yAxisLabel]="planGraphYAxisLabel"
            [dataLabelFormatting]="formatDataLabel"
            [roundDomains]="planGraphRoundDomains"
            [showDataLabel]="planGraphShowDataLabel"
            [barPadding]="planGraphBarPadding"
            [customColors] = "customColors"
            [roundEdges] = "planGraphRoundEdges"
            [rotateXAxisTicks] = "planGraphRotateXAxisTicks">
            <svg:g height=200 width=300>
                <line x1=0 x2=300 y1=40 y2=100 style="stroke:white; stroke-width:1"></line>
            </svg:g>
        </ngx-charts-bar-vertical>
    </div>
</div>
    <div class="float-right">
        <component-loader *ngIf="!evolutionGraphLoaded" [width]="340" [height]="200"></component-loader>
        <app-wok-graph *ngIf="evolutionGraphLoaded"
            [view]="view"
            [scheme]="colorScheme"
            [schemeType]="schemeType"
            [results]="evolutionGraph"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [xAxisTicks]="xAxisTicks"
            [animations]="animations"
            [showDataLabel] = "showDataLabel"
            [dataLabelFormatting] = "formatDataLabelString"
            [noBarWhenZero] = "noBarWhenZero"
            [labelDiff] = "planGraphUpperLabels?.previousYearDiffLabel"
            [viewBox] = "viewBox"
            [textLabel] = "textLabel"
            [rotateXAxisTicks] = "rotateXAxisTicks">
        </app-wok-graph>
    </div>
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { trim } from 'lodash';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COLORS } from 'src/app/shared/globals/globals';
import { CancellationRetentionActionEntity } from 'src/app/shared/models/cancellation-retention-action';
import { ExcelFileInfo } from 'src/app/shared/models/excel-file-info';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'retention-per-action-graphic',
  templateUrl: './retention-per-action-graphic.component.html',
  styleUrls: ['./retention-per-action-graphic.component.scss']
})
export class RetentionPerActionGraphicComponent implements OnInit, OnDestroy   {

  objectKeys = Object.keys;

  view: any[] = [1200, 180];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';
  animations: boolean = true;
  barPadding: number = 65;
  customColors: any = [];
  showRightYAxis: boolean = false;
  legendTitle: string = '';
  legendPosition: string = '';
  schemeType: string = 'ordinal';
  yLeftAxisScaleFactor: string = '';
  yRightAxisScaleFactor: string = '';
  showGridLines: boolean = true;
  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  tooltipDisabled: boolean = false;
  roundDomains: boolean = false;
  noBarWhenZero: boolean = false;
  lineChartScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkBlue]
  };
  comboBarScheme = {
    selectable: true,
    group: 'ordinal',
    domain: ['#5AA454', '#C7B42C', '#AAAAAA']
  };
  rotateXAxisTicks: boolean = false;
  hideHorizontalLine: boolean = true;
  showValuesAndPercentageInTooltip: boolean = true;

  verticalGraph: any = [];
  horizontalGraph: any = [];
  dimensionValues: {}
  coloursValues: {}

  policyVerticalGraphData: any = [];
  policyHorizontalGraphData: any = [];
  policyDimensionValues: {}
  policyColoursValues: {}

  bonusVerticalGraphData: any = [];
  bonusHorizontalGraphData: any = [];
  bonusDimensionValues: {}
  bonusColoursValues: {}

  loaded: boolean = false;

  // Subscription
  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();

  // Variables for saving the filter option
  optionTabPolicy = 'panels.policy';
  optionTabBonus = 'panels.bonus';
  actualOption: string = 'panels.bonus';
  graphName: string = 'retentionAction';

  monthNames:string;
  totalName: string;

  filtersValues: any;

  dateName: string = '';

  colorList: any = [COLORS.lightBlue, COLORS.pink, COLORS.lightPurple, COLORS.lightOrange, COLORS.lightestBlue, COLORS.darkGreen,
    COLORS.lightGreen, COLORS.darkYellow, COLORS.darkBlue,COLORS.darkestGreen,COLORS.lightGreen];
  colorListCSS: any = ['light-blue', 'pink', 'light-purple', 'light-orange', 'lightest-blue', 'dark-green',
    'light-green', 'dark-yellow', 'dark-blue','darkest-green','light-green'];

  // Variables to export data
  data_total: any[] = [];
  data_sheet_names: any;
  data_filter_details: any;

  constructor(
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private eventsService: EventsService,
    private translateService: TranslateService,
    private excelService: ExcelService
  ) { }

  ngOnInit(): void {
    this.subscription = this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        const monthNames = this.translateService.get('abbreviatedMonthNames');
        const totalName = this.translateService.get('labels.total');
        const dateName = this.translateService.get('labels.date');

        forkJoin([monthNames, totalName, dateName])
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            {
              next: results => {
                this.monthNames = results[0];
                this.totalName = results[1];
                this.dateName = results[2];
              },
              complete: () => {
                if(data.length !== 0) {
                  this.filtersValues = data;
                  this.resetVariables();
                  if(this.actualOption == this.optionTabBonus)
                    this.retrieveGraphData('p', data); // (p -> premium)
                  else if(this.actualOption == this.optionTabPolicy)
                    this.retrieveGraphData('c', data);
                }
              }
            }
          );
      });

      this.eventsService.policiesBonusOption$.subscribe(
        data => {
          const select = data[0];
          const name = data[1];
          if ((select === this.optionTabBonus || select === this.optionTabPolicy)
              && (data != this.actualOption) && name === this.graphName){
            this.actualOption = select;
            if(this.actualOption == this.optionTabBonus){
              if (this.bonusVerticalGraphData.length === 0) {
                this.retrieveGraphData('p', this.filtersValues); // (c -> certificate)
                this.getInfoForExcel();
              } else {
                this.assignDataToPrint();
                this.getInfoForExcel();
              }
            }else if(this.actualOption == this.optionTabPolicy){
              if (this.policyVerticalGraphData.length === 0) {
                this.retrieveGraphData('c', this.filtersValues); // (c -> certificate)
                this.getInfoForExcel();
              } else {
                this.assignDataToPrint();
                this.getInfoForExcel();
              }
            }
          }
        }
      );
  }

  private retrieveGraphData(type_data:string, filtersValues: any) {
    this.loaded = false;
    this.subscription = this.apimstService.getCancellationRetentionAction(type_data,filtersValues).subscribe(
      data => {
        const graphData: Array<CancellationRetentionActionEntity> = data;
        const verticalGraphData: any = [];
        const horizontalGraphData: any = [];
        const totalValues: any = [];
        const dimensionValuesData = {}
        const coloursValuesData = {}
        const monthValuesData = {}

        // Group distinct dimension values and colours assign
        // Group months and its dimension values
        if (graphData.length !== 0) {
          this.data_filter_details = graphData[0].filters;
        }

        for (let group of graphData) {
          if(!dimensionValuesData[group.name]){
            coloursValuesData[group.name]=this.colorList[Object.keys(dimensionValuesData).length%this.colorList.length];
            dimensionValuesData[group.name]=this.colorListCSS[Object.keys(dimensionValuesData).length%this.colorListCSS.length];
          }
          if(!monthValuesData[group.year+'-'+group.month]){
            monthValuesData[group.year+'-'+group.month]=[];
          }
          monthValuesData[group.year+'-'+group.month][group.name] = group.rate;
        }

        for(var month in monthValuesData){
          let series =  [];
          const values = monthValuesData[month];

          let total = 0;
          for(var key in dimensionValuesData)
          {
            const value = values[key];
            series.push({'name':key,'value':(value || trim(value).length>0) ? parseFloat(value.replace(/,/g, '')) : 0})
            total += (value || trim(value).length>0) ? parseFloat(value.replace(/,/g, '')) : 0;
          }

          const dateParts=month.split('-');
          verticalGraphData.push(
             {
               name: this.monthNames[dateParts[1]] + ' ' + dateParts[0].slice(2,4),
               series: series

             }
           );

          totalValues.push(
            {
              name: this.monthNames[dateParts[1]] + ' ' + dateParts[0].slice(2,4),
              value: total
            }
          );
        }

        horizontalGraphData.push(
          {
            name: this.totalName,
            series: totalValues
          }
        );


        // Bonus data (p -> premium)
        if (type_data === 'p') {
          this.bonusVerticalGraphData = verticalGraphData;
          this.bonusHorizontalGraphData = horizontalGraphData;
          this.bonusColoursValues = coloursValuesData;
          this.bonusDimensionValues = dimensionValuesData;
        } else if (type_data === 'c') { // Policy data (c -> certificate)
          this.policyVerticalGraphData = verticalGraphData;
          this.policyHorizontalGraphData = horizontalGraphData;
          this.policyColoursValues = coloursValuesData;
          this.policyDimensionValues = dimensionValuesData;
        }
        this.loaded = true;

        this.assignDataToPrint();

        this.getInfoForExcel();

      },
      error => console.log("An error ocurred while retrieving portfolio churn graph data: " + error)
    );
  }

  private assignDataToPrint() {
    if (this.actualOption === this.optionTabPolicy) {
      this.verticalGraph = this.policyVerticalGraphData;
      this.horizontalGraph = this.policyHorizontalGraphData;
      this.coloursValues = this.policyColoursValues;
      this.dimensionValues = this.policyDimensionValues;
    } else if (this.actualOption === this.optionTabBonus) {
      this.verticalGraph = this.bonusVerticalGraphData;
      this.horizontalGraph = this.bonusHorizontalGraphData;
      this.coloursValues = this.bonusColoursValues;
      this.dimensionValues = this.bonusDimensionValues;
    }
    this.assignColorBar();
  }

  onSelect(event) {
    console.log(event);
  }

  // Method to assign the colors to the graph bars
  private assignColorBar() {
    this.customColors = []
    for(var key in this.coloursValues){
      this.customColors.push({'name':key,'value': this.coloursValues[key]})
    }

    this.customColors.push(
      {'name': this.totalName, 'value': COLORS.black},
      {'name': this.dateName, 'value': COLORS.black}
    );

  }

  // Method to format the right y axis labels
  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value);
  }

  // Method to reset the data storage variables
  private resetVariables() {
    this.verticalGraph = [];
    this.horizontalGraph = [];
    this.dimensionValues = {};
    this.coloursValues = {};
    this.bonusVerticalGraphData = [];
    this.bonusHorizontalGraphData = [];
    this.bonusDimensionValues = {};
    this.bonusColoursValues = {};
    this.policyVerticalGraphData = [];
    this.policyHorizontalGraphData = [];
    this.policyDimensionValues = {};
    this.policyColoursValues = {};
  }



  getInfoForExcel(): void {
    this.data_total = [];
    const title = this.translateService.get('panels.retentionPerAction');
    const option = this.translateService.get(this.actualOption);

    let titleLabel = '';
    let optionLabel = '';

    forkJoin([title, option])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(result => {
      titleLabel = result[0];
      optionLabel = result[1];
    });

    this.getDataToExport();
    this.data_sheet_names = [titleLabel + ' - ' + optionLabel];

    let excelInfo: ExcelFileInfo = {json: [this.data_total.flat()], headers: this.data_sheet_names, excelFileName: 'retention_per_action_chart', filtersValues: this.data_filter_details, filters: true};
    this.eventsService.retentionPerActionExcelInfoLoaded$.next(excelInfo);

  }

   // Method to export the graph data to an Excel file
   exportAsXLSX() {
    this.getInfoForExcel();
    this.excelService.exportAsExcelFile([this.data_total.flat()], this.data_sheet_names,  'retention_per_action_chart', this.data_filter_details);
  }

  // Method to retrieve the data to export
  private getDataToExport() {
    const headers = ['', this.verticalGraph.map(v => v.name)];
    const groupedValues = this.verticalGraph.map(v => v.series.map(i => i.value));
    const values: any[] = [];

    for (let _i = 0; _i < this.objectKeys(this.dimensionValues).length; _i++) {
      const array = [this.verticalGraph[0].series[_i].name];
      for (let group of groupedValues) {
        array.push(group[_i]);
      }
      values.push(array);
    }

    this.data_total.push([headers.flat()], values);
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

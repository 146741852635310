import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AtlasHelperService } from '../../services/api-atlas/atlas-helper.service';
import { CurrencyService } from '../../services/currency/currency.service';
import { EventsService } from '../../services/events/events.service';
import { FiltersService } from '../../services/filters/filters.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { environment } from '../../../../environments/environment';
import { I18nService } from '../../services/i18n/i18n.service';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import _ from 'lodash';

@Component({
  selector: 'app-hamburguer-menu',
  templateUrl: './hamburguer-menu.component.html',
  styleUrls: ['./hamburguer-menu.component.scss']
})
export class HamburguerMenuComponent implements OnInit {

  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.dashboard;

  title: string;
  subscription: Subscription;

  monthDate: any;
  yearDate: any;
  currencyValues: any;
  currencyIso: string;
  currencySymbol: string;
  currencyValue: string;
  countryName: string;

  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "";

  monthNames = [
    "monthsNames.january",
    "monthsNames.february",
    "monthsNames.march",
    "monthsNames.april",
    "monthsNames.may",
    "monthsNames.june",
    "monthsNames.july",
    "monthsNames.august",
    "monthsNames.september",
    "monthsNames.october",
    "monthsNames.november",
    "monthsNames.december"
  ];

  openMenu() {
    this.events.showMenu$.emit(true);
  }

  constructor(
    private events: EventsService,
    private filtersService: FiltersService,
    private currencyService: CurrencyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private atlasHelper: AtlasHelperService,
    private i18n: I18nService
  ) { }

  ngOnInit(): void {
    this.subscribeToHeaderName();
    this.subscribeToUserInfo();
    this.subscribeToCountryName();
    this.subscribeToCurrencyInfo();
    this.subscribeToFiltersValues();
    this.subscribeToRoute();
    this.subscribeToActiveLanguage();
  }

  getUserCurrency(userInfo: any): void {
    this.currencyIso = userInfo['currency_iso'];
    this.currencySymbol = userInfo['currency_symbol'];
    this.currencyService.currencyInfo$.next({ ...this.currencyService.currencyInfo$.value, iso: this.currencyIso });
    this.currencyService.currencyInfo$.next({ ...this.currencyService.currencyInfo$.value, symbol: this.currencySymbol });
  }

  setInitCountryValue(userInfo: any): void {
    if (userInfo.hasOwnProperty('countries') && userInfo['countries'][0]) {
      this.countryName = userInfo['countries'][0].desc;
    }
  }

  subscribeToActiveLanguage() {
    this.subscription = this.i18n.language$.subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  subscribeToHeaderName(): void {
    this.subscription = this.events.headerTitle$
      .subscribe(
        data => this.title = data
      );
  }

  subscribeToUserInfo(): void {
    this.events.loginRedirectionSubject$
      .subscribe(
        data => {
          this.events.userInfoLoaded$
            .subscribe({
              next: userInfo => {
                this.setInitCountryValue(userInfo);
                this.getUserCurrency(userInfo);
              }
            })
        }
      )
  }

  subscribeToCountryName(): void {
    this.filtersService.selectedCountryName$
      .subscribe(
        countryName => {
          this.countryName = countryName;
        }
      )
  }

  subscribeToCurrencyInfo(): void {
    this.filtersService.currencyInfoSent$
      .subscribe({
        next: currencyInfo => {
          this.currencyIso = currencyInfo['iso'];
          this.currencySymbol = currencyInfo['symbol'];
          this.currencyService.currencyInfo$.next({ ...this.currencyService.currencyInfo$.value, iso: this.currencyIso });
          this.currencyService.currencyInfo$.next({ ...this.currencyService.currencyInfo$.value, symbol: this.currencySymbol });
        }
      })
  }

  subscribeToFiltersValues(): void {
    this.filtersService.filtersValues$
      .subscribe(
        values => {
          let monthValue = values.filter(function (item) { return item.name === "month"; })[0]['value'];
          let yearValue = values.filter(function (item) { return item.name === "year"; })[0]['value'];
          this.monthDate = this.monthNames[monthValue - 1];
          this.yearDate = yearValue;
        }
      )
  }

  subscribeToRoute(): void {
    this.navigationService.pageId$
      .subscribe(
        id => {
          this.pageId = id;
          this.updateAtlasInfoParams(id, this.activeLang);
        }
      );
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

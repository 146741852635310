<div class="pt-2 pb-0 overflow-hidden d-flex justify-content-between">

    <component-loader *ngIf="!loadedIndicators" [width]="400" [height]="160"></component-loader>

    <div class="pie-graph-element" *ngIf="loadedIndicators">
        <div class="left-element">
            <label class="main-checkbox d-block">{{ 'panels.creditRelated' | translate }}
                <input #CRcheckbox type="checkbox" checked="checked" (click)="checkboxSelected()">
                <span class="checkmark"></span>
            </label>
            <div *ngIf = "CRcheckboxChecked">
                <span class="main-checkbox product-percentage mb-1 d-inline-block">
                    {{CRPercentage}}%
                </span>
                <app-small-kpi [mainValue] = "CRCertificateProductToPrint?.current" [percentage] = "CRCertificateProductToPrint?.diff" [percentagePrevious] = "percentagePrevious" [trend] = "CRCertificateProductToPrint?.diff_trend" class="kpi"></app-small-kpi>
                <app-small-kpi [mainValue] = "CRPremiumProductToPrint?.current" [percentage] = "CRPremiumProductToPrint?.diff" [percentagePrevious] = "percentagePrevious" [mainValueUnit] = "currencySymbol" [trend] = "CRPremiumProductToPrint?.diff_trend" class="kpi"></app-small-kpi>
            </div>
        </div>
    </div>

    <div class="pie-graph-element" *ngIf="loadedIndicators">
        <div class="right-element">
            <label class="main-checkbox d-block">{{ 'panels.openMarket' | translate }}
                <input #OMcheckbox type="checkbox" checked="checked" (click)="checkboxSelected()">
                <span class="checkmark"></span>
            </label>
            <div *ngIf = "OMcheckboxChecked">
                <span class="main-checkbox product-percentage mb-1 d-inline-block">
                    {{OMPercentage}}%
                </span>
                <app-small-kpi [mainValue] = OMCertificateProductToPrint?.current [percentage] = OMCertificateProductToPrint?.diff [percentagePrevious] = "percentagePrevious" [trend] = OMCertificateProductToPrint?.diff_trend class="kpi"></app-small-kpi>
                <app-small-kpi [mainValue] = OMPremiumProductToPrint?.current [percentage] = OMPremiumProductToPrint?.diff [percentagePrevious] = "percentagePrevious" [mainValueUnit] = "currencySymbol" [trend] = OMPremiumProductToPrint?.diff_trend class="kpi"></app-small-kpi>
            </div>
        </div>
    </div>

    <component-loader *ngIf="!loadedGraph" [width]="200" [height]="160"></component-loader>

    <div class="pie-graph-element pie-graph-gen" *ngIf="loadedGraph">
        <div class="center-element d-flex align-items-center">
            <ngx-charts-pie-chart 
                [view]="view" 
                [scheme]="colorScheme" 
                [results]="pieGraphData" 
                [gradient]="gradient"
                [legend]="showLegend" 
                [legendPosition]="legendPosition" 
                [legendTitle]="legendTitle"
                [labels]="showLabels" 
                [doughnut]="isDoughnut"
                [arcWidth]="arcWidth"
                [tooltipDisabled]="tooltipDisabled"
                [animations]="animations"
                [labelFormatting]="labelFormatting.bind(this, pieGraphData)"
                (activate)="activate($event)">
                <ng-template #tooltipTemplate let-model="model">
                    <div class="pie-tooltip">
                        <span class="pie-tooltip-title d-block">{{ tooltipTitle }}</span>
                        <span class="pie-tooltip-data d-block">{{ tooltipNumber }}</span>
                    </div>
                </ng-template>
            </ngx-charts-pie-chart>
            <div class="fullscreen-icon-container" *ngIf="showPieDialogButton">
                <span class="material-icons fullscreen-icon text-light" (click)="openPieChartDialog()">fullscreen</span>
            </div>
            <!-- <div class="mt-2 d-flex flex-column justify-content-center align-items-start">
                <app-legend class="mr-2" name="labels.net" color="light-blue" type="round" gradient="v-gradient"></app-legend>
                <app-legend class="mr-2" name="labels.cc" color="light-green" type="round" gradient="h-gradient"></app-legend>
                <app-legend class="mr-2" name="labels.digital" color="light-orange" type="round" gradient="h-gradient"></app-legend>
                <app-legend class="mr-2" name="labels.atm" color="pink" type="round" gradient="h-gradient"></app-legend>
            </div> -->
        </div>
    </div>

</div>
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';
import { checkboxes } from './checkboxes-value';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { CancellationHarvest } from 'src/app/shared/models/cancellation-harvest';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { trim } from 'lodash';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';

@Component({
  selector: 'app-churn-rate-per-crop-graphic',
  templateUrl: './churn-rate-per-crop-graphic.component.html',
  styleUrls: ['./churn-rate-per-crop-graphic.component.scss']
})
export class ChurnRatePerCropGraphicComponent implements OnInit, OnDestroy {

  view: any[] = [580, 110];

  unsubscribe$: Subject<any> = new Subject<any>();
  monthNames: any;
  planName: string;
  month: number;
  year: number;
  filtersValues: any;
  bonusOption: string = 'panels.bonus';
  policyOption: string = 'panels.policy';
  bonusPolicySelectedOption: string = 'panels.bonus';
  loaded: boolean = false;
  graphName: string = 'cancellationHarvest';

  checkboxes: any;
  checkedCheckboxes: any = [];
  premiumHarvestGraph: any = [];
  certificateHarvestGraph: any = [];
  harvestGraphToPrint: any = [];
  cancellationHarvestData: any;
  cancellationHarvestVerticalData: any;

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';
  animations: boolean = false;
  barPadding: number = 30;
  showGridLines: boolean = true;
  maxYAxisTickLength: number = 4;
  noBarWhenZero: boolean = false;
  rotateXAxisTicks: boolean = false;
  tooltipUnit: string = "%";

  colorScheme = {
    domain: [COLORS.lightBlue]
  };

  pastMonth: string;
  actualMonth: string;
  initialCheckedCheckboxes = [0, 1, 2, 3, 4];

  actualPremiumBar: any = [];
  previousPremiumBar: any = [];
  actualCertificateBar: any = [];
  previousCertificateBar: any = [];
  actualPremiumTotalBar: number = 0;
  actualCertificateTotalBar: number = 0;
  previousPremiumTotalBar: number = 0;
  previousCertificateTotalBar: number = 0;

  previousHarvestBarToPrint: any = [];
  actualHarvesBarToPrint: any = [];

  // Variables for Excel exportation
  data_total: any[] = [];
  data_sheet_names: any;
  data_filter_details: any;

  @Input() excelExportEvent;
  @Output() loadedGraph = new EventEmitter<boolean>();

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private translateService: TranslateService,
    private eventsService: EventsService,
    private excelService: ExcelService
  ) {
    Object.assign(this, { checkboxes });
  }

  ngOnInit(): void {

    this.initCheckboxes();

    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.translateService.get('abbreviatedMonthNames')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              {
                next: data => this.monthNames = data,
                complete: () => {
                  if (data.length !== 0) {
                    this.filtersValues = data;
                    this.month = this.filtersValues.filter(d => d.name === 'month')[0].value;
                    this.year = this.filtersValues.filter(d => d.name === 'year')[0].value;
                    // this.checkedCheckboxes = [];
                    // for (let value of this.initialCheckedCheckboxes) {
                    //   this.checkedCheckboxes.push(value);
                    // }
                    this.retrieveHarvestGraph(this.filtersValues, this.checkedCheckboxes);
                  }
                }
              }
            );
        }
      );

    this.eventsService.policiesBonusOption$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const option = data[0];
          const name = data[1];
          if ((option === this.policyOption || option === this.bonusOption) && name === this.graphName) {
            this.bonusPolicySelectedOption = option;
            this.assignHarvestDataToPrint();
          }
        }
      );

    this.excelExportEvent
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data) {
            this.exportDataToExcel();
          }
        }
      );
  }

  initCheckboxes() {
    this.checkedCheckboxes = [];
    for (let value of this.initialCheckedCheckboxes) {
      this.checkedCheckboxes.push(value);
    }

    for (let check of checkboxes) {
      check.checked = false;
    }

    for( let initialCheck of this.initialCheckedCheckboxes ) {
      checkboxes.filter(c => c.id === initialCheck)[0].checked = true;
    }
  }

  // Method to retrieve cancellation haverst graph data
  private retrieveHarvestGraph(filtersValues: any, checkedCheckboxes: any) {
    this.loaded = false;

    const verticalGraphRequest = this.apimstService.getCancellationCropChurnRate(filtersValues, checkedCheckboxes);
    const horizontalGraphRequest = this.apimstService.getCancellationCropChurnRate(filtersValues, checkedCheckboxes, ["m"]);

    forkJoin([verticalGraphRequest, horizontalGraphRequest])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: data => {
          this.cancellationHarvestData = data[0];
          this.cancellationHarvestVerticalData = data[1];
          this.premiumHarvestGraph = [];
          this.certificateHarvestGraph = [];

          for (let month of this.cancellationHarvestData) {
            this.premiumHarvestGraph.push(
              {
                name: this.monthNames[month.month] + ' ' + month.year.slice(2, 4),
                value: parseFloat(month.details[0].premium).toFixed(2)
              }
            );
            this.certificateHarvestGraph.push(
              {
                name: this.monthNames[month.month] + ' ' + month.year.slice(2, 4),
                value: parseFloat(month.details[0].certificate).toFixed(2)
              }
            );
          }

          if (this.cancellationHarvestData.length !== 0) {
            this.data_filter_details = this.cancellationHarvestData[0].filters;
          }

          this.pastMonth = this.monthNames[this.month] + ' ' + (this.year - 1);
          this.actualMonth = this.monthNames[this.month] + ' ' + this.year;

          this.calculatePreviousActualBar();
          this.assignHarvestDataToPrint();

          this.loaded = true;
          this.loadedGraph.emit(true);
        },
        error: error => console.log("An error ocurred while retrieving cancellation harvest graph data " + error)
      })
  }

  // Method to calculate the graph data when checkboxes clicked
  private calculateGraphicData(monthChecked: string, operation: number) {
    this.harvestGraphToPrint = [];
    for (let month of this.cancellationHarvestData) {

      const selectedData = month.details.filter(v => v.id === monthChecked);
      let premiumValue = 0;
      let certificateValue = 0;
      if (selectedData.length !== 0) {
        premiumValue = ((selectedData[0].premium || trim(selectedData[0].premium).length > 0) ? parseFloat(selectedData[0].premium.replace(/,/g, '')) : 0);
        certificateValue = ((selectedData[0].certificate || trim(selectedData[0].certificate).length > 0) ? parseFloat(selectedData[0].certificate.replace(/,/g, '')) : 0);
      }

      let previousPremiumValue = this.premiumHarvestGraph.filter(v => v.name === this.monthNames[month.month] + ' ' + month.year.slice(2, 4));
      let previousCertificateValue = this.certificateHarvestGraph.filter(v => v.name === this.monthNames[month.month] + ' ' + month.year.slice(2, 4));

      operation > 0 ? previousPremiumValue[0].value = parseFloat((previousPremiumValue[0].value + premiumValue).toFixed(2)) : previousPremiumValue[0].value = parseFloat((previousPremiumValue[0].value - premiumValue).toFixed(2));
      operation > 0 ? previousCertificateValue[0].value = parseFloat((previousCertificateValue[0].value + certificateValue).toFixed(2)) : previousCertificateValue[0].value = parseFloat((previousCertificateValue[0].value - certificateValue).toFixed(2));

    }

    this.calculatePreviousActualBar();
    this.assignHarvestDataToPrint();
  }

  // Method to calculate the previous/actual bars
  private calculatePreviousActualBar() {

    this.actualPremiumBar = [];
    this.previousPremiumBar = [];
    this.actualCertificateBar = [];
    this.previousCertificateBar = [];
    this.actualPremiumTotalBar = 0;
    this.actualCertificateTotalBar = 0;
    this.previousPremiumTotalBar = 0;
    this.previousCertificateTotalBar = 0;

    if (this.cancellationHarvestVerticalData.length !== 0) {
      let pastMonthData = [];
      let actualMonthData = [];

      if (this.cancellationHarvestVerticalData[0].month === this.month && this.cancellationHarvestVerticalData[0].year == (this.year - 1)) {
        pastMonthData = this.cancellationHarvestVerticalData[0].details;
      }

      if (this.cancellationHarvestVerticalData[this.cancellationHarvestVerticalData.length - 1].month === this.month && this.cancellationHarvestVerticalData[this.cancellationHarvestVerticalData.length - 1].year === this.year) {
        actualMonthData = this.cancellationHarvestVerticalData[this.cancellationHarvestVerticalData.length - 1].details;
      }

      if (this.checkedCheckboxes.length === 0) {
        pastMonthData = pastMonthData.filter(v => v.id >= 0);
        actualMonthData = actualMonthData.filter(v => v.id >= 0);
        this.previousPremiumTotalBar = pastMonthData.map(v => v.premium).reduce((x, y) => parseFloat(x) + parseFloat(y));
        this.previousCertificateTotalBar = pastMonthData.map(v => v.certificate).reduce((x, y) => parseFloat(x) + parseFloat(y));
        this.actualPremiumTotalBar = actualMonthData.map(v => v.premium).reduce((x, y) => parseFloat(x) + parseFloat(y));
        this.actualCertificateTotalBar = actualMonthData.map(v => v.certificate).reduce((x, y) => parseFloat(x) + parseFloat(y));

        for (let item of pastMonthData) {
          this.previousPremiumBar.push(
            {
              name: item.id,
              color: COLORS['m' + parseInt(item.id)],
              percentage: this.previousPremiumTotalBar > 0 ? parseFloat(item.premium) / this.previousPremiumTotalBar * 100 : 0
            }
          );

          this.previousCertificateBar.push(
            {
              name: item.id,
              color: COLORS['m' + parseInt(item.id)],
              percentage: this.previousCertificateTotalBar > 0 ? parseFloat(item.certificate) / this.previousCertificateTotalBar * 100 : 0
            }
          );
        }

        for (let item of actualMonthData) {
          this.actualPremiumBar.push(
            {
              name: item.id,
              color: COLORS['m' + parseInt(item.id)],
              percentage: this.actualPremiumTotalBar ? parseFloat(item.premium) / this.actualPremiumTotalBar * 100 : 0
            }
          );

          this.actualCertificateBar.push(
            {
              name: item.id,
              color: COLORS['m' + parseInt(item.id)],
              percentage: this.actualCertificateTotalBar ? parseFloat(item.certificate) / this.actualCertificateTotalBar * 100 : 0
            }
          );
        }
      } else {
        for (let checked of this.checkedCheckboxes) {
          let hasPreviousData = pastMonthData.filter(v => parseInt(v.id) === checked);
          if (hasPreviousData.length !== 0) {
            this.previousPremiumTotalBar += parseFloat(hasPreviousData[0].premium);
            this.previousCertificateTotalBar += parseFloat(hasPreviousData[0].certificate);
          }

          let hasActualData = actualMonthData.filter(v => parseInt(v.id) === checked);
          if (hasActualData.length !== 0) {
            this.actualPremiumTotalBar += parseFloat(hasActualData[0].premium);
            this.actualCertificateTotalBar += parseFloat(hasActualData[0].certificate);
          };
        }

        for (let checked of this.checkedCheckboxes) {
          let hasPreviousData = pastMonthData.filter(v => parseInt(v.id) === checked);
          if (hasPreviousData.length !== 0) {
            this.previousPremiumBar.push(
              {
                name: hasPreviousData[0].id,
                color: COLORS['m' + parseInt(hasPreviousData[0].id)],
                percentage: this.previousPremiumTotalBar > 0 ? parseFloat(hasPreviousData[0].premium) / this.previousPremiumTotalBar * 100 : 0
              }
            );


            this.previousCertificateBar.push(
              {
                name: hasPreviousData[0].id,
                color: COLORS['m' + parseInt(hasPreviousData[0].id)],
                percentage: this.previousCertificateTotalBar > 0 ? parseFloat(hasPreviousData[0].certificate) / this.previousCertificateTotalBar * 100 : 0
              }
            );

          }

          let hasActualData = actualMonthData.filter(v => parseInt(v.id) === checked);
          if (hasActualData.length !== 0) {
            this.actualPremiumBar.push(
              {
                name: hasActualData[0].id,
                color: COLORS['m' + parseInt(hasActualData[0].id)],
                percentage: this.actualPremiumTotalBar ? parseFloat(hasActualData[0].premium) / this.actualPremiumTotalBar * 100 : 0
              }
            );

            this.actualCertificateBar.push(
              {
                name: hasActualData[0].id,
                color: COLORS['m' + parseInt(hasActualData[0].id)],
                percentage: this.actualCertificateTotalBar ? parseFloat(hasActualData[0].certificate) / this.actualCertificateTotalBar * 100 : 0
              }
            );
          };
        }
      }
    }

  }

  // Method to assign data to print according to Bonus/Policy selected option
  private assignHarvestDataToPrint() {
    if (this.bonusPolicySelectedOption === this.bonusOption) {
      this.harvestGraphToPrint = [...this.premiumHarvestGraph];
      this.previousHarvestBarToPrint = this.previousPremiumBar;
      this.actualHarvesBarToPrint = this.actualPremiumBar;
    } else if (this.bonusPolicySelectedOption === this.policyOption) {
      this.harvestGraphToPrint = [...this.certificateHarvestGraph];
      this.previousHarvestBarToPrint = this.previousCertificateBar;
      this.actualHarvesBarToPrint = this.actualCertificateBar;
    }
  }

  // Method for updating checkboxes list
  updateCheckedCheckboxes(id: number) {
    this.harvestGraphToPrint = [];
    const index = this.checkedCheckboxes.indexOf(id);
    if (index !== -1) {
      this.checkedCheckboxes.splice(index, 1);
      this.checkedCheckboxes.sort(function (a, b) {
        return parseInt(a) - parseInt(b);
      });
      checkboxes.filter(c => c.id == id)[0].checked = false;
    } else {
      this.checkedCheckboxes.push(id);
      this.checkedCheckboxes.sort(function (a, b) {
        return parseInt(a) - parseInt(b);
      });
      checkboxes.filter(c => c.id == id)[0].checked = true;
    }
    this.retrieveHarvestGraph(this.filtersValues, this.checkedCheckboxes)
  }

  // Method to format the right y axis labels
  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value) + '%';
  }

  formatNumber(num: number) {
    const formatedNumber = Intl.NumberFormat("de-DE").format(num);
    return formatedNumber;
  }

  // Method to retrieve the data to export
  private getDataToExport() {
    const headers = this.harvestGraphToPrint.map(v => v.name);
    const values = this.harvestGraphToPrint.map(v => v.value);

    this.data_total.push(headers, values);
  }

  // Method to export the graph data to an Excel file
  private exportDataToExcel() {
    this.data_total = [];
    const title = this.translateService.get('panels.churnRatePerCrop');
    const option = this.translateService.get(this.bonusPolicySelectedOption);
    const months = this.translateService.get('labels.selectedMonths');

    let titleLabel = '';
    let optionLabel = '';
    let monthsSelectedLabel = '';

    forkJoin([title, option, months])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        titleLabel = result[0];
        optionLabel = result[1];
        monthsSelectedLabel = result[2];
      });

    const selectedMonths = this.checkedCheckboxes.map(v => {
      return 'M' + v;
    });

    const header = [monthsSelectedLabel, selectedMonths];

    this.data_total.push(header.flat(), []);

    this.getDataToExport();
    this.data_sheet_names = [titleLabel + ' - ' + optionLabel];
    this.excelService.exportAsExcelFile([this.data_total], this.data_sheet_names, 'churn_rate_per_crop_chart', this.data_filter_details);
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-kpi-home',
  templateUrl: './kpi-home.component.html',
  styleUrls: ['./kpi-home.component.scss']
})
export class KpiHomeComponent implements OnInit {

  @Input() item: any;

  constructor() { }

  ngOnInit(): void {
  }

}

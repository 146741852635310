import { Component, Input, OnInit } from '@angular/core';
import { EventsService } from '../../services/events/events.service';

@Component({
  selector: 'app-color-line-tag',
  templateUrl: './color-line-tag.component.html',
  styleUrls: ['./color-line-tag.component.scss']
})
export class ColorLineTagComponent implements OnInit {
  @Input() paddingTop: string = "";
  @Input() paddingRight: string = "";
  @Input() paddingBottom: string = "";
  @Input() paddingLeft: string = "";
  @Input() title: string = "";
  @Input() lineWidth: string = "5";
  @Input() lineColor: string = "transparent";
  @Input() isActive: boolean = false;
  @Input() activeBgcolor: string = "#dbe9f2";
  @Input() cursor: boolean = false;
  @Input() isLink: boolean = false;
  @Input() id: string = "";
  @Input() uppercase: boolean = false;

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
  }

  getMainStyles() {
    var styles = {
      'padding-top.px': this.paddingTop,
      'padding-right.px': this.paddingRight,
      'padding-bottom.px': this.paddingBottom,
      'padding-left.px': this.paddingLeft,
      'cursor': this.isLink ? 'pointer' : 'auto'
      
    }
    return styles;
  }

  getLineStyles() {
    var styles = {
      'width.px': this.lineWidth,
      'background-color': this.lineColor
    }
    return styles;
  }

  getAciveStyles() {
    var styles = {
      'background-color': this.activeBgcolor,
    }
    return styles;
  }
  
  selectTag() {
    if (this.isLink) {
      const data = {
        entityType: this.id,
        showGraph: true
      }
      this.eventsService.dataGovernanceTechnicalSection$.emit(data);
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-technical-search-bar-results',
  templateUrl: './technical-search-bar-results.component.html',
  styleUrls: ['./technical-search-bar-results.component.scss']
})
export class TechnicalSearchBarResultsComponent implements OnInit {
  @Input() height: string = "520";
  @Input() results: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}

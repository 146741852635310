import { Component, Input, OnInit } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';

@Component({
  selector: 'app-kpis-graphic-card',
  templateUrl: './kpis-graphic-card.component.html',
  styleUrls: ['./kpis-graphic-card.component.scss']
})
export class KpisGraphicCardComponent implements OnInit {

  @Input() item: any;
  @Input() title: any;

  multi: any[];
  view: any[] = [200, 120];

  // options
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = false;
  xAxis: boolean = false;
  yAxis: boolean = false;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  timeline: boolean = false;

  colorScheme = {
    domain: [COLORS.lightPurple]
  };

  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  constructor() { }

  ngOnInit(): void {
  }

}

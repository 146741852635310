import { Component, Input, OnInit } from '@angular/core';
import { EventsService } from 'src/app/shared/services/events/events.service';

@Component({
  selector: 'app-technical-search-bar-result',
  templateUrl: './technical-search-bar-result.component.html',
  styleUrls: ['./technical-search-bar-result.component.scss']
})
export class TechnicalSearchBarResultComponent implements OnInit {
  @Input() result: any;
  @Input() textColor: string = "#737475";

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
  }

  getEntityInfo(id: string) {
    const data = {
      breadcrumbAction: 'reset',
      id: id
    }
    this.eventsService.networkGraphSelectedNode$.emit(data);
  }

}

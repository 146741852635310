export var single = [
    {
      "name": "Jul19",
      "value": 2.1
    },
    {
      "name": "Jul20",
      "value": 3.0
    },
    {
      "name": "Plan Jul",
      "value": 3.5
    },
    {
      "name": "Plan 2020",
      "value": 3.1   
    }
  ];
<component-loader *ngIf="!loaded" [width]="580" [height]="140"></component-loader>

<div class="line-charts-gen dashed-order-2" *ngIf="loaded">
    <ngx-charts-line-chart
        [view]="view"
        [scheme]="colorScheme"
        [legend]="legend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [showGridLines]="showGridLines"
        [showRefLines]="showRefLines"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [timeline]="timeline"
        [results]="qualityClaimsRolling"
        [tooltipDisabled]="tooltipDisabled"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [xAxisTicks]="xAxisTicksFinal"
        [rotateXAxisTicks]="rotateXAxisTicks"
        >
    </ngx-charts-line-chart>
</div>

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators'
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { ExcelFileInfo } from 'src/app/shared/models/excel-file-info';
import { QualitySurveyType } from 'src/app/shared/models/quality-survey-type';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sinisters-polls',
  templateUrl: './sinisters-polls.component.html',
  styleUrls: ['./sinisters-polls.component.scss']
})
export class SinistersPollsComponent implements OnInit {

  excelDataLoaded: ExcelFileInfo;

  subscription: Subscription;

  title: string = "";
  dropdownId: string = 'sinistersQualityNPS';
  totalLabel: string;

  unsubscribe$: Subject<any> = new Subject<any>();
  qualityNPSReasons: any = [];
  loaded: boolean = false;

  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "sinisters-quality--tnps";

  // Default constructor
  constructor(
    private apimstService: ApimstService,
    private events: EventsService,
    private filtersService: FiltersService,
    private excelService: ExcelService,
    private translateService: TranslateService,
    private i18n: I18nService,
    private atlasHelper: AtlasHelperService
  ) { }

  // OnInit
  ngOnInit(): void {
    this.subscribeToFiltersValues();
    this.subscribeToActiveLanguage();
    // this.retrieveQualityNPSReason();
    // this.subscribeToExcelInfo();
  }

  // Method to retrieve the survey type master
  private retrieveQualityNPSReason() {
    this.loaded = false;
    this.apimstService.getSurveyTypeMaster().pipe(takeUntil(this.unsubscribe$)).subscribe(
      (data: Array<QualitySurveyType>) => {
        this.qualityNPSReasons = [];
        const salesQualitySurveyType = data.filter(v => v.survey_type_id === '1');
        for (let type of salesQualitySurveyType) {
          this.qualityNPSReasons.push(
            {
              id: type.survey_subtype_id,
              parentId: type.survey_type_id,
              type: 'sinisters-nps',
              name: type.survey_subtype_desc,
              selected: false
            }
          );
        }
        this.qualityNPSReasons.push(
          {
            id: 'all',
            type: 'sale-nps',
            name: this.totalLabel,
            selected: true
          }
        );

        this.loaded = true;
      },

      error => console.log("An error ocurred while retrieving sinisters quality NPS reasons data " + error)
    );
  }

  subscribeToExcelInfo(): void {
    this.subscription = this.events.salesTnpsExcelInfoLoaded$
      .subscribe({
        next: excelDataLoaded => {
          this.loaded = false;

          if (!excelDataLoaded.json) return;

          this.excelDataLoaded = excelDataLoaded;
          this.loaded = true;

        }
      })
  }

  subscribeToFiltersValues(): void {
    this.subscription = this.filtersService.filtersValues$
      .subscribe({
        next: filters => {
          this.translateService.get('labels.total')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: data => this.totalLabel = data,
              complete: () => {
                this.retrieveQualityNPSReason();
              }
            });
        }
      })
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.excelDataLoaded.json, this.excelDataLoaded.headers, this.excelDataLoaded.excelFileName, this.excelDataLoaded.filtersValues);
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscription.unsubscribe();
  }

}

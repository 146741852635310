import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IshikawaItems } from 'src/app/shared/models/ishikawa-items';

@Component({
  selector: 'app-ishikawa-diagram',
  templateUrl: './ishikawa-diagram.component.html',
  styleUrls: ['./ishikawa-diagram.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IshikawaDiagramComponent implements OnInit {
  @Input() items: IshikawaItems;
  @Input() height: string = "200";
  @Input() middleBonesWidth: string = "18";
  @Input() middleBonesHeight: string = "90";
  @Input() tailBonesWidth: string = "18";
  @Input() mainBoneWidth: string = "12";
  @Input() bonesRightOrigin: string = "-18";
  @Input() bonesSkewDegrees: string = "45";

  constructor() { }

  ngOnInit(): void {}

  getContainerStyles() {
    let styles = {
      'height.px': this.height,
    }
    return styles;
  }

  getBonesTopStyles() {
    let styles = {
      'width.px': this.middleBonesWidth,
      'height.px': this.middleBonesHeight,
      'transform': `skew(${this.bonesSkewDegrees}deg) translateX(${this.bonesRightOrigin}px)`,
    }
    return styles;
  }

  getBonesBottomStyles() {
    let styles = {
      'width.px': this.middleBonesWidth,
      'height.px': this.middleBonesHeight,
      'transform': `skew(-${this.bonesSkewDegrees}deg) translateX(${this.bonesRightOrigin}px)`,
    }
    return styles;
  }

  getTailBonesTopStyles() {
    let styles = {
      'width.px': this.tailBonesWidth,
    }
    return styles;
  }

  getMiddleBoneStyles() {
    let styles = {
      'height.px': this.mainBoneWidth,
    }
    return styles;
  }


}

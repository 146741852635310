<div class="ishikawa-container" [ngStyle]="getContainerStyles()">
  <div class="tail-section">
    <app-small-kpi class="d-inline-block" [title]="items.tail.title" [mainValue]="items.tail.mainValue"
      [percentage]="items.tail.percentage" [trend]="items.tail.trend" [hasPercentage]="items.tail.hasPercentage" [addMagnitudeOrder]="items.tail.addMagnitudeOrder">
    </app-small-kpi>
    <div class="tail-top-bone" [ngStyle]="getTailBonesTopStyles()"></div>
    <div class="tail-bottom-bone" [ngStyle]="getTailBonesTopStyles()"></div>
  </div>
  <div class="middle-section">
    <div class="top-section">
      <div class="top-section-item" *ngFor="let topItem of items.top">
        <app-small-kpi class="d-inline-block" [title]="topItem.title" [mainValue]="topItem.mainValue"
          [percentage]="topItem.percentage" [trend]="topItem.trend" [hasPercentage]="topItem.hasPercentage">
        </app-small-kpi>
        <div class="top-section-item-bone" [ngStyle]="getBonesTopStyles()"></div>
      </div>
    </div>
    <div class="bottom-section">
      <div class="bottom-section-item" *ngFor="let bottomItem of items.bottom">
        <app-small-kpi class="d-inline-block" [title]="bottomItem.title" [mainValue]="bottomItem.mainValue"
          [percentage]="bottomItem.percentage" [trend]="bottomItem.trend" [hasPercentage]="bottomItem.hasPercentage">
        </app-small-kpi>
        <div class="bottom-section-item-bone" [ngStyle]="getBonesBottomStyles()"></div>
      </div>
    </div>
    <div class="middle-section-bone" [ngStyle]="getMiddleBoneStyles()"></div>
  </div>
  <div class="head-section">
    <app-small-kpi class="d-inline-block" [title]="items.head.title" [mainValue]="items.head.mainValue"
      [percentage]="items.head.percentage" [trend]="items.head.trend" [hasPercentage]="items.head.hasPercentage" [addMagnitudeOrder]="items.head.addMagnitudeOrder">
    </app-small-kpi>
    <!-- <div class="head-arrow"></div> -->
	  <!-- <div class="triangle"></div> -->
    <span data-type="clip-path" class="triangle clip-path"></span>
  </div>
</div>

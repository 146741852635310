<div class="double_percentage_kpi">
    <div *ngIf="(mainValue != null && (firstPercentageTrendValue === 1 || firstPercentageTrendValue === 0) && (secondPercentageTrendValue === 1 || secondPercentageTrendValue === 0))">
        <span *ngIf="mainValue != null" class="double_percentage_kpi-first">{{ mainValue }}</span>
        <span 
            class="material-icons ml-2" 
            [ngClass]="{'double_percentage_kpi-arrow-green': firstPercentageTrendValue > 0, 'double_percentage_kpi-arrow-red': firstPercentageTrendValue === 0, 'double_percentage_kpi-up': firstPercentageTrendDirection === 1, 'double_percentage_kpi-down': firstPercentageTrendDirection === 0}">
            play_arrow
        </span>
        <span *ngIf="firstPercentageValue != null"
            class="double_percentage_kpi-third" 
            [ngClass]="{'double_percentage_kpi-third-green': firstPercentageTrendValue > 0, 'double_percentage_kpi-third-red': firstPercentageTrendValue === 0}">{{firstPercentagePrevious}} {{firstPercentageValue}}%
        </span>
        <span 
            class="material-icons ml-3" 
            [ngClass]="{'double_percentage_kpi-arrow-green': secondPercentageTrendValue > 0, 'double_percentage_kpi-arrow-red': secondPercentageTrendValue === 0, 'double_percentage_kpi-up': secondPercentageTrendDirection === 1, 'double_percentage_kpi-down': secondPercentageTrendDirection === 0}">
            play_arrow
        </span>
        <span *ngIf="secondPercentageValue != null"
            class="double_percentage_kpi-third" 
            [ngClass]="{'double_percentage_kpi-third-green': secondPercentageTrendValue > 0, 'double_percentage_kpi-third-red': secondPercentageTrendValue === 0}">{{secondPercentagePrevious}} {{secondPercentageValue}}%
        </span>
    </div>    
</div>
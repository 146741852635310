<app-wrapper>

    <div class="wrapper_grid">
        <div class="portfolio-indicators">
            <app-portfolio-indicators></app-portfolio-indicators>
        </div>
        <div class="mix-portfolio-sales">
            <app-portfolio-vs-sales-mix></app-portfolio-vs-sales-mix>
        </div>
    </div>

</app-wrapper>
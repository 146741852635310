<app-widget>
    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex">
            <span class="title section-card-title">{{ 'panels.sinistersQuality' | translate }}</span>
            <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams"
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </div>
    </div>

    <div class="mt-3">
        <app-sinisters-quality-velocimeter></app-sinisters-quality-velocimeter>
    </div>

    <div class="mt-2">
        <app-sinisters-quality-graphic></app-sinisters-quality-graphic>
    </div>

</app-widget>
<color-bar
    [title]="pastMonth"
    [items]="items"
    >
</color-bar>
<color-bar
    [title]="actualMonth"
    [items]="items"
    >
</color-bar>

import { Component, OnInit, OnDestroy, Output, Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { multi } from './data';
import { COLORS } from 'src/app/shared/globals/globals';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { CancellationBouquetChannel } from 'src/app/shared/models/cancellation-bouquet-channel';
import { trim } from 'lodash';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';

@Component({
  selector: 'cancellation-branch-channel-graphic',
  templateUrl: './cancellation-branch-channel-graphic.component.html',
  styleUrls: ['./cancellation-branch-channel-graphic.component.scss']
})
export class CancellationBranchChannelGraphicComponent implements OnInit, OnDestroy {

  multi: any[];
  view: any[] = [580, 200];

  unsubscribe$: Subject<any> = new Subject<any>();
  monthNames: any;
  planName: string;
  livingPlaceName: string;
  lifeName: string;
  homeName: string;
  stoleName: string;
  othersName: string;
  month: string;
  year: number;
  filtersValues: any;
  optionTabBonus: string = 'panels.bonus';
  optionTabPolicy: string = 'panels.policy';
  optionTabBranch: string = 'panels.branch';
  optionTabChannel: string = 'panels.channel';
  bonusPolicySelectedOption: string = 'panels.bonus';
  branchChannelSelectedOption: string = 'panels.branch';
  graphName: string = 'cancellationBranchChannel';

  // options
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  showGridLines: boolean = true;
  showRefLines: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  timeline: boolean = false;
  tooltipDisabled: boolean = false;
  yScaleMin: number = 0;
  yScaleMax: number;
  customColors: any = [];
  individualLines: number = 4;
  colorScheme = {
    domain: [COLORS.darkBlue, COLORS.darkYellow, COLORS.lightestBlue, COLORS.lightPurple, COLORS.lightBlue, '#aae3f5']
  };
  rotateXAxisTicks: boolean = false;

  premiumBranchData: any = [];
  premiumChannelData: any = [];
  certificateBranchData: any = [];
  certificateChannelData: any = [];
  premiumBranchLineName: any = [];
  premiumChannelLineName: any = [];
  certificateBranchLineName: any = [];
  certificateChannelLineName: any = [];
  graphData: any = [];
  lineName: any = [];
  loaded: boolean = false;

  data_total: any[] = [];
  data_sheet_names: any;
  data_filter_details: any;

  @Input() excelExportEvent;
  @Output() loadedGraph = new EventEmitter<boolean>();

  constructor(
    private filtersService: FiltersService,
    private eventsService: EventsService,
    private apimstService: ApimstService,
    private translateService: TranslateService,
    private excelService: ExcelService
  ) {
    Object.assign(this, { multi });
  }

  yAxisTickFormatting(val) {
    return val.toLocaleString() + "%";
  }

  ngOnInit(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {

          const months = this.translateService.get('abbreviatedMonthNames')
          const livingPlace = this.translateService.get('labels.livingPlace');
          const life = this.translateService.get('labels.life');
          const home = this.translateService.get('labels.home');
          const stole = this.translateService.get('labels.stole');
          const others = this.translateService.get('labels.others');
      
          forkJoin([months, livingPlace, life, home, stole, others])
            .subscribe(
              {
                next: results => {
                  this.monthNames = results[0];
                  this.livingPlaceName = results[1];
                  this.lifeName = results[2];
                  this.homeName = results[3];
                  this.stoleName = results[4];
                  this.othersName = results[5];
                  //this.assignColorBar();
                },
                complete: () => {
                  if (data.length !== 0) {
                    this.filtersValues = data;
                    this.resetVariables();
                    if (this.branchChannelSelectedOption === this.optionTabBranch) {
                      this.retrieveCancellationBranchChannelData(['b'], this.filtersValues);
                    } else if (this.branchChannelSelectedOption === this.optionTabChannel) {
                      this.retrieveCancellationBranchChannelData(['c'], this.filtersValues);
                    }
                  }
                }
              }
            );
        }
      );

    this.eventsService.policiesBonusOption$.subscribe(
      data => {
        const option = data[0];
        const name = data[1];
        if ((option === this.optionTabBonus || option === this.optionTabPolicy) && name === this.graphName){
          this.bonusPolicySelectedOption = option;
          this.selectedData();
        } else if ((option === this.optionTabBranch || option === this.optionTabChannel) && name === this.graphName) {
          this.branchChannelSelectedOption = option;
          this.selectedData();
        }

      }
    );

    this.excelExportEvent
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(data => {
      if (data) {
        this.exportDataToExcel();
      }
    });
  }

  // Method to retrieve the cancellation branch channel data
  private retrieveCancellationBranchChannelData(dimension: any, filtersValues: any) {

    this.loaded = false;  

    this.apimstService.getCancellationBouquetchannel(dimension, filtersValues)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (data: Array<CancellationBouquetChannel>) => {
        const cancellationBouquetChannel = data;

        let helper = {};

        // Group array by date
        let cancellationBouquetChannelByDate = cancellationBouquetChannel.reduce(function (r, o) {
          var key = o.month + '-' + o.year;
          if (!helper[key]) {
            helper[key] = cancellationBouquetChannel.filter(v => (v.month === o.month && v.year === o.year));
            r.push(helper[key]);
          }
          return r;
        }, []);

        if (cancellationBouquetChannelByDate.length !== 0) {
          this.data_filter_details = cancellationBouquetChannelByDate[0][0].filters;

          // Get last month
          let lastMonthData = cancellationBouquetChannelByDate[cancellationBouquetChannelByDate.length-1];

          //////////  Premium   /////////

          // Sort last month 
          let premiumLastMonth = lastMonthData.sort(function compare(a, b) {
            if ( parseFloat(a.churn_premium) < parseFloat(b.churn_premium) ){
              return 1;
            }
            if ( parseFloat(a.churn_premium) > parseFloat(b.churn_premium) ){
              return -1;
            }
            return 0;
          })
          
          // Get individual lines to print according to the prefixed number
          const premiumLinesToPrint = premiumLastMonth.slice(0, this.individualLines).map(v => v.name);

          let premiumIndividualLinesData = [];

          for (let item of premiumLinesToPrint) {
            let series = [];
            for (let month of cancellationBouquetChannelByDate) {
              const filteredIndividualValue = month.filter(v => v.name === item);

              if (filteredIndividualValue.length !== 0) {
                series.push(
                  {
                    name: this.monthNames[filteredIndividualValue[0]?.month] + ' ' + filteredIndividualValue[0]?.year.slice(2,4),
                    value: (filteredIndividualValue[0]?.churn_premium || trim(filteredIndividualValue[0]?.churn_premium).length > 0) ? parseFloat(filteredIndividualValue[0]?.churn_premium) : 0
                  }
                );
              } else {
                series.push(
                  {
                    name: this.monthNames[month[0]?.month] + ' ' + month[0]?.year.slice(2,4),
                    value: 0
                  }
                );
              }
            }

            premiumIndividualLinesData.push({
              name: item,
              series: series
            });

          }

          if (lastMonthData.length > premiumLinesToPrint.length) {

            let premiumGroupedSeries = [];
            for (let month of cancellationBouquetChannelByDate) {
              let accumulatedValue = 0;
              for (let item of month) {
                const index = premiumLinesToPrint.indexOf(item.name);
                if (index < 0) {
                  accumulatedValue += (item.churn_premium || trim(item.premium).length > 0) ? parseFloat(item.churn_premium) : 0
                }
              }
              premiumGroupedSeries.push({
                name: this.monthNames[month[0]?.month] + ' ' + month[0]?.year.slice(2,4),
                value: accumulatedValue
              });
            }

            premiumIndividualLinesData.push({
              name: this.othersName,
              series: premiumGroupedSeries
            });

            premiumLinesToPrint.push(this.othersName);
          }


          /////////////    Certificate    ////////////////

          // Sort last month 
          let certificateLastMonth = lastMonthData.sort(function compare(a, b) {
            if ( parseFloat(a.churn) < parseFloat(b.churn) ){
              return 1;
            }
            if ( parseFloat(a.churn) > parseFloat(b.churn) ){
              return -1;
            }
            return 0;
          })
          
          // Get individual lines to print according to the prefixed number
          const certificateLinesToPrint = certificateLastMonth.slice(0, this.individualLines).map(v => v.name);

          let certificateIndividualLinesData = [];

          for (let item of certificateLinesToPrint) {
            let series = [];
            for (let month of cancellationBouquetChannelByDate) {
              const filteredIndividualValue = month.filter(v => v.name === item);

              if (filteredIndividualValue.length !== 0) {
                series.push(
                  {
                    name: this.monthNames[filteredIndividualValue[0]?.month] + ' ' + filteredIndividualValue[0]?.year.slice(2,4),
                    value: (filteredIndividualValue[0]?.churn || trim(filteredIndividualValue[0]?.churn).length > 0) ? parseFloat(filteredIndividualValue[0]?.churn) : 0
                  }
                );
              } else {
                series.push(
                  {
                    name: this.monthNames[month[0]?.month] + ' ' + month[0]?.year.slice(2,4),
                    value: 0
                  }
                );
              }
            }

            certificateIndividualLinesData.push({
              name: item,
              series: series
            });

          }

          if (lastMonthData.length > certificateLinesToPrint.length) {

            let certificateGroupedSeries = [];
            for (let month of cancellationBouquetChannelByDate) {
              let accumulatedValue = 0;
              for (let item of month) {
                const index = certificateLinesToPrint.indexOf(item.name);
                if (index < 0) {
                  accumulatedValue += (item.churn || trim(item.churn).length > 0) ? parseFloat(item.churn) : 0
                }
              }
              certificateGroupedSeries.push({
                name: this.monthNames[month[0]?.month] + ' ' + month[0]?.year.slice(2,4),
                value: accumulatedValue
              });
            }

            certificateIndividualLinesData.push({
              name: 'Otros',
              series: certificateGroupedSeries
            });

            certificateLinesToPrint.push(this.othersName);
          }

          if (dimension[0] === 'b') {
            this.premiumBranchData = premiumIndividualLinesData;
            this.premiumBranchLineName = premiumLinesToPrint;
            this.certificateBranchData = certificateIndividualLinesData;
            this.certificateBranchLineName = certificateLinesToPrint;
          } else if (dimension[0]  === 'c') {
            this.premiumChannelData = premiumIndividualLinesData;
            this.premiumChannelLineName = premiumLinesToPrint;
            this.certificateChannelData = certificateIndividualLinesData;
            this.certificateChannelLineName = certificateLinesToPrint;
          }

        }

        this.assignData();
        this.assignColorBar();
        
        this.loaded = true;
        this.loadedGraph.emit(true);
      }
    );
  }

  // Method to assign the colors to the graph
  private assignColorBar() {
    this.customColors.push(
      {
        name: this.lineName[0],
        value: COLORS.darkBlue
      },
      {
        name: this.lineName[1],
        value: COLORS.darkYellow
      },
      {
        name: this.lineName[2],
        value: COLORS.lightestBlue
      },
      {
        name: this.lineName[3],
        value: COLORS.lightPurple
      },
      {
        name: this.lineName[4],
        value: COLORS.lightBlue
      })
  }

  private assignData() {
    if (this.bonusPolicySelectedOption === this.optionTabBonus && this.branchChannelSelectedOption === this.optionTabBranch) {
      this.graphData = this.premiumBranchData;
      this.lineName = this.premiumBranchLineName.map(this.stringTruncating);
      this.loaded = true;
    } else if (this.bonusPolicySelectedOption === this.optionTabPolicy && this.branchChannelSelectedOption === this.optionTabBranch) {
      this.graphData = this.certificateBranchData;
      this.lineName = this.certificateBranchLineName.map(this.stringTruncating);
      this.loaded = true;
    } else if (this.bonusPolicySelectedOption === this.optionTabBonus && this.branchChannelSelectedOption === this.optionTabChannel) {
      this.graphData = this.premiumChannelData;
      this.lineName = this.premiumChannelLineName.map(this.stringTruncating);
      this.loaded = true;
    } else if (this.bonusPolicySelectedOption === this.optionTabPolicy && this.branchChannelSelectedOption === this.optionTabChannel) {
      this.graphData = this.certificateChannelData;
      this.lineName = this.certificateChannelLineName.map(this.stringTruncating);
      this.loaded = true;
    }
  }

  // Method to assign data according to the selected option
  private selectedData() {
    if (this.branchChannelSelectedOption === this.optionTabChannel && this.premiumChannelData.length === 0 && this.certificateChannelData.length === 0) {
      this.retrieveCancellationBranchChannelData(['c'], this.filtersValues);
    } else if (this.branchChannelSelectedOption === this.optionTabBranch && this.premiumBranchData.length === 0 && this.certificateBranchData.length === 0) {
      this.retrieveCancellationBranchChannelData(['b'], this.filtersValues);
    } else {
      this.assignData();
    }
  }

  // Method to reset the storage variables
  private resetVariables() {
    this.premiumChannelData = [];
    this.premiumBranchLineName = [];
    this.certificateChannelData = [];
    this.certificateBranchLineName = [];
    this.premiumBranchData = [];
    this.premiumChannelLineName = [];
    this.certificateBranchData = [];
    this.certificateChannelLineName = [];
  }

  // String truncating function
  stringTruncating = function(a) {
    if (a.length > 8) {
      return a.slice(0, 8) + '...';
    } else {
      return a;
    }
  }

  // Method to export the graph data to an Excel file
  private exportDataToExcel() {
    this.data_total = [];
    const title = this.translateService.get('panels.churnRate');
    const bonusPolicyoption = this.translateService.get(this.bonusPolicySelectedOption);
    const branchChannelOption = this.translateService.get(this.branchChannelSelectedOption);
    
    let titleLabel = '';
    let bonusPolicyOptionLabel = '';
    let branchChannelOptionLabel = '';

    forkJoin([title, bonusPolicyoption, branchChannelOption])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(result => {
      titleLabel = result[0];
      bonusPolicyOptionLabel = result[1];
      branchChannelOptionLabel = result[2];
    });

    this.getDataToExport();
    this.data_sheet_names = [titleLabel + ' - ' + branchChannelOptionLabel + ' - ' + bonusPolicyOptionLabel];
    this.excelService.exportAsExcelFile([this.data_total.flat()], this.data_sheet_names,  'churn_rate_per_branch_channel', this.data_filter_details);

  }

  // Method to retrieve the data to export
  private getDataToExport() {
    const values = this.graphData.map(v => {
      return [v.name, v.series.map(v => v.value)].flat();
    })

    const headers = [['', this.graphData[0].series.map(v => v.name)].flat()];

    this.data_total.push(headers, values);
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

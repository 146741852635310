import { Component, OnInit } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';
import { Subject } from 'rxjs';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { takeUntil } from 'rxjs/operators';
import { CancellationRenewalPer } from 'src/app/shared/models/cancellation-renovation-per';

@Component({
  selector: 'renewal-per-pie-chart',
  templateUrl: './renewal-per-pie-chart.component.html',
  styleUrls: ['./renewal-per-pie-chart.component.scss']
})
export class RenewalPerPieChartComponent implements OnInit {

  pieGraphData: any[];
  showPieDialogButton: boolean = false;
  graphName = 'cancellationRenewalPer';

  // Pie chart options
  view: any[] = [180, 130];
  gradient: boolean = false;
  showLegend: boolean = false;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';
  tooltipDisabled: boolean = true;
  animations: boolean = false;
  colorScheme = {
    domain: [COLORS.lightBlue, COLORS.pink, COLORS.lightPurple, COLORS.lightOrange, COLORS.lightestBlue, COLORS.darkGreen, COLORS.lightGreen, COLORS.darkYellow, COLORS.darkBlue]
  };
  arcWidth: number = 1.5;
  customColorsPieGraph: any = [];

  tooltipTitle: string;
  tooltipNumber: string;

  // Pie chart dialog options
  pieDialogView: any[] = [600, 380];
  pieDialogShowLabels: boolean = true;
  pieDialogLegendPosition: string = 'below';

  // Unsubscribe subject
  unsubscribe$: Subject<any> = new Subject<any>();

  filtersValues: any;

  constructor(
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
  
    this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        if (data.length !== 0) {
          this.filtersValues = data;
          this.retrievePieGraphData('c', 'fm');
        }
      }
    );

    this.eventsService.policiesBonusOption$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const option = data[0];
          const name = data[1];

          
        }
      );

  }

  //
  private retrievePieGraphData(type_data: string, type_report: string) {

    this.apimstService.getRenovationPayments(type_data, type_report, this.filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        const pieGraphInfo: Array<CancellationRenewalPer> = data;

        for (let item of pieGraphInfo) {
          
        }
      });

    this.eventsService.policiesBonusOption$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const option = data[0];
          const name = data[1];
          // if ((option === this.chargesAssetsOption || option === this.chargesMethodsOption) && name === this.graphName) {
          //   this.chargesAssetsMethodsSelectedOption = option;
          //   this.assignData();
          // } else if ((option === this.amountOption || option === this.receiptsOption) && name === this.graphName) {
          //   this.amountReceiptsSelectedOption = option;
          //   this.assignData();
          // }
        }
      );   

  }

  //
  labelFormatting(series: any[], name: string): string {
    const item = series.filter(data => data.name === name);
    if (item.length > 0) {
        return item[0].value + "%";
    }
    return name;
  }

  //
  activate(e) {
    this.tooltipTitle = e['value'].label;
    this.tooltipNumber = Intl.NumberFormat("de-DE").format(e['value'].value) + "%";
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-santander-vs-zs-segmentation',
  templateUrl: './santander-vs-zs-segmentation.component.html',
  styleUrls: ['./santander-vs-zs-segmentation.component.scss']
})
export class SantanderVsZsSegmentationComponent implements OnInit {

  // Drowpdown options
  santanderSegmentationDropdownId: string = "santanderSegmentation";
  santanderSegmentationDropdownOption: any = [];
  santanderSegmentationDropdownTitle: string = "";

  // Drowpdown options
  zurichSegmentationDropdownId: string = "zurichSegmentation";
  zurichSegmentationDropdownOption: any = [];
  zurichSegmentationDropdownTitle: string = "";

  constructor() { 
  }

  ngOnInit(): void {
    this.santanderSegmentationDropdownOption.push({
      id: '',
      type: 'santander-segmentation',
      name: 'Segmentación Santander',
      selected: true
    });

    this.zurichSegmentationDropdownOption.push({
      id: '',
      type: 'zurich-segmentation',
      name: 'Segmentación Zurich',
      selected: true
    });
  }
  
}

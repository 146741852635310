<div class="float-left cancellation-charges-pie-chart">
    <component-loader *ngIf="!pieGraphLoaded" [width]="200" [height]="220"></component-loader>
    <!-- <div class="mt-3 ml-n2 d-flex flex-wrap justify-content-start align-items-center"  *ngIf="pieGraphLoaded">
        <app-legend *ngFor="let label of pieGraphLabels; let i = index" class="mr-2" name={{label}} color={{colorListCSS[i]}} type="round" gradient="v-gradient"></app-legend>
    </div> -->
    
    <div class="charge-pie-chart pie-graph-gen mt-1" *ngIf="pieGraphLoaded">
        <ngx-charts-pie-chart 
            [view]="viewPie" 
            [scheme]="colorSchemePie" 
            [results]="pieGraphToPrint" 
            [gradient]="gradientPie"
            [legend]="showLegendPie"
            [legendTitle]="legendTitlePie" 
            [legendPosition]="legendPositionPie" 
            [labels]="showLabelsPie" 
            [doughnut]="isDoughnutPie"
            [arcWidth]="arcWidth"
            [tooltipDisabled]="tooltipDisabled"
            [animations]="animationsPie"
            [labelFormatting]="labelFormatting.bind(this, pieGraphToPrint)"
            [customColors]="customColorsPieGraph"
            (activate)="activate($event)">
            <ng-template #tooltipTemplate let-model="model">
                <div class="pie-tooltip">
                    <span class="pie-tooltip-title d-block">{{ tooltipTitle }}</span>
                    <span class="pie-tooltip-data d-block">{{ tooltipNumber }}</span>
                </div>
            </ng-template>
        </ngx-charts-pie-chart>
        <div class="fullscreen-icon-container" *ngIf="showPieDialogButton">
            <span class="material-icons fullscreen-icon text-light" (click)="openPieChartDialog()">fullscreen</span>
        </div>
    </div>
</div>

<div class="float-right cancellation-charges-graphic">
    <component-loader *ngIf="!barGraphLoaded" [width]="380" [height]="220"></component-loader>

    <div class="mt-3 mb-2 mr-n3 d-flex flex-wrap justify-content-end align-items-center" *ngIf="barGraphLoaded">
        <app-legend class="mr-2" name={{actualYear-1}} color="light-blue" type="round" gradient="v-gradient"></app-legend>
        <app-legend class="mr-2" name={{actualYear}} color="dark-blue" type="round" gradient="h-gradient"></app-legend>
        <app-legend class="mr-2" name="labels.tax" color="dark-yellow" type="round" gradient="h-gradient"></app-legend>
    </div>

    <div class="mr-2 ml-2 hide-horizontal-line" *ngIf="barGraphLoaded">
        <grouped-vertical-horizontal-graph-d-axis
            [view]="view"
            [scheme]="comboBarScheme"
            [results]="barGraphToPrint"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [barPadding]="barPadding"
            [groupPadding]="groupPadding"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [legendTitle]="legendTitle"
            [lineChart]="rateToPrint"
            [showGridLines]="showGridLines"
            [showRightGridLines]="showRightGridLines"
            [customColors]="customColors"
            [roundEdges]="roundEdges"
            [activeEntries]="activeEntries"
            [trimXAxisTicks]="trimXAxisTicks"
            [trimYAxisTicks]="trimXAxisTicks"
            [rotateXAxisTicks]="rotateXAxisTicks"
            [maxXAxisTickLength]="maxXAxisTickLength"
            [maxYAxisTickLength]="maxYAxisTickLength"
            [tooltipDisabled]="tooltipDisabled"
            [roundDomains]="roundDomains"
            [yRightAxisTickFormatting]="yRightAxisTickFormatting"
            [yAxisTickFormatting]="yAxisTickFormatting"
            [onlyPoints]="onlyPoints">
        </grouped-vertical-horizontal-graph-d-axis>
    </div>
</div>

import { Component, Input, Output, EventEmitter, OnChanges, ChangeDetectionStrategy, ContentChild, TemplateRef } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { BaseChartComponent } from '@swimlane/ngx-charts';

@Component({
    selector: 'g[ngx-combo-charts-series-vertical]',
    templateUrl: './stacked-vertical-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('animationState', [
            transition('* => void', [
                style({
                    opacity: 1,
                    transform: '*'
                }),
                animate(500, style({ opacity: 0, transform: 'scale(0)' }))
            ])
        ])
    ]
})
export class StackedVerticalBarComponent extends BaseChartComponent {
    
    // Input variables to configure the chart options
    // Graph dimensions
    @Input() dims;

    // Data for the stacked vertical bar representation
    @Input() series;

    // Data for the horizontal lines representation
    @Input() seriesLine;

    // Scale for the X-axis
    @Input() xScale;

    // Scale for the Y-axis
    @Input() yScale;

    // Colors
    @Input() colors;

    // Show or hide the tooltip
    @Input() tooltipDisabled;

    // Elements to highlights
    @Input() activeEntries;

    // Hide bar if value is 0 and setting is true
    @Input() noBarWhenZero;

    // Fill elements with a gradient instead of a solid color
    @Input() gradient;

    // Display the value number next to the bar
    @Input() showDataLabel;

    // Format for the data label
    @Input() dataLabelFormatting;
    
    // Enable animations
    @Input() animations;

    // Round edges of the bars
    @Input() roundEdges;

    // Show or hide the legend
    @Input() legend;

    // The legend title
    @Input() legendTitle;

    // The legend position ('below' or 'right')
    @Input() legendPosition;

    // Show or hide the X-axis label
    @Input() showXAxisLabel;

    // Show or hide the Y-axis label
    @Input() showYAxisLabel;

    // The X-axis label text
    @Input() xAxisLabel;

    // The Y-axis label text
    @Input() yAxisLabel;

    @Output() bandwidth = new EventEmitter();

    // Method to update the chart dimensions
    update(): void {
        super.update();

        let width;
        if (this.series.length) {
            width = this.xScale.bandwidth();
            this.bandwidth.emit(width);
        }
    }

    // Method to transform the vertical bar
    groupTransform(group) {
        return `translate(${this.xScale(group.name) || 0}, 0)`;
    }

}
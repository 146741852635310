import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-santander-clients-penetration-main',
  templateUrl: './santander-clients-penetration-main.component.html',
  styleUrls: ['./santander-clients-penetration-main.component.scss']
})
export class SantanderClientsPenetrationMainComponent implements OnInit {
  
  loaded: boolean = false;

  indicatorsMaxNumber: number = 10;
  indicatorsActivatedImageSource: string = "assets/icons/men.svg";
  indicatorsDeactivatedImageSource: string = "assets/icons/men-solid.svg";
  indicatorsImageWidth: string = "16";

  indicators = [
    {
      itemsNumber: 1,
      title: "Penetración total banco",
      value: "16%18%",
      percentage: "-0.2",
      trend: 0
    },
    {
      itemsNumber: 4,
      title: "Penetración CR",
      value: "35%41%",
      percentage: "-0.2",
      trend: 0
    },
    {
      itemsNumber: 2,
      title: "Penetración OM",
      value: "16%18%",
      percentage: "-0.2",
      trend: 0
    },
    {
      itemsNumber: 2,
      title: "Penetración Ahorro",
      value: "16%18%",
      percentage: "-0.2",
      trend: 0
    },
  ];

  filtersValues: any;

  unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private filtersService: FiltersService
  ) { }

  ngOnInit(): void {
    this.subscribeToFiltersValues();
  }

  subscribeToFiltersValues() {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: filtersValues => {
          this.filtersValues = filtersValues;
          this.loaded = false;

          if (this.filtersValues) {
            // this.getTranslations();
            this.retrieveClientsPenetration();
          }
        }
      })
  }

  retrieveClientsPenetration(): void {
    
    setTimeout(() => {

      // TODO: edit when final API call ready (simulated with timeout)
      this.loaded = true;
      
    }, 3000);

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<!-- <div class="mt-4" *ngIf="!percentageKpi"></div> -->
<app-wok-graph
    [view]="view"
    [scheme]="colorScheme"
    [schemeType]="schemeType"
    [results]="evolutionGraph"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [xAxisTicks]="xAxisTicks"
    [animations]="animations"
    [showDataLabel] = "showDataLabel"
    [dataLabelFormatting]="formatDataLabel"
    [noBarWhenZero] = "noBarWhenZero"
    [labelDiff] = "percentageKpi"
    [viewBox] = "viewBox"
    [textLabel] = "textLabel"
    [rotateXAxisTicks] = "rotateXAxisTicks">
</app-wok-graph>
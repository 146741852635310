<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2 w-100">
        <div class="d-flex align-items-center justify-content-between w-100"> 
            <div class="d-flex col-6 pl-0 justify-content-between w-100">
                <div class="d-flex">
                    <span class="title section-card-title">{{ 'panels.clientsNPS' | translate }}</span>
                    <app-btn-gobernance-info 
                        class="ml-2" 
                        [endpoint]="endpoint" 
                        [endpointParams]="endpointParams"
                        *ngIf="hasGobernanceInfo">
                    </app-btn-gobernance-info>
                </div>
                <span class="title section-card-subtitle ml-2">{{ 'panels.clientsNPSTotal' | translate }}</span>
            </div>
            <div class="d-flex justify-content-end align-items-center col-6 pr-0">
                <span class="title section-card-subtitle mr-4">{{ 'panels.clientsNPSByTouchpoint' | translate }}</span>
                <div>
                    <component-loader [width]="180" [height]="37" *ngIf="!surveyTypeLoaded"></component-loader>
                    <div class="dropdown-container dropdown-container dropdown-light" *ngIf="surveyTypeLoaded">
                        <app-dropdown 
                            [title]="clientsSurveysDropdownTitle" 
                            [dropdownId]="clientsDropdownId" 
                            [items]="itemsClientsSurveys">
                        </app-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-6">
            <component-loader [width]="" [height]="125" *ngIf="!surveyTypeLoaded"></component-loader>
            <app-clients-voc-velocimeter-total class="d-block mt-2" *ngIf="surveyTypeLoaded"></app-clients-voc-velocimeter-total>
        </div>
        <div class="col-6">
            <component-loader [width]="" [height]="125" *ngIf="!surveyTypeLoaded"></component-loader>
            <app-clients-voc-velocimeter class="d-block mt-2" *ngIf="surveyTypeLoaded"></app-clients-voc-velocimeter>
        </div>        
    </div>

    <div class="row">
        <div class="col-6">
            <div class="mt-3" *ngIf="!surveyTypeLoaded"><component-loader [width]="" [height]="140"></component-loader></div>
            <app-clients-voc-graphic-total class="d-block overflow-hidden pb-1" *ngIf="surveyTypeLoaded"></app-clients-voc-graphic-total>
        </div>
        <div class="col-6">
            <div class="mt-3" *ngIf="!surveyTypeLoaded"><component-loader [width]="" [height]="140"></component-loader></div>
            <app-clients-voc-graphic class="d-block overflow-hidden pb-1" *ngIf="surveyTypeLoaded"></app-clients-voc-graphic>
        </div>
    </div>
     
    <app-clients-voc-grouped-graphic class="d-block mt-4"></app-clients-voc-grouped-graphic>
    
</app-widget>

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApimstService } from '../../services/apimst.service';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

@Component({
  selector: 'app-btn-gobernance-info',
  templateUrl: './btn-gobernance-info.component.html',
  styleUrls: ['./btn-gobernance-info.component.scss']
})
export class BtnGobernanceInfoComponent implements OnInit {

  @Input() info: any;
  @Input() endpoint: any = "";
  @Input() endpointParams: any = [];

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {}

  openDialog(): void {
    let backDrop;
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data : {
        sectionTitle: 'panels.sale',
        endpoint: this.endpoint,
        endpointParams: this.endpointParams,
        info: this.info
      },
      panelClass: ['mat-dialog-gen', 'mat-dialog-info-gen']
    })
    backDrop = document.querySelector('.cdk-overlay-container');
    backDrop.classList.add('overlay-dialog-info');
    
    dialogRef.afterClosed().subscribe(result => {
      backDrop.classList.remove('overlay-dialog-info');
    });
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators'
import { QualityVelocimeter } from 'src/app/shared/models/quality-velocimeter';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { QualityResponseRate } from 'src/app/shared/models/quality-response-rate';
import { SemicirclePie } from 'src/app/shared/models/semicircle-pie';
import { COLORS } from 'src/app/shared/globals/globals';
import { TranslateService } from '@ngx-translate/core';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';

@Component({
  selector: 'app-clients-voc-velocimeter-total',
  templateUrl: './clients-voc-velocimeter-total.component.html',
  styleUrls: ['./clients-voc-velocimeter-total.component.scss']
})
export class ClientsVocVelocimeterTotalComponent implements OnInit {

  tnpsItems: SemicirclePie[] = [];
  legends: any[] = [];
  tnpsKpi: any = {};

  // ViewBox
  viewBox: string = "0 0 100 50";
  strokeWidth: number = 8;

  // Values
  target: string;
  mainValue: string;
  secondValue: string;
  trend: string;
  leftValue: string;
  hasInfo: boolean = true;
  currentValue: string;
  hAlign: string = "start";
  vAlign: string = "center";
  infoTitle: string = 'labels.responseRate';

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;

  qualityNPS: any;
  qualityNPSSelected: any = [];
  loaded: boolean = false;
  responseRate: any = {};
  barOrientation: number;

  promotersName: string = "";
  neutralsName: string = "";
  detractorsName: string = "";
  tnpsName: string = "";

  surveyTypeSelected: string = "";

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private eventsService: EventsService,
    private translateService: TranslateService,
    private magnitudeOrderPipe: MagnitudeOrderPipe
  ) { }

  ngOnInit(): void {
    this.subscribeToFilters();
  }

  getTranslations() {
    const promotersT = this.translateService.get('labels.promoters');
    const neutralsT = this.translateService.get('labels.neutrals');
    const detractorsT = this.translateService.get('labels.detractors');
    const tnpsT = this.translateService.get('labels.tnps');
    forkJoin([promotersT, neutralsT, detractorsT, tnpsT])
      .subscribe(
        {
          next: results => {
            this.promotersName = results[0];
            this.neutralsName = results[1];
            this.detractorsName = results[2];
            this.tnpsName = results[3];
          }
        })
  }

  subscribeToFilters() {

    this.filtersService.filtersValues$
      .pipe(shareReplay(), takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.resetValues();
          this.getTranslations();
          this.filtersValues = data;

          this.loaded = true;
          if (this.filtersValues.length == 0) return;

          this.retrieveQualityNPS(this.filtersValues);
        }
      )
  }

  resetValues() {
    this.qualityNPS = [];
    this.tnpsItems = [];
    this.tnpsKpi = {};
    this.responseRate = {};
  }

  filterValues(values: any[] = []) {
    return values.filter(v => v.name == this.surveyTypeSelected)[0];
  }

  // Method to retrieve the quality NPS data
  private retrieveQualityNPS(filtersValues: any) {
    this.loaded = false;
    this.apimstService.getClientsQualityClaims("ctnt", filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data) => {
          this.loaded = true;

          if(data == null) return;
          this.qualityNPS = data[0];

          if(this.qualityNPS == null) { this.qualityNPS = []; return; }
          if (typeof this.qualityNPS == "undefined") this.qualityNPS = [];

          this.createSemicirclePie(this.qualityNPS);
          this.createSemicirclePieKpi(this.qualityNPS);
          this.createLegends(this.qualityNPS);

          this.target = (parseFloat(this.qualityNPS.target) * 1.8).toString(); // 1.8 => 180º = 100%
          this.currentValue = (parseFloat(this.qualityNPS.rate) * 1.8).toString();
        },
        error => console.log("An error ocurred while retrieving quality NPS indicator data: " + error)

      );

    this.apimstService.getClientsQualityClaims("crrt", filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data) => {
          this.resetKpi();

          if(data == null) { data = []; return; }
          if (typeof data[0] == "undefined") data[0] = [];

          this.responseRate.surveys_answered = data[0].answered || data[0].answered != "" ? Intl.NumberFormat("de-DE").format(parseFloat(data[0].answered.replace(/,/g, ''))): "";
          this.responseRate.surveys_sent = data[0].sent || data[0].sent != "" ? Intl.NumberFormat("de-DE").format(parseFloat(data[0].sent.replace(/,/g, ''))): "";
          this.responseRate.response_rate = data[0].rate;
        },
        error => console.log("An error ocurred while retrieving quality response rate data: " + error)
      );
  }

  createSemicirclePie(info: any = {}) {
    this.tnpsItems = [
      {
        name: this.promotersName,
        percentage: info.promoter ? parseFloat(info.promoter) : 0,
        accumulatedPercentage: 0,
        color: this.getColor(this.promotersName)
      },
      {
        name: this.neutralsName,
        percentage: info.neutral ? parseFloat(info.neutral) : 0,
        accumulatedPercentage: 0,
        color: this.getColor(this.neutralsName)
      },
      {
        name: this.detractorsName,
        percentage: info.promoter ? parseFloat(info.detractor) : 0,
        accumulatedPercentage: 0,
        color: this.getColor(this.detractorsName)
      },
    ]

    let accumulatedItems = [];
    let accumulatedPercentage: number = 0;
    for (let item of this.tnpsItems) {
      accumulatedPercentage = accumulatedPercentage + item.percentage;
      accumulatedItems.push(
        {
          name: item.name,
          percentage: item.percentage,
          accumulatedPercentage: accumulatedPercentage / 100 * 180,
          color: item.color
        }
      )
    }
    this.tnpsItems = accumulatedItems;
  }

  createSemicirclePieKpi(kpi: any) {
    this.tnpsKpi = {
      mainValue: kpi.rate ? kpi.rate : "0",
      mainValueUnit: '%',
      secondaryValue: "",
      // secondaryValue: kpi.target ? "/ " + this.magnitudeOrderPipe.transform(parseFloat(kpi.target.replace(/,/g, ''))) + "%" : "",
      percentage: '0',
      trend: 1,
      hasPercentage: false
    };
  }

  createLegends(info: any) {
    this.legends = [
      {
        name: this.promotersName + " (" + Intl.NumberFormat("de-DE").format(this.tnpsItems[0].percentage) + "%)",
        color_code: this.getColor(this.promotersName),
        type: 'round',
        gradient: 'h-gradient'
      },
      {
        name: this.neutralsName + " (" + Intl.NumberFormat("de-DE").format(this.tnpsItems[1].percentage) + "%)",
        color_code: this.getColor(this.neutralsName),
        type: 'round',
        gradient: 'h-gradient'
      },
      {
        name: this.detractorsName + " (" + Intl.NumberFormat("de-DE").format(this.tnpsItems[2].percentage) + "%)",
        color_code: this.getColor(this.detractorsName),
        type: 'round',
        gradient: 'h-gradient'
      },
      {
        name: this.tnpsName,
        color_code: this.getColor(this.tnpsName),
        type: 'line',
        gradient: ''
      }
    ]
  }

  getColor(label: string) {
    let color: string;
    let colors: any[] = [
      { name: this.promotersName, color: COLORS.darkGreen },
      { name: this.neutralsName, color: COLORS.darkYellow },
      { name: this.detractorsName, color: COLORS.darkRed },
      { name: this.tnpsName, color: COLORS.lightPurple }
    ];

    let matchedLabel = colors.filter(l => l.name == label)

    return matchedLabel[0].color;
  }

  resetKpi() {
    this.responseRate = {};
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators'
import { forkJoin, Subject } from 'rxjs'
import { MonthlyPaymentForms } from 'src/app/shared/models/monthly-payment-forms';
import { YearPaymentForms } from 'src/app/shared/models/year-payment-forms';
import _, { trim } from 'lodash';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { PieChartDialogComponent } from 'src/app/shared/components/pie-chart-dialog/pie-chart-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'cancellation-charges-graphic',
  templateUrl: './cancellation-charges-graphic.component.html',
  styleUrls: ['./cancellation-charges-graphic.component.scss']
})
export class CancellationChargesGraphicComponent implements OnInit, OnDestroy {

  // Options for pie chart
  pieGraphData: any[];
  pieGraph: any[];
  showPieDialogButton: boolean = false;

  // Pie chart options
  viewPie: any[] = [200, 180];
  gradientPie: boolean = false;
  showLegendPie: boolean = true;
  showLabelsPie: boolean = false;
  isDoughnutPie: boolean = false;
  isDoughnut: boolean = false;
  legendPositionPie: string = 'below';
  legendTitlePie: string = '';
  tooltipDisabledPie: boolean = false;
  animationsPie: boolean = false;
  colorSchemePie = {
    domain: [COLORS.lightBlue, COLORS.pink, COLORS.lightPurple, COLORS.lightOrange, COLORS.lightestBlue, COLORS.darkGreen, COLORS.lightGreen, COLORS.darkYellow, COLORS.darkBlue]
  };
  customColorsPieGraph: any = [];
  arcWidth: number = 1.5;

  tooltipTitle: string;
  tooltipNumber: string;

  // Pie chart dialog options
  pieDialogView: any[] = [600, 380];
  pieDialogShowLabels: boolean = true;
  pieDialogLegendPosition: string = 'below';

  labelFormatting(series: any[], name: string): string {
    const item = series.filter(data => data.name === name);
    if (item.length > 0) {
      return Intl.NumberFormat("de-DE").format(item[0].value) + "%";
    }
    return name;
  }

  activate(e) {
    this.tooltipTitle = e['value'].label;
    this.tooltipNumber = Intl.NumberFormat("de-DE").format(e['value'].value) + "%";
  }

   // Options for chart creation
   view = [340, 180];
   showXAxis: boolean = true;
   showYAxis: boolean = true;
   gradient: boolean = true;
   showLegend: boolean = false;
   legendTitle: string = '';
   legendPosition: string = '';
   showXAxisLabel: boolean = false;
   showYAxisLabel: boolean = false;
   xAxisLabel: string = '';
   yAxisLabel: string = '';
   yLeftAxisScaleFactor: string = '';
   yRightAxisScaleFactor: string = '';
   showGridLines: boolean = true;
   showRightGridLines: boolean = true;
   innerPadding: string = '10%';
   animations: boolean = false;
   showRightYAxisLabel: boolean = false;
   yAxisLabelRight: string = '';
   tooltipDisabled: boolean = false;
   schemeType: string = "ordinal";
   roundDomains: boolean = true;
   noBarWhenZero: boolean = true;
   lineChartScheme = {
     selectable: true,
     group: 'ordinal',
     domain: [COLORS.lightBlue, COLORS.darkBlue, COLORS.darkYellow, '#08ded1']
   };
   comboBarScheme = {
     selectable: true,
     group: 'ordinal',
     domain: [COLORS.lightBlue, COLORS.darkBlue, COLORS.darkYellow, '#0869aa']
   };
   customColors: any = [];
   roundEdges: boolean = false;
   activeEntries: any = [];
   trimXAxisTicks: boolean = true;
   trimYAxisTicks: boolean = true;
   rotateXAxisTicks: boolean = false;
   maxXAxisTickLength: number = 10;
   maxYAxisTickLength: number = 16;
   barPadding: number = 1;
   groupPadding: number = 15;
   onlyPoints: boolean = true;

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;

  chargesAssetsOption: string = 'panels.chagesAssets';
  chargesMethodsOption: string = 'panels.chagesMethods';
  amountOption: string = 'panels.amount';
  receiptsOption: string = 'panels.receipts';
  chargesAssetsMethodsSelectedOption: string = 'panels.chagesAssets';
  amountReceiptsSelectedOption: string = 'panels.amount';

  graphName: string = 'cancellationCharges';
  restName: string;
  rateName: string;
  monthNames: any;
  barGraphLoaded: boolean = false;
  pieGraphLoaded: boolean = false;
  actualYear: number;
  numberOfBarsToPrint: number = 4;
  pieGraphLabels: any = [];
  colorList: any = [COLORS.lightBlue, COLORS.pink, COLORS.lightPurple, COLORS.lightOrange, COLORS.lightestBlue, COLORS.darkGreen, COLORS.lightGreen, COLORS.darkYellow, COLORS.darkBlue];
  colorListCSS: any = ['light-blue', 'pink', 'light-purple', 'light-orange', 'lightest-blue', 'dark-green', 'light-green', 'dark-yellow', 'dark-blue'];

  type_report_forms_year: string = 'fy';
  type_report_forms_month: string = 'fm';
  type_report_methods_year: string = 'my';
  type_report_methods_month: string = 'mm';

  amountAssetsPieGraph: any;
  amountMethodsPieGraph: any;
  receiptsAssetsPieGraph: any;
  receiptsMethodsPieGraph: any;

  amountAssetsBarGraph: any;
  amountMethodsBarGraph: any;
  receiptsAssetsBarGraph: any;
  receiptsMethodsBarGraph: any;

  amountAssetsRate: any;
  amountMethodsRate: any;
  receiptsAssetsRate: any;
  receiptsMethodsRate: any;

  amountAssetsPieGraphLabels: any;
  amountMethodsPieGraphLabels: any;
  receiptsAssetsPieGraphLabels: any;
  receiptsMethodsPieGraphLabels: any;

  barGraphToPrint: any = [];
  pieGraphToPrint: any = [];
  rateToPrint: any = [];

  constructor(
    private dialog: MatDialog,
    private apimstService: ApimstService,
    private eventsService: EventsService,
    private filtersService: FiltersService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {

    this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        const others = this.translateService.get('labels.rest');
        const months = this.translateService.get('abbreviatedMonthNames');
        const rate = this.translateService.get('labels.tax');

        forkJoin([others, months, rate])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          {
            next: results => {
              this.restName = results[0];
              this.monthNames = results[1];
              this.rateName = results[2];
            },
            complete: () => {
              if (data.length !== 0) {
                this.filtersValues = data;
                this.actualYear = parseFloat(this.filtersValues.filter(v => v.name === 'year')[0].value);
                this.resetVariables();
                this.assignData();
              }
            }
          }
        );
      }
    );

    this.eventsService.policiesBonusOption$.subscribe(
      data => {
        const option = data[0];
        const name = data[1];
        if ((option === this.chargesAssetsOption || option === this.chargesMethodsOption) && name === this.graphName) {
          this.chargesAssetsMethodsSelectedOption = option;
          this.assignData();
        } else if ((option === this.amountOption || option === this.receiptsOption) && name === this.graphName) {
          this.amountReceiptsSelectedOption = option;
          this.assignData();
        }
      }
    );
  }

  // Method to retrieve the cancellation charges
  private retrieveCancellationCharges(type_data: string, type_report: string, filtersValues: any) {
    this.pieGraphLoaded = false;
    this.barGraphLoaded = false;

    this.apimstService.getPaymentForms(type_data, type_report, filtersValues)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        if (type_report === 'fy' || type_report === 'my') {
          this.getBarGraph(data, type_data, type_report);

        } else if (type_report === 'fm' || type_report === 'mm') {
          this.getPieGraph(data, type_data, type_report);
        }
      },
      error =>
       console.log('An error occurred while retrieving payment forms ' + error)
    );
  }

  // Method to get the vertical bar graph data
  private getBarGraph(barGraphData: Array<YearPaymentForms>, type_data: string, type_report: string) {
    if (barGraphData.length !== 0) {
      let barGraph: any = [];
      let rate: any = [];
      let lineGraph: any = [];

      const sortedBarGraphData = _.sortBy(barGraphData, [function(o) { return parseFloat(o.value.replace(/,/g, '')) }]).reverse();

      let accumulatedPreviousValue = 0;
      let accumulatedActualValue = 0;
      for (let [_index, charge] of sortedBarGraphData.entries()) {
        if (_index < this.numberOfBarsToPrint) {
          barGraph.push(
            {
              name: charge?.name,
              series: [
                {
                  name: this.actualYear - 1,
                  value: (charge?.value_previous || trim(charge?.value_previous).length > 0) ?  parseFloat(charge?.value_previous.replace(/,/g, '')) : 0
                },
                {
                  name: this.actualYear,
                  value: (charge?.value || trim(charge?.value).length > 0) ?  parseFloat(charge?.value.replace(/,/g, '')) : 0
                }
              ]
            }
          );

          rate.push(
            {
              name: charge?.name,
              value: (charge?.rate || trim(charge?.rate).length > 0) ?  parseFloat(charge?.rate.replace(/,/g, '')) : 0
            }
          );
        } else {
          accumulatedActualValue += (charge?.value || trim(charge?.value).length > 0) ?  parseFloat(charge?.value.replace(/,/g, '')) : 0;
          accumulatedPreviousValue += (charge?.value_previous || trim(charge?.value_previous).length > 0) ?  parseFloat(charge?.value_previous.replace(/,/g, '')) : 0 ;

          rate.push(
            {
              name: this.restName,
              value: 0
            }
          );
        }
      }

      if (sortedBarGraphData.length > this.numberOfBarsToPrint) {
        barGraph.push(
          {
            name: this.restName,
            series: [
              {
                name: this.actualYear - 1,
                value: accumulatedPreviousValue
              },
              {
                name: this.actualYear,
                value: accumulatedActualValue
              }
            ]
          }
        );
      }

      lineGraph.push(
        {
          name: this.rateName,
          series: rate
        }
      );

      if (type_data === 'p' && type_report === 'my' ) {
        this.amountAssetsBarGraph = barGraph;
        this.amountAssetsRate = lineGraph;
        this.barGraphLoaded = true;
      } else if (type_data === 'p' && type_report === 'fy' ) {
        this.amountMethodsBarGraph = barGraph;
        this.amountMethodsRate = lineGraph;
        this.barGraphLoaded = true;
      } else if (type_data === 'c' && type_report === 'my' ) {
        this.receiptsAssetsBarGraph = barGraph;
        this.receiptsAssetsRate = lineGraph;
        this.barGraphLoaded = true;
      } else if (type_data === 'c' && type_report === 'fy' ) {
        this.receiptsMethodsBarGraph = barGraph;
        this.receiptsMethodsRate = lineGraph;
        this.barGraphLoaded = true;
      }

      this.assignColorBar();

    }

    this.assignDataToPrint();

    this.barGraphLoaded = true;
  }

  // Method to get the pie graph data
  private getPieGraph(pieGraphData: Array<MonthlyPaymentForms>, type_data: string, type_report: string) {
    if (pieGraphData.length !== 0) {
      this.pieGraph = [];
      for (let item of pieGraphData) {
        this.pieGraph.push({
          name: item.name,
          value: (item.percentage || trim(item.percentage).length>0) ?  parseFloat(item.percentage.replace(/,/g, '')) : 0
        });
      }

      const labels = pieGraphData.map(v => v.name);

      if (type_data === 'p' && type_report === 'mm' ) {
        this.amountAssetsPieGraph = this.pieGraph;
        this.amountAssetsPieGraphLabels = labels;
        this.pieGraphLoaded = true;
      } else if (type_data === 'p' && type_report === 'fm' ) {
        this.amountMethodsPieGraph = this.pieGraph;
        this.amountMethodsPieGraphLabels = labels;
        this.pieGraphLoaded = true;
      } else if (type_data === 'c' && type_report === 'mm' ) {
        this.receiptsAssetsPieGraph = this.pieGraph;
        this.receiptsAssetsPieGraphLabels = labels;
        this.pieGraphLoaded = true;
      } else if (type_data === 'c' && type_report === 'fm' ) {
        this.receiptsMethodsPieGraph = this.pieGraph;
        this.receiptsMethodsPieGraphLabels = labels;
        this.pieGraphLoaded = true;
      }

    }

    this.assignDataToPrint();

    this.pieGraphLoaded = true;
  }

  // Method to assign data according to the selected option
  private assignData() {
    if (this.chargesAssetsMethodsSelectedOption === this.chargesAssetsOption && this.amountReceiptsSelectedOption === this.amountOption && this.amountAssetsBarGraph.length === 0 && this.amountAssetsPieGraph.length === 0 && this.amountAssetsRate.length === 0) {
      this.retrieveCancellationCharges('p', 'my', this.filtersValues);
      this.retrieveCancellationCharges('p', 'mm', this.filtersValues);
    } else if (this.chargesAssetsMethodsSelectedOption === this.chargesAssetsOption && this.amountReceiptsSelectedOption === this.receiptsOption && this.receiptsAssetsBarGraph.length === 0 && this.receiptsAssetsPieGraph.length === 0 && this.receiptsAssetsRate.length === 0) {
      this.retrieveCancellationCharges('c', 'my', this.filtersValues);
      this.retrieveCancellationCharges('c', 'mm', this.filtersValues);
    } else if (this.chargesAssetsMethodsSelectedOption === this.chargesMethodsOption && this.amountReceiptsSelectedOption === this.amountOption && this.amountMethodsBarGraph.length === 0 && this.amountMethodsPieGraph.length === 0 && this.amountMethodsRate.length === 0) {
      this.retrieveCancellationCharges('p', 'fy', this.filtersValues);
      this.retrieveCancellationCharges('p', 'fm', this.filtersValues);
    } else if (this.chargesAssetsMethodsSelectedOption === this.chargesMethodsOption && this.amountReceiptsSelectedOption === this.receiptsOption && this.receiptsMethodsBarGraph.length === 0 && this.receiptsMethodsPieGraph.length === 0 && this.receiptsMethodsRate.length === 0) {
      this.retrieveCancellationCharges('c', 'fy', this.filtersValues);
      this.retrieveCancellationCharges('c', 'fm', this.filtersValues);
    } else {
      this.assignDataToPrint();
    }
  }

  // Method to assign data to print according to the selected option
  private assignDataToPrint() {
    this.showPieDialogButton = false;

    if (this.chargesAssetsMethodsSelectedOption === this.chargesAssetsOption && this.amountReceiptsSelectedOption === this.amountOption) {
      this.pieGraphToPrint = this.amountAssetsPieGraph;
      this.barGraphToPrint = this.amountAssetsBarGraph;
      this.rateToPrint = this.amountAssetsRate;
      this.pieGraphLabels = this.amountAssetsPieGraphLabels;
      if(this.amountAssetsPieGraph.length > 0) { this.showPieDialogButton = true; }
      // this.assignColorPieGraph();
    } else if (this.chargesAssetsMethodsSelectedOption === this.chargesAssetsOption && this.amountReceiptsSelectedOption === this.receiptsOption) {
      this.pieGraphToPrint = this.receiptsAssetsPieGraph;
      this.barGraphToPrint = this.receiptsAssetsBarGraph;
      this.rateToPrint = this.receiptsAssetsRate;
      this.pieGraphLabels = this.receiptsAssetsPieGraphLabels;
      if(this.receiptsAssetsPieGraph.length > 0) { this.showPieDialogButton = true; }
      // this.assignColorPieGraph();
    } else if (this.chargesAssetsMethodsSelectedOption === this.chargesMethodsOption && this.amountReceiptsSelectedOption === this.amountOption) {
      this.pieGraphToPrint = this.amountMethodsPieGraph;
      this.barGraphToPrint = this.amountMethodsBarGraph;
      this.rateToPrint = this.amountMethodsRate;
      this.pieGraphLabels = this.amountMethodsPieGraphLabels;
      if(this.amountMethodsPieGraph.length > 0) { this.showPieDialogButton = true; }
      // this.assignColorPieGraph();
    } else if (this.chargesAssetsMethodsSelectedOption === this.chargesMethodsOption && this.amountReceiptsSelectedOption === this.receiptsOption) {
      this.pieGraphToPrint = this.receiptsMethodsPieGraph;
      this.barGraphToPrint = this.receiptsMethodsBarGraph;
      this.rateToPrint = this.receiptsMethodsRate;
      this.pieGraphLabels = this.receiptsMethodsPieGraphLabels;
      if(this.receiptsMethodsPieGraph.length > 0) { this.showPieDialogButton = true; }
      // this.assignColorPieGraph();
    }
  }

  // Method to reset data variables
  private resetVariables() {
    this.amountAssetsPieGraph = [];
    this.amountMethodsPieGraph = [];
    this.receiptsAssetsPieGraph = [];
    this.receiptsMethodsPieGraph = [];

    this.amountAssetsBarGraph = [];
    this.amountMethodsBarGraph = [];
    this.receiptsAssetsBarGraph = [];
    this.receiptsMethodsBarGraph = [];

    this.amountAssetsRate = [];
    this.amountMethodsRate = [];
    this.receiptsAssetsRate = [];
    this.receiptsMethodsRate = [];

    this.amountAssetsPieGraphLabels = [];
    this.amountMethodsPieGraphLabels = [];
    this.receiptsMethodsPieGraphLabels = [];
    this.receiptsAssetsPieGraphLabels = [];
  }

  // Method to assign the colors to the graph bars
  private assignColorBar() {
    this.customColors = [];
    this.customColors.push(
      {
        name: (this.actualYear-1).toString(),
        value: COLORS.lightBlue
      },
      {
        name: (this.actualYear).toString(),
        value: COLORS.darkBlue
      },
      {
        name: this.rateName,
        value: COLORS.darkYellow
      }
    )
  }

  // Method to assign the colors to the pie graph
  private assignColorPieGraph() {
    for (let _index = 0; _index < this.pieGraphLabels.length; _index++) {
      this.customColorsPieGraph.push(
        {
          name: this.pieGraphLabels[_index],
          value: this.colorList[_index]
        }
      )
    }
  }

  // Method to format the right y axis labels with '%'
  yRightAxisTickFormatting(val) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return  formatPipe.transform(val) + '%';
  }

  // Method to format the right y axis labels
  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value);
  }

  openPieChartDialog(): void {
    this.dialog.open(PieChartDialogComponent, {
      data : {
        sectionTitle: 'panels.charges',
        results: this.pieGraphToPrint,
        view: this.pieDialogView,
        gradient: this.gradient,
        showLegend: this.showLegendPie,
        showLabels: this.pieDialogShowLabels,
        isDoughnut: this.isDoughnut,
        legendPosition: this.pieDialogLegendPosition,
        legendTitle: this.legendTitle,
        tooltipDisabled: this.tooltipDisabled,
        animations: this.animations,
        colorScheme: this.colorSchemePie,
        arcWidth: this.arcWidth,
        tooltipTitle: this.tooltipTitle,
        tooltipNumber: this.tooltipNumber

      },
      panelClass: ['mat-dialog-gen', 'mat-dialog-pie-gen', 'mat-dialog-pie-chart-monthly'],
    })
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

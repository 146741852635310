export var multi = [
  {
    "name": "Jul19",
    "series": [
      {
        "name": "R. Venta",
        "value": 62000000
      },
      {
        "name": "R. Asistencia",
        "value": 73000000
      },
      {
        "name": "R. Admin. Pólizas",
        "value": 83000000
      },
      {
        "name": "R. Siniestros",
        "value": 89400000
      },
      {
        "name": "R. Cobranza",
        "value": 89400000
      }
    ]
  },

  {
    "name": "Jul20",
    "series": [
      {
        "name": "R. Venta",
        "value": 32000000
      },
      {
        "name": "R. Asistencia",
        "value": 23000000
      },
      {
        "name": "R. Admin. Pólizas",
        "value": 53000000
      },
      {
        "name": "R. Siniestros",
        "value": 69400000
      },
      {
        "name": "R. Cobranza",
        "value": 19400000
      }
    ]
  },

  {
    "name": "Objetivo",
    "series": [
      {
        "name": "R. Venta",
        "value": 32000000
      },
      {
        "name": "R. Asistencia",
        "value": 23000000
      },
      {
        "name": "R. Admin. Pólizas",
        "value": 93000000
      },
      {
        "name": "R. Siniestros",
        "value": 29400000
      },
      {
        "name": "R. Cobranza",
        "value": 49400000
      }
    ]
  },
 
];

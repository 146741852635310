<component-loader *ngIf="!loaded" [width]="580" [height]="230"></component-loader>

<div class="mt-2 d-flex justify-content-end align-items-center" *ngIf="loaded">
    <app-legend *ngIf="lineName[0]" class="mr-2" name={{lineName[0]}} color="dark-blue" type="line" gradient="v-gradient"></app-legend>
    <app-legend *ngIf="lineName[1]" class="mr-2" name={{lineName[1]}} color="dark-yellow" type="line" gradient="h-gradient"></app-legend>
    <app-legend *ngIf="lineName[2]" class="mr-2" name={{lineName[2]}} color="lightest-blue" type="line" gradient="h-gradient"></app-legend>
    <app-legend *ngIf="lineName[3]" class="mr-2" name={{lineName[3]}} color="light-purple" type="line" gradient="h-gradient"></app-legend>
    <app-legend *ngIf="lineName[4]" name={{lineName[4]}} color="light-blue" type="line" gradient="h-gradient"></app-legend>
</div>

<div class="line-charts-gen no-x-axis has-y-lines" *ngIf="loaded">
    <ngx-charts-line-chart
        [view]="view"
        [scheme]="colorScheme"
        [legend]="legend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [showGridLines]="showGridLines"
        [showRefLines]="showRefLines"
        [yScaleMin]="yScaleMin"
        [yScaleMax]="yScaleMax"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [timeline]="timeline"
        [results]="graphData"
        [tooltipDisabled]="tooltipDisabled"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [rotateXAxisTicks]="rotateXAxisTicks"
        >
    </ngx-charts-line-chart>
</div>

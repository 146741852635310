import { Component, OnInit, OnDestroy } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'
import { QualityRolling } from 'src/app/shared/models/quality-rolling';
import { EventsService } from 'src/app/shared/services/events/events.service'; 
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { ExcelFileInfo } from 'src/app/shared/models/excel-file-info';

@Component({
  selector: 'quality-claims-graphic',
  templateUrl: './quality-claims-graphic.component.html',
  styleUrls: ['./quality-claims-graphic.component.scss']
})
export class QualityClaimsGraphicComponent implements OnInit, OnDestroy {

  view: any[] = [400, 150];

  // options
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  showGridLines: boolean = true;
  showRefLines: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  timeline: boolean = false;
  tooltipDisabled: boolean = false;
  rotateXAxisTicks: boolean = false;

  colorScheme = {
    domain: [COLORS.darkGreen, COLORS.lightGreen, '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;
  monthNames: any;
  averageName: string;
  claimsName: string;
  claimReasonName: string;
  qualityClaimsRolling: any = [];
  loaded: boolean = false;
  qualityClaimSelected: any = [];
  xAxisTicksFinal: any = [];
  xAxisTicksValues: any = [];

  data_total: any[] = [];
  data_sheet_names: any[] = [];
  data_filter_details: any[] = [];
  claimReason: string = 'Todas';

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private eventsService: EventsService,
    private translateService: TranslateService,
    private excelService: ExcelService,
    private events: EventsService
  ) { }

  ngOnInit(): void {

    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const months = this.translateService.get('abbreviatedMonthNames');
          const average = this.translateService.get('labels.average');
          const claims = this.translateService.get('labels.claims');
          const claimReason = this.translateService.get('labels.claimReason');
      
          forkJoin([months, average, claims, claimReason]).pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              {
                next: results => {
                  this.monthNames = results[0];
                  this.averageName = results[1];
                  this.claimsName = results[2];
                  this.claimReasonName = results[3];
                },
                complete: () => {
                  if (data.length !== 0) {
                    this.filtersValues = data;
                    this.qualityClaimSelected = [];
                    this.retrieveQualityClaims(this.qualityClaimSelected, this.filtersValues);
                  }
                }
              });
        }
      );

    this.eventsService.salesQualityClaims$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(data => {
      this.qualityClaimSelected = [];
      if (data.value !== '') {
        this.qualityClaimSelected.push(data.value);
      }
      this.claimReason = data.selectedOptions[0].label;
      this.retrieveQualityClaims(this.qualityClaimSelected, this.filtersValues);
    });  
  }

  // Method to retrieve the quality claim data
  private retrieveQualityClaims(qualityClaimSelected: [], filtersValues: any) {
    this.loaded = false;
    this.apimstService.getQualityClaimsRolling(qualityClaimSelected, filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data: Array<QualityRolling>) => {
          let rateSeries: any = [];
          let avgRateSeries: any = [];
          let rateToExport: any[] = [this.claimsName + ' (%)'];
          let avgRateToExport: any[] = [this.averageName + ' (%)'];
          let headers: any[] = [''];

          this.data_total = [];
          this.qualityClaimsRolling = [];
          this.xAxisTicksValues = [];
          this.xAxisTicksFinal = [];

          if (data.length !== 0) {
            this.data_filter_details = data[0].filters;

            for(let month of data) {

              const date = this.monthNames[month.month] + ' ' + month.year.slice(2, 4);
              const rate = month.rate ? parseFloat(month.rate) : 0;
              const avgRate = month.rate_avg ? parseFloat(month.rate_avg) : 0;

              rateSeries.push({
                name: date,
                value: rate
              });

              avgRateSeries.push({
                name: date,
                value: avgRate
              });

              headers.push(date);
              rateToExport.push(rate);
              avgRateToExport.push(avgRate);

              this.xAxisTicksValues.push(date);
              
            }

            this.qualityClaimsRolling.push(
              {
                name: this.claimsName,
                series: rateSeries
              },
              {
                name: this.averageName,
                series: avgRateSeries
              }
            );

            this.getXAxisValues();

            this.data_total.push([[this.claimReasonName, this.claimReason], [], headers, rateToExport, avgRateToExport]);

            this.data_sheet_names = [this.claimsName];
            let excelInfo: ExcelFileInfo = {json: this.data_total, headers: this.data_sheet_names, excelFileName: 'sales_quality_claims_chart', filtersValues: this.data_filter_details, filters: true};
            this.events.salesClaimsExcelInfoLoaded$.next(excelInfo);

          }

          this.loaded = true;
        },

        error => console.log("An error ocurred while retrieving quality claims rolling data: " + error)


      );
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // Method to format the y axis ticks with %
  yAxisTickFormatting(val) {
    return val.toLocaleString() + "%";
  }

  // Method to get only one x axis value in two 
  private getXAxisValues(): void {
    for (var _i = 0; _i < this.xAxisTicksValues.length; _i += 2) {
      this.xAxisTicksFinal.push(this.xAxisTicksValues[_i]);
    } 
  }

   // Method to export data to XSLX
   exportAsXLSX():void {
    this.data_sheet_names = [this.claimsName];
    this.excelService.exportAsExcelFile(this.data_total, this.data_sheet_names,  'sales_quality_claims_chart', this.data_filter_details);
  }

}

<div class="kpi-home">
    <span class="title d-block mb-1">{{ item.title | translate }}</span>
    <span class="trend-container d-flex align-items-center" *ngIf="item.main_value != -1">
        <span class="trend-main-value h5 mb-0" *ngIf="item.main_value !== undefined">
            {{ item.main_value }}{{ item.currency }}{{ item.main_value_suffix }}<span class="small">{{ item.main_value_small }}</span>
        </span>
        <span 
            class="material-icons icon ml-n1" 
            [ngClass]="{'text-brand-success': item.trend_color === 1, 'text-brand-danger': item.trend_color === 0, 'up': item.trend_direction === 1, 'down': item.trend_direction === 0}"
            *ngIf="item.trend_value !== undefined && item.show_arrow !== false">
                arrow_drop_up
        </span>
        <span class="trend-second-value ml-n1" *ngIf="item.trend_value !== undefined && item.show_percentage !== false">
            <span class="trend-value-previous" *ngIf="item.trend_value_previous">{{ item.trend_value_previous | translate }} </span>
            {{ item.trend_value }}%
        </span>
    </span>
</div>
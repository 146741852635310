<app-widget>

    <div class="section-header-block d-flex align-items-center pb-2">
        <span class="title section-card-title">{{ 'panels.quality' | translate }}</span>
        <app-btn-gobernance-info 
            class="ml-2" 
            [endpoint]="endpoint" 
            [endpointParams]="endpointParams"
            *ngIf="hasGobernanceInfo">
        </app-btn-gobernance-info>
    </div>

    <div class="mt-n2 mb-2 d-flex justify-content-center align-items-end">
        <component-loader *ngIf="!qualityEarlyFallIndicatorLoaded" class="mx-5 text-center" [width]="150" [height]="100"></component-loader>
        <app-kpi-color *ngIf="qualityEarlyFallIndicatorLoaded" class="mx-5 text-center" [title]="'panels.earlyFallWithMonths'" [mainValue]="qualityEarlyFallIndicator?.value" [mainValueUnit]="'%'" [percentagePrevious]="percentagePrevious" [percentage]="qualityEarlyFallIndicator?.diff" [trend]="qualityEarlyFallIndicator?.diff_trend"></app-kpi-color>
        <component-loader *ngIf="!qualityPolicyCancellationsIndicatorLoaded" class="mx-5 text-center" [width]="150" [height]="100"></component-loader>
        <app-kpi-color *ngIf="qualityPolicyCancellationsIndicatorLoaded" class="mx-5 text-center" [title]="'panels.cancellationRateUnrecognizedSale'" [mainValue]="qualityPolicyCancellationsIndicator?.value" [mainValueUnit]="'%'" [percentagePrevious]="percentagePrevious" [percentage]="qualityPolicyCancellationsIndicator?.diff" [trend]="qualityPolicyCancellationsIndicator?.diff_trend"></app-kpi-color>
        <component-loader *ngIf="!qualityPolicyCustomerCollectIndicatorLoaded" class="mx-5 text-center" [width]="150" [height]="100"></component-loader>
        <app-kpi-color *ngIf="qualityPolicyCustomerCollectIndicatorLoaded" class="mx-5 text-center" [title]="'panels.clientsPercentageCharge'" [mainValue]="qualityPolicyCustomerCollectIndicator?.value" [mainValueUnit]="'%'" [percentagePrevious]="percentagePrevious" [percentage]="qualityPolicyCustomerCollectIndicator?.diff" [trend]="qualityPolicyCustomerCollectIndicator?.diff_trend"></app-kpi-color>
    </div>

</app-widget>
import { Component, OnInit } from "@angular/core";
import { Animations } from "src/app/shared/animations/animations";
import { EventsService } from "src/app/shared/services/events/events.service";

@Component({
  selector: "app-document-management-user-panel",
  templateUrl: "./document-management-user-panel.component.html",
  styleUrls: ["./document-management-user-panel.component.scss"],
  animations: [
    Animations.slideToRight,
    Animations.fadeInOut,
  ]
})
export class DocumentManagementUserPanelComponent implements OnInit {

  constructor(
    private events: EventsService
  ) {}

  ngOnInit(): void {}

  closeUserPanel() {
    this.events.showUserPanel$.emit(false);
  }

}

import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-pre-home',
  templateUrl: './pre-home.component.html',
  styleUrls: ['./pre-home.component.scss']
})
export class PreHomeComponent implements OnInit {

  clientsActive: boolean = environment.menuVisibility.clients;
  salesActive: boolean = environment.menuVisibility.sale;
  cancellationActive: boolean = environment.menuVisibility.cancellation;
  sinistersActive: boolean = environment.menuVisibility.sinisters;
  backofficeActive: boolean = environment.menuVisibility.backoffice;

  constructor() { }

  ngOnInit(): void {
  }

}

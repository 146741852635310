import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-daily-sales-graph-dialog',
  templateUrl: './daily-sales-graph-dialog.component.html',
  styleUrls: ['./daily-sales-graph-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DailySalesGraphDialogComponent implements OnInit {

    // Graph options
    view: any[] = [1240, 580];
    colorScheme: boolean;
    schemeType: boolean;
    multi: any[];
    gradient: boolean;
    showXAxis: boolean;
    showYAxis: boolean;
    showLegend: boolean;
    showXAxisLabel: boolean;
    showYAxisLabel: boolean;
    xAxisLabel: string;
    yAxisLabel: string;
    animations: boolean;
    xAxisTicks: any[];
    
  // Deafult constructor  
  constructor(
    @Inject(MAT_DIALOG_DATA) data
  ) { 
      this.colorScheme = data.colorScheme;
      this.schemeType = data.schemeType;
      this.multi = data.results;
      this.gradient = data.gradient;
      this.showXAxis = data.showXAxis;
      this.showYAxis = data.showYAxis;
      this.showLegend = data.showLegend;
      this.showXAxisLabel = data.showXAxisLabel;
      this.showYAxisLabel = data.showYAxisLabel;
      this.xAxisLabel = data.xAxisLabel;
      this.yAxisLabel = data.yAxisLabel;
      this.animations = data.animations;
  }

  // OnInit
  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-santander-segmentation',
  templateUrl: './santander-segmentation.component.html',
  styleUrls: ['./santander-segmentation.component.scss']
})
export class SantanderSegmentationComponent implements OnInit {

    // Drowpdown options
    dropdownId: string = "santanderSegmentationClients";
    santanderSegmentationOption: any = [];
    title: string = "";
    allName: string;
  
  constructor() { }

  ngOnInit(): void {
    this.santanderSegmentationOption.push({
      id: '',
      type: 'santander-segmentation',
      name: 'Segmentación Santander',
      selected: true
    });
  }

}

import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Page } from 'src/app/shared/models/pager-pages';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss']
})
export class PagerComponent implements OnInit {

  @Input() pages: Page[];
  @Output() pageChange: EventEmitter<Page> = new EventEmitter();
  counter: number = 1;
  prevState: boolean = false;
  nextState: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.checkIndex();
    this.sendPageInfo();
  }

  goPrev(): void {
    if(this.counter > 1) this.counter = this.counter-1;
    this.checkIndex();
    this.sendPageInfo();
  }

  goNext(): void {
    if(this.counter < this.pages.length) this.counter = this.counter+1;
    this.checkIndex();
    this.sendPageInfo();
  }

  checkIndex(): void {
    this.prevState = this.counter == 1 ? true: false;
    this.nextState = this.counter == this.pages.length ? true: false;
  }

  sendPageInfo(): void {
    this.pageChange.emit(this.pages[this.counter-1]);
  }

}

<div class="line-charts-gen only-border-bar-3 only-border-bar-4 mt-2">
  <app-difference-bar-label [width] = "labelWidth" [viewBox] = "viewBox2" [labelDiff] = "labelDiff"></app-difference-bar-label>
  <app-difference-bar-label [width] = "labelWidth" [viewBox] = "viewBox3" [labelDiff] = "labelDiff"></app-difference-bar-label>
  <app-difference-bar-label [width] = "labelWidth" [viewBox] = "viewBox1" [labelDiff] = "labelDiff"></app-difference-bar-label>
  <ngx-charts-bar-vertical
    [view]="view"
    [scheme]="colorScheme"
    [results]="single"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [roundDomains]="roundDomains"
    [showDataLabel]="showDataLabel"
    [barPadding]="barPadding"
    [customColors] = "customColors"
    [roundEdges] = "roundEdges"
    [rotateXAxisTicks] = "rotateXAxisTicks">
    <svg:g height=200 width=300>
      <line x1=0 x2=300 y1=40 y2=100 style="stroke:white; stroke-width:1"></line>
    </svg:g>
    
  </ngx-charts-bar-vertical>
</div>
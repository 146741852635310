<app-widget>

    <div class="section-header-block d-flex align-items-center pb-2">
        <span class="title section-card-title">{{ 'panels.inProcessQuality' | translate }}</span>
        <app-btn-gobernance-info 
            class="ml-2" 
            [endpoint]="endpoint" 
            [endpointParams]="endpointParams"
            *ngIf="hasGobernanceInfo">
        </app-btn-gobernance-info>
    </div>

    <div class="mt-4 d-flex flex-column justify-content-end align-items-center">

        <component-loader *ngIf="!qualityPolicyAvgSentTimeIndicatorLoaded" class="text-center mb-4" [width]="210" [height]="100"></component-loader>
        <app-kpi-color *ngIf="qualityPolicyAvgSentTimeIndicatorLoaded" class="text-center" [title]="'panels.averageTimepolicySent'" [mainValue]="qualityPolicyAvgSentTimeIndicator?.value" [mainValueUnit]="' ' + qualityPolicyAvgSentTimeIndicator.unit" [percentagePrevious]="percentagePrevious" [percentage]="qualityPolicyAvgSentTimeIndicator?.diff" [trend]="qualityPolicyAvgSentTimeIndicator?.diff_trend"></app-kpi-color>

        <component-loader *ngIf="!qualityPolicySentOnTimeIndicatorLoaded" class="text-center mb-4" [width]="210" [height]="100"></component-loader>
        <app-kpi-color *ngIf="qualityPolicySentOnTimeIndicatorLoaded" class="text-center" [title]="'panels.averagePoliciesOnTime'" [mainValue]="qualityPolicySentOnTimeIndicator?.value" [mainValueUnit]="'%'" [percentagePrevious]="percentagePrevious" [percentage]="qualityPolicySentOnTimeIndicator?.diff" [trend]="qualityPolicySentOnTimeIndicator?.diff_trend"></app-kpi-color>

        <component-loader *ngIf="!qualityPolicyWithoutBackupIndicatorLoaded" class="text-center mb-4" [width]="210" [height]="100"></component-loader>
        <app-kpi-color *ngIf="qualityPolicyWithoutBackupIndicatorLoaded" class="text-center" [title]="'panels.averagePoliciesNoEngagement'" [mainValue]="qualityPolicyWithoutBackupIndicator?.value" [mainValueUnit]="'/' + qualityPolicyWithoutBackupIndicator?.target" [percentagePrevious]="percentagePrevious" [percentage]="qualityPolicyWithoutBackupIndicator?.diff" [trend]="qualityPolicyWithoutBackupIndicator?.diff_trend"></app-kpi-color>

        <component-loader *ngIf="!qualityPolicyApprovalTimeIndicatorLoaded" class="text-center mb-4" [width]="210" [height]="100"></component-loader>
        <app-kpi-color *ngIf="qualityPolicyApprovalTimeIndicatorLoaded" class="text-center" [title]="'panels.policyApprovalPeriod'" [mainValue]="qualityPolicyApprovalTimeIndicator?.value" [mainValueUnit]="' ' + qualityPolicyApprovalTimeIndicator.unit" [percentagePrevious]="percentagePrevious" [percentage]="qualityPolicyApprovalTimeIndicator?.diff" [trend]="qualityPolicyApprovalTimeIndicator?.diff_trend"></app-kpi-color>

    </div>

</app-widget>


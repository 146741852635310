import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sinisters-claims',
  templateUrl: './sinisters-claims.component.html',
  styleUrls: ['./sinisters-claims.component.scss']
})
export class SinistersClaimsComponent implements OnInit {
  
  title: string = "";
  dropdownId: string = 'sinistersQualityClaims';
  loaded: boolean = false;

  sinistersQualityClaimReasons: any = [];

  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();
  qualityClaimReasons: any = [];
  allF: string;

  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "sinisters-quality--claims";
  
  constructor(
    private apimstService: ApimstService,
    private translateService: TranslateService,
    private filtersService: FiltersService,
    private i18n: I18nService,
    private atlasHelper: AtlasHelperService
  ) { }

  ngOnInit(): void {
    this.subscribeToFiltersValues();
    this.subscribeToActiveLanguage();
    // this.translateService.get('labels.allF').pipe(takeUntil(this.unsubscribe$)).subscribe(data => this.allF = data);
    // this.retrieveSinistersQualityClaimsReason();
  }

  private retrieveSinistersQualityClaimsReason() {
    this.loaded = false;
    this.apimstService.getClaimReasonMaster().pipe(takeUntil(this.unsubscribe$)).subscribe(
      (data) => {
        this.qualityClaimReasons = [];
        const sinistersQualityClaimReasons = data.filter(v => v.type_claim_reason_id === '5');
        this.qualityClaimReasons.push(
          {
            id: '',
            type: 'sinisters-claim',
            name: this.allF,
            selected: true
          }
        );
        for(let reason of sinistersQualityClaimReasons) {
          this.qualityClaimReasons.push(
            {
              id: reason.subtype_claim_reason_id,
              parentId: reason.type_claim_reason_id,
              type: 'sale-claim',
              name: reason.subtype_claim_reason_desc,
              selected: false
            }
          );
        }

        this.loaded = true;
      },

      error => console.log("An error ocurred while retrieving sinisters quality claims reasons data " + error)
    );
  }

  subscribeToFiltersValues(): void {
    this.subscription = this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: filters => {
          this.translateService.get('labels.allF')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: data => this.allF = data,
              complete: () => {
                this.retrieveSinistersQualityClaimsReason();
              }
            });
        }
      })
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

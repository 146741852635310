import { Component, Input, OnInit } from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { CurrencyService } from 'src/app/shared/services/currency/currency.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';

@Component({
  selector: 'cancellation-charge-block-funnel',
  templateUrl: './cancellation-charge-block-funnel.component.html',
  styleUrls: ['./cancellation-charge-block-funnel.component.scss']
})
export class CancellationChargeBlockFunnelComponent implements OnInit {

  // Loader
  loaded: boolean;

  // Items to retrieve data
  items: any[] = [];
  itemsAmount: any[] = [];
  itemsReceipt: any[] = [];
  receiptsToCollect: any = {};
  receiptsCollected: any = {};
  receiptsToCollectAmount: any;
  receiptsCollectedAmount: any;

  percentagePrevious: string = "labels.pm";

  // Option tabs
  actualOption: string = 'panels.amount';
  optionTabAmount: string = 'panels.amount';
  optionTabReceipt: string = 'panels.receipts';
  graphName: string = 'collectionRateCharge';

  // Filters value
  filtersValues: any;

  // Subscription
  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();

  // Format numbers
  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  // Check number symbol
  isNegative: boolean = false;

  constructor(
    private apimstService: ApimstService,
    private eventsService: EventsService,
    private filtersService: FiltersService,
    private utilsService: UtilsService,
    private currencyService: CurrencyService
  ) { }

  ngOnInit(): void {

    this.subscription = this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data.length !== 0) {
            this.filtersValues = data;
            this.resetVariables();
            this.assignData();
          }
        }
      );

      this.subscription = this.eventsService.policiesBonusOption$.subscribe(
        data => {
          const option = data[0];
          const name = data[1];
          if ((option === this.optionTabAmount || option === this.optionTabReceipt) && name === this.graphName ) {
            this.actualOption = option;
            this.assignData();
          }
        }
      );
  }

  // Method for retrieving the data information for the funnel
  retrieveFunnelData(type_data, data) {

    //Receipts to collect & Receipts collected
    let getReceiptsToCollectAmount = this.apimstService.getChargeFunnelIndicators(type_data, 'ir', data);   // Receipts to collect
    let ReceiptsCollectedAmount = this.apimstService.getChargeFunnelIndicators(type_data, 'cr', data);      // Receipts collected

    // Show loader
    this.loaded = false;

    forkJoin([getReceiptsToCollectAmount, ReceiptsCollectedAmount])
      .subscribe(results => {
        this.receiptsToCollectAmount = results[0];
        this.receiptsCollectedAmount = results[1];

        // Currency symbol for the 'Amount' values
        const currencySymbol = this.getCurrencySymbol();

        // Create items array
        this.receiptsToCollect.title = "funnels.receiptsToCollect";
        this.receiptsToCollect.main_value = this.receiptsToCollectAmount['value'] != null && this.receiptsToCollectAmount['value'] != "" ? this.formatPipe.transform( parseFloat( this.receiptsToCollectAmount['value'].replace(/,/g, '') ) ) + currencySymbol : '-1';
        this.receiptsToCollect.second_value_prefix = this.percentagePrevious;
        this.receiptsToCollect.second_value = this.receiptsToCollectAmount['diff'] != null ? this.formatPipe.transform( parseFloat( this.receiptsToCollectAmount['diff'].replace(/-|,/g, '')) ) + "%" : '0';
        this.receiptsToCollect.trend = this.receiptsToCollectAmount['diff_trend'] != null ? parseInt(results[0]['diff_trend']) : 0;
        this.receiptsToCollect.trend_direction = this.utilsService.isNegative( this.receiptsToCollectAmount['diff'] ) == false ? 1 : 0;

        this.receiptsCollected.title = "funnels.receiptsCollected";
        this.receiptsCollected.main_value = this.receiptsCollectedAmount['value'] != null && this.receiptsCollectedAmount['value'] != "" ? this.formatPipe.transform( parseFloat( this.receiptsCollectedAmount['value'].replace(/,/g, '') ) ) + currencySymbol : '-1';
        this.receiptsCollected.second_value_prefix = this.percentagePrevious;
        this.receiptsCollected.second_value = this.receiptsCollectedAmount['diff'] != null ? this.formatPipe.transform( parseFloat( this.receiptsCollectedAmount['diff'].replace(/-|,/g, '')) ) + "%" : '0';
        this.receiptsCollected.trend = this.receiptsCollectedAmount['diff_trend'] != null ? parseInt(this.receiptsCollectedAmount['diff_trend']) : 0;
        this.receiptsCollected.trend_direction = this.utilsService.isNegative( this.receiptsCollectedAmount['diff'] ) == false ? 1 : 0;

        const itemData: any = [];
        itemData.push(this.receiptsToCollect);
        itemData.push(this.receiptsCollected);

        if (this.actualOption === this.optionTabAmount) {
          this.itemsAmount = JSON.parse(JSON.stringify(itemData));
        } else if (this.actualOption === this.optionTabReceipt) {
          this.itemsReceipt = JSON.parse(JSON.stringify(itemData));
        }

        this.assignDataToPrint();

      });

    }

  // Method to add currency symbol if 'Amount' selected
  private getCurrencySymbol() {
    let currencySymbol: string = '';

    if (this.actualOption === this.optionTabAmount) {
      this.currencyService.currencyInfo$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(currencyInfo =>
          currencySymbol = currencyInfo.symbol
        );
    }
    return currencySymbol;
  }

  // Method to reset the data storage variables
  private resetVariables() {
    this.itemsAmount = [];
    this.itemsReceipt =  [];
  }

  // Method to assing data
  private assignData() {
    if (this.actualOption === this.optionTabAmount && this.itemsAmount.length === 0) {
      this.retrieveFunnelData('p', this.filtersValues);
    } else if (this.actualOption === this.optionTabReceipt && this.itemsReceipt.length === 0) {
      this.retrieveFunnelData('c', this.filtersValues);
    } else {
      this.assignDataToPrint();
    }
  }

  // Method to assign the data to print
  private assignDataToPrint() {
    if (this.actualOption === this.optionTabAmount) {
      this.items = this.itemsAmount;
      this.loaded = true;
    } else if (this.actualOption === this.optionTabReceipt) {
      this.items = this.itemsReceipt;
      this.loaded = true;
    }
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.subscription.unsubscribe();
  }


}

<component-loader [width]="" [height]="200" class="d-block mt-3" *ngIf="!loaded"></component-loader>
<div class="d-flex justify-content-between align-items-center mt-3" *ngIf="loaded">
    <div class="d-flex flex-column w-100">
        <div class="d-flex w-100">
            <app-rejections-indicators [indicators]="indicators" class="w-100"></app-rejections-indicators>
        </div>
        <div class="d-flex justify-content-center mt-n2">
            <app-rejections-walk 
                [evolutionGraph]="evolutionGraph" 
                [percentageKpi]="percentageKpi"
                class="sinisters-rejections-walk">
            </app-rejections-walk>
        </div>
    </div>
</div>
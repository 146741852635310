import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Subscription, Subject, combineLatest } from 'rxjs'
import { COLORS } from 'src/app/shared/globals/globals';
import { MonthlySalesGraphDialogComponent } from 'src/app/shared/dialogs/monthly-sales-graph-dialog/monthly-sales-graph-dialog.component';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { DailySale } from 'src/app/shared/models/daily-sale';
import { TranslateService } from '@ngx-translate/core';
import { shareReplay, takeUntil } from 'rxjs/operators'
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { trim } from 'lodash';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';

@Component({
  selector: 'app-daily-sale-graph',
  templateUrl: './daily-sale-graph.component.html',
  styleUrls: ['./daily-sale-graph.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class DailySaleGraphComponent implements OnInit, OnDestroy {

  loaded = false;

  actualOption: string = 'panels.bonus';
  customColors: any = [];
  planName: string;
  averageName: string;
  filtersValues: any;
  totalName: string;
  titleName: string;
  policyName: string;
  bonusName: string;

  // Options for chart creation
  view = [590, 170];
  modalView = [1210, 550];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  legendTitle: string = '';
  legendPosition: string = '';
  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  animations: boolean = false;
  schemeType: string = 'ordinal';
  colorScheme = {
    domain: [COLORS.darkBlue, COLORS.lightBlue]
  };
  yLeftAxisScaleFactor: string = '';
  yRightAxisScaleFactor: string = '';
  yRightAxisTickFormatting: string = '';
  showGridLines: boolean = true;
  innerPadding: string = '10%';
  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  barPadding: number = 4;
  tooltipDisabled: boolean = false;
  roundDomains: boolean = true;
  noBarWhenZero: boolean = true;
  barChart: any[] = [];
  lineChartSeries: any[] = [];
  lineChartScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkBlue, COLORS.lightBlue, '#ffcc00', '#ff66ff']
  };
  comboBarScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkBlue, COLORS.lightBlue]
  };
  showTotalInTooltip: boolean = true;

  selectedOption: any[] = [];

  // Subscription
  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();

  // Variables for saving the charts data
  bonusVerticalBars: any[] = [];
  policyVerticalBars: any[] = [];
  bonusHorizontalBars: any[] = [];
  policyHorizontalBars: any[] = [];
  xAxisTicks: any = [];
  bonusDataTotal: any[] = [];
  policyDataTotal: any[] = [];
  bonusDataSheetNames: any[] = [];
  policyDataSheetNames: any[] = [];

  // Variables for saving the filter option
  optionTabPolicy = 'panels.policy';
  optionTabBonus = 'panels.bonus';
  graphName: string = 'salesDetails';

  // Variables to store the date values
  day: number;
  month: number;
  year: number;
  days: any = [];

  // Color list
  colorList: any = [COLORS.darkBlue, COLORS.lightBlue, COLORS.lightOrange, COLORS.lightestBlue, COLORS.darkGreen, COLORS.darkYellow, COLORS.m6, COLORS.m8, COLORS.m12, COLORS.darkBlue, COLORS.lightBlue, COLORS.lightPurple, COLORS.darkestGreen, COLORS.lightGreen];
  colorListCSS: any = ['dark-blue', 'light-blue', 'light-orange', 'lightest-blue', 'dark-green', 'dark-yellow', 'm6', 'm8', 'm12', 'dark-blue', 'light-blue', 'dark-purple', 'darkest-green', 'light-green'];

  // Legend labels
  legendLabels = [];
  legendsDailySale = [];

   // Variables to store data to export
   data_total: any[] = [];
   data_sheet_names: any[] = [];
   data_filter_details: any[] = [];

  // Input to determine the value of the chosen option
  @Input() policyBonus: string;
  @Output() dailyLegend = new EventEmitter<any>();

  // Default constructor
  constructor(
    private dialog: MatDialog,
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private eventsService: EventsService,
    private translateService: TranslateService,
    private excelService: ExcelService
  ) { }

  // OnInit
  ngOnInit(): void {

    const filters$ = this.filtersService.filtersValues$;
    const selectedOption$ = this.eventsService.optionTabMultipleChanged$;

    combineLatest([filters$, selectedOption$])
      .pipe(shareReplay(), takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if(data[0].length !== 0 && data[1]['graphName'] === this.graphName) {
            this.filtersValues = data[0];
            this.day = parseInt(this.filtersValues.filter(v => v.name === 'day')[0].value);
            this.month = parseInt(this.filtersValues.filter(v => v.name === 'month')[0].value);
            this.year = parseInt(this.filtersValues.filter(v => v.name === 'year')[0].value);
            this.resetVariables();

            this.selectedOption = data[1]['id'];
            this.assignData();
          }
        }
      )

      this.eventsService.policiesBonusOption$.subscribe(
        data => {
          const option = data[0];
          const name = data[1];
          if ((option === this.optionTabBonus || option === this.optionTabPolicy) && name === this.graphName) {
            this.actualOption = option;
            this.assignData();
          }
        }
      );

      this.eventsService.dailySaleGraphicCustomColor$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(data => {
          this.customColors = data;
          this.loaded = true;
        });
  }

  // Method for retrieving the data information for the daily graph
  private retrieveDailyGraphData(type_data: string, filters: any, dimension: any[]) {
    this.loaded = false;

    this.getTranslations();

    this.apimstService.getDailySales(type_data, filters, dimension)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        let dailySale: DailySale = JSON.parse(JSON.stringify(data));

        // Variables initialization
        let verticalBarsData = [];
        let averageData = [];
        let horizontalBarsData = [];
        let averageToExport = [];
        let targetToExport = [];
        let headersToExport = [];

        // Add the first column values for the header, average and target data
        headersToExport.push('');
        averageToExport.push(this.averageName);
        targetToExport.push(this.planName);
        this.data_filter_details = dailySale.filters;

        if (dailySale.details !== undefined) {
          this.calculateXAxisDates();
          this.getXAxisValues();
        }

        if( dailySale.hasOwnProperty('details') ) {
          if (dailySale.details.length > 0 ) {

            // Retrieve thirtheenth value date
            // 01/13/2023 (mm/dd/yyyy: American format)
            // 13/01/2023 (dd/mm/yyyy: European format)
            // If the month is in first position -> American format
            const secondValueDate = dailySale.details[12].day;
            if (this.isDateInAmericanFormat(secondValueDate)) {
              dailySale.details.map(v =>
                {
                  let date = v.day.split('/');
                  v.day = this.padWithZeros(Number(date[1]), 2) + '/' + this.padWithZeros(Number(date[0]), 2) + '/' + date[2];
                });
            } else {
              dailySale.details.map(v =>
                {
                  let date = v.day.split('/');
                  v.day = this.padWithZeros(Number(date[0]), 2) + '/' + this.padWithZeros(Number(date[1]), 2) + '/' + date[2];
                });
            }

            for (let day of this.days) {
              let series = [];
              for (let detail of dailySale.details) {
                if (detail.day === day) {
                  for (let type of detail.types) {
                    series.push({
                      name: type.name,
                      value: (type.value || trim(type.value).length > 0) ? parseFloat(type.value.replace(/,/g, '')) : 0
                    });

                    // Store the data metric name to display in the legend
                    if (!this.legendLabels.includes(type.name)) {
                      this.legendLabels.push(type.name);
                    }
                  }
                  break;
                }
              }

              const averageValue = (data.avg || trim(data.avg).length > 0) ? parseFloat(data.avg.replace(/,/g, '')) : 0;

              verticalBarsData.push({
                'name': day,
                'series': series
              });

              averageData.push({
                'name': day,
                'value': averageValue
              });

              headersToExport.push(day);
              averageToExport.push(averageValue);

            }
          }
        }

        horizontalBarsData.push({
          'name': this.averageName,
          'series': averageData
        });

        const groupedData = this.extractDataToExport(verticalBarsData, averageToExport, targetToExport, headersToExport);

        // Bonus data (p -> premium)
        if (type_data === 'p') {
          this.bonusVerticalBars = verticalBarsData;
          this.bonusHorizontalBars = horizontalBarsData;
          this.bonusDataTotal = groupedData;
          this.bonusDataSheetNames = [this.titleName + ' - ' + this.bonusName];
        } else if (type_data === 'c') { // Policy data (c -> certificate)
          this.policyVerticalBars = verticalBarsData;
          this.policyHorizontalBars = horizontalBarsData;
          this.policyDataTotal = groupedData;
          this.policyDataSheetNames = [this.titleName + ' - '  + this.policyName];
        }

        this.assignDataToPrint();

        const legendData = {
          child: 'daily',
          legends: this.legendLabels
        }
        this.dailyLegend.emit(legendData);
        this.legendLabels = [];
      },
      error => console.log("An error ocurred while retrieving daily graph data: " + error));
  }

  // Method to extract the data to export to the Excel file
  private extractDataToExport(verticalBarsData: any[], averageToExport: any[], targetToExport: any[], headersToExport: any[]): any[] {
    let dataToExport = [];

    // Retrieve the value to export for each data metric
    for (let label of this.legendLabels) {
      let series = [];
      series.push(label);
      for (let item of verticalBarsData) {
        let filteredData = item.series.filter(v => v.name === label);
        let value = 0;
        if (filteredData.length !== 0) {
          value = filteredData[0].value;
        }
        series.push(value);
      }

      dataToExport.push({
        name: label,
        series: series
      });

    }

    // Add average and target data to array
    dataToExport.push(
      {
        name: this.averageName,
        series: averageToExport
      },
      {
        name: this.planName,
        series: targetToExport
      }
    );

    // Group the data
    let groupedData = [headersToExport];

    for (let item of dataToExport) {
      groupedData.push(item.series);
    }

    return [groupedData];

  }

  // Method to assign data
  private assignData() {
    if (this.actualOption === this.optionTabPolicy && this.policyHorizontalBars.length === 0 && this.policyVerticalBars.length === 0) {
      this.retrieveDailyGraphData('c', this.filtersValues, this.selectedOption); // (c -> certificate)
    } else if (this.actualOption === this.optionTabBonus && this.bonusHorizontalBars.length === 0 && this.bonusVerticalBars.length === 0) {
      this.retrieveDailyGraphData('p', this.filtersValues, this.selectedOption); // (p -> premium)
    } else {
      this.assignDataToPrint();
    }
  }

  // Method to assign the data to print
  private assignDataToPrint() {
    if (this.actualOption === this.optionTabPolicy) {
      this.barChart = this.policyVerticalBars;
      this.lineChartSeries = this.policyHorizontalBars;
      this.data_total = this.policyDataTotal;
      this.data_sheet_names = this.policyDataSheetNames;
    } else if (this.actualOption === this.optionTabBonus) {
      this.barChart = this.bonusVerticalBars;
      this.lineChartSeries = this.bonusHorizontalBars;
      this.data_total = this.bonusDataTotal;
      this.data_sheet_names = this.bonusDataSheetNames;
    }
  }

  // Method to retrieve only the first day of the week value at X-axis
  private getXAxisValues(): void {
    this.xAxisTicks = [];
    for (var _i = 0; _i < this.days.length; _i += 7) {
      this.xAxisTicks.push(this.days[_i]);
    }
  }

  // Call to open the dialog with the graph expanded
  openDialog() {
    this.dialog.open(MonthlySalesGraphDialogComponent, {
      data : {
        sectionTitle: 'panels.dailySale',
        view: this.modalView,
        showXAxis: this.showXAxis,
        showYAxis: this.showYAxis,
        gradient: this.gradient,
        showLegend: this.showLegend,
        legendTitle: this.legendTitle,
        legendPosition: this.legendPosition,
        showXAxisLabel: this.showXAxisLabel,
        showYAxisLabel: this.showYAxisLabel,
        yAxisTickFormatting: this.yAxisTickFormatting,
        yLeftAxisScaleFactor: this.yLeftAxisScaleFactor,
        yRightAxisScaleFactor: this.yRightAxisScaleFactor,
        yRightAxisTickFormatting: this.yRightAxisTickFormatting,
        showGridLines: this.showGridLines,
        showRightYAxisLabel: this.showRightYAxisLabel,
        innerPadding: this.innerPadding,
        xAxisLabel: this.xAxisLabel,
        yAxisLabel: this.yAxisLabel,
        yAxisLabelRight: this.yAxisLabelRight,
        tooltipDisabled: this.tooltipDisabled,
        roundDomains: this.roundDomains,
        animations: this.animations,
        noBarWhenZero: this.noBarWhenZero,
        barChart: this.barChart,
        lineChartSeries: this.lineChartSeries,
        lineChartScheme: this.lineChartScheme,
        comboBarScheme: this.comboBarScheme,
        schemeType: this.schemeType,
        customColors: this.customColors,
        dialogLegends: this.legendsDailySale
      },
      panelClass: 'dialog-daily-sales'
    })
  }

  // Method to reset the data storage variables
  private resetVariables() {
    this.bonusVerticalBars = [];
    this.policyVerticalBars = [];
    this.bonusHorizontalBars = [];
    this.policyHorizontalBars = [];
    this.legendLabels = [];
    this.data_total = [];
    this.data_sheet_names = [];
  }

  // Method to format the right y axis labels
  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value);
  }

  // Method to calculate all the days from the previous and current filtered month
  private calculateXAxisDates() {
    let originalDate = new Date(this.year, this.month-1, this.day);
    let date = new Date(this.year, this.month-1, 1);
    date.setMonth(date.getMonth()-1);

    this.days = [];

    while(date <= originalDate) {
      this.days.push(this.padWithZeros(date.getDate(), 2) + '/' + this.padWithZeros((date.getMonth()+1), 2) + '/' + date.getFullYear());
      date.setDate(date.getDate()+1);
    }

  }

  // Method to export data to XSLX
  exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.data_total, this.data_sheet_names,  'daily_sale_chart', this.data_filter_details);
  }

  getTranslations(): void {
    const plan = this.translateService.get('labels.plan');
    const average = this.translateService.get('labels.average');
    const total = this.translateService.get('labels.total');
    const title = this.translateService.get('panels.dailySale');
    const policy = this.translateService.get('panels.policy');
    const bonus = this.translateService.get('panels.bonus');

    this.subscription = forkJoin([plan, average, total, title, policy, bonus]).subscribe(results => {
      this.planName = results[0];
      this.averageName = results[1];
      this.totalName = results[2];
      this.titleName = results[3];
      this.policyName = results[4];
      this.bonusName = results[5];
    });
  }

  // Function to check if date format is American
  private isDateInAmericanFormat(date: string) {
    // Retrieve filtered month
    let filteredMonth = this.filtersValues.filter(filter => filter.name === 'month')[0]?.value;

    // Calculate month prior to filtering. Is the fisrt month shown on the chart.
    let monthBeforeFiltering;
    if (filteredMonth === '01') {
      monthBeforeFiltering = '12';
    } else {
      monthBeforeFiltering = this.padWithZeros((Number(filteredMonth)-1), 2);
    }

    // mm/dd/yyyy American format - dd/mm/yyyy European format
    // If the first two values of the date match the month -> American format.
    const splittedDate = date.split('/');
    const month = this.padWithZeros(Number(splittedDate[0]), 2);

    // Check if the month match. If yes, the format is American
    return month === monthBeforeFiltering;

  }

  // Function to add zeros to the beginning of a string
  public padWithZeros(number: number, length: number) {
    let my_string = '' + number;

    while (my_string.length < length) {
      my_string = '0' + my_string;
    }

    return my_string;
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';
import { forkJoin, Subject } from 'rxjs'
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators'
import { CancellationCollectionEffective } from 'src/app/shared/models/cancellation-collection-effective';
import { trim } from 'lodash';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { ExcelFileInfo } from 'src/app/shared/models/excel-file-info';

@Component({
  selector: 'cancellation-charge-effectiveness-graphic',
  templateUrl: './cancellation-charge-effectiveness-graphic.component.html',
  styleUrls: ['./cancellation-charge-effectiveness-graphic.component.scss']
})
export class CancellationChargeEffectivenessGraphicComponent implements OnInit, OnDestroy {

  customColors: any = [];

  // Options for chart creation
  view = [530, 155];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  showRightYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  legendTitle: string = '';
  legendPosition: string = '';
  showXAxisLabel: boolean = false;
  showYAxisLabel: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  animations: boolean = false;
  schemeType: string = 'ordinal';
  colorScheme = {
    domain: [COLORS.darkBlue, COLORS.lightBlue]
  };
  yLeftAxisScaleFactor: string = '';
  yRightAxisScaleFactor: string = '';
  showGridLines: boolean = true;
  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  barPadding: number = 30;
  tooltipDisabled: boolean = false;
  roundDomains: boolean = true;
  noBarWhenZero: boolean = true;
  lineChartScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkBlue, COLORS.lightBlue, '#ffcc00', '#ff66ff']
  };
  comboBarScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkBlue, COLORS.lightBlue]
  };
  showPercentageSymbolInTooltip: boolean = true;

  colorList: any = [COLORS.darkBlue, COLORS.lightBlue, COLORS.lightestBlue, COLORS.darkYellow, COLORS.pink, COLORS.lightPurple, COLORS.lightOrange, COLORS.darkGreen, COLORS.lightGreen, COLORS.m3, COLORS.m5, COLORS.m10, COLORS.m13];
  colorListCSS: any = ['dark-blue', 'light-blue', 'lightest-blue', 'dark-yellow', 'pink', 'light-purple', 'light-orange', 'dark-green', 'light-green', 'm3', 'm5', 'm10', 'm13'];

  // Subject
  unsubscribe$: Subject<any> = new Subject<any>();

  // Variables to stare graph data
  lineChartSeries: any = [];
  barChart: any = [];

  amountBarChart: any;
  amountLineChart: any;
  receiptsBarChart: any;
  receiptsLineChart: any;

  amountLabels: any;
  receiptsLabels: any;
  labelsList: any = [];
  reducedLabelsList: any;

  // General variables
  graphName = 'collectionEffective';
  rateName: string;
  targetName: string;
  monthNames: string;
  filtersValues: any;
  loaded: boolean = false;

  // Option tabs
  actualOption: string = 'panels.amount';
  optionTabAmount: string = 'panels.amount';
  optionTabReceipt: string = 'panels.receipts';

  // Variables to export data
  data_total: any[] = [];
  data_sheet_names: any;
  data_filter_details: any;

  // Default constructor
  constructor(
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private eventsService: EventsService,
    private translateService: TranslateService,
    private excelService: ExcelService
  ) { }

  // OnInit
  ngOnInit(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const rate = this.translateService.get('labels.tax');
          const target = this.translateService.get('labels.target');
          const months = this.translateService.get('abbreviatedMonthNames');
      
          forkJoin([rate, target, months])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              {
                next: results => {
                  this.rateName = results[0];
                  this.targetName = results[1];
                  this.monthNames = results[2];
            
                  this.assignColorBar();
                },
                complete: () => {
                  if (data.length !== 0) {
                    this.filtersValues = data;
                    this.resetVariables();
                    this.assignData();
                  }
                }
              }
            ); 
        });

    this.eventsService.policiesBonusOption$.subscribe(
      data => {
        const option = data[0];
        const name = data[1];
        if ((option === this.optionTabAmount || option === this.optionTabReceipt) && name === this.graphName) {
          this.actualOption = option;
          this.assignData();
          this.getInfoForExcel();
        }
      }
    );


  }

  // Method to retrieve the cancellation collection effective
  private retrieveCancellationCollectionEffective(type_data: string, filtersValues: any) {
    this.loaded = false;

    this.apimstService.getCancellationCollectionEffective(type_data, filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data: Array<CancellationCollectionEffective>) => {
          const cancellationCollectionEffective = data;
          let barGraph: any = [];
          let lineGraph: any = [];
          let rate: any = [];

          let helper = {};

          // Group array by date
          let cancellationCollectionEffectiveByDate = cancellationCollectionEffective.reduce(function (r, o) {
            var key = o.month + '-' + o.year;
            if (!helper[key]) {
              helper[key] = cancellationCollectionEffective.filter(v => (v.month === o.month && v.year === o.year));
              r.push(helper[key]);
            }
            return r;
          }, []);

          let labels: any = [];

          if (cancellationCollectionEffectiveByDate.length !== 0) {
            this.data_filter_details = cancellationCollectionEffectiveByDate[0][0].filters;
          }

          for (let month of cancellationCollectionEffectiveByDate) {
            let series: any = [];
            for (let item of month) {
              if (!labels.includes('M' + item.name)) {
                labels.push('M' + item.name);
              }
              series.push(
                {
                  name: 'M' + item.name,
                  value: (item.value || trim(item.value).length > 0) ? parseFloat(item.value.replace(/,/g, '')) : 0
                }
              );
            }
            barGraph.push(
              {
                name: this.monthNames[month[0]?.month] + ' ' + month[0]?.year.slice(2, 4),
                series: series
              }
            );

            rate.push(
              {
                name: this.monthNames[month[0]?.month] + ' ' + month[0]?.year.slice(2, 4),
                value: (month[0].rate || trim(month[0].rate).length > 0) ? parseFloat(month[0].rate.replace(/,/g, '')) : 0
              }
            )
          }

          lineGraph.push(
            {
              name: this.rateName,
              series: rate
            }
          );

          if (type_data === 'p') {
            this.amountBarChart = barGraph;
            this.amountLineChart = lineGraph;
            this.amountLabels = this.sortLabels(labels);
          } else if (type_data === 'c') {
            this.receiptsBarChart = barGraph;
            this.receiptsLineChart = lineGraph;
            this.receiptsLabels = this.sortLabels(labels);
          }

          this.assignDataToPrint();

          this.getInfoForExcel();

        },

        error => console.log("An error occurred while retrieving cancellation collection effective data " + error)

      );
  }

  // Method to assign data to print according to the selected option (Amount/Receipt)
  private assignDataToPrint() {
    if (this.actualOption === this.optionTabAmount) {
      this.barChart = this.amountBarChart;
      this.labelsList = this.amountLabels;
      this.reduceLabelsList();
      this.lineChartSeries = this.amountLineChart;
      this.assignColorBar();
      this.loaded = true;
    } else if (this.actualOption === this.optionTabReceipt) {
      this.barChart = this.receiptsBarChart;
      this.lineChartSeries = this.receiptsLineChart;
      this.labelsList = this.receiptsLabels;
      this.reduceLabelsList();
      this.assignColorBar();
      this.loaded = true;
    }
  }

  // Method to assign data if it is loaded or to retrieve it if not
  private assignData() {
    if (this.actualOption === this.optionTabAmount && this.amountBarChart.length === 0 && this.amountLineChart.length === 0) {
      this.retrieveCancellationCollectionEffective('p', this.filtersValues);
    } else if (this.actualOption === this.optionTabReceipt && this.receiptsBarChart.length === 0 && this.receiptsLineChart.length === 0) {
      this.retrieveCancellationCollectionEffective('c', this.filtersValues);
    } else {
      this.assignDataToPrint();
    }
  }

  // Method to reset the storage variables
  private resetVariables() {
    this.amountBarChart = [];
    this.amountLineChart = [];
    this.receiptsLineChart = [];
    this.receiptsBarChart = [];
    this.amountLabels = [];
    this.receiptsLabels = [];
  }

  // Method to format the right y axis labels with '%'
  yRightAxisTickFormatting(val) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(val) + '%';
  }

  // Method to format the right y axis labels
  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value);
  }
    
  // Method to assign the colors to the graph bars
  private assignColorBar() {
    for (let _index = 0; _index < this.labelsList.length; _index++) {
      this.customColors.push(
        {
          name: this.labelsList[_index],
          value: this.colorList[_index]
        }
      )
    }
    this.customColors.push(
      {
        name: this.targetName,
        value: COLORS.lightBlue
      },
      {
        name: this.rateName,
        value: COLORS.darkYellow
      })
  }

  getInfoForExcel(): void {
    this.data_total = [];
    const title = this.translateService.get('panels.chargeEffectiveness');
    const option = this.translateService.get(this.actualOption);
        
    let titleLabel = '';
    let optionLabel = '';

    forkJoin([title, option])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(result => {
      titleLabel = result[0];
      optionLabel = result[1];
    });

    this.getDataToExport();
    this.data_sheet_names = [titleLabel + ' - ' + optionLabel];

    let excelInfo: ExcelFileInfo = {json: [this.data_total.flat()], headers: this.data_sheet_names, excelFileName: 'charge_effectiveness_chart', filtersValues: this.data_filter_details, filters: true};
    this.eventsService.chargeEffectivenessExcelInfoLoaded$.next(excelInfo);

  }
  // Method to export the graph data to an Excel file
  exportAsXLSX() {
    this.getInfoForExcel();
    this.excelService.exportAsExcelFile([this.data_total.flat()], this.data_sheet_names,  'charge_effectiveness_chart', this.data_filter_details);
  }

  // Method to retrieve the data to export
  private getDataToExport() {
    const headers = ['', this.barChart.map(v => v.name)];
    const values: any[] = [];

    for (let label of this.labelsList) {
      let array = [label];
      for (let month of this.barChart) {
        const result = month.series.filter(v => v.name === label);
        if (result.length !== 0) {
          array.push(result[0].value);
        } else {
          array.push(0);
        }
      }
      values.push(array);
    }

    this.data_total.push([headers.flat()], values);
    
    const horizontalData = this.lineChartSeries.map(v => {
      return [v.name + ' (%)', v.series.map(i => i.value)].flat();
    });

    this.data_total.push(horizontalData);

  }

  // Method to order numerically the legend labels 
  private sortLabels(array): any {
    var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
    array.sort(collator.compare);

    return array;
  }

  // Method to reduce the legend labels list if it exceeds 6
  private reduceLabelsList() {
    if (this.labelsList.length > 6) {
      this.reducedLabelsList = this.labelsList.slice(0,6);
    } else {
      this.reducedLabelsList = this.labelsList;
    }
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DateService } from './date/date.service';
import { FiltersService } from './filters/filters.service';
import { RequestsResultsStoreService } from './store/requests-results-store/requests-results-store.service';
import { UtilsService } from './utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class ApimstService {

  // Requests caching
  requestsCaching: boolean = environment.requestsCaching;

  // Base URL to connect with the Microsfot Azure API
  //baseURL: string = environment.azureApiUri + 'af-sikapiapp/';
  baseURL: string = environment.azureApiUri;

  // URL to referencing sales reports
  salesURL: string = 'sales/';

  // URL to referencing cancellation reports
  cancellationURL: string = 'cancellations/';

  // URL to referencing sinisters reports
  sinistersURL: string = 'sinisters/';

  // URL to referencing clients reports
  clientsURL: string = 'clients/';

  // URL to get the monthly sale
  monthlySale: string = 'monthly_months';

  // URL to get the daily month sale
  dailyMonthlySale: string = 'daily';

  // URL to get the portfolio indicators
  portfolioIndicators: string = 'portfolio_indicators';

  // URL to get the portfolio vs sales mix graph data
  portfolioVsSalesMix: string = 'sales/portfolio_mix';

  // URL to get the gwp
  gwp: string = 'gwp';

  // URL to get the monthly sales year for the pie graph and the table
  monthlySalesYear: string = 'monthly_year';

  // URL to get the portfolio vs GWP mix - Wok graph
  portfolioGWPMixEvolution: string = 'portfolio_evolution';

  // URL to get the portfolio churn data
  portfolioChurn: string = 'portfolio_rolling';

  // URL to get the cancellation charge funnel data
  cancellationChargeFunnel: string = 'indicators';

  // URL to get the quality indicators data
  qualityIndicators: string = 'quality_indicators';

  // URL to get the claim reason master
  claimReasonMaster: string = 'masters/claim_reason';

  // URL  to get the survey type master
  surveyTypeMaster: string = 'masters/survey_type';

  // URL to get the quality claims data
  qualityClaims: string = 'quality_claims';

  // URL to get the quality NPS data
  qualityNPS: string = 'quality_tnps';

  // URL to get the quality claims rolling graph
  qualityClaimsRolling: string = 'quality_claims_rolling';

  // URL to get the quality NPS rolling graph
  qualityNPSRolling: string = 'quality_tnps_rolling';

  // URL to get the quality response rate
  qualityResponseRate: string = 'quality_response_rate';

  // URL to get Collection charged rolling graph
  collectionRolling: string = 'collection_rolling'

  // URL to get header indicators
  headerIndicators: string = 'common/indicators';

  // URL to get the cancellation churn rate data
  cancellationChurnRate: string = 'churn_rate';

  // URL to get the cancellation churn rate per crop data
  cancellationCropChurnRate: string = 'churn_harvest';

  // URL to get the cancellation reason rate data
  cancellationReasonRate: string = 'reason_rate';

  // URL to get the cancellation churn rate per bouquet/channel
  cancellationBouquetChannel: string = 'bouquet_channel';

  // URL to get the collection rate
  collectionRate: string = 'collection_rate';

  // URL to get Collection voluntary retention report data
  collectionVoluntaryRetention: string = 'volunteers';

  // URL to get Collection voluntary retention report data
  collectionRetentionAction: string = 'retention_action';

  // URL to get Renewal Effectiveness report data
  renewalEffectiveness: string = 'renovation_effectivity';

  // URL to get the payment forms
  paymentForms: string = 'payment_forms';

  // URL to get the cancellation collection efective data
  cancellationCollectionEffective: string = 'collection_effective';

  // URL to get the cancellation retention rate
  cancellationRetentionRate: string = 'retention_rate';

  // URL to get the cancellation retention rate according to record
  cancellationRetentionRateRecord: string = 'rate';

  // URL to get the cancellation renovation rate
  cancellationRenovationRate: string = 'renovation_rate';

  // URL to get the cancellation renovation rate
  cancellationEvolutionaryRenewal: string = 'renovation_evolution';

  // URL to get the cancellation renovation rate
  user_info: string = 'common/user_info';

  // URL to get the SLAs indicators data
  SLAsIndicators: string = 'slas/months';

  // URL to get the SLAs graphs data
  SLAsGraphs: string = 'slas/rolling';

  // URL to get the target type master for the SLAs
  targetType: string = 'masters/target_type';

  // URL to get the cancellation persistence data
  cancellationPersistence: string = 'persistence';

  // URL to get the retention reason master
  retentionReasonMaster: string = 'masters/retention_reason';

  // URL to get the sales budget progress
  salesBudgetProgress: string = 'budget_progress';

  // URL to get the sales budget progress
  gwpResume: string = 'gwp';

  // URL to get the cancellation quality indicators
  cancellationQualityIndicator: string = 'indicators';

  // URL to get the cancellation rate rolling
  cancellationRateRolling: string = 'cancellation_rate_rolling';

  // URL to get the sales budget progress
  salesBudget: string = 'budget';

  // URL logout
  logoutURL: string = 'logout';

  // URL to get the cancellation reason master
  cancellationReasonMaster: string = 'masters/cancellation_reason';

  // URL to get the cancellation renovation payments
  cancellationRenovationPayments: string = 'renovation_payments';

  // URL to get the sinisters funnel chart data
  sinistersFunnel: string = 'sinisters_bouquet';

  // URL to get the sinisters funnel indicator data
  sinistersFunnelIndicator: string = 'sinisters_indicator';

  // URL to get the sinisters rejections
  sinistersRejections: string = 'rejection_rate';

  // URL to get the sinisters pending age
  sinistersPendingAge: string = 'pending_age';

  // URL to get the sinisters pending age
  sinistersQualityClaimIndicator: string = 'sinisters_indicator';

  // URL to get the sinisters quality
  sinistersQuality: string = 'quality';

  // URL to get the sinisters time processes
  sinistersTimeProcesses: string = 'process_times';

  // URL to get the sinisters quality claims data
  sinistersQualityClaims: string = 'claims';

  // URL to get the sinisters quality claims data
  sinistersQualityNPS: string = 'surveys';

  // URL to get the sinisters loss ratio
  sinistersLossRatio: string = 'loss_ratio';

  // URL to get the fixed stock data
  fixedStock: string = 'stock';

  // URL to get sinisters home indicators
  sinistersIndicators: string = 'sinisters_indicator';

  // URL to get the sinisters rejection reasons master
  sinistersRejectionReasonsMaster: string = 'masters/rejection_reason';

  // URL to get the sinisters closed by month of complaint
  closedPerMonthOfComplaint: string = 'closed';

  // URL to get the sinisters claims frequency evolution
  sinistersFrequency: string = 'frequency';
  
  // URL to get the clients portfolio
  clientsPortfolio: string = 'portfolio';

  // URL to get the clients stock
  clientsStock: string = 'stock';

  // URL to get the clients stock evolution
  clientsStockEvolution: string = 'stock_evolution';

  // URL to get the clients bouquet
  clientsBouquet: string = 'bouquet';

  // URL to get the clients occupation
  clientsOccupation: string = "occupation";
  
  // URL to get the Microstrategy shared reports 
  sharedReports: string = 'discovery/shared_folder';

  // URL to get the data discovery user folders
  dataDiscoveryUserFolders: string = 'discovery/user_folder';

  // URL to get the data discovery templates
  dataDiscoveryTemplates: string = 'discovery/templates';
  
  // URL to get the clients quality
  clientsQuality: string = 'quality';
  
  // URL to get the managed retention
  managedRetention: string = "managed_retention/"

  // URL to get the retention action
  retentionAction: string = "action"

  // URL to get the clients holding
  clientsHolding: string = "holding"

  // URL to get the clients experience
  clientsExperience: string = "experience"

  // URL to get country load
  countryLoad: string = 'common/country_load';  

  constructor(
    private httpClient: HttpClient,
    private utilsService: UtilsService,
    private dateService: DateService,
    private filtersService: FiltersService,
    private requestsStore: RequestsResultsStoreService
  ) { }

  public helloWorld(): Observable<any> {
    console.log("helloworld")
    // Zurich
    //return this.httpClient.get("https://sds-apis.azure-api.net/ms-atlas-api/helloworld");                                                                    

    // Local 
    const body: any = { year: 2020, month: "05" };
    return this.httpClient.post(environment.azureApiUri + "monthly_sale_months", body);
    //return this.httpClient.post("https://sdsapi-apim.azure-api.net/afsdsmst/monthly_sale_daily",body);                                                                    

  }

  // Service to retrieve the monhtly sales data  
  public getMonthlySales(type_data: string, filtersValues: any, dimension?: any[]): Observable<any> {
    const endpoint = this.baseURL + this.salesURL + this.monthlySale;
    let body: any = { type_data: type_data, dimension: dimension };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    return response;
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));    
  }

  // Service to retrieve the daily sales graph data
  public getDailySales(type_data: string, filterValues: any, dimension?: any[]): Observable<any> {
    const endpoint = this.baseURL + this.salesURL + this.dailyMonthlySale;
    let body: any = { type_data: type_data, dimension: dimension };
    for (let filter of filterValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    return response;
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));       
  }

  // Service to retrieve the portfolio indicators data
  public getPortfolioIndicators(portfolio_indicator: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.salesURL + this.portfolioIndicators;
    let body: any = { portfolio_indicator: portfolio_indicator };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the portfolio vs sales mix graph data
  public getPortfolioVsSalesMix(typeReport: string, dimension: any[], filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.portfolioVsSalesMix;
    let body: any = { portfolio_mix: typeReport, dimension: dimension };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the portfolio GWP
  public getGWP(type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.salesURL + this.gwp;
    let body: any = { type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));    
  }

  // Service to retrieve the monthly sales data for the pie graph and the table
  public getMonthlySalesYear(type_data: string, type_set: string, dimension: any, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.salesURL + this.monthlySalesYear;
    let body: any = { type_set: type_set, type_data: type_data, dimension: dimension };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    // return response;
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));     
  }

  // Service to retrieve the portfolio vs GWP Mix evolution
  public getPortfolioGWPEvolution(dimension: any, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.salesURL + this.portfolioGWPMixEvolution;
    let body: any = { type_data: 'p', dimension: dimension };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the portfolio churn graph data
  public getPortfolioChurn(filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.salesURL + this.portfolioChurn;
    let body: any = { type_data: 'p' };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the cancellation charge funnel data  
  public getChargeFunnelIndicators(type_data: string, cancellation_indicator: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.cancellationChargeFunnel;
    let body: any = { type_data: type_data, cancellation_indicator: cancellation_indicator };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the cancellation charge funnel data  
  public getRetentionFunnelIndicators(type_report: string, filtersValues: any, type_data?: string): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.managedRetention + this.cancellationChargeFunnel;
    let body: any = { type_data: type_data, type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the cancellation churn rate data
  public getCancellationChurnRate(type_set: string, type_data: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.cancellationChurnRate;
    let body: any = { type_set: type_set, type_data: type_data };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Sertive to retrieve the quality indicators data
  public getQualityIndicators(quality_indicator: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.salesURL + this.qualityIndicators;
    let body: any = { quality_indicator: quality_indicator };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));     
  }

  // Service to retrieve the claim reason master
  public getClaimReasonMaster(): Observable<any> {
    const endpoint = this.baseURL + this.claimReasonMaster;
    const response = this.httpClient.get(endpoint, {});
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, []);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: {}, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the survey type master
  public getSurveyTypeMaster(): Observable<any> {
    const endpoint = this.baseURL + this.surveyTypeMaster;
    const response = this.httpClient.get(endpoint, {});
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, []);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: {}, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the sales quality claims data
  public getQualityClaims(claim_subreason: [], filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.salesURL + this.qualityClaims;
    let body: any = { claim_subreason: claim_subreason };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the sales quality NPS data
  public getQualityNPS(survey_subtype: [], filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.salesURL + this.qualityNPS;
    let body: any = { survey_subtype: survey_subtype };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the sales quality claims rolling graph
  public getQualityClaimsRolling(claim_subreason: [], filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.salesURL + this.qualityClaimsRolling;
    let body: any = { claim_subreason: claim_subreason };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));    
  }

  // Service to retrieve the sales quality NPS rolling graph
  public getQualityNPSRolling(survey_subtype: [], filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.salesURL + this.qualityNPSRolling;
    let body: any = { survey_subtype: survey_subtype };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));    
  }

  // Service to retrieve the cancellation churn rate data per crop
  public getCancellationCropChurnRate(filtersValues: any, mpersistence: any[], dimension?: any[]): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.cancellationCropChurnRate;
    let body: any = { mpersistence: mpersistence, dimension: dimension };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the quality response rate
  public getQualityResponseRate(survey_subtype: [], filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.salesURL + this.qualityResponseRate;
    let body: any = { survey_subtype: survey_subtype };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));     
  }

  // Service to retrieve the cancellation reason rate data
  public getCancellationReasonRate(cancellation_reason: [], type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.cancellationReasonRate;
    let body: any = { cancellation_reason: cancellation_reason, type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
    
    
    // let body: any = { cancellation_reason: cancellation_reason, type_report: type_report };
    // for (let filter of filtersValues) {
    //   const name = filter.name;
    //   const value = filter.value;
    //   body[name] = value;
    // }
    // return this.httpClient.post(this.baseURL + this.cancellationURL + this.cancellationReasonRate, body);
  }

  // Service to retrieve the collection charge
  public getCancellationCollectionRolling(type_data: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.collectionRolling;
    let body: any = { type_data: type_data };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));     
  }

  // Service to retrieve the cancellation churn rate per bouquet/channel data
  public getCancellationBouquetchannel(dimension: any, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.cancellationBouquetChannel;
    let body: any = { dimension: dimension };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve header indicators
  public getHeaderIndicators(common_indicator: string, filtersValues: any[]): Observable<any> {
    const endpoint = this.baseURL + this.headerIndicators;
    let body: any = { common_indicator: common_indicator };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve home sale indicators
  public getHomeSaleIndicators(common_indicator: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.headerIndicators;
    let body: any = { common_indicator: common_indicator };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));    
  }

  // Service to retrieve the collection charge
  public getCancellationVoluntaryRetention(type_data: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.collectionVoluntaryRetention;
    let body: any = { type_data: type_data };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the retention charge
  public getCancellationVoluntaryRetentionPerRecord(type_data: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.managedRetention + this.collectionVoluntaryRetention;
    let body: any = { type_data: type_data };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the cancellation collection effective
  public getCancellationCollectionEffective(type_data: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.cancellationCollectionEffective;
    let body: any = { type_data: type_data };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));    
  }

  // Service to retrieve the collection rate
  public getCollectionRate(type_data: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.collectionRate;
    let body: any = { type_data: type_data };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the collection rate
  public getRenewalEffectiveness(type_data: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.renewalEffectiveness;
    let body: any = { type_data: type_data };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrive the cancellation renovation payments
  public getRenovationPayments(type_data: string, type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.cancellationRenovationPayments;
    let body: any = { type_data: type_data, type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    return response;
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the cancellation renovation rate
  public getCancellationRenovationRate(type_data: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.cancellationRenovationRate;
    let body: any = { type_data: type_data };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the renewal evolutionary renewal by
  public getCancellationEvolutionaryRenewal(type_data: string, dimension: [], filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.cancellationEvolutionaryRenewal;
    let body: any = { type_data: type_data, dimension: dimension };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the renewal evolutionary renewal by
  public getCancellationEvolutionaryRenewalRate(type_data: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.cancellationEvolutionaryRenewal;
    let body: any = { type_data: type_data };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the retention rate
  public getCancellationRetentionRate(type_data: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.cancellationRetentionRate;
    let body: any = { type_data: type_data };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the retention according to record rate
  public getCancellationRetentionRecordRate(type_data: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.managedRetention + this.cancellationRetentionRateRecord;
    let body: any = { type_data: type_data };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the collection charge
  public getCancellationRetentionAction(type_data: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.collectionRetentionAction;
    let body: any = { type_data: type_data };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the collection charge
  public getCancellationRetentionRecordAction(type_data: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.managedRetention + this.retentionAction;
    let body: any = { type_data: type_data };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the payment forms 
  public getPaymentForms(type_data: string, type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.paymentForms;
    let body: any = { type_data: type_data, type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    return response;
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve user info
  public getUserInfo(): Observable<any> {
    let today = new Date();
    let month = this.filtersService.getMonthValue().value;
    let year = this.filtersService.getYearValue().value;
    let day = today.getDate();
    // let month = "05";
    // let year = 2020;
    // let day = 31;
    //let body: any = { month: month, year: year, day: day, common_indicator: common_indicator };
    return this.httpClient.get(this.baseURL + this.user_info)
  }

  // Service to retrieve the cancellation quality claims data
  public getCancellationQualityClaims(claim_subreason: [], filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.qualityClaims;
    let body: any = { claim_subreason: claim_subreason };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the cancellation quality NPS data
  public getCancellationQualityNPS(survey_subtype: [], filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.qualityNPS;
    let body: any = { survey_subtype: survey_subtype };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));  
  }

  // Service to retrieve the cancellation quality claims rolling graph
  public getCancellationQualityClaimsRolling(claim_subreason: [], filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.qualityClaimsRolling;
    let body: any = { claim_subreason: claim_subreason };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the cancellation quality NPS rolling graph
  public getCancellationQualityNPSRolling(survey_subtype: [], filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.qualityNPSRolling;
    let body: any = { survey_subtype: survey_subtype };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the cancellation quality response rate
  public getCancellationQualityResponseRate(survey_subtype: [], filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.qualityResponseRate;
    let body: any = { survey_subtype: survey_subtype };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the SLAs indicators data
  public getSLAsIndicators(target_type: string, filtersValues: any): Observable<any> {
    let body: any = { target_type: target_type };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    return this.httpClient.post(this.baseURL + this.SLAsIndicators, body);
  }

  // Service to retrieve the SLAs graphs data
  public getSLAsGraphs(target_type: string, filtersValues: any): Observable<any> {
    let body: any = { target_type: target_type };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    return this.httpClient.post(this.baseURL + this.SLAsGraphs, body);
  }

  // Service to retrieve the target type master
  public getTargetTypeMaster(): Observable<any> {
    return this.httpClient.get(this.baseURL + this.targetType);
  }

  // Service to retrieve the cancellation persistence
  public getCancellationPersistence(type_report: string, retention_reason: [], year: string, month: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.cancellationPersistence;
    let body: any = { type_report: type_report, retention_reason: retention_reason, year: year, month: month };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the retention reason master
  public getRetentionReasonMaster(): Observable<any> {
    return this.httpClient.get(this.baseURL + this.retentionReasonMaster);
  }

  // Service to retrieve the sales busget progress graph data
  public getSalesBudget(type_data: string, type_report: string, filterValues: any): Observable<any> {
    const endpoint = this.baseURL + this.salesURL + this.salesBudget;
    let body: any = { type_data: type_data, type_report: type_report };
    for (let filter of filterValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));     
  }

  // Service to retrieve the sales budget progress
  public getSalesBudgetProgress(type_data: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.salesURL + this.salesBudgetProgress;
    let body: any = { type_data: type_data };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));     
  }

  // Service to retrieve the sales GWP (home)
  public getGWPResume(type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.salesURL + this.gwpResume;
    let body: any = { type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the cancellation quality indicator
  public getCancellationQualityIndicator(cancellation_indicator: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.cancellationQualityIndicator;
    let body: any = { cancellation_indicator: cancellation_indicator };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));    
  }

  // Service to retrieve the cancellation rate rolling
  public getCancellationRateRolling(filtersValues: any) {
    const endpoint = this.baseURL + this.cancellationURL + this.cancellationRateRolling;
    let body: any = {};
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));      
  }

  // Service to retrieve user info
  public logout(): Observable<any> {
    return this.httpClient.get(this.baseURL + this.logoutURL)
  }

  // Service to retrieve the cancellation reason master
  public getCancellationReasonMaster(): Observable<any> {
    const endpoint = this.baseURL + this.cancellationReasonMaster;
    const response = this.httpClient.get(endpoint, {});
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, []);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: {}, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the claim reason master
  public getSinistersClaimReasonMaster(): Observable<any> {
    return this.httpClient.get(this.baseURL + this.claimReasonMaster);
  }

  // Service to retrieve the sinisters quality claims data
  public getSinistersQualityClaims(claim_subreason: any[], type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.sinistersURL + this.sinistersQualityClaims;
    let body: any = { claim_subreason: claim_subreason, type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the survey type master
  public getSinistersSurveyTypeMaster(): Observable<any> {
    return this.httpClient.get(this.baseURL + this.surveyTypeMaster);
  }

  // Service to retrieve the sales quality NPS rolling graph
  public getSinistersQualityNPSRolling(survey_subtype: [], filtersValues: any): Observable<any> {
    let body: any = { survey_subtype: survey_subtype };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    return this.httpClient.post(this.baseURL + this.salesURL + this.qualityNPSRolling, body);
  }

  // Service to retrieve the sales quality NPS data
  public getSinistersQualityNPS(survey_subtype: [], type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.sinistersURL + this.sinistersQualityNPS;
    let body: any = { survey_subtype: survey_subtype, type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the quality response rate
  public getSinistersQualityResponseRate(survey_subtype: [], filtersValues: any): Observable<any> {
    let body: any = { survey_subtype: survey_subtype };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    return this.httpClient.post(this.baseURL + this.salesURL + this.qualityResponseRate, body);
  }

  // Service to retrieve the sinisters quality claims data
  public getSinistersSinistersQuality(type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.sinistersURL + this.sinistersQuality;
    let body: any = { type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the sinisters funnel graph data
  public getSinistersFunnelGraph(type_report: string, filtersValues: any, litigation_type: any, analysis_status?: any, common_indicator?: any): Observable<any> {
    const filtersToApply = filtersValues.filter(v => v.name !== 'day').filter(v => v.name !== 'budgets');
    let body: any = { type_report: type_report, analysis_status: analysis_status, common_indicator: common_indicator };
    if (JSON.stringify(litigation_type) != JSON.stringify(["1", "2"])) body.litigation_type = litigation_type;
    for (let filter of filtersToApply) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    return this.httpClient.post(this.baseURL + this.sinistersURL + this.sinistersFunnel, body);
  }

  // Service to retrieve the sinisters funnel indicator data
  public getSinistersFunnelIndicator(type_report: string, filtersValues: any, litigation_type: any): Observable<any> {
    const filtersToApply = filtersValues.filter(v => v.name !== 'day').filter(v => v.name !== 'currency').filter(v => v.name !== 'budgets');
    let body: any = { type_report: type_report };
    if (JSON.stringify(litigation_type) != JSON.stringify(["1", "2"])) body.litigation_type = litigation_type;
    for (let filter of filtersToApply) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    return this.httpClient.post(this.baseURL + this.sinistersURL + this.sinistersFunnelIndicator, body);
  }

  // Service to retrieve the sinisters funnel indicator data
  public getSinistersFunnelExtraIndicator(type_report: string, filtersValues: any, litigation_type: any): Observable<any> {
    const filtersToApply = filtersValues.filter(v => v.name !== 'day').filter(v => v.name !== 'budgets');
    let body: any = { type_report: type_report };
    if (JSON.stringify(litigation_type) != JSON.stringify(["1", "2"])) body.litigation_type = litigation_type;
    for (let filter of filtersToApply) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    return this.httpClient.post(this.baseURL + this.sinistersURL + this.sinistersFunnelIndicator, body);
  }

  // Service to retrieve the sinisters rejections
  public getSinistersRejections(type_data: string, type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.sinistersURL + this.sinistersRejections;
    let body: any = { type_data: type_data, type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);

    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the sinisters pending age
  public getSinistersPendingAge(type_data: string, type_report: string, sinister_substate: any[], sinister_substate_detail: any[], filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.sinistersURL + this.sinistersPendingAge;
    let body: any = { type_data: type_data, type_report: type_report, sinister_substate: sinister_substate, sinister_substate_detail: sinister_substate_detail };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }

    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);

    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the sinisters time processes
  public getSinistersTimeProcesses(filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.sinistersURL + this.sinistersTimeProcesses;
    let body: any = {};
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)
    
    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrive the cancellation renovation payments
  public getSinistersQualityIndicators(type_report: string, filtersValues: any): Observable<any> {
    let body: any = { type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    return this.httpClient.post(this.baseURL + this.sinistersURL + this.sinistersQualityClaimIndicator, body);
  }

  // Service to retrive the sinisters loss ratio
  public getSinistersLossRatio(type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.sinistersURL + this.sinistersLossRatio;
    let body: any = { type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);

    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the fixed stock data
  public getFixedStockChurnRateData(type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.fixedStock;
    let body: any = { type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    // return response;
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve home sale indicators
  public getHomeSinistersIndicators(type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.sinistersURL + this.sinistersIndicators;
    let body: any = { type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the sinisters rejection reasons
  public getSinistersRejectionReasons(): Observable<any> {
    const body: any = {};
    return this.httpClient.get(this.baseURL + this.sinistersRejectionReasonsMaster, body);
  }

  // Service to retrieve cancellations current stock
  public getCancellationCurrentStock(type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.cancellationURL + this.fixedStock;
    let body: any = { type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

  // Service to retrieve the sinisters closed per month
  public getSinistersClosedPerMonth(filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.sinistersURL + this.closedPerMonthOfComplaint;
    let body: any = {};
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);

    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the sinisters closed per month
  public getSinistersFrequencyEvolution(filtersValues: any, year: string, month: string): Observable<any> {
    const endpoint = this.baseURL + this.sinistersURL + this.sinistersFrequency;
    let body: any = { year: year, month: month };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);

    if (requestToSearch.length > 0) return of(requestToSearch[0].results)
    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the clients portfolio
  public getClientsPortfolio(type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.clientsURL + this.clientsPortfolio;
    let body: any = { type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the clients stock
  public getClientsStock(type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.clientsURL + this.clientsStockEvolution;
    let body: any = { type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);

    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the claim reason master
  public getClientsStockByProductClassAndBranch(type_report: string, dimension: any[], filtersValues: any[]): Observable<any> {
    const endpoint = this.baseURL + this.clientsURL + this.clientsStock;
    let body: any = { type_report: type_report, dimension: dimension };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }

    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)
    
    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the clients occupation
  public getClientsOccupation(type_report: string, filtersValues: any[]): Observable<any> {
    const endpoint = this.baseURL + this.clientsURL + this.clientsOccupation;
    let body: any = { type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);

    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }
  
  // Service to retrive the clients stock by geolocation
  public getClientsStockByGeolocation(type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.clientsURL + this.clientsStock;
    let body: any = { type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);

    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the clients claims reasons master
  public getClientsClaimsReasonsMaster(): Observable<any> {
    const body: any = {};
    return this.httpClient.get(this.baseURL + this.sinistersRejectionReasonsMaster, body);
  }

  // Service to retrieve the clients quality claims data
  public getClientsQualityClaims(type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.clientsURL + this.clientsQuality;
    let body: any = { type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the clients portfolio distribution
  public getClientsPortfolioDistribution(filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.claimReasonMaster;
    let body: any = {};
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.get(endpoint, {});
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, []);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: {}, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the clients holding
  public getClientsPortfolioHolding(type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.clientsURL + this.clientsHolding;
    let body: any = { type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }

    const response = this.httpClient.post(endpoint, body);
    const requestToSearh = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearh.length > 0) return of(requestToSearh[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the clients casualties per bouquet
  public getClientsCasualtiesPerBouquet(type_report: string, filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.clientsURL + this.clientsBouquet;
    let body: any = { type_report: type_report };
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the clients casualties per bouquet
  public getClientsExperience(filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.clientsURL + this.clientsExperience;
    let body: any = {};
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }

  // Service to retrieve the Microstrategy shared reports
  public getSharedReports(countries: any): Observable<any> {
    const params = new HttpParams().append('countries', countries);
    const endpoint = this.baseURL + this.sharedReports;
    return this.httpClient.get(endpoint, {params});
  }
  
  // Service to retrieve the data discovery user folders
  public getDataDiscoveryUserFolders(): Observable<any> {
    const endpoint = this.baseURL + this.dataDiscoveryUserFolders;
    return this.httpClient.get(endpoint);
  }

  // Service to retrieve the data discovery templates
  public getDataDiscoveryTemplates(): Observable<any> {
    const endpoint = this.baseURL + this.dataDiscoveryTemplates;
    return this.httpClient.get(endpoint);
  }

  // Service to retrieve country load report
  public getCountryLoadReport(filtersValues: any): Observable<any> {
    const endpoint = this.baseURL + this.countryLoad;
    let body: any = {};
    for (let filter of filtersValues) {
      const name = filter.name;
      const value = filter.value;
      body[name] = value;
    }
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)

    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    })); 
  }

}

<div class="thermometer" [ngStyle]="getContainerStyles()">
    <div class="thermometer-inner" [ngStyle]="getInnerContainerStyles()">
        <div class="lines">
            <div class="line" *ngFor="let i of lines(num).fill(1)"></div>
        </div>
    </div>
    <div class="marker-container">
        <div class="marker" [ngStyle]="getMarkerStyles()"></div>
    </div>
</div>
<div class="thermometer-bottom mt-1">
    <span class="text-bottom-left small">{{ textBottomLeft }}</span>
    <span class="text-bottom-right small">{{ textBottomRight }}</span>
</div>
<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex">
            <span class="title section-card-title">{{ 'panels.retention' | translate }}</span>
            <app-btn-gobernance-info 
                    class="ml-2" 
                    [endpoint]="endpoint" 
                    [endpointParams]="endpointParams"
                    *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </div>
        <app-option-tab [leftOption] = bonus [rightOption] = policy [graphName] = graphName class = "option-tab align-items-center"></app-option-tab>
    </div>

    <div class="mt-4 d-flex align-items-stretch">
        <div class="col-4 mt-2">
            <retention-funnel></retention-funnel>
        </div>
        <div class="col-8 pl-6">
            <retention-graphic></retention-graphic>
        </div>
    </div>

    
</app-widget>
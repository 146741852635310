import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { trim } from 'lodash';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COLORS } from 'src/app/shared/globals/globals';
import { CancellationRenewalEffectivenessEntity } from 'src/app/shared/models/cancellation-renewal-effectiveness';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'renewal-effectiveness-graphic',
  templateUrl: './renewal-effectiveness-graphic.component.html',
  styleUrls: ['./renewal-effectiveness-graphic.component.scss']
})
export class RenewalEffectivenessGraphicComponent implements OnInit {

   // Options for chart creation
   view = [580, 200];
   showXAxis: boolean = true;
   showYAxis: boolean = true;
   gradient: boolean = true;
   showLegend: boolean = false;
   legendTitle: string = '';
   legendPosition: string = '';
   showXAxisLabel: boolean = false;
   showYAxisLabel: boolean = false;
   xAxisLabel: string = '';
   yAxisLabel: string = '';
   yLeftAxisScaleFactor: string = '';
   yRightAxisScaleFactor: string = '';
   showGridLines: boolean = true;
   showRightGridLines: boolean = true;
   innerPadding: string = '10%';
   animations: boolean = false;
   showRightYAxisLabel: boolean = false;
   yAxisLabelRight: string = '';
   tooltipDisabled: boolean = false;
   schemeType: string = "ordinal";
   roundDomains: boolean = true;
   noBarWhenZero: boolean = true;
   lineChartScheme = {
     selectable: true,
     group: 'ordinal',
     domain: [COLORS.lightBlue, COLORS.darkYellow]
   };
   comboBarScheme = {
     selectable: true,
     group: 'ordinal',
     domain: [COLORS.lightBlue, COLORS.darkBlue]
   };
   customColors: any = [];
   roundEdges: boolean = false;
   activeEntries: any = [];
   trimXAxisTicks: boolean = true;
   trimYAxisTicks: boolean = true;
   rotateXAxisTicks: boolean = false;
   maxXAxisTickLength: number = 16;
   maxYAxisTickLength: number = 16;
   barPadding: number = 8;

   groupedVerticalGraph: any = [];
   horizontalGraph: any = [];
   loaded: boolean = false;

   policyVerticalGraphData: any = [];
   bonusVerticalGraphData: any = [];
   policyHorizontalGraphData: any = [];
   bonusHorizontalGraphData: any = [];

  // Subscription
  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();

  // Variables for saving the filter option
  optionTabPolicy = 'panels.policy';
  optionTabBonus = 'panels.bonus';
  actualOption: string = 'panels.bonus';
  graphName: string = 'renewalEffectiveness';

  monthNames: string;
  taxName: string;
  targetName: string;
  renewedName: string;
  susceptibleName: string;

  filtersValues: any;

  constructor(
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private eventsService: EventsService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    //this.assignColorBar();
    this.subscription = this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        const susceptible = this.translateService.get('labels.susceptibleP');
        const renewed = this.translateService.get('labels.renewedF');
        const tax = this.translateService.get('labels.tax');
        const target = this.translateService.get('labels.target');
        const months = this.translateService.get('abbreviatedMonthNames');

        forkJoin([susceptible, renewed, tax, target, months])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: results => {
            this.susceptibleName = results[0];
            this.renewedName = results[1];
            this.taxName = results[2];
            this.targetName = results[3];
            this.monthNames = results[4];
            this.assignColorBar();
          },
          complete: () => {
            if(data.length !== 0) {
              this.filtersValues = data;
              this.resetVariables();
              if(this.actualOption == this.optionTabBonus)
                this.retrieveGraphData('p', data); // (p -> premium)
              else if(this.actualOption == this.optionTabPolicy)
                this.retrieveGraphData('c', data);
            }
          }
        })
      });

      this.eventsService.policiesBonusOption$.subscribe(
        data => {
          const select = data[0];
          const name = data[1];
          if ((select === this.optionTabBonus || select === this.optionTabPolicy)
              && (data != this.actualOption) && name === this.graphName){
            this.actualOption = select;
            if(this.actualOption == this.optionTabBonus){
              if (this.bonusHorizontalGraphData.length === 0 && this.bonusVerticalGraphData.length === 0) {
                this.retrieveGraphData('p', this.filtersValues); // (c -> certificate)
              } else {
                this.assignDataToPrint();
              }
            }else if(this.actualOption == this.optionTabPolicy){
              if (this.policyVerticalGraphData.length === 0 && this.policyHorizontalGraphData.length === 0) {
                this.retrieveGraphData('c', this.filtersValues); // (c -> certificate)
              } else {
                this.assignDataToPrint();
              }
            }
          }
        }
      );

  }

  private retrieveGraphData(type_data:string, filtersValues: any) {
    this.loaded = false;
    this.subscription = this.apimstService.getRenewalEffectiveness(type_data,filtersValues).subscribe(
      data => {
        const graphData: Array<CancellationRenewalEffectivenessEntity> = data;
        const verticalGraphData: any = [];
        const taxHorizontalGraphData: any = [];
        const targetHorizontalGraphData: any = [];
        const horizontalGraphData:any = []
        for (let group of graphData) {
          taxHorizontalGraphData.push(
            {
              name: this.monthNames[group.month] + ' ' + group.year.slice(2,4),
              value: (group.rate || trim(group.rate).length>0)?parseFloat(group.rate.replace(/,/g, '')):0
            }
          );
          targetHorizontalGraphData.push(
            {
              name: this.monthNames[group.month] + ' ' + group.year.slice(2,4),
              value: (group.target || trim(group.target).length>0)?parseFloat(group.target.replace(/,/g, '')):0
            }
          );

          verticalGraphData.push(
            {
              name: this.monthNames[group.month] + ' ' + group.year.slice(2,4),
              series: [
                {
                  name: this.renewedName,
                  value: (group.renovated || trim(group.renovated).length>0) ? parseFloat(group.renovated.replace(/,/g, '')) : 0
                },
                {
                  name: this.susceptibleName,
                  value: (group.susceptible || trim(group.susceptible).length>0) ?  parseFloat(group.susceptible.replace(/,/g, '')) : 0
                }
              ]
            }
          );
        }
        horizontalGraphData.push(
          {
            name: this.taxName,
            series: taxHorizontalGraphData
          },
          {
            name: this.targetName,
            series: targetHorizontalGraphData
          }
        );
        this.assignColorBar();


        // Bonus data (p -> premium)
        if (type_data === 'p') {
          this.bonusVerticalGraphData = verticalGraphData;
          this.bonusHorizontalGraphData = horizontalGraphData;

        } else if (type_data === 'c') { // Policy data (c -> certificate)
          this.policyVerticalGraphData = verticalGraphData;
          this.policyHorizontalGraphData = horizontalGraphData;

        }
        this.loaded = true;

        this.assignDataToPrint();


      },
      error => console.log("An error ocurred while retrieving portfolio churn graph data: " + error)
    );
  }

 private assignDataToPrint() {
    if (this.actualOption === this.optionTabPolicy) {
      this.groupedVerticalGraph = this.policyVerticalGraphData;
      this.horizontalGraph = this.policyHorizontalGraphData;
    } else if (this.actualOption === this.optionTabBonus) {
      this.groupedVerticalGraph = this.bonusVerticalGraphData;
      this.horizontalGraph = this.bonusHorizontalGraphData;
    }
  }


  // Method to assign the colors to the graph bars
  private assignColorBar() {
    this.customColors.push(
      {
        name: this.renewedName,
        value: COLORS.lightBlue
      },
      {
        name: this.susceptibleName,
        value: COLORS.darkBlue
      },
      {
        name: this.taxName,
        value: COLORS.darkYellow
      },
      {
        name: this.targetName,
        value: COLORS.lightGreen
      })
  }


  // Method to format the right y axis labels with '%'
  yRightAxisTickFormatting(val) {
    return  val.toLocaleString() + '%';
  }

  // Method to format the right y axis labels
  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value);
  }

  // Method to reset the data storage variables
  private resetVariables() {
    this.groupedVerticalGraph = [];
    this.horizontalGraph = [];
    this.bonusHorizontalGraphData = [];
    this.policyHorizontalGraphData = [];
    this.bonusVerticalGraphData = [];
    this.policyVerticalGraphData = [];
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

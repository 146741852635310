import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventsService } from 'src/app/shared/services/events/events.service';

@Component({
  selector: 'app-business-main-information',
  templateUrl: './business-main-information.component.html',
  styleUrls: ['./business-main-information.component.scss']
})
export class BusinessMainInformationComponent implements OnInit {

  @Input() sectionInformation;

  unsubscribe$: Subject<any> = new Subject<any>();

  selectedSection: string = 'description'
  filtersInfo: any = [];
  businessCatalogueInfo: any = [];
  technicalCatalogueInfo: any = [];
  technicalInfo: any = [];

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    this.filtersInfo = [];
    this.businessCatalogueInfo = [];
    this.technicalCatalogueInfo = [];
    this.technicalInfo = [];

    if (this.sectionInformation.relationships.hasOwnProperty('filters')) {
      this.filtersInfo = this.formatFilters();
    } else {
      this.filtersInfo = [];
    }

    if (this.sectionInformation.relationships.hasOwnProperty('concepts')) {
      this.businessCatalogueInfo = this.formatBusinessCatalogue();
    } else {
      this.businessCatalogueInfo = [];
    }

    if (this.sectionInformation.relationships.hasOwnProperty('metrics')) {
      this.technicalCatalogueInfo = this.formatTechnicalCatalogue();
    } else {
      this.technicalCatalogueInfo = [];
    }

    if (this.sectionInformation.relationships.hasOwnProperty('reports')) {
      this.technicalInfo = this.formatTechnicalInfo();
    } else {
      this.technicalInfo = [];
    }
    
    this.eventsService.searchOptionSelected$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        this.selectedSection = data.id;
      });

    this.eventsService.updateBusinessInformation$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        if (data.updatedSection === 'description') {
          this.sectionInformation.description_html = data.updatedContent;
        } else if (data.updatedSection === 'image') {
          this.sectionInformation.description_image = data.updatedContent;
        }
      } )  

  }

  formatFilters(): any {
    let array = [];
    this.sectionInformation.relationships.filters.sort((a,b) => {
      if (a.name < b.name) {
        return -1;
      } else {
        return 1;
      }
    });

    for (let filter of this.sectionInformation.relationships.filters) {
      array.push(
        {
          title: filter.name,
          description: filter.rel_description_html,
          isEditable: true,
          guid: filter.rel_guid
        }
      )
    }
    return array;
  }

  formatBusinessCatalogue() {
    let array = [];
    this.sectionInformation.relationships.concepts.sort((a,b) => {
      if (parseInt(a.internal_id) < parseInt(b.internal_id)) {
        return -1;
      } else {
        return 1;
      }
    });
    
    for (let item of this.sectionInformation.relationships.concepts) {
      array.push(
        {
          title: item.internal_id + ' - ' + item.name,
          description: item.rel_description_html,
          formula: item.formula
          //link: item.rel_guid
        }
      )
    }
    return array;
  }

  formatTechnicalCatalogue() {
    let array = [];
    this.sectionInformation.relationships.metrics.sort((a,b) => {
      if (a.name < b.name) {
        return -1;
      } else {
        return 1;
      }
    });
    for (let item of this.sectionInformation.relationships.metrics) {
      array.push(
        {
          title: item.name,
          formula: item.formula,
          link: item.guid,
          type: 'metric'
        }
      )
    }
    return array;
  }

  formatTechnicalInfo() {
    let array = [];
    for (let item of this.sectionInformation.relationships.reports) {
      array.push(
        {
          title: item.name,
          link: item.guid,
          type: 'report'
        }
      )
    }
    return array;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

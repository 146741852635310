export var tableSeries = {
    very_dissatisfied: {
        value: "2,1",
        values: [
            {
                name: "Detractor de alguna encuesta (aunque haya otra experiencia positiva el negativo prevalece)",
                value: "1,9"
            },
            {
                name: "Reclamación en algún momento ya sea del banco, a un organismo regulador, etc.",
                value: "1,9"
            }
        ]
    },
    dissatisfied: {
        value: "13,1",
        values: [
            {
                name: "Clientes que han cancelado alguna póliza",
                value: "2,8"
            },
            {
                name: "Cancelación o renuncia temprana",
                value: "0,9"
            },
            {
                name: "Clientes con siniestro rechazados",
                value: "1,8"
            },
            {
                name: "Clientes con denuncia de siniestros no cursada | Rechazo en la punta",
                value: "1,7"
            },
            {
                name: "Clientes con siniestros pendientes con tiempo > 2 meses",
                value: "2,8"
            },
            {
                name: "Clientes con pago de siniestros fuera de SLA",
                value: "0,9"
            },
            {
                name: "Clientes con renovación no aplicada",
                value: "1,8"
            },
            {
                name: "Clientes con tasa de llamadas a Call Center Reincidentes",
                value: "1,9"
            },
            {
                name: "Clientes con Asistencia de cubierta",
                value: "1,9"
            },
        ]
    },
    satisfied: {
        value: "12,4",
        values: [
            {
                name: "Clientes Compra digital",
                value: "1,9"
            },
            {
                name: "Clientes pago siniestro dentro SLA",
                value: "1,9"
            },
            {
                name: "Clientes Deciden Renovar",
                value: "1,9"
            },
            {
                name: "Clientes con uso de Asistencia",
                value: "1,9"
            },
            {
                name: "Clientes de consulta Digital de página de Seguros",
                value: "1,9"
            },
            {
                name: "Clientes adheridos a campañas por mails",
                value: "1,9"
            },
            {
                name: "Clientes en programas de Loyalty",
                value: "1,9"
            },
        ]
    },
    very_satisfied: {
        value: "10,2",
        values: [
            {
                name: "Promotores por encuesta",
                value: "1,9"
            },
            {
                name: "Clientes que nos recomiendan en una campaña",
                value: "1,9"
            },
        ]
    },
    neutral: {
        value: "42",
        values: [
            {
                name: "Desconocimiento de su feedback, sin interacción con nosotros",
                value: "1,9"
            },
            {
                name: "Clientes neutros que por tipo de interacción no entren en otra categoría",
                value: "1,9"
            },
        ]
    }
};
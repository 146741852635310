export var single = [
    {
      "name": "Jul19",
      "value": 2.1
    },
    {
      "name": "Jul20",
      "value": 2.44
    },
    {
      "name": "Objetivo",
      "value": 3.95
    }
  ];
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  // Function to calculate the actual date
  public getActualDate(): string {
    let actualDate: any = {};
    const today = new Date();
    actualDate.day = today.getDate().toString();
    actualDate.month = today.getMonth();
    actualDate.year = today.getFullYear();

    return actualDate;
  }

  // Function to check if string has '-' symbol
  public isNegative(number: string): boolean {
    let isNegative: boolean = false;
    if (typeof number !== 'undefined' && number !== null) {
      if (number.includes('-')) {
        isNegative = true;
      }
    }
    return isNegative;
  }

  // Function to check if string length is greater than 1
  public lengthGreaterThanOne(name: string): boolean {
    let isGreater: boolean = false;
    if (typeof name !== 'undefined' && name !== null) {
      if (name.length > 1) {
        isGreater = true;
      }
    }
    return isGreater;
  }

  // Function to add zeros to the beginning of a string
  public padWithZeros(number: number, length: number) {
    let my_string = '' + number;

    while (my_string.length < length) {
      my_string = '0' + my_string;
    }

    return my_string;
  }

  // Function to calculate median
  public calculateMedian(arr) {
    const mid = Math.floor(arr.length / 2);
    const nums = [...arr].sort((a, b) => a - b);

    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { takeUntil } from 'rxjs/operators'
import { forkJoin, Subject } from 'rxjs'
import { PieChartDialogComponent } from 'src/app/shared/components/pie-chart-dialog/pie-chart-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { response } from './response';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-clients-portfolio-distribution-by-holding-pie',
  templateUrl: './clients-portfolio-distribution-by-holding-pie.component.html',
  styleUrls: ['./clients-portfolio-distribution-by-holding-pie.component.scss']
})
export class ClientsPortfolioDistributionByHoldingPieComponent implements OnInit {

  pieGraphData: any[];
  pieGraph: any[];
  showPieDialogButton: boolean = false;

  rawInfo: any;
  rawInfoProductClass: any;
  rawInfoDistribution: any;

  type_data: string;
  type_report: string;
  rejection_reason: any[];

  viewPie: any[] = [350, 150];
  gradientPie: boolean = false;
  showLegendPie: boolean = true;
  showLabelsPie: boolean = false;
  isDoughnutPie: boolean = false;
  isDoughnut: boolean = false;
  legendPositionPie: string = 'below';
  legendTitlePie: string = '';
  tooltipDisabledPie: boolean = false;
  animationsPie: boolean = false;
  colorSchemePie = {
    domain: [COLORS.darkBlue, COLORS.lightBlue, COLORS.pink, COLORS.lightOrange, COLORS.darkGreen, COLORS.lightPurple, COLORS.lightGreen, COLORS.lightestBlue, COLORS.darkYellow]
  };
  customColorsPieGraph: any = [];
  arcWidth: number = 1.5;

  tooltipTitle: string;
  tooltipNumber: string;

  // Pie chart dialog options
  pieDialogView: any[] = [600, 380];
  pieDialogShowLabels: boolean = true;
  pieDialogLegendPosition: string = 'below';

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;

  pieGraphLoaded: boolean = false;
  pieGraphLabels: any = [];

  barGraphToPrint: any = [];
  productClassToPrint: any = [];
  DistributionToPrint: any = [];
  rateToPrint: any = [];

  policyName: string = "";
  policiesName: string = "";

  responseNamesMatching: any = {}

  constructor(
    private dialog: MatDialog,
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.subscribeToFiltersValues();
  }

  subscribeToFiltersValues(): void {
    this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        this.getTranslations();
        this.responseNamesMatching = this.setBlockMatches();

        this.resetValues();
        this.pieGraphLoaded = false;

        this.filtersValues = data;
        if (this.filtersValues.length == 0) return;

        this.retrieveOccupationPieInfo(this.filtersValues);
      }
    )
  }

  getTranslations() {
    const policyTranslation = this.translateService.get('panels.policy');
    const policiesTranslation = this.translateService.get('panels.policies');

    forkJoin([policyTranslation, policiesTranslation])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(results => {
      this.policyName = results[0];
      this.policiesName = results[1];
    });
  }

  setBlockMatches() {
    return {
      "1": `1 ${this.policyName}`,
      "2": `2 ${this.policiesName}`,
      "3": `3 ${this.policiesName}`,
      "4": `4 ${this.policiesName}`,
      ">=5": `>=5 ${this.policiesName}`
    }
  }

  resetValues() {
    this.DistributionToPrint = [];
  }

  retrieveOccupationPieInfo(filtersValues: any): void {
    this.apimstService.getClientsPortfolioHolding("ch", filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: data => {
          this.pieGraphLoaded = true;
          if(data == null) return;

          this.rawInfoDistribution = this.createPie(data);
          if (this.rawInfoDistribution != null) this.createGraph(this.rawInfoDistribution, this.DistributionToPrint)
          this.assignColors();
        }
      })
  }

  createPie(info: any[] = []) {
    const formattedArray = info.map(
      item => ({
        name: this.responseNamesMatching[item.name],
        percentage: parseFloat(item.percentage)
      })
    )
    return formattedArray.reverse()
  }

  createGraph(rawInfo: any[] = [], newIndicator: any[] = []) {
    for (let element of rawInfo) {
      newIndicator.push({
        name: element.name,
        value: element.percentage ? parseFloat(element.percentage) : 0
      })
    }
  }

  assignColors() {
    this.customColorsPieGraph.push(
      {
        name: `1 ${this.policyName}`,
        value: COLORS.darkGreen
      },
      {
        name: `2 ${this.policiesName}`,
        value: COLORS.lightOrange
      },
      {
        name: `3 ${this.policiesName}`,
        value: COLORS.pink
      },
      {
        name: `4 ${this.policiesName}`,
        value: COLORS.lightBlue
      },
      {
        name: `>=5 ${this.policiesName}`,
        value: COLORS.darkBlue
      }
      )
  }

  openPieChartDistributionDialog(): void {
    this.dialog.open(PieChartDialogComponent, {
      data: {
        sectionTitle: 'panels.portfolioDistributionByHolding',
        results: this.DistributionToPrint,
        view: this.pieDialogView,
        gradient: this.gradientPie,
        showLegend: this.showLegendPie,
        showLabels: this.pieDialogShowLabels,
        isDoughnut: this.isDoughnut,
        legendPosition: this.pieDialogLegendPosition,
        legendTitle: this.legendTitlePie,
        tooltipDisabled: this.tooltipDisabledPie,
        animations: this.animationsPie,
        colorScheme: this.colorSchemePie,
        arcWidth: this.arcWidth,
        tooltipTitle: this.tooltipTitle,
        tooltipNumber: this.tooltipNumber
      },
      panelClass: ['mat-dialog-gen', 'mat-dialog-pie-gen', 'mat-dialog-pie-chart-clients-portfolio-distribution'],
    })
  }

  labelFormatting(series: any[], name: string): string {
    const item = series.filter(data => data.name === name);
    if (item.length > 0) {
      return Intl.NumberFormat("de-DE").format(item[0].value) + "%";
    }
    return name;
  }

  activate(e) {
    this.tooltipTitle = e['value'].label;
    this.tooltipNumber = Intl.NumberFormat("de-DE").format(e['value'].value) + "%";
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

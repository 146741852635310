<app-widget>

    <div class="section-header-block d-flex align-items-center pt-2">
      <span class="title section-card-title">{{ 'panels.portfolioIndicators' | translate }}</span>
      <app-btn-gobernance-info 
        class="ml-2" 
        [endpoint]="endpoint" 
        [endpointParams]="endpointParams"
        *ngIf="hasGobernanceInfo">
      </app-btn-gobernance-info>
    </div>

    <div class="pt-3">

      <!-- portfolio_valid -->
      <div *ngIf="!loadedValid" class="mb-4">
        <component-loader  [width]="" [height]="110"></component-loader>
      </div>

      <app-indicator-widget *ngIf="loadedValid"
        class="element mt-4 mb-3" 
        [titleLeft]="titleValidCertificate | translate" 
        [titleCenter]="titleValidAvgPremium | translate" 
        [titleRight]="titleValidPremium | translate" 
        [mainValueLeft]="portfolioIndicatorsValid?.certificate" 
        [mainValueRight]="portfolioIndicatorsValid?.premium" 
        [mainValueCenter]="portfolioIndicatorsValid?.avg_premium" 
        [mainValueUnitCenter]="portfolioIndicatorsValid?.currency?.symbol"
        [mainValueUnitRight]="portfolioIndicatorsValid?.currency?.symbol"
        [percentagePrevious] = "percentagePrevious"
        [percentageValueLeft]="portfolioIndicatorsValid?.certificate_diff" 
        [percentageValueRight]="portfolioIndicatorsValid?.premium_diff" 
        [percentageValueCenter]="portfolioIndicatorsValid?.avg_premium_diff"
        [trendLeft]="portfolioIndicatorsValid?.certificate_diff_trend"
        [trendCenter]="portfolioIndicatorsValid?.avg_premium_diff_trend"
        [trendRight]="portfolioIndicatorsValid?.premium_diff_trend">
      </app-indicator-widget>
      
      <!-- portfolio_new_sale -->

      <div *ngIf="!loadedNewSale" class="mb-4">
        <component-loader [width]="" [height]="110"></component-loader>
      </div>

      <app-indicator-widget *ngIf="loadedNewSale"
        class="element mt-2 mb-3" 
        [titleLeft]="titleNewSaleCertificate | translate" 
        [titleCenter]="titleNewSaleAvgPremium | translate" 
        [titleRight]="titleNewSalePremium | translate" 
        [mainValueLeft]="portfolioIndicatorsNewSale?.certificate" 
        [mainValueRight]="portfolioIndicatorsNewSale?.premium" 
        [mainValueCenter]="portfolioIndicatorsNewSale?.avg_premium" 
        [mainValueUnitCenter]="portfolioIndicatorsNewSale?.currency?.symbol"
        [mainValueUnitRight]="portfolioIndicatorsNewSale?.currency?.symbol"
        [percentagePrevious] = "percentagePrevious"
        [percentageValueLeft]="portfolioIndicatorsNewSale?.certificate_diff" 
        [percentageValueRight]="portfolioIndicatorsNewSale?.premium_diff" 
        [percentageValueCenter]="portfolioIndicatorsNewSale?.avg_premium_diff"
        [trendLeft]="portfolioIndicatorsNewSale?.certificate_diff_trend"
        [trendCenter]="portfolioIndicatorsNewSale?.avg_premium_diff_trend"
        [trendRight]="portfolioIndicatorsNewSale?.premium_diff_trend">
      </app-indicator-widget>

      <!-- portfolio_renovations -->

      <div *ngIf="!loadedRenovation" class="mb-4">
        <component-loader [width]="" [height]="110"></component-loader>
      </div>

      <app-indicator-widget *ngIf="loadedRenovation"
        class="element mt-2 mb-3" 
        [titleLeft]="titleRenovationCertificate | translate" 
        [titleCenter]="titleRenovationAvgPremium | translate" 
        [titleRight]="titleRenovationPremium | translate" 
        [mainValueLeft]="portfolioIndicatorsRenovation?.certificate" 
        [mainValueRight]="portfolioIndicatorsRenovation?.premium" 
        [mainValueCenter]="portfolioIndicatorsRenovation?.avg_premium" 
        [mainValueUnitCenter]="portfolioIndicatorsRenovation?.currency?.symbol"
        [mainValueUnitRight]="portfolioIndicatorsRenovation?.currency?.symbol"
        [percentagePrevious] = "percentagePrevious"
        [percentageValueLeft]="portfolioIndicatorsRenovation?.certificate_diff" 
        [percentageValueRight]="portfolioIndicatorsRenovation?.premium_diff" 
        [percentageValueCenter]="portfolioIndicatorsRenovation?.avg_premium_diff"
        [trendLeft]="portfolioIndicatorsRenovation?.certificate_diff_trend"
        [trendCenter]="portfolioIndicatorsRenovation?.avg_premium_diff_trend"
        [trendRight]="portfolioIndicatorsRenovation?.premium_diff_trend">
      </app-indicator-widget>
      
      <!-- portfolio_cancellations -->

      <div *ngIf="!loadedCancellation" class="mb-4">
        <component-loader [width]="" [height]="110"></component-loader>
      </div>

      <app-indicator-widget *ngIf="loadedCancellation"
        class="element mt-2 mb-2" 
        [titleLeft]="titleCancellationCertificate | translate" 
        [titleCenter]="titleCancellationAvgPremium | translate" 
        [titleRight]="titleCancellationPremium | translate" 
        [mainValueLeft]="portfolioIndicatorsCancellation?.certificate" 
        [mainValueRight]="portfolioIndicatorsCancellation?.premium" 
        [mainValueCenter]="portfolioIndicatorsCancellation?.avg_premium" 
        [mainValueUnitCenter]="portfolioIndicatorsCancellation?.currency?.symbol"
        [mainValueUnitRight]="portfolioIndicatorsCancellation?.currency?.symbol"
        [percentagePrevious] = "percentagePrevious"
        [percentageValueLeft]="portfolioIndicatorsCancellation?.certificate_diff" 
        [percentageValueRight]="portfolioIndicatorsCancellation?.premium_diff" 
        [percentageValueCenter]="portfolioIndicatorsCancellation?.avg_premium_diff"
        [trendLeft]="portfolioIndicatorsCancellation?.certificate_diff_trend"
        [trendCenter]="portfolioIndicatorsCancellation?.avg_premium_diff_trend"
        [trendRight]="portfolioIndicatorsCancellation?.premium_diff_trend">
      </app-indicator-widget>
    </div>

  </app-widget>
<table class="table table-closed-per-month">
    <thead>
        <tr class="table-header">
            <th [title]="'panels.complaintMonth' | translate">{{ 'panels.complaintMonth' | translate }}</th>
            <th [title]="'panels.complaintMumber' | translate">{{ 'panels.complaintMumber' | translate }}</th>
            <th colspan="24" class="inner-th">
                <table class="inner-table">
                    <tr>
                        <th colspan="24">{{ 'panels.developmentMonths' | translate }} - {{ 'panels.incrementalClosingsPercent' | translate }} (%)</th>
                    </tr>
                    <tr>
                        <th [title]="headerIndicator.name" *ngFor="let headerIndicator of headerIndicators">{{ headerIndicator.name }}</th>
                    </tr>
                </table>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of tableInfo">
            <td [title]="column.name" [ngStyle]="{'background-color': column.bgColor}"
                *ngFor="let column of row.values">
                {{ column.name }}
            </td>
        </tr>
    </tbody>
</table>
import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, forkJoin } from 'rxjs';
import { EventsService } from '../../services/events/events.service';
import { FiltersService } from '../../services/filters/filters.service';
import { Animations } from '../../animations/animations';
import { ApimstService } from '../../services/apimst.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { SelectorInfo } from 'src/app/shared/models/selector-info';
import * as _ from 'lodash';
import { DOCUMENT } from '@angular/common';
import { NavigationService } from '../../services/navigation/navigation.service';
import { UserInfo } from 'src/app/shared/models/user-info';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ResetFiltersDialogComponent } from '../reset-filters-dialog/reset-filters-dialog.component';
import { CurrencyService } from '../../services/currency/currency.service';
import { DateService } from '../../services/date/date.service';
import { FILTERS_TITLES } from 'src/app/shared/globals/globals';
import { I18nService } from '../../services/i18n/i18n.service';
import { FiltersHelperService } from '../../services/filters/filters-helper.service';

@Component({
  selector: 'app-side-filters',
  templateUrl: './side-filters.component.html',
  styleUrls: ['./side-filters.component.scss'],
  animations: [
    Animations.slideToLeft,
    Animations.fadeInOut,
    Animations.dumpAnimation
  ]
})
export class SideFiltersComponent implements OnInit {

  @Input() formName: string;

  // Single selectors
  dropdownMonth: string = "dropdownMonth";
  dropdownYear: string = "dropdownYear";
  dropdownCurrency: string = "dropdownCurrency";
  dropdownBudgets: string = "dropdownBudgets";

  // Info for country selector
  countryName: string = "";
  inactiveIcon: string = "outlined_flag";
  activeIcon: string = "flag";
  isActive: boolean = false;
  countries: any = [];
  selectedCountry: string;

  userInfo: UserInfo = null;

  dropdownList;

  loaded = false;

  hierarchyListChannel: any[] = [];

  tempEmptyItem: any = {};

  filteredChannelHierarchy: any[] = [];

  // Store filters template to show
  templateName: string = "";

  filtersOn: Boolean = false;
  filtersValues: any;

  // Subscriptions
  subscription: Subscription;
  filtersDropdownSubscription: Subscription;
  pageIdSubscription: Subscription;
  userInfoSubscription: Subscription;
  refreshFiltersSubscription: Subscription;

  // Filters select items
  itemsMonth: any;
  itemsYear: any;
  itemsCurrency: any[] = [];
  itemsBudgets: any[] = [];

  selectedIndexMonth: number;
  selectedIndexYear: number;
  selectedIndexCurrency: string;
  selectedIndexBudgets: string;
  selectedIndexCountry: string;

  // Data for selectors
  channelFilters: any[] = [];
  familyChannelItems: any[] = [];
  macroChannelItems: any[] = [];
  channelItems: any[] = [];
  pointChannelItems: any[] = [];
  corporateSocietyItems: any[] = [];
  territorialItems: any[] = [];
  zoneItems: any[] = [];
  productCategoryItems: any[] = [];
  productSubCategoryItems: any[] = [];
  productFamilyItems: any[] = [];
  productTechnicalItems: any[] = [];
  bouquetBusinessItems: any[] = [];
  bouquetLobsItems: any[] = [];
  productMisItems: any[] = [];
  productTypeItems: any[] = [];
  budgetsItems: any[] = [];
  claimOriginTypeItems: any[] = [];
  complaintReasonItems: any[] = [];
  paymentFormsItems: any[] = [];
  paymentMethodsItems: any[] = [];
  
  // Stores all the info
  familyChannelObject: any[] = [];
  macroChannelObject: any[] = [];
  channelObject: any[] = [];
  pointChannelObject: any[] = [];
  corporateSocietyObject: any[] = [];
  territorialObject: any[] = [];
  zoneObject: any[] = [];
  productCategoryObject: any[] = [];
  productSubCategoryObject: any[] = [];
  productFamilyObject: any[] = [];
  productTechnicalObject: any[] = [];
  bouquetBusinessObject: any[] = [];
  bouquetLobsObject: any[] = [];
  productMisObject: any[] = [];
  productTypeObject: any[] = [];
  claimOriginTypeObject: any[] = [];
  complaintReasonObject: any[] = [];
  paymentFormsObject: any[] = [];
  paymentMethodsObject: any[] = [];
  
  // Stores info of the selected items
  familyChannelSelectedItemsInfo: any;
  macroChannelSelectedItemsInfo: any;
  channelSelectedItemsInfo: any;
  pointChannelSelectedItemsInfo: any;
  corporateSocietySelectedItemsInfo: any;
  territorialSelectedItemsInfo: any;
  zoneSelectedItemsInfo: any;
  productCategorySelectedItemsInfo: any;
  productSubCategorySelectedItemsInfo: any;
  productFamilySelectedItemsInfo: any;
  productTechnicalSelectedItemsInfo: any;
  bouquetBusinessSelectedItemsInfo: any;
  bouquetLobsSelectedItemsInfo: any;
  productMisSelectedItemsInfo: any;
  productTypeSelectedItemsInfo: any;
  claimOriginTypeSelectedItemsInfo: any;
  complaintReasonSelectedItemsInfo: any;
  paymentFormsSelectedItemsInfo: any;
  paymentMethodsSelectedItemsInfo: any;
  budgetsSelectedItemsInfo: any;

  familyChannelItemsId: any[] = [];
  familyChannelItemsIdSelected: any[] = [];
  macroChannelItemsId: any[] = [];
  macroChannelItemsIdSelected: any[] = [];
  channelItemsId: any[] = [];
  channelItemsIdSelected: any[] = [];
  pointChannelItemsId: any[] = [];
  pointChannelItemsIdSelected: any[] = [];
  corporateSocietyItemsId: any[] = [];
  corporateSocietyItemsIdSelected: any[] = [];
  territorialItemsId: any[] = [];
  territorialItemsIdSelected: any[] = [];
  zoneItemsId: any[] = [];
  zoneItemsIdSelected: any[] = [];
  productCategoryItemsId: any[] = [];
  productCategoryItemsIdSelected: any[] = [];
  productSubCategoryItemsId: any[] = [];
  productSubCategoryItemsIdSelected: any[] = [];
  productFamilyItemsId: any[] = [];
  productFamilyItemsIdSelected: any[] = [];
  productTechnicalItemsId: any[] = [];
  productTechnicalItemsIdSelected: any[] = [];
  bouquetBusinessItemsId: any[] = [];
  bouquetBusinessItemsIdSelected: any[] = [];
  bouquetLobsItemsId: any[] = [];
  bouquetLobsItemsIdSelected: any[] = [];
  productMisItemsId: any[] = [];
  productMisItemsIdSelected: any[] = [];
  productTypeItemsId: any[] = [];
  productTypeItemsIdSelected: any[] = [];
  surveyTypeItemsId: any[] = []; 
  surveyTypeItemsIdSelected: any[] = []; 
  claimTypeItemsId: any[] = []; 
  claimTypeItemsIdSelected: any[] = []; 
  claimOriginTypeItemsId: any[] = [];  
  claimOriginTypeItemsIdSelected: any[] = [];  
  claimSubreasonItemsId: any[] = [];  
  claimSubreasonItemsIdSelected: any[] = [];  
  budgetItemsId: any[] = [];
  budgetItemsIdSelected: any[] = [];
  complaintReasonItemsId: any[] = [];
  complaintReasonItemsIdSelected: any[] = [];
  paymentFormsItemsId: any[] = [];
  paymentFormsItemsIdSelected: any[] = [];
  paymentMethodsItemsId: any[] = [];
  paymentMethodsItemsIdSelected: any[] = [];

  selectedItems: any[] = [];
  selectedItemsFamilyChannel: any[] = [];
  selectedIdsFamilyChannel: any[] = [];
  selectedItemsMacroChannel: any[] = [];
  selectedIdsMacroChannel: any[] = [];
  selectedItemsChannel: any[] = [];
  selectedIdsChannel: any[] = [];
  selectedItemsPointChannel: any[] = [];
  selectedIdsPointChannel: any[] = [];
  selectedItemsSocietyCorporate: any[] = [];
  selectedIdsSocietyCorporate: any[] = [];
  selectedItemsTerritorial: any[] = [];
  selectedIdsTerritorial: any[] = [];
  selectedItemsZone: any[] = [];
  selectedIdsZone: any[] = [];
  selectedItemsProductCategory: any[] = [];
  selectedIdsProductCategory: any[] = [];
  selectedItemsProductSubCategory: any[] = [];
  selectedIdsProductSubCategory: any[] = [];
  selectedItemsProductFamily: any[] = [];
  selectedIdsProductFamily: any[] = [];
  selectedItemsProductTechnical: any[] = [];
  selectedIdsProductTechnical: any[] = [];
  selectedItemsBouquetBusiness: any[] = [];
  selectedIdsBouquetBusiness: any[] = [];
  selectedItemsBouquetLobs: any[] = [];
  selectedIdsBouquetLobs: any[] = [];
  selectedItemsProductMis: any[] = [];
  selectedItemsProductType: any[] = [];
  selectedIdsProductMis: any[] = [];
  selectedIdsProductType: any[] = [];
  selectedItemsBudgets: any[] = [];
  selectedIdsBudgets: any[] = [];
  selectedItemsClaimOriginType: any[] = [];
  selectedIdsClaimOriginType: any[] = [];
  selectedItemsComplaintReason: any[] = [];
  selectedIdsComplaintReason: any[] = [];
  selectedItemsPaymentForms: any[] = [];
  selectedIdsPaymentForms: any[] = [];
  selectedItemsPaymentMethods: any[] = [];
  selectedIdsPaymentMethods: any[] = [];

  // Variables to store a clone of the base state of filters (when panel opens)
  countryFilterSelectionInfoTemp: any;
  monthFilterSelectionInfoTemp: any;
  yearFilterSelectionInfoTemp: any;
  currencyFilterSelectionInfoTemp: any;
  budgetsFilterSelectionInfoTemp: any;
  familyChannelFilterSelectionInfoTemp: any;
  macroChannelFilterSelectionInfoTemp: any;
  channelFilterSelectionInfoTemp: any;
  pointChannelFilterSelectionInfoTemp: any;
  societyCorporateFilterSelectionInfoTemp: any;
  territorialFilterSelectionInfoTemp: any;
  zoneFilterSelectionInfoTemp: any;
  productCategoryFilterSelectionInfoTemp: any;
  productSubCategoryFilterSelectionInfoTemp: any;
  productFamilyFilterSelectionInfoTemp: any;
  productTechnicalFilterSelectionInfoTemp: any;
  bouquetBusinessFilterSelectionInfoTemp: any;
  bouquetLobsFilterSelectionInfoTemp: any;
  productMisFilterSelectionInfoTemp: any;
  productTypeFilterSelectionInfoTemp: any;
  claimOriginTypeFilterSelectionInfoTemp: any;
  complaintReasonFilterSelectionInfoTemp: any;
  paymentFormsFilterSelectionInfoTemp: any;
  paymentMethodsFilterSelectionInfoTemp: any;

  // Variables to store raw data for filters requests
  monthRaw: any[] = [];
  currencyRaw: any[] = [];
  currencyRawMaster: any[] = [];
  channelRaw: any[] = [];
  userRaw: any[] = [];

  channelAllHierarchy: any[] = [];
  macroChannelHierarchy: any[] = [];
  channelHierarchy: any[] = [];
  pointChannelHierarchy: any[] = [];
  territorialHierarchy: any[] = [];
  zoneHierarchy: any[] = [];
  corporateSocietyHierarchy: any[] = [];

  productSubCategoryHierarchy: any[] = [];
  productFamilyHierarchy: any[] = [];
  productTechnicalHierarchy: any[] = [];
  bouquetBusinessHierarchy: any[] = [];
  bouquetLobsHierarchy: any[] = [];
  productMisHierarchy: any[] = [];
  productTypeHierarchy: any[] = [];

  productRaw: any[] = [];
  claimOriginTypeRaw: any[] = [];
  complaintReasonRaw: any[] = [];
  paymentFormsRaw: any[] = [];
  paymentMethodsRaw: any[] = [];
  budgetsRaw: any[] = [];

  // Body selector
  body;

  // Variable to store only applied filters
  currencyInfoSent: any = {iso: "", symbol: ""};
  
  filtersToRequest: any[] = [];

  titles = FILTERS_TITLES;

  disableProductTypeBlock: Boolean;

  constructor(private events: EventsService,
      private translate: TranslateService,
      private filtersService: FiltersService,
      private apiService: ApimstService,
      @Inject(DOCUMENT) private document: Document,
      private utilsService: UtilsService,
      private dateService: DateService,
      private navigationService: NavigationService,
      private dialog: MatDialog,
      public dialogRef: MatDialogRef<ResetFiltersDialogComponent>,
      private currencyService: CurrencyService,
      private i18nService: I18nService,
      private filtersHelperService: FiltersHelperService,
  ) { }

    ngOnInit() {

      this.subscribeToFiltersShowEvent();

      this.showFiltersLoading();

      this.subscribeToFiltersRefreshEvent();

      if ( this.filtersService.filtersLoaded$.value == false ) { this.subscribeToUserInfo(); }
      this.setInitCountryValues();

      this.subscribeToFiltersRawValues();

      this.handleFilters();

      this.subscribeToSelectorsChanges();

      this.showFiltersTemplate();

      this.filtersService.disableProductTypeBlock
        .subscribe(
          data => {
            this.disableProductTypeBlock = data;
        });
        
    }

    filterCurrencyByCountry(currencyRaw: any) {
      let searchCountry;
      let searchField;
      typeof this.filtersService.selectedIndexCountry$.value == "string" ? searchField = this.filtersService.selectedIndexCountry$.value : searchField = this.filtersService.selectedIndexCountry$.value.id;
      this.filtersService.selectedIndexCountry$.value != "" ? searchCountry = searchField : searchCountry = this.events.userInfoLoaded$.value.countries[0].id;
       
      let filteredCurrency: any = [];
      for(let currency of currencyRaw) {
        if(currency.countries.length > 0) {
          for(let country of currency.countries) {
            if(country == searchField) {
              filteredCurrency.push(currency);
            }
          }
        }
      }

      return filteredCurrency;
    }

    handleFilters(): void {

      if ( this.isFiltersInfoLoaded() ) {
        this.printFilters();
        return;
      }

      let currencyFiltersRaw = this.filtersService.getCurrencyFilters();
      let channelFiltersRaw = this.filtersService.getChannelFilters(this.checkCountryFilterValue());
      let productFiltersRaw = this.filtersService.getProductFilters(this.checkCountryFilterValue());
      let claimOriginTypeFiltersRaw = this.filtersService.getClaimOriginTypeFilters();
      let paymentFormsFiltersRaw = this.filtersService.getPaymentFormsFilters();
      let paymentMethodsFiltersRaw = this.filtersService.getPaymentMethodsFilters();
      let budgetsFiltersRaw = this.filtersService.getBudgetsFilters();
      let filtersRequests = [
        currencyFiltersRaw, 
        channelFiltersRaw, 
        productFiltersRaw, 
        claimOriginTypeFiltersRaw, 
        paymentFormsFiltersRaw, 
        paymentMethodsFiltersRaw,
        budgetsFiltersRaw
      ]
  
      forkJoin(filtersRequests)
        .subscribe(results => {
          results[0] ? this.currencyRaw = results[0] : this.currencyRaw = [{}];
          results[1] ? this.channelRaw = results[1] : this.channelRaw = [{}];
          results[2] ? this.productRaw = results[2] : this.productRaw = [{}];
          results[3] ? this.claimOriginTypeRaw = results[3] : this.claimOriginTypeRaw = [{}];
          results[4] ? this.paymentFormsRaw = results[4] : this.paymentFormsRaw = [{}];
          results[5] ? this.paymentMethodsRaw = results[5] : this.paymentMethodsRaw = [{}];
          results[6] ? this.budgetsRaw = results[6] : this.budgetsRaw = [{}];
  
        this.filterCurrencyRawValues();

        this.removeNotValidValues();

        this.setRawInfoInObservables();

        this.printFilters();
      });
  
    }

    isFiltersInfoLoaded(){
      return this.filtersService.filtersLoaded$.value == true && this.filtersService.countryChanged$.value == false;
    }

    hideFilters() {
      this.filtersService.updateBlockVisibility(false);
      this.filtersOn = false;
    };

    cancelFilters() {
      this.hideFilters();
      this.setFiltersInitialValues();
    };

    setFiltersInitialValues(): void {
      this.filtersService.selectedIndexMonth$.next( this.monthFilterSelectionInfoTemp );
      this.filtersService.getMonthValue().next(this.utilsService.padWithZeros(this.monthFilterSelectionInfoTemp, 2));
      this.filtersService.selectedIndexYear$.next( this.yearFilterSelectionInfoTemp );
      this.filtersService.getYearValue().next( this.yearFilterSelectionInfoTemp.toString() );
      this.filtersService.selectedIndexCurrency$.next( this.currencyFilterSelectionInfoTemp );
      this.filtersService.currencyFilterValues$.next( this.currencyFilterSelectionInfoTemp.toString() );
      this.filtersService.selectedIndexCountry$.next( this.countryFilterSelectionInfoTemp );
      this.filtersService.countryFilterValues$.next( this.countryFilterSelectionInfoTemp );
      this.filtersService.selectedIndexBudgets$.next( this.budgetsFilterSelectionInfoTemp );
      this.filtersService.budgetsFilterValues$.next( this.budgetsFilterSelectionInfoTemp );
      this.filtersService.familyChannelFilterSelectionInfo$.next( this.familyChannelFilterSelectionInfoTemp );
      this.filtersService.macroChannelFilterSelectionInfo$.next( this.macroChannelFilterSelectionInfoTemp );
      this.filtersService.channelFilterSelectionInfo$.next( this.channelFilterSelectionInfoTemp );
      this.filtersService.pointChannelFilterSelectionInfo$.next( this.pointChannelFilterSelectionInfoTemp );
      this.filtersService.corporateSocietyFilterSelectionInfo$.next( this.societyCorporateFilterSelectionInfoTemp );
      this.filtersService.territorialFilterSelectionInfo$.next( this.territorialFilterSelectionInfoTemp );
      this.filtersService.zoneFilterSelectionInfo$.next( this.zoneFilterSelectionInfoTemp );
      this.filtersService.productCategoryFilterSelectionInfo$.next( this.productCategoryFilterSelectionInfoTemp );
      this.filtersService.productSubCategoryFilterSelectionInfo$.next( this.productSubCategoryFilterSelectionInfoTemp );
      this.filtersService.productFamilyFilterSelectionInfo$.next( this.productFamilyFilterSelectionInfoTemp );
      this.filtersService.productTechnicalFilterSelectionInfo$.next( this.productTechnicalFilterSelectionInfoTemp );
      this.filtersService.bouquetBusinessFilterSelectionInfo$.next( this.bouquetBusinessFilterSelectionInfoTemp );
      this.filtersService.bouquetLobsFilterSelectionInfo$.next( this.bouquetLobsFilterSelectionInfoTemp );
      this.filtersService.productMisFilterSelectionInfo$.next( this.productMisFilterSelectionInfoTemp );
      this.filtersService.productTypeFilterSelectionInfo$.next( this.productTypeFilterSelectionInfoTemp );
      this.filtersService.claimOriginTypeFilterSelectionInfo$.next( this.claimOriginTypeFilterSelectionInfoTemp );
      this.filtersService.complaintReasonFilterSelectionInfo$.next( this.complaintReasonFilterSelectionInfoTemp );
      this.filtersService.paymentFormsFilterSelectionInfo$.next( this.paymentFormsFilterSelectionInfoTemp );
      this.filtersService.paymentMethodsFilterSelectionInfo$.next( this.paymentMethodsFilterSelectionInfoTemp );
    }

    applyFilters() {

      this.filtersService.updateMonthValue(this.filtersService.getMonthValue().value);
      this.filtersService.updateYearValue(this.filtersService.getYearValue().value);
      this.filtersService.selectedIndexMonth$.next(parseInt(this.filtersService.getMonthValue().value));
      this.filtersService.selectedIndexYear$.next(parseInt(this.filtersService.getYearValue().value));
      this.filtersService.selectedIndexCurrency$.next( this.filtersService.currencyFilterValues$.value.toString() );
      this.filtersService.selectedIndexBudgets$.next( this.filtersService.budgetsFilterValues$.value );
      this.filtersService.selectedIndexCountry$.next( this.filtersService.countryFilterValues$.value );

      // Send applied filters in object
      this.setAppliedFilters();

      // Hide filters
      this.hideFilters();

      // Notify filters update
      this.events.filtersUpdated$.emit(true);

    }

    resetFilters(): void {
      this.filtersService.updateMonthValue(this.filtersService.getMonthValue().value);
      this.filtersService.updateYearValue(this.filtersService.getYearValue().value);
      this.filtersService.selectedIndexMonth$.next(parseInt(this.filtersService.getMonthValue().value));
      this.filtersService.selectedIndexYear$.next(parseInt(this.filtersService.getYearValue().value));
      this.filtersService.selectedIndexCurrency$.next( this.filtersService.currencyFilterValues$.value.toString() );
      this.filtersService.currencyFilterValues$.next( this.filtersService.currencyFilterValues$.value.toString() );
      this.filtersService.selectedIndexBudgets$.next( this.filtersService.budgetsFilterValues$.value );
      this.filtersService.budgetsFilterValues$.next( this.filtersService.budgetsFilterValues$.value );
      this.filtersService.selectedIndexCountry$.next( this.filtersService.countryFilterValues$.value );
      this.filtersService.familyChannelFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.macroChannelFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.channelFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.pointChannelFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.corporateSocietyFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.territorialFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.zoneFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.productCategoryFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.productSubCategoryFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.productFamilyFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.productTechnicalFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.bouquetBusinessFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.bouquetLobsFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.productMisFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.productTypeFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.claimOriginTypeFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.complaintReasonFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.paymentFormsFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      this.filtersService.paymentMethodsFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
      
      this.setCountryName();
      this.changeLanguage(this.searchLanguageInUserCountries());
      this.setDefaultCountryCurrency();

    }

    setDefaultCountryCurrency(): void {
      let selectedCountry = this.filtersService.selectedIndexCountry$.value.id;
      let userRaw = this.filtersService.userRawValues$.value;
      let currencyId: string = "";
      let currencyIso: string = "";
      let currencySymbol: string = "";

      for(let country of userRaw['countries']) {
        if(country.id == selectedCountry) {
          currencyId = country.currency.id;
          currencyIso = country.currency.iso;
          currencySymbol = country.currency.symbol;
        }
      }

      this.filtersService.selectedIndexCurrency$.next(currencyId);
      this.filtersService.filtersCurrencyValue$.next(currencyId);
      this.filtersService.currencyFilterValues$.next(currencyId);
      this.currencyService.currencyInfo$.next( { ...this.currencyService.currencyInfo$.value, iso: currencyIso } );
      this.currencyService.currencyInfo$.next( { ...this.currencyService.currencyInfo$.value, symbol: currencySymbol } );
      this.filtersService.currencyInfoSent$.next( { ...this.filtersService.currencyInfoSent$.value, iso: currencyIso } );
      this.filtersService.currencyInfoSent$.next( { ...this.filtersService.currencyInfoSent$.value, symbol: currencySymbol } );

    }

    setCountryName(): void {
      this.filtersService.selectedCountryName$.next(this.filtersService.countryFilterValues$.value['name']);
    }

    searchLanguageInUserCountries() {
      return this.filtersService.searchLanguageInUserCountries();
    }

    checkCountryFilterValue() {
      return this.filtersService.checkCountryFilterValue();
    }

    printFilters() {
      this.printCountryFilters();
      this.printCurrencyFilters();
      this.printBudgetsFilters();
      this.printChannelFilters();
      this.printProductFilters();
      this.printClaimOriginTypeFilters();
      this.printComplaintReasonFilters();
      this.printPaymentFormsFilters();
      this.printPaymentMethodsFilters();
      
      this.removeDuplicates();
      this.cloneFiltersState();

      this.checkAllHierarchies();

      this.filtersService.filtersLoaded$.next(true);

      if(this.filtersService.countryChanged$.value == true) {
        this.filtersService.countryChanged$.next(false);
      }

      this.loaded = true;
      this.hideFiltersLoading();

    }
    
    printCountryFilters() {
      for (let country of this.countries) {
        if( country['id'] == this.filtersService.selectedIndexCountry$.value['id'] ) {
          this.countryName = country['name'];
        }
      }
    }

    printCurrencyFilters() {
      let selectedValue = this.filtersService.selectedIndexCurrency$.value.toString();
      for (let item of this.currencyRaw) {
        this.itemsCurrency.push(
          {
            id: item['id'],
            type: 'currency',
            name: item['iso'],
            selected: true ? selectedValue === item['id'] : false,
          }
        );
      }
    }

    printBudgetsFilters() {
      let selectedValueBudget = this.filtersService.selectedIndexBudgets$.value[0];
      for (let item of this.budgetsRaw) {
        this.itemsBudgets.push(
          {
            id: item['budget_id'],
            type: 'budgets',
            name: item['budget_desc'],
            selected: true ? selectedValueBudget === item['budget_id'] : false,
          }
        );
      }
    }

    printChannelFilters() {
      for(let item of this.filtersService.channelRawValues$.value) {
        this.pushSelectorRawValues( item['channel_family_id'], this.familyChannelObject, item['channel_family_id'], item['channel_family_desc'] );
        this.macroChannelObject.push( { item_id: item['channel_macro_id'], item_text: item['channel_macro_desc'] } );
        this.channelObject.push( { item_id: item['channel_id'], item_text: item['channel_desc'] } );
        this.pointChannelObject.push( { item_id: item['channel_point_id'], item_text: item['channel_point_desc'] } );
        this.pushSelectorRawValues( item['corporate_society_id'], this.corporateSocietyObject, item['corporate_society_id'], item['corporate_society_desc'] );
        this.territorialObject.push( { item_id: item['territorial_id'], item_text: item['territorial_desc'] } );
        this.zoneObject.push( { item_id: item['zone_id'], item_text: item['zone_desc'] } );
      }
    }

    printProductFilters() {
      for(let item of this.filtersService.productRawValues$.value) {
        this.productCategoryObject.push( { item_id: item['product_category_id'], item_text: item['product_category_desc'] } );
        this.productSubCategoryObject.push( { item_id: item['product_subcategory_id'], item_text: item['product_subcategory_'] } );
        this.productFamilyObject.push( { item_id: item['product_family_id'], item_text: item['product_family_desc'] } );
        this.productTechnicalObject.push( { item_id: item['product_technical_id'], item_text: item['product_technical_desc'] } );
        this.bouquetBusinessObject.push( { item_id: item['bouquet_business_id'], item_text: item['bouquet_business_desc'] } );
        this.bouquetLobsObject.push( { item_id: item['bouquet_lobs_id'], item_text: item['bouquet_lobs_desc'] } );
        this.productMisObject.push( { item_id: item['product_mis_id'], item_text: item['product_mis_desc'] } );
        this.productTypeObject.push( { item_id: item['product_type_id'], item_text: item['product_type_desc'] } );
      } 
    }

    printClaimOriginTypeFilters() {
      for(let item of this.claimOriginTypeRaw) {
        this.claimOriginTypeObject.push( { item_id: item['subtype_reclamation_origin_id'], item_text: item['subtype_reclamation_origin_desc'] } );
      }
    }

    printComplaintReasonFilters() {
      for(let item of this.complaintReasonRaw) {
        this.complaintReasonObject.push( { item_id: item['complaint_reason_id'], item_text: item['complaint_reason_desc'] } );
      }
    }
    
    printPaymentFormsFilters() {
      for(let item of this.paymentFormsRaw) {
        this.paymentFormsObject.push( { item_id: item['payment_form_id'], item_text: item['payment_form_desc'] } );
      }    
    }

    printPaymentMethodsFilters() {
      for(let item of this.paymentMethodsRaw) {
        this.paymentMethodsObject.push( { item_id: item['payment_method_id'], item_text: item['payment_method_desc'] } );
      }
    }

    removeDuplicates(): void {
      this.familyChannelItems = this.filterDuplicates( this.familyChannelObject );
      this.macroChannelItems = this.filterDuplicates( this.macroChannelObject );
      this.channelItems = this.filterDuplicates( this.channelObject );
      this.pointChannelItems = this.filterDuplicates( this.pointChannelObject );
      this.corporateSocietyItems = this.filterDuplicates( this.corporateSocietyObject );
      this.territorialItems = this.filterDuplicates( this.territorialObject );
      this.zoneItems = this.filterDuplicates( this.zoneObject );
      this.productCategoryItems = this.filterDuplicates( this.productCategoryObject );
      this.productSubCategoryItems = this.filterDuplicates( this.productSubCategoryObject );
      this.productFamilyItems = this.filterDuplicates( this.productFamilyObject );
      this.productTechnicalItems = this.filterDuplicates( this.productTechnicalObject );
      this.bouquetBusinessItems = this.filterDuplicates( this.bouquetBusinessObject );
      this.bouquetLobsItems = this.filterDuplicates( this.bouquetLobsObject );
      this.productMisItems = this.filterDuplicates( this.productMisObject );
      this.productTypeItems = this.filterDuplicates( this.productTypeObject );
      this.claimOriginTypeItems = this.filterDuplicates( this.claimOriginTypeObject );
      this.complaintReasonItems = this.filterDuplicates( this.complaintReasonObject );
      this.paymentFormsItems = this.filterDuplicates( this.paymentFormsObject );
      this.paymentMethodsItems = this.filterDuplicates( this.paymentMethodsObject );
    }

    cloneFiltersState(): void {
      this.monthFilterSelectionInfoTemp = _.cloneDeep(this.filtersService.selectedIndexMonth$.value);
      this.yearFilterSelectionInfoTemp = _.cloneDeep(this.filtersService.selectedIndexYear$.value);
      this.currencyFilterSelectionInfoTemp = _.cloneDeep(this.filtersService.selectedIndexCurrency$.value);
      this.budgetsFilterSelectionInfoTemp = _.cloneDeep(this.filtersService.selectedIndexBudgets$.value);
      this.countryFilterSelectionInfoTemp = _.cloneDeep(this.filtersService.selectedIndexCountry$.value);
      this.familyChannelFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.familyChannelFilterSelectionInfo$.value );
      this.macroChannelFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.macroChannelFilterSelectionInfo$.value );
      this.channelFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.channelFilterSelectionInfo$.value );
      this.pointChannelFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.pointChannelFilterSelectionInfo$.value );
      this.societyCorporateFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.corporateSocietyFilterSelectionInfo$.value );
      this.territorialFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.territorialFilterSelectionInfo$.value );
      this.zoneFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.zoneFilterSelectionInfo$.value );
      this.productCategoryFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.productCategoryFilterSelectionInfo$.value );
      this.productSubCategoryFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.productSubCategoryFilterSelectionInfo$.value );
      this.productFamilyFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.productFamilyFilterSelectionInfo$.value );
      this.productTechnicalFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.productTechnicalFilterSelectionInfo$.value );
      this.bouquetBusinessFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.bouquetBusinessFilterSelectionInfo$.value );
      this.bouquetLobsFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.bouquetLobsFilterSelectionInfo$.value );
      this.productMisFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.productMisFilterSelectionInfo$.value );
      this.productTypeFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.productTypeFilterSelectionInfo$.value );
      this.claimOriginTypeFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.claimOriginTypeFilterSelectionInfo$.value );
      this.complaintReasonFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.complaintReasonFilterSelectionInfo$.value );
      this.paymentFormsFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.paymentFormsFilterSelectionInfo$.value );
      this.paymentMethodsFilterSelectionInfoTemp = _.cloneDeep( this.filtersService.paymentMethodsFilterSelectionInfo$.value );
    }

    filterDuplicates( object ) {
      return this.filtersHelperService.filterDuplicates( object );   
    }

    setAppliedFilters() {
      this.filtersToRequest = [];
      
      this.setAppliedMonth();             // month
      this.setAppliedYear();              // year
      this.setAppliedDay();               // day
      this.setAppliedChannel();           // channel
      this.setAppliedCountry();           // country
      this.setAppliedCurrency();          // currency
      this.setAppliedProduct();           // product
      this.setAppliedBudgets();           // budgets
      this.setAppliedBouquetBusiness();   // bouquet business
      this.setAppliedBouquetLobs();       // bouquet lobs
      this.setAppliedCorporateSociety();  // corporate society
      this.setAppliedProductMis();        // product mis
      this.setAppliedProductType();       // product type
      this.setAppliedTerritorial();       // territorial/zone
      this.setAppliedClaimOriginType();   // claim origin type
      this.setAppliedComplaintReason();   // complaint reason
      this.setAppliedPaymentForms();      // payment forms
      this.setAppliedPaymentMethods();    // payment methods

      this.filtersService.filtersValues$.next( this.filtersToRequest );

      console.log( "Applied filters" );
      console.log( this.filtersToRequest );
    }

    setAppliedMonth() {
      if ( this.filtersService.getMonthValue().value != "" ) {
        let valueItems: any = [];
        let itemText: string;
        itemText = this.filtersService.getFieldString(this.utilsService.padWithZeros( parseInt( this.filtersService.getMonthValue().value), 2 ), this.filtersService.itemsMonth$.value );
        valueItems.push({item_id: this.utilsService.padWithZeros( parseInt(this.filtersService.getMonthValue().value), 2 ), item_text: itemText});
        this.filtersToRequest.push( { name: 'month', value: this.utilsService.padWithZeros( parseInt(this.filtersService.getMonthValue().value), 2 ), valueItems: valueItems } );
      }
    }

    setAppliedYear() {
      if ( this.filtersService.getYearValue().value != "" ) {
        let valueItems: any = [];
        let itemText: string;
        itemText = this.filtersService.getFieldString(this.utilsService.padWithZeros( parseInt( this.filtersService.getYearValue().value), 2 ), this.filtersService.itemsYear$.value );
        valueItems.push({item_id: this.utilsService.padWithZeros( parseInt(this.filtersService.getYearValue().value), 2 ), item_text: itemText});
        this.filtersToRequest.push( { name: 'year', value: this.filtersService.getYearValue().value, valueItems: valueItems });
      }
    }

    setAppliedDay() {
      if ( this.filtersService.getMonthValue().value != "" && this.filtersService.getYearValue().value != "" ) {
        const day = this.dateService.calculateDay(parseInt(this.filtersService.getYearValue().value), parseInt(this.filtersService.getMonthValue().value));
        let valueItems: any = [];
        let itemText: string;
        itemText = day.toString();
        valueItems.push({item_id: itemText, item_text: itemText});
        this.filtersToRequest.push( { name: 'day', value: day, valueItems: valueItems });
      }
    }

    setAppliedChannel() {
      if ( this.filtersService.pointChannelFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'channel_point', value: this.filtersService.pointChannelFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.pointChannelFilterSelectionInfo$.value['selected_items'] } );
      } else if ( this.filtersService.channelFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'channel', value: this.filtersService.channelFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.channelFilterSelectionInfo$.value['selected_items'] } );
      } else if ( this.filtersService.macroChannelFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'channel_macro', value: this.filtersService.macroChannelFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.macroChannelFilterSelectionInfo$.value['selected_items'] } );
      } else if ( this.filtersService.familyChannelFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'channel_family', value: this.filtersService.familyChannelFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.familyChannelFilterSelectionInfo$.value['selected_items'] } );
      }
    }

    setAppliedCountry() {
      if ( this.filtersService.userRawValues$.value['countries'].length > 1 ) {
        let countryValues: any[] = [];
        let countryValue;
        typeof this.filtersService.selectedIndexCountry$.value != 'string' ? countryValue = this.filtersService.selectedIndexCountry$.value['id'] : countryValue = this.filtersService.selectedIndexCountry$.value;
        countryValues.push(countryValue);
        let valueItems: any = [];
        let itemText: string;
        let countryIdToSearch;
        typeof this.filtersService.selectedIndexCountry$.value == "string" ? countryIdToSearch = this.filtersService.selectedIndexCountry$.value : countryIdToSearch = this.filtersService.selectedIndexCountry$.value['id'];
        itemText = this.filtersService.getFieldString(countryIdToSearch, this.countries );
        valueItems.push({item_id: this.filtersService.countryFilterValues$.value['id'], item_text: itemText});
        this.filtersToRequest.push( { name: 'country', value: countryValues, valueItems: valueItems });
      }
    }

    setAppliedCurrency() {
      if ( this.filtersService.currencyFilterValues$.value != "" ) {
        let valueItems: any = [];
        let itemText: string;
        let currencyIso: string;
        let currencySymbol: string;
        currencyIso = this.filtersService.getFieldString(this.filtersService.currencyFilterValues$.value, this.filtersService.currencyRawValuesMaster$.value, 'id', 'iso');
        currencySymbol = this.filtersService.getFieldString(this.filtersService.currencyFilterValues$.value, this.filtersService.currencyRawValuesMaster$.value, 'id', 'symbol');
        this.currencyInfoSent.iso = currencyIso;
        this.currencyInfoSent.symbol = currencySymbol;
        this.filtersService.currencyInfoSent$.next(this.currencyInfoSent);
        itemText = this.filtersService.getFieldString(this.filtersService.currencyFilterValues$.value, this.filtersService.currencyRawValuesMaster$.value, 'id', 'description' );
        valueItems.push({item_id: this.filtersService.currencyFilterValues$.value, item_text: itemText});
        this.filtersToRequest.push( { name: 'currency', value: this.filtersService.currencyFilterValues$.value, valueItems: valueItems });
      }
    }

    setAppliedProduct() {
      if ( this.filtersService.productTechnicalFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'product_technical', value: this.filtersService.productTechnicalFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.productTechnicalFilterSelectionInfo$.value['selected_items'] } );
      } else if ( this.filtersService.productFamilyFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'product_family', value: this.filtersService.productFamilyFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.productFamilyFilterSelectionInfo$.value['selected_items'] } );
      } else if ( this.filtersService.productSubCategoryFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'product_subcategory', value: this.filtersService.productSubCategoryFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.productSubCategoryFilterSelectionInfo$.value['selected_items'] } );
      } else if ( this.filtersService.productCategoryFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'product_category', value: this.filtersService.productCategoryFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.productCategoryFilterSelectionInfo$.value['selected_items'] } );
      }
    }

    setAppliedBudgets() {
      if ( this.filtersService.budgetsFilterValues$.value != "" ) {
        let valueItems: any = [];
        let itemText: string;
        itemText = this.filtersService.getFieldString(this.filtersService.budgetsFilterValues$.value, this.itemsBudgets );
        valueItems.push({item_id: this.filtersService.budgetsFilterValues$.value, item_text: itemText});
        this.filtersToRequest.push( { name: 'budgets', value: this.filtersService.budgetsFilterValues$.value, valueItems: valueItems });
      }
    }

    setAppliedBouquetBusiness() {
      if ( this.filtersService.bouquetBusinessFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'bouquet_business', value: this.filtersService.bouquetBusinessFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.bouquetBusinessFilterSelectionInfo$.value['selected_items'] });
      }
    }
    
    setAppliedBouquetLobs() {
      if ( this.filtersService.bouquetLobsFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'bouquet_lobs', value: this.filtersService.bouquetLobsFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.bouquetLobsFilterSelectionInfo$.value['selected_items'] });
      }
    }
    
    setAppliedCorporateSociety() {
      if ( this.filtersService.corporateSocietyFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'corporate_society', value: this.filtersService.corporateSocietyFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.corporateSocietyFilterSelectionInfo$.value['selected_items'] });
      }    
    }

    setAppliedProductMis() {
      if ( this.filtersService.productMisFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'producto_mis', value: this.filtersService.productMisFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.productMisFilterSelectionInfo$.value['selected_items'] });
      }
    }

    setAppliedProductType() {
      if ( this.filtersService.productTypeFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'product_type', value: this.filtersService.productTypeFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.productTypeFilterSelectionInfo$.value['selected_items'] });
      }
    }

    setAppliedTerritorial() {
      if ( this.filtersService.zoneFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'zone', value: this.filtersService.zoneFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.zoneFilterSelectionInfo$.value['selected_items'] } );
      } else if ( this.filtersService.territorialFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'territorial', value: this.filtersService.territorialFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.territorialFilterSelectionInfo$.value['selected_items'] } );
      }
    }

    setAppliedClaimOriginType() {
      if ( this.filtersService.claimOriginTypeFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'claim_origin_type', value: this.filtersService.claimOriginTypeFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.claimOriginTypeFilterSelectionInfo$.value['selected_items'] });
      }
    }

    setAppliedComplaintReason() {
      if ( this.filtersService.complaintReasonFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'complaint_reason', value: this.filtersService.complaintReasonFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.complaintReasonFilterSelectionInfo$.value['selected_items'] });
      }
    }

    setAppliedPaymentForms() {
      if ( this.filtersService.paymentFormsFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'payment_forms', value: this.filtersService.paymentFormsFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.paymentFormsFilterSelectionInfo$.value['selected_items'] });
      }
    }

    setAppliedPaymentMethods() {
      if ( this.filtersService.paymentMethodsFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
        this.filtersToRequest.push( { name: 'payment_methods', value: this.filtersService.paymentMethodsFilterSelectionInfo$.value['selected_ids'], valueItems: this.filtersService.paymentMethodsFilterSelectionInfo$.value['selected_items'] });
      }
    }

    addSelectedItem( object: any[], objectIds: any[], observable: any, id: string, text: string ) {
      let selectorInfo: SelectorInfo = { selected_items: [], selected_ids: [] };
      if( object.length == 0 ) {
        object.push( { item_id: id, item_text: text } );
        objectIds.push( id );
      } else {
        if( object.findIndex(obj => obj.item_id === id) == -1 ) {
          object.push( { item_id: id, item_text: text } );
          objectIds.push( id );
        }
      }
      selectorInfo.selected_items = object;
      selectorInfo.selected_ids = objectIds;
      observable.next( selectorInfo );
    }

    removeSelected( object: any[], objectIds: any[], observable: any, id: string ) {
      let selectorInfo: SelectorInfo = { selected_items: [], selected_ids: [] };
      var removeIndex = object.map( function(obj) { return obj.item_id; } ).indexOf( id );
      object.splice( removeIndex, 1 );
      const index = objectIds.indexOf(id, 0);

      if (index > -1) {
        objectIds.splice(index, 1);
      }
      selectorInfo.selected_items = object;
      selectorInfo.selected_ids = objectIds;
      observable.next( selectorInfo );
    }

    removeAllSelected(itemsId: any[], itemsIdSelected: any[], selectionInfoObservable: any, emptySelectorInfo: SelectorInfo): void {
      itemsId = [];
      itemsIdSelected = [];
      selectionInfoObservable.next( emptySelectorInfo );
    }

    checkHierarchy( hierarchyName: string ) {
      let clearSelection: boolean = true;
      
      switch( hierarchyName ) {

        // channel
        case "familyChannelForm": this.filterHierarchiesFamilyChannel(clearSelection); break;
        case "macroChannelForm": this.filterHierarchiesMacroChannel(clearSelection); break;
        case "channelForm": this.filterHierarchiesChannel(clearSelection); break;
        case "pointChannelForm": this.filterHierarchiesPointChannel(clearSelection); break;
        case "territorialForm": this.filterHierarchiesTerritorial(clearSelection); break;
        case "zoneForm": this.filterHierarchiesZone(clearSelection); break;

        // product
        case "productCategoryForm": this.filterHierarchiesProductCategory(clearSelection); break;
        case "productSubCategoryForm": this.filterHierarchiesProductSubCategory(clearSelection); break; 
        case "productFamilyForm": this.filterHierarchiesProductFamily(clearSelection); break; 
        case "productTechnicalForm": this.filterHierarchiesProductTechnical(clearSelection); break; 
        case "bouquetBusinessForm": this.filterHierarchiesBouquetBusiness(clearSelection); break; 
        case "bouquetLobsForm": this.filterHierarchiesBouquetLobs(clearSelection); break;         

        default: break;

      }

    }

    checkAllHierarchies() {
        this.filterHierarchiesFamilyChannel(false);     // channel
        this.filterHierarchiesProductCategory(false);   // product
    }

    pushSelectorRawValues( fieldToCheck: any, objectToPush: any, id: any, text: string ) {
      if( fieldToCheck != "" && fieldToCheck != null  && fieldToCheck != "'\N'") {
        objectToPush.push( { item_id: id, item_text: text } );                   
      }
    }

    selectCountry(country: any): void {
      this.filtersService.selectedIndexCountry$.next(country);
      this.filtersService.countryFilterValues$.next(country);
      this.openResetFiltersModal();
    }

    openResetFiltersModal(): void {
      let backDrop;
      const dialogRef = this.dialog.open(ResetFiltersDialogComponent, {
          disableClose: true,
          panelClass: ['mat-dialog-gen', 'mat-dialog-reset-filters'],
          backdropClass: 'backdrop-dialog-reset-filters',
      });
      backDrop = document.querySelector('.cdk-overlay-container');
      backDrop.classList.add('overlay-dialog-reset-filters');

      dialogRef.afterClosed().subscribe(result => {
        backDrop.classList.remove('overlay-dialog-reset-filters');
      });
    }

    showFiltersLoading(): void {
      this.body = document.querySelector('body');
      this.body.classList.add('filters-loading');
    }

    hideFiltersLoading(): void {
      this.body.classList.remove('filters-loading');
    }

    subscribeToUserInfo(): void {
      this.userInfoSubscription = this.events.userInfoLoaded$
        .subscribe(
          data => {
              this.userInfo = data;
              this.filtersService.userRawValues$.next(this.userInfo); 
          }
        )
    }
    
    subscribeToFiltersRefreshEvent(): void {
      this.refreshFiltersSubscription = this.events.refreshFilters$
        .subscribe(
          data => {
            this.resetFilters();
            this.filtersService.countryChanged$.next(true);
            this.applyFilters();
          }
        )
    }

    setInitCountryValues(): void {
      this.filtersService.userRawValues$.subscribe( data => { 
        this.countryName = data['countries'][0]['desc'];
        this.countries = [];
        for(let country of data['countries']) {
          this.countries.push({
            id: country['id'],
            name: country['desc']
          });
        }
      } );
    }

    subscribeToFiltersShowEvent(): void {
      this.subscription = this.filtersService.filtersBlockVisibility$.subscribe(
        show => {
          this.filtersOn = show;
        }
      );
    }

    subscribeToFiltersRawValues(): void {
      //currency
      this.filtersService.currencyRawValues$.subscribe( data => { this.currencyRaw = data; } );
      this.filtersService.currencyRawValuesMaster$.subscribe( data => { this.currencyRawMaster = data; } );
      // channel
      this.filtersService.channelRawValues$.subscribe( data => { this.channelRaw = data; } );

      // channels (all) filters hierarchy
      this.filtersService.channelAllHierarchyValues$.subscribe( data => { this.channelAllHierarchy = data; } );

      // macro channel filters hierarchy
      this.filtersService.macroChannelHierarchyValues$.subscribe( data => { this.macroChannelHierarchy = data; } );
      // channel filters hierarchy
      this.filtersService.channelHierarchyValues$.subscribe( data => { this.channelHierarchy = data; } );
      // point channel filters hierarchy
      this.filtersService.pointChannelHierarchyValues$.subscribe( data => { this.pointChannelHierarchy = data; } );
      // territorial filters hierarchy
      this.filtersService.territorialHierarchyValues$.subscribe( data => { this.territorialHierarchy = data; } );
      // zone filters hierarchy
      this.filtersService.zoneHierarchyValues$.subscribe( data => { this.zoneHierarchy = data; } );
      // corporate society filters hierarchy
      this.filtersService.corporateSocietyHierarchyValues$.subscribe( data => { this.corporateSocietyHierarchy = data; } );
      // product subcategory filters hierarchy
      this.filtersService.productSubCategoryHierarchyValues$.subscribe( data => { this.productSubCategoryHierarchy = data; } );
      // product family filters hierarchy
      this.filtersService.productFamilyHierarchyValues$.subscribe( data => { this.productFamilyHierarchy = data; } );
      // product technical filters hierarchy
      this.filtersService.productTechnicalHierarchyValues$.subscribe( data => { this.productTechnicalHierarchy = data; } );

      // bouquet business filters hierarchy
      this.filtersService.bouquetBusinessHierarchyValues$.subscribe( data => { this.bouquetBusinessHierarchy = data; } );
      // bouquet lobs filters hierarchy
      this.filtersService.bouquetLobsHierarchyValues$.subscribe( data => { this.bouquetLobsHierarchy = data; } );
      // product mis filters hierarchy
      this.filtersService.productMisHierarchyValues$.subscribe( data => { this.productMisHierarchy = data; } );
      // product type filters hierarchy
      this.filtersService.productTypeHierarchyValues$.subscribe( data => { this.productTypeHierarchy = data; } );
      // product
      this.filtersService.productRawValues$.subscribe( data => { this.productRaw = data; } );
      // claim origin type
      this.filtersService.claimOriginTypeRawValues$.subscribe( data => { this.claimOriginTypeRaw = data; } );
      // complaint reason
      this.filtersService.complaintReasonRawValues$.subscribe( data => { this.complaintReasonRaw = data; } );
      // payment forms
      this.filtersService.paymentFormsRawValues$.subscribe( data => { this.paymentFormsRaw = data; } );
      // payment methods
      this.filtersService.paymentMethodsRawValues$.subscribe( data => { this.paymentMethodsRaw = data; } );
      // budgets
      this.filtersService.budgetsRawValues$.subscribe( data => { this.budgetsRaw = data; } );

      // Subscribe to filters values
      // month
      this.filtersService.selectedIndexMonth$
        .subscribe( data => { this.selectedIndexMonth = data; } )

      this.filtersService.getFiltersMonth()
        .subscribe(data => { 
          this.itemsMonth = data;
          // Set selected value for month selector
          for (let item of this.itemsMonth) {
            item['selected'] = false;
            if( parseInt(item['id']) == this.filtersService.selectedIndexMonth$.value ) {
              item['selected'] = true;
            }
          }
      });
      // year
      this.filtersService.selectedIndexYear$
        .subscribe( data => { this.selectedIndexYear = data; } )

      this.filtersService.getFiltersYear()
        .subscribe(data => { 
          this.itemsYear = data;
          // Set selected value for year selector
          for (let item of this.itemsYear) {
            item['selected'] = false;
            if( parseInt(item['id']) == this.filtersService.selectedIndexYear$.value ) {
              item['selected'] = true;
            }
          }
        });

      // Subscribe to filters values (id's & active selections)
      // country
      this.filtersService.selectedIndexCountry$
        .subscribe( data => { this.selectedIndexCountry = data; } )
      // currency
      this.filtersService.selectedIndexCurrency$
        .subscribe( data => { this.selectedIndexCurrency = data; } )
      // budgets
      this.filtersService.selectedIndexBudgets$
      .subscribe( data => { this.selectedIndexBudgets = data; } )

      // family channel
      this.filtersService.familyChannelFilterSelectionInfo$
        .subscribe(data => { this.familyChannelSelectedItemsInfo = data; this.selectedItemsFamilyChannel = data['selected_items']; this.selectedIdsFamilyChannel = data['selected_ids']; });
      // macro channel
      this.filtersService.macroChannelFilterSelectionInfo$
      .subscribe(data => { this.macroChannelSelectedItemsInfo = data; this.selectedItemsMacroChannel = data['selected_items']; this.selectedIdsMacroChannel = data['selected_ids']; });
      // channel
      this.filtersService.channelFilterSelectionInfo$
      .subscribe(data => { this.channelSelectedItemsInfo = data; this.selectedItemsChannel = data['selected_items']; this.selectedIdsChannel = data['selected_ids']; });
      // point channel
      this.filtersService.pointChannelFilterSelectionInfo$
      .subscribe(data => { this.pointChannelSelectedItemsInfo = data; this.selectedItemsPointChannel = data['selected_items']; this.selectedIdsPointChannel = data['selected_ids']; });
      // Corporate Society
      this.filtersService.corporateSocietyFilterSelectionInfo$
      .subscribe(data => { this.corporateSocietySelectedItemsInfo = data; this.selectedItemsSocietyCorporate = data['selected_items']; this.selectedIdsSocietyCorporate = data['selected_ids']; });
      // Territorial
      this.filtersService.territorialFilterSelectionInfo$
      .subscribe(data => { this.territorialSelectedItemsInfo = data; this.selectedItemsTerritorial = data['selected_items']; this.selectedIdsTerritorial = data['selected_ids']; });
      // Zone
      this.filtersService.zoneFilterSelectionInfo$
      .subscribe(data => { this.zoneSelectedItemsInfo = data; this.selectedItemsZone = data['selected_items']; this.selectedIdsZone = data['selected_ids']; });
      // Product Category
      this.filtersService.productCategoryFilterSelectionInfo$
      .subscribe(data => { this.productCategorySelectedItemsInfo = data; this.selectedItemsProductCategory = data['selected_items']; this.selectedIdsProductCategory = data['selected_ids']; });
      // Product SubCategory
      this.filtersService.productSubCategoryFilterSelectionInfo$
      .subscribe(data => { this.productSubCategorySelectedItemsInfo = data; this.selectedItemsProductSubCategory = data['selected_items']; this.selectedIdsProductSubCategory = data['selected_ids']; });
      // Product Family
      this.filtersService.productFamilyFilterSelectionInfo$
      .subscribe(data => { this.productFamilySelectedItemsInfo = data; this.selectedItemsProductFamily = data['selected_items']; this.selectedIdsProductFamily = data['selected_ids']; });
      // Product Technical
      this.filtersService.productTechnicalFilterSelectionInfo$
      .subscribe(data => { this.productTechnicalSelectedItemsInfo = data; this.selectedItemsProductTechnical = data['selected_items']; this.selectedIdsProductTechnical = data['selected_ids']; });
      // Bouquet Business
      this.filtersService.bouquetBusinessFilterSelectionInfo$
      .subscribe(data => { this.bouquetBusinessSelectedItemsInfo = data; this.selectedItemsBouquetBusiness = data['selected_items']; this.selectedIdsBouquetBusiness = data['selected_ids']; });
      // Bouquet LOBS
      this.filtersService.bouquetLobsFilterSelectionInfo$
      .subscribe(data => { this.bouquetLobsSelectedItemsInfo = data; this.selectedItemsBouquetLobs = data['selected_items']; this.selectedIdsBouquetLobs = data['selected_ids']; });
      // Product MIS
      this.filtersService.productMisFilterSelectionInfo$
      .subscribe(data => { this.productMisSelectedItemsInfo = data; this.selectedItemsProductMis = data['selected_items']; this.selectedIdsProductMis = data['selected_ids']; });
      // Product type
      this.filtersService.productTypeFilterSelectionInfo$
      .subscribe(data => { this.productTypeSelectedItemsInfo = data; this.selectedItemsProductType = data['selected_items']; this.selectedIdsProductType = data['selected_ids']; });
      // Claim origin type
      this.filtersService.claimOriginTypeFilterSelectionInfo$
      .subscribe(data => { this.claimOriginTypeSelectedItemsInfo = data; this.selectedItemsClaimOriginType = data['selected_items']; this.selectedIdsClaimOriginType = data['selected_ids']; });
      // Complaint reason
      this.filtersService.complaintReasonFilterSelectionInfo$
      .subscribe(data => { this.complaintReasonSelectedItemsInfo = data; this.selectedItemsComplaintReason = data['selected_items']; this.selectedIdsComplaintReason = data['selected_ids']; });
      // Payment forms
      this.filtersService.paymentFormsFilterSelectionInfo$
      .subscribe(data => { this.paymentFormsSelectedItemsInfo = data; this.selectedItemsPaymentForms = data['selected_items']; this.selectedIdsPaymentForms = data['selected_ids']; });
      // Payment methods
      this.filtersService.paymentMethodsFilterSelectionInfo$
      .subscribe(data => { this.paymentMethodsSelectedItemsInfo = data; this.selectedItemsPaymentMethods = data['selected_items']; this.selectedIdsPaymentMethods = data['selected_ids']; });
      // Budgets
      this.filtersService.budgetsFilterSelectionInfo$
      .subscribe(data => { this.budgetsSelectedItemsInfo = data; this.selectedItemsBudgets = data['selected_items']; this.selectedIdsBudgets = data['selected_ids']; });
    }

    subscribeToSelectorsChanges(): void {
      this.filtersDropdownSubscription = this.events.filterDropdownEvent$.subscribe(
        item => {
          let selectionInfoObservable;
          let selectionInfoSelectedItems;
          let selectionInfoSelectedIds;
          let itemsId;
          let itemsIdSelected;
          let checkHierarchy: boolean = false;
          let emptySelectorInfo: SelectorInfo = { selected_items: [], selected_ids: [] };

          // Push checked item to corresponding array of id's 
          switch(item['form_name']) {
            case "familyChannelForm": {
              selectionInfoObservable = this.filtersService.familyChannelFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.familyChannelItemsId;
              itemsIdSelected = this.familyChannelItemsIdSelected;
              checkHierarchy = true;

              break; 
            }
            case "macroChannelForm": {
              selectionInfoObservable = this.filtersService.macroChannelFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.macroChannelItemsId;
              itemsIdSelected = this.macroChannelItemsIdSelected;
              checkHierarchy = true;

              break; 
            }
            case "channelForm": {
              selectionInfoObservable = this.filtersService.channelFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.channelItemsId;
              itemsIdSelected = this.channelItemsIdSelected;
              checkHierarchy = true;

              break; 
            }
            case "pointChannelForm": {
              selectionInfoObservable = this.filtersService.pointChannelFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.pointChannelItemsId;
              itemsIdSelected = this.pointChannelItemsIdSelected;
              checkHierarchy = true;

              break; 
            }
            case "societyCorporateForm": {
              selectionInfoObservable = this.filtersService.corporateSocietyFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.corporateSocietyItemsId;
              itemsIdSelected = this.corporateSocietyItemsIdSelected;
              checkHierarchy = false;

              break; 
            }
            case "territorialForm": {
              selectionInfoObservable = this.filtersService.territorialFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.territorialItemsId;
              itemsIdSelected = this.territorialItemsIdSelected;
              checkHierarchy = true;              
    
              break; 
            }
            case "zoneForm": {
              selectionInfoObservable = this.filtersService.zoneFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.zoneItemsId;
              itemsIdSelected = this.zoneItemsIdSelected;
              checkHierarchy = true;               
    
              break; 
            }
            case "productCategoryForm": {
              selectionInfoObservable = this.filtersService.productCategoryFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.productCategoryItemsId;
              itemsIdSelected = this.productCategoryItemsIdSelected;
              checkHierarchy = true;
    
              break; 
            }
            case "productSubCategoryForm": {
              selectionInfoObservable = this.filtersService.productSubCategoryFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.productSubCategoryItemsId;
              itemsIdSelected = this.productSubCategoryItemsIdSelected;
              checkHierarchy = true;
    
              break; 
            }
            case "productFamilyForm": {
              selectionInfoObservable = this.filtersService.productFamilyFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.productFamilyItemsId;
              itemsIdSelected = this.productFamilyItemsIdSelected;
              checkHierarchy = true;

              break; 
            }
            case "productTechnicalForm": {
              selectionInfoObservable = this.filtersService.productTechnicalFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.productTechnicalItemsId;
              itemsIdSelected = this.productTechnicalItemsIdSelected;
              checkHierarchy = true;

              break; 
            }
            case "bouquetBusinessForm": {
              selectionInfoObservable = this.filtersService.bouquetBusinessFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.bouquetBusinessItemsId;
              itemsIdSelected = this.bouquetBusinessItemsIdSelected;
              checkHierarchy = true;

              break; 
            }
            case "bouquetLobsForm": {
              selectionInfoObservable = this.filtersService.bouquetLobsFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.bouquetLobsItemsId;
              itemsIdSelected = this.bouquetLobsItemsIdSelected;
              checkHierarchy = true;

              break; 
            }
            case "productMisForm": {
              selectionInfoObservable = this.filtersService.productMisFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.productMisItemsId;
              itemsIdSelected = this.productMisItemsIdSelected;
              checkHierarchy = false;

              break; 
            }
            case "productTypeForm": {
              selectionInfoObservable = this.filtersService.productTypeFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.productTypeItemsId;
              itemsIdSelected = this.productTypeItemsIdSelected;
              checkHierarchy = false;

              break; 
            }
            case "claimOriginTypeForm": {
              selectionInfoObservable = this.filtersService.claimOriginTypeFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.claimOriginTypeItemsId;
              itemsIdSelected = this.claimOriginTypeItemsIdSelected;
              checkHierarchy = false;

              break; 
            }
            case "complaintReasonForm": {
              selectionInfoObservable = this.filtersService.complaintReasonFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.complaintReasonItemsId;
              itemsIdSelected = this.complaintReasonItemsIdSelected;
              checkHierarchy = false;
    
              break; 
            }
            case "paymentFormsForm": {
              selectionInfoObservable = this.filtersService.paymentFormsFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.paymentFormsItemsId;
              itemsIdSelected = this.paymentFormsItemsIdSelected;
              checkHierarchy = false;
    
              break; 
            }
            case "paymentMethodsForm": {
              selectionInfoObservable = this.filtersService.paymentMethodsFilterSelectionInfo$;
              selectionInfoSelectedItems = selectionInfoObservable.value['selected_items'];
              selectionInfoSelectedIds = selectionInfoObservable.value['selected_ids'];
              itemsId = this.paymentMethodsItemsId;
              itemsIdSelected = this.paymentMethodsItemsIdSelected;
              checkHierarchy = false;
    
              break; 
            }
            default: { 
              break; 
            } 
          } 

          // Add or remove depending on action
          if( item['form_action'] == "select" ) { 
            this.addSelectedItem(selectionInfoSelectedItems, selectionInfoSelectedIds, selectionInfoObservable, item['item_id'], item['item_text'] );
          } else if( item['form_action'] == "deselect" ) { 
            this.removeSelected( selectionInfoSelectedItems, selectionInfoSelectedIds, selectionInfoObservable, item['item_id'] );
          } else {
            this.removeAllSelected(itemsId, itemsIdSelected, selectionInfoObservable, emptySelectorInfo);
          }

          if(checkHierarchy) this.checkHierarchy( item['form_name'] )
    
        }
      );
    }

    showFiltersTemplate(): void {
      this.pageIdSubscription = this.navigationService.pageId$
        .subscribe(
          id => {
            this.templateName = this.filtersHelperService.getFiltersTemplate(id);
          }
        );
    }

    filterCurrencyRawValues(): void {
      this.currencyRawMaster = this.currencyRaw;
      this.currencyRaw = this.filterCurrencyByCountry(this.currencyRaw);
    }

    removeNotValidValues(): void {
      this.currencyRaw = this.currencyRaw.filter(item => item.id > 0 && item.id != null && item.id != "null")                                                                   // Currency
      this.budgetsRaw = this.budgetsRaw.filter(item => item.budget_id > 0 && item.budget_id != null && item.budget_id != "")                                                    // Budgets
      this.paymentFormsRaw = this.paymentFormsRaw.filter(item => item.payment_form_id > 0 && item.payment_form_id != null  && item.payment_form_id != "")                       // Payment forms
      this.paymentMethodsRaw = this.paymentMethodsRaw.filter(item => item.payment_method_id > 0 && item.payment_method_id != null && item.payment_method_id != "")              // Payment forms
      this.productRaw = this.productRaw.filter(item => item.product_mis_id != "-1" && item.product_mis_id != "-2" && item.product_mis_id != null && item.product_mis_id != "")  // Product
    }

    setRawInfoInObservables(): void {
      this.filtersService.currencyRawValues$.next( this.currencyRaw );
      this.filtersService.currencyRawValuesMaster$.next( this.currencyRawMaster );
      this.filtersService.channelRawValues$.next( this.channelRaw );
      this.filtersService.productRawValues$.next( this.productRaw );
      this.filtersService.claimOriginTypeRawValues$.next( this.claimOriginTypeRaw );
      this.filtersService.complaintReasonRawValues$.next( this.complaintReasonRaw );
      this.filtersService.paymentFormsRawValues$.next( this.paymentFormsRaw );
      this.filtersService.paymentMethodsRawValues$.next( this.paymentMethodsRaw );
      this.filtersService.budgetsRawValues$.next( this.budgetsRaw );
    }

    changeLanguage(language: string): void {
      this.i18nService.changeLanguage(language);
    }

    filterHierarchiesFamilyChannel(clearFilterSelection: boolean = false): void {
      this.filterHierarchyMacroChannel(clearFilterSelection);
      this.filterHierarchyChannel(clearFilterSelection);
      this.filterHierarchyPointChannel(clearFilterSelection);
      this.filterHierarchyTerritorial(clearFilterSelection);
      this.filterHierarchyZone(clearFilterSelection);
      this.filterHierarchyCorporateSociety(clearFilterSelection);
    }

    filterHierarchiesMacroChannel(clearFilterSelection: boolean = false): void {
      this.filterHierarchyChannel(clearFilterSelection);
      this.filterHierarchyPointChannel(clearFilterSelection);
      this.filterHierarchyTerritorial(clearFilterSelection);
      this.filterHierarchyZone(clearFilterSelection);
      this.filterHierarchyCorporateSociety(clearFilterSelection);
    }

    filterHierarchiesChannel(clearFilterSelection: boolean = false): void {
      this.filterHierarchyPointChannel(clearFilterSelection);
      this.filterHierarchyTerritorial(clearFilterSelection);
      this.filterHierarchyZone(clearFilterSelection);
      this.filterHierarchyCorporateSociety(clearFilterSelection);
    }

    filterHierarchiesPointChannel(clearFilterSelection: boolean = false): void {
      this.filterHierarchyTerritorial(clearFilterSelection);
      this.filterHierarchyZone(clearFilterSelection);
      this.filterHierarchyCorporateSociety(clearFilterSelection);
    }

    filterHierarchiesTerritorial(clearFilterSelection: boolean = false): void {
      this.filterHierarchyZone(clearFilterSelection);
      this.filterHierarchyCorporateSociety(clearFilterSelection);
    }

    filterHierarchiesZone(clearFilterSelection: boolean = false): void {
      this.filterHierarchyCorporateSociety(clearFilterSelection);
    }

    filterHierarchyMacroChannel(clearFilterSelection: boolean = false): void {
      let filteredMacroChannel: any = [];
      for(let element of this.channelRaw) {
        if( this.filtersService.familyChannelFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
          for( let id of this.filtersService.familyChannelFilterSelectionInfo$.value['selected_ids'] ) {
            if( id == element['channel_family_id']) { filteredMacroChannel.push(element); }
          }
        } else {
          filteredMacroChannel.push(element);
        }
      }
      filteredMacroChannel.length > 0 ? this.filtersService.macroChannelHierarchyValues$.next( filteredMacroChannel ) : this.filtersService.macroChannelHierarchyValues$.next( this.channelRaw );
      this.macroChannelObject = [];
      for(let item of this.filtersService.macroChannelHierarchyValues$.value) {
        this.macroChannelObject.push( { item_id: item['channel_macro_id'], item_text: item['channel_macro_desc'] } );
      }
      this.macroChannelItems = this.filterDuplicates( this.macroChannelObject );
      if(clearFilterSelection) this.filtersService.macroChannelFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
    }

    filterHierarchyChannel(clearFilterSelection: boolean = false): void {
      let filteredChannel: any = [];
      for(let element of this.filtersService.macroChannelHierarchyValues$.value) {
        if( this.filtersService.macroChannelFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
          for( let id of this.filtersService.macroChannelFilterSelectionInfo$.value['selected_ids'] ) {
            if( id == element['channel_macro_id']) { filteredChannel.push(element) }
          }
        } else {
          filteredChannel.push(element);
        }
      }
      filteredChannel.length > 0 ? this.filtersService.channelHierarchyValues$.next( filteredChannel ) : this.filtersService.channelHierarchyValues$.next( this.channelRaw );
      this.channelObject = [];
      for(let item of this.filtersService.channelHierarchyValues$.value) {
        this.channelObject.push( { item_id: item['channel_id'], item_text: item['channel_desc'] } );
      }
      this.channelItems = this.filterDuplicates( this.channelObject );
      if(clearFilterSelection) this.filtersService.channelFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
    }

    filterHierarchyPointChannel(clearFilterSelection: boolean = false): void {
      let filteredPointChannel: any = [];
      for(let element of this.filtersService.channelHierarchyValues$.value) {
        if( this.filtersService.channelFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
          for( let id of this.filtersService.channelFilterSelectionInfo$.value['selected_ids'] ) {
            if( id == element['channel_id']) { filteredPointChannel.push(element); }
          }
        } else {
          filteredPointChannel.push(element);
        }
      }
      filteredPointChannel.length > 0 ? this.filtersService.pointChannelHierarchyValues$.next( filteredPointChannel ) : this.filtersService.pointChannelHierarchyValues$.next( this.channelRaw );
      this.pointChannelObject = [];
      for(let item of this.filtersService.pointChannelHierarchyValues$.value) {
        this.pointChannelObject.push( { item_id: item['channel_point_id'], item_text: item['channel_point_desc'] } );
      }
      this.pointChannelItems = this.filterDuplicates( this.pointChannelObject );
      if(clearFilterSelection) this.filtersService.pointChannelFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
    }

    filterHierarchyTerritorial(clearFilterSelection: boolean = false): void {
      let filteredTerritorial: any = [];
      for(let element of this.filtersService.pointChannelHierarchyValues$.value) {
        if( this.filtersService.pointChannelFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
          for( let id of this.filtersService.pointChannelFilterSelectionInfo$.value['selected_ids'] ) {
            if( id == element['channel_point_id']) { filteredTerritorial.push(element); }
          }
        } else {
          filteredTerritorial.push(element);
        }
      }
      filteredTerritorial.length > 0 ? this.filtersService.territorialHierarchyValues$.next( filteredTerritorial ) : this.filtersService.territorialHierarchyValues$.next( this.channelRaw );
      this.territorialObject = [];
      for(let item of this.filtersService.territorialHierarchyValues$.value) {
        this.territorialObject.push( { item_id: item['territorial_id'], item_text: item['territorial_desc'] } );
      }
      this.territorialItems = this.filterDuplicates( this.territorialObject );
      if(clearFilterSelection) this.filtersService.territorialFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
    }

    filterHierarchyZone(clearFilterSelection: boolean = false): void {
      let filteredZone: any = [];
      for(let element of this.filtersService.territorialHierarchyValues$.value) {
        if( this.filtersService.territorialFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
          for( let id of this.filtersService.territorialFilterSelectionInfo$.value['selected_ids'] ) {
            if( id == element['territorial_id']) { filteredZone.push(element); }
          }
        } else {
          filteredZone.push(element);
        }
      }
      filteredZone.length > 0 ? this.filtersService.zoneHierarchyValues$.next( filteredZone ) : this.filtersService.zoneHierarchyValues$.next( this.channelRaw );
      this.zoneObject = [];
      for(let item of this.filtersService.zoneHierarchyValues$.value) {
        this.zoneObject.push( { item_id: item['zone_id'], item_text: item['zone_desc'] } );
      }
      this.zoneItems = this.filterDuplicates( this.zoneObject );
      if(clearFilterSelection) this.filtersService.zoneFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
    }

    filterHierarchyCorporateSociety(clearFilterSelection: boolean = false): void {
      let filteredCorporateSociety: any = [];
      for(let element of this.filtersService.zoneHierarchyValues$.value) {
        if( this.filtersService.zoneFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
          for( let id of this.filtersService.zoneFilterSelectionInfo$.value['selected_ids'] ) {
            if( id == element['zone_id']) { filteredCorporateSociety.push(element); }
          }
        } else {
          filteredCorporateSociety.push(element);
        }
      }
      filteredCorporateSociety.length > 0 ? this.filtersService.corporateSocietyHierarchyValues$.next( filteredCorporateSociety ) : this.filtersService.corporateSocietyHierarchyValues$.next( this.channelRaw );
      this.corporateSocietyObject = [];
      for(let item of this.filtersService.corporateSocietyHierarchyValues$.value) {
        this.corporateSocietyObject.push( { item_id: item['corporate_society_id'], item_text: item['corporate_society_desc'] } );
      }
      this.corporateSocietyItems = this.filterDuplicates( this.corporateSocietyObject );
      if(clearFilterSelection) this.filtersService.corporateSocietyFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
    }

    filterHierarchiesProductCategory(clearFilterSelection: boolean = false): void {
      this.filterHierarchyProductSubCategory(clearFilterSelection);
      this.filterHierarchyProductFamily(clearFilterSelection);
      this.filterHierarchyProductTechnical(clearFilterSelection);
      this.filterHierarchyBouquetBusiness(clearFilterSelection);
      this.filterHierarchyBouquetLobs(clearFilterSelection);
      this.filterHierarchyProductType(clearFilterSelection);
      // this.filterHierarchyProductMis(clearFilterSelection);
    }

    filterHierarchiesProductSubCategory(clearFilterSelection: boolean = false): void {
      this.filterHierarchyProductFamily(clearFilterSelection);
      this.filterHierarchyProductTechnical(clearFilterSelection);
      this.filterHierarchyBouquetBusiness(clearFilterSelection);
      this.filterHierarchyBouquetLobs(clearFilterSelection);
      this.filterHierarchyProductType(clearFilterSelection);
      // this.filterHierarchyProductMis(clearFilterSelection);
    }

    filterHierarchiesProductFamily(clearFilterSelection: boolean = false): void {
      this.filterHierarchyProductTechnical(clearFilterSelection);
      this.filterHierarchyBouquetBusiness(clearFilterSelection);
      this.filterHierarchyBouquetLobs(clearFilterSelection);
      this.filterHierarchyProductType(clearFilterSelection);
      // this.filterHierarchyProductMis(clearFilterSelection);
    }

    filterHierarchiesProductTechnical(clearFilterSelection: boolean = false): void {
      this.filterHierarchyBouquetBusiness(clearFilterSelection);
      this.filterHierarchyBouquetLobs(clearFilterSelection);
      this.filterHierarchyProductType(clearFilterSelection);
      // this.filterHierarchyProductMis(clearFilterSelection);
    }

    filterHierarchiesBouquetBusiness(clearFilterSelection: boolean = false): void {
      this.filterHierarchyBouquetLobs(clearFilterSelection);
      this.filterHierarchyProductType(clearFilterSelection);
      // this.filterHierarchyProductMis(clearFilterSelection);
    }

    filterHierarchiesBouquetLobs(clearFilterSelection: boolean = false): void {
      this.filterHierarchyProductType(clearFilterSelection);
    }

    filterHierarchyProductSubCategory(clearFilterSelection: boolean = false): void {
      let filteredProductSubCategory: any = [];
      for(let element of this.productRaw) {
        if( this.filtersService.productCategoryFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
          for( let id of this.filtersService.productCategoryFilterSelectionInfo$.value['selected_ids'] ) {
            if( id == element['product_category_id']) { filteredProductSubCategory.push(element); }
          }
        } else {
          filteredProductSubCategory.push(element);
        }
      }
      filteredProductSubCategory.length > 0 ? this.filtersService.productSubCategoryHierarchyValues$.next( filteredProductSubCategory ) : this.filtersService.productSubCategoryHierarchyValues$.next( this.productRaw );
      this.productSubCategoryObject = [];
      for(let item of this.filtersService.productSubCategoryHierarchyValues$.value) {
        this.productSubCategoryObject.push( { item_id: item['product_subcategory_id'], item_text: item['product_subcategory_'] } );
      }
      this.productSubCategoryItems = this.filterDuplicates( this.productSubCategoryObject );
      if(clearFilterSelection) this.filtersService.productSubCategoryFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
    }

    filterHierarchyProductFamily(clearFilterSelection: boolean = false): void {
      let filteredProductFamily: any = [];
      for(let element of this.filtersService.productSubCategoryHierarchyValues$.value) {
        if( this.filtersService.productSubCategoryFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
          for( let id of this.filtersService.productSubCategoryFilterSelectionInfo$.value['selected_ids'] ) {
            if( id == element['product_subcategory_id']) { filteredProductFamily.push(element); }
          }
        } else {
          filteredProductFamily.push(element);
        }
      }
      filteredProductFamily.length > 0 ? this.filtersService.productFamilyHierarchyValues$.next( filteredProductFamily ) : this.filtersService.productFamilyHierarchyValues$.next( this.productRaw );
      this.productFamilyObject = [];
      for(let item of this.filtersService.productFamilyHierarchyValues$.value) {
        this.productFamilyObject.push( { item_id: item['product_family_id'], item_text: item['product_family_desc'] } );
      }
      this.productFamilyItems = this.filterDuplicates( this.productFamilyObject );
      if(clearFilterSelection) this.filtersService.productFamilyFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
    }
    
    filterHierarchyProductTechnical(clearFilterSelection: boolean = false): void {
      let filteredProductTechnical: any = [];
      for(let element of this.filtersService.productFamilyHierarchyValues$.value) {
        if( this.filtersService.productFamilyFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
          for( let id of this.filtersService.productFamilyFilterSelectionInfo$.value['selected_ids'] ) {
            if( id == element['product_family_id']) { filteredProductTechnical.push(element); }
          }
        } else {
          filteredProductTechnical.push(element);
        }
      }
      filteredProductTechnical.length > 0 ? this.filtersService.productTechnicalHierarchyValues$.next( filteredProductTechnical ) : this.filtersService.productTechnicalHierarchyValues$.next( this.productRaw );
      this.productTechnicalObject = [];
      for(let item of this.filtersService.productTechnicalHierarchyValues$.value) {
        this.productTechnicalObject.push( { item_id: item['product_technical_id'], item_text: item['product_technical_desc'] } );
      }
      this.productTechnicalItems = this.filterDuplicates( this.productTechnicalObject );
      if(clearFilterSelection) this.filtersService.productTechnicalFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
    }

    filterHierarchyBouquetBusiness(clearFilterSelection: boolean = false): void {
      let filteredBouquetBusiness: any = [];
      for(let element of this.filtersService.productTechnicalHierarchyValues$.value) {
        if( this.filtersService.productTechnicalFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
          for( let id of this.filtersService.productTechnicalFilterSelectionInfo$.value['selected_ids'] ) {
            if( id == element['product_technical_id']) { filteredBouquetBusiness.push(element); }
          }
        } else {
          filteredBouquetBusiness.push(element);
        }
      }
      filteredBouquetBusiness.length > 0 ? this.filtersService.bouquetBusinessHierarchyValues$.next( filteredBouquetBusiness ) : this.filtersService.bouquetBusinessHierarchyValues$.next( this.productRaw );
      this.bouquetBusinessObject = [];
      for(let item of this.filtersService.bouquetBusinessHierarchyValues$.value) {
        this.bouquetBusinessObject.push( { item_id: item['bouquet_business_id'], item_text: item['bouquet_business_desc'] } );
      }
      this.bouquetBusinessItems = this.filterDuplicates( this.bouquetBusinessObject );
      if(clearFilterSelection) this.filtersService.bouquetBusinessFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
    }

    filterHierarchyBouquetLobs(clearFilterSelection: boolean = false): void {
      let filteredBouquetLobs: any = [];
      for(let element of this.filtersService.bouquetBusinessHierarchyValues$.value) {
        if( this.filtersService.bouquetBusinessFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
          for( let id of this.filtersService.bouquetBusinessFilterSelectionInfo$.value['selected_ids'] ) {
            if( id == element['bouquet_business_id']) { filteredBouquetLobs.push(element); }
          }
        } else {
          filteredBouquetLobs.push(element);
        }
      }
      filteredBouquetLobs.length > 0 ? this.filtersService.bouquetLobsHierarchyValues$.next( filteredBouquetLobs ) : this.filtersService.bouquetLobsHierarchyValues$.next( this.productRaw );
      this.bouquetLobsObject = [];
      for(let item of this.filtersService.bouquetLobsHierarchyValues$.value) {
        this.bouquetLobsObject.push( { item_id: item['bouquet_lobs_id'], item_text: item['bouquet_lobs_desc'] } );
      }
      this.bouquetLobsItems = this.filterDuplicates( this.bouquetLobsObject );
      if(clearFilterSelection) this.filtersService.bouquetLobsFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
    }

    filterHierarchyProductMis(clearFilterSelection: boolean = false): void {
      let filteredProductMis: any = [];
      for(let element of this.filtersService.bouquetLobsHierarchyValues$.value) {
        if( this.filtersService.bouquetLobsFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
          for( let id of this.filtersService.bouquetLobsFilterSelectionInfo$.value['selected_ids'] ) {
            if( id == element['bouquet_lobs_id']) { filteredProductMis.push(element); }
          }
        } else {
          filteredProductMis.push(element);
        }
      }
      filteredProductMis.length > 0 ? this.filtersService.productMisHierarchyValues$.next( filteredProductMis ) : this.filtersService.productMisHierarchyValues$.next( this.productRaw );
      this.productMisObject = [];
      for(let item of this.filtersService.productMisHierarchyValues$.value) {
        this.productMisObject.push( { item_id: item['product_mis_id'], item_text: item['product_mis_desc'] } );
      }
      this.productMisItems = this.filterDuplicates( this.productMisObject );
      if(clearFilterSelection) this.filtersService.productMisFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
    }

    filterHierarchyProductType(clearFilterSelection: boolean = false): void {
      let filteredProductType: any = [];
      for(let element of this.filtersService.bouquetLobsHierarchyValues$.value) {
        if( this.filtersService.bouquetLobsFilterSelectionInfo$.value['selected_ids'].length > 0 ) {
          for( let id of this.filtersService.bouquetLobsFilterSelectionInfo$.value['selected_ids'] ) {
            if( id == element['bouquet_lobs_id']) { filteredProductType.push(element); }
          }
        } else {
          filteredProductType.push(element);
        }
      }
      filteredProductType.length > 0 ? this.filtersService.productTypeHierarchyValues$.next( filteredProductType ) : this.filtersService.productTypeHierarchyValues$.next( this.productRaw );
      this.productTypeObject = [];
      for(let item of this.filtersService.productTypeHierarchyValues$.value) {
        this.productTypeObject.push( { item_id: item['product_type_id'], item_text: item['product_type_desc'] } );
      }
      this.productTypeItems = this.filterDuplicates( this.productTypeObject );
      if(clearFilterSelection) this.filtersService.productTypeFilterSelectionInfo$.next( {selected_items: [], selected_ids: []} );
    }

    ngOnDestroy() {
      this.subscription.unsubscribe();
      if(this.filtersDropdownSubscription){ this.filtersDropdownSubscription.unsubscribe(); } 
      if(this.pageIdSubscription){ this.pageIdSubscription.unsubscribe(); } 
      if(this.userInfoSubscription){ this.userInfoSubscription.unsubscribe(); } 
      if(this.refreshFiltersSubscription){ this.refreshFiltersSubscription.unsubscribe(); } 
    }

}
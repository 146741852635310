import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { EventsService } from '../../services/events/events.service';

@Component({
  selector: 'app-input-box',
  templateUrl: './input-box.component.html',
  styleUrls: ['./input-box.component.scss']
})
export class InputBoxComponent implements OnInit {
  @Input() icon: string = "search";
  @Input() iconColor: string = "#819cab";
  @ViewChild('searchInput') searchInput: ElementRef;
  @HostListener('keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Enter") this.search()
  }

  constructor(
    private events: EventsService
  ) { }

  ngOnInit(): void {
  }

  search() {
    const term = this.searchInput.nativeElement.value;
    this.events.technicalInputTermChanged$.emit(term);
  }

}

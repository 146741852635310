<app-widget>
    <div class="section-header-block d-flex justify-content-between align-items-start pb-2">
        <div class="d-flex justify-content-between align-items-start w-100">
            <!-- <div class="d-flex align-items-center">
                <span class="title section-card-title">{{ 'panels.fixedStock' | translate }}</span>
            </div>   -->
            <app-fixed-stock-indicators-main class="w-100"></app-fixed-stock-indicators-main>
        </div> 
    </div>
    <app-fixed-stock-indicators-graphic></app-fixed-stock-indicators-graphic>
</app-widget>



<component-loader *ngIf="!loaded" [width]="" [height]="235"></component-loader>

<div class="mt-2 mb-3 d-flex justify-content-end align-items-center" *ngIf="loaded">
    <app-legend class="mr-2" name="labels.highSubscriptions" color="light-blue" type="round" gradient="v-gradient"></app-legend>
    <app-legend class="mr-2" name="labels.unsubscriptions" color="dark-blue" type="round" gradient="h-gradient"></app-legend>
    <app-legend class="mr-2" name="labels.reactivatedMP" color="dark-yellow" type="round" gradient="h-gradient"></app-legend>
    <app-legend class="mr-2" name="labels.stock" color="lightest-blue" type="line" gradient="h-gradient"></app-legend>
    <!-- <app-legend name="labels.tenure" color="dark-yellow" type="line" gradient="h-gradient"></app-legend> -->
</div>

<div class="ml-n3" *ngIf="loaded">
    <grouped-vertical-horizontal-graph-d-axis
        [view]="view"
        [scheme]="comboBarScheme"
        [results]="groupedVerticalGraph"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [yRightAxisTickFormatting]="yRightAxisTickFormatting"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [legend]="showLegend"
        [barPadding]=1
        [groupPadding]=6
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [legendTitle]="legendTitle"
        [lineChart]="horizontalGraph"
        [showGridLines]="showGridLines"
        [showRightGridLines]="showRightGridLines"
        [customColors]="customColors"
        [roundEdges]="roundEdges"
        [activeEntries]="activeEntries"
        [trimXAxisTicks]="trimXAxisTicks"
        [trimYAxisTicks]="trimYAxisTicks"
        [rotateXAxisTicks]="rotateXAxisTicks"
        [maxXAxisTickLength]="maxXAxisTickLength"
        [maxYAxisTickLength]="maxYAxisTickLength"
        [tooltipDisabled]="tooltipDisabled"
        [roundDomains]="roundDomains"
        [showPercentageSymbolInTooltip]="showPercentageSymbolInTooltip">
    </grouped-vertical-horizontal-graph-d-axis>
</div>
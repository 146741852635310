import { Component, OnInit } from '@angular/core';
import { LossRatioKpi } from 'src/app/shared/models/loss-ratio-kpi';
import { calculateViewDimensions, ViewDimensions } from '@swimlane/ngx-charts';
import { COLORS } from 'src/app/shared/globals/globals';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-loss-ratio-evolution',
  templateUrl: './loss-ratio-evolution.component.html',
  styleUrls: ['./loss-ratio-evolution.component.scss']
})
export class LossRatioEvolutionComponent implements OnInit {

  kpiLoaded: boolean = false;
  evolutionLoaded: boolean = false;

  kpiInfo: LossRatioKpi = {};

  percentagePrevious: string = "labels.pm";

  single: any[];

  unsubscribe$: Subject<any> = new Subject<any>();

  targetName: string = "";
  abbreviatedMonthNames: any[];

  filtersValues: any[] = [];

  // Options for the graph
  width: number = 200;
  height: number = 200;
  view: any[] = [400, 110];
  dims: ViewDimensions;
  margins: any = [0, 0, 0, 0];
  customColors: any = [];
  showXAxis: boolean = true;
  showYAxis: boolean = false;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';
  colorScheme: any = {
    domain: [COLORS.darkGreen, COLORS.lightGreen, COLORS.darkGreen]
  };
  roundDomains: boolean = true;
  showDataLabel: boolean = true;
  barPadding: number = 70;
  roundEdges: boolean = false;
  rotateXAxisTicks: boolean = false;

  labelWidth: number = 330;
  labelDiff_01: number = 0.34;
  labelDiff_02: number = 1.51;
  viewBox1: any = [26, -3, 117, 20];
  viewBox2: any = [-75, -3, 117, 20];

  graphDataToPrint: any = [];
  yearDiff: number;
  targetDiff: number;

  constructor(
    private translateService: TranslateService,
    private api: ApimstService,
    private filtersService: FiltersService
  ) { }

  ngOnInit(): void {
    this.calculateViewDimensions();

    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const target = this.translateService.get('labels.target');
          const abbreviatedMonths = this.translateService.get('abbreviatedMonthNames');
          const translations = [target, abbreviatedMonths];
      
          forkJoin(translations).pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              {
                next: results => {
                  this.targetName = results[0];
                  this.abbreviatedMonthNames = results[1];
                },
                complete: () => {
                  if (data.length !== 0) {
                    this.filtersValues = data;
                  
                    this.resetValues();
                    this.retrieveLossRatioKpi("lrr", this.filtersValues);
                    this.retrieveLossRatioKpi("lr", this.filtersValues);
                  }
                }
              }
            );
        });
  }

  retrieveLossRatioKpi(type_report: string = "", filtersValues: any[] = []): void {
    this.kpiLoaded = false;
    this.evolutionLoaded = false;

    this.api.getSinistersLossRatio(type_report, filtersValues)
      .subscribe({
        next: data => {
          switch (type_report) {
            case "lrr":
              this.createKpi(data[0]);
              this.kpiLoaded = true;
              break;
            case "lr":
              this.createEvolution(data[0]);
              this.evolutionLoaded = true;
              break;
            default:
              break;
          }
        }
      })
  }

  createKpi(kpi: any = {}): void {
    this.kpiInfo.main_value = kpi.value ? kpi.value : "0";
    this.kpiInfo.main_value_symbol = "%";
    this.kpiInfo.percentage = kpi.diff ? kpi.diff : "0";
    this.kpiInfo.trend = kpi.diff_trend ? parseInt(kpi.diff_trend) : 0;
  }
  
  createEvolution(evolution: any = {}): void {
    const filteredMonth = this.getFilteredMonth(this.filtersValues);
    const filteredYear = this.getFilteredYear(this.filtersValues).slice(2,4);
    
    this.graphDataToPrint =  [
      {
        name: `${this.abbreviatedMonthNames[filteredMonth]} ${filteredYear - 1}`, 
        value: evolution?.previous ? parseFloat(evolution.previous) : 0
      },
      {
        name: `${this.abbreviatedMonthNames[filteredMonth]} ${filteredYear}`, 
        value: evolution?.value ? parseFloat(evolution.value) : 0
      },
      {
        name: this.targetName,
        value: evolution?.target ? parseFloat(evolution.target) : 0
      }
    ]
    this.yearDiff = evolution?.diff ? parseFloat(evolution.diff) : 0;
    this.targetDiff = evolution?.target_diff ? parseFloat(evolution.target_diff) : 0;
  }

  resetValues() {
    this.kpiInfo = {};
    this.graphDataToPrint = [];
  }

  calculateViewDimensions(): void {
    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margins
    });
  }

  getFilteredMonth(filtersValues: any[]) {
    let filteredMonth = this.filtersValues.filter(f => f.name == "month")
    return filteredMonth[0].value;
  }

  getFilteredYear(filtersValues: any[]) {
    let filteredYear = this.filtersValues.filter(f => f.name == "year")
    return filteredYear[0].value;
  }

  // Method to assign the colors to the graph bars
  private assignColorBar() {
    this.customColors.push(
      {
        name: "Feb 20",
        value: COLORS.darkGreen
      },
      {
        name: "Feb 21",
        value: COLORS.lightGreen
      },
      {
        name: "Objetivo",
        value: COLORS.darkGreen
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Component, OnInit } from '@angular/core';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { ExcelFileInfo } from 'src/app/shared/models/excel-file-info';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cancellation-charge-effectiveness',
  templateUrl: './cancellation-charge-effectiveness.component.html',
  styleUrls: ['./cancellation-charge-effectiveness.component.scss']
})
export class CancellationChargeEffectivenessComponent implements OnInit {

  loaded: boolean = false;

  excelDataLoaded: ExcelFileInfo;

  // Component variables
  amount = 'panels.amount';
  receipts = 'panels.receipts';
  graphName = 'collectionEffective';

  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "cancellation-charge--cancellation-charge-effectiveness";

  unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private events: EventsService,
    private filtersService: FiltersService,
    private excelService: ExcelService,
    private i18n: I18nService,
    private atlasHelper: AtlasHelperService
  ) { }

  ngOnInit(): void {
    this.subscribeToActiveLanguage();
    this.subscribeToExcelInfo();
    this.subscribeToFiltersValues();
  }

  subscribeToExcelInfo(): void {
    this.events.chargeEffectivenessExcelInfoLoaded$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: excelDataLoaded => {
          this.loaded = false;

          if (!excelDataLoaded.json) return;

          this.excelDataLoaded = excelDataLoaded;
          this.loaded = true;

        }
      })
  }

  subscribeToFiltersValues(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({ next: filters => this.loaded = false })
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.excelDataLoaded.json, this.excelDataLoaded.headers, this.excelDataLoaded.excelFileName, this.excelDataLoaded.filtersValues);
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<div class="kpis-funnel-container" [ngClass]="{'items-1': items.length == 1, 'items-2': items.length == 2, 'items-3': items.length == 3}">
    <div class="kpis">
        <div class="kpi" *ngFor="let item of items">
            <span class="title text-white d-block">{{ item.title | translate }}</span>
            <span class="kpi-block d-inline-block" *ngIf="item.main_value != '-1'">
                <span class="kpi-text text-white">
                    <span class="kpi-text-value">{{ item.main_value }}</span>
                </span>
                <span class="kpi-trend ml-2" [ngClass]="{'trend-success': item.trend == 1, 'trend-danger': item.trend == 0}">
                    <span class="material-icons trend-icon" [ngClass]="{'trend-up': item.trend_direction == 1, 'trend-down': item.trend_direction == 0}">
                        play_arrow
                    </span>
                    <span class="kpi-text-second-value">{{ item.second_value_prefix | translate }} {{ item.second_value }}</span>
                </span>
            </span>
        </div>
    </div>
    <div class="kpis-funnel-bg"></div>
</div>

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-indicator-widget',
  templateUrl: './indicator-widget.component.html',
  styleUrls: ['./indicator-widget.component.scss']
})
export class IndicatorWidgetComponent implements OnInit {

  @Input() titleLeft;
  @Input() titleCenter;
  @Input() titleRight;
  @Input() mainValueLeft;
  @Input() mainValueCenter;
  @Input() mainValueRight;
  @Input() mainValueUnitLeft;
  @Input() mainValueUnitCenter;
  @Input() mainValueUnitRight;
  @Input() percentagePrevious;
  @Input() percentageValueLeft;
  @Input() percentageValueCenter;
  @Input() percentageValueRight;
  @Input() trendLeft;
  @Input() trendCenter;
  @Input() trendRight;

  // Default Constructor
  constructor() { }

  // OnInit
  ngOnInit(): void {
  }

}

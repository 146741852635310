import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highmaps';
import argentinaMap from "@highcharts/map-collection/countries/ar/ar-all.geo.json";
import brazilMap from "@highcharts/map-collection/countries/br/br-all.geo.json";
import chileMap from "@highcharts/map-collection/countries/cl/cl-all.geo.json";
import mexicoMap from "@highcharts/map-collection/countries/mx/mx-all.geo.json";
import uruguayMap from "@highcharts/map-collection/countries/uy/uy-all.geo.json";
import { COLORS } from 'src/app/shared/globals/globals';
import { MapService } from 'src/app/shared/services/map/map.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-operational-loss-ratio',
  templateUrl: './operational-loss-ratio.component.html',
  styleUrls: ['./operational-loss-ratio.component.scss']
})
export class OperationalLossRatioComponent implements OnInit {

  loaded: boolean = false;

  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = "mapChart";
  chartOptions: Highcharts.Options;
  countryMap: any;
  mapSeries: any[] = [];
  valueSuffix: string = "";
  // chartData = [{ code3: "ABW", z: 105 }, { code3: "AFG", z: 35530 }];

  filtersValues: any[] = [];
  countryFilter: string = "";

  unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private mapService: MapService,
    private filtersService: FiltersService
  ) { }

  ngOnInit(): void {

    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data.length !== 0) {
            let zones: any[] = [];
            this.filtersValues = data;

            this.loaded = false;
            setTimeout(() => {
              this.countryFilter = this.getUserSelectedCountry();


              // console.log("this.countryFilter")
              // console.log(typeof this.countryFilter)

              zones = this.mapService.getCountryMapZones(this.countryFilter);
  
              this.getMapByCountryCode(this.countryFilter);
              this.setMapZoneValues(zones);
              this.setValuesSuffix("%");
              this.setchartOptions();
              this.loaded = true;
            }, 500);

          }
        }
      );
  }

  getUserSelectedCountry() {
    let country = this.filtersValues.filter(c => c.name == "country")[0]?.value[0];
    if(typeof country == "undefined") {
      country = this.filtersService.selectedIndexCountry$.value;
    }
    return country;
  }

  getMapByCountryCode(countryCode: string = "") {
    let map: any;
    switch (countryCode) {
      case 'ARG': map = argentinaMap; break;
      case 'BRA': map = brazilMap; break;
      case 'CHL': map = chileMap; break;
      case 'MEX': map = mexicoMap; break;
      case 'URY': map = uruguayMap; break;
    }
    this.countryMap = map;
    return map;
  }

  setMapZoneValues(zones: any[] = []) {
    this.mapSeries = [];
    for (let zone of zones) {
      let item: any[] = [];
      item.push(zone);
      item.push((Math.random() * 100).toFixed(2));
      this.mapSeries.push(item);
    }
  }

  setValuesSuffix(unit: string = "") {
    this.valueSuffix = unit;
  }

  setchartOptions() {
    this.chartOptions = {
      colorAxis: {
        min: 0,
        max: 100,
        // type: 'linear',
        // minColor: '#efecf3',
        // maxColor: '#990041',
      },
      chart: {
        map: this.countryMap,
        events:{
          render:function(){
            this.reflow()
          }
        }
      },
      title: {
        text: ""
      },
      subtitle: {
        text:
          ''
      },
      mapNavigation: {
        enabled: false,
        buttonOptions: {
          alignTo: "spacingBox"
        }
      },
      legend: {
        enabled: true
      },
      tooltip: {
        valueSuffix: this.valueSuffix
      },
      credits: {
        enabled: false
      },
      series: [
        {
          type: "map",
          name: "",
          states: {
            hover: {
              color: COLORS.darkBlue
            }
          },
          dataLabels: {
            enabled: true,
            format: "{point.name}"
          },
          allAreas: false,
          data: this.mapSeries
        }
      ]
    };

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

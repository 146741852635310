import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rejections-loss-ratio',
  templateUrl: './rejections-loss-ratio.component.html',
  styleUrls: ['./rejections-loss-ratio.component.scss']
})
export class RejectionsLossRatioComponent implements OnInit {

  pageName: string;
  pages = [
    {name: 'lr-evolution'}, 
    {name: 'lr-line-graph'}, 
    {name: 'lr-table'}
  ]

  constructor() { }

  ngOnInit(): void {
  }

  getSection(event): void {
    this.pageName = event.name;
  }

}

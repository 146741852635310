import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs'
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { COLORS } from 'src/app/shared/globals/globals';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { calculateViewDimensions, ViewDimensions } from '@swimlane/ngx-charts';
import { CancellationChurnRate } from 'src/app/shared/models/cancellation-churn-rate';
import { DecimalThousandPipe } from 'src/app/shared/pipes/decimal-thousand.pipe';


@Component({
  selector: 'app-churn-rate-evolution-graph',
  templateUrl: './churn-rate-evolution-graph.component.html',
  styleUrls: ['./churn-rate-evolution-graph.component.scss']
})
export class ChurnRateEvolutionGraphComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  month: number;
  year: number;

  unsubscribe$: Subject<any> = new Subject<any>();
  monthNames: any;
  planName: string;
  voluntaryCancellationsName: string;
  notChargesName: string;
  notRenovationsName: string;
  othersName: string;

  filtersValues: any;
  optionTabBonus: string = 'panels.bonus';
  optionTabPolicy: string = 'panels.policy';
  optionTabDiscreet: string = 'panels.discreet';
  optionTabAccumulated: string = 'panels.accumulated';
  bonusPolicySelectedOption: string = 'panels.bonus';
  discreetAccumulatedSelectedOption: string = 'panels.discreet';
  graphName: string = 'cancellationChurnRate';

  evolutionGraph: any = [];
  planGraph: any = [];
  planGraphUpperLabels: any;
  planGraphLoaded: boolean = false;
  evolutionGraphLoaded: boolean = false;

  planGraphPremiumDiscreet: any = [];
  planGraphPremiumAccumulated: any = [];
  planGraphCertificateDiscreet: any = [];
  planGraphCertificateAccumulated: any = [];

  evolutionGraphPremiumDiscreet: any = [];
  evolutionGraphPremiumAccumulated: any = [];
  evolutionGraphCertificateDiscreet: any = [];
  evolutionGraphCertificateAccumulated: any = [];

  planGraphUpperLabelPremiumDiscreet: any;
  planGraphUpperLabelPremiumAccumulated: any;
  planGraphUpperLabelCertificateDiscreet: any;
  planGraphUpperLabelCertificateAccumulated: any;

  // Plan Graph Options
  planGraphWidth: number = 200;
  planGraphHeight: number = 200;
  planGraphView: any[] = [235, 120];
  planGraphDims: ViewDimensions;
  planGraphMargins: any = [0, 0, 0, 0];
  planGraphCustomColors: any = [];
  planGraphShowXAxis: boolean = true;
  planGraphShowYAxis: boolean = false;
  planGraphGradient: boolean = false;
  planGraphShowLegend: boolean = false;
  planGraphShowXAxisLabel: boolean = false;
  planGraphXAxisLabel: string = '';
  planGraphShowYAxisLabel: boolean = false;
  planGraphYAxisLabel: string = '';
  planGraphColorScheme: any = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  planGraphRoundDomains: boolean = true;
  planGraphShowDataLabel: boolean = true;
  planGraphBarPadding: number = 30;
  planGraphRoundEdges: boolean = false;
  planGraphRotateXAxisTicks: boolean = false;

  planGraphUpperLabelWidth: number = 235;
  planGraphViewBox1: any = [37, -3, 50, 20];
  planGraphViewBox2: any = [-18, -3, 100, 20];
  planGraphViewBox3: any = [0, -3, 50, 20];


  // Evolution Graph options
  view: any[] = [350, 145];
  schemeType: string = 'ordinal';
  gradient: boolean = true;
  showXAxis: boolean = true;
  showYAxis: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  showYAxisLabel: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  xAxisTicks: any;
  animations: boolean = false;
  showDataLabel: boolean = true;
  dataLabelFormatting: any;
  noBarWhenZero: boolean = false;
  rotateXAxisTicks: boolean = false;
  colorScheme = {
    domain: [COLORS.darkGreen, COLORS.walkRed, COLORS.walkGreen, COLORS.lightGreen]
  };
  customColors: any = [];

  viewBox: any = [0, 0, 300, 6];
  textLabel: string = 'labels.incrementalRate';

  constructor(
    private eventsService: EventsService,
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private translateService: TranslateService,
    private decimalThousandFormat: DecimalThousandPipe
  ) { }

  ngOnInit(): void {

    this.planGraphDims = calculateViewDimensions({
      width: this.planGraphWidth,
      height: this.planGraphHeight,
      margins: this.planGraphMargins
    });

    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {

          const months = this.translateService.get('abbreviatedMonthNames')
          const plan = this.translateService.get('labels.plan');
          const cancellations = this.translateService.get('panels.voluntaryCancellations');
          const charges = this.translateService.get('panels.notCharge');
          const renovations = this.translateService.get('panels.notRenewal');
          const others = this.translateService.get('labels.others');
      
          forkJoin([months, plan, cancellations, charges, renovations, others])
            .subscribe(
              {
                next: results => {
                  this.monthNames = results[0];
                  this.planName = results[1];
                  this.voluntaryCancellationsName = results[2];
                  this.notChargesName = results[3];
                  this.notRenovationsName = results[4];
                  this.othersName = results[5];
                },
                complete: () => {
                  if (data.length !== 0) {
                    this.filtersValues = data;
                    this.month = this.filtersValues.filter(d => d.name === 'month')[0].value;
                    this.year = this.filtersValues.filter(d => d.name === 'year')[0].value;
                    this.resetVariables();
                    this.selectedData();
                  }
                }
              }
            );
        }
      );

    this.eventsService.policiesBonusOption$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        const option = data[0];
        const name = data[1];
        if (name === this.graphName) {
          if (option === this.optionTabBonus || option === this.optionTabPolicy) {
            this.bonusPolicySelectedOption = option;
          } else if (option === this.optionTabDiscreet || option === this.optionTabAccumulated) {
            this.discreetAccumulatedSelectedOption = option;
          }
          this.selectedData();
        }
        
      }
    );
  }

  // Method to retrieve the evolution churn rate data
  private retrieveEvolutionChurnRate(type_set: string, type_data: string, filtersValues: any) {

    this.planGraphLoaded = false;
    this.evolutionGraphLoaded = false;

    this.apimstService.getCancellationChurnRate(type_set, type_data, filtersValues).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (data: CancellationChurnRate) => {
        const cancellationChurnRate = data;
        this.fillPlanGraphData(type_set, type_data, cancellationChurnRate);
        this.fillEvolutionGraphData(type_set, type_data, cancellationChurnRate);
        this.assignColorBar();
        this.assignDataToPrint();
      },

      error => console.log("An error occurred while retrieving cancellation churn plan graph data " + error)  

    );

  }

  // Method to fill plan graph data
  private fillPlanGraphData(type_set: string, type_data: string, data: any) {
    let planGraph: any = [];
    let planGraphUpperLabels: any;
    planGraph.push({
      name: this.monthNames[this.month] + ' ' + (this.year-1),
      value: data.churn_previous ? parseFloat(data.churn_previous) : 0
    });
    planGraph.push({
      name: this.monthNames[this.month] + ' ' + this.year,
      value: data.churn ? parseFloat(data.churn) : 0
    });
    planGraph.push({
      name: this.planName + ' ' + this.monthNames[this.month],
      value: data.target ? parseFloat(data.target) : 0
    });
    planGraph.push({
      name: this.planName + ' ' + this.year,
      value: data.avg_target_year ? parseFloat(data.avg_target_year) : 0
    });

    planGraphUpperLabels =
      {
        previousYearDiffLabel: data.diff_churn_previous ? data.diff_churn_previous : 0,
        previousYearDiffNumber: data.diff_churn_previous ? parseFloat(data.diff_churn_previous) : 0,
        planDiffLabel: data.target_diff ? parseFloat(data.target_diff) : 0,
        avgPlanDiffLabel: data.target_plan_diff ? parseFloat(data.target_plan_diff) : 0
      };

    this.assignPlanGraphDataAccordingToOption(type_set, type_data, planGraph, planGraphUpperLabels);
  }

  // Method to assign data according to Premium/Certificate - Discreet/Accumulated selection
  private assignPlanGraphDataAccordingToOption(type_set: string, type_data: string, data: any, planGraphUpperLabels: any) {
    if (type_set === 'd' && type_data === 'p') {
      this.planGraphPremiumDiscreet = data;
      this.planGraphUpperLabelPremiumDiscreet = planGraphUpperLabels;
    } else if (type_set === 'd' && type_data === 'c') {
      this.planGraphCertificateDiscreet = data;
      this.planGraphUpperLabelCertificateDiscreet = planGraphUpperLabels;
    } else if (type_set === 'a' && type_data === 'p') {
      this.planGraphPremiumAccumulated = data;
      this.planGraphUpperLabelPremiumAccumulated = planGraphUpperLabels;
    } else if (type_set === 'a' && type_data === 'c') {
      this.planGraphCertificateAccumulated = data;
      this.planGraphUpperLabelCertificateAccumulated = planGraphUpperLabels;
    }
    this.planGraph = data;
  } 

  // Method to fill evolution graph data
  private fillEvolutionGraphData(type_set: string, type_data: string, data: any) {
    let evolutionGraph: any = [];
    evolutionGraph.push({
      name: this.monthNames[this.month] + ' ' + (this.year-1),
      value: data.churn_previous ? parseFloat(data.churn_previous) : 0
    });
    
    evolutionGraph.push({
      name: this.voluntaryCancellationsName,
      value: data.churn_cancellation ? parseFloat(data.churn_cancellation) : 0
    });
    evolutionGraph.push({
      name: this.notChargesName,
      value: data.churn_not_charge ? parseFloat(data.churn_not_charge) : 0
    });
    evolutionGraph.push({
      name: this.notRenovationsName,
      value: data.diff_not_renovation ? parseFloat(data.diff_not_renovation) : 0
    });
    evolutionGraph.push({
      name: this.othersName,
      value: data.others ? parseFloat(data.others) : 0
    });

    evolutionGraph.push({
      name: this.monthNames[this.month] + ' ' + this.year,
      value: data.churn ? parseFloat(data.churn) : 0
    });

    this.assignEvolutionGraphDataAccordingToOption(type_set, type_data, evolutionGraph);
  }

  // Method to assign data according to Premium/Certificate - Discreet/Accumulated selection
  private assignEvolutionGraphDataAccordingToOption(type_set: string, type_data: string, data: any) {
    if (type_set === 'd' && type_data === 'p') {
      this.evolutionGraphPremiumDiscreet = data;
    } else if (type_set === 'd' && type_data === 'c') {
      this.evolutionGraphCertificateDiscreet = data;
    } else if (type_set === 'a' && type_data === 'p') {
      this.evolutionGraphPremiumAccumulated = data;
    } else if (type_set === 'a' && type_data === 'c') {
      this.evolutionGraphCertificateAccumulated = data;
    }
    
  }

  // Method to assign the data to the tables
  private assignDataToPrint() {
    if (this.bonusPolicySelectedOption === this.optionTabPolicy && this.discreetAccumulatedSelectedOption === this.optionTabDiscreet) {
      this.evolutionGraph = this.evolutionGraphCertificateDiscreet;
      this.planGraph = this.planGraphCertificateDiscreet;
      this.planGraphLoaded = true;
      this.planGraphUpperLabels = this.planGraphUpperLabelCertificateDiscreet;
      this.evolutionGraphLoaded = true;
    } else if (this.bonusPolicySelectedOption === this.optionTabPolicy && this.discreetAccumulatedSelectedOption === this.optionTabAccumulated) {
      this.evolutionGraph = this.evolutionGraphCertificateAccumulated;
      this.planGraph = this.planGraphCertificateAccumulated;
      this.planGraphUpperLabels = this.planGraphUpperLabelCertificateAccumulated;
      this.planGraphLoaded = true;
      this.evolutionGraphLoaded = true;
    } else if (this.bonusPolicySelectedOption === this.optionTabBonus && this.discreetAccumulatedSelectedOption === this.optionTabDiscreet) {
      this.evolutionGraph = this.evolutionGraphPremiumDiscreet;
      this.planGraph = this.planGraphPremiumDiscreet;
      this.planGraphUpperLabels = this.planGraphUpperLabelPremiumDiscreet;
      this.planGraphLoaded = true;
      this.evolutionGraphLoaded = true;
    } else if (this.bonusPolicySelectedOption === this.optionTabBonus && this.discreetAccumulatedSelectedOption === this.optionTabAccumulated) {
      this.evolutionGraph = this.evolutionGraphPremiumAccumulated;
      this.planGraph = this.planGraphPremiumAccumulated;
      this.planGraphUpperLabels = this.planGraphUpperLabelPremiumAccumulated;
      this.planGraphLoaded = true;
      this.evolutionGraphLoaded = true;
    }
  }

  // Method to print the selected data
  private selectedData() {
    if (this.bonusPolicySelectedOption === this.optionTabBonus && this.discreetAccumulatedSelectedOption === this.optionTabDiscreet && this.evolutionGraphPremiumDiscreet.length === 0 && this.planGraphPremiumDiscreet.length === 0) {
      this.retrieveEvolutionChurnRate('d', 'p', this.filtersValues);
    } else if (this.bonusPolicySelectedOption === this.optionTabPolicy && this.discreetAccumulatedSelectedOption === this.optionTabDiscreet && this.evolutionGraphCertificateDiscreet.length === 0 && this.planGraphCertificateDiscreet.length === 0) {
      this.retrieveEvolutionChurnRate('d', 'c', this.filtersValues);  
    } else if (this.bonusPolicySelectedOption === this.optionTabPolicy && this.discreetAccumulatedSelectedOption === this.optionTabAccumulated && this.evolutionGraphCertificateAccumulated.length === 0 && this.planGraphCertificateAccumulated.length === 0) {
      this.retrieveEvolutionChurnRate('a', 'c', this.filtersValues);
    } else if (this.bonusPolicySelectedOption === this.optionTabBonus && this.discreetAccumulatedSelectedOption === this.optionTabAccumulated && this.evolutionGraphPremiumAccumulated.length === 0 && this.planGraphPremiumAccumulated.length === 0) {
      this.retrieveEvolutionChurnRate('a', 'p', this.filtersValues);
    } else {
      this.assignDataToPrint();
    }
  }

  // Method to reset data variables
  private resetVariables() {
    this.planGraphPremiumDiscreet = [];
    this.planGraphPremiumAccumulated = [];
    this.planGraphCertificateDiscreet = [];
    this.planGraphCertificateAccumulated = [];

    this.evolutionGraphPremiumDiscreet = [];
    this.evolutionGraphPremiumAccumulated = [];
    this.evolutionGraphCertificateDiscreet = [];
    this.evolutionGraphCertificateAccumulated = [];

    this.planGraphUpperLabelPremiumDiscreet = [];
    this.planGraphUpperLabelPremiumAccumulated = [];
    this.planGraphUpperLabelCertificateDiscreet = [];
    this.planGraphUpperLabelCertificateAccumulated = [];
  }

  // Method to assign the colors to the graph bars
  private assignColorBar() {
    this.customColors = [];
    this.customColors.push(
      {
        name: this.monthNames[this.month] + ' ' + (this.year - 1),
        value: COLORS.darkGreen
      },
      {
        name: this.monthNames[this.month] + ' ' + (this.year),
        value: COLORS.lightGreen
      },
      {
        name: this.planName + ' ' + this.monthNames[this.month],
        value: '#a0c2d5'
      },
      {
        name: this.planName + ' ' + this.year,
        value: '#ff4394'
      })
  }

  formatDataLabel(value) {
    return Intl.NumberFormat("de-DE").format(parseFloat(value)) + '%';
  }

  formatDataLabelString(value) {
    return value + '%';
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<div class="detail-container">

    <!-- HTML Editor - Page description -->
    <app-data-governance-info-page *ngIf="selectedSection === 'description'" [titleLabel]="sectionInformation.name" [descriptionText]="sectionInformation.description_html" [descriptionImage]="sectionInformation.description_image" [guid]="sectionInformation.guid" [language]="sectionInformation.language" ></app-data-governance-info-page>

    <!-- Filters information -->
    <div *ngIf="selectedSection === 'filters'">
        <div class="section-header-block pb-4">
            <span class="title section-card-title dg-gray-color">{{ 'filtersPanel.filters' | translate }}</span>
            <div class="filters-info mt-4">
                <app-dg-info-card *ngFor="let filter of filtersInfo" [cardInfo]="filter" [infoType]="selectedSection"></app-dg-info-card>
            </div>
        </div>
    </div>

    <!-- Business catalog information -->
    <div *ngIf="selectedSection === 'businessCatalogue'">
        <div class="section-header-block pb-4">
            <span class="title section-card-title dg-gray-color">{{ 'labels.businessCatalogue' | translate }}</span>
            <div class="filters-info mt-4">
                <app-dg-info-card *ngFor="let catalogue of businessCatalogueInfo" [cardInfo]="catalogue"></app-dg-info-card>
            </div>
        </div>
    </div>

    <!-- Technical catalog information -->
    <div *ngIf="selectedSection === 'technicalCatalogue'">
        <div class="section-header-block pb-4">
            <span class="title section-card-title dg-gray-color">{{ 'labels.technicalCatalogue' | translate }}</span>
            <div class="filters-info mt-4">
                <app-dg-info-card *ngFor="let catalogue of technicalCatalogueInfo" [cardInfo]="catalogue"></app-dg-info-card>
            </div>
        </div>
    </div>

    <!-- Technical information -->
    <div *ngIf="selectedSection === 'technical'">
        <div class="section-header-block pb-4">
            <span class="title section-card-title dg-gray-color">{{ 'labels.technical' | translate }}</span>
            <div class="filters-info mt-4">
                <app-dg-info-card *ngFor="let info of technicalInfo" [cardInfo]="info"></app-dg-info-card>
            </div>
        </div>
    </div>
    
</div>

<app-wrapper>

    <div class="wrapper_grid">
        <div class="sinister-rejections">
            <app-rejections-main></app-rejections-main>
        </div>
        <div class="sinister-pending-age">
            <app-rejections-pending-age></app-rejections-pending-age>
        </div>
        <div class="sinister-rejections-frequency-evolution">
            <app-rejections-frequency-evolution></app-rejections-frequency-evolution>
        </div>
        <div class="sinister-time-processes">
            <app-rejections-time-processes></app-rejections-time-processes>
        </div>
    </div>

</app-wrapper>
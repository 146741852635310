<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex">
            <div class="d-flex align-items-center">
                <span class="title section-card-title">{{ 'panels.fixedStockPersistenceTotal' | translate }}</span>
                <app-btn-gobernance-info 
                    class="ml-2" 
                    [endpoint]="endpoint" 
                    [endpointParams]="endpointParams"
                    *ngIf="hasGobernanceInfo">
                </app-btn-gobernance-info>
            </div>
            <mat-icon class="excel-icon ml-2" svgIcon="excel" (click)="exportAsXLSX()" *ngIf="loaded"></mat-icon>
        </div>
        <div class="dropdown-container dropdown-light">
            <app-dropdown-multiple
            [title]="title"
            [placeholder]="placeholderName"
            [dropdownList]="dateOptions"
            [selectedItems]="selectedDateOptions"
            [enableCheckAll]="enableCheckAll"
            [limitSelection]="limitSelection"
            formName="fixedStockPersistance">
            </app-dropdown-multiple>
        </div>
    </div>

    <div class="mt-1">
        <app-fixed-stock-persistence-graphic></app-fixed-stock-persistence-graphic>
    </div>

</app-widget>

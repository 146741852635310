<span class="title-block d-flex align-items-center">
    <span class="title" *ngIf="item.title">{{ item.title | translate }}</span>
    <span class="icon material-icons ml-1" [routerLink]="['/someUrl', item.link]" *ngIf="item.link">open_in_new</span>
</span>
<span class="subtitle d-block description" *ngIf="item.firstDescription">{{ item.firstDescription }}</span>
<span class="subtitle d-block" *ngIf="item.relationships">
    <span class="d-flex" *ngFor="let relationship of item.relationships">
        <span class="icon material-icons ml-1 mr-2 " (click)="goToTechicalInfo(relationship.id)">open_in_new</span>
        <span>{{ relationship.name }}</span>
    </span>
    
</span>
<div class ="horizontal-funnel-graph">
    <div class="funnel">
        <div class="svg-funnel-js__labels">
            <div *ngFor="let label of labels; let _i = index" [className]="'svg-funnel-js__label label-' + (_i + 1)">
                <div class="label__container">
                    <label class="main-checkbox d-block">
                        <input id="labelCheckbox" type="checkbox" name="labelCheckbox" [value]='_i' (click)="selectSinisterState(_i)" [checked]="_i === 0 ? true : false">
                        <span class="checkmark"></span>
                        <div class="label__value">
                            <span>{{label}}</span>
                            <p class="total__value">{{operationSymbol[_i]}} {{pendingValues[_i] | magnitudeOrder}} <span *ngIf="_i < 7" class="increased__value ml-2">{{stateValues[_i] | magnitudeOrder}}</span></p>
                            <p class="subtotal__value">{{totalNumberSinisterPerState[_i]}}</p>
                        </div>
                    </label>
                    <div class="label__segment-percentages">
                        <ul class="segment-percentage__list">
                            <li *ngFor="let item of bouquetsLabels; let _j = index">
                                <span class="percentage__list-color" [style.background]="colors[_j][0]"></span>
                                <span class="percentage__list-label">{{item}}: {{values[_i][_j] | magnitudeOrder}} ({{calculateTooltipPercentage(values[_i][_j], stateValues[_i]) | magnitudeOrder}}%)</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="svg-funnel-js__label-bottom-line">
                <svg height="10" [attr.width]=lineWidth>
                    <line [attr.x1]="0" y1="0" [attr.x2]=lineLowerLimit y2="0" style="stroke:#adb5bd66;stroke-width:2" />
                    <line [attr.x1]=lineUpperLimit y1="0" [attr.x2]=lineWidth y2="0" style="stroke:#adb5bd66;stroke-width:2" />
                    <line [attr.x1]=lineLowerLimit y1="0" [attr.x2]=lineLabelCenter y2="6" style="stroke:#adb5bd66;stroke-width:2" />
                    <line [attr.x1]=lineLabelCenter y1="6" [attr.x2]=lineUpperLimit y2="0" style="stroke:#adb5bd66;stroke-width:2" />
                </svg>
            </div>
        </div>
        <div class="svg-funnel-js__subLabels">
            <div *ngFor="let substate of substateData; let _i = index" [className]="'svg-funnel-js__subLabel subLabel-' + (_i + 1)">
                <div class="label__container">
                    <label class="main-checkbox d-block">
                        <input id="subLabelCheckbox" type="checkbox" name="subLabelCheckbox" [value]='_i' (click)="selectSinisterSubState(_i, substate.id, substate.state_id)"  [checked]="_i === 0 ? true : false">
                        <span class="checkmark"></span>
                        <div class="label__value">
                            <span>{{substate.label}}</span>
                            <p class="total__value">{{substate.value | magnitudeOrder}}{{substate.subcategory_unit}}
                                <span 
                                    class="material-icons" 
                                    [ngClass]="{'horizontal_funnel_graph-arrow-green': substate.diff_trend > 0, 'horizontal_funnel_graph-arrow-red': substate.diff_trend === 0, 'horizontal_funnel_graph-up': substate.diff_direction === 1, 'horizontal_funnel_graph-down': substate.diff_direction === 0}">
                                    play_arrow
                                </span>
                                <span class="horizontal_funnel_graph-value" [ngClass]="{'horizontal_funnel_graph-value-green': substate.diff_trend > 0, 'horizontal_funnel_graph-value-red': substate.diff_trend === 0}">{{substate.diff | decimalThousandFormat}}%</span>
                            </p>
                        </div>
                    </label>
                </div>
            </div>
            <div class="svg-funnel-js__subLabel-bottom-line">
                <svg height="10" [attr.width]=lineWidth>
                    <line [attr.x1]="0" y1="0" [attr.x2]=lineLowerLimit y2="0" style="stroke:#adb5bd66;stroke-width:2" />
                    <line [attr.x1]=lineUpperLimit y1="0" [attr.x2]=lineWidth y2="0" style="stroke:#adb5bd66;stroke-width:2" />
                    <line [attr.x1]=lineLowerLimit y1="0" [attr.x2]=lineLabelCenter y2="6" style="stroke:#adb5bd66;stroke-width:2" />
                    <line [attr.x1]=lineLabelCenter y1="6" [attr.x2]=lineUpperLimit y2="0" style="stroke:#adb5bd66;stroke-width:2" />
                </svg>
            </div>
        </div>
    </div>
</div>
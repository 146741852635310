<div class="data-discovery-container">
    <app-dg-header [headerTitle]="headerTitle" [routerLink]="routerLink" [hasSearchButton]="false"></app-dg-header>

    <div class="justify-content-end row mr-5 mt-4">
        <div class="button-container">
            <button type="button" class="btn btn-block d-flex add-button pr-4 py-1" (click)="newDossierDialog()"><mat-icon class="add-icon">add</mat-icon>{{ createNewLabel | translate }}</button>
        </div>
    </div>
    
    <div *ngIf="loaded" class="cards-container">
        <app-data-discovery-card *ngFor="let item of folders" [folder]="item"></app-data-discovery-card>
    </div>

    <div class="background"></div>

    <div class="app-loader" *ngIf="!loaded">
        <base-loader></base-loader>
    </div>
</div>

export var multi = [
    {
        "name": "Vivienda",
        "series": [
            {
                "name": "Jul19",
                "value": 5
            },
            {
                "name": "Ago19",
                "value": 4
            },
            {
                "name": "Sep19",
                "value": 3.5
            },
            {
                "name": "Oct19",
                "value": 4.25
            },
            {
                "name": "Nov19",
                "value": 3.25
            },
            {
                "name": "Dic19",
                "value": 1
            },
            {
                "name": "Ene20",
                "value": 3.46
            },
            {
                "name": "Feb20",
                "value": 4.25
            },
            {
                "name": "Mar20",
                "value": 2.45
            },
            {
                "name": "Abr20",
                "value": 4.45
            },
            {
                "name": "May20",
                "value": 3.12
            },
            {
                "name": "Jun20",
                "value": 2.13
            },
            {
                "name": "Ago20",
                "value": 4.12
            },
        ]
    },
    {
        "name": "Vida",
        "series": [
            {
                "name": "Jul19",
                "value": 3.25
            },
            {
                "name": "Ago19",
                "value": 4.21
            },
            {
                "name": "Sep19",
                "value": 2.5
            },
            {
                "name": "Oct19",
                "value": 3.25
            },
            {
                "name": "Nov19",
                "value": 5
            },
            {
                "name": "Dic19",
                "value": 3
            },
            {
                "name": "Ene20",
                "value": 3
            },
            {
                "name": "Feb20",
                "value": 2.25
            },
            {
                "name": "Mar20",
                "value": 1.45
            },
            {
                "name": "Abr20",
                "value": 3.45
            },
            {
                "name": "May20",
                "value": 4.12
            },
            {
                "name": "Jun20",
                "value": 5
            },
            {
                "name": "Ago20",
                "value": 2.35
            },
        ]
    },
    {
        "name": "Hogar",
        "series": [
            {
                "name": "Jul19",
                "value": 2.25
            },
            {
                "name": "Ago19",
                "value": 1.21
            },
            {
                "name": "Sep19",
                "value": 3.25
            },
            {
                "name": "Oct19",
                "value": 2.15
            },
            {
                "name": "Nov19",
                "value": 2
            },
            {
                "name": "Dic19",
                "value": 5
            },
            {
                "name": "Ene20",
                "value": 1
            },
            {
                "name": "Feb20",
                "value": 4.25
            },
            {
                "name": "Mar20",
                "value": 2.45
            },
            {
                "name": "Abr20",
                "value": 3.45
            },
            {
                "name": "May20",
                "value": 2.12
            },
            {
                "name": "Jun20",
                "value": 4.58
            },
            {
                "name": "Ago20",
                "value": 4.35
            },
        ]
    },
    {
        "name": "Robo",
        "series": [
            {
                "name": "Jul19",
                "value": 3.15
            },
            {
                "name": "Ago19",
                "value": 2.21
            },
            {
                "name": "Sep19",
                "value": 2.15
            },
            {
                "name": "Oct19",
                "value": 3.5
            },
            {
                "name": "Nov19",
                "value": 3.54
            },
            {
                "name": "Dic19",
                "value": 4.36
            },
            {
                "name": "Ene20",
                "value": 5.21
            },
            {
                "name": "Feb20",
                "value": 3.56
            },
            {
                "name": "Mar20",
                "value": 4.25
            },
            {
                "name": "Abr20",
                "value": 2.35
            },
            {
                "name": "May20",
                "value": 2.45
            },
            {
                "name": "Jun20",
                "value": 4.1
            },
            {
                "name": "Ago20",
                "value": 2.23
            },
        ]
    },
    {
        "name": "Otros",
        "series": [
            {
                "name": "Jul19",
                "value": 3.15
            },
            {
                "name": "Ago19",
                "value": 1.21
            },
            {
                "name": "Sep19",
                "value": 3.15
            },
            {
                "name": "Oct19",
                "value": 1.5
            },
            {
                "name": "Nov19",
                "value": 2.54
            },
            {
                "name": "Dic19",
                "value": 3.36
            },
            {
                "name": "Ene20",
                "value": 2.21
            },
            {
                "name": "Feb20",
                "value": 2.56
            },
            {
                "name": "Mar20",
                "value": 2.5
            },
            {
                "name": "Abr20",
                "value": 3.35
            },
            {
                "name": "May20",
                "value": 1.45
            },
            {
                "name": "Jun20",
                "value": 3.1
            },
            {
                "name": "Ago20",
                "value": 3.23
            },
        ]
    },
];

<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex">
            <span class="title section-card-title">{{ 'panels.cancellationReasons' | translate }}</span>
            <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams"
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </div>
        <div class="dropdown">
            <div class="filter">
                <app-dropdown-multiple
                    [title]="title"
                    placeholder="-"
                    [dropdownList]="cancellationReasonsItems"
                    [selectedItems]="selectedCancellationReasons"
                    formName="cancellationReasons">
              </app-dropdown-multiple>
              </div>
        </div>
        <app-option-tab [leftOption] = bonus [rightOption] = policy [graphName] = graphName class = "option-tab align-items-center"></app-option-tab>
    </div>

    <div class="mt-2 d-flex flex-wrap justify-content-end align-items-center">
        <app-legend class="mr-2 mb-1" name="labels.involuntary" color="dark-blue" type="round" gradient="v-gradient"></app-legend>
        <app-legend class="mr-2 mb-1" name="labels.voluntary" color="light-blue" type="round" gradient="h-gradient"></app-legend>
        <app-legend class="mr-2 mb-1" name="labels.companyDecision" color="dark-yellow" type="round" gradient="h-gradient"></app-legend>
        <app-legend class="mr-2 mb-1" name="labels.plan"  color="lightest-blue" type="line" gradient="h-gradient"></app-legend>
        <app-legend class="mr-2 mb-1" name="labels.accumulatedChurnRate" color="light-orange" type="line" gradient="h-gradient"></app-legend>
        <app-legend name="labels.churnRate" color="light-blue" type="line" gradient="h-gradient"></app-legend>
    </div>

    <div class="mt-2 ">
        <app-cancellation-reasons-graphic></app-cancellation-reasons-graphic>
    </div>

</app-widget>
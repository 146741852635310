<div class="dialog">
    <div class="wrapper_grid">
        <app-widget>
            <div class="section-header">
                <p class="section-title">
                    {{ sectionTitle | translate }}
                    <span class="material-icons ml-2 fullscreen-icon" [mat-dialog-close]="true" >fullscreen_exit</span>
                </p>
                <fancy-selector [ngClass]="{'country-disabled': countries.length <= 1}" [title]="countryName"
                    [inactiveIcon]="inactiveIcon" [activeIcon]="activeIcon" [isActive]="isActive" [menuItems]="countries"
                    (select)="selectCountry($event)">
                </fancy-selector>
            </div>
            <div class = "mt-0 d-block"> 
                <div class = "table-wrapper">
            
                    <component-loader *ngIf="!data.countryLoadData" [width]="" [height]="590"></component-loader>
            
                    <div *ngIf="data.countryLoadData">
                        <table class = "table table-branch" >
                            <thead>
                                <tr class = "table-header">
                                    <th [title]="'home.loadItem' | translate">{{ 'home.loadItem' | translate }}</th>
                                    <th [title]="'home.loadDate' | translate">{{ 'home.loadDate' | translate }}</th>
                                    <th [title]="'home.loadDate' | translate">{{ 'home.loadBusinessDate' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class = "table-body" *ngFor = "let item of data.countryLoadData">
                                    <td [title]="item?.item_name">{{ item?.item_name }}</td>
                                    <td>{{ item?.date === '-' ? '-' : item?.date | date : "dd/MM/yyy" }}</td>
                                    <td>{{ item?.business_date === '-' ? '-' : item?.business_date | date : "dd/MM/yyy" }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>            
        </app-widget>
    </div> 
</div>   
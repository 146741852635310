<component-loader *ngIf="!loaded" [width]="580" [height]="140"></component-loader>

<div *ngIf="loaded" class="d-flex justify-content-end align-items-center">
    <app-legend class="mr-2" name="labels.tnps" color="pink" type="line" gradient="v-gradient"></app-legend>
</div>

<div class="ml-n2 line-charts-gen dashed-order-2 ng-star-inserted" *ngIf="loaded">
    <stacked-vertical-horizontal-graph
        [view]="view"
        [scheme]="comboBarScheme"
        [colorSchemeLine]="lineChartScheme"
        [customColors]="customColors"
        [results]="qualityNPSRolling"
        [animations]="animations"
        [lineChart]="lineChartSeries"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [yLeftAxisScaleFactor]="yLeftAxisScaleFactor"
        [yRightAxisScaleFactor]="yRightAxisScaleFactor"
        [yRightAxisTickFormatting]="yRightAxisTickFormatting"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [legendTitle]="legendTitle"
        [legendPosition]="legendPosition"
        [showGridLines]="showGridLines"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [showRightYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [tooltipDisabled]="tooltipDisabled"
        [barPadding]="barPadding"
        [schemeType]="schemeType"
        [roundDomains]="roundDomains"
        [noBarWhenZero]="noBarWhenZero"
        [showTotalInTooltip]="showTotalInTooltip">
    </stacked-vertical-horizontal-graph>
</div>

<!-- <div class="line-charts-gen dashed-order-2" *ngIf="loaded">
    <ngx-charts-line-chart
        [view]="view"
        [scheme]="colorScheme"
        [legend]="legend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [showGridLines]="showGridLines"
        [showRefLines]="showRefLines"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [timeline]="timeline"
        [results]="qualityNPSRolling"
        [tooltipDisabled]="tooltipDisabled"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [xAxisTicks]="xAxisTicksFinal"
        [rotateXAxisTicks]="rotateXAxisTicks"
        >
    </ngx-charts-line-chart>
</div>
 -->

import { environment } from '../../../environments/environment';

export const OK = 'OK'

export const COLORS = {
    primary: "#1abdb7",
    secondary: "#203440",
    darkBlue: "#0866a9",
    lightBlue: "#0cc4bb",
    lightestBlue: "#c3e9ff",
    darkYellow: "#f6b40e",
    lightPurple: "#d96ceb",
    darkGreen: "#1196a9",
    darkestGreen: "#557c80",
    lightGreen: "#06bfa2",
    gray: "#adb5bd",
    pink: "#ff4394",
    lightOrange: "#f99c34",
    darkOrange: "#ed7200",
    darkRed: "#ff4848",
    m0: '#b01818',
    m1: '#e31f1f',
    m2: '#ff2828',
    m3: '#ff4848',
    m4: '#ff8c4c',
    m5: '#ffce51',
    m6: '#fbff4d',
    m7: '#fdff9e',
    m8: '#00c142',
    m9: '#05ef55',
    m10: '#32f975',
    m11: '#5cfc93',
    m12: '#7dffa9',
    m13: '#a0ffc1',
    m14: '#acf3c4',
    m15: '#bff8d3',
    m16: '#bdffd4',
    m17: '#ddf8e7',
    teal: "#20c997",
    white: "#ffffff",
    black: "#000000",
    walkRed: '#99484f',
    walkGreen: '#237a83',
    tableBeige: "#f8cbad",
    tableLightestOrange: "#ffe699",
    tableLightOrange: "#ffc000",
    tableMediumOrange: "#ed7d31",
    tableDarkOrange: "#c65911",
    tableGray: "#757171",
    tableDarkBlue: "#305496",
    tableMediumBlue: "#4472c4",
    tableLightBlue: "#8ea9db",
    tableLightestBlue: "#d9e1f2",
    tableLightestGreen: "#c6e0b4",
    tableDarkGreen: "#70ad47",
    attribute: "#C900B5",
    report: "#00C9A8",
    metric: "#C90000",
    table: "#5800C9",
    fact: "#C9B500",
    dgGray: "#406880"
}

export const MENU = [
    {
        name: 'menu.portal',
        id: 'portal',
        flag: '',
        link: '/portal',
        active: environment.menuVisibility.portal,
        children: []
    },
    {
        name: 'menu.home',
        id: 'prehome',
        flag: '',
        link: '/prehome',
        active: environment.menuVisibility.prehome,
        children: []
    },
    {
        name: 'menu.clients',
        id: 'clients',
        flag: 'bg-brand-client',
        link: '',
        active: environment.menuVisibility.clients,
        children: [
            {
                name: 'menu.clientsPortfolio',
                id: 'clients-portfolio',
                flag: 'bg-brand-client',
                link: '/clients/clients-portfolio',
                active: environment.menuVisibility.clientsPortoflio,
            },
            {
                name: 'menu.occupation',
                id: 'clients-occupation',
                flag: 'bg-brand-client',
                link: '/clients/clients-occupation',
                active: environment.menuVisibility.clientsOccupation,
            },
            {
                name: 'menu.voc',
                id: 'clients-voc',
                flag: 'bg-brand-client',
                link: '/clients/clients-voc',
                active: environment.menuVisibility.clientsVoc,
            },
            {
                name: 'menu.portfolioDistribution',
                id: 'clients-portfolio-distribution',
                flag: 'bg-brand-client',
                link: '/clients/clients-portfolio-distribution',
                active: environment.menuVisibility.clientsPortfolioDistribution,
            },
            {
                name: 'menu.clientsInternalProcessQuality',
                id: 'clients-internal-process-quality',
                flag: 'bg-brand-client',
                link: '/clients/clients-internal-process-quality',
                active: environment.menuVisibility.clientsInternalProcessQuality,
            },
            {
                name: 'menu.penetration&Segmentation',
                id: 'clients-penetration-segmentation',
                flag: 'bg-brand-client',
                link: '/clients/clients-penetration-segmentation',
                active: environment.menuVisibility.clientsPenetrationSegmentation,
            }
        ]
    },
    {
        name: 'menu.sale',
        id: 'sales',
        flag: 'bg-brand-sale',
        link: '',
        active: environment.menuVisibility.sale,
        children: [
            {
                name: 'menu.saleDetail',
                id: 'sale-detail',
                flag: 'bg-brand-sale',
                link: '/sales/details',
                active: environment.menuVisibility.saleDetail,
            },
            {
                name: 'menu.wallet',
                id: 'sale-portfolio',
                flag: 'bg-brand-sale',
                link: '/sales/portfolio',
                active: environment.menuVisibility.wallet,
            },
            {
                name: 'menu.walletGwpMix',
                id: 'sale-mix-portfolio-gwp',
                flag: 'bg-brand-sale',
                link: '/sales/mix-portfolio-gwp',
                active: environment.menuVisibility.walletGwpMix,
            },
            {
                name: 'menu.quality&Claims',
                id: 'sale-quality-complaints',
                flag: 'bg-brand-sale',
                link: '/sales/quality-complaints',
                active: environment.menuVisibility.qualityClaims,
            }
        ]
    },
    {
        name: 'menu.cancel',
        id: 'cancellation',
        flag: 'bg-brand-cancel',
        link: '',
        active: environment.menuVisibility.cancellation,
        children: [
            {
                name: 'menu.fixedStock',
                id: 'cancellation-fixed-stock',
                flag: 'bg-brand-cancel',
                link: '/cancellation/fixed-stock',
                active: environment.menuVisibility.fixedStock,
            },
            {
                name: 'menu.retentionAccordingToRecord',
                id: 'cancellation-retention-according-to-record',
                flag: 'bg-brand-cancel',
                link: '/cancellation/retention-according-to-record',
                active: environment.menuVisibility.retentionAccordingToRecord,
            },
            {
                name: 'menu.retention',
                id: 'cancellation-retention',
                flag: 'bg-brand-cancel',
                link: '/cancellation/retention',
                active: environment.menuVisibility.retention,
            },
            {
                name: 'menu.charge',
                id: 'cancellation-charge',
                flag: 'bg-brand-cancel',
                link: '/cancellation/charge',
                active: environment.menuVisibility.charge,
            },
            {
                name: 'menu.renewal',
                id: 'cancellation-renewal',
                flag: 'bg-brand-cancel',
                link: '/cancellation/renewal',
                active: environment.menuVisibility.renewal,
            },
            {
                name: 'menu.persistence',
                id: 'cancellation-persistence',
                flag: 'bg-brand-cancel',
                link: '/cancellation/persistence',
                active: environment.menuVisibility.persistence,
            },
            {
                name: 'menu.cancelDetail',
                id: 'cancellation-detail',
                flag: 'bg-brand-cancel',
                link: '/cancellation/details',
                active: environment.menuVisibility.cancelDetail,
            },
            {
                name: 'menu.cancellationCuality',
                id: 'cancellation-quality',
                flag: 'bg-brand-cancel',
                link: '/cancellation/quality',
                active: environment.menuVisibility.cancellationCuality,
            }
        ]
    },
    {
        name: 'menu.sinisters',
        id: 'sinisters',
        flag: 'bg-brand-sinister',
        link: '',
        active: environment.menuVisibility.sinisters,
        children: [
            {
                name: 'menu.sinisters',
                id: 'sinisters-detail',
                flag: 'bg-brand-sinister',
                link: '/sinisters/details',
                active: environment.menuVisibility.sinistersDetails,
            },
            {
                name: 'menu.rejections&Closures',
                id: 'sinisters-rejections',
                flag: 'bg-brand-sinister',
                link: '/sinisters/rejections',
                active: environment.menuVisibility.sinistersRejections,
            },
            {
                name: 'menu.lossRatioOperational',
                id: 'sinisters-loss-ratio',
                flag: 'bg-brand-sinister',
                link: '/sinisters/loss-ratio',
                active: environment.menuVisibility.sinistersLossRatio,
            },
            {
                name: 'menu.sinistersQuality',
                id: 'sinisters-quality',
                flag: 'bg-brand-sinister',
                link: '/sinisters/quality',
                active: environment.menuVisibility.sinistersQuality,
            }

        ]
    },
    {
        name: 'menu.slas',
        id: 'slas',
        flag: 'bg-brand-slas',
        link: '/slas',
        active: environment.menuVisibility.slas,
        children: []
    },
]

export const MESSAGES = {
    errorInfo: "messages.errorInfo",
    notEnoughInfo: "messages.notEnoughInfo",
}

export const ICONS = {
    arrowRightRound: 'arrow-right-round',
    excel: 'excel'
}

export const FILTERS_MAP = {
    month: "month",
    year: "year",
    day: "day",
    country: "country",
    currency: "currency",
    channel_family: "familyChannel",
    channel_macro: "macroChannel",
    channel: "channel",
    channel_point: "pointChannel",
    corporate_society: "corporateSociety",
    territorial: "territorial",
    zone: "zone",
    product_category: "productCategory",
    product_subcategory: "productSubCategory",
    product_family: "productFamily",
    product_technical: "productTechnical",
    bouquet_business: "bouquetBusiness",
    bouquet_lobs: "bouquetLobs",
    producto_mis: "productMis",
    product_type: "productType",
    budgets: "budgets",
    claim_origin_type: "claimOriginType",
    complaint_reason: "complaintReason",
    payment_forms: "paymentForms",
    payment_methods: "paymentMethods"
}

export const FILTERS_TITLES = [{
    month: "filtersPanel.month",
    year: "filtersPanel.year",
    currency: "filtersPanel.currency",
    familyChannel: "filtersPanel.familyChannel",
    macroChannel: "filtersPanel.macroChannel",
    channel: "filtersPanel.channel",
    pointChannel: "filtersPanel.pointChannel",
    corporateSociety: "filtersPanel.corporateSociety",
    territorial: "filtersPanel.territorial",
    zone: "filtersPanel.zone",
    productCategory: "filtersPanel.productCategory",
    productSubCategory: "filtersPanel.productSubCategory",
    productFamily: "filtersPanel.productFamily",
    productTechnical: "filtersPanel.productTechnical",
    bouquetBusiness: "filtersPanel.bouquetBusiness",
    bouquetLobs: "filtersPanel.bouquetLobs",
    productMis: "filtersPanel.productMis",
    productType: "filtersPanel.productType",
    budgets: "filtersPanel.budgets",
    claimOriginType: "filtersPanel.claimOriginType",
    complaintReason: "filtersPanel.complaintReason",
    paymentForms: "filtersPanel.paymentForms",
    paymentMethods: "filtersPanel.paymentMethods"
}]

export const FILTERS_TEMPLATES = [
    { name: 'sale-detail', template: "tpl-sale-1" },
    { name: 'sale-portfolio', template: "tpl-sale-2" },
    { name: 'prehome', template: "tpl-sale-3" },
    { name: 'sale-mix-portfolio-gwp', template: "tpl-sale-3" },
    { name: 'sale-quality-complaints', template: "tpl-sale-4" },
    { name: 'cancellation-detail', template: "tpl-cancellation-1" },
    { name: 'cancellation-persistence', template: "tpl-cancellation-1" },
    { name: 'cancellation-charge', template: "tpl-cancellation-2" },
    { name: 'cancellation-quality', template: "tpl-cancellation-3" },
    { name: 'cancellation-retention-according-to-record', template: "tpl-cancellation-4" },
    { name: 'cancellation-retention', template: "tpl-cancellation-4" },
    { name: 'cancellation-fixed-stock', template: "tpl-cancellation-1" },
    { name: 'cancellation-renewal', template: "tpl-cancellation-5" },
    { name: 'slas', template: "tpl-slas-1" },
    { name: 'sinisters-detail', template: "tpl-cancellation-1" },
    { name: 'sinisters-rejections', template: "tpl-cancellation-1" },
    { name: 'sinisters-loss-ratio', template: "tpl-cancellation-1" },
    { name: 'sinisters-quality', template: "tpl-cancellation-1" },
    { name: 'clients-portfolio', template: "tpl-cancellation-1" },
    { name: 'clients-occupation', template: "tpl-cancellation-1" },
    { name: 'clients-voc', template: "tpl-cancellation-1" },
    { name: 'clients-portfolio-distribution', template: "tpl-cancellation-1" }
]

export const FILTERS_MONTHS_SELECTOR = [
    { id: "01", type: "month", name: "Enero", selected: false },
    { id: "02", type: "month", name: "Febrero", selected: false },
    { id: "03", type: "month", name: "Marzo", selected: false },
    { id: "04", type: "month", name: "Abril", selected: false },
    { id: "05", type: "month", name: "Mayo", selected: false },
    { id: "06", type: "month", name: "Junio", selected: false },
    { id: "07", type: "month", name: "Julio", selected: false },
    { id: "08", type: "month", name: "Agosto", selected: false },
    { id: "09", type: "month", name: "Septiembre", selected: false },
    { id: "10", type: "month", name: "Octubre", selected: false },
    { id: "11", type: "month", name: "Noviembre", selected: false },
    { id: "12", type: "month", name: "Diciembre", selected: false },
]

export const COUNTRY_ZONES = [
    {
        countryCode: "ARG",
        zones: [
            'ar-tf',
            'ar-ba',
            'ar-sj',
            'ar-mz',
            'ar-nq',
            'ar-lp',
            'ar-rn',
            'ar-sl',
            'ar-cb',
            'ar-ct',
            'ar-lr',
            'ar-sa',
            'ar-se',
            'ar-tm',
            'ar-cc',
            'ar-fm',
            'ar-cn',
            'ar-er',
            'ar-ch',
            'ar-sf',
            'ar-mn',
            'ar-df',
            'ar-sc'
        ]
    },
    {
        countryCode: "BRA",
        zones: [
            'br-sp',
            'br-ma',
            'br-pa',
            'br-sc',
            'br-ba',
            'br-ap',
            'br-ms',
            'br-mg',
            'br-go',
            'br-rs',
            'br-to',
            'br-pi',
            'br-al',
            'br-pb',
            'br-ce',
            'br-se',
            'br-rr',
            'br-pe',
            'br-pr',
            'br-es',
            'br-rj',
            'br-rn',
            'br-am',
            'br-mt',
            'br-df',
            'br-ac',
            'br-ro'
        ]
    },
    {
        countryCode: "CHL",
        zones: [
            'cl-2730',
            'cl-bi',
            'cl-ll',
            'cl-li',
            'cl-ai',
            'cl-ma',
            'cl-co',
            'cl-at',
            'cl-vs',
            'cl-rm',
            'cl-ar',
            'cl-ml',
            'cl-ta',
            'cl-2740',
            'cl-an'
        ]
    },
    {
        countryCode: "MEX",
        zones: [
            'mx-3622',
            'mx-bc',
            'mx-bs',
            'mx-so',
            'mx-cl',
            'mx-na',
            'mx-cm',
            'mx-qr',
            'mx-mx',
            'mx-mo',
            'mx-df',
            'mx-qt',
            'mx-tb',
            'mx-cs',
            'mx-nl',
            'mx-si',
            'mx-ch',
            'mx-ve',
            'mx-za',
            'mx-ag',
            'mx-ja',
            'mx-mi',
            'mx-oa',
            'mx-pu',
            'mx-gr',
            'mx-tl',
            'mx-tm',
            'mx-co',
            'mx-yu',
            'mx-dg',
            'mx-gj',
            'mx-sl',
            'mx-hg'
        ]
    },
    {
        countryCode: "URY",
        zones: [
            'uy-sa',
            'uy-so',
            'uy-cl',
            'uy-du',
            'uy-rv',
            'uy-ta',
            'uy-tt',
            'uy-ca',
            'uy-fd',
            'uy-la',
            'uy-ma',
            'uy-mo',
            'uy-ro',
            'uy-co',
            'uy-sj',
            'uy-ar',
            'uy-fs',
            'uy-pa',
            'uy-rn'
        ]
    }
]

export const ATLAS_ENDPOINTS = {
    dashboard: "dashboards/dashboard",
    section: "dashboards/dashboard_section",
    filters: "filters"
}

export const ATLAS_PARAMS = [
    {
        id: 'sale-detail',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.ventas.detalledeventa"
            }
        ]
    },
    {
        id: 'sale-detail--sale',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.ventas.detalledeventa.venta"
            }
        ]
    },
    {
        id: 'sale-detail--monthly-sale',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.ventas.detalledeventa.ventamensual"
            }
        ]
    },
    {
        id: 'sale-portfolio',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.ventas.cartera"
            }
        ]
    },
    {
        id: 'sale-portfolio--indicators',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.ventas.cartera.indicadorescartera"
            }
        ]
    },
    {
        id: 'sale-portfolio--portfolio-vs-sale',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.ventas.cartera.mixcarteravsventa"
            }
        ]
    },
    {
        id: 'sale-mix-portfolio-gwp',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.ventas.mixcarteraygwp"
            }
        ]
    },
    {
        id: 'sale-mix-portfolio-gwp--portfolio',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.ventas.mixcarteraygwp.cartera"
            }
        ]
    },
    {
        id: 'sale-mix-portfolio-gwp--gwp',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.ventas.mixcarteraygwp.gwp"
            }
        ]
    },
    {
        id: 'sale-quality-complaints',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.ventas.calidadyreclamaciones"
            }
        ]
    },
    {
        id: 'sale-quality-complaints--quality',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.ventas.calidadyreclamaciones.calidad"
            }
        ]
    },
    {
        id: 'sale-quality-complaints--internal-process-quality',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.ventas.calidadyreclamaciones.calidadprocesointerno"
            }
        ]
    },
    {
        id: 'sale-quality-complaints--sales-claims',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.ventas.calidadyreclamaciones.reclamaciones"
            }
        ]
    },
    {
        id: 'sale-quality-complaints--sales-tnps',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.ventas.calidadyreclamaciones.tnps"
            }
        ]
    },
    {
        id: 'cancellation-detail',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.cancelacion.detallecancelaciones"
            }
        ]
    },
    {
        id: 'cancellation-detail--churn-rate',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.detallecancelaciones.tasachurn"
            }
        ]
    },
    {
        id: 'cancellation-detail--churn-rate-per-crop',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.detallecancelaciones.tasachurnporcosecha"
            }
        ]
    },
    {
        id: 'cancellation-detail--cancellation-branch-channel',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.detallecancelaciones.tasachurnporramo_canal"
            }
        ]
    },
    {
        id: 'cancellation-detail--cancellation-reasons',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.detallecancelaciones.motivosdecancelacion"
            }
        ]
    },
    {
        id: 'cancellation-retention',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.cancelacion.retencion"
            }
        ]
    },
    {
        id: 'cancellation-retention--retention',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.retencion.retencion"
            }
        ]
    },
    {
        id: 'cancellation-retention--retention-per-action',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.retencion.retencionporaccion"
            }
        ]
    },
    {
        id: 'cancellation-retention--voluntary-cancellation',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.retencion.cancelacionvoluntaria"
            }
        ]
    },
    {
        id: 'cancellation-charge',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.cancelacion.cobro"
            }
        ]
    },
    {
        id: 'cancellation-charge--cancellation-charge-activity',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.cobro.actividadcobranza"
            }
        ]
    },
    {
        id: 'cancellation-charge--cancellation-charge-block',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.cobro.cobro"
            }
        ]
    },
    {
        id: 'cancellation-charge--cancellation-charge-effectiveness',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.cobro.efectividadcobranza"
            }
        ]
    },
    {
        id: 'cancellation-charge--cancellation-charges',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.cobro.cobros"
            }
        ]
    },
    {
        id: 'cancellation-renewal',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.cancelacion.renovacion"
            }
        ]
    },
    {
        id: 'cancellation-renewal--evolutionary-renewalby',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.renovacion.evolutivorenovacionpor…"
            }
        ]
    },
    {
        id: 'cancellation-renewal--renewal',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.renovacion.renovacion"
            }
        ]
    },
    {
        id: 'cancellation-renewal--renewal-effectiveness',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.renovacion.efectividaddelarenovacion"
            }
        ]
    },
    {
        id: 'cancellation-renewal--renewal-per',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.renovacion.renovacionpor…"
            }
        ]
    },
    {
        id: 'cancellation-persistence',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.cancelacion.persistencia"
            }
        ]
    },
    {
        id: 'cancellation-persistence--persistence-monthly',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.persistencia.persistenciamensual"
            }
        ]
    },
    {
        id: 'cancellation-persistence--persistence-new-sale',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.persistencia.persistenciaventanueva"
            }
        ]
    },
    {
        id: 'cancellation-persistence--persistence-postretention',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.persistencia.persistenciapostretencion"
            }
        ]
    },
    {
        id: 'cancellation-persistence--persistence-total',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.persistencia.persistenciatotal"
            }
        ]
    },
    {
        id: 'cancellation-fixed-stock',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.cancelacion.tasachurnstockfijo"
            }
        ]
    },
    {
        id: 'cancellation-fixed-stock--fixed-stock-churn-rate',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.tasachurnstockfijo.tasachurn-stockfijo"
            }
        ]
    },
    {
        id: 'cancellation-fixed-stock--fixed-stock-churn-rate-per-crop',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.tasachurnstockfijo.tasachurnporcosecha-stockfijo"
            }
        ]
    },
    {
        id: 'cancellation-fixed-stock--fixed-stock-persitence',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.tasachurnstockfijo.persistenciatotal-stockfijo"
            }
        ]
    },
    {
        id: 'cancellation-quality',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.cancelacion.calidad"
            }
        ]
    },
    {
        id: 'cancellation-quality--cancellation-nps',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.calidad.tnps"
            }
        ]
    },
    {
        id: 'cancellation-quality--cancellation-quality-block',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.calidad.calidad"
            }
        ]
    },
    {
        id: 'cancellation-quality--cancellation-quality-claims',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.calidad.reclamaciones"
            }
        ]
    },
    {
        id: 'cancellation-retention-according-to-record',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.cancelacion.retencionsegunregistro"
            }
        ]
    },
    {
        id: 'cancellation-retention-according-to-record--retention-according-to-record',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.retencionsegunregistro.retencionsegunregistro"
            }
        ]
    },
    {
        id: 'cancellation-retention-according-to-record--voluntary-cancellation',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.retencionsegunregistro.cancelacionvoluntaria"
            }
        ]
    },
    {
        id: 'cancellation-retention-according-to-record--retention-per-action',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.cancelacion.retencionsegunregistro.retencionporaccion"
            }
        ]
    },
    {
        id: 'sinisters-detail',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.siniestros.siniestros"
            }
        ]
    },
    {
        id: 'sinisters-detail--sinisters',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.siniestros.siniestros.siniestros"
            }
        ]
    },
    {
        id: 'sinisters-rejections',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.siniestros.rechazosycierres"
            }
        ]
    },
    {
        id: 'sinisters-rejections--rejections-and-closings',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.siniestros.rechazosycierres.rechazosycierres"
            }
        ]
    },
    {
        id: 'sinisters-rejections--pending-age',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.siniestros.rechazosycierres.antiguedad"
            }
        ]
    },
    {
        id: 'sinisters-rejections--frequency-evolution',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.siniestros.rechazosycierres.evoluciondefrecuenciapormes"
            }
        ]
    },
    {
        id: 'sinisters-rejections--time-processes',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.siniestros.rechazosycierres.tiemposprocesos"
            }
        ]
    },
    {
        id: 'sinisters-loss-ratio',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.siniestros.lossratiooperacional"
            }
        ]
    },
    {
        id: 'sinisters-loss-ratio--loss-ratio-operational',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.siniestros.lossratiooperacional.lossratiooperacional"
            }
        ]
    },
    {
        id: 'sinisters-quality',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.siniestros.calidad"
            }
        ]
    },
    {
        id: 'sinisters-quality--quality',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.siniestros.calidad.calidad"
            }
        ]
    },
    {
        id: 'sinisters-quality--claims',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.siniestros.calidad.reclamaciones"
            }
        ]
    },
    {
        id: 'sinisters-quality--closed-per-month-of-complaint',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.siniestros.calidad.cerradospormesdedenuncia"
            }
        ]
    },
    {
        id: 'sinisters-quality--tnps',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.siniestros.calidad.tnps"
            }
        ]
    },
    {
        id: 'clients-portfolio',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.clientes.carteradeclientes"
            }
        ]
    },
    {
        id: 'clients-portfolio--portfolio',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.clientes.carteradeclientes.carteradeclientes"
            }
        ]
    },
    {
        id: 'clients-portfolio--stock',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.clientes.carteradeclientes.sockdeclientes"
            }
        ]
    },
    {
        id: 'clients-portfolio--stock-product-class-bouquet',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.clientes.carteradeclientes.stockdeclientesporclasedeproductoyramo"
            }
        ]
    },
    {
        id: 'clients-portfolio--new-clients-casualties-per-bouquet',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.clientes.carteradeclientes.nuevosclientesybajasporramo"
            }
        ]
    },
    {
        id: 'clients-occupation',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.clientes.ocupacion"
            }
        ]
    },
    {
        id: 'clients-occupation--occupation',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.clientes.ocupacion.perfildeclientes"
            }
        ]
    },
    {
        id: 'clients-occupation--stock-by-geolocation',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.clientes.ocupacion.stockdeclientesporubicaciongeografica"
            }
        ]
    },
    {
        id: 'clients-voc',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.clientes.voc"
            }
        ]
    },
    {
        id: 'clients-voc--claims',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.clientes.voc.reclamaciones"
            }
        ]
    },
    {
        id: 'clients-voc--tnps',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.clientes.voc.tnps"
            }
        ]
    },
    {
        id: 'clients-portfolio-distribution',
        params: [
            {
                name: "qname",
                value: "sik_dashboard.clientes.repartodecartera"
            }
        ]
    },
    {
        id: 'clients-portfolio-distribution--distribution-by-holding',
        params: [
            {
                name: "qname",
                value: "sik_dashboard_section.clientes.repartodecartera.repartodecarteraportenencia"
            }
        ]
    },    
]
<app-widget>
    <div class="section-header-block d-flex justify-content-between align-items-center pb-2 mb-2">
        <div class="d-flex">
            <span class="title section-card-title">{{ 'panels.sinisters' | translate }}</span>
            <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams"
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </div>
        <app-option-tab-multiple [graphName]="graphName" [options]="options"></app-option-tab-multiple>
    </div>

    <div class="mb-3 d-flex justify-content-between">
        <div>
            <div *ngIf="!indicatorLoaded" class="mb-3">
                <component-loader [width]="260" [height]="60"></component-loader>
            </div>
            <div *ngIf="indicatorLoaded">
                <span class="text-light mb-1 title">{{ 'panels.pendingSinistersPreviousPeriod' | translate }}</span>
                <app-double-percentage-kpi 
                    [mainValue]="mainValue" 
                    [firstPercentageValue]="firstPercentageValue" 
                    [firstPercentagePrevious]="firstPercentagePrevious" 
                    [firstPercentageTrendValue]="firstPercentageTrendValue" 
                    [firstPercentageTrendDirection]="firstPercentageTrendDirection" 
                    [secondPercentageValue]="secondPercentageValue" 
                    [secondPercentagePrevious]="secondPercentagePrevious" 
                    [secondPercentageTrendValue]="secondPercentageTrendValue" 
                    [secondPercentageTrendDirection]="secondPercentageTrendDirection" 
                    class="kpi">
                </app-double-percentage-kpi>
            </div>
        </div>
        <div class="ml-5">
            <div *ngIf="!indicatorExtraLoaded" class="mb-3">
                <component-loader [width]="260" [height]="60"></component-loader>
            </div>
            <div *ngIf="indicatorExtraLoaded">
                <span class="text-light mb-1 d-block title">{{ 'panels.postedSinistersPeriodReservationsPlusPayments' | translate }}</span>
                <app-small-kpi 
                    [mainValue]="mainValueExtraKpi" 
                    [mainValueUnit]="mainValueUnitExtra" 
                    [percentagePrevious] ="percentagePreviousExtra" 
                    [percentage] ="percentageKpiExtra" 
                    [trend]="trendKpiExtra">
                </app-small-kpi>
            </div>
        </div>
    </div>

    <div class="funnel-container">
        <app-horizontal-funnel-graph *ngIf="funnelLoaded" [options]="funnelOptions" [substateData]="substateData"></app-horizontal-funnel-graph>
        <div class="mb-2">
            <component-loader class="mb-2 mt-1" *ngIf="!funnelLoaded" [width]="" [height]="205"></component-loader>
        </div>
        <div *ngIf="!subcategoryLoaded" class="subcategory-loader">
            <component-loader class="mb-2" [width]="" [height]="75"></component-loader>
        </div>
        <div *ngIf="!bottomGraphLoaded" class="bottom-graph-loader">
            <component-loader class="mb-2"  [width]="" [height]="148"></component-loader>
        </div>
    </div>

    <div *ngIf="funnelLoaded">
        <div class="mt-4 mb-2 d-flex justify-content-end align-items-center">
            <app-legend class="mr-2" name="labels.notManageable" color="light-blue" type="round" gradient="v-gradient"></app-legend>
            <app-legend class="mr-2" name="labels.manageable" color="dark-blue" type="round" gradient="h-gradient"></app-legend>
        </div>
        <grouped-vertical-horizontal-graph-d-axis
            [view]="view"
            [scheme]="comboBarScheme"
            [results]="groupedVerticalGraph"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [yAxisTickFormatting]="yAxisTickFormatting"
            [legend]="showLegend"
            [barPadding]=4
            [groupPadding]=3
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [legendTitle]="legendTitle"
            [lineChart]="horizontalGraph"
            [showGridLines]="showGridLines"
            [showRightGridLines]="showRightGridLines"
            [customColors]="customColors"
            [roundEdges]="roundEdges"
            [activeEntries]="activeEntries"
            [trimXAxisTicks]="trimXAxisTicks"
            [trimYAxisTicks]="trimYAxisTicks"
            [rotateXAxisTicks]="rotateXAxisTicks"
            [maxXAxisTickLength]="maxXAxisTickLength"
            [maxYAxisTickLength]="maxYAxisTickLength"
            [tooltipDisabled]="tooltipDisabled"
            [roundDomains]="roundDomains"
            [addHorizontalGraph]="addHorizontalGraph"
            (activate)="activate($event)">
            <ng-template #tooltipTemplate let-model="model">
                <div class="pie-tooltip">
                    <span class="d-block">{{ tooltipTitle }}</span>
                    <span class="d-block">{{ tooltipValue }}</span>
                </div>
            </ng-template>
        </grouped-vertical-horizontal-graph-d-axis>
    </div>

</app-widget>
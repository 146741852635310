import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { IshikawaItems } from 'src/app/shared/models/ishikawa-items';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-clients-portfolio-ishikawa-diagram',
  templateUrl: './clients-portfolio-ishikawa-diagram.component.html',
  styleUrls: ['./clients-portfolio-ishikawa-diagram.component.scss']
})
export class ClientsPortfolioIshikawaDiagramComponent implements OnInit {

  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "clients-portfolio--portfolio";

  mainValueNewClientsNet: string = "2532";
  percentageNewClientsNet: string = "32";
  trendNewClientsNet: number = 1;

  ishikawaItems: IshikawaItems = {
    tail: {
      title: "",
      mainValue: "",
      percentage: "",
      trend: 1,
      hasPercentage: false
    },
    top: [
      {
        title: "",
        mainValue: "",
        percentage: "",
        trend: 1,
        hasPercentage: false
      }
    ],
    bottom: [
      {
        title: "",
        mainValue: "",
        percentage: "",
        trend: 0,
        hasPercentage: false
      }
    ],
    head: {
      title: "",
      mainValue: "",
      percentage: "",
      trend: 1,
      hasPercentage: true
    }
  };
  ishikawaHeight: string = "200";

  emptyItem: any = {
    title: "",
    mainValue: "",
    percentage: "",
    trend: 1,
    hasPercentage: false
  }

  monthsNames: any;
  clientsNewAccuName: string = "";
  clientsNewPName: string = "";
  clientsReactivatedName: string = "";
  clientsCasualtiesAccuName: string = "";
  clientsCasualtiesName: string = "";

  loaded: boolean = false;
  kpiLoaded: boolean = false;

  filtersValues: any;

  tailIndicator: any;
  topIndicator_01: any;
  topIndicator_02: any;
  topIndicator_03: any;
  bottomIndicator_01: any;
  bottomIndicator_02: any;
  bottomIndicator_03: any;
  headIndicator: any;

  unsubscribe$: Subject<any> = new Subject<any>();

  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private translateService: TranslateService,
    private utilsService: UtilsService,
    private i18n: I18nService,
    private atlasHelper: AtlasHelperService
  ) { }

  ngOnInit(): void {
    this.subscribeToActiveLanguage();
    this.subscribeToFiltersValues();
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: language => {
          this.activeLang = language;
          this.updateAtlasInfoParams(this.pageId, this.activeLang);
        }
      })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  subscribeToFiltersValues() {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: filtersValues => {
          this.filtersValues = filtersValues;
          if (this.filtersValues) {
            this.getTranslations();
            this.retrieveClientsPortfolio(this.filtersValues);
            this.retrieveClientsNet(this.filtersValues);
          }
        }
      })
  }

  getTranslations() {
    const monthsTranslations = this.translateService.get('abbreviatedMonthNames');
    const clientsNewAccuTranslation = this.translateService.get('labels.clientsNewAccu');
    const clientsNewPTranslation = this.translateService.get('labels.clientsNewP');
    const clientsReactivatedTranslation = this.translateService.get('labels.reactivatedMP');
    const clientsCasualtiesAccuTranslation = this.translateService.get('labels.clientsCasualtiesAccu');
    const clientsCasualtiesTranslation = this.translateService.get('labels.clientsCasualties');
    const translations = [monthsTranslations, clientsNewAccuTranslation, clientsNewPTranslation, clientsReactivatedTranslation, clientsCasualtiesAccuTranslation, clientsCasualtiesTranslation]

    forkJoin(translations)
      .subscribe({
        next: translations => {
          this.monthsNames = translations[0];
          this.clientsNewAccuName = translations[1];
          this.clientsNewPName = translations[2];
          this.clientsReactivatedName = translations[3];
          this.clientsCasualtiesAccuName = translations[4];
          this.clientsCasualtiesName = translations[5];
        }
      })
  }

  retrieveClientsPortfolio(filtersValues: any[] = []): void {
    this.loaded = false;
    const portfolioVariationRequest = this.apimstService.getClientsPortfolio("cpv", filtersValues);
    const newClientsVariationRequest = this.apimstService.getClientsPortfolio("csv", filtersValues);
    const annualAccumulatedCasualtiesVariationRequest = this.apimstService.getClientsPortfolio("cuvy", filtersValues);
    const casualtiesVariationRequest = this.apimstService.getClientsPortfolio("cuv", filtersValues);
    const restRequest = this.apimstService.getClientsPortfolio("cre", filtersValues);
    const requests = [portfolioVariationRequest, newClientsVariationRequest, annualAccumulatedCasualtiesVariationRequest, casualtiesVariationRequest, restRequest];
    // const allRequest = this.apimstService.getClientsPortfolio("all", filtersValues);

    forkJoin(requests)
      .subscribe({
        next: results => {
          this.resetValues();
          this.loaded = true;

          if (this.isNotValidResponse(results)) return;
          this.createIndicators(results);
        },
        error: error => console.log('An error ocurred while retrieving clients portfolio data ' + error)
      })
  }

  retrieveClientsNet(filtersValues: any[] = []): void {
    this.kpiLoaded = false;
    this.apimstService.getClientsPortfolio("csn", filtersValues)
      .subscribe({
        next: kpiData => {
          this.resetKpiValues();
          this.kpiLoaded = true;

          if (kpiData == null) return;
          this.createKpi(kpiData[0]);
        },
        error: error => console.log('An error ocurred while retrieving net of new clients data ' + error)
      })

  }

  resetValues() {
    this.ishikawaItems = {
      tail: {
        title: "",
        mainValue: "",
        percentage: "",
        trend: 1,
        hasPercentage: false
      },
      top: [
        {
          title: "",
          mainValue: "",
          percentage: "",
          trend: 1,
          hasPercentage: false
        }
      ],
      bottom: [
        {
          title: "",
          mainValue: "",
          percentage: "",
          trend: 0,
          hasPercentage: false
        }
      ],
      head: {
        title: "",
        mainValue: "",
        percentage: "",
        trend: 1,
        hasPercentage: false
      }
    }
  }

  resetKpiValues() {
    this.mainValueNewClientsNet = "";
    this.percentageNewClientsNet = "";
    this.trendNewClientsNet = 0;
  }

  createIndicators(indicators: any[] = []) {
    const portfolioVariation = indicators[0][0];
    const newClientsVariation = indicators[1][0];
    const annualAccumulatedCasualtiesVariation = indicators[2][0];
    const casualtiesVariation = indicators[3][0];
    const rest = indicators[4][0];

    this.createTailIndicator(portfolioVariation);
    this.createHeadIndicator(portfolioVariation);
    this.createTop1Indicator(newClientsVariation);
    this.createTop2Indicator(newClientsVariation);
    this.createTop3Indicator(rest);
    this.createBottom1Indicator(annualAccumulatedCasualtiesVariation);
    this.createBottom2Indicator(casualtiesVariation);
    this.createBottom3Indicator();

  }

  createKpi(kpiInfo: any = {}) {
    this.mainValueNewClientsNet = kpiInfo.value && kpiInfo.value != "" ? kpiInfo.value.replace(/,/g, '') : "";
    this.percentageNewClientsNet = kpiInfo.diff && kpiInfo.diff != "" ? kpiInfo.diff.replace(/-|,/g, '') + "%" : "";
    this.trendNewClientsNet = kpiInfo.color && kpiInfo.color != "" ? parseInt(kpiInfo.color) : 0;
  }

  createTailIndicator(indicator: any = {}) {
    this.tailIndicator = {
      title: `${this.monthsNames[12]} ${this.getFiltersYear() - 1}`,
      mainValue: indicator.value_previous_year ? this.tailAndHeadFormat(indicator.value_previous_year) : "0",
      percentage: "",
      trend: 0,
      hasPercentage: false,
      addMagnitudeOrder: false

    }
    this.ishikawaItems.tail = this.tailIndicator;
  }

  createHeadIndicator(indicator: any = {}) {
    this.headIndicator = {
      title: `${this.monthsNames[this.getFiltersMonth()]} ${this.getFiltersYear()}`,
      mainValue: indicator.value ? this.tailAndHeadFormat(indicator.value) : "0",
      percentage: indicator.diff ? indicator.diff.replace(/,/g, '') : "0",
      trend: indicator.diff_trend ? parseInt(indicator.diff_trend) : 0,
      hasPercentage: true,
      addMagnitudeOrder: false
    }
    this.ishikawaItems.head = this.headIndicator;
  }

  createTop1Indicator(indicator: any = {}) {
    this.topIndicator_01 = {
      title: `${this.clientsNewAccuName}\n${this.monthsNames[this.getPreviousMonthString(this.getFiltersMonth())]} ${this.handleYearValue(this.getPreviousMonthString(this.getFiltersMonth()))}`,
      mainValue: indicator.value_accumulated ? indicator.value_accumulated.replace(/,/g, '') : "0",
      percentage: indicator.diff_accumulated ? indicator.diff_accumulated.replace(/,/g, '') : "0",
      trend: indicator.diff_trend_accumulated ? parseInt(indicator.diff_trend_accumulated) : 0,
      hasPercentage: true
    }
    this.ishikawaItems.top[0] = this.topIndicator_01;
  }

  createTop2Indicator(indicator: any = {}) {
    this.topIndicator_02 = {
      title: `${this.clientsNewPName}\n${this.monthsNames[this.getFiltersMonth()]} ${this.getFiltersYear()}`,
      mainValue: indicator.value_accumulated ? indicator.value.replace(/,/g, '') : "0",
      percentage: indicator.diff_accumulated ? indicator.diff.replace(/,/g, '') : "0",
      trend: indicator.diff_trend_accumulated ? parseInt(indicator.diff_trend) : 0,
      hasPercentage: true
    }
    this.ishikawaItems.top[1] = this.topIndicator_02;
  }

  createTop3Indicator(rest: any = {}) {
    this.topIndicator_03 = {
      title: `${this.clientsReactivatedName}\n${this.monthsNames[this.getFiltersMonth()]} ${this.getFiltersYear()}`,
      mainValue: rest.value ? rest.value.replace(/,/g, '') : "0",
      percentage: rest.diff ? rest.diff.replace(/,/g, '') : "0",
      trend: rest.diff_trend ? parseInt(rest.diff_trend) : 0,
      hasPercentage: true
    }
    this.ishikawaItems.top[2] = this.topIndicator_03;
  }

  createBottom1Indicator(indicator: any = {}) {
    this.bottomIndicator_01 = {
      title: `${this.clientsCasualtiesAccuName}\n${this.monthsNames[this.getPreviousMonthString(this.getFiltersMonth())]} ${this.handleYearValue(this.getPreviousMonthString(this.getFiltersMonth()))}`,
      mainValue: indicator.value ? indicator.value.replace(/,/g, '') : "0",
      percentage: indicator.diff ? indicator.diff.replace(/,/g, '') : "0",
      trend: indicator.diff_trend ? parseInt(indicator.diff_trend) : 0,
      hasPercentage: true
    }
    this.ishikawaItems.bottom[0] = this.bottomIndicator_01;
  }

  createBottom2Indicator(indicator: any = {}) {
    this.bottomIndicator_02 = {
      title: `${this.clientsCasualtiesName}\n${this.monthsNames[this.getFiltersMonth()]} ${this.getFiltersYear()}`,
      mainValue: indicator.value ? indicator.value.replace(/,/g, '') : "0",
      percentage: indicator.diff ? indicator.diff.replace(/,/g, '') : "0",
      trend: indicator.diff_trend ? parseInt(indicator.diff_trend) : 0,
      hasPercentage: true
    }
    this.ishikawaItems.bottom[1] = this.bottomIndicator_02;
  }

  createBottom3Indicator() {
    this.bottomIndicator_03 = {
      title: "",
      mainValue: "",
      percentage: "",
      trend: 0,
      hasPercentage: false
    }
    this.ishikawaItems.bottom[2] = this.bottomIndicator_03;
  }

  getFiltersMonth() {
    return this.filtersValues.filter(f => f.name == "month")[0].value;
  }

  getFiltersYear() {
    return parseInt(this.filtersValues.filter(f => f.name == "year")[0].value);
  }

  getPreviousMonthString(month: string) {
    let previousMonthNumber = month != "01" ? parseInt(month) - 1 : 12;
    return this.utilsService.padWithZeros(previousMonthNumber, 2);
  }

  handleYearValue(month: string): number {
    return month != "12" ? this.getFiltersYear() : (this.getFiltersYear() - 1);
  }

  isNotValidResponse(response: any) {
    return response[0] == null || response[1] == null || response[2] == null || response[3] == null || response[4] == null;
  }

  tailAndHeadFormat(data: String) {
    const magnitudeOrder = [
      { value: 1e12, letter: 'B' },
      { value: 1e9, letter: 'MM' },
      { value: 1e6, letter: 'M' },
      { value: 1e3, letter: 'K' }
    ];

    const numericValue = parseFloat(data.replace(/,/g, ''));

    for (const order of magnitudeOrder) {
      if (Math.abs(numericValue) >= order.value) {
        const finalValue = parseFloat((numericValue / order.value).toFixed(3));
        return (Intl.NumberFormat("de-DE").format(finalValue) + order.letter);
      }
    }

    return (Intl.NumberFormat("de-DE").format(numericValue));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiAtlasService } from 'src/app/shared/services/api-atlas/api-atlas.service';
import { ApiDataService } from 'src/app/shared/services/api-data/api-data.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';

@Component({
  selector: 'app-data-governance-business',
  templateUrl: './data-governance-business.component.html',
  styleUrls: ['./data-governance-business.component.scss']
})
export class DataGovernanceBusinessComponent implements OnInit { 
  unsubscribe$: Subject<any> = new Subject<any>();

  area: string = 'business';
  headerTitle: string = 'dataGovernanceSection.dataGovernancePortal';
  headerSubtitle: string = 'dataGovernanceSection.businessArea';
  sectionName: string = 'businessDetail';
  controlPanelSection: any[] = [ { id: "controlPanel", name: "labels.controlPanel", selected: true } ];
  loaded: boolean = false;
  detailLoaded: boolean = false;
  descriptionOption: boolean = true;
  filtersOption: boolean = false;
  colorStripe: string;
  categoryInformation: any;
  upperMenuSections: any;
  selectedSectionGuid: string;
  selectedSectionIndex: number = 0;
  sections: any = [];
  filtersInfo: any = [];
  title: string;
  activeLanguage: string;
  routerLink: string = "/data-governance/portal";

  constructor(
    private eventsService: EventsService,
    private apiDataService: ApiDataService,
    private apiAtlasService: ApiAtlasService,
    private i18n: I18nService,
  ) { }

  ngOnInit(): void {
    this.subscribeToActiveLanguage();
    this.loaded = false;
    this.detailLoaded = false;
    this.apiDataService.getDataGovernanceBusinessCategory().pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if (data !== undefined) {
        if (data.length !== 0) {
          this.processBusinessInformation(data);
        }
      } else {
        if (localStorage.length !== 0) {
          const data = JSON.parse(localStorage.getItem('dataGovernanceInfo'));
          if (Object.keys(data).length !== 0) {
            this.processBusinessInformation(data);
          }
        }
      }     
     });

    this.eventsService.searchOptionSelected$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        if (data.sectionName === 'businessDetail') {
          this.detailLoaded = false;
          this.apiAtlasService.getDashboardSectionDetail(this.activeLanguage, data.guid).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
            this.categoryInformation = data;
            this.categoryInformation.language = this.activeLanguage;
            this.detailLoaded = true;
          });

        } else if (data.sectionName === 'businessUpperMenu') {
          this.detailLoaded = false;
          this.apiAtlasService.getDashboardDetail(this.activeLanguage, data.id).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
            let sectionInformation = data;
            sectionInformation.color = this.categoryInformation.color;
            sectionInformation.title = this.title;
            sectionInformation.dashboards = this.upperMenuSections;
            sectionInformation.controlPanelGuid = sectionInformation.guid;
            sectionInformation.order = 0;
            sectionInformation.sections = data.sections;
            this.processBusinessInformation(sectionInformation);

            this.detailLoaded = true;
          });
        }
      }); 

  }

  private processBusinessInformation(data: any) {
    this.categoryInformation = data;
    this.categoryInformation.language = this.activeLanguage;
    if (this.categoryInformation.controlPanelGuid) {
      this.controlPanelSection[0]['guid'] = this.categoryInformation.controlPanelGuid;
    } 

    this.title = this.categoryInformation.title;
    this.colorStripe = this.categoryInformation.color;
    this.selectedSectionGuid = this.categoryInformation.controlPanelGuid;
    this.selectedSectionIndex = this.categoryInformation.order;
    this.upperMenuSections = this.sortArrayFromOrderParam(this.categoryInformation.dashboards);
    this.addSections(this.sortArrayFromOrderParam(this.categoryInformation.relationships.sections));
    this.loaded = true;
    this.detailLoaded = true;
  }

  private subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLanguage = language;
      }
    })
  }

  sortArrayFromOrderParam(array): Array<any> {
    if (array.length !== 0) {
      return array.sort((a,b) => {
        if (a.order < b.order) {
          return -1;
        } else {
          return 1;
        }
      });
    } else {
      return [];
    }
    
  }

  addSections(sections: any) {
    this.sections = [];
    this.sections = _.cloneDeep(this.controlPanelSection);
    for (let [index, item] of sections.entries()) {
      this.sections.push(
        {
          guid: item.guid,
          name: item.name,
          selected: (index === this.selectedSectionIndex - 1) ? true : false
        }
      );
    }
    if (this.selectedSectionIndex !== 0) this.sections[0]['selected'] = false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { animate, animateChild, query, stagger, style, transition, trigger, group } from '@angular/animations';

export class Animations {

    /* fadeInOut */
    public static fadeInOut = trigger('fadeInOut', [
        transition(':enter', [
            style({ opacity: '0' }),
            animate('.2s linear', style({ opacity: '1' })),
        ]),
        transition(':leave', [
            style({ opacity: '1' }),
            animate('.2s linear', style({ opacity: '0' })),
        ])
    ]);

    /* slideToLeft */
    public static slideToLeft = trigger('slideToLeft', [
        transition(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate('.2s linear', style({ transform: 'translateX(0)' })),
        ]),
        transition(':leave', [
            style({ transform: 'translateX(0)' }),
            animate('.2s linear', style({ transform: 'translateX(100%)' })),
        ])
    ]);

    /* slideToRight */
    public static slideToRight = trigger('slideToRight', [
        transition(':enter', [
            style({ transform: 'translateX(-100%)' }),
            animate('.2s linear', style({ transform: 'translateX(0)' })),
        ]),
        transition(':leave', [
            style({ transform: 'translateX(0)' }),
            animate('.2s linear', style({ transform: 'translateX(-100%)' })),
        ])
    ]);

    public static dumpAnimation = trigger('dumpAnimation', [
        transition('* => void', [
            query('@*', [animateChild()], { optional: true })
        ]),
    ]);

    public static animateFilters = trigger('animateFilters', [
        transition(':leave', [
            group([
                query('@fadeInOut', animateChild()),
                query('@slideToLeft', animateChild())
            ])
        ])
    ])

    public static animateLeftPanel = trigger('animateLeftPanel', [
        transition(':leave', [
            group([
                query('@fadeInOut', animateChild()),
                query('@slideToRight', animateChild())
            ])
        ])
    ])

    /* fader */
    public static fader = trigger('routeAnimations', [
        transition('* <=> *', [
            
            
            
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0
                })
            ], {optional: true}),
            query(':enter', [
                animate('300ms linear',
                    style({
                        opacity: 1,
                    }))
            ], {optional: true}),

            
        ]),
    ]);

}
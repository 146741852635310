import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ATLAS_ENDPOINTS, ATLAS_PARAMS } from '../../globals/globals';
import { ApiAtlasService } from '../../services/api-atlas/api-atlas.service';
import { ApiDataService } from '../../services/api-data/api-data.service';
import { EventsService } from '../../services/events/events.service';
import { I18nService } from '../../services/i18n/i18n.service';

@Component({
  selector: 'app-dg-info-card',
  templateUrl: './dg-info-card.component.html',
  styleUrls: ['./dg-info-card.component.scss']
})
export class DgInfoCardComponent implements OnInit {

  @Input() cardInfo; // Object with the properties: title, description and link
  @Input() infoType;

  unsubscribe$: Subject<any> = new Subject<any>();

  textEditorHeight: '300px';
  textEditorWidth: '470px';
  textEditor: boolean = false;
  openTextEditor: boolean = false;
  elementGuid: string;
  elementUpdate: string;
  activeLanguage: string;
  endpoint: string;
  hasEditPermission: boolean = false;

  constructor(
    private eventsService: EventsService,
    private apiAtlasService: ApiAtlasService,
    private i18n: I18nService,
    private router: Router,
    private apiDataService: ApiDataService
  ) { }

  ngOnInit(): void {
    this.subscribeToActiveLanguage();
    this.selectEditionEndpoint();
    this.subscribeToUserInfoLoaded();

    // Detect HTML edition cancellation
    this.eventsService.cancelHtmlTextEdition$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data.cancel && data.elementGuid === this.elementGuid) this.openTextEditor = false;
      });

    // Detect HTML edition storage  
    this.eventsService.saveHtmlTextEdition$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data.edition && data.elementGuid !== undefined && data.elementGuid === this.elementGuid) {
          this.cardInfo.description = data.content;
          this.openTextEditor = false;
          const attributes = {
            desc_html: this.cardInfo.description
          }
          this.apiAtlasService.updateDataGovernanceInfo(this.activeLanguage, this.elementGuid, attributes, this.endpoint).pipe(takeUntil(this.unsubscribe$)).subscribe();
        }
      })

  }

  subscribeToUserInfoLoaded(): void {
    this.eventsService.userInfoLoaded$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        userInfo => {
          this.hasEditPermission = userInfo.edit_permission == "1" ? true: false;
        }
      )
  }

  textEdition(guid) {
    this.openTextEditor = true;
    this.elementGuid = guid;
  }

  private subscribeToActiveLanguage() {
    this.i18n.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: language => {
          this.activeLanguage = language;
        }
      })
  }

  private selectEditionEndpoint() {
    switch (this.infoType) {
      case 'filters':
        this.endpoint = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.filters;
        break;
    }
  }

  goToTechnicalInfo(id: string, type: string) {
    const entityData = {
      breadcrumbAction: 'reset',
      id: id
    }

    const entityType = {
      entityType: type,
      showGraph: false
    }
    const data = { entityData, entityType }

    this.apiDataService.updateDataGovernanceEntity(data);
    this.router.navigate(['/data-governance/technical']);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

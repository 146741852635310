import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationItems } from '../../models/navigation-items'

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  pageId: string = "";

  navigationItems = { activeItemIndex: 0, itemsNumber: 0, items: [{}] };

  navigationItems$ = new BehaviorSubject<Object>( this.navigationItems );
  pageId$ = new BehaviorSubject<string>( this.pageId );

  constructor() { }
}

<app-widget>

    <div class="section-header-block d-flex justify-content-start align-items-center pb-2">
        <span class="title section-card-title">{{ 'panels.gwp' | translate }}</span>
        <app-btn-gobernance-info 
            class="ml-2" 
            [endpoint]="endpoint" 
            [endpointParams]="endpointParams"
            *ngIf="hasGobernanceInfo">
        </app-btn-gobernance-info>
        <mat-icon class="excel-icon ml-2" svgIcon="excel" (click)="exportAsXLSX()" *ngIf="loaded"></mat-icon>
    </div>

    <gwp-graph></gwp-graph>

</app-widget>

import { Component, Input, OnInit } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';

@Component({
  selector: 'app-tile-list-item',
  templateUrl: './tile-list-item.component.html',
  styleUrls: ['./tile-list-item.component.scss']
})
export class TileListItemComponent implements OnInit {
  
  @Input() item: any;
  @Input() textAlign: string = "center";
  @Input() bgColor: string = COLORS.darkBlue;
  @Input() textColor: string = COLORS.gray;

  constructor() { }

  ngOnInit(): void {
  }

}

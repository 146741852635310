import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClientsOccupationGenre } from 'src/app/shared/models/client-occupation-genre';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-clients-occupation-main-indicators',
  templateUrl: './clients-occupation-main-indicators.component.html',
  styleUrls: ['./clients-occupation-main-indicators.component.scss']
})
export class ClientsOccupationMainIndicatorsComponent implements OnInit {
  loaded: boolean = false;

  indicators = {
    men: {
      iconRoute: "assets/icons/men.svg",
      iconWidth: "16",
      mainValue: "49",
      mainValueUnit: "%",
      trend: 1,
      percentage: "0.2",
    },
    women: {
      iconRoute: "assets/icons/women.svg",
      iconWidth: "17",
      mainValue: "51",
      mainValueUnit: "%",
      trend: 1,
      percentage: "0.2",
    }
  }

  emptyIndicator: any = {
    iconRoute: "",
    iconWidth: "",
    mainValue: "0",
    mainValueUnit: "%",
    trend: 0,
    percentage: "0",
  }

  filtersValues: any[];

  unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService
  ) { }

  ngOnInit(): void {
    this.subscribeToFiltersValues();
  }

  subscribeToFiltersValues(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: filtersValues => {
          this.loaded = false;
          if (filtersValues.length !== 0) {
            this.filtersValues = filtersValues;
            this.resetValues();
            this.retrieveClientsOccupation(this.filtersValues);
          }
        }
      });
  }

  resetValues() {
    this.indicators.men = this.emptyIndicator;
    this.indicators.women = this.emptyIndicator;
  }

  retrieveClientsOccupation(filtersValues: any[] = []) {
    this.apimstService.getClientsOccupation("csg", filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data: ClientsOccupationGenre[]) => {
          this.loaded = true;

          if (data == null) return;
          this.indicators = this.createIndicators(data);
        }
      );
  }

  createIndicators(indicators: any[] = []) {
    const menInfoRaw = indicators.filter( n => this.isMan(n.name) )[0];
    const womenInfoRaw = indicators.filter( n => this.isWoman(n.name) )[0];

    let indicatorsFinal: any = { men: {}, women: {} };
    indicatorsFinal.men.iconRoute = "assets/icons/men.svg";
    indicatorsFinal.men.iconWidth = "16";
    indicatorsFinal.men.mainValue = menInfoRaw.value ? parseFloat(menInfoRaw.value).toString() : "0";
    indicatorsFinal.men.mainValueUnit = "%";
    indicatorsFinal.men.trend = menInfoRaw.diff_trend ? parseInt(menInfoRaw.diff_trend) : 0;
    indicatorsFinal.men.percentage = menInfoRaw.diff ? parseFloat(menInfoRaw.diff).toString() : "0";

    indicatorsFinal.women.iconRoute = "assets/icons/women.svg";
    indicatorsFinal.women.iconWidth = "16";
    indicatorsFinal.women.mainValue = womenInfoRaw.value ? parseFloat(womenInfoRaw.value).toString() : "0";
    indicatorsFinal.women.mainValueUnit = "%";
    indicatorsFinal.women.trend = womenInfoRaw.diff_trend ? parseInt(womenInfoRaw.diff_trend) : 0;
    indicatorsFinal.women.percentage = womenInfoRaw.diff ? parseFloat(womenInfoRaw.diff).toString() : "0";

    return indicatorsFinal;
  }

  isMan(name: string) {
    return (name).trim().toLowerCase() == "Hombre".trim().toLowerCase()
            || (name).trim().toLowerCase() == "Masculino".trim().toLowerCase()
  }

  isWoman(name: string) {
    return (name).trim().toLowerCase() == "Mujer".trim().toLowerCase()
            || (name).trim().toLowerCase() == "Femenino".trim().toLowerCase()
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { tableSeries } from './data';
import * as _ from 'lodash';

@Component({
  selector: 'app-clients-experience-indicators-table',
  templateUrl: './clients-experience-indicators-table.component.html',
  styleUrls: ['./clients-experience-indicators-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientsExperienceIndicatorsTableComponent implements OnInit {
  loaded: boolean = false;

  tableSeries: any = {};

  typeMatching: any = {
    "Tipo 1": "very_dissatisfied",
    "Tipo 2": "dissatisfied",
    "Tipo 3": "satisfied",
    "Tipo 4": "very_satisfied",
    "Tipo 5": "neutral"
  }

  filtersValues: any[];

  unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService
  ) { }

  ngOnInit(): void {
    this.subscribeToFiltersValues();
  }

  subscribeToFiltersValues(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: filtersValues => {
          this.filtersValues = filtersValues;
          this.loaded = false;
          this.resetValues();
          this.retrieveClientsExperience(this.filtersValues);
        }
      })
  }

  resetValues(): void {
    this.tableSeries = {
      very_dissatisfied: {},
      dissatisfied: {},
      satisfied: {},
      very_satisfied: {},
      neutral: {},
    };
  }

  retrieveClientsExperience(filtersValues: any[] = []): void {

    this.apimstService.getClientsExperience(filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: data => {
          this.loaded = true;
          if(data == null) return;
          this.tableSeries = this.createTable(data);
        },
        error: error => console.log("An error occurred while retrieving clients experience data " + error)  
      }
    );
  }

  createIndicators(): void {
    this.tableSeries = tableSeries;
  }

  createTable(rawInfo): void {
    const groupedByType = this.groupByType(rawInfo);
    let series = _.cloneDeep(this.tableSeries);
    const keys = Object.keys(series);
    keys.forEach((key, index) => {
      if(groupedByType.filter( v => v.type == key )[0] != undefined) {
        series[key] = {
          value: groupedByType.filter( v => v.type == key )[0].values[0].valueTotal,
          values: groupedByType.filter( v => v.type == key )[0].values
        }
      }
    });
    return series;
  }

  groupByType(graphInfo: any) {
    let graphInfoCopy = graphInfo;
    let groupedByType: any[] = [];
    groupedByType = _.chain(graphInfoCopy).groupBy("type").map(
      (value, key) => (
        { 
          type: this.typeMatching[key], 
          values: value.map( subtype => (
            { 
              name: subtype.subtype, 
              value: Intl.NumberFormat("de-DE").format(parseFloat(subtype.percentage_total)).toLocaleString(),
              valueTotal: Intl.NumberFormat("de-DE").format(parseFloat(subtype.percentage_type)).toLocaleString()
            }))
        })
    ).value();

    return groupedByType;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<div class="dialog">
    <div class="wrapper_grid">
        <app-widget>
            <p class="section-title">{{ 'panels.dailySale' | translate }}
                <span class="material-icons ml-2 fullscreen-icon" [mat-dialog-close]="true" >fullscreen_exit</span>
            </p>

            <div class="d-flex justify-content-end align-items-center">
                <app-legend class="mr-2" name="OM" color="light-blue" type="round" gradient="v-gradient"></app-legend>
                <app-legend class="mr-2" name="CR" color="dark-blue" type="round" gradient="v-gradient"></app-legend>
            </div>

            <div>
                <ngx-charts-bar-vertical-stacked
                class="stackedBarVerticalDialog"
                [view]="view"
                [scheme]="colorScheme"
                [schemeType]="schemeType"
                [results]="multi"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [xAxisTicks]="xAxisTicks"  
                [animations]="animations">
                </ngx-charts-bar-vertical-stacked>
            </div>
        </app-widget>
    </div> 
</div>
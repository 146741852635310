import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {

  @Input() icon: string;
  @Input() message: string;
  @Input() width: any;
  @Input() height: any;
  @Input() direction: string;

  getStyles() {

    var styles;

    if( this.width == '' || this.width == null || this.width == '0' || this.width == 0) {
      styles = {
        'width.%': 100,
        'height.px': this.height ? this.height: '',
      }
    } else {
      styles = {
        'width.px': this.width ? this.width: '',
        'height.px': this.height ? this.height: '',
      }
    }

    return styles;
  }

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventsService } from 'src/app/shared/services/events/events.service';

@Component({
  selector: 'app-data-governance-home',
  templateUrl: './data-governance-home.component.html',
  styleUrls: ['./data-governance-home.component.scss']
})
export class DataGovernanceHomeComponent implements OnInit {

  unsubscribe$: Subject<any> = new Subject<any>();

  isDataGovernancePortal: boolean = true;
  portalLoaded: boolean = false;
  searchPanel: boolean;

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    this.eventsService.dataGovernancePortalLoaded$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data) {
          this.portalLoaded = true;
        }
      });
  }

  detectSearchPanel(event) {
    this.searchPanel = event;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

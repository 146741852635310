import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-panel-tabs',
  templateUrl: './info-panel-tabs.component.html',
  styleUrls: ['./info-panel-tabs.component.scss']
})
export class InfoPanelTabsComponent implements OnInit {
  @Input() tabElements: any[];
  @Input() tabLineColor: string;

  constructor() { }

  ngOnInit(): void {
    this.removeTabLineColor();
    this.changeTabLineColor();
  }

  ngOnChanges(): void {
    this.removeTabLineColor();
    this.changeTabLineColor();
  }

  changeTabLineColor() {
    const matInkBar = document.querySelector<HTMLElement>('.tab-group-info-panel .mat-ink-bar');
    matInkBar.style.cssText = 'background-color:' + this.tabLineColor + ' !important';
  }

  removeTabLineColor() {
    const matInkBar = document.querySelector<HTMLElement>('.tab-group-info-panel .mat-ink-bar');
    matInkBar.style.removeProperty('background-color');
  }

  ngOnDestroy(): void {
    this.removeTabLineColor();
  }

}

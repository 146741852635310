import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LossRatioTable } from 'src/app/shared/models/loss-ratio-table';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-loss-ratio-info-table',
  templateUrl: './loss-ratio-info-table.component.html',
  styleUrls: ['./loss-ratio-info-table.component.scss']
})
export class LossRatioInfoTableComponent implements OnInit {

  tableInfo: LossRatioTable[] = [];
  loaded: boolean = false;

  unsubscribe$: Subject<any> = new Subject<any>();

  filtersValues: any[] = [];

  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  percentageSymbol: string = "%";

  constructor(
    private translateService: TranslateService,
    private api: ApimstService,
    private filtersService: FiltersService
  ) { }

  ngOnInit(): void {

    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data.length !== 0) {
            this.filtersValues = data;

            this.resetValues();
            this.retrieveLossRatioTable("lrb", this.filtersValues);
          }
        });
  }

  retrieveLossRatioTable(type_report: string = "", filtersValues: any[] = []): void {
    this.loaded = false;

    this.api.getSinistersLossRatio(type_report, filtersValues)
      .subscribe({
        next: data => {
          this.createTable(data);
          this.loaded = true;
        }
      })
  }

  createTable(info: any[] = []) {
    if (info == null) return;
    for(let serie of info) {
      this.tableInfo.push({
        branch: serie.name ? serie.name: "",
        loss_ratio: serie.value ? this.formatPipe.transform( parseFloat(serie.value.replace(/-|,/g, '')) ) + this.percentageSymbol : "",
        comparative: serie.diff ? this.formatPipe.transform( parseFloat(serie.diff.replace(/-|,/g, '')) ) + this.percentageSymbol : "",
        diffComparative: serie.diff_trend ? parseInt(serie.diff_trend) : 1,
        accumulated: serie.value_accumulated ? this.formatPipe.transform( parseFloat(serie.value_accumulated.replace(/-|,/g, '')) ) + this.percentageSymbol : "",
        diffAccumulated: serie.diff_trend_accumulated ? parseInt(serie.diff_trend_accumulated) : 1,
        variation: serie.diff_accumulated ? this.formatPipe.transform( parseFloat(serie.diff_accumulated.replace(/-|,/g, '')) ) + this.percentageSymbol : ""
      })
    }
  }

  resetValues() {
    this.tableInfo = [];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


}

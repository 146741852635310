<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2 w-100">
        <div class="d-flex justify-content-between align-items-center w-100"> 
            <div class="d-flex align-items-center">
                <span class="title section-card-title">{{ 'panels.clientsClaims' | translate }}</span>
                <app-btn-gobernance-info 
                    class="ml-2" 
                    [endpoint]="endpoint" 
                    [endpointParams]="endpointParams"
                    *ngIf="hasGobernanceInfo">
                </app-btn-gobernance-info>
            </div>
            <div class="d-flex align-items-center">
                <component-loader [width]="180" [height]="37" *ngIf="!claimReasonsLoaded"></component-loader>
                <div class="dropdown-container dropdown-container dropdown-light" *ngIf="claimReasonsLoaded">
                    <app-dropdown 
                        [title]="clientsDropdownTitle" 
                        [dropdownId]="clientsDropdownId" 
                        [items]="itemsClientsClaims">
                    </app-dropdown>
                </div>
                <!-- <app-option-tab-multiple [graphName]="graphName" [options]="panelOptions"></app-option-tab-multiple> -->
            </div>
        </div>
    </div>

    <component-loader class="d-block mt-2" [width]="" [height]="103" *ngIf="!claimReasonsLoaded"></component-loader>
    <app-clients-voc-claims-velocimeter class="d-block mt-2" *ngIf="claimReasonsLoaded"></app-clients-voc-claims-velocimeter>
    
    <component-loader class="d-block mt-3 overflow-hidden" [width]="" [height]="140" *ngIf="!claimReasonsLoaded"></component-loader>
    <app-clients-voc-claims-graphic class="d-block mt-4 overflow-hidden pb-1" *ngIf="claimReasonsLoaded"></app-clients-voc-claims-graphic>
    
    <app-clients-voc-claims-polar class="d-block mt-4"></app-clients-voc-claims-polar>

</app-widget>

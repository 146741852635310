import { Component, Input, OnInit } from '@angular/core';
import { EventsService } from '../../services/events/events.service';

@Component({
  selector: 'app-option-tab-multiple',
  templateUrl: './option-tab-multiple.component.html',
  styleUrls: ['./option-tab-multiple.component.scss']
})
export class OptionTabMultipleComponent implements OnInit {

  @Input() options: any[];
  @Input() graphName: string;

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    // console.log("this.options")
    // console.log(this.options)
  }

  selectOption(option: any, options: any) {
    for(let option of options) {
      option.selected = false;
    }
    option.selected = true;
    option.graphName = this.graphName;
    this.eventsService.optionTabMultipleSelected$.emit(option);
    this.eventsService.optionTabMultipleChanged$.next(option);
  }

}

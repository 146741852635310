<component-loader *ngIf="!loaded" [width]="410" [height]="140"></component-loader>

<velocimeter *ngIf="loaded"
    [viewBox]="viewBox"
    [strokeWidth]="strokeWidth"
    [target]="target"
    [currentValue]="currentValue"
    [mainValue]="qualityClaim?.value"
    [secondValue]="qualityClaim?.target"
    [diff]="qualityClaim?.diff"
    [trend]="qualityClaim?.diff_trend"
    [hasInfo]="hasInfo"
    [hAlign]="hAlign"
    [vAlign]="vAlign"
    [barOrientation]="barOrientation"
    >
</velocimeter>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'
import { QualityIndicator } from 'src/app/shared/models/quality-indicator';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { NumberPipe } from 'src/app/shared/pipes/number.pipe';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import _ from 'lodash';

@Component({
  selector: 'app-quality-internal-process',
  templateUrl: './quality-internal-process.component.html',
  styleUrls: ['./quality-internal-process.component.scss']
})
export class QualityInternalProcessComponent implements OnInit, OnDestroy {
  
  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "sale-quality-complaints--internal-process-quality";

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;

  qualityPolicySentOnTimeIndicator: QualityIndicator
  qualityPolicyApprovalTimeIndicator: QualityIndicator;
  qualityPolicyAvgSentTimeIndicator: QualityIndicator;
  qualityPolicyWithoutBackupIndicator: QualityIndicator;

  qualityPolicySentOnTimeIndicatorLoaded: boolean = false;
  qualityPolicyApprovalTimeIndicatorLoaded: boolean = false;
  qualityPolicyAvgSentTimeIndicatorLoaded: boolean = false;
  qualityPolicyWithoutBackupIndicatorLoaded: boolean = false;

  daysName: string;
  
  percentagePrevious: string = "labels.pm";

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private magnitudeOrder: MagnitudeOrderPipe,
    private numberFormat: NumberPipe,
    private translateService: TranslateService,
    private atlasHelper: AtlasHelperService,
    private i18n: I18nService
  ) { }

  ngOnInit(): void {

    this.subscribeToActiveLanguage();

    this.translateService.get('labels.days')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(data => this.daysName = data);

    this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        if (data.length !== 0) {
          this.filtersValues = data;
          this.qualityPolicySentOnTimeIndicatorLoaded = false;
          this.qualityPolicyApprovalTimeIndicatorLoaded = false;
          this.qualityPolicyAvgSentTimeIndicatorLoaded = false;
          this.qualityPolicyWithoutBackupIndicatorLoaded = false;

          // Quality indicators calls
          this.retrieveQualityIndicator('st', data); // Quality policy sent on time
          this.retrieveQualityIndicator('at', data); // Quality policy approval time
          this.retrieveQualityIndicator('ast', data); // Quality policy average sent time
          this.retrieveQualityIndicator('wb', data); // Quality policy without backup
        }
      }
    );

  }

  // Method to retrieve the quality indicator data
  private retrieveQualityIndicator(quality_indicator: string, filtersValues: any) {
    this.apimstService.getQualityIndicators(quality_indicator, filtersValues)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (data: QualityIndicator) => {
        switch(quality_indicator) {
          case 'st':
            this.qualityPolicySentOnTimeIndicator = data;
            this.qualityPolicySentOnTimeIndicatorLoaded = true;
            break;
          case 'at':
            this.qualityPolicyApprovalTimeIndicator = data;
            this.qualityPolicyApprovalTimeIndicator.unit = this.daysName;
            this.qualityPolicyApprovalTimeIndicatorLoaded = true;
            break;
          case 'ast':
            this.qualityPolicyAvgSentTimeIndicator = data;
            this.qualityPolicyAvgSentTimeIndicator.unit = this.daysName;
            this.qualityPolicyAvgSentTimeIndicatorLoaded = true;
            break;
          case 'wb':
            this.qualityPolicyWithoutBackupIndicator = data;
            this.qualityPolicyWithoutBackupIndicator.target = this.magnitudeOrder.transform(this.numberFormat.transform(this.qualityPolicyWithoutBackupIndicator.target));
            this.qualityPolicyWithoutBackupIndicatorLoaded = true;
            break;
          default:
            break;  
        }
      }
    );
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }



}

<div class="breadcrumbs">
  <span class="ellipsis" [ngStyle]="getEllipsisStyles()" [matMenuTriggerFor]="menu" *ngIf="showEllipsis">...</span>
  <mat-menu #menu="matMenu">
    <span *ngFor="let breadcrumb of breadcrumbs" class="breadcrumb-list-item d-flex" [ngStyle]="getBreadcrumbListStyles(breadcrumb)" (click)="goToEntityInfo(breadcrumb.id)"><mat-icon class="arrow-icon dg-gray-color">arrow_right</mat-icon>{{ breadcrumb.name | translate | uppercase }}</span>
  </mat-menu>

  <span class="breadcrumb" *ngFor="let breadcrumb of reducedBreadcrumbs">
    <span class="breadcrumb-name" [ngStyle]="getNameStyles(breadcrumb)" (click)="goToEntityInfo(breadcrumb.id)">{{ breadcrumb.name | translate | uppercase
      }}</span>
    <span class="separator" [ngStyle]="getSeparatorStyles()">></span>
  </span>
</div>
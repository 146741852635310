import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    ChangeDetectionStrategy,
    TemplateRef
  } from '@angular/core';
  import { trigger, style, animate, transition } from '@angular/animations';
  import { formatLabel, escapeLabel, D0Types, DataItem } from '@swimlane/ngx-charts';
  
  @Component({
    selector: 'g[stacked-horizontal-negative-graph-bars]',
    templateUrl: './stacked-horizontal-negative-graph-bars.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
      trigger('animationState', [
        transition(':leave', [
          style({
            opacity: 1
          }),
          animate(500, style({ opacity: 0 }))
        ])
      ])
    ]
  })
  export class StackedHorizontalNegativeGraphBarsComponent implements OnChanges {
    bars: any;
    x: any;
    y: any;
    barsForDataLabels: Array<{ x: number; y: number; width: number; height: number; total: number; series: string }> = [];
  
    @Input() dims;
    @Input() type;
    @Input() series;
    @Input() xScale;
    @Input() yScale;
    @Input() colors;
    @Input() tooltipDisabled;
    @Input() gradient: boolean;
    @Input() activeEntries: any[];
    @Input() seriesName: string;
    @Input() tooltipTemplate: TemplateRef<any>;
    @Input() roundEdges: boolean;
    @Input() animations: boolean;
    @Input() showDataLabel: boolean;
    @Input() dataLabelFormatting: any;
    @Input() noBarWhenZero: boolean;
      
    @Output() select = new EventEmitter();
    @Output() activate = new EventEmitter();
    @Output() deactivate = new EventEmitter();
    @Output() dataLabelWidthChanged = new EventEmitter();
  
    tooltipPlacement: string;
    tooltipType: string;
  
    ngOnChanges(changes: SimpleChanges): void {
      this.update();
    }
  
    update(): void {
      this.updateTooltipSettings();
      const d0 = {
        [D0Types.positive]: 0,
        [D0Types.negative]: 0
      };
      let d0Type: D0Types;
      d0Type = D0Types.positive;
      let total;
      if (this.type === 'normalized') {
        total = this.series.map(d => d.value).reduce((sum, d) => sum + d, 0);
      }
      const xScaleMin = Math.max(this.xScale.domain()[0], 0);
  
      this.bars = this.series.map((d, index) => {
        let value = d.value;
        const roundEdges = this.roundEdges;
        d0Type = value > 0 ? D0Types.positive : D0Types.negative;
  
        const bar: any = {
          value,
          roundEdges,
          data: d
        };
  
        bar.height = this.yScale.bandwidth() - 9;
   
        if (this.type === 'stacked') {
          const offset0 = d0[d0Type];
          const offset1 = offset0 + value;
          d0[d0Type] += value;
  
          bar.width = this.xScale(offset1) - this.xScale(offset0);
          bar.x = this.xScale(offset0);
          bar.y = 0;
          bar.offset0 = offset0;
          bar.offset1 = offset1;
        } else if (this.type === 'normalized') {
          let offset0 = d0[d0Type];
          let offset1 = offset0 + value;
          d0[d0Type] += value;
  
          if (total > 0) {
            offset0 = (offset0 * 100) / total;
            offset1 = (offset1 * 100) / total;
          } else {
            offset0 = 0;
            offset1 = 0;
          }
  
          bar.width = this.xScale(offset1) - this.xScale(offset0);
          bar.x = this.xScale(offset0);
          bar.y = 0;
          bar.offset0 = offset0;
          bar.offset1 = offset1;
          value = (offset1 - offset0).toFixed(2) + '%';
        }
  
        if (this.colors.scaleType === 'ordinal') {
          bar.color = this.colors.getColor(d.label);
        } else {
          if (this.type === 'standard') {
            bar.color = this.colors.getColor(value);
            bar.gradientStops = this.colors.getLinearGradientStops(value);
          } else {
            bar.color = this.colors.getColor(bar.offset1);
            bar.gradientStops = this.colors.getLinearGradientStops(bar.offset1, bar.offset0);
          }
        }
  
  
        return bar;
      });
  
      this.updateDataLabels();
    }
  
    updateDataLabels() {
      if (this.type === 'stacked') {
        this.barsForDataLabels = [];
        const section: any = {};
        section.series = this.seriesName;
        const totalPositive = this.series.map(d => d.value).reduce((sum, d) => (d > 0 ? sum + d : sum), 0);
        const totalNegative = this.series.map(d => d.value).reduce((sum, d) => (d < 0 ? sum + d : sum), 0);
        section.totalRight = (totalPositive === 0 || totalPositive === 100) ? totalPositive + '%' : totalPositive.toFixed(2) + '%';
        section.totalLeft = (Math.abs(totalNegative) === 0 || Math.abs(totalNegative) === 100) ? Math.abs(totalNegative) + '%' : Math.abs(totalNegative).toFixed(2) + '%';
        section.x = 0;
        section.y = 0;
        section.widthRight = this.xScale(totalPositive);
        if (Math.abs(totalNegative) > 99) {
          section.widthLeft = this.xScale(totalNegative)-44;
        } else {
          section.widthLeft = this.xScale(totalNegative)-34;
        }
        
        section.height = this.yScale.bandwidth();
        this.barsForDataLabels.push(section);
      } else {
        this.barsForDataLabels = this.series.map(d => {
          const section: any = {};
          section.series = this.seriesName ? this.seriesName : d.label;
          section.total = d.value;
          section.x = this.xScale(0);
          section.y = this.yScale(d.label);
          section.width = this.xScale(section.total) - this.xScale(0);
          section.height = this.yScale.bandwidth();
          return section;
        });
      }
    }
  
    updateTooltipSettings() {
      this.tooltipPlacement = this.tooltipDisabled ? undefined : 'top';
      this.tooltipType = this.tooltipDisabled ? undefined : 'tooltip';
    }
  
    isActive(entry): boolean {
      if (!this.activeEntries) return false;
      const item = this.activeEntries.find(d => {
        return entry.name === d.name && entry.series === d.series;
      });
      return item !== undefined;
    }
  
    getLabel(dataItem): string {
      if (dataItem.label) {
        return dataItem.label;
      }
      return dataItem.name;
    }
  
    trackBy(index, bar) {
      return bar.label;
    }
  
    trackDataLabelBy(index, barLabel) {
      return index + '#' + barLabel.series + '#' + barLabel.total;
    }
  
    click(data: DataItem): void {
      this.select.emit(data);
    }
  }
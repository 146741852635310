import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { LogoutDialogComponent } from '../../components/logout-dialog/logout-dialog.component';
import { MsalService } from '../../external/msal';
import { ApimstService } from '../apimst.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private apimstService: ApimstService,
    private cookieService: CookieService,
    private authService: MsalService,
    private dialog: MatDialog
  ) { }

  logout() {
    this.apimstService.logout()
    .subscribe(
      data => {            
        console.log('Logout MST');

        console.log('Remove cookies');
        this.cookieService.delete('iSession','/');
        this.cookieService.delete('jSessionID','/');
            
        this.authService.logout();        
    });    

  }

  openLogoutModal(): void {
    this.dialog.open(LogoutDialogComponent, {
      panelClass: 'mat-dialog-gen'
    });
  }

}

<app-widget>

    <div class="section-header-block d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <span class="title section-card-title"> {{ 'panels.mixPortfolioSales' | translate }}</span>
        <app-btn-gobernance-info 
          class="ml-2" 
          [endpoint]="endpoint" 
          [endpointParams]="endpointParams"
          *ngIf="hasGobernanceInfo">
        </app-btn-gobernance-info>
        <mat-icon *ngIf="loaded" class="excel-icon ml-2" svgIcon="excel" (click)="exportAsXLSX()"></mat-icon>
      </div>
      <app-option-tab [leftOption]="leftOption" [rightOption]="rightOption" [graphName]="graphName" class="option-tab"></app-option-tab>
    </div>

    <div class = "d-inline-block mt-4 mb-4 w-100">

      <div class="d-flex justify-content-end align-items-center mt-3 w-100">
        <span class="material-icons ml-2 fullscreen-icon text-light mr-auto" (click)="openDialogMixProduct()">fullscreen</span>
        <app-legend class="mr-2" name="labels.portfolioProductMix" color="light-blue" type="round" gradient="v-gradient"></app-legend>
        <app-legend class="mr-2" name="labels.sales" color="dark-blue" type="round" gradient="v-gradient"></app-legend>
      </div>

      <component-loader *ngIf="!loaded" [width]="" [height]="190"></component-loader>

      <div class = "mt-3 ml-6 line-charts-gen y-axis-negative" *ngIf="loaded">
        <app-stacked-horizontal-negative-graph
          [view]="view"
          [scheme]="colorScheme"
          [schemeType]="schemeType"
          [results]="bouquetValues"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [animations]="animations"
          [showDataLabel] = "showDataLabel"
          [dataLabelFormatting] = "dataLabelFormatting"
          [roundDomains] = "roundDomains"
          [trimYAxisTicks]="trimYAxisTicks"
          [maxYAxisTickLength]="maxYAxisTickLength"
          [showGridLines]="showGridLines"
          [customColors]="customColors"
          [tooltipDisabled]="tooltipDisabled"
          [xAxisTickFormatting]="formatPercent">
        </app-stacked-horizontal-negative-graph>

      </div>  
  </div>

  <div class = "d-inline-block mt-3 w-100">

    <div class="d-flex justify-content-end align-items-center mt-3 w-100">
      <span class="material-icons ml-2 fullscreen-icon text-light mr-auto" (click)="openDialogMixChannel()">fullscreen</span>
      <app-legend class="mr-2" name="labels.portfolioChannelMix" color="light-blue" type="round" gradient="v-gradient"></app-legend>
      <app-legend class="mr-2" name="labels.sales" color="dark-blue" type="round" gradient="v-gradient"></app-legend>     
    </div>

    <component-loader *ngIf="!loaded" [width]="" [height]="190"></component-loader>

    <div class = "mt-3 ml-6 line-charts-gen y-axis-negative" *ngIf="loaded">
      <app-stacked-horizontal-negative-graph
          [view]="view"
          [scheme]="colorScheme"
          [schemeType]="schemeType"
          [results]="channelValues"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [animations]="animations"
          [showDataLabel] = "showDataLabel"
          [dataLabelFormatting] = "dataLabelFormatting"
          [roundDomains] = "roundDomains"
          [trimYAxisTicks]="trimYAxisTicks"
          [maxYAxisTickLength]="maxYAxisTickLength"
          [showGridLines]="showGridLines"
          [customColors]="customColors"
          [tooltipDisabled]="tooltipDisabled"
          [xAxisTickFormatting]="formatPercent">
        </app-stacked-horizontal-negative-graph>
    </div>
  </div>
</app-widget>
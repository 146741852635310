import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { utils } from 'protractor';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COLORS } from 'src/app/shared/globals/globals';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';

@Component({
  selector: 'app-sinisters-quality-graphic',
  templateUrl: './sinisters-quality-graphic.component.html',
  styleUrls: ['./sinisters-quality-graphic.component.scss']
})
export class SinistersQualityGraphicComponent implements OnInit {

  view: any[] = [770, 130];

  compareDateLabel: string = "";
  actualDateLabel: string = "";

  // options
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  showGridLines: boolean = true;
  showRefLines: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  yScaleMax: number = 100;
  timeline: boolean = false;
  tooltipDisabled: boolean = false;
  rotateXAxisTicks: boolean = false;

  colorScheme = {
    domain: [COLORS.darkGreen, COLORS.lightGreen, '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;
  monthNames: any;
  averageName: string;
  claimsName: string;
  claimReasonName: string;
  settledInTimeName: string;
  settledInTimeSinistersName: string;
  settledInTimeAvgSinistersName: string;
  qualityClaimsRolling: any = [];
  loaded: boolean = false;
  qualityClaimSelected: any = [];
  xAxisTicksFinal: any = [];
  xAxisTicksValues: any = [];

  data_total: any[] = [];
  data_sheet_names: any[] = [];
  data_filter_details: any[] = [];
  claimReason: string = 'Todas';

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private excelService: ExcelService
  ) { }

  ngOnInit(): void {

    const months = this.translateService.get('abbreviatedMonthNames');
    const average = this.translateService.get('labels.average');
    const claims = this.translateService.get('labels.claims');
    const claimReason = this.translateService.get('labels.claimReason');
    const settledInTime = this.translateService.get('panels.settledInTime');
    const settledInTimeSinisters = this.translateService.get('labels.settledInTimeSinisters');
    const settledInTimeAvgSinisters = this.translateService.get('labels.settledInTimeAvgSinisters');

    forkJoin([months, average, claims, claimReason, settledInTime, settledInTimeSinisters, settledInTimeAvgSinisters]).pipe(takeUntil(this.unsubscribe$)).subscribe(results => {
      this.monthNames = results[0];
      this.averageName = results[1];
      this.claimsName = results[2];
      this.claimReasonName = results[3];
      this.settledInTimeName = results[4];
      this.settledInTimeSinistersName = results[5];
      this.settledInTimeAvgSinistersName = results[6];
    });

    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data.length !== 0) {
            this.filtersValues = data;

            this.resetValues();
            this.retrieveSinistersQualityGraph("cote", this.filtersValues);
          }
        }
      );
  }

  // Method to retrieve the quality claim data
  private retrieveSinistersQualityGraph(type_report: string, filtersValues: any) {
    this.loaded = false;
    this.apimstService.getSinistersSinistersQuality(type_report, filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data) => {
          let rateSeries: any = [];
          let avgRateSeries: any = [];
          let rateToExport: any[] = [this.claimsName + ' (%)'];
          let avgRateToExport: any[] = [this.averageName + ' (%)'];
          let headers: any[] = [''];

          this.data_total = [];
          this.qualityClaimsRolling = [];
          this.xAxisTicksValues = [];
          this.xAxisTicksFinal = [];
          
          this.loaded = true;

          if(data == null) return;

          if (data.length !== 0) {
            this.data_filter_details = data[0].filters;

            for(let month of data) {
              const date = this.monthNames[month.month] + ' ' + month.year.slice(2, 4);
              const rate = month.value ? parseFloat(month.value) : 0;
              const avgRate = month.avg ? parseFloat(month.avg) : 0;

              rateSeries.push({
                name: date,
                value: rate
              });

              avgRateSeries.push({
                name: date,
                value: avgRate
              });

              headers.push(date);
              rateToExport.push(rate);
              avgRateToExport.push(avgRate);

              this.xAxisTicksValues.push(date);
            }

            this.qualityClaimsRolling.push(
              {
                name: this.settledInTimeSinistersName,
                series: rateSeries
              },
              {
                name: this.settledInTimeAvgSinistersName,
                series: avgRateSeries
              }
            );

            // this.getXAxisValues();

            this.data_total.push([[this.settledInTimeName, this.claimReason], [], headers, rateToExport, avgRateToExport]);
          }

          // this.compareDateLabel = `${this.utilsService.getActualDate()['year']}-${this.utilsService.getActualDate()['month']}`;
          // this.actualDateLabel = `${this.utilsService.getActualDate()['year']}-${this.utilsService.getActualDate()['month']}`;

          this.loaded = true;
        },

        error => console.log("An error ocurred while retrieving sinisters claims rolling data: " + error)


      );
  }

  resetValues() {
    this.qualityClaimsRolling = [];
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // Method to format the y axis ticks with %
  yAxisTickFormatting(val) {
    return val.toLocaleString() + "%";
  }

  // Method to get only one x axis value in two 
  // private getXAxisValues(): void {
  //   for (var _i = 0; _i < this.xAxisTicksValues.length; _i += 2) {
  //     this.xAxisTicksFinal.push(this.xAxisTicksValues[_i]);
  //   } 
  // }

   // Method to export data to XSLX
   exportAsXLSX():void {
    this.data_sheet_names = [this.claimsName];
    this.excelService.exportAsExcelFile(this.data_total, this.data_sheet_names,  'sinisters_quality_chart', this.data_filter_details);
  }

}

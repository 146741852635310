import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { trim } from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COLORS } from 'src/app/shared/globals/globals';
import { CancellationHarvest } from 'src/app/shared/models/cancellation-harvest';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { checkboxes } from './checkboxes-value';

@Component({
  selector: 'app-fixed-stock-churn-rate-per-crop-graphic',
  templateUrl: './fixed-stock-churn-rate-per-crop-graphic.component.html',
  styleUrls: ['./fixed-stock-churn-rate-per-crop-graphic.component.scss']
})
export class FixedStockChurnRatePerCropGraphicComponent implements OnInit {

  view: any[] = [580, 110];

  unsubscribe$: Subject<any> = new Subject<any>();
  monthNames: any;
  planName: string;
  month: number;
  year: number;
  filtersValues: any;
  bonusOption: string = 'panels.bonus';
  policyOption: string = 'panels.policy';
  bonusPolicySelectedOption: string = 'panels.bonus';
  loaded: boolean = false;
  graphName: string = 'fixedStockHarvest';

  checkboxes: any;
  checkedCheckboxes: any = [];
  premiumHarvestGraph: any = [];
  certificateHarvestGraph: any = [];
  harvestGraphToPrint: any = [];
  cancellationHarvestData: any;

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';
  animations: boolean = false;
  barPadding: number = 30;
  showGridLines: boolean = true;
  maxYAxisTickLength: number = 4;
  noBarWhenZero: boolean = false;
  rotateXAxisTicks: boolean = false;
  tooltipUnit: string = "%";

  colorScheme = {
    domain: [COLORS.lightBlue]
  };

  pastMonth: string;
  actualMonth: string;
  initialCheckedCheckboxes = [0, 1, 2, 3, 4];

  actualPremiumBar: any = [];
  previousPremiumBar: any = [];
  actualCertificateBar: any = [];
  previousCertificateBar: any = [];
  actualPremiumTotalBar: number = 0;
  actualCertificateTotalBar: number = 0;
  previousPremiumTotalBar: number = 0;
  previousCertificateTotalBar: number = 0;

  previousHarvestBarToPrint: any = [];
  actualHarvesBarToPrint: any = [];

  // Variables for Excel exportation
  data_total: any[] = [];
  data_sheet_names: any;
  data_filter_details: any;

  @Input() excelExportEvent;
  @Output() loadedGraph = new EventEmitter<boolean>();

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private translateService: TranslateService,
    private eventsService: EventsService,
    private excelService: ExcelService
  ) {
    Object.assign(this, { checkboxes });
  }

   ngOnInit(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {

          this.translateService.get('abbreviatedMonthNames').pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              {
                next: data => this.monthNames = data,
                complete: () => {
                  if (data.length !== 0) {
                    this.loaded = false;
                    setTimeout(() => {
                      this.filtersValues = data;
                      this.month = this.filtersValues.filter(d => d.name === 'month')[0].value;
                      this.year = this.filtersValues.filter(d => d.name === 'year')[0].value;
                      this.checkedCheckboxes = [];
                      for (let value of this.initialCheckedCheckboxes) {
                        this.checkedCheckboxes.push(value);
                      }
                      this.retrieveHarvestGraph(this.filtersValues);
                    }, 25);
                  }
                }
              }
            );
        }
      );

      this.eventsService.policiesBonusOption$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const option = data[0];
          const name = data[1];
          if ((option === this.policyOption || option === this.bonusOption) && name === this.graphName) {
            this.bonusPolicySelectedOption = option;
            this.assignHarvestDataToPrint();
          }
        }
      );

      this.excelExportEvent
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data) {
            this.exportDataToExcel();
          }
        }
      );
  }

  // Method to retrieve cancellation haverst graph data
  private retrieveHarvestGraph(filtersValues: any) {

    this.loaded = false;

    this.apimstService.getFixedStockChurnRateData('csch', filtersValues).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (data: Array<CancellationHarvest>) => {
        const cancellationHarvest = data;
        this.premiumHarvestGraph = [];
        this.cancellationHarvestData = [];
        this.certificateHarvestGraph = [];

        if (cancellationHarvest.length !== 0) {
          this.data_filter_details = cancellationHarvest[0].filters;

          // Group array by date
          let helper = {};
          this.cancellationHarvestData = cancellationHarvest.reduce(function (r, o) {
            var key = o.month + '-' + o.year;
            if (!helper[key]) {
              helper[key] = cancellationHarvest.filter(v => (v.month === o.month && v.year === o.year));
              r.push(helper[key]);
            }
            return r;
          }, []);


          for(let month of this.cancellationHarvestData) {
            let premiumValue = 0;
            let certificateValue = 0;
            for (let checked of this.checkedCheckboxes) {
              let premiumData = month.filter(v => parseInt(v.id) === checked);
              if (premiumData.length !== 0) {
                premiumValue += ((premiumData[0].premium || trim(premiumData[0].premium).length>0) ? parseFloat(premiumData[0].premium.replace(/,/g, '')) : 0);
              }

              let certificateData = month.filter(v => parseInt(v.id) === checked);
              if (certificateData.length !== 0) {
                certificateValue += ((certificateData[0].certificate || trim(certificateData[0].certificate).length>0) ? parseFloat(certificateData[0].certificate.replace(/,/g, '')) : 0);
              }
            }

            this.premiumHarvestGraph.push(
              {
                name: this.monthNames[month[0].month] + ' ' + month[0].year.slice(2,4),
                value: parseFloat(premiumValue.toFixed(2))
              }
            );

            this.certificateHarvestGraph.push(
              {
                name: this.monthNames[month[0].month] + ' ' + month[0].year.slice(2,4),
                value: parseFloat(certificateValue.toFixed(2))
              }
            );

          }

          this.pastMonth = this.monthNames[this.month] + ' ' + (this.year-1);
          this.actualMonth = this.monthNames[this.month] + ' ' + this.year;

          this.calculatePreviousActualBar();
          this.assignHarvestDataToPrint();

        }
        this.loaded = true;
        this.loadedGraph.emit(true);
      },

      error => console.log("An error ocurred while retrieving cancellation fixed stock harvest graph data " + error)

    );
  }

  // Method to calculate the graph data when checkboxes clicked
  private calculateGraphicData(monthChecked: number, operation: number) {
    this.harvestGraphToPrint = [];
    for (let month of this.cancellationHarvestData) {

      const selectedData = month.filter(v => parseInt(v.id) === monthChecked);
      let premiumValue = 0;
      let certificateValue = 0;
      if (selectedData.length !== 0) {
        premiumValue = ((selectedData[0].premium || trim(selectedData[0].premium).length>0) ? parseFloat(selectedData[0].premium.replace(/,/g, '')) : 0);
        certificateValue = ((selectedData[0].certificate || trim(selectedData[0].certificate).length>0) ? parseFloat(selectedData[0].certificate.replace(/,/g, '')) : 0);
      }

      let previousPremiumValue = this.premiumHarvestGraph.filter(v => v.name === this.monthNames[month[0].month] + ' ' + month[0].year.slice(2,4));
      let previousCertificateValue = this.certificateHarvestGraph.filter(v => v.name === this.monthNames[month[0].month] + ' ' + month[0].year.slice(2,4));

      operation > 0 ? previousPremiumValue[0].value = parseFloat((previousPremiumValue[0].value + premiumValue).toFixed(2)) : previousPremiumValue[0].value = parseFloat((previousPremiumValue[0].value - premiumValue).toFixed(2));
      operation > 0 ? previousCertificateValue[0].value = parseFloat((previousCertificateValue[0].value + certificateValue).toFixed(2)) : previousCertificateValue[0].value = parseFloat((previousCertificateValue[0].value - certificateValue).toFixed(2));

    }

    this.calculatePreviousActualBar();
    this.assignHarvestDataToPrint();
  }

  // Method to calculate the previous/actual bars
  private calculatePreviousActualBar() {

    this.actualPremiumBar = [];
    this.previousPremiumBar = [];
    this.actualCertificateBar = [];
    this.previousCertificateBar = [];
    this.actualPremiumTotalBar = 0;
    this.actualCertificateTotalBar = 0;
    this.previousPremiumTotalBar = 0;
    this.previousCertificateTotalBar = 0;

    if (this.cancellationHarvestData.length !== 0) {
      let pastMonthData = [];
      let actualMonthData = [];

      if (this.cancellationHarvestData[0][0].month === this.month && this.cancellationHarvestData[0][0].year == (this.year-1)) {
        pastMonthData = this.cancellationHarvestData[0];
      }

      if (this.cancellationHarvestData[this.cancellationHarvestData.length-1][0].month === this.month && this.cancellationHarvestData[this.cancellationHarvestData.length-1][0].year === this.year) {
        actualMonthData = this.cancellationHarvestData[this.cancellationHarvestData.length-1];
      }

      for (let checked of this.checkedCheckboxes) {
        let hasPreviousData = pastMonthData.filter(v => parseInt(v.id) === checked);
        if (hasPreviousData.length !== 0) {
          this.previousPremiumTotalBar += parseFloat(hasPreviousData[0].premium);
          this.previousCertificateTotalBar += parseFloat(hasPreviousData[0].certificate);
        }

        let hasActualData = actualMonthData.filter(v => parseInt(v.id) === checked);
        if (hasActualData.length !== 0) {
          this.actualPremiumTotalBar += parseFloat(hasActualData[0].premium);
          this.actualCertificateTotalBar += parseFloat(hasActualData[0].certificate);
        };
      }

      if (this.checkedCheckboxes.length === 0) {
        pastMonthData = pastMonthData.filter(v => v.id >= 0);
        actualMonthData = actualMonthData.filter(v => v.id >= 0);
        this.previousPremiumTotalBar = pastMonthData.map(v => v.premium).reduce((x, y) => parseFloat(x) + parseFloat(y));
        this.previousCertificateTotalBar = pastMonthData.map(v => v.certificate).reduce((x, y) => parseFloat(x) + parseFloat(y));
        this.actualPremiumTotalBar = actualMonthData.map(v => v.premium).reduce((x, y) => parseFloat(x) + parseFloat(y));
        this.actualCertificateTotalBar = actualMonthData.map(v => v.certificate).reduce((x, y) => parseFloat(x) + parseFloat(y));

        for (let item of pastMonthData) {
          this.previousPremiumBar.push(
            {
              name: item.id,
              color: COLORS['m' + parseInt(item.id)],
              percentage: this.previousPremiumTotalBar > 0 ? parseFloat(item.premium) / this.previousPremiumTotalBar * 100 : 0
            }
          );

          this.previousCertificateBar.push(
            {
              name: item.id,
              color: COLORS['m' + parseInt(item.id)],
              percentage: this.previousCertificateTotalBar > 0 ? parseFloat(item.certificate) / this.previousCertificateTotalBar * 100 : 0
            }
          );
        }

        for (let item of actualMonthData) {
          this.actualPremiumBar.push(
            {
              name: item.id,
              color: COLORS['m' + parseInt(item.id)],
              percentage: this.actualPremiumTotalBar ? parseFloat(item.premium) / this.actualPremiumTotalBar * 100 : 0
            }
          );

          this.actualCertificateBar.push(
            {
              name: item.id,
              color: COLORS['m' + parseInt(item.id)],
              percentage: this.actualCertificateTotalBar ? parseFloat(item.certificate) / this.actualCertificateTotalBar * 100 : 0
            }
          );
        }
      } else {
        for (let checked of this.checkedCheckboxes) {
          let hasPreviousData = pastMonthData.filter(v => parseInt(v.id) === checked);
          if (hasPreviousData.length !== 0) {
            this.previousPremiumBar.push(
              {
                name: hasPreviousData[0].id,
                color: COLORS['m' + parseInt(hasPreviousData[0].id)],
                percentage: this.previousPremiumTotalBar > 0 ? parseFloat(hasPreviousData[0].premium)/this.previousPremiumTotalBar*100 : 0
              }
            );


            this.previousCertificateBar.push(
              {
                name: hasPreviousData[0].id,
                color: COLORS['m' + parseInt(hasPreviousData[0].id)],
                percentage: this.previousCertificateTotalBar > 0 ? parseFloat(hasPreviousData[0].certificate)/this.previousCertificateTotalBar*100 : 0
              }
            );

          }

          let hasActualData = actualMonthData.filter(v => parseInt(v.id) === checked);
          if (hasActualData.length !== 0) {
            this.actualPremiumBar.push(
              {
                name: hasActualData[0].id,
                color: COLORS['m' + parseInt(hasActualData[0].id)],
                percentage: this.actualPremiumTotalBar ? parseFloat(hasActualData[0].premium)/this.actualPremiumTotalBar*100 : 0
              }
            );

            this.actualCertificateBar.push(
              {
                name: hasActualData[0].id,
                color: COLORS['m' + parseInt(hasActualData[0].id)],
                percentage: this.actualCertificateTotalBar ? parseFloat(hasActualData[0].certificate)/this.actualCertificateTotalBar*100 : 0
              }
            );
          };
        }
      }
    }

  }

  // Method to assign data to print according to Bonus/Policy selected option
  private assignHarvestDataToPrint() {
    if (this.bonusPolicySelectedOption === this.bonusOption) {
      this.harvestGraphToPrint = [...this.premiumHarvestGraph];
      this.previousHarvestBarToPrint = this.previousPremiumBar;
      this.actualHarvesBarToPrint = this.actualPremiumBar;
    } else if (this.bonusPolicySelectedOption === this.policyOption) {
      this.harvestGraphToPrint = [...this.certificateHarvestGraph];
      this.previousHarvestBarToPrint = this.previousCertificateBar;
      this.actualHarvesBarToPrint = this.actualCertificateBar;
    }

  }

  // Method for updating checkboxes list
  updateCheckedCheckboxes(id: number) {
    this.harvestGraphToPrint = [];
    const index = this.checkedCheckboxes.indexOf(id);
    if (index !== -1) {
      this.checkedCheckboxes.splice(index, 1);
      this.checkedCheckboxes.sort(function(a, b) {
        return parseInt(a) - parseInt(b);
      });
      if (this.checkedCheckboxes.length === 0) {
        this.calculateAllCheckSelected();
      } else {
        this.calculateGraphicData(id, -1);
      }
    } else {
      if (this.checkedCheckboxes.length === 0) {
        this.premiumHarvestGraph.map(v => v.value = 0);
        this.certificateHarvestGraph.map(v => v.value = 0);
      }
      this.checkedCheckboxes.push(id);
      this.checkedCheckboxes.sort(function(a, b) {
        return parseInt(a) - parseInt(b);
      });
      this.calculateGraphicData(id, 1);
    }

  }

  private calculateAllCheckSelected() {
    this.premiumHarvestGraph = [];
    this.certificateHarvestGraph = [];
    for(let month of this.cancellationHarvestData) {
      let premiumValue = 0;
      let certificateValue = 0;

      month = month.filter(v => v.id >= 0);
      for (let item of month) {
        premiumValue += ((item.premium || trim(item.premium).length>0) ? parseFloat(item.premium.replace(/,/g, '')) : 0);
        certificateValue += ((item.certificate || trim(item.certificate).length>0) ? parseFloat(item.certificate.replace(/,/g, '')) : 0);
      }

      this.premiumHarvestGraph.push(
        {
          name: this.monthNames[month[0].month] + ' ' + month[0].year.slice(2,4),
          value: parseFloat(premiumValue.toFixed(2))
        }
      );

      this.certificateHarvestGraph.push(
        {
          name: this.monthNames[month[0].month] + ' ' + month[0].year.slice(2,4),
          value: parseFloat(certificateValue.toFixed(2))
        }
      );
    }
    this.calculatePreviousActualBar();
    this.assignHarvestDataToPrint();
  }

  // Method to format the right y axis labels
  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value) + '%';
  }

  // Method to retrieve the data to export
  private getDataToExport() {
    const headers = this.harvestGraphToPrint.map(v => v.name);
    const values = this.harvestGraphToPrint.map(v => v.value);

    this.data_total.push(headers, values);
  }

  formatNumber(num: number) {
    const formatedNumber = Intl.NumberFormat("de-DE").format(num);
    return formatedNumber;
  }

  // Method to export the graph data to an Excel file
  private exportDataToExcel() {
    this.data_total = [];
    const title = this.translateService.get('panels.churnRatePerCrop');
    const option = this.translateService.get(this.bonusPolicySelectedOption);
    const months = this.translateService.get('labels.selectedMonths');

    let titleLabel = '';
    let optionLabel = '';
    let monthsSelectedLabel = '';

    forkJoin([title, option, months])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(result => {
      titleLabel = result[0];
      optionLabel = result[1];
      monthsSelectedLabel = result[2];
    });

    const selectedMonths = this.checkedCheckboxes.map(v => {
      return 'M' + v;
    });

    const header = [monthsSelectedLabel, selectedMonths];

    this.data_total.push(header.flat(), []);

    this.getDataToExport();
    this.data_sheet_names = [titleLabel + ' - ' + optionLabel];
    this.excelService.exportAsExcelFile([this.data_total], this.data_sheet_names,  'fixed_stock_harvest_churn_rate_per_crop_chart', this.data_filter_details);
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

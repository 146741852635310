import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { trim } from 'lodash';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { CurrencyService } from 'src/app/shared/services/currency/currency.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-btn-clients',
  templateUrl: './btn-clients.component.html',
  styleUrls: ['./btn-clients.component.scss']
})
export class BtnClientsComponent implements OnInit {

  // Format pipe
  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  clientsActive: boolean = environment.menuVisibility.clients;

  isNegative: boolean = false;

  clientsSubscriptionsUnsubscriptions: any = {};
  clientPortfolio: any = {};
  clientPortfolioVariation: any = {};
  claimPercentage: any = {};
  closingRatioQuantity: any = {};
  closingRatio: any = {};

  currencySymbol: string = "";
  percentageSymbol: string = "%";

  percentagePrevious: string = "labels.py";
  closedName: string = "";
  paidPlusRejectedName: string = "";

  subsUnsubsName: string = "";
  clientPortfolioName: string = "";
  clientPortfolioVariationName: string = "";
  abbreviatedMonthNames: any;

  // Subscription
  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;
  loaded: boolean = false;

  filterYearValue: string = "";
  filterMonthValue: string = "";

  constructor(
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private currencyService: CurrencyService,
    private utilsService: UtilsService,
    private events: EventsService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {

    if (this.clientsActive) {
      this.filtersService.filtersValues$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          filtersValues => {
            this.getTranslations();
            this.filterYearValue = this.getFiltersYear(filtersValues);
            this.filterMonthValue = this.getFiltersMonth(filtersValues);

            if (filtersValues.length !== 0) {
              this.filtersValues = filtersValues;
              this.retrieveHomeClients();
            }
          }
        );

        this.subscribeToCurrency();
    }
  }

  getFiltersMonth(filtersValues: any = []) {
    return filtersValues.filter( filter => filter.name == "month" )[0].value;
  }

  getFiltersYear(filtersValues: any = []) {
    return filtersValues.filter( filter => filter.name == "year" )[0].value;
  }

  getPreviousYear(year: string = "") {
    return String(parseInt(year) - 1);
  }

  getTranslations(): void {
    const subsUnsubsTranslation = this.translateService.get('labels.subsUnsubsClients');
    const clientPortfolioTranslation = this.translateService.get('labels.clientPortfolio');
    const clientPortfolioVariationTranslation = this.translateService.get('labels.clientPortfolioVariation');
    const abbreviatedMonthsTranslation = this.translateService.get('abbreviatedMonthNames');
    const translations = [subsUnsubsTranslation, clientPortfolioTranslation, clientPortfolioVariationTranslation, abbreviatedMonthsTranslation];

    forkJoin(translations)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(results => {
        this.subsUnsubsName = results[0];
        this.clientPortfolioName = results[1];
        this.clientPortfolioVariationName = results[2];
        this.abbreviatedMonthNames = results[3];
      });
  }

  subscribeToCurrency() {
    this.currencyService.currencyInfo$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      currencyInfo => {
        this.currencySymbol = currencyInfo.symbol;
      }
    )
  }

  private retrieveHomeClients() {
    this.loaded = false;

    const subsUnsubsRequest = this.apimstService.getHeaderIndicators("sc", this.filtersValues);
    const clientPortfolioRequest = this.apimstService.getHeaderIndicators("pc", this.filtersValues);
    const clientPortfolioVariationRequest = this.apimstService.getHeaderIndicators("scy", this.filtersValues);
    const requests = [subsUnsubsRequest, clientPortfolioRequest, clientPortfolioVariationRequest]

    forkJoin(requests)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        results => {
          const subsUnsubsData = results[0] ? results[0] : {};
          const clientPortfolioData = results[1] ? results[1] : {};
          const clientPortfolioVariationData = results[2] ? results[2] : {};

          this.clientsSubscriptionsUnsubscriptions = {
            // title: `${this.subsUnsubsName} ${this.abbreviatedMonthNames[this.filterMonthValue]} ${this.filterYearValue}`,
            title: 'kpisHeader.hightLowClients',
            main_value: (subsUnsubsData.value || trim(subsUnsubsData.value).length > 0) ? this.formatPipe.transform(parseFloat(subsUnsubsData.value.replace(/,/g, ''))) : -1,
            trend_value_previous: this.percentagePrevious,
            trend_value: (subsUnsubsData.diff || trim(subsUnsubsData.diff).length > 0) ? this.formatPipe.transform(parseFloat(subsUnsubsData.diff.replace(/-|,/g, ''))) : 0,
            trend_color: (subsUnsubsData.diff_trend || trim(subsUnsubsData.diff_trend).length > 0) ? parseFloat(subsUnsubsData.diff_trend.replace(/,/g, '')) : 0,
            trend_direction: this.utilsService.isNegative(subsUnsubsData.diff) == false ? 1 : 0,
            main_value_suffix: ""
          };

          this.clientPortfolio = {
            // title: `${this.clientPortfolioName} ${this.filterYearValue}`,
            title: 'kpisHeader.clientWalletVariation',
            main_value: (clientPortfolioData.value || trim(clientPortfolioData.value).length > 0) ? this.formatPipe.transform(parseFloat(clientPortfolioData.value.replace(/,/g, ''))) : -1,
            trend_value_previous: this.percentagePrevious,
            // trend_value: undefined,
            trend_value: (clientPortfolioData.diff || trim(clientPortfolioData.diff).length > 0) ? this.formatPipe.transform(parseFloat(clientPortfolioData.diff.replace(/-|,/g, ''))) : 0,
            trend_color: (clientPortfolioData.diff_trend || trim(clientPortfolioData.diff_trend).length > 0) ? parseFloat(clientPortfolioData.diff_trend.replace(/,/g, '')) : 0,
            trend_direction: this.utilsService.isNegative(clientPortfolioData.diff) == false ? 1 : 0,
            main_value_suffix: ""
          };

          this.clientPortfolioVariation = {
            // title: `${this.clientPortfolioVariationName} ${this.filterYearValue} YTD ${this.abbreviatedMonthNames[12]} ${this.getPreviousYear(this.filterYearValue)}`,
            title: 'kpisHeader.portfolioClients',
            main_value: (clientPortfolioVariationData.value || trim(clientPortfolioVariationData.value).length > 0) ? this.formatPipe.transform(parseFloat(clientPortfolioVariationData.value.replace(/,/g, ''))) : -1,
            trend_value_previous: this.percentagePrevious,
            trend_value: (clientPortfolioVariationData.diff || trim(clientPortfolioVariationData.diff).length > 0) ? this.formatPipe.transform(parseFloat(clientPortfolioVariationData.diff.replace(/-|,/g, ''))) : 0,
            trend_color: (clientPortfolioVariationData.diff_trend || trim(clientPortfolioVariationData.diff_trend).length > 0) ? parseFloat(clientPortfolioVariationData.diff_trend.replace(/,/g, '')) : 0,
            trend_direction: this.utilsService.isNegative(clientPortfolioVariationData.diff) == false ? 1 : 0,
            main_value_suffix: ""
          };

          this.loaded = true;
        }
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex align-items-center">
            <span class="title section-card-title">{{ 'panels.clientsStockByGeo' | translate }}</span>
            <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams"
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </div>
    </div>

    <div class="d-flex justify-content-center">
        <app-clients-stock-by-geo-map></app-clients-stock-by-geo-map>
    </div>

</app-widget>

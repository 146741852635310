import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, forkJoin, Subject, Subscription } from 'rxjs'
import { MonthlySalesGraphDialogComponent } from 'src/app/shared/dialogs/monthly-sales-graph-dialog/monthly-sales-graph-dialog.component';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { COLORS } from 'src/app/shared/globals/globals';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { MonthlySale } from 'src/app/shared/models/monthly-sale';
import { TranslateService } from '@ngx-translate/core';
import { shareReplay, takeUntil } from 'rxjs/operators'
import _, { trim } from 'lodash';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { environment } from '../../../../../environments/environment';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';

@Component({
  selector: 'app-monthly-sale-graph',
  templateUrl: './monthly-sale-graph.component.html',
  styleUrls: ['./monthly-sale-graph.component.scss']
})
export class MonthlySaleGraphComponent implements OnInit, OnDestroy {

  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "sale-detail--monthly-sale";

  loaded: boolean = false;

  filtersValues: any;
  actualOption: string = 'panels.bonus';
  customColors: any = [];
  planName: string;
  averageName: string;
  monthNames: any;
  totalName: string;
  titleName: string;
  policyName: string;
  bonusName: string;

  legendsMonthlySale: any = [];

  // Options for chart creation
  view = [590, 160];
  modalView = [1210, 580];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  legendTitle: string = '';
  legendPosition: string = '';
  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  yLeftAxisScaleFactor: string = '';
  yRightAxisScaleFactor: string = '';
  yRightAxisTickFormatting: string = '';
  showGridLines: boolean = true;
  innerPadding: string = '10%';
  animations: boolean = false;
  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  barPadding: number = 8;
  tooltipDisabled: boolean = false;
  schemeType: string = "ordinal";
  roundDomains: boolean = true;
  noBarWhenZero: boolean = true;
  barChart: any[] = [];
  lineChartSeries: any[] = [];
  lineChartScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkYellow, COLORS.lightPurple, '#0099cc', '#08ded1']
  };
  comboBarScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkBlue, COLORS.lightBlue, '#085da6', '#0869aa']
  };
  showTotalInTooltip: boolean = true;

  // Variables for saving the filter option
  optionTabPolicy = 'panels.policy';
  optionTabBonus = 'panels.bonus';
  graphName = 'salesDetails';

  selectedOption: any[] = [];

  // Variables for saving the charts data
  bonusVerticalBars: any[] = [];
  policyVerticalBars: any[] = [];
  bonusHorizontalBars: any[] = [];
  policyHorizontalBars: any[] = [];
  legendLabels: any[] = [];
  bonusDataTotal: any[] = [];
  policyDataTotal: any[] = [];
  bonusDataSheetNames: any[] = [];
  policyDataSheetNames: any[] = [];

  // Subscription
  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();

  colorList: any = [COLORS.darkBlue, COLORS.lightBlue, COLORS.lightOrange, COLORS.lightestBlue, COLORS.darkGreen, COLORS.darkYellow, COLORS.m6, COLORS.m8, COLORS.m12, COLORS.darkBlue, COLORS.lightBlue, COLORS.lightPurple, COLORS.darkestGreen, COLORS.lightGreen];
  colorListCSS: any = ['dark-blue', 'light-blue', 'light-orange', 'lightest-blue', 'dark-green', 'dark-yellow', 'm6', 'm8', 'm12', 'dark-blue', 'light-blue', 'dark-purple', 'darkest-green', 'light-green'];

  // Variables to store data to export
  data_total: any[] = [];
  data_sheet_names: any[] = [];
  data_filter_details: any[] = [];

  // Input to determine the value of the chosen option
  @Input() policyBonus: string;
  @Output() monthlyLegend = new EventEmitter<any>();

  // Default constructor
  constructor(
    private dialog: MatDialog,
    private apimstService: ApimstService,
    private eventsService: EventsService,
    private filtersService: FiltersService,
    private translateService: TranslateService,
    private excelService: ExcelService,
    private atlasHelper: AtlasHelperService,
    private i18n: I18nService
  ) { }

  // OnInit
  ngOnInit(): void {
    this.subscribeToActiveLanguage();

    const filters$ = this.filtersService.filtersValues$;
    const selectedOption$ = this.eventsService.optionTabMultipleChanged$;

    combineLatest([filters$, selectedOption$])
      .pipe(shareReplay(), takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data[0].length !== 0 && data[1]['graphName'] === this.graphName) {
            this.filtersValues = data[0];
            if (this.filtersValues.length == 0) return;
            this.resetVariables();

            this.selectedOption = data[1]['id'];
            this.assignData();
          }
        }
      )

    this.eventsService.policiesBonusOption$.subscribe(
      data => {
        const option = data[0];
        const name = data[1];
        if ((option === this.optionTabBonus || option === this.optionTabPolicy) && name === this.graphName) {
          this.actualOption = option;
          this.assignData();
        }
      }
    );

    this.eventsService.monthlySaleGraphicCustomColor$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.customColors = data;
        this.loaded = true;
      });
  }

  // Method for retrieving the data information for the monthly graph
  private retrieveMonthlyGraphData(typeData: string, filters: any, dimension: any[]) {
    this.loaded = false;
    this.getTranslations();
    this.subscription = this.apimstService.getMonthlySales(typeData, filters, dimension).subscribe(
      data => {
        let monthlySale: MonthlySale = data;

        // Variables initialization
        let verticalBarsData = [];
        let averageData = [];
        let targetData = [];
        let horizontalBarsData = [];
        let averageToExport = [];
        let targetToExport = [];
        let headersToExport = [];

        // Add the first column values for the header, average and target data
        headersToExport.push('');
        averageToExport.push(this.averageName);
        targetToExport.push(this.planName);
        this.data_filter_details = monthlySale.filters;

        // Data verification and storage
        if (monthlySale.hasOwnProperty('details')) {
          if (monthlySale.details.length > 0) {
            for (let month of monthlySale.details) {  // Retrieve the value of the data metric for each month
              let series = [];
              let accumulatedTarget = 0;
              for (let type of month.types) {
                series.push({
                  'name': type.name,
                  'value': (type.value || trim(type.value).length > 0) ? parseFloat(type.value.replace(/,/g, '')) : 0
                });

                // Store the data metric name to display in the legend
                if (!this.legendLabels.includes(type.name)) {
                  this.legendLabels.push(type.name);
                }

                accumulatedTarget += (type.target || trim(type.target).length > 0) ? parseFloat(type.target.replace(/,/g, '')) : 0;

              }

              const monthValue = this.monthNames[month.month] + month.year.substring(2, 4);
              const averageValue = (data.avg || trim(data.avg).length > 0) ? parseFloat(data.avg.replace(/,/g, '')) : 0;

              verticalBarsData.push({
                'name': monthValue,
                'series': series
              });

              averageData.push({
                'name': monthValue,
                'value': averageValue
              });

              targetData.push({
                'name': monthValue,
                'value': accumulatedTarget
              });

              headersToExport.push(monthValue);
              averageToExport.push(averageValue);
              targetToExport.push(accumulatedTarget);

            }
          }
        }

        // Store the data for the horizontal lines
        horizontalBarsData.push(
          {
            'name': this.averageName,
            'series': averageData
          },
          {
            'name': this.planName,
            'series': targetData
          }
        );

        const groupedData = this.extractDataToExport(verticalBarsData, averageToExport, targetToExport, headersToExport);

        // Bonus data (p -> premium)
        if (typeData === 'p') {
          this.bonusVerticalBars = verticalBarsData;
          this.bonusHorizontalBars = horizontalBarsData;
          this.bonusDataTotal = groupedData;
          this.bonusDataSheetNames = [this.titleName + ' - ' + this.bonusName];
        } else if (typeData === 'c') { // Policy data (c -> certificate)
          this.policyVerticalBars = verticalBarsData;
          this.policyHorizontalBars = horizontalBarsData;
          this.policyDataTotal = groupedData;
          this.policyDataSheetNames = [this.titleName + ' - ' + this.policyName];
        }

        this.assignDataToPrint();

        const legendData = {
          child: 'monthly',
          legends: this.legendLabels
        }
        this.monthlyLegend.emit(legendData);
        this.legendLabels = [];
      },
      error => console.log("An error ocurred while retrieving monthly graph data: " + error));
  }

  // Method to extract the data to export to the Excel file
  private extractDataToExport(verticalBarsData: any[], averageToExport: any[], targetToExport: any[], headersToExport: any[]): any[] {
    let dataToExport = [];

    // Retrieve the value to export for each data metric
    for (let label of this.legendLabels) {
      let series = [];
      series.push(label);
      for (let item of verticalBarsData) {
        let filteredData = item.series.filter(v => v.name === label);
        let value = 0;
        if (filteredData.length !== 0) {
          value = filteredData[0].value;
        }
        series.push(value);
      }

      dataToExport.push({
        name: label,
        series: series
      });

    }

    // Add average and target data to array
    dataToExport.push(
      {
        name: this.averageName,
        series: averageToExport
      },
      {
        name: this.planName,
        series: targetToExport
      }
    );

    // Group the data
    let groupedData = [headersToExport];

    for (let item of dataToExport) {
      groupedData.push(item.series);
    }

    return [groupedData];

  }

  // Method to assign data
  private assignData() {
    if (this.actualOption === this.optionTabPolicy && this.policyHorizontalBars.length === 0 && this.policyVerticalBars.length === 0) {
      this.retrieveMonthlyGraphData('c', this.filtersValues, this.selectedOption); // (c -> certificate)
    } else if (this.actualOption === this.optionTabBonus && this.bonusHorizontalBars.length === 0 && this.bonusVerticalBars.length === 0) {
      this.retrieveMonthlyGraphData('p', this.filtersValues, this.selectedOption); // (p -> premium)
    } else {
      this.assignDataToPrint();
    }
  }

  // Method to assign the data to print
  private assignDataToPrint() {
    if (this.actualOption === this.optionTabPolicy) {
      this.barChart = this.policyVerticalBars;
      this.lineChartSeries = this.policyHorizontalBars;
      this.data_total = this.policyDataTotal;
      this.data_sheet_names = this.policyDataSheetNames;
    } else if (this.actualOption === this.optionTabBonus) {
      this.barChart = this.bonusVerticalBars;
      this.lineChartSeries = this.bonusHorizontalBars;
      this.data_total = this.bonusDataTotal;
      this.data_sheet_names = this.bonusDataSheetNames;
    }
  }

  // Method to open the dialog with the expanded chart
  openDialog() {
    this.dialog.open(MonthlySalesGraphDialogComponent, {
      data: {
        sectionTitle: 'panels.monthlySale',
        view: this.modalView,
        showXAxis: this.showXAxis,
        showYAxis: this.showYAxis,
        gradient: this.gradient,
        showLegend: this.showLegend,
        legendTitle: this.legendTitle,
        legendPosition: this.legendPosition,
        showXAxisLabel: this.showXAxisLabel,
        showYAxisLabel: this.showYAxisLabel,
        yAxisTickFormatting: this.yAxisTickFormatting,
        yLeftAxisScaleFactor: this.yLeftAxisScaleFactor,
        yRightAxisScaleFactor: this.yRightAxisScaleFactor,
        yRightAxisTickFormatting: this.yRightAxisTickFormatting,
        showGridLines: this.showGridLines,
        showRightYAxisLabel: this.showRightYAxisLabel,
        innerPadding: this.innerPadding,
        xAxisLabel: this.xAxisLabel,
        yAxisLabel: this.yAxisLabel,
        yAxisLabelRight: this.yAxisLabelRight,
        tooltipDisabled: this.tooltipDisabled,
        roundDomains: this.roundDomains,
        animations: this.animations,
        noBarWhenZero: this.noBarWhenZero,
        barChart: this.barChart,
        lineChartSeries: this.lineChartSeries,
        lineChartScheme: this.lineChartScheme,
        comboBarScheme: this.comboBarScheme,
        schemeType: this.schemeType,
        customColors: this.customColors,
        dialogLegends: this.legendsMonthlySale
      },
      panelClass: 'dialog-monthly-sales'
    })
  }

  // Method to reset the data storage variables
  private resetVariables() {
    this.bonusVerticalBars = [];
    this.policyVerticalBars = [];
    this.bonusHorizontalBars = [];
    this.policyHorizontalBars = [];
    this.legendLabels = [];
    this.data_total = [];
    this.data_sheet_names = [];
  }

  // Method to format the right y axis labels
  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value);
  }

  // Method to export data to XSLX
  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.data_total, this.data_sheet_names, 'monthly_sale_chart', this.data_filter_details);
  }

  getTranslations(): void {
    const plan = this.translateService.get('labels.plan');
    const average = this.translateService.get('labels.average');
    const total = this.translateService.get('labels.total');
    const title = this.translateService.get('panels.monthlySale');
    const policy = this.translateService.get('panels.policy');
    const bonus = this.translateService.get('panels.bonus');

    this.subscription = forkJoin([plan, average, total, title, policy, bonus]).subscribe(results => {
      this.planName = results[0];
      this.averageName = results[1];
      this.totalName = results[2];
      this.titleName = results[3];
      this.policyName = results[4];
      this.bonusName = results[5];
    });

    this.subscription = this.translateService.get('abbreviatedMonthNames').subscribe(data => this.monthNames = data);

  }

  subscribeToActiveLanguage() {
    this.i18n.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: language => {
          this.activeLang = language;
          this.updateAtlasInfoParams(this.pageId, this.activeLang);
        }
      })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<div class="data-discovery-container">
    <app-dg-header [headerTitle]="headerTitle" [routerLink]="routerLink" [hasSearchButton]="false"></app-dg-header>
    
    <div *ngIf="loaded" class="cards-container">
        <app-data-discovery-card *ngFor="let item of reports" [folder]="item"></app-data-discovery-card>
    </div>

    <div class="background"></div>

    <div class="app-loader" *ngIf="!loaded">
        <base-loader></base-loader>
    </div>
</div>

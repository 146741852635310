import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../services/events/events.service';
import { FiltersService } from '../../services/filters/filters.service';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss'],
})
export class FilterButtonComponent implements OnInit {

  showFilters() {
    this.filtersService.updateBlockVisibility(true);
  };

  constructor( private filtersService: FiltersService ) { }

  ngOnInit(): void { }

}
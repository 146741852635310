<component-loader [width]="" [height]="250" *ngIf="!loaded"></component-loader>
<div class="d-flex justify-content-end" *ngIf="loaded">
    <app-legend 
        name={{legend.name}} 
        color={{legend.color}} 
        type={{legend.type}} 
        gradient={{legend.gradient}}
        *ngFor="let legend of legends">
    </app-legend>
</div>
<div class="polar-chart-gen polar-chart-last-line-dots d-flex justify-content-center mt-2" *ngIf="loaded">
    <ngx-charts-polar-chart
        [view]="view"
        [scheme]="colorScheme"
        [legend]="legend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [labelTrim]="labelTrim"
        [results]="multi"
        (select)="onSelect($event)">
    </ngx-charts-polar-chart>
</div>
<div class="mt-3 d-flex flex-wrap">
    <div class="col-4">
        <component-loader [width]="150" [height]="69" class="mt-3 d-block" *ngIf="!loadedClientsPolicyCancellationPlusOne"></component-loader>
        <app-small-kpi 
            [title]="clientsPolicyCancellationPlusOneTitle"
            [mainValue]="clientsPolicyCancellationPlusOneMainValue" 
            [percentage] ="clientsPolicyCancellationPlusOnePercentage" 
            [trend]="clientsPolicyCancellationPlusOneTrend"
            *ngIf="loadedClientsPolicyCancellationPlusOne">
        </app-small-kpi>
    </div>
    <div class="col-4">
        <component-loader [width]="150" [height]="69" class="mt-3 d-block" *ngIf="!loadedRetainedCustomersNumber"></component-loader>
        <app-small-kpi 
            [title]="retainedCustomersNumberTitle"
            [mainValue]="retainedCustomersNumberMainValue" 
            [percentage] ="retainedCustomersNumberPercentage" 
            [trend]="retainedCustomersNumberTrend"
            *ngIf="loadedRetainedCustomersNumber">
        </app-small-kpi>
    </div>
    <div class="col-4">
        <component-loader [width]="150" [height]="69" class="mt-3 d-block" *ngIf="!loadedRetentionsByClientNumber"></component-loader>
        <app-small-kpi 
            [title]="retentionsByClientNumberTitle"
            [mainValue]="retentionsByClientNumberMainValue" 
            [percentage] ="retentionsByClientNumberPercentage" 
            [trend]="retentionsByClientNumberTrend"
            *ngIf="loadedRetentionsByClientNumber">
        </app-small-kpi>
    </div>
</div>
import { Component, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EventEmitter } from 'protractor';
import { EventsService } from '../../services/events/events.service';

@Component({
  selector: 'app-dropdown-multiple',
  templateUrl: './dropdown-multiple.component.html',
  styleUrls: ['./dropdown-multiple.component.scss']
})
export class DropdownMultipleComponent implements OnInit {

  /* Multiselect */
  @Input() dropdownList;
  @Input() placeholder;
  @Input() title;
  @Input() formName;

  @Input() selectedItems;
  @Input() enableCheckAll: boolean = true;
  @Input() limitSelection;

  // selectedItems = [];
  dropdownSettings: IDropdownSettings = {};

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todos',
      unSelectAllText: 'Deseleccionar todos',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      searchPlaceholderText: "Buscar",
      maxHeight: 120,
      enableCheckAll: this.enableCheckAll
    };

    if (this.limitSelection !== undefined) {
      this.dropdownSettings.limitSelection = this.limitSelection;
    }

  }

  onItemSelect(item: any) {
    // Add form name to item selected
    item.form_action = "select";
    item.form_name = this.formName;

    // Emit event with info (item_id, item_text, form_name)
    this.events.filterDropdownEvent$.emit(item);
    this.events.multiDropdownEvent$.next({ item: item })
  }

  onSelectAll(items: any) {
    // Add form name & action to all items
    if (this.formName === 'cancellationReasons') {
      let itemIDs = [];
      let item: any = {};
      for (let item of items) {
        itemIDs.push(item.item_id);
      }
      item.form_action = "selectAll";
      item.form_name = this.formName;
      item.item_id = itemIDs
      this.events.filterDropdownEvent$.emit(item);
    } else {
      for (let item of items) {
        item.form_action = "select";
        item.form_name = this.formName;
        this.events.filterDropdownEvent$.emit(item);
      }
    }

    let item: any = {};
    item.form_action = "selectAll";
    item.form_name = this.formName;
    this.events.multiDropdownEvent$.next({ item: item, items: items })
  }

  onItemDeSelect(item: any) {
    // Add form name to item selected
    item.form_action = "deselect";
    item.form_name = this.formName;

    // Emit event with info (item_id, item_text, form_name)
    this.events.filterDropdownEvent$.emit(item);
    this.events.multiDropdownEvent$.next({ item: item })
  }

  onDeSelectAll(item: any) {
    item.form_action = "deselectAll";
    item.form_name = this.formName;
    this.events.filterDropdownEvent$.emit(item);
    this.events.multiDropdownEvent$.next({ item: item })
  }

  constructor(
    private events: EventsService
  ) { }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'component-loader',
  templateUrl: './component-loader.component.html',
  styleUrls: ['./component-loader.component.scss']
})
export class ComponentLoaderComponent implements OnInit {

  @Input() width;
  @Input() height;
  @Input() lightStyle: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  getStyles() {

    var styles;

    if( this.width == '' || this.width == null || this.width == '0' || this.width == 0) {
      styles = {
        'width.%': 100,
        'height.px': this.height ? this.height: '',
      }
    } else {
      styles = {
        'width.px': this.width ? this.width: '',
        'height.px': this.height ? this.height: '',
      }
    }

    return styles;
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-technical-main-information',
  templateUrl: './technical-main-information.component.html',
  styleUrls: ['./technical-main-information.component.scss']
})
export class TechnicalMainInformationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

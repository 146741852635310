<ngx-charts-chart
  [view]="[width, height]"
  [showLegend]="legend"
  [legendOptions]="legendOptions"
  [activeEntries]="activeEntries"
  [animations]="animations"
>
  <svg:g [attr.transform]="transform" class="bar-chart chart">
    <svg:g
      ngx-charts-x-axis
      *ngIf="xAxis"
      [xScale]="xScale"
      [dims]="dims"
      [showLabel]="showXAxisLabel"
      [labelText]="xAxisLabel"
      [tickFormatting]="xAxisTickFormatting"
      [ticks]="xAxisTicks"
      [rotateTicks]="rotateXAxisTicks"
      (dimensionsChanged)="updateXAxisHeight($event)"
    ></svg:g>
    <svg:g
      ngx-charts-y-axis
      *ngIf="yAxis"
      [yScale]="yScale"
      [dims]="dims"
      [yOrient]="yOrientLeft"
      [showGridLines]="showGridLines"
      [showLabel]="showYAxisLabel"
      [labelText]="yAxisLabel"
      [tickFormatting]="yAxisTickFormatting"
      (dimensionsChanged)="updateYAxisWidth($event)"
    ></svg:g>
    <svg:g
      ngx-charts-y-axis
      *ngIf="rightYAxis"
      [yScale]="yScaleLine"
      [dims]="dims"
      [yOrient]="yOrientRight"
      [showGridLines]="showGridLines"
      [showLabel]="showRightYAxisLabel"
      [labelText]="yAxisLabelRight"
      [tickFormatting]="yRightAxisTickFormatting"
      (dimensionsChanged)="updateYAxisWidth($event)"
    ></svg:g>
    <svg:g
      ngx-combo-charts-series-vertical
      [xScale]="xScale"
      [yScale]="yScale"
      [colors]="colors"
      [series]="results"
      [seriesLine]="lineChart"
      [dims]="dims"
      [tooltipDisabled]="tooltipDisabled"
      [activeEntries]="activeEntries"
      [noBarWhenZero]="noBarWhenZero"
      [gradient]="gradient"
      [showDataLabel]="showVerticalDataLabel"
      [dataLabelFormatting]="verticalDataLabelFormatting"
      [animations]="verticalAnimations"
      [roundEdges]="verticalRoundEdges"
      [legend]="verticalLegend"
      [legendTitle]="verticalLegendTitle"
      [legendPosition]="verticalLegendPosition"
      [showXAxisLabel]="showVerticalXAxisLabel"
      [showYAxisLabel]="showVerticalYAxisLabel"
      [xAxisLabel]="verticalXAxisLabel"
      [yAxisLabel]="verticalYAxisLabel"
      (bandwidth)="updateLineWidth($event)"
    ></svg:g> 
  </svg:g>

  <svg:g [attr.transform]="transform" class="line-chart chart">
    <svg:g>
      <svg:g *ngIf="!hideHorizontalLine">
        <svg:g *ngFor="let series of lineChart">
          <svg:g
            ngx-charts-line-series
            [xScale]="xScaleLine"
            [yScale]="rightYAxis ? yScaleLine : yScale"
            [colors]="colorsLine"
            [data]="series"
            [activeEntries]="activeEntries"
            [scaleType]="scaleType"
            [curve]="curve"
            [rangeFillOpacity]="rangeFillOpacity"
            [animations]="animations"
          />
        </svg:g>
      </svg:g>
      <svg:g
        ngx-charts-tooltip-area
        *ngIf="!tooltipDisabled"
        [dims]="dims"
        [xSet]="xSet"
        [xScale]="xScaleLine"
        [yScale]="rightYAxis ? yScaleLine : yScale"
        [results]="combinedSeries"
        [colors]="colorsLine"
        [tooltipDisabled]="tooltipDisabled"
        (hover)="updateHoveredVertical($event)"
      />
      <svg:g *ngFor="let series of lineChart">
        <svg:g
          ngx-charts-circle-series
          [xScale]="xScaleLine"
          [yScale]="rightYAxis ? yScaleLine : yScale"
          [colors]="colorsLine"
          [data]="series"
          [scaleType]="scaleType"
          [visibleValue]="hoveredVertical"
          [activeEntries]="activeEntries"
          [tooltipDisabled]="tooltipDisabled"
        />
      </svg:g>
    </svg:g>
  </svg:g>
</ngx-charts-chart>
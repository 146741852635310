import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { sinistersClaimsIndicator } from 'src/app/shared/models/sinisters-claims-indicator';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-sinisters-claims-percentage',
  templateUrl: './sinisters-claims-percentage.component.html',
  styleUrls: ['./sinisters-claims-percentage.component.scss']
})
export class SinistersClaimsPercentageComponent implements OnInit {

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;

  sinistersClaimsPercentageLoaded: boolean = false;
  regulatorClaimsPercentageLoaded: boolean = false;
  judicialClaimsPercentageLoaded: boolean = false;

  sinistersClaimsPercentage: sinistersClaimsIndicator = {};
  regulatorClaimsPercentage: sinistersClaimsIndicator = {};
  judicialClaimsPercentage: sinistersClaimsIndicator = {};

  percentagePrevious: string = "labels.pm";

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
  ) { }

  ngOnInit(): void {

    this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      filtersValues => {
        if (filtersValues.length !== 0) {
          this.filtersValues = filtersValues;
          this.sinistersClaimsPercentageLoaded = false;
          this.regulatorClaimsPercentageLoaded = false;
          this.judicialClaimsPercentageLoaded = false;

          this.retrieveSinistersClaimsPercentages('scp', this.filtersValues);
          this.retrieveSinistersClaimsPercentages('scr', this.filtersValues);
        }
      }
    );

  }

  retrieveSinistersClaimsPercentages(type_report: string, filtersValues: any): void {
    this.apimstService.getSinistersQualityIndicators(type_report, filtersValues)
      .subscribe({
        next: data => {
          switch(type_report) {
            
            // Porcentaje de reclamaciones de siniestros
            case 'scp':
              this.createIndicator(this.sinistersClaimsPercentage, data);
              this.sinistersClaimsPercentageLoaded = true;
              break;
              
            // Porcentaje de reclamaciones regulador
            case 'scr':
              this.createIndicator(this.regulatorClaimsPercentage, data);
              this.regulatorClaimsPercentageLoaded = true;
              break;

            default:
              break;  
          }

        }
      })
  }

  createIndicator(destinationData: any, sourceData: any): void {
    destinationData.main_value = sourceData.value ? sourceData.value : "0";
    destinationData.main_value_unit = '%';
    destinationData.percentage_previous = this.percentagePrevious;
    destinationData.percentage = sourceData.diff;
    destinationData.trend = sourceData.diff_trend ? parseInt(sourceData.diff_trend) : 0;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

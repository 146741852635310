import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COLORS } from 'src/app/shared/globals/globals';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { bubbleData } from './data';

@Component({
  selector: 'app-santander-vs-zs-segmentation-graphic',
  templateUrl: './santander-vs-zs-segmentation-graphic.component.html',
  styleUrls: ['./santander-vs-zs-segmentation-graphic.component.scss']
})
export class SantanderVsZsSegmentationGraphicComponent implements OnInit {

  bubbleData: any[];
  view: any[] = [720, 550];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  yAxisLabel: string = 'Segmentación Z';
  showYAxisLabel: boolean = false;
  xAxisLabel: string = 'Segmentación S';
  maxRadius: number = 40;
  minRadius: number = 15;
  yScaleMin: number = 70;
  yScaleMax: number = 85;
  xAxisTicks: any = ['Platinum', 'Gold', 'Silver', 'Bronze', 'Otro'];
  yAxisTicks: any = ['Premier', 'Preferente', 'Pyme', 'Otros'];
  colorScheme = {
    domain: [COLORS.primary]
  };

  unsubscribe$: Subject<any> = new Subject<any>();
  graphLoaded: boolean = false;
  results: any = [];

  constructor(
    private eventsService: EventsService,
    private apimstService: ApimstService
  ) {
    Object.assign(this, { bubbleData });
   }

  ngOnInit(): void {
  }

  subscriptionToSantanderSegmentationDropdown() {
    this.eventsService.clientsSantanderSegmentation$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  subscriptionToZurichSegmentationDropdown() {
    this.eventsService.clientsZurichSegmentation$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  retrieveSantanderVsZurichSegmentation(filtersValues: any): void {
    this.apimstService.getClientsOccupation("csb", filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data: []) => {
          this.graphLoaded = true;
          if( data == null) return;
          this.results = this.createGraph(data);
        }
      })
  }

  createGraph(rawInfo: any[] = []) {
    let graphFinal: any[] = [];
    for (let element of rawInfo) {
      graphFinal.push({
        name: element.name,
        value: element.percentage ? parseFloat(element.percentage) : 0
      })
    }
    return graphFinal;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

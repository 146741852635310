<div class="mt-3">
    <component-loader [width]="" [height]="540" *ngIf="!loaded"></component-loader>
    <highcharts-chart 
        class="map-gen" 
        [Highcharts]="Highcharts" 
        [constructorType]="chartConstructor"
        [options]="chartOptions" 
        style="width: 100%; height: 540px; display: block;"
        *ngIf="loaded && isValidMap">
    </highcharts-chart>
</div>
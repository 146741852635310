import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { Subscription, Subject, forkJoin } from 'rxjs';
import { MonthlySalePieGraph } from 'src/app/shared/models/monthly-sale-pie-graph';
import { MonthlySalePieGraphDetails } from 'src/app/shared/models/monthly-sale-pie-grahp-details';
import { NumberPipe } from 'src/app/shared/pipes/number.pipe';
import { takeUntil } from 'rxjs/operators'
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { TranslateService } from '@ngx-translate/core';
import { trim } from 'lodash';

@Component({
  selector: 'app-monthly-sale-table',
  templateUrl: './monthly-sale-table.component.html',
  styleUrls: ['./monthly-sale-table.component.scss']
})
export class MonthlySaleTableComponent implements OnInit, OnDestroy {

  // Variable to save the data for the table
  bouquetsName: string = 'panels.branch';
  bouquetsToPrint: any = [];
  bouquetsTotalToPrint: any = [];

  channelsName: string = 'panels.channel';
  channelsToPrint: any = [];
  channelsTotalToPrint: any = [];

  headers: any = [];
  discreetAccumulatedOptionSelected: string = 'panels.discreet';
  bonusPolicyOptionSelected: string = 'panels.bonus';

  bonusOption: string = 'panels.bonus';
  policyOption: string = 'panels.policy';
  discreetOption: string = 'panels.discreet';
  accumulatedOption: string = 'panels.accumulated';

  month: number;
  year: number;
  filtersValues: any;
  loadedBouquets: boolean = false;
  loadedChannels: boolean = false;
  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();
  graphName: string = 'yearSales';
  totalName: string;
  planName: string;
  bouquetName: string;
  channelName: string;

  bouquetsPremiumDiscreet: any;
  bouquetsPremiumAccumulated: any;
  bouquetsCertificateDiscreet: any;
  bouquetsCertificateAccumulated: any;

  channelsPremiumDiscreet: any;
  channelsPremiumAccumulated: any;
  channelsCertificateDiscreet: any;
  channelsCertificateAccumulated: any;

  totalBouquetsPremiumDiscreet: any;
  totalBouquetsPremiumAccumulated: any;
  totalBouquetsCertificateDiscreet: any;
  totalBouquetsCertificateAccumulated: any;

  totalChannelsPremiumDiscreet: any;
  totalChannelsPremiumAccumulated: any;
  totalChannelsCertificateDiscreet: any;
  totalChannelsCertificateAccumulated: any;

  bouquetsPremiumDiscreetCR: any;
  bouquetsPremiumAccumulatedCR: any;
  bouquetsCertificateDiscreetCR: any;
  bouquetsCertificateAccumulatedCR: any;

  channelsPremiumDiscreetCR: any;
  channelsPremiumAccumulatedCR: any;
  channelsCertificateDiscreetCR: any;
  channelsCertificateAccumulatedCR: any;

  totalBouquetsPremiumDiscreetCR: any;
  totalBouquetsPremiumAccumulatedCR: any;
  totalBouquetsCertificateDiscreetCR: any;
  totalBouquetsCertificateAccumulatedCR: any;

  totalChannelsPremiumDiscreetCR: any;
  totalChannelsPremiumAccumulatedCR: any;
  totalChannelsCertificateDiscreetCR: any;
  totalChannelsCertificateAccumulatedCR: any;

  bouquetsPremiumDiscreetOM: any;
  bouquetsPremiumAccumulatedOM: any;
  bouquetsCertificateDiscreetOM: any;
  bouquetsCertificateAccumulatedOM: any;

  channelsPremiumDiscreetOM: any;
  channelsPremiumAccumulatedOM: any;
  channelsCertificateDiscreetOM: any;
  channelsCertificateAccumulatedOM: any;

  totalBouquetsPremiumDiscreetOM: any;
  totalBouquetsPremiumAccumulatedOM: any;
  totalBouquetsCertificateDiscreetOM: any;
  totalBouquetsCertificateAccumulatedOM: any;

  totalChannelsPremiumDiscreetOM: any;
  totalChannelsPremiumAccumulatedOM: any;
  totalChannelsCertificateDiscreetOM: any;
  totalChannelsCertificateAccumulatedOM: any;

  monthNames: any[] = [];

  selectedProductType = '';

  data_total: any[] = [];
  data_sheet_names: any[] = [];
  data_filter_details: any[] = [];

  // Default Constructor
  constructor(
    private events: EventsService,
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private numberFormat: NumberPipe,
    private translateService: TranslateService,
    private excelService: ExcelService) { }

  // OnInit
  ngOnInit(): void {

    // Event to detect filter changes
    this.subscription = this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        if (data.length !== 0) {
          this.filtersValues = data;
          this.resetVariables();
          this.month = data.filter(value => value.name === 'month')[0].value-1;
          this.year = data.filter(value => value.name === 'year')[0].value;
          this.getTableHeaders();
          this.selectedData();
        }
      }
    );

    this.events.policiesBonusOption$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        const option = data[0];
        const name = data[1];
        if ((option === this.policyOption || option === this.bonusOption) && name === this.graphName) {
          this.bonusPolicyOptionSelected = option;
          this.selectedData();
        } else if ((option === this.discreetOption || option === this.accumulatedOption) && name === this.graphName) {
          this.discreetAccumulatedOptionSelected = option;
          this.selectedData();
        }
      }
    );

    this.events.monthlySaleProductType$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        const productType = data;
        if (productType === '') {
          this.filtersValues = this.filtersValues.filter(v => v.name !== 'product_type');
          this.selectedProductType = '';
          this.selectedData();
        } else if (productType === 'cr') {
          this.selectedProductType = 'cr';
          this.filtersValues.push(
            {
              name: 'product_type',
              value: [2]
            }
          );
          this.selectedData();
        } else if (productType === 'om') {
          this.selectedProductType = 'om';
          this.filtersValues.push(
            {
              name: 'product_type',
              value: [1]
            }
          );
          this.selectedData();
        }
      }
    );

    this.events.monthlySaleTableExcelButton$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        if (data) {
          this.exportAsXLSX();
        }
      }
    );

  }

  // Method to retrieve the data for the tables
  private retrieveTableData(type_data: string, type_set: string, dimension: any, filters: any) {

    this.loadedBouquets = false;
    this.loadedChannels = false;

    this.subscription = this.apimstService.getMonthlySalesYear(type_data, type_set, dimension, filters).subscribe(
      data => {
        this.loadedBouquets = true;
        this.loadedChannels = true;



        let tableData: MonthlySalePieGraph = data;
        let tableDataDetails: Array<MonthlySalePieGraphDetails> = tableData.details;
        this.data_filter_details = tableData.filters;

        if (tableDataDetails.length !== 0) {
          let totalPrevious = tableDataDetails.map(data => this.numberFormat.transform(data.previous)).reduce((x,y) => x+y);
          let totalCurrent = tableDataDetails.map(data => this.numberFormat.transform(data.current)).reduce((x,y) => x+y);
          let totalPlan = tableDataDetails.map(data => this.numberFormat.transform(data.budget)).reduce((x,y) => x+y);
          let totalDiff = totalPrevious !== 0 ? (((totalCurrent - totalPrevious) / totalPrevious) * 100) : 0;
          let totalPlanDiff = totalPlan !== 0 ? (((totalCurrent - totalPlan) / totalPlan) * 100) : 0;

          let total = {
            name: this.totalName,
            previous: totalPrevious.toFixed(2),
            current: totalCurrent.toFixed(2),
            diff: totalDiff.toFixed(2),
            diff_sign: totalDiff >= 0 ? 1 : 0,
            budget: totalPlan.toFixed(2),
            budget_diff: totalPlanDiff ? totalPlanDiff.toFixed(2) : 0,
            budget_diff_sign: totalPlanDiff >= 0 ? 1 : 0
          };

          // Channels Table
          if (dimension[0] === 'c') {
            if (type_data === 'p' && type_set === 'd' && this.selectedProductType === '') {
              this.channelsPremiumDiscreet = tableDataDetails;
              this.totalChannelsPremiumDiscreet = total;
              this.loadedChannels = true;
            } else if (type_data === 'p' && type_set === 'a' && this.selectedProductType === '') {
              this.channelsPremiumAccumulated = tableDataDetails;
              this.totalChannelsPremiumAccumulated = total;
              this.loadedChannels = true;
            } else if (type_data === 'c' && type_set === 'd' && this.selectedProductType === '') {
              this.channelsCertificateDiscreet = tableDataDetails;
              this.totalChannelsCertificateDiscreet = total;
              this.loadedChannels = true;
            } else if (type_data === 'c' && type_set === 'a' && this.selectedProductType === '') {
              this.channelsCertificateAccumulated = tableDataDetails;
              this.totalChannelsCertificateAccumulated = total;
              this.loadedChannels = true;
            } else if (type_data === 'p' && type_set === 'd' && this.selectedProductType === 'cr') {
              this.channelsPremiumDiscreetCR = tableDataDetails;
              this.totalChannelsPremiumDiscreetCR = total;
              this.loadedChannels = true;
            } else if (type_data === 'p' && type_set === 'a' && this.selectedProductType === 'cr') {
              this.channelsPremiumAccumulatedCR = tableDataDetails;
              this.totalChannelsPremiumAccumulatedCR = total;
              this.loadedChannels = true;
            } else if (type_data === 'c' && type_set === 'd' && this.selectedProductType === 'cr') {
              this.channelsCertificateDiscreetCR = tableDataDetails;
              this.totalChannelsCertificateDiscreetCR = total;
              this.loadedChannels = true;
            } else if (type_data === 'c' && type_set === 'a' && this.selectedProductType === 'cr') {
              this.channelsCertificateAccumulatedCR = tableDataDetails;
              this.totalChannelsCertificateAccumulatedCR = total;
              this.loadedChannels = true;
            } else if (type_data === 'p' && type_set === 'd' && this.selectedProductType === 'om') {
              this.channelsPremiumDiscreetOM = tableDataDetails;
              this.totalChannelsPremiumDiscreetOM = total;
              this.loadedChannels = true;
            } else if (type_data === 'p' && type_set === 'a' && this.selectedProductType === 'om') {
              this.channelsPremiumAccumulatedOM = tableDataDetails;
              this.totalChannelsPremiumAccumulatedOM = total;
              this.loadedChannels = true;
            } else if (type_data === 'c' && type_set === 'd' && this.selectedProductType === 'om') {
              this.channelsCertificateDiscreetOM = tableDataDetails;
              this.totalChannelsCertificateDiscreetOM = total;
              this.loadedChannels = true;
            } else if (type_data === 'c' && type_set === 'a' && this.selectedProductType === 'om') {
              this.channelsCertificateAccumulatedOM = tableDataDetails;
              this.totalChannelsCertificateAccumulatedOM = total;
              this.loadedChannels = true;
            }
            //Bouquets Table
          } else if (dimension[0] === 'b') {
            if (type_data === 'p' && type_set === 'd' && this.selectedProductType === '') {
              this.bouquetsPremiumDiscreet = tableDataDetails;
              this.totalBouquetsPremiumDiscreet = total;
              this.loadedBouquets = true;
            } else if (type_data === 'p' && type_set === 'a' && this.selectedProductType === '') {
              this.bouquetsPremiumAccumulated = tableDataDetails;
              this.totalBouquetsPremiumAccumulated = total;
              this.loadedBouquets = true;
            } else if (type_data === 'c' && type_set === 'd' && this.selectedProductType === '') {
              this.bouquetsCertificateDiscreet = tableDataDetails;
              this.totalBouquetsCertificateDiscreet = total;
              this.loadedBouquets = true;
            } else if (type_data === 'c' && type_set === 'a' && this.selectedProductType === '') {
              this.bouquetsCertificateAccumulated = tableDataDetails;
              this.totalBouquetsCertificateAccumulated = total;
              this.loadedBouquets = true;
            } else if (type_data === 'p' && type_set === 'd' && this.selectedProductType === 'cr') {
              this.bouquetsPremiumDiscreetCR = tableDataDetails;
              this.totalBouquetsPremiumDiscreetCR = total;
              this.loadedBouquets = true;
            } else if (type_data === 'p' && type_set === 'a' && this.selectedProductType === 'cr') {
              this.bouquetsPremiumAccumulatedCR = tableDataDetails;
              this.totalBouquetsPremiumAccumulatedCR = total;
              this.loadedBouquets = true;
            } else if (type_data === 'c' && type_set === 'd' && this.selectedProductType === 'cr') {
              this.bouquetsCertificateDiscreetCR = tableDataDetails;
              this.totalBouquetsCertificateDiscreetCR = total;
              this.loadedBouquets = true;
            } else if (type_data === 'c' && type_set === 'a' && this.selectedProductType === 'cr') {
              this.bouquetsCertificateAccumulatedCR = tableDataDetails;
              this.totalBouquetsCertificateAccumulatedCR = total;
              this.loadedBouquets = true;
            } else if (type_data === 'p' && type_set === 'd' && this.selectedProductType === 'om') {
              this.bouquetsPremiumDiscreetOM = tableDataDetails;
              this.totalBouquetsPremiumDiscreetOM = total;
              this.loadedBouquets = true;
            } else if (type_data === 'p' && type_set === 'a' && this.selectedProductType === 'om') {
              this.bouquetsPremiumAccumulatedOM = tableDataDetails;
              this.totalBouquetsPremiumAccumulatedOM = total;
              this.loadedBouquets = true;
            } else if (type_data === 'c' && type_set === 'd' && this.selectedProductType === 'om') {
              this.bouquetsCertificateDiscreetOM = tableDataDetails;
              this.totalBouquetsCertificateDiscreetOM = total;
              this.loadedBouquets = true;
            } else if (type_data === 'c' && type_set === 'a' && this.selectedProductType === 'om') {
              this.bouquetsCertificateAccumulatedOM = tableDataDetails;
              this.totalBouquetsCertificateAccumulatedOM = total;
              this.loadedBouquets = true;
            }
          }
          this.assignDataToPrint();
          this.loadedBouquets = true;
          this.loadedChannels = true;
        } else {
          if (dimension[0] === 'c') {
            this.channelsToPrint = [];
            this.channelsTotalToPrint = [];
            this.loadedChannels = true;
          } else if (dimension[0] === 'b') {
            this.bouquetsToPrint = [];
            this.bouquetsTotalToPrint = [];
            this.loadedBouquets = true;
          }
        }
      },
      error => console.log("An error ocurred while retrieving data for the monthly sales table: " + error)
    );

  }

  // Method to assign the data to the tables
  private assignDataToPrint() {
    if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === '') {
      this.bouquetsToPrint = this.bouquetsCertificateDiscreet;
      this.bouquetsTotalToPrint = this.totalBouquetsCertificateDiscreet;
      this.channelsToPrint = this.channelsCertificateDiscreet;
      this.channelsTotalToPrint = this.totalChannelsCertificateDiscreet;
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === '') {
      this.bouquetsToPrint = this.bouquetsCertificateAccumulated;
      this.bouquetsTotalToPrint = this.totalBouquetsCertificateAccumulated;
      this.channelsToPrint = this.channelsCertificateAccumulated;
      this.channelsTotalToPrint = this.totalChannelsCertificateAccumulated;
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === '') {
      this.bouquetsToPrint = this.bouquetsPremiumDiscreet;
      this.bouquetsTotalToPrint = this.totalBouquetsPremiumDiscreet;
      this.channelsToPrint = this.channelsPremiumDiscreet;
      this.channelsTotalToPrint = this.totalChannelsPremiumDiscreet;
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === '') {
      this.bouquetsToPrint = this.bouquetsPremiumAccumulated;
      this.bouquetsTotalToPrint = this.totalBouquetsPremiumAccumulated;
      this.channelsToPrint = this.channelsPremiumAccumulated;
      this.channelsTotalToPrint = this.totalChannelsPremiumAccumulated;
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === 'cr') {
      this.bouquetsToPrint = this.bouquetsCertificateDiscreetCR;
      this.bouquetsTotalToPrint = this.totalBouquetsCertificateDiscreetCR;
      this.channelsToPrint = this.channelsCertificateDiscreetCR;
      this.channelsTotalToPrint = this.totalChannelsCertificateDiscreetCR;
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === 'cr') {
      this.bouquetsToPrint = this.bouquetsCertificateAccumulatedCR;
      this.bouquetsTotalToPrint = this.totalBouquetsCertificateAccumulatedCR;
      this.channelsToPrint = this.channelsCertificateAccumulatedCR;
      this.channelsTotalToPrint = this.totalChannelsCertificateAccumulatedCR;
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === 'cr') {
      this.bouquetsToPrint = this.bouquetsPremiumDiscreetCR;
      this.bouquetsTotalToPrint = this.totalBouquetsPremiumDiscreetCR;
      this.channelsToPrint = this.channelsPremiumDiscreetCR;
      this.channelsTotalToPrint = this.totalChannelsPremiumDiscreetCR;
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === 'cr') {
      this.bouquetsToPrint = this.bouquetsPremiumAccumulatedCR;
      this.bouquetsTotalToPrint = this.totalBouquetsPremiumAccumulatedCR;
      this.channelsToPrint = this.channelsPremiumAccumulatedCR;
      this.channelsTotalToPrint = this.totalChannelsPremiumAccumulatedCR;
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === 'om') {
      this.bouquetsToPrint = this.bouquetsCertificateDiscreetOM;
      this.bouquetsTotalToPrint = this.totalBouquetsCertificateDiscreetOM;
      this.channelsToPrint = this.channelsCertificateDiscreetOM;
      this.channelsTotalToPrint = this.totalChannelsCertificateDiscreetOM;
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === 'om') {
      this.bouquetsToPrint = this.bouquetsCertificateAccumulatedOM;
      this.bouquetsTotalToPrint = this.totalBouquetsCertificateAccumulatedOM;
      this.channelsToPrint = this.channelsCertificateAccumulatedOM;
      this.channelsTotalToPrint = this.totalChannelsCertificateAccumulatedOM;
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === 'om') {
      this.bouquetsToPrint = this.bouquetsPremiumDiscreetOM;
      this.bouquetsTotalToPrint = this.totalBouquetsPremiumDiscreetOM;
      this.channelsToPrint = this.channelsPremiumDiscreetOM;
      this.channelsTotalToPrint = this.totalChannelsPremiumDiscreetOM;
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === 'om') {
      this.bouquetsToPrint = this.bouquetsPremiumAccumulatedOM;
      this.bouquetsTotalToPrint = this.totalBouquetsPremiumAccumulatedOM;
      this.channelsToPrint = this.channelsPremiumAccumulatedOM;
      this.channelsTotalToPrint = this.totalChannelsPremiumAccumulatedOM;
    }
  }

  // Method to get the tables headers
  private getTableHeaders() {

    // Translation labels
    const total = this.translateService.get('labels.total');
    const plan = this.translateService.get('labels.plan');
    const bouquet = this.translateService.get('panels.branch');
    const channel = this.translateService.get('panels.channel');
    const months = this.translateService.get('monthNamesWihtNumber');

    forkJoin([total, plan, bouquet, channel, months])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(result => {
      this.totalName = result[0];
      this.planName = result[1];
      this.bouquetName = result[2];
      this.channelName = result[3];
      this.monthNames = result[4];

      this.headers = {
        previousMonth: this.monthNames[this.month] + ' ' + (this.year-1),
        actualMonth: this.monthNames[this.month] + ' ' + this.year,
        diff: '%',
        plan: this.planName + ' ' + this.year,
        totalDiff: '%'
      };
    });

  }

  // Method to reset the storage data variables
  private resetVariables() {
    this.bouquetsPremiumDiscreet = [];
    this.bouquetsPremiumAccumulated = [];
    this.bouquetsCertificateDiscreet = [];
    this.bouquetsCertificateAccumulated = [];

    this.channelsPremiumDiscreet = [];
    this.channelsPremiumAccumulated = [];
    this.channelsCertificateDiscreet = [];
    this.channelsCertificateAccumulated = [];

    this.bouquetsPremiumDiscreetCR = [];
    this.bouquetsPremiumAccumulatedCR = [];
    this.bouquetsCertificateDiscreetCR = [];
    this.bouquetsCertificateAccumulatedCR = [];

    this.channelsPremiumDiscreetCR = [];
    this.channelsPremiumAccumulatedCR = [];
    this.channelsCertificateDiscreetCR = [];
    this.channelsCertificateAccumulatedCR = [];

    this.bouquetsPremiumDiscreetOM = [];
    this.bouquetsPremiumAccumulatedOM = [];
    this.bouquetsCertificateDiscreetOM = [];
    this.bouquetsCertificateAccumulatedOM = [];

    this.channelsPremiumDiscreetOM = [];
    this.channelsPremiumAccumulatedOM = [];
    this.channelsCertificateDiscreetOM = [];
    this.channelsCertificateAccumulatedOM = [];

    this.selectedProductType = '';

  }

  // Method to print the selected data
  private selectedData() {

    if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === '' &&  this.bouquetsPremiumDiscreet.length === 0 && this.channelsPremiumDiscreet.length === 0) {
      this.retrieveTableData('p', 'd', ['b'], this.filtersValues);
      this.retrieveTableData('p', 'd', ['c'], this.filtersValues);
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === '' && this.bouquetsCertificateDiscreet.length === 0 && this.channelsCertificateDiscreet.length === 0) {
      this.retrieveTableData('c', 'd', ['b'], this.filtersValues);
      this.retrieveTableData('c', 'd', ['c'], this.filtersValues);
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === '' && this.bouquetsCertificateAccumulated.length === 0 && this.channelsCertificateAccumulated.length === 0) {
      this.retrieveTableData('c', 'a', ['b'], this.filtersValues);
      this.retrieveTableData('c', 'a', ['c'], this.filtersValues);
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === '' && this.bouquetsPremiumAccumulated.length === 0 && this.channelsPremiumAccumulated.length === 0) {
      this.retrieveTableData('p', 'a', ['b'], this.filtersValues);
      this.retrieveTableData('p', 'a', ['c'], this.filtersValues);
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === 'cr' &&  this.bouquetsPremiumDiscreetCR.length === 0 && this.channelsPremiumDiscreetCR.length === 0) {
      this.retrieveTableData('p', 'd', ['b'], this.filtersValues);
      this.retrieveTableData('p', 'd', ['c'], this.filtersValues);
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === 'cr' && this.bouquetsCertificateDiscreetCR.length === 0 && this.channelsCertificateDiscreetCR.length === 0) {
      this.retrieveTableData('c', 'd', ['b'], this.filtersValues);
      this.retrieveTableData('c', 'd', ['c'], this.filtersValues);
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === 'cr' && this.bouquetsCertificateAccumulatedCR.length === 0 && this.channelsCertificateAccumulatedCR.length === 0) {
      this.retrieveTableData('c', 'a', ['b'], this.filtersValues);
      this.retrieveTableData('c', 'a', ['c'], this.filtersValues);
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === 'cr' && this.bouquetsPremiumAccumulatedCR.length === 0 && this.channelsPremiumAccumulatedCR.length === 0) {
      this.retrieveTableData('p', 'a', ['b'], this.filtersValues);
      this.retrieveTableData('p', 'a', ['c'], this.filtersValues);
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === 'om' &&  this.bouquetsPremiumDiscreetOM.length === 0 && this.channelsPremiumDiscreetOM.length === 0) {
      this.retrieveTableData('p', 'd', ['b'], this.filtersValues);
      this.retrieveTableData('p', 'd', ['c'], this.filtersValues);
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.discreetOption && this.selectedProductType === 'om' && this.bouquetsCertificateDiscreetOM.length === 0 && this.channelsCertificateDiscreetOM.length === 0) {
      this.retrieveTableData('c', 'd', ['b'], this.filtersValues);
      this.retrieveTableData('c', 'd', ['c'], this.filtersValues);
    } else if (this.bonusPolicyOptionSelected === this.policyOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === 'om' && this.bouquetsCertificateAccumulatedOM.length === 0 && this.channelsCertificateAccumulatedOM.length === 0) {
      this.retrieveTableData('c', 'a', ['b'], this.filtersValues);
      this.retrieveTableData('c', 'a', ['c'], this.filtersValues);
    } else if (this.bonusPolicyOptionSelected === this.bonusOption && this.discreetAccumulatedOptionSelected === this.accumulatedOption && this.selectedProductType === 'om' && this.bouquetsPremiumAccumulatedOM.length === 0 && this.channelsPremiumAccumulatedOM.length === 0) {
      this.retrieveTableData('p', 'a', ['b'], this.filtersValues);
      this.retrieveTableData('p', 'a', ['c'], this.filtersValues);
    } else {
      this.assignDataToPrint();
      this.loadedBouquets = true;
      this.loadedChannels = true;
    }
  }

  // Method to export data to XSLX
  exportAsXLSX():void {
    const sheetSufix = this.getOptionTabTranslation();

    let channelHeaders: any = [this.channelName, Object.values(this.headers)];
    let bouquetHeaders: any = [this.bouquetName, Object.values(this.headers)];

    const filteredChannelTotal = Object.fromEntries(Object.entries(this.channelsTotalToPrint).filter(([key]) => (key !== 'diff_sign' && key !== 'plan_diff_sign')));
    const filteredBouquetTotal = Object.fromEntries(Object.entries(this.bouquetsTotalToPrint).filter(([key]) => (key !== 'diff_sign' && key !== 'plan_diff_sign')));

    let channel: any = [channelHeaders.flat(), Object.values(this.stringObjectToNumber(filteredChannelTotal))];
    let bouquet: any = [bouquetHeaders.flat(), Object.values(this.stringObjectToNumber(filteredBouquetTotal))];

    for (let item of this.channelsToPrint) {
      let value = this.stringObjectToNumber(item);
      channel.push(Object.values(value));
    }

    for (let item of this.bouquetsToPrint) {
      let value = this.stringObjectToNumber(item);
      bouquet.push(Object.values(value));
    }

    this.data_total = [channel, bouquet];
    this.data_sheet_names = [this.channelName + ' - ' + sheetSufix + ' - ' + this.selectedProductType.toUpperCase(), this.bouquetName + ' - ' + sheetSufix + ' - ' + this.selectedProductType.toUpperCase()];

    this.excelService.exportAsExcelFile(this.data_total, this.data_sheet_names,  'monthly_sale_table', this.data_filter_details);
  }

  // Method to get the option tabs translation
  private getOptionTabTranslation(): string {
    let premiumCertificateOption = '';
    let discreetAccumulatedOption = '';

    const tab1 = this.translateService.get(this.bonusPolicyOptionSelected);
    const tab2 = this.translateService.get(this.discreetAccumulatedOptionSelected);

    forkJoin([tab1, tab2]).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
      premiumCertificateOption = result[0];
      discreetAccumulatedOption = result[1];
    });

    return premiumCertificateOption + ' - ' + discreetAccumulatedOption;
  }

  // Method to convert the string values of the data object to number
  private stringObjectToNumber(item: Object): Object {
    const value = Object.fromEntries(Object.entries(item).filter(([key]) => (key !== 'diff_trend' && key !== 'budget_diff_trend')));
    let dataObject = {};
    dataObject['name'] = value['name'];
    dataObject['previous'] = ( value['previous'] || trim(value['previous'].toString()).length > 0) ? parseFloat(value['previous'].toString().replace(/,/g, '')) : 0;
    dataObject['current'] = ( value['current'] || trim(value['current'].toString()).length > 0) ? parseFloat(value['current'].toString().replace(/,/g, '')) : 0;
    dataObject['diff'] = ( value['diff'] || trim(value['diff'].toString()).length > 0) ? parseFloat(value['diff'].toString().replace(/,/g, '')) : 0;
    dataObject['budget'] = ( value['budget'] || trim(value['budget'].toString()).length > 0) ? parseFloat(value['budget'].toString().replace(/,/g, '')) : 0;
    dataObject['budget_diff'] = ( value['budget_diff'] || trim(value['budget_diff'].toString()).length > 0) ? parseFloat(value['budget_diff'].toString().replace(/,/g, '')) : 0;
    return dataObject;
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


}

<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex">
            <span class="title section-card-title">{{ 'panels.claims' | translate }}</span>
            <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams"
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
            <mat-icon class="excel-icon ml-2 icon-margin" svgIcon="excel" (click)="exportAsXLSX()" *ngIf="loaded"></mat-icon>
        </div>

        <component-loader *ngIf="!loaded" [width]="200" [height]="40"></component-loader>
        <div *ngIf="loaded" class="dropdown-container dropdown-light">
            <app-dropdown
                [title]="title"
                [items]="qualityClaimReasons"
                [dropdownId]="dropdownId">
            </app-dropdown>
        </div>
    </div>

    <div class="mt-4">
        <app-quality-claims-velocimeter></app-quality-claims-velocimeter>
    </div>

    <div class="mt-1">
        <quality-claims-graphic></quality-claims-graphic>
    </div>
   

</app-widget>
<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex align-items-center">
            <span class="title section-card-subtitle">{{ 'panels.renewalPer' | translate }}</span>
            <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams"
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </div>
        <app-option-tab [leftOption] = chagesAssets [rightOption] = chagesMethods [graphName] = graphName class = "option-tab align-items-center"></app-option-tab>
        <app-option-tab [leftOption] = bonus [rightOption] = policy [graphName] = graphName class = "option-tab"></app-option-tab>
    </div>
  
    <div class="d-flex">
        <!-- <renewal-per-pie-chart></renewal-per-pie-chart>  -->
        <renewal-per-graphic></renewal-per-graphic>
    </div>

</app-widget>
<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <span class="title section-card-title">{{ 'panels.operationalLossRatio' | translate }}</span>
    </div>

    <div class="mt-3">
        <component-loader *ngIf="!loaded" [width]="" [height]="560"></component-loader>
        <highcharts-chart 
        class="map-gen" 
        [Highcharts]="Highcharts" 
        [constructorType]="chartConstructor"
        [options]="chartOptions" 
        style="width: 100%; height: 560px; display: block;"
        *ngIf="loaded">
        </highcharts-chart>
    </div>

  </app-widget>
  
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-portfolio-mix-dialog',
  templateUrl: './portfolio-mix-dialog.component.html',
  styleUrls: ['./portfolio-mix-dialog.component.scss']
})
export class PortfolioMixDialogComponent {

  sectionTitle: string;
  view: any[];
  //xAxisTicks: any[] = [-100, -50, 0, 50, 100];
  xAxisTicks: any[];
  showXAxis: boolean;
  showYAxis: boolean;
  gradient: boolean;
  showLegend: boolean;
  showXAxisLabel: boolean;
  showYAxisLabel: boolean;
  xAxisLabel: string;
  showDataLabel: boolean;
  animations: boolean;
  schemeType: string;
  colorScheme: any;
  legend: boolean;
  legendTitle: string;
  legendPosition: string;
  xAxis: boolean;
  yAxis: boolean;
  yAxisLabel: string;
  tooltipDisabled: boolean;
  showGridLines: boolean;
  activeEntries: any[];
  trimXAxisTicks: boolean;
  trimYAxisTicks: boolean;
  rotateXAxisTicks: boolean;
  maxXAxisTickLength: number = 16;
  maxYAxisTickLength: number = 10;
  xAxisTickFormatting: any;
  yAxisTickFormatting: any;
  yAxisTicks: any[];
  barPadding = 8;
  roundDomains: boolean = true;
  xScaleMax: number;
  dataLabelFormatting: any;
  noBarWhenZero: boolean = true;
  customColors: any = [];
  results: any;
  dialogLegends: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.sectionTitle = data.sectionTitle,
    this.view = data.view,
    this.colorScheme = data.scheme,
    this.schemeType = data.schemeType,
    this.results = data.results,
    this.gradient = data.gradient,
    this.xAxis = data.xAxis,
    this.yAxis = data.yAxis,
    this.legend = data.legend,
    this.showXAxisLabel = data.showXAxisLabel,
    this.showYAxisLabel = data.showYAxisLabel,
    this.xAxisLabel = data.xAxisLabel,
    this.yAxisLabel = data.yAxisLabel,
    this.animations = data.animations,
    this.showDataLabel = data.showDataLabel,
    this.dataLabelFormatting = data.dataLabelFormatting,
    this.roundDomains = data.roundDomains,
    this.trimYAxisTicks = data.trimYAxisTicks,
    this.maxYAxisTickLength = data.maxYAxisTickLength,
    this.showGridLines = data.showGridLines,
    this.customColors = data.customColors
    this.tooltipDisabled = data.tooltipDisabled
    this.dialogLegends = data.dialogLegends,
    this.xAxisTickFormatting = data.xAxisTickFormatting
  }

}

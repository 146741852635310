<app-widget>

    <div class="section-header-block justify-content-between align-items-center pb-2">
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex">
                <span class="title section-card-title">{{ 'panels.santandervsZsSegmentation' | translate }}</span>
                <!-- <app-btn-gobernance-info 
                    class="ml-2" 
                    [endpoint]="endpoint" 
                    [endpointParams]="endpointParams"
                    *ngIf="hasGobernanceInfo">
                </app-btn-gobernance-info> -->
            </div>
            <div class="dropdown-container dropdown-light">
                <app-dropdown
                    [title]="santanderSegmentationDropdownTitle"
                    [items]="santanderSegmentationDropdownOption"
                    [dropdownId]="santanderSegmentationDropdownId">
                </app-dropdown>
            </div>
            <div class="dropdown-container dropdown-light">
                <app-dropdown
                    [title]="zurichSegmentationDropdownTitle"
                    [items]="zurichSegmentationDropdownOption"
                    [dropdownId]="zurichSegmentationDropdownId">
                </app-dropdown>
            </div>
        </div>
    </div>

    <app-santander-vs-zs-segmentation-graphic></app-santander-vs-zs-segmentation-graphic>

</app-widget>

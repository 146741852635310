import { Component, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-tree-map',
  templateUrl: './tree-map.component.html',
  styleUrls: ['./tree-map.component.scss']
})
export class TreeMapComponent implements OnInit {

  @Input() single: any[];
  @Input() view: any[];
  @Input() gradient: boolean;
  @Input() animations: boolean;
  @Input() scheme: {};
  @Input() results: any[];
  @Input() labelFormatting: any;
  @Input() tooltipValueSuffix: string = "";

  onSelect(event) {
    console.log(event);
  }

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {}
  // labelFormatting(c) {
  //   console.log(c);
  //   return `${(c.label)} Population`;
  // }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.setBorderRadius();
  }

  setBorderRadius(): void {
    let cells = this.document.querySelectorAll('.cell');
    
    [].forEach.call(cells, function(cell) {
      cell.setAttribute("rx", '8');
    });
  }

  formatValue(v) {
    return v.toLocaleString('de-DE');;
  }

}

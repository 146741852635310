import { Component, OnDestroy, OnInit } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'
import { QualityRolling } from 'src/app/shared/models/quality-rolling';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from 'src/app/shared/services/events/events.service';

@Component({
  selector: 'cancellation-nps-graphic',
  templateUrl: './cancellation-nps-graphic.component.html',
  styleUrls: ['./cancellation-nps-graphic.component.scss']
})
export class CancellationNpsGraphicComponent implements OnInit, OnDestroy {

  multi: any[];
  customColors: any = [];

  // options
  legend: boolean = false;
  showLabels: boolean = false;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showRefLines: boolean = false;
  timeline: boolean = false;
  rotateXAxisTicks: boolean = false;

  colorScheme = {
    domain: [COLORS.darkGreen, COLORS.darkYellow, COLORS.darkRed, COLORS.lightBlue, '#a8385d', '#aae3f5']
  };

  // Options for chart creation
  view = [580, 160];
  modalView = [1210, 580];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  legendTitle: string = '';
  legendPosition: string = '';
  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  yLeftAxisScaleFactor: string = '';
  yRightAxisScaleFactor: string = '';
  yRightAxisTickFormatting: string = '';
  showGridLines: boolean = true;
  innerPadding: string = '10%';
  animations: boolean = false;
  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  barPadding: number = 8;
  tooltipDisabled: boolean = false;
  schemeType: string = "ordinal";
  roundDomains: boolean = true;
  noBarWhenZero: boolean = true;
  barChart: any[] = [];
  lineChartSeries: any[] = [];
  lineChartScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkYellow, COLORS.lightPurple, '#0099cc', '#08ded1']
  };
  comboBarScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkGreen, COLORS.darkYellow, COLORS.darkRed, COLORS.lightBlue, '#a8385d', '#aae3f5']
  };
  showTotalInTooltip: boolean = false;


  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;
  monthNames: any;
  tnpsName: string;
  promotersName: string;
  neutralsName: string;
  detractorsName: string;
  qualityNPSRolling: any = [];
  qualityNPSRollingWithAverage: any = [];
  loaded: boolean = false;
  qualityNPSSelected: any = [];
  xAxisTicksFinal: any = [];
  xAxisTicksValues: any = [];

  promotersSeries: any = [];
  neutralsSeries: any = [];
  detractorsSeries: any = [];
  averageSeries: any = [];

  promotersToExport: any[] = [];
  neutralsToExport: any[] = [];
  detractorsToExport: any[] = [];
  averageToExport: any[] = [];

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private translateService: TranslateService,
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {

    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          const months = this.translateService.get('abbreviatedMonthNames');
          const tnps = this.translateService.get('labels.tnps');
          const promoters = this.translateService.get('labels.promoters');
          const neutrals = this.translateService.get('labels.neutrals');
          const detractors = this.translateService.get('labels.detractors');

          forkJoin([months, tnps, promoters, neutrals, detractors])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: results => {
                this.monthNames = results[0];
                this.tnpsName = results[1];
                this.promotersName = results[2];
                this.neutralsName = results[3];
                this.detractorsName = results[4];
              },
              complete: () => {
                if (data.length !== 0) {
                  this.loaded = false;
                  this.filtersValues = data;
                  this.resetVariables();
                  this.retrieveQualityNPS(this.qualityNPSSelected, this.filtersValues);
                }
              }
            });
        }
      );

      this.eventsService.salesQualityNPS$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.resetVariables();
        this.qualityNPSSelected = [];
        if (data.value !== 'all') {
          this.qualityNPSSelected.push(data.value);
        }
        this.retrieveQualityNPS(this.qualityNPSSelected, this.filtersValues);
      });

  }

  // Method to retrieve the quality NPS data
  private retrieveQualityNPS(qualityNPSSelected: [], filtersValues: any) {
    this.loaded = false;
    this.apimstService.getCancellationQualityNPSRolling(qualityNPSSelected, filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data: Array<QualityRolling>) => {
          let rateSeries: any = [];
          let avgRateSeries: any = [];
          this.qualityNPSRolling = [];
          this.xAxisTicksValues = [];
          this.xAxisTicksFinal = [];

          for(let month of data) {
            rateSeries.push({
              name: this.monthNames[month.month] + ' ' + month.year.slice(2, 4),
              value: month.rate ? parseFloat(month.rate) : 0
            });

            avgRateSeries.push({
              name: this.monthNames[month.month] + ' ' + month.year.slice(2, 4),
              value: month.rate_avg ? parseFloat(month.rate_avg) : 0
            });
            this.xAxisTicksValues.push(this.monthNames[month.month] + ' ' + month.year.slice(2, 4));
            this.getXAxisValues();
          }

          this.qualityNPSRolling.push(
            {
              name: this.promotersName,
              series: rateSeries
            },
            {
              name: this.tnpsName,
              series: avgRateSeries
            }
          );

          this.createGraph(data);
          this.createLineGraph(data);

          this.assignColorBar();

          this.loaded = true;
        },

        error => console.log("An error ocurred while retrieving cancellation quality NPS rolling data: " + error)


      );
  }

  createGraph(data: any = {}) {
    this.qualityNPSRolling = [];
    this.qualityNPSRollingWithAverage = [];

    this.promotersSeries = [];
    this.neutralsSeries = [];
    this.detractorsSeries = [];
    this.averageSeries = [];

    for (let month of data) {
      const date = this.monthNames[month.month] + ' ' + month.year.slice(2, 4);
      this.qualityNPSRolling.push({
        name: date,
        series: [
          { name: this.promotersName, value: month.promoter ? parseFloat(month.promoter) : 0 },
          { name: this.neutralsName, value: month.neutral ? parseFloat(month.neutral) : 0 },
          { name: this.detractorsName, value: month.detractor ? parseFloat(month.detractor) : 0 }
        ]
      });
    }
  }

  createLineGraph(data: any = {}) {
    this.lineChartSeries.push({ name: this.tnpsName, series: [] })

    for (let month of data) {
      const date = this.monthNames[month.month] + ' ' + month.year.slice(2, 4);
      this.lineChartSeries[0].series.push({
        name: date,
        value: month.rate ? parseFloat(month.rate) : 0
      })
    }
  }

  setTooltipRate(model: any) {
    let values: any[] = [];

    for(let item of this.averageSeries[0].series) {
      if(item.name == model[0].name ) {
        return item.value
      }
    }

    return;
  }

  onActivate(e) {
    // console.log("activate")
    // console.log(e)
  }

  onSelect(e) {
    // console.log("click")
    // console.log(e)
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // Method to formath the yAxis ticks
  yAxisTickFormatting(val) {
    return val.toLocaleString() + "%";
  }

  private assignColorBar() {
    this.customColors.push(
      {
        name: this.promotersName,
        value: COLORS.darkGreen
      },
      {
        name: this.neutralsName,
        value: COLORS.darkYellow
      },
      {
        name: this.detractorsName,
        value: COLORS.darkRed
      },
      {
        name: this.tnpsName,
        value: COLORS.pink
      }
    )
  }

  private resetVariables() {
    this.qualityNPSRolling = [];
    this.lineChartSeries = [];
  }

  // Method to get only one x axis value in two 
  private getXAxisValues(): void {
    this.xAxisTicksFinal = [];
    for (var _i = 0; _i < this.xAxisTicksValues.length; _i += 2) {
      this.xAxisTicksFinal.push(this.xAxisTicksValues[_i]);
    } 
  }

}

<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex align-items-center">
            <span class="title section-card-subtitle">{{ 'panels.clientsByClassProduct&Branch' | translate }}</span>
            <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams"
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </div>
    </div>

    <app-clients-stock-by-product-class-branch-pie-graphics></app-clients-stock-by-product-class-branch-pie-graphics>

</app-widget>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fixed-stock-indicators',
  templateUrl: './fixed-stock-indicators.component.html',
  styleUrls: ['./fixed-stock-indicators.component.scss']
})
export class FixedStockIndicatorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {}

}

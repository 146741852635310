<app-widget>
  <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
    <div class="d-flex">
      <span class="title section-card-title">{{ 'panels.antiquity' | translate }}</span>
      <app-btn-gobernance-info 
        class="ml-2" 
        [endpoint]="endpoint" 
        [endpointParams]="endpointParams"
        *ngIf="hasGobernanceInfo">
      </app-btn-gobernance-info>
    </div>
    <div class="d-flex align-items-center">
      <app-pager [pages]="pages" (pageChange)="getSection($event)" class="mr-3"></app-pager>
      <app-option-tab-multiple [graphName]="graphName" [options]="panelOptions" [ngClass]="{'loading' : isLoading}">
      </app-option-tab-multiple>
      <div class="ml-4">
        <component-loader [width]="170" [height]="37" *ngIf="!loaded"></component-loader>
        <div class="dropdown-container dropdown-light" *ngIf="loaded">
          <app-dropdown [title]="dropdownTitle" [items]="ageReasons" [dropdownId]="dropdownId">
          </app-dropdown>
        </div>
      </div>
    </div>
  </div>

  <div class="sections-container" *ngIf="loaded">
    <div *ngIf="pageName == 'lr-block'">
        <app-pending-age-block></app-pending-age-block>
    </div>
    <div *ngIf="pageName == 'lr-evolution'">
        <app-pending-age-evolution></app-pending-age-evolution>
    </div>
</div>
</app-widget>

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-clients-occupation-main-thermometer',
  templateUrl: './clients-occupation-main-thermometer.component.html',
  styleUrls: ['./clients-occupation-main-thermometer.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ClientsOccupationMainThermometerComponent implements OnInit {
  loaded: boolean = false;

  minName: string = "";
  maxName: string = "";
  yearsName: string = "";
  avgAgeName: string = "";
  avgSeniorityName: string = "";

  emptyIndicator: any = {
    title: "",
    mainValue: "",
    mainValueUnit: "",
    percentage: "",
    trend: 0,
    hasPercentage: false,
    markerPosition: "0",
    textBottomLeft: "",
    textBottomRight: "",
  }

  indicators: any = {
    age: {},
    seniority: {},
  }

  filtersValues: any;

  unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.subscribeToFiltersValues();
  }

  subscribeToFiltersValues(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: filtersValues => {
          this.loaded = false;
          if (filtersValues.length !== 0) {
            this.filtersValues = filtersValues;
            this.getTranslations();
            this.resetValues();
            this.retrieveClientsOccupation(this.filtersValues);
          }
        }
      });
  }

  getTranslations() {
    const minTranslation = this.translateService.get('labels.minAbbr');
    const maxTranslation = this.translateService.get('labels.maxAbbr');
    const yearsTranslation = this.translateService.get('labels.years');
    const avgAgeTranslation = this.translateService.get('labels.avgAge');
    const avgSeniorityTranslation = this.translateService.get('labels.avgSeniority');
    const translations = [minTranslation, maxTranslation, yearsTranslation, avgAgeTranslation, avgSeniorityTranslation];

    forkJoin(translations)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(results => {
        this.minName = results[0];
        this.maxName = results[1];
        this.yearsName = results[2];
        this.avgAgeName = results[3];
        this.avgSeniorityName = results[4];
      });
  }

  resetValues() {
    this.indicators.age = {};
    this.indicators.seniority = {};
  }

  retrieveClientsOccupation(filtersValues: any[] = []) {
    const ageRequest = this.apimstService.getClientsOccupation("caa", filtersValues);
    const seniorityRequest = this.apimstService.getClientsOccupation("cas", filtersValues)

    forkJoin([ageRequest, seniorityRequest])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.loaded = true;

          if (data[0] != null) {
            this.indicators.age = this.createAgeIndicator(data[0][0]);
          };

          if (data[1] != null) {
            this.indicators.seniority = this.createSeniorityIndicator(data[1][0]);
          };
          
        }
      );
  }

  createAgeIndicator(rawInfo: any = {}) {
    let indicator: any = {};
    indicator.title = this.avgAgeName;
    indicator.mainValue = rawInfo.avg ? rawInfo.avg : "0";
    indicator.mainValueUnit = ` ${this.yearsName.toLowerCase()}`;
    indicator.percentage = "0";
    indicator.trend = 0;
    indicator.hasPercentage = false;
    indicator.markerPosition = this.setPercentage(parseFloat(rawInfo.avg), parseFloat(rawInfo.min), parseFloat(rawInfo.max));
    indicator.textBottomLeft = `${this.minName} ${rawInfo.min} ${this.yearsName.toLowerCase()}`;
    indicator.textBottomRight = `${this.maxName} ${rawInfo.max} ${this.yearsName.toLowerCase()}`;
    return indicator;
  }

  createSeniorityIndicator(rawInfo: any = {}) {
    let indicator: any = {};
    indicator.title = this.avgSeniorityName;
    indicator.mainValue = rawInfo.avg ? rawInfo.avg : "0";
    indicator.mainValueUnit = ` ${this.yearsName.toLowerCase()}`;
    indicator.percentage = rawInfo.diff ? rawInfo.diff : "0";
    indicator.trend = rawInfo.diff_trend ? parseInt(rawInfo.diff_trend) : 0;
    indicator.hasPercentage = false;
    indicator.markerPosition = this.setPercentage(parseFloat(rawInfo.avg), parseFloat(rawInfo.min), parseFloat(rawInfo.max));
    indicator.textBottomLeft = `${this.minName} ${rawInfo.min} ${this.yearsName.toLowerCase()}`;
    indicator.textBottomRight = `${this.maxName} ${rawInfo.max} ${this.yearsName.toLowerCase()}`;
    return indicator;
  }

  setPercentage(value: number = 0, min: number = 0, max: number = 0) {
    const calc = ((value - min) / (max - min)) * 100;
    const calcString = calc <= 100 ? calc.toString() : "100"
    return calcString;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

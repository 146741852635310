import { Component, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'fancy-selector',
  templateUrl: './fancy-selector.component.html',
  styleUrls: ['./fancy-selector.component.scss']
})
export class FancySelectorComponent implements OnInit {

  @Input() title: string = "";
  @Input() inactiveIcon: string;
  @Input() activeIcon: string;
  @Input() menuItems: any;
  @Input() isActive: boolean;

  @Output() select: EventEmitter<any> = new EventEmitter();

  title$ = new BehaviorSubject<string>("");
  menuItemsCopy$ = new BehaviorSubject<any>([]);
  
  filteredMenuItems: any[];
  menuItemsCopy: any[];
  showMenu: boolean = false;

  subscription: Subscription;
  
  constructor() {}

  ngOnInit(): void {
    this.title$.next(this.title);
    this.menuItemsCopy$.next( _.cloneDeep( this.menuItems ) );
    this.subscription = this.title$.subscribe( data => {  this.filterList(); } );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.title$.next(this.title);
  }

  onOpen(event) {
    this.showMenu = true;
    this.isActive = !this.isActive;
  }

  onClose(event) {
    this.showMenu = false;
    this.isActive = !this.isActive;
  }

  selectItem(event, item) {
    this.onClose(event);
    this.select.emit( item );
    this.title = item.name;
    this.title$.next(this.title);
  }

  filterList(): void {
    this.filteredMenuItems = this.menuItemsCopy$.value.filter(item => item.name.indexOf(this.title) === -1);
    this.menuItems = this.filteredMenuItems;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

<app-widget>

    <div class="row">
        <div class="col-6">
            <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
                <div class="d-flex">
                    <span class="title section-card-title">{{ 'panels.lossRatioOperational' | translate }}</span>
                    <app-btn-gobernance-info 
                        class="ml-2" 
                        [endpoint]="endpoint" 
                        [endpointParams]="endpointParams"
                        *ngIf="hasGobernanceInfo">
                    </app-btn-gobernance-info>
                </div>
            </div> 
            <app-loss-ratio-map></app-loss-ratio-map>
        </div>
        <div class="col-6 d-flex flex-column justify-content-between sections-container">
            <div>
                <app-loss-ratio-info-evolution></app-loss-ratio-info-evolution>
            </div>
        
            <div class="d-block">
                <app-loss-ratio-info-line-graph></app-loss-ratio-info-line-graph>
            </div>
        
            <div>
                <app-loss-ratio-info-table></app-loss-ratio-info-table>
            </div>
        </div>
    </div>

  </app-widget>
  
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancellation-renewal',
  templateUrl: './cancellation-renewal.component.html',
  styleUrls: ['./cancellation-renewal.component.scss']
})
export class CancellationRenewalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

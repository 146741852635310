<component-loader *ngIf="!loaded" [width]="580" [height]="140"></component-loader>

<div class="d-flex justify-content-center" *ngIf="loaded">
    <div class="legends-container d-flex">
        <app-legend
            [name]="legend.name" 
            [colorCode]="legend.color_code" 
            [type]="legend.type" 
            [gradient]="legend.gradient" 
            *ngFor="let legend of legends">
        </app-legend>
    </div>
</div>
<div class="d-flex justify-content-center align-items-center mt-3" *ngIf="loaded">
    <div class="mr-3">
        <app-semicircle-pie
            [items]="tnpsItems"
            [kpiInfo]="tnpsKpi"
            [width]="200">
        </app-semicircle-pie>
    </div>
    <div class="d-flex flex-column">
        <app-velocimeter-info
            [infoTitle]="infoTitle"
            [infoActualValue]="responseRate?.surveys_answered"
            [infoTotalValue]="responseRate?.surveys_sent"
            [infoPercentage]="responseRate?.response_rate">
        </app-velocimeter-info>
    </div>
</div>


<!-- <velocimeter *ngIf="loaded"
    [viewBox]="viewBox"
    [strokeWidth]="strokeWidth"
    [target]="target"
    [currentValue]="currentValue"
    [mainValue]="qualityNPS?.value"
    [secondValue]="qualityNPS?.target"
    [diff]="qualityNPS?.diff"
    [trend]="qualityNPS?.diff_trend"
    [hasInfo]="hasInfo"
    [hAlign]="hAlign"
    [vAlign]="vAlign"
    [infoTitle]="infoTitle"
    [infoActualValue]="responseRate?.surveys_answered"
    [infoTotalValue]="responseRate?.surveys_sent"
    [infoPercentage]="responseRate?.response_rate"
    [barOrientation]="barOrientation"
    >
</velocimeter> -->
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';

@Injectable({
  providedIn: 'root'
})
export class ClientsVocNpsService {

  graphName: string = 'clientsNPS';
  panelOptions: any[] = [
    { id: "rejectionsCompany", name: "panels.company", selected: true, graphName: this.graphName },
    { id: "rejectionsRegulator", name: "panels.regulator", selected: false, graphName: this.graphName }
  ];
  panelSelectedOption$ = new BehaviorSubject<any>(this.panelOptions[0]);
  tnpsSelected$ = new BehaviorSubject<any>([]);

  surveyTypeDefaultValue: string = "Asistencia";
  itemsClientsSurveys: any[] = [
    { id: "Asistencia", type: "clientSurvey", name: "Asistencia", selected: true }
  ]

  dropdownOptions = [{
    id: '',
    type: 'clients-nps',
    name: "Todas",
    selected: true
  }];

  constructor(
    private apimstService: ApimstService,
    private eventsService: EventsService
  ) { }

  getGraphName() {
    return this.graphName;
  }

  getSwitherOptions() {
    return this.panelOptions;
  }

  getDropdownDefaultOptions() {
    return this.dropdownOptions;
  }

  getSurveyTypeMaster() {
    return this.apimstService.getSurveyTypeMaster();
  }

  setSelectedReason(option: string = "") {
    // for (let item of this.itemsClientsSurveys) item.selected = false;
    // this.itemsClientsSurveys.filter( item => item.id == option )[0].selected = true;
    this.eventsService.clientsSurveys$.next(option);
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pie-chart-dialog',
  templateUrl: './pie-chart-dialog.component.html',
  styleUrls: ['./pie-chart-dialog.component.scss']
})
export class PieChartDialogComponent implements OnInit {

  sectionTitle: string;
  view: any[];
  results: any;
  gradient: boolean;
  showLegend: boolean;
  showLabels: boolean;
  isDoughnut: boolean;
  legendPosition: string;
  legendTitle: string;
  tooltipDisabled: boolean;
  animations: boolean;
  colorScheme: any;
  arcWidth: number;
  tooltipTitle: string;
  tooltipNumber: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.sectionTitle = data.sectionTitle,
    this.results = data.results,
    this.view = data.view,
    this.gradient = data.gradient,
    this.showLegend = data.showLegend,
    this.showLabels = data.showLabels,
    this.isDoughnut = data.isDoughnut,
    this.legendPosition = data.legendPosition,
    this.legendTitle = data.legendTitle,
    this.tooltipDisabled = data.tooltipDisabled,
    this.animations = data.animations,
    this.colorScheme = data.colorScheme,
    this.arcWidth = data.arcWidth,
    this.tooltipTitle = data.tooltipTitle,
    this.tooltipNumber = data.tooltipNumber
  }

  // Method to format the labels for the pie graph
  labelFormatting(series: any[], name: string): string {
    const item = series.filter(data => data.name === name);
    if (item.length > 0) {
      return Intl.NumberFormat("de-DE").format(item[0].value) + "%";
    }
    return name;
  }

  activate(e) {
    this.tooltipTitle = e['value'].label;
    this.tooltipNumber = Intl.NumberFormat("de-DE").format(e['value'].value) + "%";
  }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss']
})
export class ControlPanelComponent implements OnInit {

  headerTitle: string = 'home.managementTitle';
  routerLink: string = '/portal';

  sikTitle: string = 'controlPanel.sikTitle';
  sikDescription: string = 'controlPanel.sikDescription';
  sikButtonLabel: string = 'labels.open';
  sikBg: string = 'assets/img/sik-card-bg.png';
  sikRouterLink: string = '/prehome';

  marketsTitle: string = 'controlPanel.marketsTitle';
  marketsDescription: string = 'controlPanel.marketsDescription';
  marketsButtonLabel: string = 'labels.open';
  marketsBg: string = 'assets/img/markets-card-bg.png';
  marketsLink: string = environment.marketsURL;

  misTitle: string = 'controlPanel.misTitle';
  misDescription: string = 'controlPanel.misDescription';
  misButtonLabel: string = 'labels.open';
  misBg: string = 'assets/img/mis-card-bg.png';
  misLink: string = environment.misURL;

  constructor() { }

  ngOnInit(): void {
  }

}

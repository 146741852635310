<app-wrapper>

  <div class="wrapper_grid">

    <div class="quality">
      <app-quality></app-quality>
    </div>

    <div class="in-quality">
      <app-quality-internal-process></app-quality-internal-process>
    </div>

    <div class="sales-complaints">
      <app-quality-claims></app-quality-claims>
    </div>

    <div class="nps-sales">
      <app-nps-sale></app-nps-sale>
    </div>

  </div>

</app-wrapper>

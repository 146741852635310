import { Component, OnInit } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';

@Component({
  selector: 'app-document-management-home',
  templateUrl: './document-management-home.component.html',
  styleUrls: ['./document-management-home.component.scss']
})
export class DocumentManagementHomeComponent implements OnInit {

  items: any[] = [
    {
      title: "INFORMACIÓN DE DIRECCIÓN",
      subtitle: "",
      description: "Tile 1 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur pulvinar lacus vel fringilla ultricies. Mauris viverra tortor dolor, id venenatis leo suscipit vel. Pellentesque tincidunt libero eget magna rhoncus vehicula. Mauris est ipsum, vehicula vel sapien eget, vestibulum finibus ligula. Sed semper faucibus nisl, sit amet scelerisque augue lacinia nec.",
      backgroundColor: COLORS.darkGreen,
      backgroundImage: "assets/img/dm-home-tile-1.jpg"
    },
    {
      title: "CONTROL DE GESTIÓN: ANALÍTICA",
      subtitle: "",
      description: "Tile 2 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur pulvinar lacus vel fringilla ultricies. Mauris viverra tortor dolor, id venenatis leo suscipit vel. Pellentesque tincidunt libero eget magna rhoncus vehicula. Mauris est ipsum, vehicula vel sapien eget, vestibulum finibus ligula. Sed semper faucibus nisl, sit amet scelerisque augue lacinia nec.",
      backgroundColor: COLORS.dgGray,
      backgroundImage: "assets/img/dm-home-tile-2.jpg"
    },
    {
      title: "CAPITAL Y SOLVENCIA",
      subtitle: "",
      description: "Tile 3 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur pulvinar lacus vel fringilla ultricies. Mauris viverra tortor dolor, id venenatis leo suscipit vel. Pellentesque tincidunt libero eget magna rhoncus vehicula. Mauris est ipsum, vehicula vel sapien eget, vestibulum finibus ligula. Sed semper faucibus nisl, sit amet scelerisque augue lacinia nec.",
      backgroundColor: COLORS.dgGray,
      backgroundImage: "assets/img/dm-home-tile-3.jpg"
    },
    {
      title: "BALANCE",
      subtitle: "(SUELDOS)",
      description: "Tile 4 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur pulvinar lacus vel fringilla ultricies. Mauris viverra tortor dolor, id venenatis leo suscipit vel. Pellentesque tincidunt libero eget magna rhoncus vehicula. Mauris est ipsum, vehicula vel sapien eget, vestibulum finibus ligula. Sed semper faucibus nisl, sit amet scelerisque augue lacinia nec.",
      backgroundColor: COLORS.darkGreen,
      backgroundImage: "assets/img/dm-home-tile-4.jpg"
    },
    {
      title: "RECURSOS AJENOS",
      subtitle: "(INFORMACIÓN DE GESTIÓN)",
      description: "Tile 5 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur pulvinar lacus vel fringilla ultricies. Mauris viverra tortor dolor, id venenatis leo suscipit vel. Pellentesque tincidunt libero eget magna rhoncus vehicula. Mauris est ipsum, vehicula vel sapien eget, vestibulum finibus ligula. Sed semper faucibus nisl, sit amet scelerisque augue lacinia nec.",
      backgroundColor: COLORS.dgGray,
      backgroundImage: "assets/img/dm-home-tile-5.jpg"
    },
    {
      title: "ACTIVO",
      subtitle: "(INFORMACIÓN DE GESTIÓN)",
      description: "Tile 7 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur pulvinar lacus vel fringilla ultricies. Mauris viverra tortor dolor, id venenatis leo suscipit vel. Pellentesque tincidunt libero eget magna rhoncus vehicula. Mauris est ipsum, vehicula vel sapien eget, vestibulum finibus ligula. Sed semper faucibus nisl, sit amet scelerisque augue lacinia nec.",
      backgroundColor: COLORS.gray,
      backgroundImage: "assets/img/dm-home-tile-6.jpg"
    },
    {
      title: "INFORMACIÓN COMERCIAL Y CLIENTES",
      subtitle: "",
      description: "Tile 7 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur pulvinar lacus vel fringilla ultricies. Mauris viverra tortor dolor, id venenatis leo suscipit vel. Pellentesque tincidunt libero eget magna rhoncus vehicula. Mauris est ipsum, vehicula vel sapien eget, vestibulum finibus ligula. Sed semper faucibus nisl, sit amet scelerisque augue lacinia nec.",
      backgroundColor: COLORS.dgGray,
      backgroundImage: "assets/img/dm-home-tile-7.jpg"
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

  handleItemClicked(event) {
    console.log(event);
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'
import { QualityIndicator } from 'src/app/shared/models/quality-indicator';
import { environment } from '../../../../environments/environment';
import { ATLAS_ENDPOINTS } from 'src/app/shared/globals/globals';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import _ from 'lodash';

@Component({
  selector: 'app-quality',
  templateUrl: './quality.component.html',
  styleUrls: ['./quality.component.scss']
})
export class QualityComponent implements OnInit, OnDestroy {
  
  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "sale-quality-complaints--quality";

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;

  qualityEarlyFallIndicator: QualityIndicator;
  qualityPolicyCancellationsIndicator: QualityIndicator;
  qualityPolicyCustomerCollectIndicator: QualityIndicator;

  qualityEarlyFallIndicatorLoaded: boolean = false;
  qualityPolicyCancellationsIndicatorLoaded: boolean = false;
  qualityPolicyCustomerCollectIndicatorLoaded: boolean = false;

  percentagePrevious: string = "labels.pm";

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private atlasHelper: AtlasHelperService,
    private i18n: I18nService
  ) { }

  ngOnInit(): void {
    this.subscribeToActiveLanguage();

    this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        if (data.length !== 0) {
          this.filtersValues = data;
          this.qualityEarlyFallIndicatorLoaded = false;
          this.qualityPolicyCancellationsIndicatorLoaded = false;
          this.qualityPolicyCustomerCollectIndicatorLoaded = false;

          // Premium - Discreet calls
          this.retrieveQualityIndicator('ef', data); // Quality early fall
          this.retrieveQualityIndicator('pc', data); // Quality policy cancellations
          this.retrieveQualityIndicator('cc', data); // Quality policy customer collect
        }
      }
    );

  }

  // Method to retrieve the quality indicator data
  private retrieveQualityIndicator(quality_indicator: string, filtersValues: any) {
    this.apimstService.getQualityIndicators(quality_indicator, filtersValues)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (data: QualityIndicator) => {
        switch(quality_indicator) {
          case 'ef':
            this.qualityEarlyFallIndicator = data;
            this.qualityEarlyFallIndicatorLoaded = true;
            break;
          case 'pc':
            this.qualityPolicyCancellationsIndicator = data;
            this.qualityPolicyCancellationsIndicatorLoaded = true;
            break;
          case 'cc':
            this.qualityPolicyCustomerCollectIndicator = data;
            this.qualityPolicyCustomerCollectIndicatorLoaded = true;
            break;
          default:
            break;  
        }
      }
    );
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id));
    this.endpointParams.push({ name: "language", value: lang })
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<ngx-charts-chart
  [view]="[width, height]"
  [showLegend]="legend"
  [legendOptions]="legendOptions"
  [activeEntries]="activeEntries"
  [animations]="animations"
  (legendLabelActivate)="onActivate($event, undefined, true)"
  (legendLabelDeactivate)="onDeactivate($event, undefined, true)"
  (legendLabelClick)="onClick($event)"
>
  <svg:g [attr.transform]="transform" class="bar-chart chart">

    <!-- X axis -->
    <svg:g
      ngx-charts-x-axis
      *ngIf="xAxis"
      [xScale]="groupScale"
      [dims]="dims"
      [showLabel]="showXAxisLabel"
      [labelText]="xAxisLabel"
      [trimTicks]="trimXAxisTicks"
      [rotateTicks]="rotateXAxisTicks"
      [maxTickLength]="maxXAxisTickLength"
      [tickFormatting]="xAxisTickFormatting"
      [ticks]="xAxisTicks"
      [xAxisOffset]="dataLabelMaxHeight.negative"
      (dimensionsChanged)="updateXAxisHeight($event)"
    ></svg:g>

    <!-- Left Y axis -->
    <svg:g
      ngx-charts-y-axis
      *ngIf="yAxis"
      [yScale]="valueScale"
      [dims]="dims"
      [showGridLines]="showGridLines"
      [showLabel]="showYAxisLabel"
      [labelText]="yAxisLabel"
      [trimTicks]="trimYAxisTicks"
      [maxTickLength]="maxYAxisTickLength"
      [tickFormatting]="yAxisTickFormatting"
      [showGridLines]="showGridLines"
      (dimensionsChanged)="updateYAxisWidth($event)"
    ></svg:g>

    <!-- Rigth Y axis -->
    <svg:g
      ngx-charts-y-axis
      *ngIf="yAxis"
      [yScale]="synchronizedYAxis ? valueScale : yScaleLine"
      [dims]="dims"
      [yOrient]="yOrientRight"
      [showGridLines]="showRightGridLines"
      [showLabel]="showRightYAxisLabel"
      [labelText]="yAxisLabelRight"
      [trimTicks]="trimYAxisTicks"
      [maxTickLength]="maxYAxisTickLength"
      [tickFormatting]="yRightAxisTickFormatting"
      (dimensionsChanged)="updateYAxisWidth($event)"
    ></svg:g>

    <!-- Grouped vertical graph -->
    <svg:g
      ngx-charts-series-vertical
      *ngFor="let group of results; let index = index; trackBy: trackBy"
      [@animationState]="'active'"
      [attr.transform]="groupTransform(group)"
      [activeEntries]="activeEntries"
      [xScale]="innerScale"
      [yScale]="valueScale"
      [colors]="colors"
      [series]="group.series"
      [dims]="dims"
      [gradient]="gradient"
      [tooltipDisabled]="tooltipDisabled"
      [tooltipTemplate]="tooltipTemplate"
      [showDataLabel]="showDataLabel"
      [dataLabelFormatting]="dataLabelFormatting"
      [seriesName]="group.name"
      [roundEdges]="roundEdges"
      [animations]="animations"
      [noBarWhenZero]="noBarWhenZero"
      (select)="onClick($event, group)"
      (activate)="onActivate($event, group)"
      (deactivate)="onDeactivate($event, group)"
      (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event, index)"
    />
  </svg:g>
    
  <!-- Horizontal graph -->
  <svg:g *ngIf="addHorizontalGraph" [attr.transform]="transformLine" class="line-chart chart">
    <svg:g>
      <svg:g *ngFor="let series of lineChart">

        <svg:g
          ngx-charts-line-series
          [xScale]="groupScale"
          [yScale]="synchronizedYAxis ? valueScale : yScaleLine"
          [colors]="colorsLine"
          [data]="series"
          [activeEntries]="activeEntries"
          [scaleType]="scaleType"
          [curve]="curve"
          [rangeFillOpacity]="rangeFillOpacity"
          [animations]="animations"
        />
      </svg:g>

      <!-- Tooltip -->
      <svg:g
        ngx-charts-tooltip-area
        *ngIf="!tooltipDisabled"
        [dims]="dims"
        [xSet]="xSet"
        [xScale]="groupScale"
        [yScale]="yScaleLine"
        [results]="combinedSeries"
        [colors]="colorsLine"
        [tooltipDisabled]="tooltipDisabled"
        (hover)="updateHoveredVertical($event)"
      />

      <!-- Circle indicator tooltip -->
      <svg:g *ngFor="let series of lineChart">
        <svg:g
          ngx-charts-circle-series
          *ngIf="!tooltipDisabled"
          [xScale]="groupScale"
          [yScale]="yScaleLine"
          [colors]="colorsLine"
          [data]="series"
          [scaleType]="scaleType"
          [visibleValue]="activeEntries"
          [activeEntries]="activeEntries"
          [tooltipDisabled]="tooltipDisabled"
        />
      </svg:g>


      <!-- Bloc thats allows displaying the points of the horizontal line and hiding the line -->
      <!-- Its activated whit onlyPoints = true-->
      <svg:g *ngIf="onlyPoints">
        <svg:g *ngFor="let value of groupDomain">
          <svg:g
            ngx-charts-circle-series
            *ngIf="!tooltipDisabled"
            [xScale]="groupScale"
            [yScale]="synchronizedYAxis ? valueScale : yScaleLine"
            [colors]="colorsLine"
            [data]="lineChart[0]"
            [scaleType]="scaleType"
            [visibleValue]="value"
            [activeEntries]="activeEntries"
            [tooltipDisabled]="tooltipDisabled"
          />
        </svg:g>
      </svg:g>
    </svg:g>
  </svg:g>
</ngx-charts-chart>
import { Component, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventsService } from '../../services/events/events.service';

@Component({
  selector: 'app-network-graph',
  templateUrl: './network-graph.component.html',
  styleUrls: ['./network-graph.component.scss']
})
export class NetworkGraphComponent implements OnInit {
  @Input() data;
  @Input() nodes;
  @Input() width;
  @Input() height;
  @Input() onlyNodes;

  unsubscribe$: Subject<any> = new Subject<any>();
  svg: any;
  secondaryColours = {
    '#00C9A8': '#1ea9924D',
    '#C90000': '#a91e1e4D',
    '#5800C9': '#5a1ea94D',
    '#C9B500': '#a99b1e4D',
    '#C900B5': '#a91e9b4D'
  }

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    this.svg = d3.select("svg");
     
    this.eventsService.filteredTechnicalResults$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data !== undefined) {
          d3.selectAll("svg > *").remove();
          this.data = data;
          this.onlyNodes = true;
          this.draw();
        }
      });

    this.draw();

  }

  private draw() {
    let simulation;
    let root;
    let links = [];
    let nodes;
    let depthInt = [];
    let r;

    let g = this.svg.append("g");

    if (this.onlyNodes) {
      nodes = this.data.nodes;

      var strength = -5;
      if (nodes.length < 50) {
        strength = -100
      }

      simulation = d3.forceSimulation()
        .force("charge", d3.forceManyBody().strength(function () { return Math.round(Math.random() * (-100) + strength) }))
        .force('x', d3.forceX(this.width / 2))
        .force('y', d3.forceY(this.height / 2))
    } else {
      root = d3.hierarchy(this.data);
      links = root.links();
      nodes = root.descendants();

      root.each(d => depthInt.push(d.depth));

      r = d3.scaleLinear()
        .domain(d3.extent(depthInt))
        .range([10, 30]);

      simulation = d3.forceSimulation()
        .force("link", d3.forceLink().id(d => d.id).distance(function (d) { return Math.round(Math.random() * 80 + 50) }).strength(1))
        .force("charge", d3.forceManyBody().strength(-200))
        .force("center", d3.forceCenter(this.width / 2, this.height / 2));

      var link = g.append("g")
        .attr("class", "links")
        .selectAll("line")
        .data(links)
        .enter().append("line")
        .attr("stroke", "#9BA5AA")
        .attr("stroke-width", "2");
    }

    let node = g.append("g")
      .attr("class", "nodes")
      .selectAll("g")
      .data(nodes)
      .enter().append("g")
      .call(d3.drag()
        .on("start", function (d) {
          if (!d3.event.active) simulation.alphaTarget(0.3).restart();
          d.fx = d.x;
          d.fy = d.y;
        }.bind(this))
        .on("drag", function (d) {
          d.fx = d3.event.x;
          d.fy = d3.event.y;
        }.bind(this))
        .on("end", function (d) {
          if (!d3.event.active) simulation.alphaTarget(0);
          d.fx = null;
          d.fy = null;
        }.bind(this)));

    node.append("title")
      .text(function (d) {
        if (this.onlyNodes) {
          return d.name;
        } else {
          return d.data.name;
        }
      }.bind(this));

    let clonedArray = _.cloneDeep(nodes);
    clonedArray.sort(() => Math.random() - Math.random());
    let selectedNodes = clonedArray.slice(0, 15);

    let circles = node.append("circle")
      .style('cursor', 'pointer')
      .attr("r", this.onlyNodes ? 5 : d => r(d.height) * 0.75)
      .attr("fill", function (d) {
        for (let item of selectedNodes) {
          if (this.onlyNodes) {
            if (item.name === d.name) {
              return d.color;
            }
          } else {
            if (item.data.name === d.data.name) {
              return d.data.color;
            }
          }
        }
        return this.secondaryColours[(this.onlyNodes ? d.color : d.data.color)];
      }.bind(this))
      .on("click", function (d) {
        if (!d.hasOwnProperty('children')) {
          const data = {
            breadcrumbAction: 'add',
            id: this.onlyNodes ? d.id : d.data.id
          }
          this.eventsService.networkGraphSelectedNode$.emit(data);
        }
      }.bind(this));

    let ampliedLinks = [];
    if (links.length !== 0) {
      ampliedLinks = [_.cloneDeep(links[0])];
      ampliedLinks[0].target = ampliedLinks[0].source;
      ampliedLinks.push(links);
      ampliedLinks = ampliedLinks.flat();
    } else {
      ampliedLinks.push({
        target: {
          x: 0
        },
        source: {
          x: 0
        }
      });
    }

    var labels;
    labels = node.append("text")
      .text(function (d) {
        for (let item of selectedNodes) {
          if (this.onlyNodes) {
            if (item.name === d.name) {
              return d.name;
            }
          } else {
            if (item.data.name === d.data.name) {
              return d.data.name;
            }
          }
        }
      }.bind(this))
      .attr("fill", function (d) {
        if (this.onlyNodes) {
          for (let item of selectedNodes) {
            if (item.name === d.name) {
              return d.color;
            }
          }
        } else {
          return d.data.color;
        }
      }.bind(this))
      .data(this.onlyNodes ? nodes: ampliedLinks)

    simulation
      .nodes(nodes)
      .on("tick", function ticked() {
        if (this.onlyNodes) {
          node
            .attr("transform", function (d) {
              return "translate(" + d.x + "," + d.y + ")";
            });

          if (labels !== undefined) {
            labels
              .attr('x', 15)
              .attr('y', 5)
          }
        } else {
          link
            .attr("x1", function (d) { return d.source.x; })
            .attr("y1", function (d) { return d.source.y; })
            .attr("x2", function (d) { return d.target.x; })
            .attr("y2", function (d) { return d.target.y; })

          node
            .attr("transform", function (d) {
              return "translate(" + d.x + "," + d.y + ")";
            })

          if (labels !== undefined) {
            labels
              .attr('x', 15)
              .attr('x', function (d) {
                if (d.target.x === d.source.x) {
                  return (30);
                }
                else return (15);
              })
              .attr('y', 5)
          }
        }
      }.bind(this));

    if (!this.onlyNodes) {
      simulation.force("link")
        .links(links);
    }

    const zoom = d3.zoom()
      .scaleExtent([0.5, 4])
      .on("zoom", function zoomed() {
        g.attr("transform", d3.event.transform)
      });

    zoom(this.svg);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

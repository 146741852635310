import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-panel-list',
  templateUrl: './info-panel-list.component.html',
  styleUrls: ['./info-panel-list.component.scss']
})
export class InfoPanelListComponent implements OnInit {
  @Input() items: any[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

}

<div>
    <div class="d-flex justify-content-between w-100">
        <app-small-kpi 
            class="indicator-widget-element d-inline-block" 
            [title]=kpi.title
            [mainValue]=kpi.mainValue
            [mainValueUnit]=kpi.mainValueUnit
            [trend]=kpi.trend
            [percentagePrevious]="percentagePrevious"
            [percentage]=kpi.percentage
            *ngFor="let kpi of indicators">
        </app-small-kpi>
    </div>
</div>
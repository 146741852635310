<mat-tab-group mat-align-tabs="start" class="tab-group-gen">
  <mat-tab [label]="tab.tabLabel" *ngFor="let tab of tabElements">
    <div *ngIf="tab.tabType == 'raw'">
      <div [innerHTML]="tab.tabContent" class="html-content"></div>
      <!-- <app-color-link icon="open_in_new" color="primary" class="d-inline-block mb-1" *ngIf="tab.moreInfo">
      </app-color-link> -->
    </div>
    <div *ngIf="tab.tabType == 'list'">
      <app-tile-list [items]="tab.tabContent"></app-tile-list>
    </div>
  </mat-tab>
</mat-tab-group>

<component-loader [width]="" [height]="200" class="d-block mt-3" *ngIf="!pieGraphLoaded"></component-loader>
<div class="d-flex justify-content-around mt-3">
    <div>
        <div class="clients-stock-product-pie-chart pie-graph-gen mt-1" *ngIf="pieGraphLoaded">
            <ngx-charts-pie-chart 
                [view]="viewPie" 
                [scheme]="colorSchemePie" 
                [results]="productClassToPrint" 
                [gradient]="gradientPie"
                [legend]="showLegendPie"
                [legendTitle]="legendTitlePie" 
                [legendPosition]="legendPositionPie" 
                [labels]="showLabelsPie" 
                [doughnut]="isDoughnutPie"
                [arcWidth]="arcWidth"
                [tooltipDisabled]="tooltipDisabledPie"
                [animations]="animationsPie"
                [labelFormatting]="labelFormatting.bind(this, productClassToPrint)"
                [customColors]="customColorsPieGraph"
                (activate)="activate($event)">
                <ng-template #tooltipTemplate let-model="model">
                    <div class="pie-tooltip">
                        <span class="pie-tooltip-title d-block">{{ tooltipTitle }}</span>
                        <span class="pie-tooltip-data d-block">{{ tooltipNumber }}</span>
                    </div>
                </ng-template>
            </ngx-charts-pie-chart>
            <span class="material-icons fullscreen-icon text-light" (click)="openPieChartProductClassDialog()" *ngIf="pieGraphLoaded">fullscreen</span>
        </div>
    </div>
    <div>
        <div class="clients-stock-product-pie-chart pie-graph-gen mt-1" *ngIf="pieGraphLoaded">
            <ngx-charts-pie-chart 
                [view]="viewPie" 
                [scheme]="colorSchemePie" 
                [results]="BouquetToPrint" 
                [gradient]="gradientPie"
                [legend]="showLegendPie"
                [legendTitle]="legendTitlePie" 
                [legendPosition]="legendPositionPie" 
                [labels]="showLabelsPie" 
                [doughnut]="isDoughnutPie"
                [arcWidth]="arcWidth"
                [tooltipDisabled]="tooltipDisabledPie"
                [animations]="animationsPie"
                [labelFormatting]="labelFormatting.bind(this, BouquetToPrint)"
                [customColors]="customColorsPieGraph"
                (activate)="activate($event)">
                <ng-template #tooltipTemplate let-model="model">
                    <div class="pie-tooltip">
                        <span class="pie-tooltip-title d-block">{{ tooltipTitle }}</span>
                        <span class="pie-tooltip-data d-block">{{ tooltipNumber }}</span>
                    </div>
                </ng-template>
            </ngx-charts-pie-chart>
            <span class="material-icons fullscreen-icon text-light" (click)="openPieChartBouquetDialog()" *ngIf="pieGraphLoaded">fullscreen</span>
        </div>
    </div>
</div>


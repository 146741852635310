<app-wrapper>

    <div class="wrapper_grid">
        <div class="retention-according-to-record">
            <app-retention-according-to-record></app-retention-according-to-record>
        </div>
        <div class="voluntary-cancellation-record">
            <app-voluntary-cancellation-record></app-voluntary-cancellation-record>
        </div>        
        <div class="retention-per-action-record">
            <app-retention-per-action-record></app-retention-per-action-record>
        </div>
    </div>

</app-wrapper>
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COLORS } from 'src/app/shared/globals/globals';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { multi } from './data';

@Component({
  selector: 'app-clients-voc-polar',
  templateUrl: './clients-voc-polar.component.html',
  styleUrls: ['./clients-voc-polar.component.scss']
})
export class ClientsVocPolarComponent implements OnInit {

  loaded: boolean = false;

  multi: any[] = multi;
  view: any[] = [540, 240];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = false;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  labelTrim: boolean = false;

  colorScheme = {
    domain: [COLORS.darkOrange, COLORS.lightBlue, COLORS.darkYellow]
  };
  colorListCSS: any = ['dark-orange', 'light-blue', 'dark-yellow'];

  monthsNames: any;
  targetName: string = "";

  legends = [
    {
      name: 'Jul19',
      color: this.colorListCSS[0],
      type: 'line',
      gradient: 'v-gradient'
    },
    {
      name: 'Jul20',
      color: this.colorListCSS[1],
      type: 'line',
      gradient: 'v-gradient'
    },
    {
      name: 'Objetivo',
      color: this.colorListCSS[2],
      type: 'round',
      gradient: 'v-gradient'
    }
  ];

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any[];

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.subscribeToFiltersValues();
  }

  subscribeToFiltersValues(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: filtersValues => {

          this.resetValues();
          this.getTranslations();

          this.filtersValues = filtersValues;
          this.loaded = false;
          this.retrieveClientsClaimsGraph("ctny", this.filtersValues);
        }
      })
  }

  resetValues() {
    this.multi = [];
    this.legends = [];
  }

  getTranslations() {
    const monthsTranslations = this.translateService.get('abbreviatedMonthNames');
    const targetTranslation = this.translateService.get('labels.target');

    forkJoin([monthsTranslations, targetTranslation])
      .subscribe({
        next: translations => {
          this.monthsNames = translations[0];
          this.targetName = translations[1];
        }
      })
  }

  private retrieveClientsClaimsGraph(type_report: string, filtersValues: any) {
    this.apimstService.getClientsQualityClaims(type_report, filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: data => {
          this.loaded = true;

          if (data == null) return;

          this.multi = this.handleData(data);
          this.legends = this.createLegends(this.multi);

        }
      });
  }

  createLegends(series) {
    let legends: any[] = series.map((item, index) =>
    ({
      name: item.name,
      color: this.colorListCSS[index],
      type: 'line',
      gradient: 'v-gradient'
    }))

    return legends;
  }

  handleData(data: any[] = []) {
    const filteredValues = this.filterValues(data);
    const numericValues = this.setNumericValues(filteredValues);
    const series = this.createSeries(numericValues);
    return series;
  }

  filterValues(values: any[] = []) {
    return values.filter(v => v.name != "No asignado" && v.name != "No aplica");
  }

  setNumericValues(values: any[] = []) {
    return values.map(v => ({
      name: v.name,
      value: v.rate && v.rate != "" ? parseFloat(v.rate) : 0,
      value_previous_year: v.rate_previous_year && v.rate_previous_year != "" ? parseFloat(v.rate_previous_year) : 0,
      target: v.target && v.target != "" ? parseFloat(v.target) : 0,
    }));
  }

  createSeries(values: any[] = []) {
    let previousYearSeries: any[] = [];
    let actualYearSeries: any[] = [];
    let targetSeries: any[] = [];

    let series = [
      { name: `${this.getMonthName()}${this.getPreviousYearFilterValue().slice(2, 4)}`, series: [] },
      { name: `${this.getMonthName()}${this.getYearFilterValue().slice(2, 4)}`, series: [] },
      { name: this.targetName, series: [] },
    ]

    for (let value of values) {
      previousYearSeries.push({ name: value.name, value: value.value_previous_year })
      actualYearSeries.push({ name: value.name, value: value.value })
      targetSeries.push({ name: value.name, value: value.target })
    }

    series[0].series = previousYearSeries;
    series[1].series = actualYearSeries;
    series[2].series = targetSeries;

    return series;
  }

  getMonthFilterValue(filtersValues: any[] = []) {
    return filtersValues.filter(f => f.name == "month")[0].value;
  }

  getMonthName() {
    const index = this.filtersValues.filter(f => f.name == "month")[0].value;
    return this.monthsNames[index];
  }

  getYearFilterValue() {
    return this.filtersValues.filter(f => f.name == "year")[0].value;
  }

  getPreviousYearFilterValue() {
    return String(parseInt(this.filtersValues.filter(f => f.name == "year")[0].value) - 1);
  }

  onSelect(event) {
    // console.log(event);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

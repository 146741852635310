import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-management-header',
  templateUrl: './document-management-header.component.html',
  styleUrls: ['./document-management-header.component.scss']
})
export class DocumentManagementHeaderComponent implements OnInit {

  zLogoSrc: string = 'assets/img/logo_zurich_data_governance.svg';
  santanderLogoSrc: string = 'assets/img/logo_santander_data_governance.svg';

  menuLinks: any[] = [
    {
      name: "documentManagementSection.home",
      link: "/document-management/portal",
      visible: true
    },
    {
      name: "documentManagementSection.fileManager",
      link: "/document-management/file-manager",
      visible: true
    },
    {
      name: "documentManagementSection.metadataEditor",
      link: "/document-management/metadata-editor",
      visible: true
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}

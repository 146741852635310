<header class="d-flex justify-content-between">

  <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
    <div>
      <span class="title section-card-title">{{ dialogTitle | translate }}</span>
    </div>
  </div>

  <span class="h5 btn-close material-icons" (click)="closeDialog()">close</span>
</header>

<div class="img-container row mt-4 px-2">
  <img class="w-100" src="assets/img/{{image}}-{{currentLang}}.{{imageExtension}}" *ngIf="imageHasTranslation" />
  <img class="w-100" src="assets/img/{{image}}.{{imageExtension}}" *ngIf="!imageHasTranslation" />
</div>
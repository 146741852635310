<app-wrapper>

  <div class="wrapper_grid">
    <div class="santander-vs-zs-segmentation">
      <app-santander-vs-zs-segmentation></app-santander-vs-zs-segmentation>
    </div>
    <!-- <div class="santander-clients-penetration">
      <app-santander-clients-penetration></app-santander-clients-penetration>
    </div> -->
    <div class="santander-segmentation">
      <app-santander-segmentation></app-santander-segmentation>
    </div>
  </div>

</app-wrapper>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../../../../src/environments/environment';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { DateService } from '../date/date.service';
import { FILTERS_MONTHS_SELECTOR } from 'src/app/shared/globals/globals';
import { FiltersHelperService } from './filters-helper.service';
import { RequestsResultsStoreService } from '../store/requests-results-store/requests-results-store.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  // Base URL to connect with Microsoft Azure API
  baseURL: string = environment.azureApiUri;

  monthValue = this.dateService.getLastDayDate().month;
  yearValue = this.dateService.getLastDayDate().year;
  day = this.dateService.calculateDay(this.yearValue, this.monthValue);
  currencyValue = "3";
  budgetsValue: any[] = ["4"];
  countryValue = "";
  channelValue = "";
  subchannel: "";
  businessType: "";
  branch: "";
  productType: "";
  product: "";
  cancellationReason: "";
  itemsMonth = FILTERS_MONTHS_SELECTOR;
  itemsYear = this.filtersHelperService.getYearSelectorValues();
  itemsCurrency = [];
  itemsBudgets = [];

  currencyInfoSent$ = new BehaviorSubject<Object>({iso: "", symbol: ""});  

  selectedIndexMonth$ = new BehaviorSubject<number>(this.monthValue);
  selectedIndexYear$ = new BehaviorSubject<number>(this.yearValue);
  selectedIndexCurrency$ = new BehaviorSubject<string>(this.currencyValue);  
  selectedIndexBudgets$ = new BehaviorSubject<any>(this.budgetsValue);
  selectedIndexCountry$ = new BehaviorSubject<any>(this.countryValue);
  
  selectedCountryName$ = new BehaviorSubject<any>("");

  filtersMonthValue$ = new BehaviorSubject<string>(this.monthValue.toString());
  filtersYearValue$ = new BehaviorSubject<string>(this.yearValue.toString());
  filtersCurrencyValue$ = new BehaviorSubject<Object>(this.currencyValue);
  filtersBudgetsValue$ = new BehaviorSubject<any>(this.budgetsValue);

  itemsMonth$ = new BehaviorSubject<Array<any>>(this.itemsMonth);
  itemsYear$ = new BehaviorSubject<Array<any>>(this.itemsYear);
  itemsCurrency$ = new BehaviorSubject<Array<any>>(this.itemsCurrency);
  // _itemsBudgets = new BehaviorSubject<Object>(this.itemsBudgets);

  filtersOn: boolean = false;
  filtersBlockVisibility$ = new BehaviorSubject<Boolean>(this.filtersOn);
  filtersBlockVisibility = this.filtersBlockVisibility$.asObservable();

  currencyRawValues$ = new BehaviorSubject<Array<any>>([]);
  currencyRawValuesMaster$ = new BehaviorSubject<Array<any>>([]);
  channelRawValues$ = new BehaviorSubject<Array<any>>([]);
  budgetsRawValues$ = new BehaviorSubject<Array<any>>([]);
  userRawValues$ = new BehaviorSubject<any>(null);
  productRawValues$ = new BehaviorSubject<Array<any>>([]);
  claimOriginTypeRawValues$ = new BehaviorSubject<Array<any>>([]);
  complaintReasonRawValues$ = new BehaviorSubject<Array<any>>([]);
  paymentFormsRawValues$ = new BehaviorSubject<Array<any>>([]);
  paymentMethodsRawValues$ = new BehaviorSubject<Array<any>>([]);

  channelAllHierarchyValues$ = new BehaviorSubject<Array<any>>([]);
  macroChannelHierarchyValues$ = new BehaviorSubject<Array<any>>([]);
  channelHierarchyValues$ = new BehaviorSubject<Array<any>>([]);
  pointChannelHierarchyValues$ = new BehaviorSubject<Array<any>>([]);
  territorialHierarchyValues$ = new BehaviorSubject<Array<any>>([]);
  zoneHierarchyValues$ = new BehaviorSubject<Array<any>>([]);
  corporateSocietyHierarchyValues$ = new BehaviorSubject<Array<any>>([]);
  productSubCategoryHierarchyValues$ = new BehaviorSubject<Array<any>>([]);
  productFamilyHierarchyValues$ = new BehaviorSubject<Array<any>>([]);
  productTechnicalHierarchyValues$ = new BehaviorSubject<Array<any>>([]);
  bouquetBusinessHierarchyValues$ = new BehaviorSubject<Array<any>>([]);
  bouquetLobsHierarchyValues$ = new BehaviorSubject<Array<any>>([]);
  productMisHierarchyValues$ = new BehaviorSubject<Array<any>>([]);
  productTypeHierarchyValues$ = new BehaviorSubject<Array<any>>([]);

  filtersLoaded$ = new BehaviorSubject<Boolean>(false);
  countryChanged$ = new BehaviorSubject<Boolean>(false);

  disableProductType: Boolean = false;
  disableProductTypeBlock$ = new BehaviorSubject<Boolean>(this.disableProductType);
  disableProductTypeBlock = this.disableProductTypeBlock$.asObservable();
  
  requestsCaching: boolean = environment.requestsCaching;

  constructor(
    private httpClient: HttpClient,
    private utilsService: UtilsService,
    private dateService: DateService,
    private filtersHelperService: FiltersHelperService,
    private requestsStore: RequestsResultsStoreService
  ) { }

  // Observables to store filters values
  familyChannelFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  macroChannelFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  channelFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  pointChannelFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  corporateSocietyFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  territorialFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  zoneFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  productCategoryFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  productSubCategoryFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  productFamilyFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  productTechnicalFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  bouquetBusinessFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  bouquetLobsFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  productMisFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  productTypeFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  claimOriginTypeFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  complaintReasonFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  paymentFormsFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  paymentMethodsFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});
  budgetsFilterSelectionInfo$ = new BehaviorSubject<Object>({selected_items: [], selected_ids: []});

  dayFilterValue$ = new BehaviorSubject<String>(this.day);
  familyChannelFilterValues$ = new BehaviorSubject<Array<any>>([]);
  familyChannelFilterValuesBase$ = new BehaviorSubject<Array<any>>([]);

  familyChannelFilterValuesSelected$ = new BehaviorSubject<Array<any>>([]);
  macroChannelFilterValues$ = new BehaviorSubject<Array<any>>([]);
  macroChannelFilterValuesSelected$ = new BehaviorSubject<Array<any>>([]);
  channelFilterValues$ = new BehaviorSubject<Array<any>>([]);
  channelFilterValuesSelected$ = new BehaviorSubject<Array<any>>([]);
  pointChannelFilterValues$ = new BehaviorSubject<Array<any>>([]);
  pointChannelFilterValuesSelected$ = new BehaviorSubject<Array<any>>([]);
  corporateSocietyFilterValuesSelected$ = new BehaviorSubject<Array<any>>([]);
  territorialFilterValuesSelected$ = new BehaviorSubject<Array<any>>([]);
  zoneFilterValuesSelected$ = new BehaviorSubject<Array<any>>([]);
  productCategoryFilterValuesSelected$ = new BehaviorSubject<Array<any>>([]);
  productSubCategoryFilterValuesSelected$ = new BehaviorSubject<Array<any>>([]);
  productFamilyFilterValuesSelected$ = new BehaviorSubject<Array<any>>([]);
  productTechnicalFilterValuesSelected$ = new BehaviorSubject<Array<any>>([]);
  bouquetBusinessFilterValuesSelected$ = new BehaviorSubject<Array<any>>([]);
  bouquetLobsFilterValuesSelected$ = new BehaviorSubject<Array<any>>([]);
  productMisFilterValuesSelected$ = new BehaviorSubject<Array<any>>([]);
  
  countryFilterValues$ = new BehaviorSubject<string>(this.countryValue);      // Country
  currencyFilterValues$ = new BehaviorSubject<string>(this.currencyValue);    // Currency
  budgetsFilterValues$ = new BehaviorSubject<any>(this.budgetsValue);         // Budgets
  productCategoryFilterValues$ = new BehaviorSubject<Array<any>>([]);         // Product category
  productSubCategoryFilterValues$ = new BehaviorSubject<Array<any>>([]);      // Product subcategory
  productFamilyFilterValues$ = new BehaviorSubject<Array<any>>([]);           // Product family
  productTechnicalFilterValues$ = new BehaviorSubject<Array<any>>([]);        // Product technical
  bouquetBusinessFilterValues$ = new BehaviorSubject<Array<any>>([]);         // Bouquet business
  bouquetLobsFilterValues$ = new BehaviorSubject<Array<any>>([]);             // Bouquet LOBS
  corporateSocietyFilterValues$ = new BehaviorSubject<Array<any>>([]);        // Corporate society
  productMisFilterValues$ = new BehaviorSubject<Array<any>>([]);              // MIS product
  zoneFilterValues$ = new BehaviorSubject<Array<any>>([]);                    // Zone
  territorialFilterValues$ = new BehaviorSubject<Array<any>>([]);             // Territorial
  surveyTypeFilterValues$ = new BehaviorSubject<Array<any>>([]);              // Survey type
  claimTypeFilterValues$ = new BehaviorSubject<Array<any>>([]);               // Claim type
  claimOriginTypeFilterValues$ = new BehaviorSubject<Array<any>>([]);         // Claim origin type
  claimSubreasonFilterValues$ = new BehaviorSubject<Array<any>>([]);          // Claim subreason

  // URL's to get filters
  filtersCurrencyUrl: string = 'masters/currency';
  filtersProductUrl: string = 'masters/product';
  filtersChannelUrl: string = 'masters/channel';
  filtersClaimOriginTypeUrl: string = 'masters/reclamation_origin';
  filtersComplaintReasonUrl: string = 'masters/complaint_reason';
  filtersPaymentFormsUrl: string = 'masters/payment_forms';
  filtersPaymentMethodsUrl: string = 'masters/payment_methods';
  filtersBudgetsUrl: string = 'masters/budget';

  // Global filters values
  filtersValues$ = new BehaviorSubject<Array<any>>(this.getFiltersInitialValues());

  getFiltersInitialValues() {
    let initialMonthId = this.utilsService.padWithZeros(parseInt(this.dateService.getLastDayDate().month), 2);
    let initialMonthText = this.getFieldString(initialMonthId, this.itemsMonth$.value )
    let initialYearId = this.dateService.getLastDayDate().year.toString();
    let initialYearText = this.getFieldString(initialYearId, this.itemsYear$.value );
    let initialDayId = this.dateService.getLastDayDate().day.toString();
    let initialCurrencyText = this.getFieldString(this.currencyValue['0'].toString(), this.itemsCurrency$.value );
    let initialBudgetsText = this.getFieldString(this.budgetsFilterValues$.value, this.itemsBudgets );
    let monthValueItems: any = [{item_id: initialMonthId, item_text: initialMonthText}];
    let yearValueItems: any = [{item_id: initialYearId, item_text: initialYearText}];
    let dayValueItems: any = [{item_id: initialDayId, item_text: initialDayId}];
    let currencyValueItems: any = [{item_id: this.currencyValue, item_text: initialCurrencyText}];
    let budgetsValueItems: any = [{item_id: this.budgetsValue, item_text: initialBudgetsText}];
    let filtersValues: any[];
    filtersValues = [
      { name: 'month', value: initialMonthId, valueItems: monthValueItems },
      { name: 'year', value: initialYearId, valueItems: yearValueItems },
      { name: 'day', value: initialDayId, valueItems: dayValueItems },
      { name: 'currency', value: this.currencyValue, valueItems: currencyValueItems },
      { name: 'budgets', value: this.budgetsValue, valueItems: budgetsValueItems }
    ]

    return filtersValues;
  }

  updateBlockVisibility(show: boolean){
    this.filtersBlockVisibility$.next(show);
    this.filtersOn = show;
  }

  getBlockVisibility() {
    return this.filtersOn;
  }

  getMonthValue() {
    return this.filtersMonthValue$;
  }

  setDisableProductTypeBlock(disableProductTypeBlock: Boolean) {
    this.disableProductTypeBlock$.next(disableProductTypeBlock);
  }

  getFieldString(index: string, list: any[], fieldToCompare: string = 'id', stringField: string = 'name') {
    let name= "";

    for( let item of list) {
      if(index == item[fieldToCompare]) {
        name = item[stringField];
      }
    }

    return name;
  }

  getCurrencyValue() {
    return this.filtersCurrencyValue$;
  }

  updateMonthValue(data: string) {
    this.filtersMonthValue$.next(data);

    // Search and assign new selected item
    for (var i=0; i < this.itemsMonth.length; i++) {
      if (this.itemsMonth[i].id === data) {
        this.itemsMonth[i].selected = true
      } else {
        this.itemsMonth[i].selected = false
      }
    }
    this.itemsMonth$.next(this.itemsMonth);

  }

  getYearValue() {
    return this.filtersYearValue$;
  }

  updateYearValue(data: string) {
    this.filtersYearValue$.next(data);

      // Search and assign new selected item
      for (var i=0; i < this.itemsYear.length; i++) {
        if (this.itemsYear[i].id === data) {
          this.itemsYear[i].selected = true
        } else {
          this.itemsYear[i].selected = false
        }
      }
      this.itemsYear$.next(this.itemsYear);

  }

  getFiltersMonth() {
    return this.itemsMonth$;
  }

  getFiltersYear() {
    return this.itemsYear$;
  }

  searchLanguageInUserCountries() {
    let countryToSearch: string = this.selectedIndexCountry$.value.id;
    let language: string;

    for(let country of this.userRawValues$.value['countries']) {
      if(country.id == countryToSearch) {
        language = country.language.id;
      }
    }

    return language;
  }

  checkCountryFilterValue() {
    let countryFilterValue: any = {}; 
    this.filtersValues$.value.filter(function(field) {
      if(field.name == 'country') {
        countryFilterValue = field.value;
      }
    });

    return countryFilterValue;
  }

  // Methods to retrieve filters
  public getCurrencyFilters(): Observable<any>{
    const endpoint = this.baseURL + this.filtersCurrencyUrl;
    const response = this.httpClient.get(endpoint, {});
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, []);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)
    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: {}, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }
  public getProductFilters(country: any): Observable<any>{
    const endpoint = this.baseURL + this.filtersProductUrl;
    let body: any = { country };
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)
    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }
  public getChannelFilters(country: any): Observable<any>{
    const endpoint = this.baseURL + this.filtersChannelUrl;
    let body: any = { country };
    const response = this.httpClient.post(endpoint, body);
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, body);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)
    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: body, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }
  public getClaimOriginTypeFilters(): Observable<any>{
    const endpoint = this.baseURL + this.filtersClaimOriginTypeUrl;
    const response = this.httpClient.get(endpoint, {});
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, []);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)
    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: {}, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));
  }
  public getComplaintReasonFilters(): Observable<any>{
    const endpoint = this.baseURL + this.filtersComplaintReasonUrl;
    const response = this.httpClient.get(endpoint, {});
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, []);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)
    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: {}, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));    
  }
  public getPaymentFormsFilters(): Observable<any>{
    const endpoint = this.baseURL + this.filtersPaymentFormsUrl;
    const response = this.httpClient.get(endpoint, {});
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, []);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)
    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: {}, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));     
  }
  public getPaymentMethodsFilters(): Observable<any>{
    const endpoint = this.baseURL + this.filtersPaymentMethodsUrl;
    const response = this.httpClient.get(endpoint, {});
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, []);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)
    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: {}, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));     
  }
  public getBudgetsFilters(): Observable<any>{
    const endpoint = this.baseURL + this.filtersBudgetsUrl;
    const response = this.httpClient.get(endpoint, {});
    if(!this.requestsCaching) return response;
    const requestToSearch = this.requestsStore.searchRequest(endpoint, []);
    if (requestToSearch.length > 0) return of(requestToSearch[0].results)
    return response.pipe(map((data) => {
      let state = { endpoint: endpoint, params: {}, results: data }
      this.requestsStore.addRequestState(state);
      return data;
    }));     
  }

}

import { Component, OnInit } from '@angular/core';
import { Router, RouterEvent, Event, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { leftRouting } from '../../../left-routing';
import { rightRouting } from '../../../right-routing';
import { MENU } from 'src/app/shared/globals/globals';
import { Subscription } from 'rxjs';
import { NavigationService } from '../../services/navigation/navigation.service';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit {

  // Component variables
  currentRoute: string;
  lastRoute: string;
  isFirstPage: boolean;
  isLastPage: boolean;
  leftRouting: any;
  rightRouting: any;

  dots: any[] = [];
  itemsNumber: number = 0;
  activeItemIndex: number = 0;
  nextItem: string = '';
  prevItem: string = '';

  menu: any[] =  MENU;

  subscription: Subscription;

  // routes: any[] = routes;

  // Default constructor
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationService
  ) { }

  // OnInit
  ngOnInit(): void {

    // Get route info for dots and arrows
    this.subscription = this.navigationService.navigationItems$
      .subscribe(
        data => {
          this.itemsNumber = data[0]['itemsNumber']
          this.activeItemIndex = data[0]['activeItemIndex'];

          if(this.activeItemIndex <= 1) {
            this.prevItem = "/";
          } else {
            this.prevItem = data[0]['items'][this.activeItemIndex - 2]['link'];
          }

          if(this.activeItemIndex == this.itemsNumber) {
            this.nextItem = "/";
          } else {
            this.nextItem = data[0]['items'][this.activeItemIndex]['link'];
          }

          this.dots = data[0]['items'];

        }
      )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // Method do redirect to previous page
  toPreviousPage() {
    const previousRoute = leftRouting.filter(route => route.name === this.lastRoute);

    if (previousRoute.length !== 0) {
      const route = previousRoute[0].value;
      this.router.navigate([route]);
    }

  }

  // Method to redirect to next page
  toNextPage() {
    const nextRoute = rightRouting.filter(route => route.name === this.lastRoute);

    if (nextRoute.length !== 0) {
      const route = nextRoute[0].value;
      this.router.navigate([route]);
    }
  }

}

<div class="info-card-container">
    <div class="info-card-title">
        <div class="d-flex">
            <span>{{ cardInfo?.title }}</span>
            <mat-icon *ngIf="cardInfo?.link" class="open-in-new-icon info-card-icon ml-2 dg-gray-color" (click)="goToTechnicalInfo(cardInfo.link, cardInfo.type)">open_in_new</mat-icon>
        </div>
    </div>
    <div class="info-card-description">
        <div class="d-flex">
            <span [innerHtml]="cardInfo.description"></span>
            <mat-icon *ngIf="cardInfo?.isEditable && hasEditPermission" class="edit-icon info-card-icon ml-2 dg-gray-color" (click)="textEdition(cardInfo.guid)">mode_edit</mat-icon>
        </div>
        <div class="mt-2 mb-2" *ngIf="openTextEditor">
            <app-html-editor [textEditor]="textEditor" [height]="textEditorHeight"
            [width]="textEditorWidth" [htmlContent]="cardInfo?.description" [elementGuid]="cardInfo.guid"></app-html-editor>
        </div>
    </div>
    <span *ngIf="cardInfo?.formula" class="info-card-description">{{ cardInfo?.formula }}</span>
    <!-- <span *ngIf="cardInfo?.link" class="info-card-link d-flex">{{ cardInfo?.link }}<mat-icon
            class="open-in-new-icon ml-2">open_in_new</mat-icon></span> -->
</div>
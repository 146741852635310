export var lineChartSeries = [

    {
      name: 'Tasa Renovación',
      series: [
        {
          name: 'Jul19',
          value: 4
        },
        {
          name: 'Ago19',
          value: 5
        },
        {
          name: 'Sep19',
          value: 2
        },
        {
          name: 'Oct19',
          value: 5
        },
        {
          name: 'Nov19',
          value: 3
        },
        {
          name: 'Dic19',
          value: 4
        },
        {
          name: 'Ene20',
          value: 2
        },
        {
          name: 'Feb20',
          value: 7
        },
        {
          name: 'Mar20',
          value: 6
        },
        {
          name: 'Abr20',
          value: 5
        },
        {
          name: 'May20',
          value: 3
        },
        {
          name: 'Jun20',
          value: 6
        },
        {
          name: 'Jul20',
          value: 4
        }
      ]
    },
  
  ];
  
  export var barChart: any = [
    {
      name: 'Jul19',
      series: [
        {
          name: "Vivienda",
          value: 1300
        },
        {
          name: "Vida",
          value: 3900
        },
        {
          name: "Auto",
          value: 3900
        },
        {
          name: "Otros",
          value: 3900
        }
      ]
    },
    {
      name: 'Ago19',
      series: [
        {
          name: "Vivienda",
          value: 1000
        },
        {
          name: "Vida",
          value: 2000
        },
        {
          name: "Auto",
          value: 3900
        },
        {
          name: "Otros",
          value: 3900
        }
      ]
    },
    {
      name: 'Sep19',
      series: [
        {
          name: "Vivienda",
          value: 1200
        },
        {
          name: "Vida",
          value: 2550
        },
        {
          name: "Auto",
          value: 3900
        },
        {
          name: "Otros",
          value: 3900
        }
      ]
    },
    {
      name: 'Oct19',
      series: [
        {
          name: "Vivienda",
          value: 1000
        },
        {
          name: "Vida",
          value: 2850
        },
        {
          name: "Auto",
          value: 3900
        },
        {
          name: "Otros",
          value: 3900
        }
      ]
    },
    {
      name: 'Nov19',
      series: [
        {
          name: "Vivienda",
          value: 1100
        },
        {
          name: "Vida",
          value: 2050
        },
        {
          name: "Auto",
          value: 3900
        },
        {
          name: "Otros",
          value: 3900
        }
      ]
    },
    {
      name: 'Dic19',
      series: [
        {
          name: "Vivienda",
          value: 1100
        },
        {
          name: "Vida",
          value: 2050
        },
        {
          name: "Auto",
          value: 3900
        },
        {
          name: "Otros",
          value: 3900
        }
      ]
    },
    {
      name: 'Ene20',
      series: [
        {
          name: "Vivienda",
          value: 1100
        },
        {
          name: "Vida",
          value: 2050
        },
        {
          name: "Auto",
          value: 3900
        },
        {
          name: "Otros",
          value: 3900
        }
      ]
    },
    {
      name: 'Feb20',
      series: [
        {
          name: "Vivienda",
          value: 1100
        },
        {
          name: "Vida",
          value: 2050
        },
        {
          name: "Auto",
          value: 3900
        },
        {
          name: "Otros",
          value: 3900
        }
      ]
    },
    {
      name: 'Mar20',
      series: [
        {
          name: "Vivienda",
          value: 1100
        },
        {
          name: "Vida",
          value: 2050
        },
        {
          name: "Auto",
          value: 3900
        },
        {
          name: "Otros",
          value: 3900
        }
      ]
    },
    {
      name: 'Abr20',
      series: [
        {
          name: "Vivienda",
          value: 1100
        },
        {
          name: "Vida",
          value: 2050
        },
        {
          name: "Auto",
          value: 3900
        },
        {
          name: "Otros",
          value: 3900
        }
      ]
    },
    {
      name: 'May20',
      series: [
        {
          name: "Vivienda",
          value: 1100
        },
        {
          name: "Vida",
          value: 2050
        },
        {
          name: "Auto",
          value: 3900
        },
        {
          name: "Otros",
          value: 3900
        }
      ]
    },
    {
      name: 'Jun20',
      series: [
        {
          name: "Vivienda",
          value: 1100
        },
        {
          name: "Vida",
          value: 2050
        },
        {
          name: "Auto",
          value: 3900
        },
        {
          name: "Otros",
          value: 3900
        }
      ]
    },
    {
      name: 'Jul20',
      series: [
        {
          name: "Vivienda",
          value: 1100
        },
        {
          name: "Vida",
          value: 2050
        },
        {
          name: "Auto",
          value: 3900
        },
        {
          name: "Otros",
          value: 3900
        }
      ]
    }
  ];
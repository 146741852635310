import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { conforms } from "lodash";
import { EventsService } from "../../services/events/events.service";
import { LoginService } from "../../services/login/login.service";

@Component({
  selector: "app-user-bubble",
  templateUrl: "./user-bubble.component.html",
  styleUrls: ["./user-bubble.component.scss"],
})
export class UserBubbleComponent implements OnInit {
  username: string = "";
  @Output() buttonClicked: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private loginService: LoginService,
    private eventsService: EventsService
  ) {}

  ngOnInit(): void {
    this.usbscribeToUserInfo();
  }

  usbscribeToUserInfo() {
    this.eventsService.userInfoLoaded$.subscribe({
      next: (data) => {
        this.username = data["username"];

        if (this.username != undefined) {
          this.getInitials(this.username);
        }
      },
    });
  }

  handleClick(): void {
    this.buttonClicked.emit(true);
  }

  getInitials(name: string): void {
    let separatedByPointName: any = [];
    let entireName: string = "";

    if (name.includes(".")) {
      name.split(".");
      separatedByPointName = name.split(".");
      for (name of separatedByPointName) {
        entireName = entireName + name.substring(0, 1);
      }
    } else {
      entireName = name.substring(0, 2);
    }

    this.username = entireName;
  }
}

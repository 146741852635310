<div class="home-main-block-container">
    <div class="title-block">
        <span class="home-main-block-title">{{ 'home.portalSharedDataServices' | translate }}</span>
    </div>
    <div class="main-block row mx-0 mt-2">
        <div class="main-block-left col-6 p-0">
            <div class="logo-block">
                <img src="assets/img/logo_z.png">
                <img src="assets/img/logo_santander.png">
            </div>
            <img class="w-100" src="assets/img/bg-home-block.jpg">
        </div>
        <div class="main-block-right col-6 p-0 bg-secondary">
            <div class="button-group d-flex row mx-0">
                <div class="home-button home-button-direction col-6">
                    <a [routerLink]="['/control-panel']">
                        <span class="home-button-title h5 d-block">{{ 'home.managementTitle' | translate | uppercase }}</span>
                        <span class="home-button-description h5 d-block">{{ 'home.managementDescription' | translate }}</span>
                    </a>
                </div>
                <div class="home-button home-button-autoservice col-6">
                    <a [routerLink]="['/data-discovery/portal']">
                        <span class="home-button-title h5 d-block">{{ 'home.dataDiscoveryTitle' | translate | uppercase }}</span>
                        <span class="home-button-description h5 d-block">{{ 'home.dataDiscoveryDescription' | translate }}</span>
                    </a>
                </div>  
                <div class="home-button home-button-reports col-6">
                    <a [routerLink]="['/reports/portal']">
                        <span class="home-button-title h5 d-block">{{ 'home.reportsTitle' | translate | uppercase }}</span>
                        <span class="home-button-description h5 d-block">{{ 'home.reportsDescription' | translate }}</span>
                    </a>
                </div>
                <div class="home-button home-button-gobernance col-6">
                    <a [routerLink]="['/data-governance/portal']">
                        <span class="home-button-title h5 d-block">{{ 'home.dataGovernanceTitle' | translate | uppercase }}</span>
                        <span class="home-button-description h5 d-block">{{ 'home.dataGovernanceDescription' | translate }}</span>
                    </a>
                </div>
                <div class="col-6">
                </div>
                <div class="col-6 section-title mb-1">
                    <div>
                        <div [ngClass]="labelColor" class="section-title--name">{{ mainLabel1 }}</div>                      
                        <div [ngClass]="labelColor" class="section-title--name">{{ mainLabel2 }}</div>                     
                    </div>
                    <div  class="material-icons ml-3 home-button" (click)="openDialog()" *ngIf="mainLabel1">fullscreen</div> 
                </div>                     
            </div>
            <div class="bg-image">
                <img class="w-100" src="assets/img/bg-home-block-right.png">
            </div>
        </div>
    </div>
</div>
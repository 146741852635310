import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterInfoDialogComponent } from '../filter-info-dialog/filter-info-dialog.component';

@Component({
  selector: 'app-info-header',
  templateUrl: './info-header.component.html',
  styleUrls: ['./info-header.component.scss']
})
export class InfoHeaderComponent implements OnInit {

  @Input() title: string = "";
  @Input() dialogTitle: string = "";
  @Input() icon: string;
  @Input() image: string;
  @Input() imageExtension: string = "png";
  @Input() imageHasTranslation: boolean = true;

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  openInfoDialog(): void {
    let backDrop;
    const dialogRef = this.dialog.open(FilterInfoDialogComponent, {
        panelClass: ['mat-dialog-gen', 'mat-dialog-filter-info'],
        backdropClass: 'backdrop-dialog-filter-info',
        data : {
          dialogTitle: this.dialogTitle,
          image: this.image,
          imageExtension: this.imageExtension,
          imageHasTranslation: this.imageHasTranslation
        }
    });
    backDrop = document.querySelector('.cdk-overlay-container');
    backDrop.classList.add('overlay-dialog-filter-info');
    
    dialogRef.afterClosed().subscribe(result => {
      backDrop.classList.remove('overlay-dialog-filter-info');
    });
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { Subscription, Subject, forkJoin } from 'rxjs';
import { PortfolioChurnEntity } from 'src/app/shared/models/portfolio-churn-entity';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators'
import { trim, values } from 'lodash';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';

@Component({
  selector: 'app-renewals-cancellations',
  templateUrl: './renewals-cancellations.component.html',
  styleUrls: ['./renewals-cancellations.component.scss']
})
export class RenewalsCancellationsComponent implements OnInit, OnDestroy {

  // Options for chart creation
  view = [800, 200];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  legendTitle: string = '';
  legendPosition: string = '';
  showXAxisLabel: boolean = false;
  showYAxisLabel: boolean = true;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  yLeftAxisScaleFactor: string = '';
  yRightAxisScaleFactor: string = '';
  showGridLines: boolean = true;
  showRightGridLines: boolean = true;
  innerPadding: string = '10%';
  animations: boolean = false;
  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  tooltipDisabled: boolean = false;
  schemeType: string = "ordinal";
  roundDomains: boolean = true;
  noBarWhenZero: boolean = true;
  lineChartScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.lightPurple, COLORS.darkYellow, COLORS.lightBlue, '#08ded1']
  };
  comboBarScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.lightBlue, COLORS.lightBlue, '#085da6', '#0869aa']
  };
  customColors: any = [];
  roundEdges: boolean = false;
  activeEntries: any = [];
  trimXAxisTicks: boolean = true;
  trimYAxisTicks: boolean = true;
  rotateXAxisTicks: boolean = true;
  maxXAxisTickLength: number = 16;
  maxYAxisTickLength: number = 16;
  barPadding: number = 8;
  showPercentageSymbolInTooltip: boolean = true;

  // Component variables
  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();
  portfolioChurnGroupedVerticalGraph: any = [];
  portfolioChurnHorizontalGraph: any = [];

  nbName: string;
  cancellationName: string;
  renewalName: string;
  churnRateName: string;
  monthNames: string;
  loaded: boolean = false;
  titleLabel: string;

  data_total: any[] = [];
  data_sheet_names: any[] = [];
  data_filter_details: any[] = [];

  // Default constructor
  constructor(
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private translateService: TranslateService,
    private excelService: ExcelService
  ) { }

  // OnInit
  ngOnInit(): void {


    this.subscription = this.filtersService.filtersValues$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      data => {
        const nb = this.translateService.get('labels.nb');
        const renewal = this.translateService.get('labels.renewal');
        const cancellation = this.translateService.get('labels.cancellation');
        const churnRate = this.translateService.get('labels.churnRate');
        const months = this.translateService.get('abbreviatedMonthNames');
        const title = this.translateService.get('labels.portfolioRolling');

        forkJoin([nb, renewal, cancellation, churnRate, months, title])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
            next: result => {
              this.nbName = result[0];
              this.renewalName = result[1];
              this.cancellationName = result[2];
              this.churnRateName = result[3];
              this.monthNames = result[4];
              this.titleLabel = result[5];
            },
            complete: () => {
              if (data.length !== 0) {
                this.loaded = false;
                this.retrievePortfolioChurnData(data);
              }
            }
          });
      }
    );
  }

  // Method to retrieve the portfolio churn graph data
  private retrievePortfolioChurnData(filtersValues: any) {
    this.portfolioChurnGroupedVerticalGraph = [];
    this.portfolioChurnHorizontalGraph = [];
    this.data_total = [];
    this.subscription = this.apimstService.getPortfolioChurn(filtersValues).subscribe(
      data => {
        const portfolioChurnGraphData: Array<PortfolioChurnEntity> = data;
        const verticalGraphData: any = [];
        const horizontalGraphData: any = [];
        const headersToExport: any = [''];
        const churnRateToExport: any = [this.churnRateName + ' (%)'];

        const nbDataToExport: any = [this.nbName];
        const renewalDataToExport: any = [this.renewalName];
        const cancellationDataToExport: any = [this.cancellationName];

        if (portfolioChurnGraphData.length !== 0) {
          this.data_filter_details = portfolioChurnGraphData[0].filters;
        }

        for (let group of portfolioChurnGraphData) {
          const dataValue = this.monthNames[group.year] + ' ' + group.month.slice(2,4);
          const churnRateValue = (group.churn_rate || trim(group.churn_rate).length > 0) ? parseFloat(group.churn_rate.replace(/,/g, '')) : 0;
          horizontalGraphData.push(
            {
              name: dataValue,
              value: churnRateValue
            }
          );

          const nbValue = (group.certificate_new || trim(group.certificate_new).length > 0) ? parseFloat(group.certificate_new.replace(/,/g, '')) : 0;
          const renewalValue = (group.certificate_renovation || trim(group.certificate_renovation).length > 0) ? parseFloat(group.certificate_renovation.replace(/,/g, '')) : 0;
          const cancellationValue = (group.certificate_cancel || trim(group.certificate_cancel).length > 0) ? parseFloat(group.certificate_cancel.replace(/,/g, '')) : 0;

          verticalGraphData.push(
            {
              name: dataValue,
              series: [
                {
                  name: this.nbName,
                  value: nbValue
                },
                {
                  name: this.renewalName,
                  value: renewalValue
                },
                {
                  name: this.cancellationName,
                  value: cancellationValue
                }
              ]
            }
          );

          nbDataToExport.push(nbValue);
          renewalDataToExport.push(renewalValue);
          cancellationDataToExport.push(cancellationValue);

          headersToExport.push(dataValue);
          churnRateToExport.push(churnRateValue);

        }
        this.portfolioChurnHorizontalGraph.push(
          {
            name: this.churnRateName,
            series: horizontalGraphData
          }
        );
        this.portfolioChurnGroupedVerticalGraph = verticalGraphData;

        this.data_total.push(headersToExport, nbDataToExport, renewalDataToExport, cancellationDataToExport, churnRateToExport);
        this.data_total = [this.data_total];

        this.assignColorBar();
        this.loaded = true;
      },
      error => console.log("An error ocurred while retrieving portfolio churn graph data: " + error)
    );
  }

  // Method to assign the colors to the graph bars
  private assignColorBar() {
    this.customColors.push(
      {
        name: this.nbName,
        value: COLORS.lightBlue
      },
      {
        name: this.renewalName,
        value: COLORS.darkBlue
      },
      {
        name: this.cancellationName,
        value: COLORS.gray
      },
      {
        name: this.churnRateName,
        value: COLORS.pink
      })
  }

  // Method to format the y axis labels
  yAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value);
  }

  // Method to format the right y axis labels
  yRightAxisTickFormatting(value) {
    const formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();
    return formatPipe.transform(value)+'%';
  }

  // Method to export data to XSLX
  exportAsXLSX():void {
    this.data_sheet_names = [this.titleLabel];
    this.excelService.exportAsExcelFile(this.data_total, this.data_sheet_names, 'portfolio_rolling_chart', this.data_filter_details);
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

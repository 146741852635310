<p class="section-title mb-0">
  <span class="section-title--name">{{ 'panels.monthlySale' | translate }}</span>
  <!-- <app-btn-gobernance-info 
    class="ml-2" 
    [endpoint]="endpoint" 
    [endpointParams]="endpointParams"
    *ngIf="hasGobernanceInfo">
  </app-btn-gobernance-info> -->
  <span *ngIf="loaded" class="material-icons ml-2 fullscreen-icon" (click)="openDialog()">fullscreen</span>
  <mat-icon *ngIf="loaded" class="excel-icon ml-2 mb-2" svgIcon="excel" (click)="exportAsXLSX()" (disabled)="!loaded"></mat-icon>
</p>

<component-loader *ngIf="!loaded" [width]="" [height]="150"></component-loader>

<div class="ml-n3" *ngIf="loaded">
  <stacked-vertical-horizontal-graph
    [view]="view"
    [scheme]="comboBarScheme"
    [colorSchemeLine]="lineChartScheme"
    [customColors]="customColors"
    [results]="barChart"
    [animations]="animations"
    [lineChart]="lineChartSeries"
    [yAxisTickFormatting]="yAxisTickFormatting"
    [yLeftAxisScaleFactor]="yLeftAxisScaleFactor"
    [yRightAxisScaleFactor]="yRightAxisScaleFactor"
    [yRightAxisTickFormatting]="yRightAxisTickFormatting"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [legendTitle]="legendTitle"
    [legendPosition]="legendPosition"
    [showGridLines]="showGridLines"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [showRightYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [tooltipDisabled]="tooltipDisabled"
    [barPadding]="barPadding"
    [schemeType]="schemeType"
    [roundDomains]="roundDomains"
    [noBarWhenZero]="noBarWhenZero"
    [showTotalInTooltip]="showTotalInTooltip">
  </stacked-vertical-horizontal-graph>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Animations } from '../../animations/animations';
import { EventsService } from '../../services/events/events.service';

@Component({
  selector: 'app-dg-header',
  templateUrl: './dg-header.component.html',
  styleUrls: ['./dg-header.component.scss'],
  animations: [
    Animations.animateFilters
  ]
})
export class DgHeaderComponent implements OnInit {

  @Input() area: string;
  @Input() headerTitle: string;
  @Input() headerSubtitle: string;
  @Input() routerLink: string;
  @Input() hasSearchButton: boolean;
  areaLabel: string;
  isDataGovernancePortal: boolean = false;
  openSearchPanel: boolean = false;

  unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    if (this.area === 'business') {
      this.areaLabel = 'dataGovernanceSection.businessArea';
    } else if (this.area === 'technical') {
      this.areaLabel = 'dataGovernanceSection.technicalArea';
    }

    this.eventsService.openSearchPanel$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.openSearchPanel = data;
      });
  }

}

<app-wrapper>

    <div class="wrapper_grid">
        <div class="portfolio">
            <app-portfolio></app-portfolio>
        </div>
        <div class="gwp">
            <app-gwp></app-gwp>
        </div>
    </div>

</app-wrapper>

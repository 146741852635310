import { Component, OnInit } from '@angular/core';
import { AtlasHelperService } from 'src/app/shared/services/api-atlas/atlas-helper.service';
import { I18nService } from 'src/app/shared/services/i18n/i18n.service';
import { environment } from '../../../../environments/environment';
import { ATLAS_ENDPOINTS, COLORS } from 'src/app/shared/globals/globals';
import { takeUntil } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { SalesMainService } from './sales-main.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit {
  
  endpoint: string = environment.azureApiUriAtlas + ATLAS_ENDPOINTS.section;
  hasGobernanceInfo: boolean = false;
  endpointParams: any = [];
  activeLang: string = "";
  pageId: string = "sale-detail--sale";

  unsubscribe$: Subject<any> = new Subject<any>();

  // Component variables
  leftOption: string = 'panels.bonus';
  rightOption: string = 'panels.policy';
  chosenOption: string;
  graphName: string = 'salesDetails';

  title: string = "";
  dropdownId: string = 'salesMain';
  salesMainItems: any = [];

  planName: string;
  averageName: string;
  totalName: string;

  dailyLegend: boolean = false;
  monthlyLegend: boolean = false;
  legends: any = [];
  legendsToPrint: any = [];
  legendLoaded: boolean = false;

  colorList: any = [COLORS.darkBlue, COLORS.lightBlue, COLORS.lightOrange, COLORS.lightestBlue, COLORS.darkGreen, COLORS.darkYellow, COLORS.m6, COLORS.m8, COLORS.m12, COLORS.darkBlue, COLORS.lightBlue, COLORS.lightPurple, COLORS.darkestGreen, COLORS.lightGreen];
  colorListCSS: any = ['dark-blue', 'light-blue', 'light-orange', 'lightest-blue', 'dark-green', 'dark-yellow', 'm6', 'm8', 'm12', 'dark-blue', 'light-blue', 'dark-purple', 'darkest-green', 'light-green'];

  customColors: any = [];

  // Default constructor
  constructor(
    private atlasHelper: AtlasHelperService,
    private i18n: I18nService,
    private salesMainService: SalesMainService,
    private eventsService: EventsService,
    private translateService: TranslateService
  ) { }

  // OnInit
  ngOnInit(): void {
    this.setSelectorValues();
    this.setDefaultSelectedValue();
    this.subscribeToActiveLanguage();
    this.getTranslations();
    this.legendLoaded = false;
  }

  setSelectorValues() {
    this.salesMainItems = this.salesMainService.getSalesSelectorItems();
  }

  setDefaultSelectedValue() {
    this.salesMainService.setDefaultSelectedValue();
  }

  subscribeToActiveLanguage() {
    this.i18n.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: language => { 
        this.activeLang = language;
        this.updateAtlasInfoParams(this.pageId, this.activeLang);
      }
    })
  }

  updateAtlasInfoParams(id: string = "", lang: string = "") {
    this.hasGobernanceInfo = false;
    this.endpointParams = [];
    if (this.atlasHelper.hasParamsEndpoint(id)) this.hasGobernanceInfo = true;
    this.endpointParams = _.cloneDeep(_.cloneDeep(this.atlasHelper.getAtlasEndpointParams(id)));
    this.endpointParams.push({ name: "language", value: lang })
  }

  // Method to asign the value of the chosen option from the event 
  optionDetector(option: string) {
    this.chosenOption = option;
  }

  getTranslations(): void {
    const plan = this.translateService.get('labels.plan');
    const average = this.translateService.get('labels.average');
    const total = this.translateService.get('labels.total');

   forkJoin([plan, average, total]).subscribe(results => {
      this.planName = results[0];
      this.averageName = results[1];
      this.totalName = results[2];
    });
  }

  getChildLegends(e) {
    this.legendLoaded = false;
    const graphLegend = e.legends;

    for (let item of graphLegend) {
      if (!this.legends.includes(item)) {
        this.legends.push(item);
      }
    }

    if (e.child === 'daily') {
      this.dailyLegend = true;
    } else if (e.child === 'monthly') {
      this.monthlyLegend = true;
    }

    if (this.dailyLegend && this.monthlyLegend) {
      this.generateLegend();
      this.generateCustomColor();
      this.eventsService.dailySaleGraphicCustomColor$.emit(this.customColors);
      this.eventsService.monthlySaleGraphicCustomColor$.emit(this.customColors);
      this.legends = [];
      this.legendLoaded = true;
      this.monthlyLegend = false;
      this.dailyLegend = false;
    }

  }

  generateLegend() {
    this.legendsToPrint = [];

    if (this.legends.length !== 0) {
      let additionalIndex = 0;

      if (this.legends.includes('Open Market (OM)') || this.legends.includes('Credit Related (CR)')) {
        this.legendsToPrint.push(
          {
            name: this.mapName('Open Market (OM)'),
            color: this.colorListCSS[0],
            type: 'round',
            gradient: 'v-gradient'
          },
          {
            name: this.mapName('Credit Related (CR)'),
            color: this.colorListCSS[1],
            type: 'round',
            gradient: 'v-gradient'
          }
        );
        additionalIndex = 2;
      }

      let filteredLabels = this.legends.filter(v => v !== 'Open Market (OM)').filter(v => v !== 'Credit Related (CR)');
      for (let [_i, item] of filteredLabels.entries()) {
        this.legendsToPrint.push(
          {
            name: this.mapName(item),
            color: this.colorListCSS[_i + additionalIndex],
            type: 'round',
            gradient: 'v-gradient'
          }
        );
      }

      this.legendsToPrint.push(
        {
          name: this.planName,
          color: 'dark-yellow',
          type: 'line',
          gradient: 'h-gradient'
        },
        {
          name: this.averageName,
          color: 'light-purple',
          type: 'line',
          gradient: 'h-gradient'
        }
      );
    }
  }

  generateCustomColor() {
    this.customColors = [];
    let additionalIndex = 0;

    if (this.legends.includes('Open Market (OM)') || this.legends.includes('Credit Related (CR)')) {
      this.customColors.push(
        {
          name: 'Open Market (OM)',
          value: COLORS.darkBlue
        },
        {
          name: 'Credit Related (CR)',
          value: COLORS.lightBlue
        }
      );
      additionalIndex = 2;

    }
    
    let filteredLabels = this.legends.filter(v => v !== 'Open Market (OM)').filter(v => v !== 'Credit Related (CR)');
    for (let [_i, item] of filteredLabels.entries()) {
      this.customColors.push(
        {
          name: item,
          value: this.colorList[_i + additionalIndex]
        }
      );
    }

    this.customColors.push(
      {
        name: this.averageName,
        value: COLORS.lightPurple
      },
      {
        name: this.planName,
        value: COLORS.darkYellow
      },
      {
        name: this.totalName,
        value: COLORS.black
      })
  }

  mapName(name: string = "") {
    let outputName: string = "";

    switch (name) {
      case "Open Market (OM)":
        outputName = "OM";
        break;
      case "Credit Related (CR)":
        outputName = "CR";
        break;
      default:
        outputName = name;
        break;
    }

    return outputName;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

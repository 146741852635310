import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-portfolio',
  templateUrl: './sales-portfolio.component.html',
  styleUrls: ['./sales-portfolio.component.scss']
})
export class SalesPortfolioComponent implements OnInit {

  // Default Constructor
  constructor() { }

  // OnInit
  ngOnInit(): void {
  }

}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filter-info-dialog',
  templateUrl: './filter-info-dialog.component.html',
  styleUrls: ['./filter-info-dialog.component.scss']
})
export class FilterInfoDialogComponent implements OnInit {

  dialogTitle: string;
  image: string;
  imageHasTranslation: boolean;
  imageExtension: string;
  currentLang: string;

  constructor(
    public dialogRef: MatDialogRef<FilterInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private translate: TranslateService
  ) {
    this.currentLang = this.translate.currentLang;

    this.dialogTitle = data.dialogTitle,
    this.image = data.image
    this.imageExtension = data.imageExtension;
    this.imageHasTranslation = data.imageHasTranslation;
  }

  ngOnInit(): void {}

  closeDialog(): void {
    this.dialogRef.close();
  }

}

<div id="widget-{{order}}" class="widget">
    <div class="widget-container d-flex justify-content-between">
        <div class="d-flex">
            <span id="color-pipe-{{order}}" class="color-pipe"></span>
            <span id="section-title-{{order}}" class="title dg-section-card-title technical-info-title">{{ sectionInformation.title | translate }}</span>
        </div>
        <div class="image-background" (click)="goToSectionDetail(sectionInformation.id)">
            <img [src]="sectionInformation.image">
        </div>
    </div>
</div>

<div class="d-flex justify-content-between">
    <div class="left-block">
        <div class="mt-2 mb-1">
            <component-loader [width]="145" [height]="60" *ngIf="!kpiInfoLoaded"></component-loader>
            <app-small-kpi 
                [mainValue]="kpiInfo.quantity" 
                [mainValueUnit]="kpiInfo.symbol" 
                [percentage]="kpiInfo.percentage" 
                [trend]="kpiInfo.trend" 
                class="kpi-highlight"
                *ngIf="kpiInfoLoaded">
            </app-small-kpi>
        </div>
        <div>
            <component-loader [width]="145" [height]="60" *ngIf="!kpisInfoLoaded"></component-loader>
            <div class="d-flex mt-n3">
                <app-small-kpi 
                    class="kpi-big-second-value mr-4"
                    [mainValue]="kpisInfoA.quantity" 
                    [mainValueUnit]="kpisInfoA.symbol" 
                    [secondaryValue]="kpisInfoA.second_quantity" 
                    [percentagePrevious]="kpisInfoA.percentage_previous" 
                    [percentage]="kpisInfoA.percentage" 
                    [trend]="kpisInfoA.trend" 
                    *ngIf="kpisInfoLoaded">
                </app-small-kpi>
                <app-small-kpi 
                    class="kpi-big-second-value"
                    [mainValue]="kpisInfoM.quantity" 
                    [mainValueUnit]="kpisInfoM.symbol" 
                    [secondaryValue]="kpisInfoM.second_quantity" 
                    [percentagePrevious]="kpisInfoM.percentage_previous" 
                    [percentage]="kpisInfoM.percentage" 
                    [trend]="kpisInfoM.trend" 
                    *ngIf="kpisInfoLoaded">
                </app-small-kpi>
            </div>
        </div>
        <div class="mt-4">
            <component-loader class="mt-6" [width]="145" [height]="50" *ngIf="!treeInfoLoaded"></component-loader>
            <div class="d-flex align-items-start flex-wrap legends-container" *ngIf="treeInfoLoaded && treeValidData">
                <div class="d-flex my-1" *ngFor="let legend of legendsTreeMap">
                    <app-legend class="mr-2" [name]=legend.name [color]=legend.color [colorCode]=legend.color_code [type]=legend.type [gradient]=legend.gradient></app-legend>
                </div> 
            </div>
        </div>
    </div>
    <div class="right-block">
        <component-loader [width]="340" [height]="215" *ngIf="!treeInfoLoaded"></component-loader>
        <app-tree-map
            [view]="view"
            [scheme]="colorScheme"
            [results]="pendingAgeTreeInfo"
            [gradient]="gradient"
            [animations]="animations"
            [labelFormatting]="labelFormatting"
            [tooltipValueSuffix]="tooltipValueSuffix"
            *ngIf="treeInfoLoaded && treeValidData">
        </app-tree-map>
    </div>
</div>
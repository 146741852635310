import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApimstService } from '../../services/apimst.service';

@Component({
  selector: 'app-data-discovery-new-dossier-dialog',
  templateUrl: './data-discovery-new-dossier-dialog.component.html',
  styleUrls: ['./data-discovery-new-dossier-dialog.component.scss']
})
export class DataDiscoveryNewDossierDialogComponent implements OnInit {

  unsubscribe$: Subject<any> = new Subject<any>();
  templates = [];
  loaded: boolean = false;
  openLabel: string = 'labels.open';

  constructor(
    private dialogRef: MatDialogRef<DataDiscoveryNewDossierDialogComponent>,
    private apimstService: ApimstService,

  ) { }

  ngOnInit(): void {
    this.loaded = false;
    this.apimstService.getDataDiscoveryTemplates()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.templates = data;
        this.loaded = true;
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  goToTemplate(url: string) {
    window.open(url, '_blank');
  }
  
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

<component-loader [width]="310" [height]="182" *ngIf="!loaded"></component-loader>
<div class="line-charts-gen wok-gen only-border-bar-3 only-border-bar-4 mt-2" *ngIf="loaded">
    <app-difference-bar-label [width] = "labelWidth" [viewBox] = "viewBox2" [labelDiff] = "targetDiff"></app-difference-bar-label>
    <app-difference-bar-label [width] = "labelWidth" [viewBox] = "viewBox1" [labelDiff] = "yearDiff"></app-difference-bar-label>
    <ngx-charts-bar-vertical
      [view]="view"
      [scheme]="colorScheme"
      [results]="graphDataToPrint"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [dataLabelFormatting]="formatDataLabel"
      [roundDomains]="roundDomains"
      [showDataLabel]="showDataLabel"
      [barPadding]="barPadding"
      [customColors] = "customColors"
      [roundEdges] = "roundEdges"
      [rotateXAxisTicks] = "rotateXAxisTicks">
      <svg:g height=200 width=300>
        <line x1=0 x2=300 y1=40 y2=100 style="stroke:#ced4da; stroke-width:1"></line>
      </svg:g>
      
    </ngx-charts-bar-vertical>
    <div class="wok-x-axis"></div>
  </div>
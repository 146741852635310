<div class="info-panel" [ngStyle]="getStyles()">
    <div class="header">
        <div class="info-panel-main-block">
            <span 
                class="title d-block h6 mb-0" 
                [ngStyle]="{'color': headerColor}" 
                *ngIf="title">
                {{ title | uppercase }}
            </span>
            <span class="subtitle d-block h6 mb-0" *ngIf="subtitle">{{ subtitle }}</span>
        </div>
        <div class="info-panel-bg" [ngStyle]="getHeaderStyles()"></div>
    </div>
    <app-info-panel-tabs [tabElements]="tabElements" [tabLineColor]="headerColor"></app-info-panel-tabs>
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { MENU } from 'src/app/shared/globals/globals';
import { CookieService } from 'ngx-cookie-service';
import { MsalService } from 'src/app/shared/external/msal';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, take, first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LogoutDialogComponent } from '../../logout-dialog/logout-dialog.component';
import { LoginService } from 'src/app/shared/services/login/login.service';

@Component({
  selector: 'app-header-submenu',
  templateUrl: './header-submenu.component.html',
  styleUrls: ['./header-submenu.component.scss']
})
export class HeaderSubmenuComponent implements OnInit, OnDestroy {

  unsubscribe$: Subject<any> = new Subject<any>();
  subscription: Subscription;
  
  menuOn: boolean = false;
  navigationMenu = MENU;  

  username: string;

  toggle(item, items) {
    for(let i of items) { i.show = false; }
    item.show = !item.show;
  }

  hideMenu() {
    this.events.showMenu$.emit(false);
  }

  resetMenu() {
    console.log('resetMenu()');
    this.hideMenu();
  }

  logout() {
    this.loginService.logout();  
  }

  constructor( 
    private events: EventsService,
    private cookieService: CookieService,
    private authService: MsalService,
    public apimstService: ApimstService,
    private dialog: MatDialog,
    private loginService: LoginService
     ) { }

  ngOnInit(): void {
    this.events.showMenu$.subscribe(
      show => {
        this.menuOn = show;
      }
    );

    this.events.userInfoLoaded$
      .subscribe(
        data => {
          if( data ) {
            this.username = data['username'];
          }
        }
      )

  }

  openLogoutModal(): void {
    this.dialog.open(LogoutDialogComponent, {
      panelClass: 'mat-dialog-gen'
    });
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }  

}

<app-wrapper>

    <div class="wrapper_grid">
        <div class="clients-portfolio">
            <app-clients-portfolio-main></app-clients-portfolio-main>
        </div>
        <div class="clients-stock">
            <app-clients-stock></app-clients-stock>
        </div>        
        <div class="clients-stock-by-product-class-branch">
            <app-clients-stock-by-product-class-branch></app-clients-stock-by-product-class-branch>
        </div>
        <div class="new-clients-casualties-by-branch">
            <app-new-clients-casualties-by-branch></app-new-clients-casualties-by-branch>
        </div>
    </div>

</app-wrapper>
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit {

  @Input() name: string;
  @Input() color: string;       // Check _legends.scss. Example: .legend-dark-blue -> 'dark-blue'
  @Input() colorCode: string;   // Hexadecimal code -> '#FF232F' 
  @Input() type: string;        // 'round' or 'line'
  @Input() gradient: string;    // 'h-gradient' or 'v-gradient'

  constructor() { }

  ngOnInit(): void {
  }

  legendStyles() {

    var styles;

    if( this.colorCode != '') {
      styles = {
        'background': this.colorCode,
      }
    }

    return styles;
  }

}

<app-wrapper>

    <div class="wrapper_grid">
  
      <div class="quality">
        <app-sinisters-quality-main></app-sinisters-quality-main>
      </div>
      <div class="closed-per-month-of-complaint">
        <app-closed-per-month-of-complaint></app-closed-per-month-of-complaint>
      </div>
      <div class="sinisters-claims">
        <app-sinisters-claims></app-sinisters-claims>
      </div>
      <div class="sinisters-polls">
        <app-sinisters-polls></app-sinisters-polls>
      </div>

    </div>
  
  </app-wrapper>
  
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-upper-menu',
  templateUrl: './business-upper-menu.component.html',
  styleUrls: ['./business-upper-menu.component.scss']
})
export class BusinessUpperMenuComponent implements OnInit {

  @Input() title: string;
  @Input() colorStripe: string;
  @Input() sections;
  @Input() selectedSectionGuid;

  options: any = [];

  sectionName: string = 'businessUpperMenu';

  constructor(  ) { }

  ngOnInit(): void {
    document.getElementById('color-stripe').style.background = this.colorStripe;
    this.generateOptionsDetails();
  }

  generateOptionsDetails() {
    this.options = [];
    for (let item of this.sections) {
      this.options.push(
        {
          id: item.guid,
          name: item.name,
          selected: this.selectedSectionGuid === item.guid ? true : false,
          guid: item.guid
        }
      );
    }
  }

}

<app-widget>

    <div class="section-header-block d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex">
            <span class="title section-card-title">{{ 'panels.claims' | translate }}</span>
            <app-btn-gobernance-info 
                class="ml-2" 
                [endpoint]="endpoint" 
                [endpointParams]="endpointParams"
                *ngIf="hasGobernanceInfo">
            </app-btn-gobernance-info>
        </div>
        <component-loader *ngIf="!loaded" [width]="300" [height]="40"></component-loader>
        <div class="dropdown-container dropdown-light" *ngIf="loaded">
            <app-dropdown
            [title]="title"
            [items]="qualityClaimReasons"
            [dropdownId]="dropdownId">
            </app-dropdown>
        </div>
    </div>

    <div class="d-flex mr-5 mt-4">
        <div class="velocimeter-container">
            <cancellation-quality-claims-velocimeter></cancellation-quality-claims-velocimeter>
        </div>
    </div>

    <div class="d-flex justify-content-end align-items-center mt-4">
        <app-legend class="mr-2" name="labels.average" color="light-green" type="line" gradient="v-gradient"></app-legend>
        <app-legend name="labels.claims" color="dark-green" type="line" gradient="h-gradient"></app-legend>
    </div>

    <div class="mt-2">
        <cancellation-quality-claims-graphic></cancellation-quality-claims-graphic>
    </div>

</app-widget>
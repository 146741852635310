import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';

@Component({
  selector: 'app-fixed-stock-indicators-main',
  templateUrl: './fixed-stock-indicators-main.component.html',
  styleUrls: ['./fixed-stock-indicators-main.component.scss']
})
export class FixedStockIndicatorsMainComponent implements OnInit {
  currentFixedStockLoaded: boolean = false;
  premiumCertificateLoaded: boolean = false;

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any[] = [];

  currentFixedStockRawInfo: any = [];

  mainValueCurrentFixedStock: string = "";
  mainValueUnitCurrentFixedStock: string = "";
  percentagePreviousCurrentFixedStock: string = "";
  percentageCurrentFixedStock: string = "";
  trendCurrentFixedStock: number = 0;

  mainValuePremiumStock: string = "";
  mainValueUnitPremiumStock: string = "";
  percentagePreviousPremiumStock: string = "";
  percentagePremiumStock: string = "";
  trendPremiumStock: number = 0;

  mainValueCertificateStock: string = "";
  mainValueUnitCertificateStock: string = "";
  percentagePreviousCertificateStock: string = "";
  percentageCertificateStock: string = "";
  trendCertificateStock: number = 0;

  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService
  ) { }

  ngOnInit(): void {
    this.subscribeToFiltersValues();
  }

  subscribeToFiltersValues() {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: filters => {
          this.filtersValues = filters;
          if (this.filtersValues.length > 0) {
            this.resetValues();
            this.currentFixedStockLoaded = false;
            this.premiumCertificateLoaded = false;
            this.retreiveCancellationCurrentStock("cssy", this.filtersValues); // main indicator
            this.retreiveCancellationCurrentStock("cssyc", this.filtersValues); // premium/certificate indicator
          }
        }
      })
  }

  resetValues() {
    this.mainValueCurrentFixedStock = "";
    this.mainValueUnitCurrentFixedStock = "";
    this.percentagePreviousCurrentFixedStock = "PM";
    this.percentageCurrentFixedStock = "";
    this.trendCurrentFixedStock = 0;
    this.mainValuePremiumStock = "0";
    this.mainValueUnitPremiumStock = "";
    this.percentagePreviousPremiumStock = "PM";
    this.percentagePremiumStock = "";
    this.trendPremiumStock = 0;
    this.mainValueCertificateStock = "0";
    this.mainValueUnitCertificateStock = "";
    this.percentagePreviousCertificateStock = "PM";
    this.percentageCertificateStock = "";
    this.trendCertificateStock = 0;
  }

  retreiveCancellationCurrentStock(type_report: string = "", filtersValues: any[] = []) {
    this.apimstService.getCancellationCurrentStock(type_report, filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: indicatorData => {

          if (indicatorData == null) {
            if( type_report == "cssy") this.currentFixedStockLoaded = true;
            if( type_report == "cssyc") this.premiumCertificateLoaded = true;
            return
          };

          this.currentFixedStockRawInfo = indicatorData[0];

          if( type_report == "cssy") {
            this.currentFixedStockLoaded = true;
            this.createCurrentFixedStockKpi(this.currentFixedStockRawInfo);
          }
          if( type_report == "cssyc") {
            this.premiumCertificateLoaded = true;
            this.createkKpiPremiumCertificate(this.currentFixedStockRawInfo)
          };
        },
        error: error => console.log("An error occurred while retrieving cancellations stock indicators data " + error)
      })
  }

  createCurrentFixedStockKpi(data: any) {
    this.mainValueCurrentFixedStock = this.isValidValue(data.value) ? data.value.replace(/,/g, '') : "0";
    this.mainValueUnitCurrentFixedStock = "";
    this.percentagePreviousCurrentFixedStock = "PM";
    this.percentageCurrentFixedStock = String(parseFloat(data.diff));
    this.trendCurrentFixedStock = data.diff_trend ? parseInt(data.diff_trend) : 0;
  }

  createkKpiPremiumCertificate(data: any) {
    this.mainValuePremiumStock = this.isValidValue(data.premium) ? data.premium.replace(/,/g, '') : "0";
    this.mainValueUnitPremiumStock = "";
    this.percentagePreviousPremiumStock = "PM";
    this.percentagePremiumStock = String(parseFloat(data.premium_diff));
    this.trendPremiumStock = data.premium_diff_trend ? parseInt(data.premium_diff_trend) : 0;
    this.mainValueCertificateStock = this.isValidValue(data.certificate) ? data.certificate.replace(/,/g, '') : "0";
    this.mainValueUnitCertificateStock = "";
    this.percentagePreviousCertificateStock = "PM";
    this.percentageCertificateStock = String(parseFloat(data.certificate_diff));
    this.trendCertificateStock = data.certificate_diff_trend ? parseInt(data.certificate_diff_trend) : 0;
  }

  isValidValue(value: any) {
    return value != null && value != "";
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

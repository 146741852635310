<div class="tile-area-container" *ngFor="let itemBlock of items" [ngClass]="{'has-4-items' : itemBlock.length == 4}" [ngStyle]="getContainerStyles()">
  <div class="tile-area tile-area-{{i+1}}" *ngFor="let item of itemBlock; let i = index" [ngStyle]="getTileAreaStyles()" (click)="handleItemClick(item)">
    <div class="tile-area-inner">
      <div class="tile-area-text-block">
        <span class="tile-area-title d-block text-white mb-0" *ngIf="isValidValue(item.title)">{{ item.title }}</span>
        <span class="tile-area-subtitle d-block text-white mb-0" *ngIf="isValidValue(item.subtitle)">{{ item.subtitle }}</span>
        <span class="tile-area-description text-white">{{ item.description }}</span>
      </div>
      <div class="tile-area-bg-color-block" [ngStyle]="getBgColorStyles(item.backgroundColor)"></div>
      <div class="tile-area-bg-image-block">
        <img class="tile-area-bg-image" [src]="item.backgroundImage" />
      </div>
    </div>
  </div>
</div>

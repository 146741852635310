<app-wrapper>

    <div class="wrapper_grid">
        <div class="churn-rate">
            <app-churn-rate></app-churn-rate>
        </div>
        <div class="churn-rate-per-crop">
            <app-churn-rate-per-crop></app-churn-rate-per-crop>
        </div>        
        <div class="cancellation-reasons">
            <app-cancellation-reasons></app-cancellation-reasons>
        </div>
        <div class="cancellation-branch-channel">
            <app-cancellation-branch-channel></app-cancellation-branch-channel>
        </div>
    </div>

</app-wrapper>
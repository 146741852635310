<ng-container>
    <header class="header">
        <div class="d-flex justify-content-between">
            <h4 class="title">{{ 'labels.createNew' | translate }}</h4>
        </div>
        <div class="position-absolute">
            <span class="h2 btn-close material-icons close-button" (click)="closeDialog()">close</span>
        </div>
    </header>

    <component-loader *ngIf="!loaded" [width]="1100" [height]="350" [lightStyle]="true"></component-loader>

    <p-carousel *ngIf="loaded" [value]="templates" [numVisible]="4" [numScroll]="4" [circular]="false">
        <ng-template let-template pTemplate="item">
            <div class="template-item">
                <div class="template-item-content">
                    <div class="template-item-content--image"></div>
                    <div class="">
                        <div class="box-shadow"></div>
                        <div class="template-item-content--text">
                            <h5 class="p-mb-1">{{template.name}}</h5>
                            <span class="p-mt-0 p-mb-3">{{template.description}}</span>
                            <div class="d-flex justify-content-start">
                                <button type="button" class="btn open-button px-4 py-1" (click)="goToTemplate(template.url)">{{ openLabel | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-carousel>


</ng-container>
import { Component, OnInit } from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { CurrencyService } from 'src/app/shared/services/currency/currency.service';

@Component({
  selector: 'retention-funnel',
  templateUrl: './retention-funnel.component.html',
  styleUrls: ['./retention-funnel.component.scss']
})
export class RetentionFunnelComponent implements OnInit {

  // Loader
  loaded: boolean;

  // Items to retrieve data
  items: any = [];
  itemsBonus: any[] = [];
  itemsPolicy: any[] = [];

  cancellationRequest: any = {};
  cancellationRetentionAction: any = {};
  cancellationVolunteersIndicator: any = {};

  cancellationRequestBonus: any = [];
  cancellationRequestPolicy: any = []
  cancellationRetentionActionBonus: any = [];
  cancellationRetentionActionPolicy: any = [];
  cancellationVolunteersIndicatorBonus: any = [];
  cancellationVolunteersIndicatorPolicy: any = [];

  percentagePrevious: string = "labels.pm";

  // Option tabs
  actualOption: string = 'panels.bonus';
  optionTabBonus: string = 'panels.bonus';
  optionTabPolicy: string = 'panels.policy';
  optionToFetch: string = 'p';
  graphName: string = 'retentionRateCharge';

  // Filters value
  filtersValues: any;

  // Subscription
  subscription: Subscription;
  unsubscribe$: Subject<any> = new Subject<any>();

  // Format numbers
  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  // Check number symbol
  isNegative: boolean = false;

  constructor(
    private apimstService: ApimstService,
    private eventsService: EventsService,
    private filtersService: FiltersService,
    private utilsService: UtilsService,
    private currencyService: CurrencyService
  ) { }

  ngOnInit(): void {

    this.subscription = this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          // if (data.length !== 0 && !this.subscription.closed) {
          if (data.length !== 0) {
            this.filtersValues = data;
            this.resetVariables();
            this.assignData();
          }
        }
      );

      this.subscription = this.eventsService.policiesBonusOption$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          data => {
            const option = data[0];
            const name = data[1];
            if ((option === this.optionTabBonus || option === this.optionTabPolicy) && name === this.graphName ) {
              this.actualOption = option;
              this.assignData();
            }
          }
        );
  }

  // Method for retrieving the data information for the funnel
  retrieveFunnelData(type_data, data) {
    // Cancellation request, cancellation retention action and cancellation volunteers indicator for 'p' (amount)
    let cancellationRequest = this.apimstService.getChargeFunnelIndicators(type_data, 'crq', data);   // Cancellation request
    let cancellationRetentionAction = this.apimstService.getChargeFunnelIndicators(type_data, 'cra', data);      // Cancellation retention action
    let cancellationVolunteersIndicator = this.apimstService.getChargeFunnelIndicators(type_data, 'cv', data);      // Cancellation volunteers indicator

    // Show loader
    this.loaded = false;

    forkJoin([cancellationRequest, cancellationRetentionAction, cancellationVolunteersIndicator])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(results => {
        // results[0] => Cancellation request
        // results[1] => Cancellation retention action
        // results[2] => Cancellation volunteers indicator

        const cancellationRequestResult = results[0];
        const cancellationRetentionActionResult = results[1];
        const cancellationVolunteersIndicatorResult = results[2];

        // Currency symbol for the 'Bonus' values
        const currencySymbol = this.getCurrencySymbol();

        // Create items array
        this.cancellationRequest.title = "funnels.requests";
        this.cancellationRequest.main_value = cancellationRequestResult['value'] != null && cancellationRequestResult['value'] != "" ? this.formatPipe.transform( parseFloat( cancellationRequestResult['value'].replace(/,/g, '') ) ) + currencySymbol : '-1';
        this.cancellationRequest.second_value_prefix = this.percentagePrevious;
        this.cancellationRequest.second_value = cancellationRequestResult['diff'] != null ? this.formatPipe.transform( parseFloat( cancellationRequestResult['diff'].replace(/-|,/g, '')) ) + "%" : '0';
        this.cancellationRequest.trend = cancellationRequestResult['diff_trend'] != null ? parseInt(cancellationRequestResult['diff_trend']) : 0;
        this.cancellationRequest.trend_direction = this.utilsService.isNegative( cancellationRequestResult['diff'] ) == false ? 1 : 0;

        this.cancellationRetentionAction.title = "funnels.retentionsPerAction";
        this.cancellationRetentionAction.main_value = cancellationRetentionActionResult['value'] != null && cancellationRetentionActionResult['value'] != "" ? this.formatPipe.transform( parseFloat( cancellationRetentionActionResult['value'].replace(/,/g, '') ) ) + currencySymbol : '-1';
        this.cancellationRetentionAction.second_value_prefix = this.percentagePrevious;
        this.cancellationRetentionAction.second_value = cancellationRetentionActionResult['diff'] != null ? this.formatPipe.transform( parseFloat( cancellationRetentionActionResult['diff'].replace(/-|,/g, '')) ) + "%" : '0';
        this.cancellationRetentionAction.trend = cancellationRetentionActionResult['diff_trend'] != null ? parseInt(cancellationRetentionActionResult['diff_trend']) : 0;
        this.cancellationRetentionAction.trend_direction = this.utilsService.isNegative( cancellationRetentionActionResult['diff'] ) == false ? 1 : 0;

        this.cancellationVolunteersIndicator.title = "funnels.voluntaryCancellation";
        this.cancellationVolunteersIndicator.main_value = cancellationVolunteersIndicatorResult['value'] != null && cancellationVolunteersIndicatorResult['value'] != "" ? this.formatPipe.transform( parseFloat( cancellationVolunteersIndicatorResult['value'].replace(/,/g, '') ) ) + currencySymbol : '-1';
        this.cancellationVolunteersIndicator.second_value_prefix = this.percentagePrevious;
        this.cancellationVolunteersIndicator.second_value = cancellationVolunteersIndicatorResult['diff'] != null ? this.formatPipe.transform( parseFloat( cancellationVolunteersIndicatorResult['diff'].replace(/-|,/g, '')) ) + "%" : '0';
        this.cancellationVolunteersIndicator.trend = cancellationVolunteersIndicatorResult['diff_trend'] != null ? parseInt(cancellationVolunteersIndicatorResult['diff_trend']) : 0;
        this.cancellationVolunteersIndicator.trend_direction = this.utilsService.isNegative( cancellationVolunteersIndicatorResult['diff'] ) == false ? 1 : 0;

        const itemData: any = [];
        itemData.push(this.cancellationRequest);
        itemData.push(this.cancellationRetentionAction);
        itemData.push(this.cancellationVolunteersIndicator);

        if (this.actualOption === this.optionTabBonus) {
          this.itemsBonus = JSON.parse(JSON.stringify(itemData));
        } else if (this.actualOption === this.optionTabPolicy) {
          this.itemsPolicy = JSON.parse(JSON.stringify(itemData));
        }

        this.assignDataToPrint();

      });

    }

  // Method to add currency symbol if 'Bonus' selected
  private getCurrencySymbol() {
    let currencySymbol: string = '';

    if (this.actualOption === this.optionTabBonus) {
      this.currencyService.currencyInfo$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(currencyInfo =>
          currencySymbol = currencyInfo.symbol
        );
    }
    return currencySymbol;
  }

  // Method to reset the data storage variables
  private resetVariables() {
    this.itemsBonus = [];
    this.itemsPolicy = [];
  }

  // Method to assign the data to print
  private assignDataToPrint() {
    if (this.actualOption === this.optionTabBonus) {
      this.items = this.itemsBonus;
      this.loaded = true;
    } else if (this.actualOption === this.optionTabPolicy) {
      this.items = this.itemsPolicy;
      this.loaded = true;
    }
  }

  // Method to assing data
  private assignData() {
    if (this.actualOption === this.optionTabBonus && this.itemsBonus.length === 0) {
      this.retrieveFunnelData('p', this.filtersValues);
    } else if (this.actualOption === this.optionTabPolicy && this.itemsPolicy.length === 0) {
      this.retrieveFunnelData('c', this.filtersValues);
    } else {
      this.assignDataToPrint();
    }
  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

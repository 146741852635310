
<span class="title mb-2">{{ item.title }}</span>
<div class="percentage-graphic-bottom">
    <div class="percentage-graphic-left">
        <span class="main-value-block text-white d-block" *ngIf="item.main_value != -1">
            <span class="main-value d-inline-block mr-1">{{  item.main_value }}</span>
            <span class="unit" *ngIf="item.unit != -1" [title]="item.unit" [ngClass]="{'unit-big' : item.unit_size === 1, 'unit-small' : item.unit_size === 0}">{{  item.unit }}</span>
        </span> 
        <span class="second-value-block d-block mr-1" *ngIf="item.second_value != -1">
            <span class="second-value d-inline-block mr-1" *ngIf="item.second_value != -1">{{ item.second_value }}</span>
            <span class="unit" *ngIf="item.unit != -1" [title]="item.unit" [ngClass]="{'unit-big' : item.unit_size === 1, 'unit-small' : item.unit_size === 0}">{{  item.unit }}</span>
        </span>
        <span 
            class="trend-block d-flex align-items-center" 
            [ngClass]="{'text-brand-success' : item.trend === 1, 'text-brand-danger' : item.trend === 0}" 
            *ngIf="item.trend_value != -1">
                <span class="material-icons icon" *ngIf="item.trend_direction === 1">arrow_drop_up</span>
                <span class="material-icons icon" *ngIf="item.trend_direction === 0">arrow_drop_down</span>
                <span class="trend-value"><span *ngIf="item.trend_value_previous">{{ item.trend_value_previous }} </span>{{ item.trend_value }}</span>
        </span>
    </div>
    <div class="percentage-graphic-right">
        <div class="graphic-container">
            <ngx-charts-line-chart
            [scheme]="colorScheme"
            [legend]="legend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [timeline]="timeline"
            [results]="item.graphic_data"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
            >
        </ngx-charts-line-chart>
      </div>
    </div>
</div>
<div *ngIf="item.trend === 1" class="bg-gradient bg-gradient-success"></div>
<div *ngIf="item.trend === 0" class="bg-gradient bg-gradient-danger"></div>

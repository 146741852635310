import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SlowBuffer } from 'buffer';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Animations } from '../../animations/animations';
import { COLORS } from '../../globals/globals';
import { EventsService } from '../../services/events/events.service';

@Component({
  selector: 'app-searcher',
  templateUrl: './searcher.component.html',
  styleUrls: ['./searcher.component.scss'],
  animations: [
    Animations.slideToLeft,
    Animations.fadeInOut,
    Animations.dumpAnimation
  ]
})
export class SearcherComponent implements OnInit {

  @Input() area;

  // Labels for the options panel
  options: any[] = [
    { id: "business", name: "labels.business", selected: true },
    { id: "technical", name: "labels.technical", selected: false }
  ];
  selectedArea: string = '';
  sectionName: string = 'searcher';
  openPanel: boolean = true;
  searchResultNumber;
  results: any = [];

  unsubscribe$: Subject<any> = new Subject<any>();

  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    this.eventsService.searchAreaType$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  closePanel() {
    this.eventsService.openSearchPanel$.emit(false);
    this.openPanel = false;
  }

  search() {
    const searchValue = this.searchInput.nativeElement.value;
    if (searchValue !== '') {
      this.searchResultNumber = "5 Resultados";
      this.results = [
        {
          type: 'attribute',
          guid: '',
          title: 'Tasa Churn (Mes Anterior)'
        },
        {
          type: 'report',
          guid: '',
          title: 'Tasa Churn (Mes Anterior)'
        },
        {
          type: 'fact',
          guid: '',
          title: 'Tasa Churn (Mes Anterior)'
        },
        {
          type: 'table',
          guid: '',
          title: 'Tasa Churn (Mes Anterior)'
        },
        {
          type: 'table',
          guid: '',
          title: 'Tasa Churn (Mes Anterior)'
        },
        {
          type: 'attribute',
          guid: '',
          title: 'Tasa Churn (Mes Anterior)'
        },
        {
          type: 'report',
          guid: '',
          title: 'Tasa Churn (Mes Anterior)'
        },
        {
          type: 'fact',
          guid: '',
          title: 'Tasa Churn (Mes Anterior)'
        },
        {
          type: 'table',
          guid: '',
          title: 'Tasa Churn (Mes Anterior)'
        },
        {
          type: 'table',
          guid: '',
          title: 'Tasa Churn (Mes Anterior)'
        },
        {
          type: 'attribute',
          guid: '',
          title: 'Tasa Churn (Mes Anterior)'
        },
        {
          type: 'report',
          guid: '',
          title: 'Tasa Churn (Mes Anterior)'
        },
        {
          type: 'fact',
          guid: '',
          title: 'Tasa Churn (Mes Anterior)'
        },
        {
          type: 'table',
          guid: '',
          title: 'Tasa Churn (Mes Anterior)'
        },
        {
          type: 'table',
          guid: '',
          title: 'Tasa Churn (Mes Anterior)'
        }
      ];
      for (let item of this.results) {
        item.entityType = 'dataGovernanceTechnicalArea.' + item.type;
        item.color = COLORS[item.type]
      }
    } else {
      this.searchResultNumber = '';
    }

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

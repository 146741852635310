import { Component, OnInit } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { ClientsVocClaimsService } from '../clients-voc-claims.service';
import { response } from './response'

@Component({
  selector: 'app-clients-voc-claims-velocimeter',
  templateUrl: './clients-voc-claims-velocimeter.component.html',
  styleUrls: ['./clients-voc-claims-velocimeter.component.scss']
})
export class ClientsVocClaimsVelocimeterComponent implements OnInit {

  // ViewBox
  viewBox: string = "0 0 100 50";
  strokeWidth: number = 8;

  // Values
  target: string;
  mainValue: string;
  secondValue: string;
  trend: string;
  leftValue;
  hasInfo: boolean = false;
  currentValue;
  hAlign: string = "center";
  vAlign: string = "center";
  barOrientation: number;
  showSecondValue: boolean = false;
  diff: any = "";

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;

  qualityClaim: any = {};
  typeSelected: any = {};
  qualityClaimSelected: any = [];
  qualitySinisterIndicator: any = {};
  percentagePrevious: string = "labels.pm";

  categoryNames: any = {
    assistance: "Asistencias",
    cancellation: "Cancelación",
    infoProblems: "Problemas con la Información",
    renewal: "Renovación",
    sinisters: "Siniestros",
    sales: "Ventas",
  }

  loaded: boolean = false;
  formatPipe: MagnitudeOrderPipe = new MagnitudeOrderPipe();

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private eventsService: EventsService,
    private clientsVocClaimsService: ClientsVocClaimsService
  ) { }

  ngOnInit(): void {
    this.subscribeToSelectorsEvents();
  }

  subscribeToSelectorsEvents() {
    const filters$ = this.filtersService.filtersValues$;
    const selectedClaimReason$ = this.eventsService.clientsQualityClaims$;

    combineLatest([filters$, selectedClaimReason$])
      .pipe(shareReplay(), takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.resetValues();
          this.filtersValues = data[0];
          this.qualityClaimSelected = data[1];

          this.loaded = true;
          if (this.filtersValues.length == 0) return;

          this.retrieveClientsQualityIndicator("ccp", this.filtersValues);

          // this.getSelectedOption(data[1]);
          // this.retrieveRejectionsInfo(this.type_data, this.filtersValues);
        }
      )
  }

  private retrieveClientsQualityIndicator(type_report: string, filtersValues: any) {
    this.loaded = false;

    this.apimstService.getClientsQualityClaims(type_report, filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          let response = data;
          this.loaded = true;
          this.setEmptyData();

          if (data == null) return;

          const filteredValues = this.filterValues(data);
          if (typeof filteredValues == "undefined" || filteredValues == null) return;

          this.currentValue = (parseFloat(filteredValues.value) * 1.8).toString();
          this.qualityClaim.diff_trend = parseInt(filteredValues.diff_trend);
          this.qualityClaim.diff = this.formatPipe.transform(parseFloat(filteredValues.diff.replace(/-|,/g, '')));

          this.qualityClaim.value = filteredValues.value;
          this.qualityClaim.diff = filteredValues.diff;
          this.qualityClaim.diff_trend = parseInt(filteredValues.diff_trend);

          // this.createIndicator(filteredValues);

        },
        error => console.log("An error ocurred while retrieving clients quality velocimeter indicator data: " + error)

      );
  }

  filterValues(values: any[] = []) {
    return values.filter(v => v.name == this.qualityClaimSelected)[0];
  }

  createIndicator(indicator: any): void {
    this.qualitySinisterIndicator.title = '';
    this.qualitySinisterIndicator.main_value = this.isValidValue(indicator.value) ? indicator.value : "";
    this.qualitySinisterIndicator.main_value_unit = '%';
    this.qualitySinisterIndicator.percentage_previous = this.percentagePrevious;
    this.qualitySinisterIndicator.percentage = this.isValidValue(indicator.diff) ? indicator.diff : "";
    this.qualitySinisterIndicator.trend = this.isValidValue(indicator.diff_trend) ? parseInt(indicator.diff_trend) : 0;
  }

  setEmptyData() {
    this.qualityClaim.title = '';
    this.qualityClaim.value = '';
    this.qualityClaim.main_value_unit = '';
    this.qualityClaim.target = '';
    this.qualityClaim.percentage_previous = '';
    this.qualityClaim.percentage = '';
    this.qualityClaim.trend = 0;
    this.qualityClaim.diff = "";
    this.qualityClaim.diff_trend = 0;
    this.target = "";
    this.currentValue = "0";
    this.barOrientation = 0;
  }

  resetValues() {
    this.qualitySinisterIndicator = {};
  }

  isValidValue(value: any) {
    const isValid = value != null && typeof value != "undefined";
    return isValid;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

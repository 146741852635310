<component-loader *ngIf="!loaded" [width]="" [height]="180"></component-loader>

<div *ngIf="loaded">

  <div class="mt-2 d-flex justify-content-end align-items-center">
    <app-legend *ngFor="let key of objectKeys(dimensionValues)" class="mr-2" name="{{key}}" color="{{dimensionValues[key]}}" type="round" gradient="h-gradient"></app-legend>
    <!-- <mat-icon *ngIf="loaded" class="excel-icon ml-2" svgIcon="excel" (click)="exportAsXLSX()"></mat-icon> -->
  </div>

  <stacked-vertical-horizontal-graph
      [view]="view"
      [scheme]="comboBarScheme"
      [colorSchemeLine]="lineChartScheme"
      [customColors]="customColors"
      [results]="verticalGraph"
      [animations]="animations"
      [lineChart]="horizontalGraph"
      [yAxisTickFormatting]="yAxisTickFormatting"
      [yLeftAxisScaleFactor]="yLeftAxisScaleFactor"
      [yRightAxisScaleFactor]="yRightAxisScaleFactor"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [rightYAxis]="showRightYAxis"
      [legend]="showLegend"
      [legendTitle]="legendTitle"
      [legendPosition]="legendPosition"
      [showGridLines]="showGridLines"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [showRightYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [barPadding]="barPadding"
      [tooltipDisabled]="tooltipDisabled"
      [schemeType]="schemeType"
      [roundDomains]="roundDomains"
      [noBarWhenZero]="noBarWhenZero"
      [rotateXAxisTicks]="rotateXAxisTicks"
      [hideHorizontalLine]="hideHorizontalLine"
      [showValuesAndPercentageInTooltip]="showValuesAndPercentageInTooltip"
      [dateName]="dateName">
    </stacked-vertical-horizontal-graph>


</div>

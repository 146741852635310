<div class="section-header-block d-flex justify-content-between align-items-center pb-2">
    <div class="d-flex align-items-center">
        <span class="title section-card-subtitle">{{ 'panels.newClients&CasualtiesByBranch' | translate }}</span>
        <app-btn-gobernance-info 
            class="ml-2" 
            [endpoint]="endpoint" 
            [endpointParams]="endpointParams"
            *ngIf="hasGobernanceInfo">
        </app-btn-gobernance-info>
        <span class="material-icons ml-2 fullscreen-icon text-light mr-auto" (click)="openDialogBouquet()" *ngIf="loaded">fullscreen</span>
        <mat-icon class="excel-icon ml-2" svgIcon="excel" (click)="exportAsXLSX()" *ngIf="loaded"></mat-icon>
    </div>

    <component-loader *ngIf="!loaded" [width]="150" [height]="25"></component-loader>
    <div *ngIf="loaded" class="d-flex justify-content-end align-items-center">
        <app-legend class="mr-2" name="labels.unsubscriptions" color="dark-blue" type="round" gradient="v-gradient"></app-legend>
        <app-legend name="labels.highSubscriptions" color="light-blue" type="round" gradient="h-gradient"></app-legend>
    </div>
</div>

<div class="d-flex justify-content-center mt-3">
        <!-- <div class="d-flex justify-content-end align-items-center mt-3 w-100">
        <span class="material-icons ml-2 fullscreen-icon text-light mr-auto" (click)="openDialogMixProduct()">fullscreen</span>
        <app-legend class="mr-2" name="labels.portfolioProductMix" color="light-blue" type="round" gradient="v-gradient"></app-legend>
        <app-legend class="mr-2" name="labels.sales" color="dark-blue" type="round" gradient="v-gradient"></app-legend>
        </div> -->
        <component-loader *ngIf="!loaded" [width]="580" [height]="190"></component-loader>
        <div class="mt-3 ml-6 line-charts-gen y-axis-negative" *ngIf="loaded">
        <app-stacked-horizontal-negative-graph
            [view]="view"
            [scheme]="colorScheme"
            [schemeType]="schemeType"
            [results]="clientsValues"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [animations]="animations"
            [showDataLabel] = "showDataLabel"
            [dataLabelFormatting]="formatDataLabel"
            [roundDomains] = "roundDomains"
            [trimYAxisTicks]="trimYAxisTicks"
            [maxYAxisTickLength]="maxYAxisTickLength"
            [showGridLines]="showGridLines"
            [customColors]="customColors"
            [tooltipDisabled]="tooltipDisabled"
            [xAxisTickFormatting]="formatXAxisTick">
        </app-stacked-horizontal-negative-graph>
    </div>  
    
</div>

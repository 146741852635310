import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginService } from '../../services/login/login.service';

@Component({
  selector: 'logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss']
})
export class LogoutDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LogoutDialogComponent>,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
  }

  acceptAction(): void {
    this.loginService.logout();
  }

  declineAction(): void {
    this.dialogRef.close();
  }

}

<div class="card-item">
    <div class="card-item-content">
        <div class="card-item-content--image" [ngStyle]="getImageStyle()"></div>
        <div class="card-item-content--text">
            <h2 class="card-title">{{title | translate}}</h2>
            <span class="p-mt-0 p-mb-3">{{description | translate}}</span>
            <div class="d-flex">
                <button type="button" class="btn button px-4 py-1" (click)="goToLink()" [routerLink]="routerLink">{{buttonLabel | translate}}</button>
            </div>
        </div>
    </div>
</div>

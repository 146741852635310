<app-wok-graph-upper-label class="mb-4" [viewBox]="viewBox" [width]="width" [labelDiff] = "labelDiff" [textLabel] = "textLabel">

</app-wok-graph-upper-label>

<ngx-charts-chart
    [view]="[width, height]"
    [showLegend]="legend"
    [legendOptions]="legendOptions"
    [activeEntries]="activeEntries"
    [animations]="animations"
    (legendLabelClick)="onClick($event)"
    (legendLabelActivate)="onActivate($event, true)"
    (legendLabelDeactivate)="onDeactivate($event, true)"
    >
    <svg:g [attr.transform]="transform" class="bar-chart chart">
        <svg:g
        ngx-charts-x-axis
        *ngIf="xAxis"
        [xScale]="xScale"
        [dims]="dims"
        [showLabel]="showXAxisLabel"
        [labelText]="xAxisLabel"
        [trimTicks]="trimXAxisTicks"
        [rotateTicks]="rotateXAxisTicks"
        [maxTickLength]="maxXAxisTickLength"
        [tickFormatting]="xAxisTickFormatting"
        [ticks]="xAxisTicks"
        [xAxisOffset]="dataLabelMaxHeight.negative"
        (dimensionsChanged)="updateXAxisHeight($event)"
        ></svg:g>
        <svg:g
        ngx-charts-y-axis
        *ngIf="yAxis"
        [yScale]="yScale"
        [dims]="dims"
        [showGridLines]="showGridLines"
        [showLabel]="showYAxisLabel"
        [labelText]="yAxisLabel"
        [trimTicks]="trimYAxisTicks"
        [maxTickLength]="maxYAxisTickLength"
        [tickFormatting]="yAxisTickFormatting"
        [ticks]="yAxisTicks"
        (dimensionsChanged)="updateYAxisWidth($event)"
        ></svg:g>
        <svg:g
        app-wok-graph-bar-difference
        [xScale]="xScale"
        [yScale]="yScale"
        [colors]="colors"
        [series]="results"
        [dims]="dims"
        [gradient]="gradient"
        [tooltipDisabled]="tooltipDisabled"
        [tooltipTemplate]="tooltipTemplate"
        [showDataLabel]="showDataLabel"
        [dataLabelFormatting]="dataLabelFormatting"
        [activeEntries]="activeEntries"
        [roundEdges]="roundEdges"
        [animations]="animations"
        [noBarWhenZero]="noBarWhenZero"
        [dataLabelUnit]="dataLabelUnit"
        (barWidth)="barWidth($event)"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)"
        (select)="onClick($event)"
        (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event)"
        ></svg:g>
        <svg height=100 [attr.width]="dims.width">
            <line x1=0 x2=100% [attr.y1]="dims.height" [attr.y2]="dims.height" style="stroke:white; stroke-width:1"></line>
        </svg>
    </svg:g>
</ngx-charts-chart>
import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-btn-filter',
  templateUrl: './btn-filter.component.html',
  styleUrls: ['./btn-filter.component.scss']
})
export class BtnFilterComponent implements OnInit {

  visible: boolean = false;
  active: boolean = false;

  @Input() title: string;
  @Input() inactiveIcon: string;
  @Input() activeIcon: string;
  @Input() isActive: boolean;

  @Output() open: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  clickEvent() {
      this.active = !this.active;  
      this.visible = !this.visible;
      if (this.visible) {
        this.open.emit(null);
      } else {
        this.close.emit(null);
      }
  }

  constructor() { }

  ngOnInit(): void {
    this.toggleActive();
  }

  ngOnChanges(): void {
    this.toggleActive();
  }

  toggleActive(): void {
    if( this.isActive ) {
      this.active = true;
      this.visible = true;
    } else {
      this.active = false;
      this.visible = false;
    }
  }

}

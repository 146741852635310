import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { utils } from 'protractor';
import { BehaviorSubject, combineLatest, forkJoin, Subject, Subscription } from 'rxjs';
import { shareReplay, take, takeUntil } from 'rxjs/operators';
import { COLORS } from 'src/app/shared/globals/globals';
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { multi, multiBar } from './data';
import { response } from './response';
import { checkboxes } from './checkboxes-values';
import { MagnitudeOrderPipe } from 'src/app/shared/pipes/magnitude-order.pipe';
import * as _ from 'lodash';

@Component({
  selector: 'app-clients-portfolio-distribution-by-holding-line-graph',
  templateUrl: './clients-portfolio-distribution-by-holding-line-graph.component.html',
  styleUrls: ['./clients-portfolio-distribution-by-holding-line-graph.component.scss']
})
export class ClientsPortfolioDistributionByHoldingLineGraphComponent implements OnInit {

  // view: any[] = [330, 130];

  compareDateLabel: string = "";
  actualDateLabel: string = "";

  rawInfo: any;

  checkboxes: any;
  checkedCheckboxes: any = ['1'];

  checkboxChanged$ = new BehaviorSubject<any>(this.checkedCheckboxes);


  // view: any[] = [400, 170];
  customColors: any = [];

  // options
  legend: boolean = false;
  showLabels: boolean = false;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showRefLines: boolean = false;
  timeline: boolean = false;
  rotateXAxisTicks: boolean = false;
  colorScheme = {
    domain: [COLORS.darkGreen, COLORS.lightOrange, COLORS.pink, COLORS.lightBlue, COLORS.darkBlue]
  };

  // Options for chart creation
  view = [590, 250];
  modalView = [1210, 580];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = false;
  legendTitle: string = '';
  legendPosition: string = '';
  showXAxisLabel: boolean = false;
  showYAxisLabel: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  yLeftAxisScaleFactor: string = '';
  yRightAxisScaleFactor: string = '';
  yRightAxisTickFormatting: string = '';
  showGridLines: boolean = true;
  innerPadding: string = '5%';
  animations: boolean = false;
  showRightYAxisLabel: boolean = false;
  yAxisLabelRight: string = '';
  barPadding: number = 25;
  tooltipDisabled: boolean = false;
  schemeType: string = "ordinal";
  roundDomains: boolean = true;
  noBarWhenZero: boolean = true;
  autoScale: boolean = true;
  barChart: any[] = [];
  lineChartSeries: any[] = [];
  lineChartScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkYellow, COLORS.lightPurple, '#0099cc', '#08ded1']
  };
  comboBarScheme = {
    selectable: true,
    group: 'ordinal',
    domain: [COLORS.darkGreen, COLORS.darkYellow, COLORS.darkRed, COLORS.lightBlue, '#a8385d', '#aae3f5']
  };
  showTotalInTooltip: boolean = false;

  unsubscribe$: Subject<any> = new Subject<any>();
  filtersValues: any;
  monthNames: any;

  technicalRejectionTaxName: string = "";
  administrativeName: string = "";
  paymentZeroName: string = "";
  clientsNumberName: string = "";
  totalName: string = "";
  clientsNumberTotal: string = "";

  averageName: string;
  claimsName: string;
  claimReasonName: string;
  settledInTimeName: string;
  settledInTimeSinistersName: string;
  settledInTimeAvgSinistersName: string;
  sinistersClaimsRolling: any = [];
  loaded: boolean = false;
  qualityClaimSelected: any = [];
  xAxisTicksFinal: any = [];
  xAxisTicksValues: any = [];

  tenureDistribution: any = [];

  data_total: any[] = [];
  data_sheet_names: any[] = [];
  data_filter_details: any[] = [];
  claimReason: string = 'Todas';

  constructor(
    private filtersService: FiltersService,
    private apimstService: ApimstService,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private excelService: ExcelService,
    private events: EventsService,
  ) { }

  ngOnInit(): void {
    this.initCheckboxes();
    this.subscribeToEvents();
  }

  subscribeToEvents() {
    const filtersChanged$ = this.filtersService.filtersValues$;
    const checkboxChanged$ = this.checkboxChanged$;

    combineLatest([filtersChanged$, checkboxChanged$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.loaded = false;
          this.getTranslations();
          this.resetValues();

          this.filtersValues = data[0];
          if (this.filtersValues.length == 0) return;

          this.retrieveRejectionsGraph(this.filtersValues);
        }
      )
  }

  initCheckboxes() {
    this.checkboxes = this.getCheckboxesInitialValues();
    this.setCheckboxesInitialActiveValues(this.checkboxes);
  }

  getCheckboxesInitialValues() {
    return checkboxes;
  }

  setCheckboxesInitialActiveValues(checkboxes: any[] = []) {
    for (let checkbox of this.checkboxes) checkbox.checked = false;
    for (let activated of this.checkedCheckboxes) {
      checkboxes.filter(checkbox => checkbox.id == activated)[0].checked = true;
    }
  }

  getTranslations() {
    const monthsTranslation = this.translateService.get('abbreviatedMonthNames');
    const technicalRejectionTaxTranslation = this.translateService.get('panels.technicalRejectionTax');
    const administrativeTranslation = this.translateService.get('panels.administrative');
    const paymentZeroTranslation = this.translateService.get('panels.paymentZero');
    const clientsNumberTranslation = this.translateService.get('panels.clientsNumberAbbr');
    const totalTranslation = this.translateService.get('labels.total');
    const clientsTotalTranslation = this.translateService.get('labels.clientsNumberTotal');

    const translations = [monthsTranslation, technicalRejectionTaxTranslation, administrativeTranslation, paymentZeroTranslation, clientsNumberTranslation, totalTranslation, clientsTotalTranslation];

    forkJoin(translations)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: results => {
          this.monthNames = results[0];
          this.technicalRejectionTaxName = results[1];
          this.administrativeName = results[2];
          this.paymentZeroName = results[3];
          this.clientsNumberName = results[4];
          this.totalName = results[5];
          this.clientsNumberTotal = results[6];
        }
      });
  }

  updateCheckedCheckboxes(id: string = "", event: any) {
    let selectedValues: any[] = this.checkedCheckboxes;
    const checked = event.target.checked;
    if (checked) {
      selectedValues.push(id);
    } else {
      selectedValues = selectedValues.filter(checkbox => checkbox != id);
    }
    this.checkedCheckboxes = selectedValues;
    this.checkboxChanged$.next(this.checkedCheckboxes);
    this.initCheckboxes();

    return selectedValues;
  }

  retrieveRejectionsGraph(filtersValues: any[] = []): void {
    this.loaded = false;

    this.apimstService.getClientsPortfolioHolding("che", filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: data => {
          this.loaded = true;
          if(data == null) return;

          this.tenureDistribution = this.handleInfo(data);
          this.assignColors();
        }
      })
  }

  handleInfo(rawInfo: any) {
    const graphInfoFormatted = this.createGraph(rawInfo);
    const graphInfoFiltered = this.filterGraph(graphInfoFormatted);
    return graphInfoFiltered;
  }

  createGraph(info: any[] = []) {
    const groupedByMonthData = this.groupByMonth(info);
    const groupedByName = this.groupByName(info);
    const series = groupedByName.map(
      item => ({
        name: item.name,
        series: item.values.map( value => ({
          name: this.monthNames[value.month] + value.year.slice(2, 4),
          value: parseInt(value.value.replace(/,/g, ''))
        }))
      })
    )

    return series;
  }

  filterGraph(info: any[] = []) {
    const selectedCheckboxes = this.checkedCheckboxes;
    let filteredGraph: any = [];
    if(selectedCheckboxes.length == 0) return info;
    info.map( item => {
      if( selectedCheckboxes.includes(item.name) ) {
        filteredGraph.push(item)
      }
    })
    return filteredGraph;
  }

  groupByMonth(graphInfo: any) {
    let graphInfoCopy = graphInfo;
    let groupedByMonth: any[] = [];
    for (let item of graphInfoCopy) { item.group_by = item.month + '-' + item.year }
    groupedByMonth = _.chain(graphInfoCopy).groupBy("group_by").map((value, key) => ({ date: key, values: value })).value();
    return groupedByMonth;
  }

  groupByName(graphInfo: any) {
    let graphInfoCopy = graphInfo;
    let groupedByName: any[] = [];
    groupedByName = _.chain(graphInfoCopy).groupBy("name").map((value, key) => ({ name: key, values: value })).value();
    return groupedByName;
  }

  getTotal(model) {
    const reducer = (sum, val) => sum + val.value;
    const initialValue = 0;
    return Intl.NumberFormat("de-DE").format(model.reduce(reducer, initialValue));
  }

  assignColors() {
    this.customColors.push(
      {
        name: "1",
        value: COLORS.darkGreen
      },
      {
        name: "2",
        value: COLORS.lightOrange
      },
      {
        name: "3",
        value: COLORS.pink
      },
      {
        name: "4",
        value: COLORS.lightBlue
      },
      {
        name: ">=5",
        value: COLORS.darkBlue
      }
      )
  }

  resetValues() {
    this.tenureDistribution = [];
  }

  yAxisTickFormatting(val) {
    const magnitudeOrder = new MagnitudeOrderPipe()
    return magnitudeOrder.transform(val);
  }

  exportAsXLSX(): void {
    this.data_sheet_names = [this.claimsName];
    this.excelService.exportAsExcelFile(this.data_total, this.data_sheet_names, 'clients_portfolio_distribution_chart', this.data_filter_details);
  }

  formatValue(v) {
    return Intl.NumberFormat("de-DE").format(v);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

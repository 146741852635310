export const environment = {
  production: true,        
  authority: 'https://login.microsoftonline.com/9ef0d257-39bc-470d-8e6d-4f03476e240a',
  clientId: '3cd59f31-0635-4555-876e-5844cb3ae828',
  redirectUri: 'https://ms-atlas-frontend.azurewebsites.net/',
  //redirectUri: 'ms-atlas-frontend.azurewebsites.net',
  azureApiUri: 'https://sds-apis.azure-api.net/sik-ms-api/', 
  azureApiUriAtlas: '  https://sds-apis.azure-api.net/atlas-api/',
  //azureApiKey: 'api://35f8dbb2-152a-4c35-b0ec-1ba58149bdc7/user_impersonation',
  microstrategyURL: 'https://sdssandbox.zsccoe.com',
  marketsURL: 'https://sdssandbox.zsccoe.com/MicroStrategySSO/servlet/mstrWeb?Server=SDSSANDBOX.ZSCCOE.COM&Project=Mercados&Port=0&evt=3140&src=mstrWeb.3140&documentID=DECB0C0111EBA1016F3E0080EF45D45F',
  misURL: 'https://sdssandbox.zsccoe.com/MicroStrategySSO/servlet/mstrWeb?evt=3186&src=mstrWeb.3186&subscriptionID=34483E9245411B98B43B9287977B655F&Server=SDSSANDBOX.ZSCCOE.COM&Project=ZS%20MIS&Port=0&share=1',
  menuVisibility: {
    portal: true,
    prehome: true,
    sale: true,
    saleDetail: true,
    wallet: true,
    walletGwpMix: true,
    qualityClaims: true,
    cancellation: true,
    cancelDetail: true,
    retention: true,
    retentionAccordingToRecord: true,
    charge: true,
    renewal: true,
    persistence: true,
    fixedStock: true,
    cancellationCuality: true,
    sinisters: true,
    sinistersDetails: true,
    sinistersRejections: true,
    sinistersQuality: true,
    sinistersMap: false,
    sinistersLossRatio: false,
    slas: true,
    clients: true,
    clientsPortoflio: true,
    clientsOccupation: true,
    clientsVoc: true,
    clientsInternalProcessQuality: false,
    clientsPenetrationSegmentation: false,
    clientsPortfolioDistribution: true,
    backoffice: false,
  },
  requestsCaching: true,
  documentManagement: false
};

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Animations } from '../../animations/animations';
import { EventsService } from '../../services/events/events.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss'],
  animations: [
    Animations.animateFilters
  ]
})
export class HomeHeaderComponent implements OnInit {

  @Input() isDataGovernancePortal: boolean = false;

  documentManagementVisibility = environment.documentManagement;
  
  menuLinks: any[] = [
    {
      name: "menu.documentManagement",
      link: "/document-management/portal",
      visible: this.documentManagementVisibility
    }
  ];

  zLogoSrc: string;
  santanderLogoSrc: string;
  area: string = 'portal';

  openSearchPanel: boolean = false;
  unsubscribe$: Subject<any> = new Subject<any>();

  @Output() searchPanel = new EventEmitter<any>();

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    if (this.isDataGovernancePortal) {
      this.zLogoSrc = 'assets/img/logo_zurich_data_governance.svg';
      this.santanderLogoSrc = 'assets/img/logo_santander_data_governance.svg';
    } else {
      this.zLogoSrc = 'assets/img/logo_z_extended.png';
      this.santanderLogoSrc = 'assets/img/logo_santander_extended.png';
    }

    this.eventsService.openSearchPanel$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.openSearchPanel = data;
        this.searchPanel.emit(data);
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

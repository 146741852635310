import { Injectable } from '@angular/core';
import { ATLAS_PARAMS } from 'src/app/shared/globals/globals';

@Injectable({
  providedIn: 'root'
})
export class AtlasHelperService {

  ATLAS_PARAMS = ATLAS_PARAMS;

  constructor() { }

  hasParamsEndpoint(pageId: string = "") {
    let hasInfo: boolean = false;
    if (this.getAtlasEndpointParams(pageId).length > 0) hasInfo = true;
    return hasInfo;
  }

  getAtlasEndpointParams(pageId: any = []) {
    const filteredList = this.ATLAS_PARAMS.filter(f => f.id == pageId);
    return filteredList.length > 0 && filteredList[0].params ? filteredList[0].params : [];
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { COLORS } from 'src/app/shared/globals/globals';
import { trim } from 'lodash';
import { forkJoin, Subject } from 'rxjs'
import { ApimstService } from 'src/app/shared/services/apimst.service';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { EventsService } from 'src/app/shared/services/events/events.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators'
import { CancellationPersistance } from 'src/app/shared/models/cancellation-persistance';
import { ExcelService } from 'src/app/shared/services/export/excel/excel.service';
import { ExcelFileInfo } from 'src/app/shared/models/excel-file-info';

@Component({
  selector: 'persistence-total-graphic',
  templateUrl: './persistence-total-graphic.component.html',
  styleUrls: ['./persistence-total-graphic.component.scss']
})
export class PersistenceTotalGraphicComponent implements OnInit, OnDestroy {

  // options
  view: any[] = [580, 190];
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  showGridLines: boolean = true;
  showRefLines: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  yScaleMax: number = 100;
  timeline: boolean = false;
  tooltipDisabled: boolean = false;
  customColors: any = [];
  colorScheme = {
    domain: [COLORS.lightBlue, COLORS.darkestGreen]
  };

  // Subscription
  unsubscribe$: Subject<any> = new Subject<any>();

  // Data storage variables
  filtersValues: any;
  filteredMonth: string;
  filteredYear: string;
  monthNames: any;
  loaded: boolean = false;
  dropdownOptions: any = [];
  dropdownSelectedValues: any = [];
  persistanceTotalData: any = [];
  colorList: any = [COLORS.lightBlue, COLORS.darkestGreen, COLORS.lightOrange, COLORS.lightestBlue, COLORS.lightPurple, COLORS.darkYellow, COLORS.darkBlue, COLORS.darkRed, COLORS.darkGreen, COLORS.m0, COLORS.m1, COLORS.m2, COLORS.m3, COLORS.m4, COLORS.m5, COLORS.m6, COLORS.m7, COLORS.m8, COLORS.m9, COLORS.m10, COLORS.m11, COLORS.m12, COLORS.m13, COLORS.m14];
  colorListCSS: any = ['light-blue', 'darkest-green', 'light-orange', 'lightest-blue', 'light-purple', 'dark-yellow', 'dark-blue', 'dark-red', 'dark-green', 'm0', 'm1', 'm2', 'm3', 'm4', 'm5', 'm6', 'm7', 'm8', 'm9', 'm10', 'm11', 'm12', 'm13', 'm14'];

  // Variables to export data
  data_total: any[] = [];
  data_sheet_names: any;
  data_filter_details: any;

  constructor(
    private apimstService: ApimstService,
    private filtersService: FiltersService,
    private eventsService: EventsService,
    private translateService: TranslateService,
    private excelService: ExcelService
  ) { }

  ngOnInit(): void {
    this.filtersService.filtersValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data.length !== 0) {
            this.filteredMonth = data.filter(v => v.name === 'month')[0].value;
            this.filteredYear = data.filter(v => v.name === 'year')[0].value;
            this.filtersValues = data;
            this.translateService.get('monthNamesWihtNumber')
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(data => {
                this.monthNames = data;
                this.dropdownSelectedValues = [];
                this.calculateDropdownValues(this.filteredYear, this.filteredMonth);
                this.persistanceTotalData = [];
                this.addCancellationPersistenceLine((parseInt(this.filteredYear) - 1).toString() + '-' + this.filteredMonth);
              });
          }
        });

    this.eventsService.filterDropdownEvent$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          if (data['form_name'] === 'cancellationTotalPersistance') {
            if (data['form_action'] == "select") {
              this.addSelectedItem(data['item_id']);
            } else if (data['form_action'] == "deselect") {
              this.removeSelectedItem(data['item_id']);
            } else {
              this.dropdownSelectedValues = [];
              this.persistanceTotalData = [];
            }
          }
        }
      );
  }

  // Method for calculating dropdown date values
  private calculateDropdownValues(year: string, month: string) {
    const yearInt = parseInt(year);
    const monthInt = parseInt(month) - 1;
    const date = new Date(yearInt, monthInt);
    let dateOptions: any = [];
    for (let _index = 0; _index < 24; _index++) {
      date.setFullYear(yearInt);
      date.setMonth(monthInt - _index);

      dateOptions.push({
        item_text: this.monthNames[date.getMonth()] + ' ' + date.getFullYear(),
        item_id: date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0')
      });
    }
    this.eventsService.cancellationTotalPersistance$.emit(dateOptions);

    this.dropdownSelectedValues.push(dateOptions[12].item_id);
  }

  // Method to add a line for the cancellation total persistance graph
  private addCancellationPersistenceLine(idDate: string) {
    this.loaded = false;

    const date = idDate.split('-');
    const year = date[0];
    const month = date[1];

    this.filtersValues = this.filtersValues.filter(v => v.name !== 'month').filter(v => v.name !== 'year').filter(v => v.name !== 'day');

    this.apimstService.getCancellationPersistence('pt', [], year, month, this.filtersValues)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: Array<CancellationPersistance>) => {
        const cancellationPersistance = data;

        const persistenceIndex = this.dropdownSelectedValues.indexOf(idDate);

        if (cancellationPersistance.length !== 0 && persistenceIndex !== -1) {
          this.data_filter_details = cancellationPersistance[0].filters;
          let series: any = [];
          for (let month of cancellationPersistance) {
            series.push(
              {
                name: 'M' + month.month_order,
                value: (month.rate || trim(month.rate).length > 0) ? parseFloat(month.rate.replace(/,/g, '')) : 0
              }
            );
          }
          this.persistanceTotalData.push(
            {
              name: idDate,
              series: series
            }
          );
          this.persistanceTotalData = [...this.persistanceTotalData];
          this.assignColorData();
        }

        this.loaded = true;

        this.getInfoForExcel();
      },

        error => console.log("An error occurred while retrieving total persistance data :" + error)
      );
  }

  // Method to remove a line of the cancellation total persistance graph
  private removeCancellationPersistanceLine(id: string) {
    this.persistanceTotalData = this.persistanceTotalData.filter(v => v.name != id);
    this.assignColorData();
    this.getInfoForExcel();
  }

  // Method to add an item to the selection list
  private addSelectedItem(item: string) {
    const index = this.dropdownSelectedValues.indexOf(item);
    if (index === -1) {
      this.dropdownSelectedValues.push(item);
      this.addCancellationPersistenceLine(item);
    }
  }

  // Method to remove an item from the selection list
  private removeSelectedItem(item: string) {
    const index = this.dropdownSelectedValues.indexOf(item);
    if (index !== -1) {
      this.dropdownSelectedValues.splice(index, 1);
      this.removeCancellationPersistanceLine(item);
    }
  }

  // Method to assign color to the graph lines
  private assignColorData() {
    this.customColors = [];
    for (let _index = 0; _index < this.dropdownSelectedValues.length; _index++) {
      this.customColors.push(
        {
          name: this.dropdownSelectedValues[_index],
          value: this.colorList[_index]
        }
      )
    }
  }

  // Method to format the Y axis with %
  yAxisTickFormatting(val) {
    return val.toLocaleString() + "%";
  }

  getInfoForExcel(): void {
    this.data_total = [];
    const title = this.translateService.get('panels.persistenceTotal');

    let titleLabel = '';

    forkJoin([title])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(result => {
      titleLabel = result[0];
    });

    this.getDataToExport();
    this.data_sheet_names = [titleLabel];

    let excelInfo: ExcelFileInfo = {json: [this.data_total], headers: this.data_sheet_names, excelFileName: 'total_persistance_chart', filtersValues: this.data_filter_details, filters: true};
    this.eventsService.persistenceTotalExcelInfoLoaded$.next(excelInfo);

  }

  // Method to export the graph data to an Excel file
  exportAsXLSX() {
    this.getInfoForExcel();
    this.excelService.exportAsExcelFile([this.data_total], this.data_sheet_names,  'total_persistance_chart', this.data_filter_details);
  }

  // Method to retrieve the data to export
  private getDataToExport() {
    const values: any[] = [];
    const dates: any[] = [];
    for (let line of this.persistanceTotalData) {
      const data = [line.name + ' (%)', line.series.map(v => v.value)].flat();
      values.push(data);
      dates.push(line.series.map(v => v.name));
    }

    let dateLength = 0;
    let dateIndex = 0;
    for (let [index, item] of dates.entries()) {
      if (item.length > dateLength) {
        dateLength = item.length;
        dateIndex = index;
      }
    }

    const headers = ['', dates[dateIndex]];
    this.data_total.push(headers.flat());

    for (let line of values) {
      this.data_total.push(line);
    }

  }

  // OnDestroy
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
